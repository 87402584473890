import { List, ListItem } from "@chakra-ui/react";
import { ReactElement } from "react";

import { CalloutLink, CalloutLinkProps } from "./CalloutLink";

export interface DocsLinkListProps {
  links: CalloutLinkProps[];
}

/**
 * Renders a vertical list of links with external indicators.
 */
export function DocsLinkList({ links }: DocsLinkListProps): ReactElement {
  return (
    <List>
      {links.map((link, i) => {
        return (
          <ListItem key={i}>
            <CalloutLink {...link} />
          </ListItem>
        );
      })}
    </List>
  );
}
