import { PredictorsBlockedProvidersEnum } from "../outcomes/OutcomesShowPage/OutcomeDataCard";
import { Pill } from "./Pill";

function getsAccountName(provider: string): boolean {
  // check if provider is an account uuid when provider spec is implemented
  // or perhaps there is a utility function to check if a uuid is an account uuid
  // const uuidRegex =
  // /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

  if (provider === PredictorsBlockedProvidersEnum.SELF) {
    return true;
  }
  return false;
}

export function ProviderPill({
  provider,
  accountName,
}: {
  provider: string;
  accountName?: string;
}) {
  // add something like `|| provider === "fig"` to the condition
  // when adding the rest of the provider spec
  if (provider.startsWith("fig/")) {
    return (
      <Pill
        sx={{
          color: "fdy_purple.500",
          backgroundColor: "fdy_purple.100",
        }}
      >
        Faraday
      </Pill>
    );
  }

  return (
    <Pill
      sx={{
        color: "fdy_magenta.500",
        backgroundColor: "fdy_magenta.200",
      }}
    >
      {getsAccountName(provider) ? accountName : "User defined"}
    </Pill>
  );
}
