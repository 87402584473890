import { Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

/**
 * Renders generic tabular data from the API.
 * Purposefully non-restrictive data structure to allow for flexibility.
 */
export function TabularDataTable({
  headers,
  rows,
  title,
}: {
  title?: string;
  // awful null types cause gql makes them nullish...
  headers: (string | null)[];
  rows: ((string | null | undefined)[] | null)[];
}) {
  return (
    <>
      {title && (
        <Heading as="h3" sx={{ mb: 2, fontSize: "fdy_sm" }}>
          {title}
        </Heading>
      )}
      <Table size="sm">
        <Thead>
          <Tr>
            {headers.map((header, i) => (
              <Th key={i}>{header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row, i) => (
            <Tr key={i}>
              {row?.map((cell, j) => (
                <Td key={j}>{cell}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}
