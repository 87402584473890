import { gql } from "@apollo/client";

import { useSojournerQuery } from "../../services/sojournerApolloClient";
import {
  CohortsShowPageQuery,
  CohortsShowPageQueryVariables,
} from "./__generated__/CohortsShowPageQuery";
import { cohortFragment } from "./cohortFragment";

export const COHORT_SHOW_QUERY = gql`
  query CohortsShowPageQuery($id: ID!) {
    cohort(cohortId: $id) {
      ...CohortFragment
    }
  }
  ${cohortFragment}
`;

export function useCohortQuery(
  cohortId?: string | null,
  opts: {
    skip?: boolean;
  } = {}
) {
  const skip = !cohortId || opts.skip;

  return useSojournerQuery<CohortsShowPageQuery, CohortsShowPageQueryVariables>(
    COHORT_SHOW_QUERY,
    {
      variables: {
        // The query is skipped if cohortId is undefined, but the typescript `variables` aren't smart enough to accept optional when `skip` is used.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: cohortId!,
      },
      skip,
    }
  );
}
