import { PrimitiveDataType } from "@fdy/faraday-js";
import {
  CalendarBlank,
  CircleDashed,
  Hash,
  Icon,
  TextT,
  ToggleLeft,
} from "@phosphor-icons/react";

import { titleCase } from "../../../utils/formatters";
import { IconWithText } from "../../ui/IconWithText";

const dataTypeIcons: Record<PrimitiveDataType, Icon> = {
  boolean: ToggleLeft,
  date: CalendarBlank,
  double: Hash,
  long: Hash,
  string: TextT,
};

// alias a few trait types to make them more app/dashboard user friendly
export const traitTypeAliases: Record<PrimitiveDataType, string> = {
  boolean: "boolean",
  date: "date",
  string: "string",
  long: "integer",
  double: "float",
};

export const getTraitTypeAlias = (type: PrimitiveDataType) => {
  return traitTypeAliases[type];
};

export const PrimitiveDataTypePill = ({
  type,
}: {
  type: PrimitiveDataType | undefined;
}) => {
  const TypeIcon = type ? dataTypeIcons[type] : CircleDashed; // unknown
  const alias = type ? traitTypeAliases[type] : "Unknown";

  return (
    <IconWithText>
      <TypeIcon />
      {titleCase(alias)}
    </IconWithText>
  );
};
