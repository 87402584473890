import { Box } from "@chakra-ui/react";

import { AccordionV2 } from "../../../ui/AccordionV2";
import { OutcomeAnalysisQuery_outcomeAnalysis_bias_data } from "../__generated__/OutcomeAnalysisQuery";
import { BiasLevelIcon } from "./BiasLevelIcon";
import { biasLevelToLiterate } from "./outcomeAnalysisBiasUtils";
import { BiasDataAgeDistributionChart } from "./OutcomeBiasDataAgeDistributionChart";
import { BiasDataGenderProportionChart } from "./OutcomeBiasDataGenderProportionChart";

function HistricalDataLevel({ level }: { level: string }) {
  return (
    <Box
      sx={{
        display: "inline-flex", // inline so it text-aligns to right of accordion item
        gap: 2,
      }}
    >
      {level === "strong_bias" ? <BiasLevelIcon ok={false} /> : null}
      {biasLevelToLiterate(level)}
    </Box>
  );
}

/**
 * Renders bias historical data charts for an outcome
 */
export function OutcomeBiasHistoricalData({
  data,
}: {
  data: OutcomeAnalysisQuery_outcomeAnalysis_bias_data;
}) {
  return (
    <AccordionV2>
      <AccordionV2.Item
        title="Age"
        afterTitle={<HistricalDataLevel level={data.age.level} />}
      >
        <BiasDataAgeDistributionChart data={data.age} />
      </AccordionV2.Item>
      <AccordionV2.Item
        title="Gender"
        afterTitle={<HistricalDataLevel level={data.gender.level} />}
      >
        <BiasDataGenderProportionChart data={data.gender} />
      </AccordionV2.Item>
    </AccordionV2>
  );
}
