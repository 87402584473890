import { gql } from "@apollo/client";
import React, { useMemo, useState } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useApiErrorToaster } from "../../../hooks/useApiErrorToaster";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { ResourceTableList } from "../../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../../ui/ResourceTableTabs";
import { SortableHeader, SortableTable } from "../../ui/SortableTable";
import {
  PipelinesTableQuery,
  PipelinesTableQuery_scopes as Scope,
} from "../__generated__/PipelinesTableQuery";
import { targetFragment } from "../deployment/targetFragment";
import { pipelinesQuery } from "../pipelinesQuery";
import { TargetsQuery } from "./__generated__/TargetsQuery";
import { PipelineTableRow } from "./PipelinesTableRow";

const BLANK_STATE_PROPS = {
  title: "No pipelines found",
  text: "Pipelines use data from Datasets in the form of Cohorts, Outcomes, and/or Persona Sets, and deploy them to Connections or to a downloadable CSV.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Start by adding a Dataset if you haven't already.",
  },
};

export const TARGETS_QUERY = gql`
  query TargetsQuery {
    targets {
      ...TargetFragment
    }
  }
  ${targetFragment}
`;

const tableHeaders: SortableHeader<Scope>[] = [
  {
    key: "name",
    label: "Name",
    width: 200,
  },
  {
    label: "Population",
    width: 200,
  },
  {
    label: "Payload",
    width: 200,
  },
  {
    label: "Deployments",
    width: 200,
  },
  {
    key: "status",
    label: "Status",
    sortingFn: "string",
  },
  {
    key: "createdAt",
    label: "Created",
    sortingFn: "date",
  },
  {
    key: "lastUpdatedConfigAt",
    label: "Modified",
    sortingFn: "date",
  },
  {
    key: "lastUpdatedOutputAt",
    label: "Processed",
    sortingFn: "date",
  },
  {
    label: "Actions",
    width: 64,
    visuallyHidden: true,
  },
];

/**
 * Render list of account's scopes with various meta data,
 * error state indicator, a switch to toggle preview mode, and actions menu.
 */
export function PipelinesTable() {
  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);
  const { data, loading, error } = useSojournerQuery<PipelinesTableQuery>(
    pipelinesQuery,
    {
      pollInterval: 10000,
    }
  );

  const {
    data: targetsData,
    loading: targetsLoading,
    error: targetsError,
  } = useSojournerQuery<TargetsQuery>(TARGETS_QUERY);

  useApiErrorToaster(targetsError, {
    handleUnknownAs: "toast",
  });

  const filteredScopes = useMemo(
    () => filterResourceTabs(data?.scopes, activeTab),
    [data, activeTab]
  );

  const renderScopeRow = (scope: Scope) => {
    const targets =
      targetsData?.targets.filter((t) => t.scopeId === scope.id) ?? [];
    return (
      <PipelineTableRow
        scope={scope}
        targets={targets}
        targetsLoading={targetsLoading}
      />
    );
  };

  return (
    <>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data?.scopes}
      />
      <SortableTable
        storageKey="scopes"
        defaultSort={{
          key: "createdAt",
          desc: true,
        }}
        data={filteredScopes}
        headers={tableHeaders}
        renderData={(data) => (
          <ResourceTableList<Scope>
            error={error}
            loading={loading}
            data={data}
            renderData={renderScopeRow}
            blankSlateProps={BLANK_STATE_PROPS}
          />
        )}
      />
    </>
  );
}
