import { gql } from "@apollo/client";
import { MockedResponse } from "@apollo/client/testing";

import { UsagesQuery } from "./__generated__/UsagesQuery";

export const usagesQuery = gql`
  query UsagesQuery {
    usages {
      description
      limit
      usage
      name
    }
  }
`;

// use for mocking in tests
export function makeUsagesQuery(): MockedResponse<UsagesQuery> {
  return {
    request: {
      query: usagesQuery,
    },
    result: {
      data: {
        usages: [
          {
            __typename: "AccountUsage",
            description: "Number of xxx allowed",
            limit: 5,
            usage: 0,
            name: "xxx.max_count",
          },
        ],
      },
    },
  };
}
