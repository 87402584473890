import {
  Box,
  Divider,
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { PencilSimple } from "@phosphor-icons/react/dist/ssr";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useAccountBasics } from "../../../hooks/accountConfigHooks";
import { useTraitsQuery } from "../../../hooks/useTraitsQuery";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { CardV2 } from "../../ui/Card/CardV2";
import { Cardv2Section } from "../../ui/Card/Cardv2Section";
import { LinkButton } from "../../ui/LinkButton";
import { MutedText } from "../../ui/MutedText";
import { ProviderPill } from "../../ui/ProviderPill";
import { SkeletonCard } from "../../ui/SkeletonCard";
import {
  OutcomeFragment,
  OutcomeFragment_predictors_blocked,
} from "../__generated__/OutcomeFragment";
import { OutcomeDataCardFeatures } from "./OutcomeDataCard/OutcomeDataCardFeatures";
import { useOutcomeAnalysisQuery } from "./useOutcomeAnalysisQuery";

const dataInfo = {
  title: "Data",
  text: "Below are the top features from the Faraday Identity Graph ordered by their impact on the outcome. First-party features may also appear here (indicated with a sparkle). Directionality information is also included.",
};

const exceptionsInfo = {
  title: "Exceptions",
  text: "Faraday attempts to use as much data from your account as possible when looking for predictive patterns. However, in some cases, certain data shouldn’t be used. Exceptions for this outcome are listed below.",
};
const exceptionsStyles = {
  display: "flex",
  flexDirection: "column",
};

export enum PredictorsBlockedProvidersEnum {
  SELF = "self",
  FIG = "fig",
}

type Providers = OutcomeFragment_predictors_blocked["providers"] &
  OutcomeFragment["featureBlocklist"];

function OutcomeExceptionsTable({
  featureBlocklist,
  predictorsBlockedProviders,
}: {
  featureBlocklist: OutcomeFragment["featureBlocklist"] | undefined;
  predictorsBlockedProviders:
    | OutcomeFragment_predictors_blocked["providers"]
    | undefined;
}) {
  const accountBasics = useAccountBasics();

  let providers: Providers = [];

  if (featureBlocklist && predictorsBlockedProviders) {
    providers = [...featureBlocklist, ...predictorsBlockedProviders];
  } else if (featureBlocklist) {
    providers = featureBlocklist;
  } else if (predictorsBlockedProviders) {
    providers = predictorsBlockedProviders;
  }

  // modify when we have more providers e.g. accountId and fig
  const blockedProviders: string[] = [PredictorsBlockedProvidersEnum.SELF];
  const { traitsMap, loadingTraits } = useTraitsQuery();
  if (loadingTraits) return <AnimatedZapLogo />;
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Provider</Th>
          <Th>Name</Th>
          <Th>Status</Th>
        </Tr>
      </Thead>
      <Tbody>
        {providers.map((provider) => {
          const trait = traitsMap[provider];
          const literate = trait?.literate ?? trait?.name ?? provider;
          return (
            <Tr key={provider}>
              <Td>
                <ProviderPill
                  provider={provider}
                  accountName={accountBasics.name}
                />
              </Td>
              <Td>
                {blockedProviders.includes(provider) ? "All traits" : literate}
              </Td>
              <Td
                sx={{
                  fontWeight: "bold",
                  color: "fdy_red.500",
                }}
              >
                Blocked by user
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

function OutcomeDataCardExceptions({ outcome }: { outcome: OutcomeFragment }) {
  return (
    <Cardv2Section title={exceptionsInfo.title} text={exceptionsInfo.text}>
      <Box sx={exceptionsStyles}>
        <Link
          href="https://faraday.ai/docs/abstractions/outcomes#exceptions"
          isExternal
          sx={{
            mb: 4,
          }}
        >
          Learn more about data exceptions
        </Link>
        {outcome.featureBlocklist.length ||
        outcome.predictors?.blocked?.providers.length ? (
          <OutcomeExceptionsTable
            predictorsBlockedProviders={outcome.predictors?.blocked?.providers}
            featureBlocklist={outcome.featureBlocklist}
          />
        ) : (
          <MutedText>None</MutedText>
        )}
      </Box>
      <LinkButton
        width="full"
        variant="secondary"
        routeName={ROUTE_NAMES.OUTCOMES_EDIT}
        params={{ outcome: outcome.id }}
        leftIcon={<PencilSimple />}
        mt={4}
        analyticsName="data-edit-outcome"
      >
        Edit
      </LinkButton>
    </Cardv2Section>
  );
}

export function OutcomeDataCard({ outcome }: { outcome: OutcomeFragment }) {
  const { data, loading } = useOutcomeAnalysisQuery(outcome);

  const { overallFeatures, tenureFeatures } = data?.outcomeAnalysis ?? {};

  if (loading) return <SkeletonCard />;

  // if we have outcome analysis data, render the feature importances
  if (overallFeatures || (overallFeatures && tenureFeatures)) {
    return (
      <CardV2 title={dataInfo.title} text={dataInfo.text}>
        <OutcomeDataCardFeatures
          eligibleCohortName={outcome.eligibleCohortName}
          outcomeName={outcome.name}
          overallFeatures={overallFeatures}
          tenureFeatures={tenureFeatures}
        />
        <Divider />
        <OutcomeDataCardExceptions outcome={outcome} />
      </CardV2>
    );
  }

  return (
    <CardV2 title={dataInfo.title}>
      <OutcomeDataCardExceptions outcome={outcome} />
    </CardV2>
  );
}
