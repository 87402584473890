import { useMemo, useState } from "react";

export const FEATURES_SHOWN_LIMIT = 10;

export function useProgressiveDisclosureItems<T>(items: T[]) {
  const [shown, setShown] = useState(FEATURES_SHOWN_LIMIT);

  const shownItems = useMemo(() => items.slice(0, shown), [items, shown]);

  const hasMoreToShow = shown < items.length;

  return {
    shownItems,
    hasMoreToShow,
    showMoreButtonProps: {
      onClick: () => setShown(shown + FEATURES_SHOWN_LIMIT),
      children: `Show ${FEATURES_SHOWN_LIMIT} more`,
    },
  };
}
