import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { OutcomesEditPage } from "./OutcomesEditPage";
import { OutcomesListPage } from "./OutcomesListPage";
import { OutcomesNewPage } from "./OutcomesNewPage";
import { OutcomesShowPage } from "./OutcomesShowPage";

export function Outcomes() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.OUTCOMES]: OutcomesListPage,
    [ROUTE_NAMES.OUTCOMES_NEW]: OutcomesNewPage,
    [ROUTE_NAMES.OUTCOMES_DEFINITION]: OutcomesShowPage,
    [ROUTE_NAMES.OUTCOMES_EDIT]: OutcomesEditPage,
  };

  const Page = routes[route.name] ?? <span>Page not found</span>;

  return <Page />;
}
