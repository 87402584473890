import { Prose } from "../ui/Prose";

export function RecommendersInfoSidebar() {
  return (
    <>
      <Prose>
        <p>
          Recommenders allow you to predict which one of several options each
          person is likely to choose. For example, you could use a recommender
          to promote the product each person is likeliest to buy.
        </p>
      </Prose>
    </>
  );
}
