import { lazy, Suspense } from "react";
import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { Redirect } from "../ui/Redirect";

const RecipeDetailPage = lazy(
  () => import("./RecipeDetailPage/RecipeDetailPage")
);
const RecipesListPage = lazy(() => import("./RecipeListPage"));

/**
 * Recipes console lets users view and configure their recipes.
 */
export function Recipes() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.RECIPES]: RecipesListPage,
    [ROUTE_NAMES.RECIPES_VIEW]: RecipeDetailPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.RECIPES} />
  );

  /* lazy loading because the praxis docs are big */
  return (
    <Suspense fallback={<AnimatedZapLogo />}>
      <Page />
    </Suspense>
  );
}
