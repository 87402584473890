import { AreaSeries, LineSeries } from "@visx/xychart";

import { colors } from "../../../../styles/chakra-theme-v2";
import { percent } from "../../../../utils/formatters";
import { AxisBottom } from "../../../ui/charts-v2/AxisBottom";
import { AxisLeft } from "../../../ui/charts-v2/AxisLeft";
import { Chart } from "../../../ui/charts-v2/Chart";

interface LiftCurveDatum {
  x: number;
  y: number;
  yMin: number;
  yMax: number;
}

interface OutcomeLiftCurveChartProps<Datum extends LiftCurveDatum> {
  title: string;
  data: Datum[];
  yLabel: string;
  xLabel: string;
}

/**
 * Render a responsive line curve chart. This chart is used to display
 * a line curve with a shaded area around it.
 */
export function OutcomeLiftCurveChart<Datum extends LiftCurveDatum>({
  data,
  yLabel,
  xLabel,
  title,
}: OutcomeLiftCurveChartProps<Datum>) {
  return (
    <Chart
      title={title}
      yScale={{ type: "linear" }}
      xScale={{ type: "linear" }}
    >
      <AreaSeries
        dataKey="area"
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.yMin}
        y0Accessor={(d) => d.yMax}
        fill={colors.fdy_green[300]}
        // these adjust the top of area line styles
        stroke={colors.fdy_green[500]}
        strokeDasharray="2,4"
        // these adjust the bottom line styles
        lineProps={{
          strokeWidth: 2,
          strokeDasharray: "2,4",
          stroke: colors.fdy_green[500],
        }}
      />

      <LineSeries
        dataKey="lines"
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        stroke={colors.fdy_green[600]}
      />

      <AxisLeft label={yLabel} tickFormat={percent} />

      <AxisBottom label={xLabel} tickFormat={percent} />
    </Chart>
  );
}
