import { gql } from "@apollo/client";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { Blankslate } from "../../ui/Blankslate";
import { CardV2 } from "../../ui/Card/CardV2";
import { PersonaSetHookQuery_personaSet } from "../__generated__/PersonaSetHookQuery";
import {
  PersonaSetFlowPageQuery,
  PersonaSetFlowPageQueryVariables,
} from "./__generated__/PersonaSetFlowPageQuery";
import { PersonaFlowChart } from "./PersonaFlowChart";

const PERSONA_FLOW_PAGE = gql`
  query PersonaSetFlowPageQuery($id: ID!) {
    personaSetAnalysisFlow(personaSetId: $id) {
      personaId
      days {
        date
        count
      }
    }
  }
`;

type PersonaSetFlowProps = {
  personaSet: PersonaSetHookQuery_personaSet;
};

export function PersonaSetFlowCard({ personaSet }: PersonaSetFlowProps) {
  const { data, loading, error } = useSojournerQuery<
    PersonaSetFlowPageQuery,
    PersonaSetFlowPageQueryVariables
  >(PERSONA_FLOW_PAGE, {
    variables: {
      id: personaSet.id,
    },
  });

  if (error) throw error;

  if (loading || !data?.personaSetAnalysisFlow) {
    return <AnimatedZapLogo />;
  }

  return (
    <CardV2
      title="Change over time"
      text={
        <>
          The proportion of people in <strong>{personaSet.cohort?.name}</strong>{" "}
          that belong to each persona changes over time. The graph below
          visualizes this evolution. Hover anywhere for details.
        </>
      }
    >
      {data.personaSetAnalysisFlow.length ? (
        <PersonaFlowChart
          flow={data?.personaSetAnalysisFlow}
          personas={personaSet.personas}
        />
      ) : (
        <Blankslate
          title="Unable to show change over time"
          text="To see your personas over time, your persona set must be built from a cohort that uses an event stream with a datetime property."
          button={{
            children: "View cohorts",
            routeName: ROUTE_NAMES.COHORTS,
          }}
          filled
        />
      )}
    </CardV2>
  );
}
