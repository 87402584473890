import { defaultStyles as defaultTooltipStyles } from "@visx/tooltip";
import { Tooltip } from "@visx/xychart";

import { ChartDatum, Persona } from "./personaFlowChartUtils";
import { PersonaFlowTooltipContent } from "./PersonaFlowTooltipContent";

/**
 * Render the tooltip for the PersonaFlowChart.
 * Must be rendered inside an XYChart.
 */
export function PersonaFlowTooltip({
  flow,
  personas,
}: {
  flow: ChartDatum[];
  personas: Persona[];
}) {
  return (
    <Tooltip<ChartDatum>
      snapTooltipToDatumX
      snapTooltipToDatumY
      showVerticalCrosshair
      style={{
        ...defaultTooltipStyles,
        // Unset some defaults that come with the tooltip. We still need the
        // base defaults for proper hide/show styles.
        padding: 0,
      }}
      renderTooltip={({ tooltipData }) => {
        if (!tooltipData?.nearestDatum?.key) {
          return null;
        }

        const personaId = tooltipData.nearestDatum.key;
        const persona = personas.find((p) => p.id === personaId);
        if (!persona) {
          throw new Error(
            `Persona not found for id: ${personaId}. Personas must be passed to the chart.`
          );
        }

        const first = flow[0];
        const last = flow[flow.length - 1];

        return (
          <PersonaFlowTooltipContent
            persona={persona}
            cursor={tooltipData.nearestDatum.datum}
            first={first}
            last={last}
          />
        );
      }}
    />
  );
}
