export const route = (...args: string[]): string => args.join(".");

export const ACCOUNT = "account";
export const ANALYSIS = "analysis";
export const API = "api";
export const BETA = "beta";
export const BILLING = "billing";
export const CREATE = "create";
export const COHORTS = "cohorts";
export const CONNECTIONS = "connections";
export const DASHBOARD = "dashboard";
export const DATA = "data";
export const DATASETS = "datasets";
export const DEPLOYMENTS = "deployments";
export const EDIT = "edit";
export const EVENTS = "events";
export const EXPLORE = "explore";
export const FILE = "file";
export const FILES = "files";
export const FLOW = "flow";
export const GEOGRAPHIES = "geographies";
export const HOME = "home";
export const ID = "id";
export const INFORM = "inform";
export const LOCATIONS = "locations";
export const MAP = "map";
export const MEMBERS = "members";
export const NAME = "name";
export const NEW = "new";
export const ONBOARDING = "onboarding";
export const OUTCOMES = "outcomes";
export const PAGE = "page";
export const PERSONAS = "personas";
export const PREDICTION = "prediction";
export const PIPELINES = "pipelines";
export const RECIPE = "recipe"; // for individual recipe pages
export const RECIPES = "recipes"; // for recipe list view
export const RECOMMENDERS = "recommenders";
export const SETTINGS = "settings";
export const SETUP = "setup";
export const SEGMENTS = "segments";
export const STATISTICS = "stats";
export const TABLE = "table";
export const TRAITS = "traits";
export const ANALYTICS = "analytics";
export const CONFIGURATION = "configuration";
export const DETAILS = "details";
export const TAB = "tab";
export const LOGOUT = "logout";
export const UPLOAD = "upload";

export const ROUTE_NAMES = {
  EXPLORE,
  EXPLORE_MAP: route(EXPLORE, MAP),
  EXPLORE_ANALYSIS: route(EXPLORE, ANALYSIS),
  HOME,
  DASHBOARD,
  DATASETS: route(DATASETS),
  DATASETS_NEW: route(DATASETS, NEW),
  DATASETS_DEFINITION: route(DATASETS, ID),
  DATASETS_DATA: route(DATASETS, ID, DATA),
  EVENTS: route(EVENTS),
  EVENTS_SHOW: route(EVENTS, ID),
  TRAITS: route(TRAITS),
  INFORM,
  INFORM_PAGE: route(INFORM, PAGE),
  INFORM_VIEW_CALL: route(INFORM, PAGE, ID),
  INFORM_VIEW_CALL_TAB: route(INFORM, PAGE, ID, TAB),
  LOCATIONS,
  LOCATIONS_NEW: route(LOCATIONS, NEW),
  VIEW_LOCATION: route(LOCATIONS, ID),
  EDIT_LOCATION: route(LOCATIONS, ID, EDIT),
  SETTINGS_MEMBERS: route(SETTINGS, MEMBERS),
  PERSONAS,
  PERSONAS_NEW: route(PERSONAS, NEW),
  PERSONAS_EDIT: route(PERSONAS, ID, EDIT),
  PERSONAS_ANALYSIS: route(PERSONAS, ID, ANALYSIS),
  PERSONAS_ROSTER: route(PERSONAS, ID),
  PERSONAS_FLOW: route(PERSONAS, ID, FLOW),
  PERSONAS_STATISTICS: route(PERSONAS, ID, STATISTICS),
  PERSONAS_SETUP: route(PERSONAS, ID, SETUP),
  NAME,
  COHORTS,
  COHORT_NEW: route(COHORTS, NEW),
  COHORTS_SHOW: route(COHORTS, ID),
  COHORT_ANALYSIS: route(COHORTS, ID, ANALYSIS),
  ONBOARDING,
  OUTCOMES_EDIT: route(OUTCOMES, ID, EDIT),
  OUTCOMES_NEW: route(OUTCOMES, NEW),
  OUTCOMES_DEFINITION: route(OUTCOMES, ID),
  OUTCOMES_ANALYSIS: route(OUTCOMES, ID, ANALYSIS),
  OUTCOMES: route(OUTCOMES),
  PIPELINES,
  PIPELINES_NEW: route(PIPELINES, NEW),
  PIPELINES_VIEW: route(PIPELINES, ID),
  PIPELINES_EDIT: route(PIPELINES, ID, EDIT),
  PIPELINES_ANALYSIS: route(PIPELINES, ID, ANALYSIS),
  CONNECTIONS,
  CONNECTIONS_NEW: route(CONNECTIONS, NEW),
  CONNECTIONS_SHOW: route(CONNECTIONS, ID),
  CONNECTIONS_EDIT: route(CONNECTIONS, ID, EDIT),
  RECIPES,
  RECIPES_VIEW: route(RECIPES, RECIPE),
  RECOMMENDERS,
  RECOMMENDERS_NEW: route(RECOMMENDERS, NEW),
  RECOMMENDERS_SHOW: route(RECOMMENDERS, ID),
  RECOMMENDERS_EDIT: route(RECOMMENDERS, ID, EDIT),
  SETTINGS,
  SETTINGS_API: route(SETTINGS, API),
  SETTINGS_ACCOUNT: route(SETTINGS, ACCOUNT),
  SETTINGS_BILLING: route(SETTINGS, BILLING),
  SETTINGS_FILES: route(SETTINGS, FILES),
  SETTINGS_GEOGRAPHIES: route(SETTINGS, GEOGRAPHIES),
  SETTINGS_VIEW_FILE: route(SETTINGS, FILES, FILE),
  SETTINGS_VIEW_GEOGRAPHY: route(SETTINGS, GEOGRAPHIES, ID),
  LOGOUT,
};
