import { Spinner } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";

import {
  CohortTraitInput,
  PrimitiveDataType,
} from "../../../../__generated__/sojournerGlobalTypes";
import { Trait, useTraitQuery } from "../../../../hooks/useTraitsQuery";
import { CohortFilterEditor } from "../CohortFilterEditor";
import {
  booleanTraitToWire,
  traitConditionsFromWire,
  traitConditionsToWire,
} from "./utils";

export interface CohortTraitsEditorProps {
  trait: Trait;
  condition: CohortTraitInput;
  onChange: Dispatch<SetStateAction<CohortTraitInput>>;
}

/** If the trait has a lookup table, we want to apply it so the user
 * friendly categories are shown in the UI. This could transform values
 * that are numbers with breaks like 1,2,3,4 to Definite Renter, Probable
 * Renter, Probable Owner, Definite Owner. This is so modeling can be as
 * specific as possible maintaining relationship between values, but users
 * still understand what is happening. */
function applyLookupTableToTrait(trait: Trait): Trait {
  const traitWithFilterProperties = { ...trait };

  if (trait.lookupTable !== null) {
    traitWithFilterProperties.categories = Object.values(
      trait.lookupTable
    ) as string[];
    traitWithFilterProperties.type = PrimitiveDataType.STRING;
  }

  return traitWithFilterProperties;
}

export function CohortTraitsEditor(props: CohortTraitsEditorProps) {
  const { trait, loading } = useTraitQuery(props.trait.id);
  if (loading) return <Spinner />;
  if (!trait) throw new Error(`Trait ${props.trait.id} not found.`);

  return (
    <CohortFilterEditor<CohortTraitInput>
      filterName={trait.name}
      filterProperties={applyLookupTableToTrait(trait)}
      condition={props.condition}
      onChange={props.onChange}
      conditionsToWire={(condition, conditionsList) =>
        traitConditionsToWire(trait, condition, conditionsList)
      }
      conditionsFromWire={(condition) =>
        traitConditionsFromWire(trait, condition)
      }
      booleanConditionToWire={booleanTraitToWire}
    />
  );
}
