import { Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FormatOptionLabelMeta } from "react-select";

import { exists } from "../../../../../utils/exists";
import { percent } from "../../../../../utils/formatters";
import { DatasetSelectOption } from "../../../shared/reactSelectStyle";
import { DetectedColumn } from "./types";

export type SampleData = {
  [columnName: string]: string[];
};

// api.interfaces.yml only specifies that it's a SojJSON
// make a type that's more precise
export function isSampleData(
  sampleData: SojJSON | null
): sampleData is SampleData {
  return (
    !!sampleData &&
    Object.keys(sampleData).length > 0 &&
    Object.values(sampleData).every((sample) => Array.isArray(sample))
  );
}

export function hintFromSampleData({
  sampleData,
  column,
}: {
  sampleData?: SampleData;
  column?: DetectedColumn;
}) {
  const columnName = column?.name;
  if (!sampleData || !columnName || !sampleData[columnName]) return undefined;
  return sampleData[columnName].join(" • ");
}

function OptionWithSampleData({ label, hint, fillRate }: DatasetSelectOption) {
  return (
    <>
      <Text>{label}</Text>
      {hint && (
        <Text color="fdy_gray.600" fontSize="fdy_sm">
          {hint}
        </Text>
      )}
      {exists(fillRate) && (
        <Text color="fdy_gray.600" fontWeight="semibold" fontSize="fdy_sm">
          {percent(fillRate)} fill rate
        </Text>
      )}
    </>
  );
}

export function formatOptionLabel(
  data: DatasetSelectOption,
  formatOptionLabelMeta: FormatOptionLabelMeta<DatasetSelectOption>
): ReactNode {
  // declutter the UI
  // show the sample data in the menu option, NOT in the select's display value
  return formatOptionLabelMeta.context === "menu"
    ? OptionWithSampleData(data)
    : data.label;
}
