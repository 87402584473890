import { gql } from "@apollo/client";

import { ApolloGQLService } from "../ApolloGQLService";
import { ShareableUrlRedirectQuery } from "./__generated__/ShareableUrlRedirectQuery";
import { NEVER_RESOLVE } from "./base";
import { EmbeddedAuthService } from "./embedded";
import { AuthError } from "./errors";

/**
 * An auth service for JWTs that are directly signed by external identity
 * providers using their own keys.
 */
export class ShareableURLAuthService extends EmbeddedAuthService {
  protected handleMissingToken(): Promise<never> {
    throw new AuthError("[AuthError] no #fdy_token was provided!");
  }

  protected handleExpiredToken(_token: string): Promise<never> {
    this.log.error(
      "[AuthError] expired shareable url JWT token; not sure what to do"
    );
    return NEVER_RESOLVE;
  }

  async redirectionIfRouteIsUnauthorized(
    gqlClient: ApolloGQLService,
    routeName: string
  ): Promise<string | null> {
    const { data } = await gqlClient.query<ShareableUrlRedirectQuery>({
      query: gql`
        query ShareableUrlRedirectQuery {
          shareable_url_redirect_info {
            default_route
            allowed_routes
          }
        }
      `,
    });
    if (!data) throw new AuthError("[AuthError] Expected GQL client to block");
    if (!data.shareable_url_redirect_info)
      throw new AuthError(
        "[AuthError] All shareable url users should have redirect info"
      );

    if (!data.shareable_url_redirect_info.allowed_routes.includes(routeName)) {
      return data.shareable_url_redirect_info.default_route;
    } else {
      return null;
    }
  }
}
