import { Box } from "@chakra-ui/react";

export function PersonaAvatar({
  persona,
  size = "md",
}: {
  persona: {
    name: string | null;
    avatar: string | null;
  };
  size?: "md" | "lg";
}) {
  if (!persona.avatar) return null;

  const sizes = {
    md: "48px",
    lg: "64px",
  };

  return (
    <Box
      as="img"
      src={persona.avatar}
      sx={{
        width: sizes[size],
        borderRadius: 1000,
        border: "2px solid",
        borderColor: "fdy_gray.200",
      }}
    />
  );
}
