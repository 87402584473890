import styled from "@emotion/styled";
import { ReactElement } from "react";

import { theme } from "../../constants/theme";
import { colors } from "../../styles/chakra-theme-v2";
import { AnalyticsStack } from "../ui/Analytics/AnalyticsStack";
import { AccountNav } from "./AccountNav";
import { CurrentAccount } from "./CurrentAccount";
import { DataNav } from "./DataNav";
import { PredictionsNav } from "./PredictionsNav";
import { ProductNav } from "./ProductNav";

const StyledNavigationBar = styled.div`
  background: ${colors.fdy_gray[800]};
  display: flex;
  flex-shrink: 0; // prevent shrinking horizontally since this elem's parent is a flexbox
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-inline: ${theme.space(2)};
  position: relative;
  overflow-y: auto;
  z-index: 1;
  width: 15rem;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

interface NavigationBarProps {
  account: { name: string };
}

export function NavigationBar({ account }: NavigationBarProps): ReactElement {
  return (
    <AnalyticsStack value="nav">
      <StyledNavigationBar>
        <CurrentAccount account={account} />
        <ProductNav />
        <DataNav />
        <PredictionsNav />
        <Spacer aria-hidden />
        <AccountNav />
      </StyledNavigationBar>
    </AnalyticsStack>
  );
}
