import {
  forwardRef,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";

export interface InputProps extends ChakraInputProps {
  // these are omitted from InputProps
  disabled?: boolean;
  required?: boolean;
  // this is needed for Heap analytics tagging
  analyticsName?: string;
}

export const Input = forwardRef<InputProps, "input">(
  ({ analyticsName, type, ...props }, ref) => {
    const analyticsKey = useAnalyticsKey(analyticsName);

    const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
      if (type === "number") {
        event.currentTarget.blur();
      }
    };

    return (
      <ChakraInput
        ref={ref}
        type={type}
        onWheel={handleWheel}
        {...props}
        {...analyticsAttrs(analyticsKey)}
      />
    );
  }
);
