import { Box, Container } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { DocsLink } from "../../ui/DocsLink";
import { LinkButton } from "../../ui/LinkButton";
import { PageHeader } from "../../ui/PageHeader";
import { PageLayout } from "../../ui/PageLayout";
import { UsageWarningNotice } from "../../ui/UsageWarningNotice";
import { ConnectionsTable } from "./ConnectionsTable";

/**
 * Renders the overall Connections list page with breadcrumbs and a table of connections.
 */
export function ConnectionsListPage() {
  const headerCta = (
    <Box display="flex" gap={2}>
      <DocsLink resourceType="connections" />
      <LinkButton
        routeName={ROUTE_NAMES.CONNECTIONS_NEW}
        leftIcon={<Plus weight="bold" />}
        analyticsName="new-connection"
      >
        New Connection
      </LinkButton>
    </Box>
  );

  return (
    <PageLayout analyticsStackName="connection-list">
      <PageHeader
        title="Connections"
        rightContent={headerCta}
        crumbs={[
          {
            label: "Connections",
            routeName: ROUTE_NAMES.CONNECTIONS,
          },
        ]}
      />
      <Container size={"fdy_lg"} py={6}>
        <UsageWarningNotice resource="connections" />
        <ConnectionsTable />
      </Container>
    </PageLayout>
  );
}
