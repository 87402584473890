import React, { useMemo, useState } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerQueryWithAliasSubstitution } from "../../../services/sojournerApolloClient";
import {
  MERGE_CONNECTION_TYPES,
  PUBLICATION_CONNECTION_TYPES,
} from "../../pipelines/connectionUtils";
import { ResourceTableList } from "../../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../../ui/ResourceTableTabs";
import { SortableHeader, SortableTable } from "../../ui/SortableTable";
import {
  ConnectionsTableQuery,
  ConnectionsTableQuery_connections as Connection,
} from "../__generated__/ConnectionsTableQuery";
import { connectionsQuery } from "../connectionsQuery";
import { ConnectionTableRow } from "./ConnectionsTableRow";

const BLANK_SLATE_PROPS = {
  title: "No Connections found",
  text: "Connections are bidirectional, used for both Datasets and Deployments (part of Pipelines).",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children:
      "If you are uploading CSVs, you can use Datasets without a Connection.",
  },
};
const tableHeaders: SortableHeader<Connection>[] = [
  {
    key: "name",
    label: "Name",
  },
  {
    label: "Datasets",
  },
  {
    label: "Targets",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "createdAt",
    label: "Created",
    sortingFn: "date",
  },
  {
    key: "lastUpdatedConfigAt",
    label: "Modified",
    sortingFn: "date",
  },
  {
    key: "lastUpdatedOutputAt",
    label: "Processed",
    sortingFn: "date",
  },
  {
    label: "Actions",
    width: 64,
    visuallyHidden: true,
  },
];

/**
 * Render list of account's connections with various meta data,
 * error state indicator, a switch to toggle preview mode, and actions menu.
 */
export function ConnectionsTable() {
  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);

  const { data, loading, error } =
    useSojournerQueryWithAliasSubstitution<ConnectionsTableQuery>(
      connectionsQuery
    );

  const connections = useMemo(() => {
    const filteredByTab = filterResourceTabs(data?.connections, activeTab);

    // hide publication and merge connections from UI
    return filteredByTab.filter((c) => {
      const isPublication = PUBLICATION_CONNECTION_TYPES.some(
        (ct) => ct.slug === c.options.type
      );
      const isMerge = MERGE_CONNECTION_TYPES.some(
        (ct) => ct.slug === c.options.type
      );

      return !isPublication || !isMerge;
    });
  }, [data, activeTab]);

  const renderConnection = (connection: Connection) => {
    return <ConnectionTableRow connection={connection} />;
  };

  return (
    <>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data?.connections}
      />
      <SortableTable
        storageKey="connections"
        defaultSort={{
          key: "createdAt",
          desc: true,
        }}
        data={connections}
        headers={tableHeaders}
        renderData={(data) => (
          <ResourceTableList<Connection>
            loading={loading}
            data={data}
            error={error}
            renderData={renderConnection}
            blankSlateProps={BLANK_SLATE_PROPS}
          />
        )}
      />
    </>
  );
}
