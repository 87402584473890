/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The type of column on households the field interacts with.
 * 
 * These values are straight from the database as is. Perhaps we lowercase them in the field resolver
 * to be compatible with alchemist field `type`.
 * 
 * ARRAY<STRING> also exists but not used yet and is clearly difficult to enum-ize,
 * so kick the can for now.
 */
export enum FdyFieldType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATETIME = "DATETIME",
  FLOAT64 = "FLOAT64",
  GEOGRAPHY = "GEOGRAPHY",
  INT64 = "INT64",
  STRING = "STRING",
}

/**
 * The type of a field for an account.
 */
export enum FieldFilterType {
  boolean = "boolean",
  categorical = "categorical",
  geography = "geography",
  geography_mode = "geography_mode",
  multicategorical = "multicategorical",
  range = "range",
  tristate = "tristate",
}

export enum Product {
  consoles = "consoles",
  explore = "explore",
  inform = "inform",
  monitor = "monitor",
  optimize = "optimize",
  reach = "reach",
  shopify = "shopify",
}

export enum SegmentSubtype {
  audience = "audience",
  baseline = "baseline",
  cohort = "cohort",
  national = "national",
  top_1 = "top_1",
  top_10 = "top_10",
  top_5 = "top_5",
}

export enum SegmentType {
  Audience = "Audience",
  Cohort = "Cohort",
  National = "National",
  Outcome = "Outcome",
  Persona = "Persona",
}

export enum UpdateAccountSetupStatus {
  onboarded = "onboarded",
}

/**
 * Input value for inviting a new user to the current user's account.
 */
export interface InvitedUser {
  email: string;
  name?: string | null;
}

/**
 * Input to update a roster.
 * 
 * Most things are optional, and you should just specify the keys you want to
 * update.
 */
export interface RosterUpdateInput {
  analysis_fields?: string[] | null;
  name?: string | null;
}

export interface SegmentInfo {
  target_id?: FdyUUID | null;
  target_type?: SegmentType | null;
  subtype?: SegmentSubtype | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
