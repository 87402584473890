import { CohortStreamConditionInput } from "../../../../__generated__/sojournerGlobalTypes";
import { components } from "../../../../sojourner-oas-types";
import { CohortFilterEditor } from "../CohortFilterEditor";
import {
  BooleanFilterState,
  booleanFilterToWire,
} from "../CohortFilterEditor/CohortFilterEditorBoolean";
import { conditionsFromWire } from "../utils";
import { streamConditionsToWire } from "./CohortEventProperties";

export interface CohortStreamPropertyEditorProps {
  streamProperty: components["schemas"]["StreamPropertyDetails"];
  streamPropertyName: string;
  condition: CohortStreamConditionInput;
  onChange: (prevState: CohortStreamConditionInput) => void;
}

/**
 * Given StreamPropertyDetails, present the relevant filtering option for the
 * stream properties data types.
 */
export function CohortStreamPropertyEditor(
  props: CohortStreamPropertyEditorProps
) {
  return (
    <CohortFilterEditor<CohortStreamConditionInput>
      filterName={props.streamPropertyName}
      filterProperties={props.streamProperty}
      condition={props.condition}
      onChange={props.onChange}
      conditionsToWire={streamConditionsToWire}
      conditionsFromWire={conditionsFromWire}
      booleanConditionToWire={booleanStreamPropertyToWire}
    />
  );
}

/**
 * Convert state of checkboxes UI to boolean cohort stream property condition
 */
function booleanStreamPropertyToWire(
  propertyName: string,
  val: BooleanFilterState
): CohortStreamConditionInput {
  const streamCondition: CohortStreamConditionInput = {
    property: propertyName,
  };

  return booleanFilterToWire(streamCondition, val);
}
