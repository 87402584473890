type ValueOf<T> = T[keyof T];
type Entries<T> = [keyof T, ValueOf<T>][];

/**
 * Roughly typesafe Object.entries
 *
 * Warning: There's a possibility that your object passed could have more keys
 * than those defined on the type, resulting it to be not fully type-safe.
 *
 * Source: https://stackoverflow.com/questions/60141960/typescript-key-value-relation-preserving-object-entries-type
 */
export function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}
