import { Td, Tr } from "@chakra-ui/react";
import { Recommender } from "@fdy/faraday-js";
import { useMemo, useState } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useRecommenderListQuery } from "../../hooks/api";
import { ResourceStatusBadge } from "../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTableList } from "../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../ui/ResourceTableTabs";
import { RouterLink } from "../ui/RouterLink";
import {
  SortableHeader,
  SortableTable,
} from "../ui/SortableTable/SortableTable";
import { TimeAgo } from "../ui/TimeAgo";
import { RecommenderActionsMenu } from "./RecommenderActionsMenu";

const BLANK_SLATE_PROPS = {
  title: "No recommenders found",
  text: "Recommenders are based on Event Stream properties, which are emitted by Datasets. They are used to create Pipelines.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Start by adding a Dataset if you haven't already.",
  },
};

const tableHeaders: SortableHeader<Recommender>[] = [
  {
    key: "name",
    label: "Name",
    sortingFn: "string",
    width: 200,
  },
  {
    key: "stream_name",
    label: "Event",
    sortingFn: "string",
  },
  {
    key: "stream_property_name",
    label: "Property",
    sortingFn: "string",
  },
  {
    key: "status",
    label: "Status",
    sortingFn: "string",
  },
  {
    key: "created_at",
    label: "Created",
    sortingFn: "date",
  },
  {
    key: "last_updated_config_at",
    label: "Modified",
    sortingFn: "date",
  },
  {
    key: "last_updated_output_at",
    label: "Processed",
    sortingFn: "date",
  },
  {
    label: "Actions",
    width: 24,
    visuallyHidden: true,
  },
];

function RecommendersTableRow({ recommender }: { recommender: Recommender }) {
  return (
    <Tr>
      <Td>
        <RouterLink
          routeName={ROUTE_NAMES.RECOMMENDERS_SHOW}
          params={{ id: recommender.id }}
          fontWeight="bold"
        >
          {recommender.name}
        </RouterLink>
      </Td>
      <Td>{recommender.stream_name}</Td>
      <Td>{recommender.stream_property_name}</Td>
      <Td>
        <ResourceStatusBadge resource={recommender} />
      </Td>
      <Td>
        <TimeAgo date={recommender.created_at} />
      </Td>
      <Td>
        <TimeAgo date={recommender.last_updated_config_at} />
      </Td>
      <Td>
        <TimeAgo date={recommender.last_updated_output_at} />
      </Td>
      <Td>
        <RecommenderActionsMenu
          recommender={recommender}
          options={{
            view: true,
            archive: true,
          }}
          buttonProps={{ variant: "icon", my: -2 }}
        />
      </Td>
    </Tr>
  );
}

function RecommendersTableRows({
  recommenders,
}: {
  recommenders: Recommender[];
}) {
  const renderRow = (recommender: Recommender) => {
    return <RecommendersTableRow recommender={recommender} />;
  };

  return (
    <ResourceTableList<Recommender>
      data={recommenders}
      renderData={renderRow}
      blankSlateProps={BLANK_SLATE_PROPS}
    />
  );
}

export function RecommendersTable() {
  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);

  const { data } = useRecommenderListQuery();

  const filteredRecommenders = useMemo(
    () => filterResourceTabs(data, activeTab),
    [data, activeTab]
  );

  return (
    <>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data}
      />
      <SortableTable
        storageKey="recommenders"
        defaultSort={{
          key: "created_at",
          desc: true,
        }}
        data={filteredRecommenders}
        headers={tableHeaders}
        renderData={(data) => <RecommendersTableRows recommenders={data} />}
      />
    </>
  );
}
