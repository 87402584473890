import { ApolloError } from "@apollo/client";
import React, { useMemo, useState } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { ResourceTableList } from "../../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../../ui/ResourceTableTabs";
import { SortableHeader, SortableTable } from "../../ui/SortableTable";
import {
  DatasetsTableQuery_connections,
  DatasetsTableQuery_datasets as Dataset,
} from "../__generated__/DatasetsTableQuery";
import { DatasetTableRow } from "./DatasetsTableRow";
import { datasetTooltips } from "./datasetTooltips";

const BLANK_SLATE_PROPS = {
  title: "No datasets found",
  text: "Datasets let you pull data into Faraday for use in predictions. You can upload CSVs or add data from a Connection.",
  button: {
    routeName: ROUTE_NAMES.CONNECTIONS,
    children:
      "If you are not uploading a CSV, use Connections to add an external data system.",
  },
};

/**
 * Render list of account's datasets with various meta data,
 */
export function DatasetsTable({
  datasets,
  connections,
  loading,
  error,
}: {
  datasets: Dataset[] | undefined;
  connections: DatasetsTableQuery_connections[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}) {
  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);

  const filteredDatasets = useMemo(
    () => filterResourceTabs(datasets, activeTab),
    [datasets, activeTab]
  );

  const renderDatasetRow = (dataset: Dataset) => {
    return (
      <DatasetTableRow dataset={dataset} connections={connections ?? []} />
    );
  };

  const hoverNote = " (Hover over totals for a breakdown)";
  const tableHeaders: SortableHeader<Dataset>[] = [
    {
      key: "name",
      label: "Name",
    },
    {
      label: "Connection",
    },
    {
      label: "Count",
      tooltipText: datasetTooltips.count + hoverNote,
    },
    {
      label: "Output",
      tooltipText: datasetTooltips.output + hoverNote,
    },
    {
      label: "Enrichment",
      tooltipText: datasetTooltips.enrichment,
    },
    {
      key: "status",
      label: "Status",
      sortingFn: "string",
    },
    {
      key: "createdAt",
      label: "Created",
      sortingFn: "date",
    },
    {
      key: "lastUpdatedConfigAt",
      label: "Modified",
      sortingFn: "date",
    },
    {
      key: "lastUpdatedOutputAt",
      label: "Processed",
      sortingFn: "date",
    },
    {
      label: "Actions",
      visuallyHidden: true,
    },
  ];

  return (
    <>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={datasets}
      />
      <SortableTable
        storageKey="datasets"
        defaultSort={{
          key: "createdAt",
          desc: true,
        }}
        data={filteredDatasets}
        headers={tableHeaders}
        renderData={(data) => (
          <ResourceTableList<Dataset>
            loading={loading}
            error={error}
            data={data}
            renderData={renderDatasetRow}
            blankSlateProps={BLANK_SLATE_PROPS}
          />
        )}
      />
    </>
  );
}
