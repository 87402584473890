import { CohortTraitInput } from "../../../../__generated__/sojournerGlobalTypes";
import { Trait } from "../../../../hooks/useTraitsQuery";
import { assertNonEmptyArray } from "../../../../utils/assertNonEmptyArray";
import { conditionToSummary } from "../../../../utils/conditionToSummary";
import { exists } from "../../../../utils/exists";
import { ConditionRow } from "../../../ui/ConditionsBuilder";
import {
  BooleanFilterState,
  booleanFilterToWire,
} from "../CohortFilterEditor/CohortFilterEditorBoolean";
import { ConditionDefintion, conditionsFromWire } from "../utils";

/**
 * Convert state of checkboxes UI to boolean cohort trait condition
 */
export function booleanTraitToWire(
  propertyName: string,
  val: BooleanFilterState
): CohortTraitInput {
  const cohortTrait: CohortTraitInput = {
    name: propertyName,
  };

  return booleanFilterToWire(cohortTrait, val);
}

/** Transform the database/modeling version to the human friendly version. */
function applyLookupTableToValue(
  value: string | null,
  lookupTable: SojJSON | null
): string | null {
  if (!lookupTable) return value;

  for (const [key, val] of Object.entries(lookupTable)) {
    if (value === key) return val as string;
  }

  return value;
}

/** Transform the user friendly version to the database/modeling version. */
function removeLookupTableFromValue(
  value: string,
  lookupTable: SojJSON | null
): string {
  if (!lookupTable) return value;

  for (const [key, val] of Object.entries(lookupTable)) {
    if (value === val) return key;
  }

  return value;
}

/** Given a condition and a trait, apply a lookup table if it exists. */
function applyLookupTableToCondition(trait: Trait, c: ConditionDefintion) {
  if (!trait.lookupTable) return c;

  const condition = { ...c };

  if (exists(c.eq)) {
    condition.eq = applyLookupTableToValue(c.eq, trait.lookupTable);
  }

  if (exists(c.in) && assertNonEmptyArray(c.in)) {
    condition.in = c.in.map((v) =>
      applyLookupTableToValue(v, trait.lookupTable)
    );
  }

  if (exists(c.nin) && assertNonEmptyArray(c.nin)) {
    condition.nin = c.nin.map((v) =>
      applyLookupTableToValue(v, trait.lookupTable)
    );
  }

  return condition;
}
/** Given a fdy field condition and a trait, transform it to the shape
 * that we need in the UI. Note that we can have many ConditionRows from
 * one ConditionDefinition because each ConditionDefinition can have multiple
 * operators populated.
 */
export function traitConditionsFromWire(
  trait: Trait,
  condition: ConditionDefintion
): ConditionRow[] {
  if (trait.lookupTable) {
    return conditionsFromWire(applyLookupTableToCondition(trait, condition));
  }
  return conditionsFromWire(condition);
}

/** Given out trait conditions defined, transform into wire format
 *  for the database, reversing the lookup table if needed.
 */
export function traitConditionsToWire(
  trait: Trait,
  condition: CohortTraitInput,
  conditionList: ConditionRow[]
): CohortTraitInput {
  const newCondition: CohortTraitInput = {
    name: condition.name,
    optional: condition.optional,
  };

  conditionList.forEach((cond) => {
    if (!cond.operator || cond.value === null || cond.value === undefined) {
      return;
    }

    // Cohort conditions don't support neq, but we use it as a workaround to
    // produce eq: false for some condition builders in target filters, so need to skip it here.
    if (cond.operator === "neq") return;

    switch (cond.type) {
      case "boolean":
        newCondition[cond.operator] = cond.value;
        break;
      case "list":
        newCondition[cond.operator] = cond.value.map((v) =>
          removeLookupTableFromValue(v, trait.lookupTable)
        );
        break;
      case "numeric":
        newCondition[cond.operator] = cond.value;
        break;
      case "text":
        newCondition[cond.operator] = removeLookupTableFromValue(
          cond.value,
          trait.lookupTable
        );
        break;
    }
  });

  return newCondition;
}

export function traitConditionToSummary(
  trait: Trait,
  condition: ConditionDefintion
) {
  if (trait.lookupTable) {
    return conditionToSummary(applyLookupTableToCondition(trait, condition));
  }
  return conditionToSummary(condition);
}

/**
 * Naively validate that trait input has at least one value set.
 */
export function traitHasAtLeastOneOperator(condition: CohortTraitInput) {
  const needOneOfKeys = [
    "eq",
    "matches",
    "nnull",
    "null",
    "in",
    "nin",
    "gt",
    "gte",
    "lt",
    "lte",
  ];

  // validate that at least one operator is set
  return needOneOfKeys.some((key) =>
    exists(condition[key as keyof CohortTraitInput])
  );
}
