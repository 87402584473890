import { ReactElement } from "react";

import {
  CohortStreamConditionInput,
  CohortTraitInput,
} from "../../../../__generated__/sojournerGlobalTypes";
import {
  ConditionRow,
  ConditionsBuilder,
  OPERATOR_LABELS,
} from "../../../ui/ConditionsBuilder";
import { CohortFilterEditorProps } from ".";

export function CohortFilterEditorCategorical<
  CohortConditionInput extends CohortTraitInput | CohortStreamConditionInput
>({
  filterProperties,
  condition,
  onChange,
  conditionsToWire,
  conditionsFromWire,
}: CohortFilterEditorProps<CohortConditionInput>): ReactElement {
  function handleChange(conditions: ConditionRow[]) {
    onChange(conditionsToWire(condition, conditions));
  }

  // TODO: sort?
  const picklist = filterProperties.categories
    ? filterProperties.categories.map((v) => ({
        value: v,
        label: v,
      }))
    : [];

  const init = conditionsFromWire(condition);

  // show picklist as default condition
  if (init.length === 0) {
    if (picklist.length) {
      init.push({
        type: "list",
        operator: "in",
        value: null,
      });
    } else {
      init.push({
        type: "boolean",
        operator: "nnull",
        value: true,
      });
    }
  }

  return (
    <ConditionsBuilder
      virtualized={true}
      initialConditions={init}
      onChange={handleChange}
      operators={[
        {
          label: OPERATOR_LABELS.nnull,
          operator: "nnull",
        },
        {
          label: "Select exact values from list",
          operator: "in",
          picklist,
        },
        {
          label: "Exclude exact values from list",
          operator: "nin",
          picklist,
        },
        {
          label: OPERATOR_LABELS.eq,
          operator: "eq",
        },
        {
          label: OPERATOR_LABELS.null,
          operator: "null",
        },
        {
          label: OPERATOR_LABELS.matches,
          operator: "matches",
        },
      ]}
      lockedRows
    />
  );
}
