import { ReactElement } from "react";
import { useMeasure } from "react-use";

import { theme } from "../../../constants/theme";
import { percent as toPercentString } from "../../../utils/formatters";
import { getLabelCoords, transitionStyles } from "./chartUtils";
import { AnalysisDataset } from "./types";

const CHART_HEIGHT = 64;
const BAR_HEIGHT = 8;

const Bar = (props: {
  height: number;
  width: number;
  fill: string;
  animate?: boolean;
}) => {
  return (
    <rect
      width={props.width + 16}
      height={props.height}
      x={-16}
      y={0}
      fill={props.fill}
      style={props.animate ? transitionStyles : undefined}
    />
  );
};

interface PercentChartProps {
  color: string;
  data: AnalysisDataset;
}

export function PercentChart({
  color,
  data,
}: PercentChartProps): ReactElement | null {
  const [ref, { width, left, right }] = useMeasure();
  // The typedef for useMeasure's ref param is slightly wrong. Shim it until
  // it can be fixed. see: <https://github.com/streamich/react-use/issues/1264>
  const refShim = (el: HTMLElement | null) => {
    if (el) ref(el);
  };

  const percent = Math.min(1, data.true_rate);

  const barWidth = width * percent;
  const vCenter = CHART_HEIGHT / 2 - BAR_HEIGHT / 2;

  const textX = barWidth;
  const textY = vCenter;

  const label = toPercentString(percent);

  const { textAnchor, overlap } = getLabelCoords({
    x: textX,
    label,
    left,
    right,
  });

  // nudge the label if it would be flush with an edge
  const offsetTextX = {
    left: textX + 4,
    right: textX - 4,
    none: textX,
  }[overlap];

  return (
    <div ref={refShim}>
      <svg
        width={width}
        height={CHART_HEIGHT}
        viewBox={`0 0 ${width} ${CHART_HEIGHT}`}
      >
        <g transform={`translate(0, ${vCenter})`}>
          <Bar
            height={BAR_HEIGHT}
            width={width}
            fill={theme.colors.lighter_gray}
          />
          <Bar height={BAR_HEIGHT} width={barWidth} fill={color} animate />
          {/* g used to support transitions in safari */}
          <g
            style={{
              ...transitionStyles,
              transform: `translate(${offsetTextX}px,0)`,
            }}
          >
            <text
              y={textY}
              textAnchor={textAnchor}
              style={{
                fontWeight: 600,
                fontSize: 14,
                color: theme.colors.dark_gray,
              }}
            >
              {label}
            </text>
          </g>
        </g>
      </svg>
    </div>
  );
}
