// we need to make a version of Chakra select which accepts analyticsName similar to Button.tsx

import {
  forwardRef,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from "@chakra-ui/react";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";

export interface SelectProps extends ChakraSelectProps {
  required?: boolean;
  disabled?: boolean;

  analyticsName?: string;
}

/**
 * A wrapper around Chakra's Select component that adds analytics tracking.
 */
export const Select = forwardRef<SelectProps, "select">(
  ({ analyticsName, ...props }, ref) => {
    const analyticsKey = useAnalyticsKey(analyticsName);
    return (
      <ChakraSelect ref={ref} {...props} {...analyticsAttrs(analyticsKey)} />
    );
  }
);
