import {
  CohortStreamConditionInput,
  RecencyOccurrence,
} from "../../../../__generated__/sojournerGlobalTypes";
import { conditionToSummary } from "../../../../utils/conditionToSummary";
import { exists } from "../../../../utils/exists";
import { EventState } from "../CohortForm";
import { CohortSummaryTagsSection } from "../CohortSummaryTagsSection";

export function CohortEventSummary({
  event: {
    streamName,
    streamConditions,
    occurrence,
    minCount,
    maxCount,
    maxDays,
    minDays,
    minValue,
    maxValue,
  },
  showStreamName,
}: {
  event: Partial<EventState>;
  showStreamName?: boolean;
}) {
  const result = [];

  if (streamName && showStreamName) {
    result.push(streamName);
  }

  if (occurrence === RecencyOccurrence.FIRST) {
    result.push("Only the first event");
  } else if (occurrence === RecencyOccurrence.LAST) {
    result.push("Only the last event");
  }

  if (exists(minDays) && exists(maxDays)) {
    result.push(`Occurred ${minDays}-${maxDays} days`);
  } else if (exists(minDays)) {
    result.push(`Occurred at least ${minDays} days ago`);
  } else if (exists(maxDays)) {
    result.push(`Occurred at most ${maxDays} days ago`);
  }

  if (exists(minCount) && exists(maxCount)) {
    result.push(`Happened ${minCount}-${maxCount} times`);
  } else if (exists(minCount)) {
    result.push(`Happened at least ${minCount} times`);
  } else if (exists(maxCount)) {
    result.push(`Happened at most ${maxCount} times`);
  }

  if (exists(minValue) && exists(maxValue)) {
    result.push(`Lifetime value ${minValue}-${maxValue}`);
  } else if (exists(minValue)) {
    result.push(`Lifetime value at least ${minValue}`);
  } else if (exists(maxValue)) {
    result.push(`Lifetime value at most ${maxValue}`);
  }

  if (streamConditions) {
    result.push(...getStreamConditionsSummary(streamConditions));
  }

  return <CohortSummaryTagsSection tags={result} />;
}

/** Giving a list of stream conditions from the wire, return a
 * list of short descriptor strings to summarize each condition.
 */
function getStreamConditionsSummary(
  streamConditions: CohortStreamConditionInput[]
): string[] {
  const result: string[] = [];
  const groupedConditions = streamConditions.reduce<{
    optional: Record<string, string[]>;
    required: Record<string, string[]>;
  }>(
    (map, condition) => {
      if (condition.optional) {
        if (map.optional[condition.property]) {
          map.optional[condition.property].push(
            ...conditionToSummary(condition)
          );
        } else {
          map.optional[condition.property] = conditionToSummary(condition);
        }
      } else {
        if (map.required[condition.property]) {
          map.required[condition.property].push(
            ...conditionToSummary(condition)
          );
        } else {
          map.required[condition.property] = conditionToSummary(condition);
        }
      }

      return map;
    },
    { optional: {}, required: {} }
  );

  Object.entries(groupedConditions.required).forEach(([name, conditions]) => {
    result.push(`${name} ${conditions.join(" and ")}`);
  });

  Object.entries(groupedConditions.optional).forEach(([name, conditions]) => {
    result.push(`${name} ${conditions.join(" or ")}`);
  });

  return result;
}
