import { gql, QueryHookOptions } from "@apollo/client";

import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import {
  ScopePayloadQuery,
  ScopePayloadQuery_scope,
  ScopePayloadQueryVariables,
} from "../__generated__/ScopePayloadQuery";
import {
  ScopePopulationQuery,
  ScopePopulationQuery_scope,
  ScopePopulationQueryVariables,
} from "../__generated__/ScopePopulationQuery";
import { SCOPE_PAYLOAD_QUERY } from "../ScopePayloadBreakdown";
import { SCOPE_POPULATION_QUERY } from "../ScopePopulationBreakdown";
import {
  ScopeDatasetsQuery,
  ScopeDatasetsQuery_scopeDatasets,
  ScopeDatasetsQueryVariables,
} from "./__generated__/ScopeDatasetsQuery";

export type ScopeDataset = ScopeDatasetsQuery_scopeDatasets;

export const SCOPE_DATASETS_QUERY = gql`
  query ScopeDatasetsQuery($id: ID!) {
    scopeDatasets(scopeId: $id) {
      id
      name
      managed
      detectedColumns {
        name
      }
      referenceKeyColumn
    }
  }
`;

export type ScopeDependencies = Pick<
  ScopePayloadQuery_scope & ScopePopulationQuery_scope,
  | "payload"
  | "payloadCohorts"
  | "payloadPersonaSets"
  | "payloadOutcomes"
  | "payloadRecommenders"
  | "populationCohorts"
  | "populationExclusionCohorts"
> &
  ScopeDatasetsQuery & {
    loading: boolean;
  };

type ScopeDependencyVars = ScopePayloadQueryVariables &
  ScopePopulationQueryVariables &
  ScopeDatasetsQueryVariables;

// These are separate queries instead of 1 large gql query for a few reasons:
// - the same queries are used in other components, so we can rely on apollo query deduping and caching
// - we can migrate to react-query in the future easily
export function useScopeDependencies(
  scope: PipelineFragment
): ScopeDependencies {
  const variables: ScopeDependencyVars = {
    id: scope.id,
  };

  const options: QueryHookOptions = {
    variables,
  };

  const payloadQuery = useSojournerQuery<ScopePayloadQuery>(
    SCOPE_PAYLOAD_QUERY,
    options
  );
  const populationQuery = useSojournerQuery<ScopePopulationQuery>(
    SCOPE_POPULATION_QUERY,
    options
  );
  const datasetsQuery = useSojournerQuery<ScopeDatasetsQuery>(
    SCOPE_DATASETS_QUERY,
    options
  );

  const {
    payload,
    payloadCohorts,
    payloadOutcomes,
    payloadPersonaSets,
    payloadRecommenders,
  } = payloadQuery.data?.scope ?? {};

  const { populationCohorts, populationExclusionCohorts } =
    populationQuery.data?.scope ?? {};

  return {
    loading:
      // checking for data is required because apollo will return loading=true
      // when pulling from cache, even if the data is already there.
      (payloadQuery.loading && !payloadQuery.data) ||
      (populationQuery.loading && !populationQuery.data) ||
      (datasetsQuery.loading && !datasetsQuery.data),

    payload: payload ?? {
      __typename: "ScopePayload",
      attributes: [],
      explainability: false,
    },
    payloadCohorts: payloadCohorts ?? [],
    payloadPersonaSets: payloadPersonaSets ?? [],
    payloadOutcomes: payloadOutcomes ?? [],
    payloadRecommenders: payloadRecommenders ?? [],
    populationCohorts: populationCohorts ?? [],
    populationExclusionCohorts: populationExclusionCohorts ?? [],
    scopeDatasets: datasetsQuery.data?.scopeDatasets ?? [],
  };
}
