import { gql } from "@apollo/client";
import { Flex, Select, Text } from "@chakra-ui/react";
import { useState } from "react";

import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { isoDate } from "../../../utils/formatters";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { LineChart } from "../../ui/charts-v2/LineChart";
import {
  CohortsAnalysisPageQuery,
  CohortsAnalysisPageQuery_analysis,
  CohortsAnalysisPageQuery_analysis_months,
  CohortsAnalysisPageQueryVariables,
} from "./__generated__/CohortsAnalysisPageQuery";

const COHORT_ANALYSIS_QUERY = gql`
  query CohortsAnalysisPageQuery($id: ID!) {
    analysis: cohortAnalysisMembership(cohortId: $id) {
      days {
        date
        count
      }
      weeks {
        date
        count
      }
      months {
        date
        count
      }
    }
  }
`;

type CohortsAnalysisPageQuery_analysis_extended =
  CohortsAnalysisPageQuery_analysis & {
    years: (CohortsAnalysisPageQuery_analysis_months | null)[];
  };

enum CohortMembershipTimeframe {
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Years = "years",
}

enum GraphType {
  Cumulative = "total membership",
  ChangeOverTime = "change over time",
}

function filterNullItems<T>(items: (T | null)[]): T[] {
  return items.filter((item): item is T => item !== null);
}

function getDelta(
  input: CohortsAnalysisPageQuery_analysis_extended
): CohortsAnalysisPageQuery_analysis_extended {
  const output = Object.entries(input).reduce(
    (currentOutput, [timeframe, data]) => {
      if (timeframe === "__typename") return currentOutput;
      // we don't know what was the count before the first bin
      // so set the delta for the first bin to 0
      let previousCount: number | null = null;
      const newData: CohortsAnalysisPageQuery_analysis_months[] = [];
      data.forEach((pointInTime: CohortsAnalysisPageQuery_analysis_months) => {
        // each item in the array is currentValue - prevValue
        // but for the first item, we have no prevValue
        // so remove it from the final result
        if (previousCount === null) {
          previousCount = pointInTime.count;
          return currentOutput;
        }
        newData.push({
          __typename: "CohortAnalysisMembershipDatum",
          date: pointInTime.date,
          count: pointInTime.count - previousCount,
        });
        previousCount = pointInTime.count;
      });
      currentOutput[timeframe as CohortMembershipTimeframe] = newData;
      return currentOutput;
    },
    {} as CohortsAnalysisPageQuery_analysis_extended
  );
  return output;
}

/**
 * Fetches cohort membeship analysis data and renders the cohort membership over time chart.
 */
export function CohortMembershipChart({ cohortId }: { cohortId: string }) {
  const [timeframe, setTimeframe] = useState<CohortMembershipTimeframe>(
    CohortMembershipTimeframe.Months
  );
  const [graphType, setGraphType] = useState<GraphType>(GraphType.Cumulative);

  const { data, loading, error } = useSojournerQuery<
    CohortsAnalysisPageQuery,
    CohortsAnalysisPageQueryVariables
  >(COHORT_ANALYSIS_QUERY, {
    variables: {
      id: cohortId,
    },
  });

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  if (!data?.analysis) {
    return <p>Analysis not found</p>;
  }

  // default "months" analysis is 36 months long
  // make a shorter version available to the user too
  const analysis = {
    ...data.analysis,
    years: data.analysis.months,
    months: data.analysis.months.slice(-12),
  } as CohortsAnalysisPageQuery_analysis_extended;

  const analysisDelta = getDelta(analysis);

  const counts = {
    days: analysis.days.length,
    weeks: analysis.weeks.length,
    months: analysis.months.length,
    years: analysis.years.length / 12, // year analysis is split by month
  };

  // TODO: return an empty state for when cohort is not based on a datetime event?
  const timeframeData = filterNullItems(
    graphType === GraphType.Cumulative
      ? analysis[timeframe]
      : analysisDelta[timeframe]
  );

  if (!timeframeData || timeframeData.length === 0) {
    return <p>No cohort membership data available.</p>;
  }

  const chartData = timeframeData.map((d) => ({
    x: isoDate(d.date),
    y: d.count,
  }));

  return (
    <div>
      <Flex gap={2} my={2}>
        <Select
          aria-label="Timeframe"
          value={timeframe}
          onChange={(e) => {
            setTimeframe(e.target.value as CohortMembershipTimeframe);
          }}
        >
          {Object.values(CohortMembershipTimeframe).map((timeframe) => (
            <option key={timeframe} value={timeframe}>
              {counts[timeframe]} {timeframe}
            </option>
          ))}
        </Select>
        <Select
          aria-label="Graph type"
          value={graphType}
          onChange={(e) => {
            setGraphType(e.target.value as GraphType);
          }}
        >
          {Object.values(GraphType).map((graphType) => (
            <option key={graphType} value={graphType}>
              {graphType}
            </option>
          ))}
        </Select>
      </Flex>

      <LineChart
        title="Cohort membership over time"
        yLabel="Population size"
        data={chartData}
        renderTooltip={({ tooltipData }) => {
          if (!tooltipData?.nearestDatum?.key) {
            return null;
          }

          const { x, y } = tooltipData.nearestDatum.datum;

          return (
            <div>
              <Text fontWeight="normal" mb={2}>
                {x.toDateString()}
              </Text>
              <Text fontSize="fdy_xl" fontWeight="semibold" mb={2}>
                {y.toLocaleString()}
              </Text>
            </div>
          );
        }}
      />
    </div>
  );
}
