import styled from "@emotion/styled";
import { Icon, IconProps } from "@phosphor-icons/react";
import { MouseEvent, ReactElement } from "react";

import { theme } from "../../constants/theme";
import chakraThemeV2, { colors } from "../../styles/chakra-theme-v2";
import {
  analyticsAttrs,
  useAnalyticsKey,
} from "../ui/Analytics/AnalyticsStack";
import { Link } from "../ui/Link";

const iconProps: IconProps = {
  size: 20,
};

const StyledNavigationBarOption = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 ${theme.space()};
  height: ${theme.space(4)};
  border-radius: 6px;
  transition: background-color ${theme.durations.quick};
  color: ${colors.fdy_gray[200]};
  text-decoration: none;
  font-family: ${chakraThemeV2.fonts.body};
  font-size: ${chakraThemeV2.fontSizes.fdy_sm};
  width: 100%;

  &:focus,
  &:hover {
    outline: none;
    background-color: ${colors.fdy_gray[900]};
  }

  &.active {
    background-color: ${colors.fdy_gray[900]};
    color: ${colors.fdy_purple[200]};
    font-weight: 700;
  }
`;

const StyledNavigationBarIcon = styled.div`
  svg {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    fill: currentColor;
  }
`;

const StyledNavigationBarLabel = styled.div`
  margin-left: ${theme.space(2)};
  white-space: nowrap;
`;

interface NavigationBarLinkProps {
  className?: string;
  icon: Icon;
  label: string;
  routeName: string;
  routeParams?: Record<string, string>;
  analyticsName?: string;
}

/**
 * A link to various sections of the app. Should look more-or-less like the
 * other navigation bar options available.
 */
export const NavigationBarLink = (
  props: NavigationBarLinkProps
): ReactElement => {
  const {
    className,
    icon: Icon,
    label,
    routeName,
    routeParams,
    analyticsName,
  } = props;

  return (
    <StyledNavigationBarOption
      as={(props) => (
        <Link
          {...props}
          activeClassName="active"
          routeName={routeName}
          params={routeParams}
          analyticsName={analyticsName}
        />
      )}
      className={className}
    >
      <StyledNavigationBarIcon>
        <Icon {...iconProps} />
      </StyledNavigationBarIcon>
      <StyledNavigationBarLabel>{label}</StyledNavigationBarLabel>
    </StyledNavigationBarOption>
  );
};

interface NavigationBarAnchorProps {
  className?: string;
  icon: Icon;
  label: string;
  href: string;
}

/**
 * A link to possibly external (outside the app) web pages. Should look more-or-less like the
 * other navigation bar options available.
 */
export const NavigationBarAnchor = (
  props: NavigationBarAnchorProps
): ReactElement => {
  const { className, icon: Icon, label, href } = props;

  return (
    <StyledNavigationBarOption
      as={(props) => <a {...props} href={href} />}
      className={className}
    >
      <StyledNavigationBarIcon>
        <Icon {...iconProps} />
      </StyledNavigationBarIcon>
      <StyledNavigationBarLabel>{label}</StyledNavigationBarLabel>
    </StyledNavigationBarOption>
  );
};

interface NavigationBarButtonProps {
  active?: boolean;
  className?: string;
  icon: Icon;
  label: string;
  onClick: (event: MouseEvent) => void;
  analyticsName?: string;
}

/**
 * For menu options that are not links (e.g. the hubspot chat help button) but need
 * to appear more-or-less identically to links:
 */
export const NavigationBarButton = (
  props: NavigationBarButtonProps
): ReactElement => {
  const {
    active,
    className,
    icon: Icon,
    label,
    onClick,
    analyticsName,
  } = props;
  const analyticsKey = useAnalyticsKey(analyticsName);
  const cls = [className, active ? "active" : ""].filter(Boolean).join(" ");

  return (
    <StyledNavigationBarOption
      as="button"
      className={cls}
      onClick={onClick}
      {...analyticsAttrs(analyticsKey)}
    >
      <StyledNavigationBarIcon>
        <Icon {...iconProps} />
      </StyledNavigationBarIcon>
      <StyledNavigationBarLabel>{label}</StyledNavigationBarLabel>
    </StyledNavigationBarOption>
  );
};
