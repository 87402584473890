import { ReactElement } from "react";

import { DocsLinkList, DocsLinkListProps } from "../ui/DocsLinkList";
import { Prose } from "../ui/Prose";

const helpLinks: DocsLinkListProps["links"] = [
  {
    type: "external",
    href: "https://faraday.ai/docs/abstractions/persona_sets",
    label: "How to create persona sets",
  },
];

export function PersonaSetsInfoSidebar(): ReactElement {
  return (
    <>
      <Prose>
        <p>
          Every brand's customers break down into a handful of clusters of
          similar people. We call these clusters personas—you can think of each
          one as a "type" of customer. Knowing your personas is useful for
          producing relevant creative variants. Assigning each of your customers
          to one allows you to personalize your outreach with the correct
          variant.
        </p>
      </Prose>

      <DocsLinkList links={helpLinks} />
    </>
  );
}
