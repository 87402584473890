import { GlyphSeries, LineSeries } from "@visx/xychart";

import { colors } from "../../../styles/chakra-theme-v2";
import { largeNumber } from "../../../utils/formatters";
import { AxisBottomDates } from "./AxisBottomDates";
import { AxisLeft } from "./AxisLeft";
import { Chart, RenderTooltipCallback } from "./Chart";

// you may want to update this type or make it configurable via props if you're not using dates
interface LineChartDatum {
  x: Date;
  y: number;
}

interface LineChartProps<Datum extends LineChartDatum> {
  title: string;
  data: Datum[];
  yLabel: string;
  renderTooltip?: RenderTooltipCallback<Datum>;
}

/**
 * Render a responsive line chart with a tooltip shown on column hover.
 * The bottom axis is a date axis.
 * The left axis is a number axis.
 */
export function LineChart<Datum extends LineChartDatum>({
  data,
  yLabel,
  title,
  renderTooltip,
}: LineChartProps<Datum>) {
  return (
    <Chart
      title={title}
      xScale={{ type: "time" }}
      yScale={{ type: "linear" }}
      renderTooltip={renderTooltip}
    >
      <LineSeries
        dataKey="lines"
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        stroke={colors.fdy_green[500]}
      />

      <GlyphSeries
        dataKey="line-dots"
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        colorAccessor={() => colors.fdy_green[500]}
      />

      <AxisLeft<number> label={yLabel} tickFormat={(d) => largeNumber(d)} />

      <AxisBottomDates />
    </Chart>
  );
}
