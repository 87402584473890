import { CardV2 } from "../../ui/Card/CardV2";
import { FormField } from "../../ui/FormField";
import { Input } from "../../ui/Input";

export function CohortNameCard({
  name,
  onNameChange,
  error,
}: {
  name: string;
  onNameChange: (name: string) => void;
  error?: string;
}) {
  return (
    <CardV2>
      <FormField
        label="Cohort name"
        helpText={
          <>
            A strong cohort name is descriptive of its intent, and might be a
            summary of the selected events and traits, such as "Current
            customers active in the last 3 months."
          </>
        }
        error={error}
      >
        <Input
          type="text"
          value={name}
          onChange={(e) => {
            onNameChange(e.target.value);
          }}
          analyticsName="name"
        />
      </FormField>
    </CardV2>
  );
}
