import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface ScrollProps {
  /**
   * Removes the scroll bar UI from the element while leaving it scrollable.
   * Using this prop is highly discouraged as it leaves zero indicator of a scrolling area for the user.
   * There are still a few cases where it's sort-of-ok to do if it's an edge case.
   */
  hideBars?: boolean;
  maxHeight?: number;
}

export const Scroll = styled.div<ScrollProps>`
  height: 100%;
  overflow-y: auto;

  ${({ maxHeight }) =>
    Boolean(maxHeight) &&
    css`
      max-height: ${maxHeight}px;
    `}

  ${(p) =>
    p.hideBars &&
    css`
      -ms-overflow-style: none; // explorer, edge
      scrollbar-width: none; // firefox

      &::-webkit-scrollbar {
        display: none; // chrome, safari
      }
    `}
`;
