import { Box, Flex, Heading } from "@chakra-ui/react";
import { Resource } from "@fdy/faraday-js";

import { CodeSample } from "../CodeSample";
import { RequestDocsLink } from "./RequestDocsLink";
import {
  CodeLanguage,
  HttpRequest,
  requestToHttpSnippet,
} from "./requestToHttpSnippet";

const snippetHeadings: Record<HttpRequest["method"], string> = {
  GET: "Retrieve this resource",
  POST: "Create another resource just like this one",
  PATCH: "Update this resource",
  DELETE: "Delete this resource",
};

// FIXME: rename all 'sample' -> snippet
/**
 * Renders a code sample for a single request, using the given language.
 * Also includes a link to the relevant documentation.
 */
export function RequestCodeSnippet({
  request,
  language,
  resource,
}: {
  request: HttpRequest;
  language: CodeLanguage;
  resource: Resource;
}) {
  const heading = snippetHeadings[request.method];

  const code = requestToHttpSnippet(request, language);

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Heading sx={{ fontSize: "fdy_md", mb: 2 }}>{heading}</Heading>
        <RequestDocsLink request={request} resource={resource} />
      </Flex>
      <CodeSample code={code} />
    </Box>
  );
}
