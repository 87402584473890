import { useRoute } from "react-router5";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { PersonaSetPageSkeleton } from "../PersonaSetPageSkeleton";
import { PersonaSetsSidebarLayout } from "../PersonaSetsLayout";
import { usePersonaSet } from "../usePersonaSet";
import { PersonaSetFlowCard } from "./PersonaSetFlowCard";

/**
 * Persona flow page renders a chart showing the evolution of a persona set
 * over time. It's a stacked area chart where each area represents a persona
 * and the heights of the area represents the number of members that match that persona.
 */
export function PersonaSetFlowPage() {
  const { route } = useRoute();

  const personaSetId = route.params.roster;
  const { data, error, loading } = usePersonaSet(personaSetId);

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  const personaSet = data?.personaSet;
  if (!personaSet) {
    throw new Error(
      `BUG: No persona set found, but sojourner did not throw an error`
    );
  }

  return (
    <PersonaSetsSidebarLayout
      personaSet={personaSet}
      title={personaSet.name}
      lastCrumb={{
        label: personaSet.name,
        routeName: ROUTE_NAMES.PERSONAS_FLOW,
        params: { roster: route.params.roster },
      }}
    >
      {personaSet.personas.length ? (
        <PersonaSetFlowCard personaSet={personaSet} />
      ) : personaSet.status !== ResourceStatus.ERROR ? (
        <PersonaSetPageSkeleton />
      ) : null}
    </PersonaSetsSidebarLayout>
  );
}
