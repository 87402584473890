import { gql } from "@apollo/client";
import { Plus } from "@phosphor-icons/react";
import { Dispatch, SetStateAction, useState } from "react";

import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { useSojournerQuery } from "../../../../services/sojournerApolloClient";
import { ActionableCardItem } from "../../../ui/ActionableCardItem";
import { Blankslate } from "../../../ui/Blankslate";
import { Button } from "../../../ui/Button";
import { EventState } from "../CohortForm";
import { FormSection } from "../FormSection";
import { CohortStreamsQuery } from "./__generated__/CohortStreamsQuery";
import { CohortEventSetupModal } from "./CohortEventsSetupModal";
import { CohortEventsStreamSelectModal } from "./CohortEventsStreamSelectModal";
import { CohortEventSummary } from "./CohortEventSummary";

enum CohortEventModalMode {
  /** No modal open */
  Closed,
  /** Selecting an event stream */
  Stream,
  /** Modifying a selected stream */
  Setup,
}

export const COHORT_STREAMS_QUERY = gql`
  query CohortStreamsQuery {
    streams {
      id
      name
      properties
      archivedAt
    }
  }
`;

type CohortEventEditorProps = {
  event: EventState | undefined;
  onEventChange: Dispatch<SetStateAction<EventState | undefined>>;
  disableRemove?: boolean;
  error?: string;
};

/**
 * Renders a card with the event summary if present,
 */
export function CohortEventsCard({
  event,
  onEventChange,
  disableRemove,
  error,
}: CohortEventEditorProps) {
  // which modal we have open at a given time
  const [modalMode, setModalMode] = useState<CohortEventModalMode>(
    CohortEventModalMode.Closed
  );

  // temporary state for the modals
  const [eventEditState, setEventEditState] = useState<EventState>();

  const [modalError, setModalError] = useState<string | undefined>();

  const {
    data,
    loading,
    error: queryError,
  } = useSojournerQuery<CohortStreamsQuery>(COHORT_STREAMS_QUERY);

  if (queryError) throw queryError;

  // step 1 - user clicks "Add Event"
  function handleAddEventClick() {
    // load some initial state for the event modal
    setEventEditState({
      streamName: null,
      streamConditions: [],
      occurrence: undefined,
      minDays: null,
      maxDays: null,
      minCount: null,
      maxCount: null,
      minValue: null,
      maxValue: null,
    });

    setModalMode(CohortEventModalMode.Stream);
  }

  // user picks an event stream - now show the event setup modal
  function handleStreamSelectNext() {
    if (!eventEditState?.streamName) {
      setModalError("Please select an event stream");
    } else {
      setModalError(undefined);
      setModalMode(CohortEventModalMode.Setup);
    }
  }

  function cleanup() {
    setEventEditState(undefined);
    setModalError(undefined);
    setModalMode(CohortEventModalMode.Closed);
  }

  // general 'cancel' handler that can happen throughout the modal steps
  function handleEventModalClose() {
    cleanup();
  }

  // user is done setting up an event for first time
  function handleAddEventFinish() {
    onEventChange(eventEditState);
    cleanup();
  }

  // when setting up an event for first time, and adding advanced options, user
  // can go back to stream select modal
  function handleSetupBack() {
    setModalMode(CohortEventModalMode.Stream);
  }

  function handleEventEditClick() {
    setEventEditState(event);
    setModalMode(CohortEventModalMode.Stream);
  }

  function handleEventRemoveClick() {
    // unset the whole event
    onEventChange(undefined);
  }

  const hasPopulatedEventStream =
    data?.streams && eventEditState && eventEditState.streamName;

  // If we have no data (the query is loading) or no streams, we can't add an event. The no data
  // check is here to avoid a flicker in UI rendering logic.
  const accountHasEventStreams = !data || data.streams?.length ? true : false;

  const blankSlateContent = (
    <Blankslate
      title="There are no event streams defined."
      text="To create a cohort of individuals who have experienced an event define an event stream."
      button={{
        children: "Set up event streams in Datasets",
        routeName: ROUTE_NAMES.DATASETS,
      }}
      filled
    />
  );

  const hasEventStreamContent = event?.streamName ? (
    <ActionableCardItem
      title={event.streamName}
      content={<CohortEventSummary event={event} />}
      menuBtnLabel={`${event.streamName} event options`}
      onEdit={handleEventEditClick}
      onDelete={disableRemove ? undefined : handleEventRemoveClick}
    />
  ) : (
    <Button
      onClick={handleAddEventClick}
      isLoading={loading}
      variant="secondary"
      leftIcon={<Plus weight="bold" />}
      width="100%"
      analyticsName="add-event"
    >
      Add event
    </Button>
  );

  return (
    <FormSection
      title="Events"
      suffix="Recommended"
      text="Choose the key event that members of this cohort must have previously experienced. For example, you could define your customers as people who have experienced an order event."
      error={error}
    >
      {accountHasEventStreams ? hasEventStreamContent : blankSlateContent}
      {modalMode === CohortEventModalMode.Stream && data?.streams && (
        <CohortEventsStreamSelectModal
          streams={data.streams}
          event={eventEditState}
          error={modalError}
          onCancel={handleEventModalClose}
          onClose={handleEventModalClose}
          onEventChange={setEventEditState}
          onNext={handleStreamSelectNext}
        />
      )}

      {modalMode === CohortEventModalMode.Setup && hasPopulatedEventStream && (
        <CohortEventSetupModal
          stream={data.streams.find(
            (s) => s.name === eventEditState.streamName
          )}
          backButtonLabel="Back"
          event={eventEditState}
          onBack={handleSetupBack}
          onCancel={handleEventModalClose}
          onEventChange={setEventEditState}
          onFinish={handleAddEventFinish}
          title="Add event conditions"
        />
      )}
    </FormSection>
  );
}
