import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Icon } from "@phosphor-icons/react";
import { ReactElement } from "react";

import {
  COHORTS_ENABLED,
  PERSONAS_ENABLED,
} from "../../../constants/accountFeatureNames";
import { useAccountConfigMap } from "../../../hooks/accountConfigHooks";
import { isDemoOrDevEnv } from "../../../utils/isDemoOrDevEnv";
import {
  analyticsAttrs,
  useAnalyticsKeyGenerator,
} from "../../ui/Analytics/AnalyticsStack";
import { ErrorBoundary } from "../../ui/ErrorBoundary";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { ExploreCohorts } from "./ExploreCohorts";
import { ExplorePersonas } from "./ExplorePersonas";

interface PresetTab {
  label: string;
  icon: Icon;
  enabled?: boolean;
  name: string;
  panel: ReactElement;
}

export function PresetTabs(): ReactElement {
  const featureMap = useAccountConfigMap();
  const demoOrDevEnv = isDemoOrDevEnv();

  const getAnalyticsKey = useAnalyticsKeyGenerator("presets");

  const tabs: PresetTab[] = [
    {
      label: "Personas",
      icon: ResourceIcon.persona_sets,
      enabled: featureMap[PERSONAS_ENABLED],
      panel: <ExplorePersonas />,
      name: "personas",
    },
    {
      label: "Cohorts",
      icon: ResourceIcon.cohorts,
      enabled: featureMap[COHORTS_ENABLED],
      name: "cohorts",
      panel: <ExploreCohorts />,
    },
  ];

  const enabledTabs = tabs.filter((t) => t.enabled || demoOrDevEnv);

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "md",
        border: "1px solid",
        borderColor: "fdy_gray.200",
        boxShadow: "md",
        overflow: "hidden",
        width: 320,
      }}
    >
      <Tabs size="sm">
        <TabList>
          {enabledTabs.map(({ icon: Icon, label, name }, idx) => {
            return (
              <Tab
                key={idx}
                flex="1"
                py={4}
                {...analyticsAttrs(getAnalyticsKey(name))}
              >
                <Flex gap={2} alignItems="center">
                  <Icon />
                  {label}
                </Flex>
              </Tab>
            );
          })}
        </TabList>

        <TabPanels>
          {enabledTabs.map(({ panel }, idx) => {
            return (
              <TabPanel key={idx} p={0}>
                <ErrorBoundary>{panel}</ErrorBoundary>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
