import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  Text,
  visuallyHiddenStyle,
} from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";

interface FormFieldProps {
  label: ReactNode;
  suffix?: "optional" | "required";
  helpText?: ReactNode;
  dataType?: string;
  error?: string;
  children: ReactNode;
  htmlFor?: string;
  visuallyHiddenLabel?: boolean;
  /**
   * Adjust size of label a nd help text. Often used when there's one form control within a titleless CardV2.
   */
  size?: "md" | "lg";
  analyticsName?: string;
}

export const LabelSuffix: React.FC<PropsWithChildren> = ({ children }) => (
  <Text
    as="span"
    sx={{
      ml: 2,
      textTransform: "uppercase",
      fontSize: "fdy_sm",
      color: "fdy_gray.700",
    }}
  >
    {children}
  </Text>
);

/**
 * Wraps up the common form field elements into a single component.
 */
export const FormField = forwardRef<FormFieldProps, "div">(
  (
    {
      label,
      helpText,
      error,
      children,
      dataType,
      suffix,
      htmlFor,
      visuallyHiddenLabel,
      size = "md",
      analyticsName,
    },
    ref
  ) => {
    const analyticsKey = useAnalyticsKey(analyticsName);
    return (
      <FormControl isInvalid={!!error} size={size} ref={ref}>
        <FormLabel
          htmlFor={htmlFor}
          sx={visuallyHiddenLabel ? visuallyHiddenStyle : undefined}
          size={size}
          {...analyticsAttrs(analyticsKey)}
        >
          {label}

          {suffix && <LabelSuffix>{suffix}</LabelSuffix>}

          {dataType && (
            <Text
              as="span"
              sx={{
                ml: 2,
                fontSize: "fdy_sm",
                fontWeight: "normal",
                color: "fdy_gray.700",
              }}
            >
              {dataType}
            </Text>
          )}
        </FormLabel>
        {helpText && (
          <FormHelperText
            sx={{
              fontSize: size === "lg" ? "fdy_lg" : "fdy_md",
            }}
          >
            {helpText}
          </FormHelperText>
        )}
        {children}
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  }
);
