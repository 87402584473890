import { UnreachableCodeError } from "@fdy/jwt";
import { useRouter } from "react-router5";

import { OutcomeBiasMitigationStrategy } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_UPDATED_OUTCOME,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { components } from "../../sojourner-oas-types";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { OutcomeForm } from "./OutcomeForm";
import { OutcomeLayout } from "./OutcomeLayout";
import { useOutcomeQuery } from "./useOutcomeQuery";
import { OutcomeFormState, useUpdateOutcome } from "./useUpdateOutcome";

type OutcomeMergePatch = components["schemas"]["OutcomeMergePatch"];
type OutcomeMergePatchBiasMitigationStrategy =
  components["schemas"]["OutcomeBiasMitigationStrategy"];

function biasMitigationStrategyToPatch(
  strategy: OutcomeBiasMitigationStrategy | null | undefined
): OutcomeMergePatchBiasMitigationStrategy {
  switch (strategy) {
    case OutcomeBiasMitigationStrategy.NONE:
      return "none";
    case OutcomeBiasMitigationStrategy.EQUALITY:
      return "equality";
    case OutcomeBiasMitigationStrategy.EQUITY:
      return "equity";
    case null:
    case undefined:
      return "none";
    default:
      throw new UnreachableCodeError(strategy);
  }
}

function prepareOutcomePatch(state: OutcomeFormState): OutcomeMergePatch {
  return {
    name: state.name,
    attainment_cohort_id: state.attainment_cohort_id,
    attrition_cohort_id:
      state.attrition_cohort_id === "" ? null : state.attrition_cohort_id,
    eligible_cohort_id:
      state.eligible_cohort_id === "" ? null : state.eligible_cohort_id,
    feature_blocklist: state.feature_blocklist,
    predictors: {
      blocked: {
        providers: state.predictors?.blocked?.providers
          ? state.predictors?.blocked?.providers
          : [],
      },
    },
    bias_mitigation: {
      age: biasMitigationStrategyToPatch(state.bias_mitigation?.age),
      gender: biasMitigationStrategyToPatch(state.bias_mitigation?.gender),
    },
  };
}

export function OutcomesEditPage() {
  const { navigate, getState } = useRouter();
  const toast = useToast();

  const outcomeId = getState().params.outcome;

  const { data, loading, error } = useOutcomeQuery(outcomeId);
  const track = useHubSpotEvent();

  const { updateOutcome, updating } = useUpdateOutcome({
    onCompleted(data) {
      if (!data?.updateOutcome) return;

      const { id, name } = data.updateOutcome;

      toast({
        title: `${name} successfully updated!`,
        description:
          "It may take a few hours before this outcome is available. We'll send you an email when it's ready.",
        status: "success",
      });

      track(HUBSPOT_USER_UPDATED_OUTCOME, {
        resource_id: id,
        resource_name: name,
      });

      navigate(ROUTE_NAMES.OUTCOMES_DEFINITION, {
        outcome: id,
      });
    },
  });

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  if (!data?.outcome) throw new Error("No outcome found");

  const { outcome } = data;

  const initialState: OutcomeFormState = {
    name: outcome.name,
    attainment_cohort_id: outcome.attainmentCohortId,
    eligible_cohort_id: outcome.eligibleCohortId ?? "",
    attrition_cohort_id: outcome.attritionCohortId ?? "",
    feature_blocklist: outcome.featureBlocklist ?? [],
    predictors: {
      blocked: {
        providers: outcome.predictors?.blocked?.providers ?? [],
      },
    },
    bias_mitigation: outcome.biasMitigation ?? {
      age: OutcomeBiasMitigationStrategy.NONE,
      gender: OutcomeBiasMitigationStrategy.NONE,
    },
  };

  function handleSave(state: OutcomeFormState) {
    updateOutcome(outcome.id, prepareOutcomePatch(state));
  }

  return (
    <OutcomeLayout
      crumbs={[
        {
          label: "Outcomes",
          routeName: ROUTE_NAMES.OUTCOMES,
        },
        {
          label: outcome.name,
          routeName: ROUTE_NAMES.OUTCOMES_DEFINITION,
          params: { outcome: outcomeId },
        },
        {
          label: "Edit",
          routeName: ROUTE_NAMES.OUTCOMES_EDIT,
          params: { outcome: outcomeId },
        },
      ]}
      backBtnProps={{
        label: "Outcomes",
        routeName: ROUTE_NAMES.OUTCOMES_DEFINITION,
        routeParams: { outcome: outcomeId },
      }}
      outcome={outcome}
    >
      <OutcomeForm
        initialState={initialState}
        onSave={handleSave}
        saving={updating}
      />
    </OutcomeLayout>
  );
}
