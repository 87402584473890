import { gql } from "@apollo/client";
import { Link, List } from "@chakra-ui/react";
import { useMemo } from "react";

import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { ActionableCardItem } from "../../ui/ActionableCardItem";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { CardV2 } from "../../ui/Card/CardV2";
import { ChatLink } from "../../ui/ChatLink";
import { MutedText } from "../../ui/MutedText";
import { PlacesQuery, PlacesQuery_places } from "./__generated__/PlacesQuery";
import { CohortsFormState } from "./CohortForm";
import { FormSection } from "./FormSection";

const PLACES_QUERY = gql`
  query PlacesQuery {
    places {
      id
      name
    }
  }
`;

function metersToMiles(meters: number) {
  return (meters / 1609.344).toFixed(2);
}

function CohortPlaceConditionsItems({
  placeConditions,
}: {
  placeConditions: CohortsFormState["placeConditions"];
}) {
  const { data, loading, error } = useSojournerQuery<PlacesQuery>(PLACES_QUERY);

  const placesByid = useMemo(
    () =>
      data?.places.reduce<Record<string, PlacesQuery_places>>((acc, place) => {
        acc[place.id] = place;
        return acc;
      }, {}) ?? {},
    [data]
  );

  if (error) throw error;

  if (loading) {
    return <AnimatedZapLogo />;
  }

  const conds = placeConditions ?? [];

  if (conds.length === 0) {
    return <MutedText>None</MutedText>;
  }

  return (
    <List display="grid" gap={2}>
      {conds.map((cond, i) => {
        const place = placesByid[cond.placeId];

        return (
          <ActionableCardItem
            key={i}
            title={place?.name ?? cond.placeId}
            content={
              <div>
                {cond.invert ? "Outside of" : "Within"}{" "}
                {cond.distance && (
                  <span>{metersToMiles(cond.distance)} mile radius</span>
                )}
              </div>
            }
          />
        );
      })}
    </List>
  );
}

export function CohortPlaceConditionsCard({
  placeConditions,
}: {
  placeConditions: CohortsFormState["placeConditions"];
}) {
  return (
    <CardV2>
      {/* TODO: stop using FormSection with CardV2. They're so visually and not functionally different enough? Need to match other cohort form cards too. */}
      <FormSection
        title="Places"
        text={
          <>
            Require members of this cohort to live within or outside of a
            certain radius of specific places. To modify this section,{" "}
            <ChatLink>contact support</ChatLink> or{" "}
            <Link href="https://faraday.ai/developers/reference/updatecohort">
              use our API.
            </Link>
          </>
        }
      >
        <CohortPlaceConditionsItems placeConditions={placeConditions} />
      </FormSection>
    </CardV2>
  );
}
