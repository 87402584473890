import { ROUTE_NAMES } from "../../../constants/routeNames";
import { TabLinks } from "../../ui/TabLinks";
import { DatasetFragment } from "../__generated__/DatasetFragment";

export function DatasetTabs({ dataset }: { dataset: DatasetFragment }) {
  const tabs = [
    {
      label: "Definition",
      routeName: ROUTE_NAMES.DATASETS_DEFINITION,
      params: {
        id: dataset.id,
      },
    },
    {
      label: "Data",
      routeName: ROUTE_NAMES.DATASETS_DATA,
      params: {
        id: dataset.id,
      },
    },
  ];

  return <TabLinks tabs={tabs} />;
}
