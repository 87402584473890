import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  visuallyHiddenStyle,
} from "@chakra-ui/react";
import { Trash } from "@phosphor-icons/react";
import { ChangeEvent } from "react";

import {
  analyticsAttrs,
  AnalyticsStack,
  useAnalyticsKeyGenerator,
} from "../Analytics/AnalyticsStack";
import { Select } from "../Select";
import { useId } from "../useId";
import { ConditionsBuilderValueInput } from "./ConditionsBuilderValueInput";
import { ConditionRow, Operator, RowProps } from "./types";
import { createNewConditionRow } from "./utils";

/**
 * Renders a single row of the conditions builder widget.
 *
 * Each row has a select/dropdown where user can choose an operator.
 * Based on the operator, a different input is rendered.
 *
 * Rows can be removed unless the overall builder is locked.
 */
export function ConditionsBuilderRow({
  conditions,
  condition: cond,
  onChange,
  operators,
  largeCsvInput,
  locked,
  unit,
  virtualized,
}: RowProps) {
  const inputId = useId("condition-builder-row");
  const operator = operators.find((o) => o.operator === cond.operator);

  function handleRemoveCondition() {
    onChange((prev) => prev.filter((c) => c !== cond));
  }

  function handleOperatorChange(currRow: ConditionRow) {
    return (e: ChangeEvent<HTMLSelectElement>) => {
      const val = e.target.value === "" ? null : (e.target.value as Operator);

      // update the condition row to the new operator and type
      onChange((prev) => {
        return prev.map((c) => {
          if (c === currRow) {
            return createNewConditionRow(operators, val);
          }
          return c;
        });
      });
    };
  }

  const getOperatorAnalyticsKey = useAnalyticsKeyGenerator(
    "properties/operator"
  );

  const select = (
    <AnalyticsStack value="properties">
      <FormControl>
        <FormLabel sx={visuallyHiddenStyle}>
          Operator{operator ? ` (${operator.label})` : ""}
        </FormLabel>
        <Select
          value={cond.operator ?? ""}
          placeholder={"Select operator..."}
          onChange={handleOperatorChange(cond)}
          analyticsName="operator"
        >
          {operators.map((opt) => {
            // disallow already used operators
            const alreadyUsed = conditions.some(
              (c) => c.operator === opt.operator && c !== cond
            );

            return (
              <option
                key={opt.operator}
                value={opt.operator}
                disabled={alreadyUsed}
                {...analyticsAttrs(getOperatorAnalyticsKey(opt.operator))}
              >
                {opt.label}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </AnalyticsStack>
  );

  const removeBtn = locked ? null : (
    <IconButton
      variant="icon"
      aria-label={`Remove ${operator?.label} condition`}
      icon={<Trash />}
      onClick={handleRemoveCondition}
    />
  );

  const input = (
    <FormControl>
      <FormLabel htmlFor={inputId} sx={visuallyHiddenStyle}>
        {operator?.label}
      </FormLabel>
      <ConditionsBuilderValueInput
        id={inputId}
        unit={unit}
        largeCsvInput={largeCsvInput}
        condition={cond}
        onChange={onChange}
        picklist={operator?.picklist}
        options={operator?.options}
        min={operator?.min}
        max={operator?.max}
        step={operator?.step}
        virtualized={virtualized}
      />
    </FormControl>
  );

  // For pick lists and csv inputs that want to be full width (like text constraints),
  // we render the select and input on separate lines.
  const renderInputBelow = Boolean(
    (cond.type === "list" && largeCsvInput) || operator?.picklist
  );

  if (renderInputBelow) {
    return (
      <>
        <Box display="flex" gap={2} mb={2}>
          {select}
          {removeBtn}
        </Box>
        {input}
      </>
    );
  }

  return (
    <Box display="flex" gap={2}>
      {select}
      {input}
      <Box>{removeBtn}</Box>
    </Box>
  );
}
