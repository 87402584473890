import { Box } from "@chakra-ui/react";

import { PopoverInfoPill, PopoverInfoPillPart } from "../../ui/PopoverInfoPill";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { PipelinesTableQuery_scopes as Scope } from "../__generated__/PipelinesTableQuery";
import { scopeInfo } from "../scopeInfo";
import { ScopePayloadBreakdown } from "../ScopePayloadBreakdown";

export function ScopePayloadPill({ scope }: { scope: Scope }) {
  const { cohortIds, outcomeIds, personaSetIds, recommenderIds, attributes } =
    scope.payload;
  const cohortIdCount = cohortIds.length;
  const outcomeIdCount = outcomeIds.length;
  const personaSetIdCount = personaSetIds.length;
  const recommenderIdCount = recommenderIds.length;
  const traitsCount = attributes.length;
  return (
    <PopoverInfoPill
      popover={
        <Box maxWidth={600}>
          <ScopePayloadBreakdown scope={scope} caption={scopeInfo.payload} />
        </Box>
      }
    >
      {cohortIdCount > 0 && (
        <PopoverInfoPillPart
          icon={ResourceIcon.cohorts}
          value={cohortIdCount}
        />
      )}
      {outcomeIdCount > 0 && (
        <PopoverInfoPillPart
          icon={ResourceIcon.outcomes}
          value={outcomeIdCount}
        />
      )}
      {personaSetIdCount > 0 && (
        <PopoverInfoPillPart
          icon={ResourceIcon.persona_sets}
          value={personaSetIdCount}
        />
      )}
      {traitsCount > 0 && (
        <PopoverInfoPillPart icon={ResourceIcon.traits} value={traitsCount} />
      )}
      {recommenderIdCount > 0 && (
        <PopoverInfoPillPart
          icon={ResourceIcon.recommenders}
          value={recommenderIdCount}
        />
      )}
    </PopoverInfoPill>
  );
}
