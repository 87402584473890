import { Operator, OperatorsOption } from "./types";

export const OPERATOR_LABELS: Record<Operator, string> = {
  neq: "Not equal to (!=)",
  eq: "Equal to (=)",
  matches: "Contains (value or regex)",
  gt: "Greater than (>)",
  gte: "Greater than or equal to (>=)",
  lt: "Less than (<)",
  lte: "Less than or equal to (<=)",
  in: "Equals one of (comma separated)",
  nin: "Does not equal one of (comma separated)",
  null: "Empty values only (null)",
  nnull: "Any values (non-null)",
};

export const NUMBER_OPERATORS: OperatorsOption[] = [
  { operator: "nnull", label: OPERATOR_LABELS.nnull },
  { operator: "eq", label: OPERATOR_LABELS.eq },
  { operator: "gt", label: OPERATOR_LABELS.gt },
  { operator: "gte", label: OPERATOR_LABELS.gte },
  { operator: "lt", label: OPERATOR_LABELS.lt },
  { operator: "lte", label: OPERATOR_LABELS.lte },
  { operator: "in", label: OPERATOR_LABELS.in },
  { operator: "nin", label: OPERATOR_LABELS.nin },
  { operator: "null", label: OPERATOR_LABELS.null },
  { operator: "matches", label: OPERATOR_LABELS.matches },
];

export const TEXT_OPERATORS: OperatorsOption[] = [
  { operator: "nnull", label: OPERATOR_LABELS.nnull },
  { operator: "eq", label: OPERATOR_LABELS.eq },
  { operator: "in", label: OPERATOR_LABELS.in },
  { operator: "nin", label: OPERATOR_LABELS.nin },
  { operator: "null", label: OPERATOR_LABELS.null },
  { operator: "matches", label: OPERATOR_LABELS.matches },
];

export const ALL_OPERATORS: OperatorsOption[] = [
  { operator: "nnull", label: OPERATOR_LABELS.nnull },
  { operator: "eq", label: OPERATOR_LABELS.eq },
  { operator: "neq", label: OPERATOR_LABELS.neq },
  { operator: "in", label: OPERATOR_LABELS.in },
  { operator: "nin", label: OPERATOR_LABELS.nin },
  { operator: "null", label: OPERATOR_LABELS.null },
  { operator: "matches", label: OPERATOR_LABELS.matches },
  { operator: "gt", label: OPERATOR_LABELS.gt },
  { operator: "gte", label: OPERATOR_LABELS.gte },
  { operator: "lt", label: OPERATOR_LABELS.lt },
  { operator: "lte", label: OPERATOR_LABELS.lte },
];
