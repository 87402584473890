import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tag,
} from "@chakra-ui/react";
import { Icon, Info } from "@phosphor-icons/react";
import { ReactNode } from "react";

import { colors } from "../../styles/chakra-theme-v2";
import { ErrorBoundary } from "./ErrorBoundary";
import { VendorLogo } from "./VendorLogo";

// set up some style specifics for icon we use here.
// TODO: move datasets pill tables to config breakdown table then move this to the one place it's used in this file
export function PopoverPillIcon({ icon: Icon }: { icon: Icon }) {
  return <Icon />;
}

export function PopoverInfoPill({
  popover,
  bodyPadding,
  children,
}: {
  popover: ReactNode;
  bodyPadding?: number;
  children: ReactNode;
}) {
  const label = (
    <Tag sx={{ display: "inline-flex", gap: 1 }}>
      {children}
      {popover && <Info weight="fill" color={colors.fdy_gray[500]} />}
    </Tag>
  );

  if (!popover) return label;

  return (
    <Popover
      trigger="hover"
      // IMPORTANT: don't let inner components mount till hover is shown.
      // Otherwise any content that queries API will run on page load.
      isLazy
    >
      <PopoverTrigger>{label}</PopoverTrigger>
      {/* have to render in a portal, otherwise the table gets its hover styles applied */}
      <Portal>
        <PopoverContent minWidth={400} width="auto" maxWidth={600}>
          <PopoverArrow />
          <PopoverBody
            p={bodyPadding}
            sx={{
              overflowY: "auto",
              maxHeight: 400,
            }}
          >
            <ErrorBoundary>{popover}</ErrorBoundary>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export function PopoverInfoPillPart({
  icon,
  value,
}: {
  icon: Icon | { logo: string };
  value: string | number;
}) {
  return (
    <span style={{ whiteSpace: "nowrap", display: "flex", gap: 3 }}>
      {"logo" in icon ? (
        <VendorLogo name={icon.logo} dimension={16} inline />
      ) : (
        <PopoverPillIcon icon={icon} />
      )}
      {value}
    </span>
  );
}
