import { useQuery } from "@tanstack/react-query";

import { scopeQueryKeys } from "../../../hooks/api";
import { useFdyClient } from "../../../services/FdyClientProvider";

export function useScopePayloadRecommendersQuery(scopeId: string) {
  const client = useFdyClient();

  return useQuery({
    queryKey: [scopeQueryKeys.show(scopeId), "payload", "recommenders"],
    queryFn: () => client.scopes.getScopePayloadRecommenders(scopeId),
  });
}

export function useScopePayloadOutcomesQuery(scopeId: string) {
  const client = useFdyClient();

  return useQuery({
    queryKey: [scopeQueryKeys.show(scopeId), "payload", "outcomes"],
    queryFn: () => client.scopes.getScopePayloadOutcomes(scopeId),
  });
}

export function useScopePayloadCohortsQuery(scopeId: string) {
  const client = useFdyClient();

  return useQuery({
    queryKey: [scopeQueryKeys.show(scopeId), "payload", "cohorts"],
    queryFn: () => client.scopes.getScopePayloadCohorts(scopeId),
  });
}

export function useScopePayloadPersonaSetsQuery(scopeId: string) {
  const client = useFdyClient();

  return useQuery({
    queryKey: [scopeQueryKeys.show(scopeId), "payload", "personaSets"],
    queryFn: () => client.scopes.getScopePayloadPersonaSets(scopeId),
  });
}

export function useScopePayloadQuery(scopeId: string) {
  const recommenders = useScopePayloadRecommendersQuery(scopeId);
  const outcomes = useScopePayloadOutcomesQuery(scopeId);
  const cohorts = useScopePayloadCohortsQuery(scopeId);
  const personaSets = useScopePayloadPersonaSetsQuery(scopeId);

  return {
    recommenders: recommenders.data,
    outcomes: outcomes.data,
    cohorts: cohorts.data,
    personaSets: personaSets.data,
  };
}
