import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";

import { FdyClientError } from "../../services/FdyClientProvider";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        // don't retry 404
        if (error instanceof FdyClientError && error.statusCode === 404) {
          return false;
        }
        return failureCount <= 6;
      },
      suspense: true,
      useErrorBoundary: true,
    },
    mutations: {
      // Sadly can't use this. Error boundaries remount their contents on catch,
      // so if we wrapped forms with it, their state would reset on error, and
      // user would have to re-enter everything.
      // Instead, apply useApiErrorToaster hook when using react-query mutations.
      // https://github.com/facebook/react/issues/26259
      // useErrorBoundary: true,
    },
  },
});

export function ReactQueryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
}
