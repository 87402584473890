import { Text } from "@chakra-ui/react";
import { useState } from "react";

import { DatasetPostInput } from "../../../__generated__/sojournerGlobalTypes";
import { Button } from "../../ui/Button";
import { FormField } from "../../ui/FormField";
import { InlineButtons } from "../../ui/InlineButtons";
import { ModalV2 } from "../../ui/ModalV2";
import { useId } from "../../ui/useId";
import {
  DatasetsTableQuery_connections as Connection,
  DatasetsTableQuery_datasets as Dataset,
} from "../__generated__/DatasetsTableQuery";
import {
  DatasetMergeForm,
  DatasetMergeFormat,
  validationMergeDatasetErrors,
} from "../shared/DatasetsMergeForm";
import {
  DatasetMergeChangeConfigWarning,
  needToChangeMergeInput,
} from "../shared/DatasetsMergeWarning";
import {
  DatasetsCreateNameField,
  validateDatasetName,
} from "./DatasetsCreateNameField";
import { useCreateDataset } from "./useCreateDataset";

enum AvailableModals {
  connectionSetupMerge,
  connectionSetupMergeWarning,
}

/**
 * Renders a form to create a new merge dataset
 */
export function DatasetsCreateModalMerge({
  isOpen,
  onBack,
  onClose,
  datasets,
  connections,
}: {
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
  datasets: Dataset[] | undefined;
  connections: Connection[] | undefined;
}) {
  const mergeFormId = useId("merge-setup");

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [name, setName] = useState<string>("");

  const [mergeDatasets, setMergeDatasets] = useState<DatasetMergeFormat[]>([
    // must have at least two inputs, so start with two blank rows in the table
    { datasetId: null, joinColumn: null },
    { datasetId: null, joinColumn: null },
  ]);

  const [activeModal, setActiveModal] = useState<AvailableModals>(
    AvailableModals.connectionSetupMerge
  );

  const { createDataset, loading } = useCreateDataset({
    onError: (error) => {
      setErrors({ ...errors, api: error.message });
    },
  });

  async function validateMergeDataset() {
    const mergeError = validationMergeDatasetErrors(mergeDatasets);
    if (mergeError) {
      setErrors({
        ...errors,
        merge: mergeError,
      });
      return;
    }

    const inputsNeedChange = needToChangeMergeInput(
      mergeDatasets,
      datasets || []
    );
    if (Object.keys(inputsNeedChange).length) {
      setActiveModal(AvailableModals.connectionSetupMergeWarning);
    } else {
      createMergeDataset();
    }
  }

  async function createMergeDataset(migrate: boolean = false) {
    const dataset: DatasetPostInput = {
      preview: true,
      name,
      identitySets: {},
      options: {
        type: "merge",
        merge: mergeDatasets,
        migrate,
      },
    };

    createDataset(dataset);
  }

  async function handleSubmit() {
    const nameErr = validateDatasetName(name);
    if (nameErr) {
      setErrors({ ...errors, name: nameErr });
      return;
    }

    await validateMergeDataset();
  }

  if (!connections) return null;

  return (
    <>
      <ModalV2
        isOpen={isOpen && activeModal === AvailableModals.connectionSetupMerge}
        onClose={onClose}
        title="Merge Dataset"
        footer={
          <InlineButtons>
            <Button variant="tertiary" onClick={onBack} analyticsName="back">
              Back
            </Button>
            <Button
              variant="primary"
              type="submit"
              form={mergeFormId}
              loadingText="Creating dataset..."
              isLoading={loading}
              disabled={loading}
              analyticsName="finish"
            >
              Create dataset
            </Button>
          </InlineButtons>
        }
        analyticsStackName="merge-dataset-modal"
      >
        <Text mb={4}>
          Choose the existing datasets containing the data you want to merge.
        </Text>
        <form
          id={mergeFormId}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <FormField label="Datasets to merge" error={errors.merge}>
            <DatasetMergeForm
              merge={mergeDatasets}
              setMerge={setMergeDatasets}
              clearError={() => {
                setErrors({ ...errors, merge: "" });
              }}
              existingConnections={connections}
              existingDatasets={datasets ?? []}
            />
          </FormField>

          <DatasetsCreateNameField
            name={name}
            setName={setName}
            error={errors.name}
            setError={(err) => {
              return { ...errors, name: err };
            }}
          />
        </form>
      </ModalV2>
      <DatasetMergeChangeConfigWarning
        isOpen={
          isOpen && activeModal === AvailableModals.connectionSetupMergeWarning
        }
        onSave={() => createMergeDataset(true)}
        onCancel={() => {
          setActiveModal(AvailableModals.connectionSetupMerge);
        }}
        mergeConfig={mergeDatasets}
        existingDatasets={datasets ?? []}
        error={!!errors.api}
        loading={loading}
      />
    </>
  );
}
