import { Text } from "@chakra-ui/react";
import React, { FC, PropsWithChildren } from "react";

export const ErrorText: FC<PropsWithChildren<{ id?: string }>> = ({
  id,
  children,
}) =>
  children ? (
    <Text
      id={id}
      sx={{
        color: "error",
        fontSize: "fdy_sm",
        fontWeight: "bold",
        my: 2,
      }}
      aria-live="polite"
      // TODO: bind to form controls using aria-describedby like FormErrorMessage does?
      data-testid="error-text"
    >
      {children}
    </Text>
  ) : null;
