/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Aggregation {
  AVG = "AVG",
  COUNT = "COUNT",
  COUNTIF = "COUNTIF",
}

export enum Direction {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum Directionality {
  BIDIRECTIONAL = "BIDIRECTIONAL",
  DESTINATION_ONLY = "DESTINATION_ONLY",
  SOURCE_ONLY = "SOURCE_ONLY",
}

export enum Level {
  LOW_BIAS = "LOW_BIAS",
  MODERATE_BIAS = "MODERATE_BIAS",
  STRONG_BIAS = "STRONG_BIAS",
}

export enum Level2 {
  LOW_BIAS = "LOW_BIAS",
  MODERATE_BIAS = "MODERATE_BIAS",
  STRONG_BIAS = "STRONG_BIAS",
}

export enum ModelingField {
  FIG_ACTVTY_NUM_PURCHASE_QUINTILE = "FIG_ACTVTY_NUM_PURCHASE_QUINTILE",
  FIG_ACTVTY_TTL_DOLLARS_QUINTILE = "FIG_ACTVTY_TTL_DOLLARS_QUINTILE",
  FIG_AGE = "FIG_AGE",
  FIG_ANTIQUES = "FIG_ANTIQUES",
  FIG_BOOKS_MAGAZINES = "FIG_BOOKS_MAGAZINES",
  FIG_CHARITABLE_DONATIONS = "FIG_CHARITABLE_DONATIONS",
  FIG_DIETING = "FIG_DIETING",
  FIG_EDUCATION = "FIG_EDUCATION",
  FIG_FAVM = "FIG_FAVM",
  FIG_FREQUENT_REMODELER = "FIG_FREQUENT_REMODELER",
  FIG_GARDENER = "FIG_GARDENER",
  FIG_GENDER = "FIG_GENDER",
  FIG_HEALTH_CONSCIOUS = "FIG_HEALTH_CONSCIOUS",
  FIG_HOMEOWNER_STATUS = "FIG_HOMEOWNER_STATUS",
  FIG_HOUSEHOLD_INCOME = "FIG_HOUSEHOLD_INCOME",
  FIG_HOUSEHOLD_SIZE = "FIG_HOUSEHOLD_SIZE",
  FIG_HOUSING_DENSITY = "FIG_HOUSING_DENSITY",
  FIG_LENGTH_OF_RESIDENCE = "FIG_LENGTH_OF_RESIDENCE",
  FIG_LIFE_SPORTS_SPORTS_ALL = "FIG_LIFE_SPORTS_SPORTS_ALL",
  FIG_LIVING_AREA = "FIG_LIVING_AREA",
  FIG_MARITAL_STATUS = "FIG_MARITAL_STATUS",
  FIG_MUSIC = "FIG_MUSIC",
  FIG_NET_WORTH = "FIG_NET_WORTH",
  FIG_PERCENT_EQUITY = "FIG_PERCENT_EQUITY",
  FIG_PET_ANY = "FIG_PET_ANY",
  FIG_PURCH_CHAN_INTERNET = "FIG_PURCH_CHAN_INTERNET",
  FIG_SHOPPING_STYLES = "FIG_SHOPPING_STYLES",
  FIG_TRAVEL = "FIG_TRAVEL",
  FIG_VALUE_SCORE_ALL = "FIG_VALUE_SCORE_ALL",
  FIG_YEAR_BUILT = "FIG_YEAR_BUILT",
}

export enum OutcomeBiasMitigationStrategy {
  EQUALITY = "EQUALITY",
  EQUITY = "EQUITY",
  NONE = "NONE",
}

export enum PrimitiveDataType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DOUBLE = "DOUBLE",
  LONG = "LONG",
  STRING = "STRING",
}

export enum Privacy {
  DELETE = "DELETE",
  SUPPRESS = "SUPPRESS",
}

export enum RecencyOccurrence {
  FIRST = "FIRST",
  LAST = "LAST",
}

export enum ResourceStatus {
  ERROR = "ERROR",
  NEW = "NEW",
  READY = "READY",
  RUNNING = "RUNNING",
  STARTING = "STARTING",
}

export enum ResourceType {
  ACCOUNTS = "ACCOUNTS",
  COHORTS = "COHORTS",
  CONNECTIONS = "CONNECTIONS",
  DATASETS = "DATASETS",
  OUTCOMES = "OUTCOMES",
  PERSONA_SETS = "PERSONA_SETS",
  PLACES = "PLACES",
  RECOMMENDERS = "RECOMMENDERS",
  SCOPES = "SCOPES",
  STREAMS = "STREAMS",
  TARGETS = "TARGETS",
  TRAITS = "TRAITS",
}

export enum TargetAggregateGeographic {
  CENSUS_BLOCK_GROUP = "CENSUS_BLOCK_GROUP",
  CENSUS_TRACT = "CENSUS_TRACT",
  COUNTY = "COUNTY",
  DMA = "DMA",
  METRO = "METRO",
  POSTCODE = "POSTCODE",
  STATE = "STATE",
}

export enum TargetAggregateIdentified {
  PERSON = "PERSON",
  RESIDENCE = "RESIDENCE",
}

export enum TargetTransformPresetAggregated {
  DEFAULT = "DEFAULT",
  GOOGLE_ADS = "GOOGLE_ADS",
}

export enum TargetTransformPresetHashed {
  DEFAULT = "DEFAULT",
  FACEBOOK = "FACEBOOK",
  GOOGLE_ADS = "GOOGLE_ADS",
  LINKEDIN = "LINKEDIN",
  PINTEREST = "PINTEREST",
  SNAPCHAT = "SNAPCHAT",
  TABOOLA = "TABOOLA",
  TIKTOK = "TIKTOK",
  YOUTUBE = "YOUTUBE",
}

export enum TargetTransformPresetIdentified {
  ADDRESS_ONLY = "ADDRESS_ONLY",
  DEFAULT = "DEFAULT",
  HUBSPOT = "HUBSPOT",
  ITERABLE = "ITERABLE",
  KLAVIYO = "KLAVIYO",
  LINKEDIN = "LINKEDIN",
  LIVERAMP = "LIVERAMP",
  POPLAR = "POPLAR",
  SALESFORCE = "SALESFORCE",
  SEGMENT = "SEGMENT",
  YOUTUBE = "YOUTUBE",
}

export enum TargetTransformPresetReferenced {
  DEFAULT = "DEFAULT",
}

export enum TraitCategory {
  FIG_DEMOGRAPHY = "FIG_DEMOGRAPHY",
  FIG_ENVIRONMENT = "FIG_ENVIRONMENT",
  FIG_FINANCIAL = "FIG_FINANCIAL",
  FIG_GEOGRAPHY = "FIG_GEOGRAPHY",
  FIG_IDENTITY = "FIG_IDENTITY",
  FIG_LIFESTYLE = "FIG_LIFESTYLE",
  FIG_LIFE_EVENT = "FIG_LIFE_EVENT",
  FIG_PROPERTY = "FIG_PROPERTY",
  FIG_REACHABILITY = "FIG_REACHABILITY",
  FIG_SOCIETY = "FIG_SOCIETY",
  USER_DEFINED = "USER_DEFINED",
}

export enum TraitPermission {
  ADD_TO_SCOPE_PAYLOAD = "ADD_TO_SCOPE_PAYLOAD",
  DEFINE_COHORT = "DEFINE_COHORT",
}

export enum TraitTier {
  PREMIUM = "PREMIUM",
  STANDARD = "STANDARD",
}

export interface ArchiveConfigInput {
  cascadeTo?: (string | null)[] | null;
  cascadeToAll?: boolean | null;
}

/**
 * The strategy to be applied to gender and/or age to mitigate or reverse the bias found in the attainment population.
 * 
 * Though the spec appears you can combine different mitigation strategies per dimension of concern (age=equity, gender=equality), API runtime validation will prevent it for now. Later we may support this so the spec is designed for future proofing. Note: age=none, gender=equity is allowed.
 */
export interface BiasMitigationInput {
  age?: OutcomeBiasMitigationStrategy | null;
  gender?: OutcomeBiasMitigationStrategy | null;
}

export interface CohortPlaceConditionInput {
  distance?: number | null;
  invert?: boolean | null;
  placeId: string;
}

/**
 * (Parameters used to POST a new value of the `Cohort` type.)
 * 
 * A specific group of people, such as "Customers" or "Subscription customers".
 */
export interface CohortPostInput {
  explore?: boolean | null;
  maxCount?: number | null;
  maxValue?: number | null;
  minCount?: number | null;
  minValue?: number | null;
  name: string;
  placeConditions?: (CohortPlaceConditionInput | null)[] | null;
  recency?: RecencyPostInput | null;
  streamConditions?: (CohortStreamConditionInput | null)[] | null;
  streamName?: string | null;
  traits?: (CohortTraitInput | null)[] | null;
}

export interface CohortStreamConditionInput {
  eq?: string | null;
  gt?: number | null;
  gte?: number | null;
  in?: (string | null)[] | null;
  lt?: number | null;
  lte?: number | null;
  matches?: string | null;
  nin?: (string | null)[] | null;
  nnull?: boolean | null;
  null?: boolean | null;
  optional?: boolean | null;
  property: string;
}

export interface CohortTraitInput {
  eq?: string | null;
  gt?: number | null;
  gte?: number | null;
  in?: (string | null)[] | null;
  lt?: number | null;
  lte?: number | null;
  matches?: string | null;
  name: string;
  nin?: (string | null)[] | null;
  nnull?: boolean | null;
  null?: boolean | null;
  optional?: boolean | null;
}

/**
 * (Parameters used to POST a new value of the `Connection` type.)
 * 
 * Configuration for connecting data between Faraday and an external location.
 * 
 * Connections are required when working with **replication targets**.
 */
export interface ConnectionPostInput {
  name: string;
  options: SojJSON;
}

/**
 * (Parameters used to POST a new value of the `Dataset` type.)
 * 
 * Tabular data describing orders, customers, leads, etc.
 */
export interface DatasetPostInput {
  connectionId?: string | null;
  identitySets: SojJSON;
  incrementalColumn?: string | null;
  name: string;
  options: SojJSON;
  outputToStreams?: SojJSON | null;
  outputToTraits?: SojJSON | null;
  preview?: boolean | null;
  privacy?: Privacy | null;
  referenceKeyColumn?: string | null;
  referenceKeyColumns?: (string | null)[] | null;
  upsertColumns?: (string | null)[] | null;
}

/**
 * (Parameters used to POST a new value of the `Outcome` type.)
 * 
 * A business objective describing how likely a group of people are to transition from one cohort to another (for example, from a prospect to a customer).
 */
export interface OutcomePostInput {
  attainmentCohortId: string;
  attritionCohortId?: string | null;
  biasMitigation?: BiasMitigationInput | null;
  eligibleCohortId?: string | null;
  featureBlocklist?: (string | null)[] | null;
  name: string;
  predictors?: OutcomePredictorsPostInput | null;
}

export interface OutcomePredictorsBlockedPostInput {
  providers?: (string | null)[] | null;
}

/**
 * (Parameters used to POST a new value of the `OutcomePredictors` type.)
 * 
 * Provides control over the features used during modeling to predict an outcome.
 */
export interface OutcomePredictorsPostInput {
  blocked?: OutcomePredictorsBlockedPostInput | null;
}

/**
 * (Parameters used to POST a new value of the `PersonaSet` type.)
 * 
 * A set of customer personas.
 */
export interface PersonaSetPostInput {
  cohortId: string;
  explore?: boolean | null;
  modelingFields?: (ModelingField | null)[] | null;
  name: string;
  numberOfClusters?: number | null;
}

/**
 * (Parameters used to POST a new value of the `Recency` type.)
 * 
 * Basing cohort on recency of event
 */
export interface RecencyPostInput {
  maxDays?: number | null;
  minDays?: number | null;
  occurrence: RecencyOccurrence;
}

/**
 * (Parameters used to POST a new value of the `ScopePayload` type.)
 * 
 * The data to include for each person in this scope.
 */
export interface ScopePayloadPostInput {
  attributes?: (string | null)[] | null;
  cohortIds?: (string | null)[] | null;
  explainability?: boolean | null;
  outcomeIds?: (string | null)[] | null;
  personaSetIds?: (string | null)[] | null;
  recommenderIds?: (string | null)[] | null;
}

/**
 * (Parameters used to POST a new value of the `ScopePopulation` type.)
 * 
 * The people to include in a scope. Leave `cohort_ids` empty to include the entire US population.
 */
export interface ScopePopulationPostInput {
  cohortIds: (string | null)[];
  exclusionCohortIds?: (string | null)[] | null;
}

/**
 * (Parameters used to POST a new value of the `Scope` type.)
 * 
 * Instructions on how to produce output data.
 */
export interface ScopePostInput {
  name: string;
  payload: ScopePayloadPostInput;
  population: ScopePopulationPostInput;
  preview: boolean;
}

export interface TargetFilterCohortMembershipConditionsInput {
  cohortId: string;
  eq: boolean;
}

export interface TargetFilterOutcomePercentileConditionsInput {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  nnull?: boolean | null;
  null?: boolean | null;
  outcomeId: string;
}

export interface TargetFilterOutcomeProbabilityConditionsInput {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  nnull?: boolean | null;
  null?: boolean | null;
  outcomeId: string;
}

export interface TargetFilterOutcomeScoreConditionsInput {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  nnull?: boolean | null;
  null?: boolean | null;
  outcomeId: string;
}

export interface TargetFilterPersonaSetPersonaIdConditionsInput {
  eq: boolean;
  personaId: string;
  personaSetId: string;
}

/**
 * (Parameters used to POST a new value of the `TargetFilter` type.)
 * 
 * Filter the rows exported in a target using the scope payload elements.
 * - **Deprecated:** To filter by outcome scores, use `outcome_score`.
 * - To filter by outcome probability, use `outcome_probability`.
 * - To filter by outcome percentile, use `outcome_percentile`.
 * - To filter by persona set persona id, use `persona`.
 * - To filter by cohort membership, use `cohort_membership`.
 * - To filter by trait, use `trait`.
 * - To filter by recommender uncalibrated probability, use `recommender_uncalibrated_probability`.
 * - To filter by recommender rank, use `recommender_rank`.
 * 
 * Conditions are AND-ed together (like SQL) - each condition applies an additional constraint.
 * 
 * Nulls are excluded unless explicitly requested with `_null: true`.
 * 
 * Target filters are not supported for targets with options.type = "lookup_api".
 */
export interface TargetFilterPostInput {
  cohortMembership?: (TargetFilterCohortMembershipConditionsInput | null)[] | null;
  outcomePercentile?: (TargetFilterOutcomePercentileConditionsInput | null)[] | null;
  outcomeProbability?: (TargetFilterOutcomeProbabilityConditionsInput | null)[] | null;
  outcomeScore?: (TargetFilterOutcomeScoreConditionsInput | null)[] | null;
  persona?: (TargetFilterPersonaSetPersonaIdConditionsInput | null)[] | null;
  recommenderRank?: (TargetFilterRecommenderRankConditionsInput | null)[] | null;
  recommenderUncalibratedProbability?: (TargetFilterRecommenderUncalibratedProbabilityConditionsInput | null)[] | null;
  trait?: (TargetFilterTraitConditionsInput | null)[] | null;
}

export interface TargetFilterRecommenderRankConditionsInput {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  nnull?: boolean | null;
  null?: boolean | null;
  recommenderId: string;
}

export interface TargetFilterRecommenderUncalibratedProbabilityConditionsInput {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  nnull?: boolean | null;
  null?: boolean | null;
  recommenderId: string;
}

export interface TargetFilterTraitConditionsInput {
  eq?: string | null;
  gt?: number | null;
  gte?: number | null;
  in?: (string | null)[] | null;
  lt?: number | null;
  lte?: number | null;
  matches?: string | null;
  name: string;
  neq?: string | null;
  nin?: (string | null)[] | null;
  nnull?: boolean | null;
  null?: boolean | null;
}

/**
 * (Parameters used to POST a new value of the `Target` type.)
 * 
 * Instructions on how to export output data defined by a scope.
 * 
 * There are three types of targets:
 * <table>
 * <thead>
 * <tr><th>Target type</th><th>Description</th><th>API requirement</th></tr>
 * </thead>
 * <tbody>
 * <tr><td><strong>Publication</strong></td><td>Faraday <em>hosts</em> your predictions for convenient retrieval as needed.</td><td>Specify a <code>type</code> of <code>hosted_csv</code> in <code>options</code>. Omit <code>connection_id</code>.</td></tr>
 * <tr><td><strong>Replication</strong></td><td>Faraday copies your predictions to systems <em>you</em> control. You may then push them to third parties like Facebook, Google Ads, etc.</td><td>Specify a valid <code>connection_id</code> and the corresponding <code>type</code> of the connection in <code>options</code>.</td></tr>
 * <tr><td><strong>Managed</strong></td><td>Faraday manages a push to third parties like Facebook, Google Ads, and more.</td><td>Must be on an enterprise plan. Contact Customer Success to set up.</td></tr>
 * </tbody>
 * </table>
 */
export interface TargetPostInput {
  connectionId?: string | null;
  customStructure?: (TargetStructureTransformationInput | null)[] | null;
  filter?: TargetFilterPostInput | null;
  humanReadable?: boolean | null;
  limit?: SojJSON | null;
  name: string;
  options: SojJSON;
  representation: SojJSON;
  scopeId: string;
}

export interface TargetStructureTransformationInput {
  aggregation?: Aggregation | null;
  aggregationCriteria?: string | null;
  isIdentityCol?: boolean | null;
  outputCol: string;
  scopeCol: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
