import { Box } from "@chakra-ui/react";

import { ModalV2 } from "../../ui/ModalV2";
import { UsageWarningNotice } from "../../ui/UsageWarningNotice";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { TargetConnectionState } from "./PipelineTargetManager";
import { TargetNewForm } from "./TargetNewForm";

/**
 * Render a modal to create a new target.
 */
export function TargetNewModal({
  scope,
  onTargetAdded,
  onClose,
  connection,
  isOpen,
}: {
  connection: TargetConnectionState | undefined;
  onClose: () => void;
  onTargetAdded: () => void;
  scope: PipelineFragment;
  isOpen: boolean;
}) {
  if (!connection) return null;

  const title = `New ${connection.info.literate} deployment`;

  return (
    <ModalV2
      title={title}
      onClose={onClose}
      isOpen={isOpen}
      variant="flush"
      width="960px"
      analyticsStackName="new-target-modal"
    >
      <Box
        sx={{
          // Hack in the padding here again because the modal has no body padding.
          p: 4,
          // Hide the padded box when there's no warning rendered.
          _empty: { display: "none" },
        }}
      >
        <UsageWarningNotice resource="targets" />
      </Box>

      <TargetNewForm
        scope={scope}
        targetConnectionState={connection}
        onBack={onClose}
        onCompleted={() => {
          onTargetAdded();
          onClose();
        }}
      />
    </ModalV2>
  );
}
