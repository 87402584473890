import { useEffect, useState } from "react";

import { useAuth } from "./AuthProvider";

export function useToken(): string | null {
  const auth = useAuth();
  const [token, setToken] = useState<string | null>(null);
  let shouldSetToken = false;

  useEffect(() => {
    shouldSetToken = true;
    auth.getToken().then((token) => {
      if (shouldSetToken) setToken(token);
    });

    return () => {
      shouldSetToken = false;
    };
  }, [auth]);

  return token;
}
