import { Text } from "@chakra-ui/react";
import { AxisScaleOutput } from "@visx/axis";
import { ScaleConfig } from "@visx/scale";
import { Grid, lightTheme, Margin, Tooltip, XYChart } from "@visx/xychart";
import { RenderTooltipParams } from "@visx/xychart/lib/components/Tooltip";
import { CSSProperties, ReactNode } from "react";
import * as React from "react";

import chakraThemeV2, { colors } from "../../../styles/chakra-theme-v2";
import { ChartLegend, ChartLegendProps } from "./ChartLegend";
import { FdyChartProvider, useFdyChartContext } from "./FdyChartProvider";

export type RenderTooltipCallback<Datum extends object> = (
  params: RenderTooltipParams<Datum>
) => React.ReactNode;

export interface ChartProps<
  // Using `any` since the prop types come from examples within XYChart
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  XScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  YScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  Datum extends object
> {
  yScale: YScaleConfig;
  xScale: XScaleConfig;
  title?: ReactNode;
  accessibilityLabel?: string;
  children: ReactNode;
  legend?: ChartLegendProps;
  height?: number;
  margin?: Margin;
  renderTooltip?: RenderTooltipCallback<Datum>;
  aspectRatio?: CSSProperties["aspectRatio"];
  maxWidth?: CSSProperties["maxWidth"];
}

const chartWrapStyles = {
  minWidth: 400,
  border: "1px solid",
  borderColor: colors.fdy_gray[300],
  borderRadius: 6,
};

/**
 * Sub-component to render the chart itself.
 * Needed so FdyChartContext can be used.
 */
function InnerChart<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  XScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  YScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  Datum extends object
>(props: ChartProps<XScaleConfig, YScaleConfig, Datum>) {
  const {
    height = 500,
    accessibilityLabel,
    xScale,
    yScale,
    renderTooltip,
    children,
  } = props;

  const { margin } = useFdyChartContext();
  return (
    <XYChart
      accessibilityLabel={accessibilityLabel}
      height={height}
      xScale={xScale}
      yScale={yScale}
      margin={margin}
      theme={{
        ...lightTheme,
        colors: [colors.fdy_purple[500]],
      }}
    >
      <Grid
        key="grid"
        columns={false}
        rows={true}
        lineStyle={{ stroke: colors.fdy_gray[300] }}
        numTicks={6}
      />

      {children}

      {renderTooltip ? (
        <Tooltip<Datum>
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          renderTooltip={renderTooltip}
          style={{
            position: "absolute",
            backgroundColor: colors.fdy_gray[900],
            color: "white",
            borderRadius: 6,
            padding: 8,
            fontSize: 14,
            lineHeight: 1.5,
            fontFamily: chakraThemeV2.fonts.body,
            zIndex: 10000, // appear in front of modal in case chart is in modal
          }}
        />
      ) : null}
    </XYChart>
  );
}

/**
 * Base chart wrapper for most charts made with visx.
 * Styles the wrapper and grid as Faraday desires.
 */
export function Chart<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  XScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  YScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  Datum extends object
>(props: ChartProps<XScaleConfig, YScaleConfig, Datum>) {
  const { aspectRatio, maxWidth, title, legend } = props;
  return (
    <FdyChartProvider>
      <div style={{ ...chartWrapStyles, aspectRatio, maxWidth }}>
        {title && (
          <Text
            sx={{
              textAlign: "center",
              py: 4,
            }}
          >
            {title}
          </Text>
        )}

        <InnerChart {...props} />

        {legend && <ChartLegend {...legend} />}
      </div>
    </FdyChartProvider>
  );
}
