import styled from "@emotion/styled";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import ReactPagination, { PaginationProps } from "rc-pagination";
import { ReactElement, ReactNode } from "react";

import { theme } from "../../../constants/theme";
import { colors } from "../../../styles/chakra-theme-v2";

const localeInfo = {
  items_per_page: "/ page",
  jump_to: "Go to",
  jump_to_confirm: "confirm",
  page: "Page",
  prev_page: "Previous Page",
  next_page: "Next Page",
  prev_5: "Previous 5 Pages",
  next_5: "Next 5 Pages",
  prev_3: "Previous 3 Pages",
  next_3: "Next 3 Pages",
  page_size: "Page Size",
};

const StyledPagination = styled(ReactPagination)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.space(0.5)};
`;

const BaseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: ${theme.radii.md};
  padding-block: ${theme.space(1)};
  padding-inline: ${theme.space(2)};
  cursor: pointer;
`;

const PrevNextBtn = styled(BaseBtn)`
  &:hover {
    background: ${theme.colors.lighter_gray};
  }

  &:disabled {
    opacity: ${theme.opacities.disabled};
    pointer-events: none;
  }
`;

const PageBtn = styled(BaseBtn)`
  background: white;
  border-color: ${colors.fdy_gray[300]};

  &:hover {
    background: ${colors.fdy_gray[300]};
  }

  &[aria-current] {
    background: ${colors.fdy_purple[500]};
    color: white;
  }

  &:disabled {
    background: ${colors.fdy_gray[100]};
    pointer-events: none;
  }
`;

/**
 * Renders traditional pagination controls with next/prev buttons, and numbers for each page.
 * Internally uses rc-pagination package, but with some custom styling.
 *
 * It's nonideal to have a tiny bit of localization but rc-pagination is Chinese locales by default.
 * Even so, this is the most applicable react pagination package I could find (maybe react-paginate as runner up).
 */
export function Pagination(props: PaginationProps): ReactElement {
  const itemRender: PaginationProps["itemRender"] = (page, type) => {
    const pageBtn = (
      <PageBtn
        aria-label={`Page ${page}`}
        aria-current={props.current === page ? true : undefined}
      >
        {page}
      </PageBtn>
    );

    const ellipsisBtn = <PageBtn disabled>&hellip;</PageBtn>;

    const btnMaps: Record<typeof type, ReactNode> = {
      page: pageBtn,
      prev: (
        <PrevNextBtn>
          <CaretLeft />
        </PrevNextBtn>
      ),
      next: (
        <PrevNextBtn>
          <CaretRight />
        </PrevNextBtn>
      ),
      "jump-prev": ellipsisBtn,
      "jump-next": ellipsisBtn,
    };

    return btnMaps[type];
  };

  return (
    <nav>
      <StyledPagination
        {...props}
        locale={localeInfo}
        itemRender={itemRender}
        hideOnSinglePage
      />
    </nav>
  );
}
