import styled from "@emotion/styled";

interface TruncateProps {
  title?: string;
}

export const Truncate = styled.span<TruncateProps>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
`;
