import { gql } from "@apollo/client";

export const cohortFragment = gql`
  fragment CohortFragment on Cohort {
    id
    explore
    name
    status
    statusError
    updatedAt
    archivedAt
    classic
    lastUpdatedOutputAt
    lastUpdatedConfigAt
    createdAt
    lastReadInputAt
    populationCount

    # events
    streamName
    streamConditions {
      property
      optional
      eq
      matches
      gt
      gte
      lt
      lte
      nin
      in
      null
      nnull
    }
    maxCount
    maxValue
    minCount
    minValue
    populationCount
    recency {
      occurrence
      minDays
      maxDays
    }

    # traits
    traits {
      name
      optional
      eq
      matches
      gt
      gte
      lt
      lte
      nin
      in
      null
      nnull
    }

    placeConditions {
      placeId
      distance
      invert
    }
  }
`;
