import { LockSimpleOpen } from "@phosphor-icons/react";

import {
  AccountFeature,
  useAccountConfigMap,
} from "../../../hooks/accountConfigHooks";
import { ChatLink } from "../../ui/ChatLink";
import { Notice } from "../../ui/Notice";

export function FeatureLockedNotice({
  unlessEnabled,
}: {
  unlessEnabled: AccountFeature[];
}) {
  const configMap = useAccountConfigMap();

  // if all features are enabled already, don't show the notice
  if (unlessEnabled.every((f) => configMap[f] === true)) return null;

  return (
    <Notice
      icon={LockSimpleOpen}
      variant="info"
      title="Need a feature unlocked?"
      description={
        <>
          Reach out to <ChatLink>support</ChatLink> to enable locked
          functionality.
        </>
      }
    />
  );
}
