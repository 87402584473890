import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { Eye, EyeSlash } from "@phosphor-icons/react";
import { ReactElement, useState } from "react";

/**
 * Render a text input with a toggle button to obfuscate/reveal the input value.
 */
export function PasswordInput({
  visibilityButtonLabel,
  ...props
}: InputProps & {
  visibilityButtonLabel?: string;
}): ReactElement {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const prefix = show ? "Hide" : "Show";
  const toggleAriaLabel = visibilityButtonLabel
    ? `${prefix} ${visibilityButtonLabel}`
    : `${prefix} password`;

  return (
    <InputGroup>
      <Input type={show ? "text" : "password"} {...props} />
      <InputRightElement pointerEvents="all" pr={0}>
        <Button
          variant="icon"
          h="1.75rem"
          size="sm"
          onClick={handleClick}
          aria-label={toggleAriaLabel}
        >
          {show ? <EyeSlash /> : <Eye />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
