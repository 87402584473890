/**
 * Use this theme for purple primary color and Aktiv Grotesk font designs.
 */

import { extendTheme, ThemeOverride } from "@chakra-ui/react";

import { components } from "./chakra-components";

/**
 * Colors
 *
 * Prefixed with fdy_ to avoid mixing existing color ranges with chakra's defaults.
 * Hopefully Chakra will support a way to disable its defaults entirely.
 *
 * Note: Not all colors have a full 100-900 color range.
 * It's unlikely there would be use cases for those additional ranges.
 */
export const colors = {
  fdy_purple: {
    100: "#eee1fe",
    200: "#cba5fd",
    400: "#6e14e1",
    500: "#5810b3",
    600: "#1c0538",
  },
  fdy_magenta: {
    200: "#fce3f5",
    300: "#f7abe2",
    400: "#ce1299",
    500: "#b31085",
    600: "#38052a",
  },
  fdy_red: {
    200: "#fee1e1",
    300: "#fda5a5",
    400: "#de3535",
    500: "#a71b1b",
  },
  fdy_yellow: {
    300: "#fff6e0",
    500: "#ffc839",
    600: "#856514",
  },
  fdy_green: {
    100: "#F8FEFC",
    200: "#EFFCF8",
    300: "#e5fbf4",
    340: "#D0F7EB",
    380: "#C0F4E4",
    420: "#A1EFD6",
    460: "#81E9C9",
    500: "#62e4bb",
    533: "#4EB696",
    566: "#3B8970",
    600: "#0d7156",
    750: "#1D4438",
    900: "#142E25",
  },
  fdy_gray: {
    100: "#f5f4f5",
    200: "#eaeaeb",
    300: "#e0dfe2",
    400: "#d1cfd3",
    500: "#b2afb6",
    600: "#939098",
    700: "#6b676f",
    800: "#424045",
    900: "#19181b",
  },
  fdy_orange: {
    500: "#e46715",
  },
  white: "#fff",
  black: "#000",
};

export const fontSizes = {
  fdy_xs: "0.694rem",
  fdy_sm: "0.875rem",
  fdy_md: "1rem",
  fdy_lg: "1.2rem",
  fdy_xl: "1.44rem",
  fdy_2xl: "1.728rem",
  fdy_3xl: "2.074rem",
  fdy_4xl: "2.448rem",
};

/**
 * Adjust chakra default theme to fit our needs.
 *
 * https://chakra-ui.com/docs/theming
 */
const override: ThemeOverride = {
  /**
   * Global styles
   */
  styles: {
    global: {
      body: {
        fontFamily: "Aktiv Grotesk, sans-serif",
        bg: "fdy_gray.100",
        color: "fdy_gray.900",
      },
      "*": {
        borderColor: "fdy_gray.300",
      },
      "*::placeholder": {
        color: "fdy_gray.600",
      },
      "#chakra-toast-manager-bottom": {
        pb: 100,
      },
      li: {
        listStyle: "none",
      },

      // input addons are not currently themeable through components,
      // so just use a selector
      ".chakra-input__right-element": {
        width: "auto!important",
        color: "fdy_gray.600",
        fontWeight: "bold",
        pr: 3,
        pointerEvents: "none",
      },
    },
  },

  /**
   * Apply our custom component styles
   * https://chakra-ui.com/docs/styled-system/theming/component-style
   */
  components,

  colors,

  /**
   * Typography
   */

  fonts: {
    body: "Aktiv Grotesk, sans-serif",
    heading: "Aktiv Grotesk, sans-serif",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.5,
  },

  fontSizes,

  /**
   * Sizes and spacing
   */

  sizes: {
    // roughly set a readable line length, usually for paragraphs of text
    prose: "65ch",
  },

  /**
   * We use chakra's default space scale, which is mostly multiples of 4px
   * https://chakra-ui.com/docs/styled-system/theming/theme#spacing
   *
   * If we have to add any custom ones, uncomment and add below.
   */
  // space: {},

  /**
   * Other
   */
  shadows: {
    modal: "0px 4px 32px 0px hsla(0, 0%, 40%, 0.16)",
    diffuse: "0px 4px 24px 0px hsla(266, 84%, 12%, 0.04)",
    "diffuse-hover": "0px 4px 32px 0px hsla(267, 84%, 12%, 0.12)",
    outline: "0 0 0 2px hsla(266, 83%, 38%, 0.5)",
    "error-inset": "inset 4px 0 0 0 var(--chakra-colors-fdy_red-500)",
  },

  /**
   * Semantic tokens
   *
   * https://chakra-ui.com/docs/styled-system/features/semantic-tokens
   */
  semanticTokens: {
    colors: {
      primary: colors.fdy_purple[500],
      secondary: colors.fdy_magenta[500],
      success: colors.fdy_green[500],
      error: colors.fdy_red[500],
      warning: colors.fdy_yellow[500],
      info: colors.fdy_gray[600],
    },
  },
};

export const chakraThemeV2 = extendTheme(override);

// export for cli tokens
export default chakraThemeV2;
