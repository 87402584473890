import { Text } from "@chakra-ui/react";
import { CheckCircle, Warning } from "@phosphor-icons/react";

export function BiasLevelIcon({ ok }: { ok: boolean }) {
  return (
    <Text as="span" color={ok ? "success" : "warning"}>
      {ok ? <CheckCircle /> : <Warning />}
    </Text>
  );
}
