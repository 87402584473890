import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { IdentitySet } from "@fdy/faraday-js";
import { Dispatch, SetStateAction } from "react";
import Select from "react-select";

import {
  analyticsAttrs,
  useAnalyticsKeyGenerator,
} from "../../../../ui/Analytics/AnalyticsStack";
import { ErrorText } from "../../../../ui/ErrorText";
import {
  DatasetSelectOption,
  reactSelectStyle,
} from "../../../shared/reactSelectStyle";
import {
  formatOptionLabel,
  hintFromSampleData,
  SampleData,
} from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";

// TODO: import the other as needed instead of aliasing here
export type IdentityProperties = IdentitySet;

// Convert house_number_and_street array to single address_1 and address_2 keys
// cause it's easier to work with in local state.
// It should be converted back to house_number_and_street when sending to the
// API with propertiesToWire.
export type IdentityPropertiesState = Omit<
  IdentitySet,
  "house_number_and_street"
> & {
  address_1?: string;
  address_2?: string;
};

type IdentityPropertyName = keyof IdentityPropertiesState;

const IDENTITY_PROPERTY_LABELS: Record<IdentityPropertyName, string> = {
  email: "Email",
  email_hash: "SHA-256 email hash",
  phone: "Phone",
  person_first_name: "First name",
  person_last_name: "Last name",
  person_full_name: "Full name",
  address_1: "Address line 1",
  address_2: "Address line 2",
  city: "City",
  state: "State",
  postcode: "Zip",
  freeform_address: "Street address, city, state, zip (freeform)",
};

type IdentityPropertyField = {
  name: IdentityPropertyName;
  label: string;
  value: DatasetSelectOption | null;
  isInDetectedColumns: boolean;
};

export function DatasetIdentitySetsPropertiesTable({
  properties,
  setProperties,
  detectedColumns,
  sampleData,
}: {
  properties: IdentityPropertiesState;
  setProperties: Dispatch<SetStateAction<IdentityPropertiesState>>;
  detectedColumns: DetectedColumn[];
  sampleData?: SampleData;
}) {
  const selectedColumns = Object.values(properties)
    // house_number_and_street is an array so flatmap to return a single value
    .flatMap((value) => value);

  const columnOptions: DatasetSelectOption[] = detectedColumns.map((column) => {
    return {
      value: column.name,
      label: column.name,
      isDisabled: selectedColumns.includes(column.name),
      hint: hintFromSampleData({ sampleData, column }),
      fillRate: column.fillRate ?? undefined,
    };
  });

  // inject any currently selected properties even if no longer in detectedColumns
  Object.values(properties).forEach((columnName) => {
    if (columnName && !columnOptions.find((opt) => opt.value === columnName)) {
      columnOptions.push({
        value: columnName,
        label: columnName,
        isDisabled: selectedColumns.includes(columnName),
      });
    }
  });

  const fields: IdentityPropertyField[] = Object.entries(
    IDENTITY_PROPERTY_LABELS
  ).map(([name, label]) => {
    const identityProperty = name as IdentityPropertyName;

    const value =
      columnOptions.find((opt) => opt.value === properties[identityProperty]) ??
      null;

    const isInDetectedColumns = detectedColumns.some(
      (col) => col.name === value?.value
    );

    return {
      name: identityProperty,
      label,
      value,
      isInDetectedColumns,
    };
  });

  const handleSelectChange = (
    newValue: DatasetSelectOption | null,
    field: IdentityPropertyField
  ) => {
    setProperties((state) => {
      return {
        ...state,
        [field.name]: newValue?.value ?? null,
      };
    });
  };
  const getAnalyticsKey = useAnalyticsKeyGenerator("identity-set");

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Property name</Th>
          <Th width="70%">Field in dataset</Th>
        </Tr>
      </Thead>
      <Tbody>
        {fields.map((field) => {
          return (
            <Tr key={field.label}>
              <Td>{field.label}</Td>
              <Td p={1} {...analyticsAttrs(getAnalyticsKey(field.name))}>
                <Select<DatasetSelectOption>
                  isClearable
                  isDisabled={properties.email === "classic"}
                  name={field.name}
                  aria-label={`${field.label} select`}
                  options={columnOptions}
                  formatOptionLabel={formatOptionLabel}
                  value={field.value}
                  onChange={(newValue) => handleSelectChange(newValue, field)}
                  styles={reactSelectStyle}
                  menuPortalTarget={document.body} // select options should appear outside modal
                />
                {field.value && !field.isInDetectedColumns && (
                  <ErrorText>
                    Invalid field selected. Clear or choose another.
                  </ErrorText>
                )}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
