import { gql } from "@apollo/client";
import { ApolloError } from "@apollo/client";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import {
  HUBSPOT_USER_UPDATED_DATASET,
  useHubSpotEvent,
} from "../../../hooks/useHubspotEvent";
import { useToast } from "../../../hooks/useToast";
import { useSojournerMutation } from "../../../services/sojournerApolloClient";
import { components } from "../../../sojourner-oas-types";
import {
  DatasetEditMutation,
  DatasetEditMutationVariables,
} from "../__generated__/DatasetEditMutation";
import { datasetEditMutation } from "../sharedQueries";

type DatasetMergePatch = components["schemas"]["DatasetMergePatch"];

export function useUpdateDataset(options?: {
  onCompleted?: (data: DatasetEditMutation) => void;
  onError?: (error: ApolloError) => void;
}) {
  const toast = useToast();
  const track = useHubSpotEvent();
  const [mutate, { loading: updating, client }] = useSojournerMutation<
    DatasetEditMutation,
    DatasetEditMutationVariables
  >(datasetEditMutation, {
    onCompleted: (data: DatasetEditMutation) => {
      if (!data.updateDataset) return;

      const { name, id } = data.updateDataset;
      toast({
        status: "success",
        title: `${name} saved`,
        description: `Your changes to ${name} have been saved`,
      });

      track(HUBSPOT_USER_UPDATED_DATASET, {
        resource_id: id,
        resource_name: name,
      });

      // if the dataset is creating a new stream, we need to clear the cache
      // for ANY queries that have fetched 'streams'
      client.cache.evict({
        id: "ROOT_QUERY",
        fieldName: "streams",
      });
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
    },
    onError: options?.onError,
  });

  function updateDataset(id: string, input: DatasetMergePatch) {
    return mutate({
      variables: {
        datasetId: id,
        dataset: JSON.stringify(input),
      },
      // same process used on PipelinesEditPage
      // to get ahead of the job status by automatically mutating
      // the resource status when a config change takes place

      update(cache, { data }) {
        if (data?.updateDataset) {
          cache.writeFragment({
            id: `Dataset:${id}`,
            fragment: gql`
              fragment DatasetStatus on Dataset {
                status
              }
            `,
            data: {
              status: ResourceStatus.RUNNING,
            },
          });
        }
      },
    });
  }

  return {
    updateDataset,
    updating,
  };
}
