import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { DotsThreeOutline, PencilSimple, Trash } from "@phosphor-icons/react";
import { ReactNode } from "react";

/**
 * Renders a short gray region with title, optional content, and meatball menu to
 * take actions on the entity.
 *
 * Usually use this in Cardv2 for when a resource has rows of information to manage.
 **/
export function ActionableCardItem({
  leftIcon,
  title,
  subtitle,
  content,
  onEdit,
  onDelete,
  menuBtnLabel,
  compact,
  actionsDisabled,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
  leftIcon?: ReactNode;
  content?: ReactNode;
  menuBtnLabel?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  compact?: boolean;
  actionsDisabled?: boolean;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        bg: "fdy_gray.100",
        border: "1px solid",
        borderColor: "fdy_gray.400",
        borderRadius: "md",
        py: compact ? 2 : 4,
        px: 5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 4,
          alignItems: "center",
        }}
      >
        {leftIcon && (
          <Box
            sx={{
              alignSelf: "flex-start",
              flex: "0 0 auto",
            }}
          >
            {leftIcon}
          </Box>
        )}
        <Box>
          <Text
            as="h3"
            sx={{
              fontWeight: "bold",
              fontSize: compact ? "fdy_md" : "fdy_lg",
            }}
          >
            {title}
          </Text>
          {subtitle && (
            <Box mt={compact ? 0 : 2} fontSize={compact ? "fdy_sm" : "fdy_md"}>
              {subtitle}
            </Box>
          )}
        </Box>
        {content && <div>{content}</div>}
      </Box>
      {onEdit && onDelete && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label={menuBtnLabel}
            icon={<DotsThreeOutline weight="fill" />}
            variant="icon"
            size="sm"
            disabled={actionsDisabled}
          />
          <MenuList>
            {onEdit && (
              <MenuItem onClick={onEdit} aria-label={`Edit ${title}`}>
                Edit
              </MenuItem>
            )}
            {onDelete && (
              <MenuItem
                onClick={onDelete}
                color="fdy_red.500"
                aria-label={`Remove ${title}`}
              >
                Remove
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
      {/* reduce click economy */}
      {onEdit && !onDelete && (
        <IconButton
          variant="icon"
          color="fdy_gray.600"
          aria-label={`Edit ${title}`}
          icon={<PencilSimple />}
          onClick={onEdit}
        />
      )}
      {!onEdit && onDelete && (
        <IconButton
          variant="icon"
          color="fdy_gray.600"
          aria-label={`Delete ${title}`}
          icon={<Trash />}
          onClick={onDelete}
        />
      )}
    </Box>
  );
}
