import { ResourceStatus, ResourceType } from "@fdy/faraday-js";
import {
  ArrowsClockwise,
  CheckCircle,
  ClockCountdown,
  Cube,
  Icon,
  Warning,
} from "@phosphor-icons/react";
import { format, formatDistanceToNow } from "date-fns";
import capitalize from "lodash/capitalize";

import { typeAlias } from "../../../constants/typeAlias";
import { NoticeVariant } from "../Notice";
import {
  MixedResource,
  normalizeResourceDetails,
} from "./normalizeResourceDetails";

export type MixedResourceDependency = {
  name: string;
  status: MixedResource["status"];
};

export type ResourceStatusInfo = {
  variant: NoticeVariant;
  icon: Icon;
  title: string;
  shortTitle: string;
  description: string;
};

/**
 * Given a resource and optionally its dependencies, return the user-facing messaging
 * regarding the status of a resource.
 */
export function getStatusInfoFromResource({
  resource,
  readyAsTimestamp,
}: {
  resource: MixedResource;
  readyAsTimestamp?: boolean;
}): ResourceStatusInfo | undefined {
  const res = normalizeResourceDetails(resource);
  const resourceName = typeAlias[res.resource_type as ResourceType];
  const capResourceName = capitalize(resourceName);

  const runtype = res.last_updated_output_at ? "refresh" : "build";

  const isStartingOrRunning =
    res.status === ResourceStatus.Starting ||
    res.status === ResourceStatus.Running;

  const isConfigUpdatedAfterLastRead = Boolean(
    !res.archived_at &&
      res.last_read_input_at &&
      res.last_updated_config_at &&
      new Date(res.last_updated_config_at) > new Date(res.last_read_input_at)
  );

  const hasUpdatedOutput = Boolean(res.last_updated_output_at);

  if (
    res.status === ResourceStatus.Error &&
    res.status_error?.includes("automatically paused")
  ) {
    return {
      variant: "warning",
      icon: Warning,
      title: `${capResourceName} paused.`,
      shortTitle: "Paused",
      description: res.status_error,
    };
  }

  if (res.status === ResourceStatus.Error) {
    return {
      variant: "error",
      icon: Warning,
      title: `${capResourceName} ${runtype} error.`,
      shortTitle: "Error",
      description: `There was a problem ${runtype}ing this ${resourceName}. ${
        res.status_error ?? ""
      }`,
    };
  }

  if (res.status === ResourceStatus.New) {
    return {
      variant: "info",
      icon: ClockCountdown,
      title: `${capResourceName} is queued.`,
      shortTitle: "Queued...",
      description: `If there are any upstream dependencies, building will start when they are ready. You can configure other resources with this ${resourceName} in the meantime.`,
    };
  }

  if (isStartingOrRunning && !hasUpdatedOutput) {
    return {
      variant: "info",
      icon: Cube,
      title: `${capResourceName} is building.`,
      shortTitle: "Building...",
      description: `You can configure other resources with this ${resourceName} in the meantime.`,
    };
  }

  if (
    (isStartingOrRunning || isConfigUpdatedAfterLastRead) &&
    hasUpdatedOutput
  ) {
    return {
      variant: "info",
      icon: ArrowsClockwise,
      title: `${capResourceName} is refreshing in the background.`,
      shortTitle: "Refreshing...",
      description: `You can still use this ${resourceName} like normal.`,
    };
  }

  if (res.status === ResourceStatus.Ready && res.last_updated_output_at) {
    const timestamp = format(res.last_updated_output_at, "MMM d, yyyy h:mm a");
    const readySynonym = resourceName === "dataset" ? "connected" : "ready";
    return {
      variant: "success",
      icon: readyAsTimestamp ? ArrowsClockwise : CheckCircle,
      title: `${capResourceName} is ${readySynonym}.`,
      shortTitle: readyAsTimestamp
        ? formatDistanceToNow(res.last_updated_output_at)
        : "Ready",
      description: `Last built ${timestamp}`,
    };
  }

  if (res.status === ResourceStatus.Ready && !res.last_updated_output_at) {
    if (resourceName === "dataset") {
      // This is a special case where we show a warning instead of a "please
      // contact support" message. Datasets should only be in this state if they
      // are missing config and we have a notice indicating they need to add
      // identity_sets/events/traits to complete the dataset.
      return;
    }
    return {
      variant: "info",
      icon: Warning,
      title: `${capResourceName} has run with no output.`,
      shortTitle: "Empty",
      description: `${capResourceName} has run with no output. If you expected output, please contact support.`,
    };
  }
}
