import {
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { RecommenderPerformanceModel } from "@fdy/faraday-js";

import { BarChart } from "../../ui/charts-v2/BarChart";
import { RocChart } from "../../ui/RocChart";
import { TabularDataTable } from "../../ui/TabularDataTable";
import { RecommenderAccuracyCurveChart } from "./RecommenderAccuracyChart";

// some accounts may have lots of properties, so we limit the number of properties shown
const MAX_PROPERTIES = 20;

export function RecommenderAnalysisPerformanceCharts({
  charts,
}: {
  charts: RecommenderPerformanceModel;
}) {
  const propertyDistribution = charts.property_distribution.sort(
    (a, b) => b.proportion - a.proportion
  );

  const topProperties = propertyDistribution.slice(0, MAX_PROPERTIES);
  const hasMoreProperties = propertyDistribution.length > MAX_PROPERTIES;

  const propertyDistributionLabel =
    "Property distribution" +
    (hasMoreProperties ? ` (top ${MAX_PROPERTIES})` : "");

  return (
    <Tabs size="sm">
      <TabList>
        {/* coming soon */}
        {/* <Tab>Performance</Tab> */}
        <Tab>Metrics</Tab>
        <Tab>Accuracy chart</Tab>
        <Tab>Accuracy table</Tab>
        <Tab>ROC AUC</Tab>
      </TabList>
      <TabPanels>
        {/* <TabPanel>
          grid
          {JSON.stringify({
            roc_auc: charts.roc_auc,
            avg_rank_true_label: charts.avg_rank_true_label,
          })}
        </TabPanel> */}
        <TabPanel>
          <TabularDataTable
            headers={["Metric", "Value", "Description"]}
            rows={[
              [
                "ROC",
                charts.roc_auc.toPrecision(4),
                "Receiver operating characteristic",
              ],
              [
                "AROOP",
                charts.avg_rank_true_label?.toPrecision(4),
                "Average rank of observed property",
              ],
            ]}
          />
        </TabPanel>
        <TabPanel>
          <RecommenderAccuracyCurveChart
            title="Accuracy per threshold"
            xLabel="Threshold"
            yLabel="Accuracy"
            data={charts.accuracy_per_threshold.map((d) => ({
              x: d.threshold,
              y: d.accuracy,
            }))}
          />

          <Spacer my={6} />

          <BarChart
            title={propertyDistributionLabel}
            xLabel="Property"
            yLabel="Proportion"
            data={topProperties.map((d) => ({
              x: d.property,
              y: Number(d.proportion.toPrecision(2)),
            }))}
          />
        </TabPanel>
        <TabPanel>
          <TabularDataTable
            title="Accuracy per threshold"
            headers={["Threshold", "Accuracy"]}
            rows={charts.accuracy_per_threshold.map((d) => [
              d.threshold.toString(),
              d.accuracy.toPrecision(4),
            ])}
          />

          <Spacer my={8} />

          <TabularDataTable
            title={propertyDistributionLabel}
            headers={[
              "Property",
              "Proportion",
              "Average rank",
              "Average rank true label",
            ]}
            rows={topProperties.map((d) => [
              d.property,
              d.proportion.toPrecision(4),
              d.avg_rank.toPrecision(4),
              d.avg_rank_true_label?.toPrecision(4),
            ])}
          />
        </TabPanel>

        <TabPanel>
          <RocChart data={charts.roc_curve} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
