import { AccordionV2 } from "../../../ui/AccordionV2";
import { FeatureImportanceBar } from "../../../ui/FeatureImportanceBar";
import { OutcomeAnalysisFeature } from "../__generated__/OutcomeAnalysisFeature";
import { AnalysisFeatureTitle } from "./AnalysisFeatureTitle";
import { OutcomeFeatureDirectionalityChart } from "./OutcomeFeatureDirectionalityChart";

export function OutcomeDataCardFeatureAccordionItem({
  feature,
  maxImportance,
  outcomeName,
  eligibleCohortName,
}: {
  feature: OutcomeAnalysisFeature;
  maxImportance: number;
  outcomeName: string;
  eligibleCohortName: string | null;
}) {
  const featureName = feature.literate;

  return (
    <AccordionV2.Item
      title={
        <AnalysisFeatureTitle name={feature.name} literate={feature.literate} />
      }
      afterTitle={
        <FeatureImportanceBar feature={feature} maxImportance={maxImportance} />
      }
    >
      <OutcomeFeatureDirectionalityChart
        outcomeName={outcomeName}
        eligibleCohortName={eligibleCohortName}
        featureName={featureName}
        directionality={feature.directionality}
      />
    </AccordionV2.Item>
  );
}
