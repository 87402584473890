import { AccountConfigMap } from "../../../../../hooks/accountConfigHooks";
import { IdentitySetState } from "./DatasetsIdentitySetsModal";
import {
  IdentityProperties,
  IdentityPropertiesState,
} from "./DatasetsIdentitySetsPropertiesTable";

export type IdentitySetErrors = {
  name?: string;
  properties?: string;
};

export function propertiesFromWire(
  properties: IdentityProperties
): IdentityPropertiesState {
  const { house_number_and_street, ...rest } = properties;
  return {
    ...rest,
    address_1: house_number_and_street?.[0],
    address_2: house_number_and_street?.[1],
  };
}

export function propertiesToWire(
  propertiesState: IdentityPropertiesState
): IdentityProperties {
  // take address lines so they are omitted from the rest before returning
  const { address_1, address_2, ...rest } = propertiesState;

  const addr = [];
  if (address_1) addr.push(address_1);
  if (address_2) addr.push(address_2);

  return {
    ...rest,
    house_number_and_street: addr,
  };
}

// Checks that user is entering an identity that we can match with
// different variations of required PII to match.
function hasIdentityPropertySet(properties: IdentityProperties) {
  // ensure keys are those of populated values
  const keys = Object.keys(properties).filter(
    (key) => properties[key as keyof IdentityProperties]
  ) as (keyof IdentityProperties)[];

  const hasPhoneOrEmail =
    keys.includes("email") ||
    keys.includes("phone") ||
    keys.includes("email_hash");

  const hasName =
    (keys.includes("person_first_name") && keys.includes("person_last_name")) ||
    keys.includes("person_full_name");

  // either has freeform address, address + zip, or address + city + state
  const hasAddress =
    keys.includes("freeform_address") ||
    (keys.includes("house_number_and_street") &&
      (keys.includes("postcode") ||
        (keys.includes("city") && keys.includes("state"))));

  return hasPhoneOrEmail || (hasName && hasAddress);
}

export const nameHelpText =
  "Name must only contain lowercase letters, numbers, and underscores";

export function validateIdentitySet(
  state: IdentitySetState,
  config: AccountConfigMap
) {
  const { name, properties } = state;

  const errors: IdentitySetErrors = {};

  const trimmedName = name.trim();

  if (!trimmedName) {
    errors.name = "Name is required";
  } else if (!trimmedName.match(/^[a-z0-9_]+$/)) {
    errors.name = nameHelpText;
  }

  const addressOnlyDataAccount = config["address_only_data.enabled"];
  const propertiesForWire = propertiesToWire(properties);

  if (Object.values(properties).filter((v) => v).length === 0) {
    errors.properties =
      "Please include at least one column for the identity set";
  } else if (properties.address_2 && !properties.address_1) {
    errors.properties = "Address line 2 cannot be set without address line 1";
  } else if (
    properties.freeform_address &&
    (properties.address_1 ||
      properties.address_2 ||
      properties.city ||
      properties.state ||
      properties.postcode)
  ) {
    errors.properties =
      "Cannot set freeform address and individual address fields at the same time";
  } else if (
    // If we only handle address data - don't do an identity set check, if there is incomplete
    // data the dataset itself will raise a user friendly error.
    !addressOnlyDataAccount &&
    !hasIdentityPropertySet(propertiesForWire)
  ) {
    errors.properties =
      "Email/email hash, or phone, or name and address is required";
  }

  console.log(errors);

  return Object.values(errors).length ? errors : undefined;
}
