import { ApolloProvider as ParentApolloProvider } from "@apollo/client";
import { ReactElement, ReactNode, useState } from "react";

import { ApolloGQLService } from "../../services/ApolloGQLService";
import { useAuth } from "./AuthProvider/AuthProvider";
import { useRollbar } from "./RollbarProvider";

// don't destructure process because it's replaced at build time
const NODE_ENV = process.env.NODE_ENV;

interface ApolloProviderProps {
  children: ReactNode;
}

export function ApolloProvider({
  children,
}: ApolloProviderProps): ReactElement {
  const authService = useAuth();
  const rollbar = useRollbar();
  const [client] = useState(
    () =>
      new ApolloGQLService({
        authService,
        log: NODE_ENV === "production" ? rollbar : console,
      })
  );

  return (
    <ParentApolloProvider client={client}>{children}</ParentApolloProvider>
  );
}
