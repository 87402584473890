import { ApolloError, gql } from "@apollo/client";
import { Trash } from "@phosphor-icons/react";
import { ReactElement } from "react";

import { useToast } from "../../../hooks/useToast";
import { useSojournerMutation } from "../../../services/sojournerApolloClient";
import { Button } from "../../ui/Button";
import { useModalV2 } from "../../ui/ModalV2";
import {
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
} from "./__generated__/DeleteAccountMutation";

const deleteAccountMutation = gql`
  mutation DeleteAccountMutation($accountId: ID!) {
    deleteAccount(accountId: $accountId)
  }
`;

export function AccountDeleteButton({
  accountId,
  onCompleted,
}: {
  accountId: string;
  onCompleted: () => void;
}): ReactElement {
  const { confirm } = useModalV2();
  const toast = useToast();

  const [deleteAccount, { loading }] = useSojournerMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(deleteAccountMutation, {
    onCompleted() {
      toast({
        title: "Account scheduled for deletion",
        description:
          "Your account and all resources associated with it will be deleted within 24 hours. If you wish to cancel, please contact support.",
        status: "success",
      });
      onCompleted();
    },
    onError(error: ApolloError) {
      toast({
        title: `Error deleting account`,
        description: error.message,
        status: "error",
      });
    },
  });

  function handleDeleteClick() {
    confirm({
      label: "Delete account",
      title: "Permanently delete account?",
      text: "Are you sure you want to delete your account? By clicking 'Delete', your account and all resources associated with it will be scheduled for deletion within 24 hours. If you wish to cancel scheduling, please contact support.",
      confirmLabel: "Delete",
      onConfirm: () => deleteAccount({ variables: { accountId } }),
      loading,
    });
  }

  return (
    <Button
      onClick={handleDeleteClick}
      isLoading={loading}
      disabled={loading}
      variant="danger"
      leftIcon={<Trash />}
      analyticsName="delete-account"
    >
      Delete account...
    </Button>
  );
}
