import { Text } from "@chakra-ui/react";
import { useState } from "react";
import * as React from "react";

import { DatasetPostInput } from "../../../__generated__/sojournerGlobalTypes";
import { ApiOptions } from "../../../services/connectionOptions";
import { Button } from "../../ui/Button";
import { InlineButtons } from "../../ui/InlineButtons";
import { ModalV2 } from "../../ui/ModalV2";
import { useId } from "../../ui/useId";
import {
  DatasetsTableQuery_connections as Connection,
  DatasetsTableQuery_datasets as Dataset,
} from "../__generated__/DatasetsTableQuery";
import { ConnectionTypeForm } from "../shared/DatasetsConnectionsForm";
import {
  DatasetsCreateNameField,
  validateDatasetName,
} from "./DatasetsCreateNameField";
import { DefaultConnectionTypes, hostedCSV, merged } from "./SelectConnection";
import { useCreateDataset } from "./useCreateDataset";

/**
 * Renders a form to input dataset's options for most connection types
 */
export function DatasetsCreateModalConnectionOptions({
  isOpen,
  onBack,
  onClose,
  connections,
  connectionId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  datasets: Dataset[] | undefined;
  connections: Connection[] | undefined;
  connectionId: string;
}) {
  const connectionFormId = useId("connection-setup");

  const [name, setName] = useState<string>("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [options, setOptions] = useState<ApiOptions>({ type: "hosted_csv" });

  const { createDataset, loading } = useCreateDataset();

  function getSelectedConnection(selected: string) {
    // we create these connection types for the user
    // so they may not be in the 'connections' list yet
    if (selected === DefaultConnectionTypes.hostedCSV) return hostedCSV;
    if (selected === DefaultConnectionTypes.merge) return merged;
    // user shouldn't see this. They had no connections, yet managed to pick a connection
    if (!connections) throw Error("unknown connection");
    const foundConnection = connections.find((conn) => conn.id === selected);
    // user shouldn't see this. They managed to pick a connection not in their list of connections
    if (!foundConnection) throw Error("unknown connection");
    return foundConnection;
  }

  const connection = getSelectedConnection(connectionId);

  async function createConnectionDataset() {
    const dataset: DatasetPostInput = {
      preview: true,
      name,
      // we fill in id sets after POST, when we have detected columns
      identitySets: {},
      connectionId: connectionId,
      options: {
        ...options,
        type: connection?.options.type,
      },
    };

    createDataset(dataset);
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const nameErr = validateDatasetName(name);
    if (nameErr) {
      setErrors({ ...errors, name: nameErr });
      return;
    }

    await createConnectionDataset();
  }

  if (!connections) return null;

  return (
    <ModalV2
      isOpen={isOpen}
      onClose={onClose}
      title={`${connection?.name} Dataset` ?? "New Dataset"}
      footer={
        <InlineButtons>
          <Button variant="tertiary" onClick={onBack} analyticsName="back">
            Back
          </Button>
          <Button
            variant="primary"
            type="submit"
            loadingText="Creating dataset..."
            isLoading={loading}
            disabled={loading}
            form={connectionFormId}
            analyticsName="finish"
          >
            Create dataset
          </Button>
        </InlineButtons>
      }
      analyticsStackName="dataset-connection-options"
    >
      <Text mb={4}>
        Please configure a source table for this dataset. Once this dataset is
        created, you'll be able to view and manage how your data is handled in
        the Data tab.
      </Text>

      <form id={connectionFormId} onSubmit={handleSubmit}>
        <ConnectionTypeForm
          connection={connection}
          options={options}
          setName={setName}
          setOptions={setOptions}
          displayContents={true}
        />
        <div style={{ marginTop: "1.5rem" }}>
          <DatasetsCreateNameField
            name={name}
            setName={setName}
            error={errors.name}
            setError={(val) => {
              setErrors({ ...errors, name: val });
            }}
          />
        </div>
      </form>
    </ModalV2>
  );
}
