import { Link, Td, Tr } from "@chakra-ui/react";
import { useRef } from "react";

import { TraitCategory } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { traitLiterate } from "../../utils/traitUtils";
import { Link as RouterLink } from "../ui/Link";
import { ProviderPill } from "../ui/ProviderPill";
import {
  TraitsTableQuery_datasets as Dataset,
  TraitsTableQuery_traits3 as Trait,
} from "./__generated__/TraitsTableQuery";
import { TraitActionsMenu } from "./TraitActionsMenu";
import {
  traitCategoryToLiterate,
  traitTierToLiterate,
} from "./traitCategoryToLiterate";

/**
 * Render a single row for the Traits table/list page.
 */
export function TraitTableRow({
  trait,
  datasets,
  onDeleteComplete,
}: {
  trait: Trait;
  datasets: Dataset[];
  onDeleteComplete: () => void;
}) {
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const label = `Toggle ${trait.name} actions`;

  return (
    <Tr>
      <Td data-testid={`name_${trait.id}`}>{traitLiterate(trait)}</Td>
      <Td data-testid={`provider_${trait.id}`}>
        <ProviderPill provider={trait.name} />
      </Td>
      <Td>{traitCategoryToLiterate(trait.category)}</Td>
      <Td>{traitTierToLiterate(trait.tier)}</Td>
      <Td data-testid={`details_${trait.id}`}>
        {trait.category === TraitCategory.USER_DEFINED ? (
          <RelevantDatasets trait={trait} datasets={datasets} />
        ) : (
          trait.description
        )}
      </Td>
      <Td textAlign="right">
        {trait.category === "USER_DEFINED" && (
          <TraitActionsMenu
            trait={trait}
            menuBtnRef={menuBtnRef}
            label={label}
            onDeleteComplete={onDeleteComplete}
          />
        )}
      </Td>
    </Tr>
  );
}

function RelevantDatasets({
  trait,
  datasets,
}: {
  trait: Trait;
  datasets: Dataset[];
}) {
  const relevantDatasets = datasets.filter((dataset) => {
    const datasetTraits = dataset.outputToTraits as {
      [traitName: string]: { columnName: string };
    };
    const filteredTraits = Object.keys(datasetTraits).filter(
      (datasetTrait) => datasetTrait === trait.name
    );
    if (filteredTraits.length) return true;
  });
  if (relevantDatasets.length) {
    return (
      <>
        {relevantDatasets.map((dataset) => (
          <div key={`${trait.id}_${dataset.id}`}>
            <Link
              as={RouterLink}
              routeName={ROUTE_NAMES.DATASETS_DEFINITION}
              params={{ id: dataset.id }}
              fontWeight="bold"
            >
              {dataset.name}
            </Link>
          </div>
        ))}
      </>
    );
  }
  return null;
}
