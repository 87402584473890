import { Tooltip } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { ReactElement } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { theme } from "../../constants/theme";
import chakraThemeV2, { fontSizes } from "../../styles/chakra-theme-v2";
import { Link } from "../ui/Link";
import { AccountSwitcher } from "./AccountSwitcher";

// Define an arbitrary max number of characters for the account name.
// we shouldn't _really_ need to measure text to determine truncating if we can
// assume the nav width will be consistent.
const NAME_CHARS_MAX = 16;

const StyledCurrentAccount = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${chakraThemeV2.colors.fdy_gray[700]};
  margin-bottom: ${theme.space(2)};
  line-height: 1;
`;

const StyledLogoLink = styled(Link)`
  // Without setting the font family to same as nav items, the logo shifts when
  // navigating between old design (gold) pages and new design (purple) pages.
  // TODO: Remove this when we remove the old design pages.
  font-family: ${chakraThemeV2.fonts.body};
  width: 2rem;
`;

const StyledAccountName = styled.span`
  font-family: ${chakraThemeV2.fonts.body};
  font-size: ${fontSizes.fdy_sm};
  font-weight: bold;
  color: ${chakraThemeV2.colors.fdy_gray[100]};
`;

interface CurrentAccountProps {
  account: {
    name: string;
  };
}

function TruncatedName({ name }: { name: string }) {
  if (name.length > NAME_CHARS_MAX) {
    return (
      <Tooltip label={name}>
        <StyledAccountName>
          {name.slice(0, NAME_CHARS_MAX)}...
        </StyledAccountName>
      </Tooltip>
    );
  }

  return <StyledAccountName>{name}</StyledAccountName>;
}

const Logo = () => (
  <svg viewBox="0 0 38 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 22.9998L17.0788 6.00024V22.9998H0Z" fill="#F72F96" />
    <path
      d="M37.1574 6.00024L20.0786 22.9998V6.00024H37.1574Z"
      fill="#B641FF"
    />
  </svg>
);

export function CurrentAccount({ account }: CurrentAccountProps): ReactElement {
  return (
    <StyledCurrentAccount>
      <StyledLogoLink
        routeName={ROUTE_NAMES.HOME}
        title={account.name}
        analyticsName="faraday-logo"
        activeClassName="active"
      >
        <Logo />
      </StyledLogoLink>

      <AccountSwitcher>
        <TruncatedName name={account.name} />
      </AccountSwitcher>
    </StyledCurrentAccount>
  );
}
