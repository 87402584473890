import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { DatasetsListPage } from "./DatasetsListPage";
import { DatasetDataPage } from "./DatasetsShowPage/DatasetsShowDataPage/DatasetsDataPage";
import { DatasetsShowPage } from "./DatasetsShowPage/DatasetsShowPage";

/**
 * Datasets console lets users view and configure their datasets.
 */
export function Datasets() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.DATASETS]: DatasetsListPage,
    [ROUTE_NAMES.DATASETS_DEFINITION]: DatasetsShowPage,
    [ROUTE_NAMES.DATASETS_DATA]: DatasetDataPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.DATASETS} showToast />
  );

  return <Page />;
}
