import { ReactElement } from "react";

import {
  SegmentSubtype,
  SegmentType,
} from "../../../__generated__/globalTypes";
import { CohortSegmentCard } from "./cards/CohortSegmentCard";
import { NationalSegment } from "./cards/NationalSegment";
import { PersonaSegmentCard } from "./cards/PersonaSegmentCard";
import { SegmentInfo } from "./useExploreParams";

interface Props {
  id: string;
  color: string;
  type: SegmentType;
  subtype?: SegmentSubtype;
  removeSegment: (s: SegmentInfo) => void;
  disableRemove?: boolean;
}

export function PresetSegmentCard({
  id,
  color,
  type,
  subtype,
  removeSegment,
  disableRemove,
}: Props): ReactElement {
  const props = {
    id,
    color,
    onRemoveClick: () => removeSegment({ id, type, subtype }),
    subtype,
    disableRemove,
  };

  const cards = {
    Persona: PersonaSegmentCard,
    Cohort: CohortSegmentCard,
    Audience: () => null,
    Outcome: () => null,
    National: NationalSegment,
  };

  const Card = cards[type];

  return <Card {...props} />;
}
