import { List, ListItem, Tag, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import { PropsWithChildren } from "react";
import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useTraitsQuery } from "../../hooks/useTraitsQuery";
import { number, percent } from "../../utils/formatters";
import { ActionableCardItem } from "../ui/ActionableCardItem";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { CardV2 } from "../ui/Card/CardV2";
import { CardStack } from "../ui/CardStack";
import { RenameModal } from "../ui/RenameModal";
import { ResourceGraphCard } from "../ui/ResourceGraphCard";
import { ResourceIcon } from "../ui/ResourceIcon";
import {
  ResourceSummaryGrid,
  ResourceSummaryGridCell,
} from "../ui/ResourceSummaryGrid";
import { RouterLink } from "../ui/RouterLink";
import { PersonaSetFragment } from "./__generated__/PersonaSetFragment";
import { PersonaAvatar } from "./analysis/PersonaAvatar";
import { AnalysisDimensionRow } from "./analysis/types";
import { usePersonaSetAnalysis } from "./analysis/usePersonaSetAnalysis";
import { sortPersonas } from "./analysis/utils";
import { Persona, PersonaSet } from "./personaSetFragment";
import { PersonaSetPageSkeleton } from "./PersonaSetPageSkeleton";
import { PersonaSetsSidebarLayout } from "./PersonaSetsLayout";
import { useModelingFields } from "./useModelingFields";
import { usePersonaSet } from "./usePersonaSet";
import { useUpdatePersona } from "./useUpdatePersona";

const TagList: React.FC<PropsWithChildren> = ({ children }) => (
  <List
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 2,
      listStyleType: "none",
    }}
  >
    {children}
  </List>
);

const LabeledTag = ({ label, value }: { label: string; value: string }) => (
  <Tag>
    <strong style={{ marginRight: 4 }}>{label}</strong>
    <span>{value}</span>
  </Tag>
);

function PersonaDimensionTags({
  persona,
  total,
  dimensions,
}: {
  persona: Persona;
  total: number;
  dimensions: AnalysisDimensionRow[];
}) {
  const { individualsCount, details } = persona;

  if (!details?.center) return null;

  const count = individualsCount ?? 0;

  const sortedDimensions = dimensions.sort((a, b) =>
    a.field.name.localeCompare(b.field.name)
  );

  return (
    <TagList>
      <LabeledTag label="Individuals" value={number(count)} />
      <LabeledTag label="% of persona set" value={percent(count / total)} />

      {sortedDimensions.map((dim) => {
        const personaDims = dim.personas.find((d) => d.id === persona.id);

        if (!personaDims)
          throw new Error(`Missing dimension for persona ${persona.id}`);

        return (
          <LabeledTag
            key={dim.field.name}
            label={dim.field.literate ?? dim.field.name}
            value={personaDims.common}
          />
        );
      })}
    </TagList>
  );
}

function PersonaCardPersonaListItem({
  personaSet,
  persona,
  total,
  clusteringTraits,
}: {
  personaSet: PersonaSetFragment;
  persona: Persona;
  total: number;
  clusteringTraits: AnalysisDimensionRow[];
}) {
  const modalProps = useDisclosure();

  const updatePersonaMutation = useUpdatePersona({
    personaSetId: personaSet.id,
    personaId: persona.id,
    onSuccess: modalProps.onClose,
  });

  return (
    <>
      <ActionableCardItem
        leftIcon={<PersonaAvatar persona={persona} size="lg" />}
        title={persona.name}
        subtitle={
          <PersonaDimensionTags
            persona={persona}
            total={total}
            dimensions={clusteringTraits}
          />
        }
        onEdit={modalProps.onOpen}
      />

      {modalProps.isOpen && (
        <RenameModal
          defaultValue={persona.name}
          onClose={modalProps.onClose}
          updateFn={(name) => updatePersonaMutation.mutate({ name })}
          title="Rename persona"
          updating={updatePersonaMutation.isLoading}
        />
      )}
    </>
  );
}

function PersonasCardPersonaList({
  personaSet,
  personas,
  total,
  clusteringTraits,
}: {
  personaSet: PersonaSet;
  personas: PersonaSetFragment["personas"];
  total: number;
  clusteringTraits: AnalysisDimensionRow[];
}) {
  return (
    <List display="grid" gap={4}>
      {personas.map((persona) => (
        <ListItem key={persona.id}>
          <PersonaCardPersonaListItem
            personaSet={personaSet}
            persona={persona}
            total={total}
            clusteringTraits={clusteringTraits}
          />
        </ListItem>
      ))}
    </List>
  );
}

/**
 * Renders a card that contains the list of detected personas.
 * Each persona shows a summary of the clustering traits and the number of
 * individuals found by that persona.
 */
function PersonasCard({ personaSet }: { personaSet: PersonaSet }) {
  const { traitsMap } = useTraitsQuery();

  const { loading, clusteringDimensions: clusteringTraits } =
    usePersonaSetAnalysis({
      personaSetId: personaSet.id,
      modelingFields: personaSet.modelingFields,
      traitsMap,
    });

  const personas = sortPersonas(personaSet.personas);

  const totalIndividualsCount = personaSet.personas.reduce(
    (acc, persona) => acc + (persona.individualsCount ?? 0),
    0
  );

  return (
    <CardV2
      title="Personas"
      text={`Faraday discovered these personas in your ${personaSet.cohort?.name} cohort.`}
    >
      {loading ? (
        <AnimatedZapLogo />
      ) : (
        <PersonasCardPersonaList
          personaSet={personaSet}
          personas={personas}
          total={totalIndividualsCount}
          clusteringTraits={clusteringTraits}
        />
      )}
    </CardV2>
  );
}

/**
 * Renders the details abou the persona set configuration (what cohort was used
 * and what modeling fields).
 *
 * If there are personas to show, a card with the list of personas is rendered.
 */
function PersonaSetDefinitionPageCards({
  personaSet,
}: {
  personaSet: PersonaSetFragment;
}) {
  const { fields } = useModelingFields(personaSet.modelingFields);
  const sortedFields = fields.sort((a, b) => a.label.localeCompare(b.label));

  if (!personaSet.cohort) {
    throw new Error(`Missing cohort for persona set ${personaSet.id}`);
  }

  return (
    <CardStack>
      <CardV2
        title="Persona set definition"
        text={
          <>
            The <RouterLink routeName={ROUTE_NAMES.COHORTS}>cohort</RouterLink>{" "}
            you selected has been organized into thematic personas based on the
            following effective clustering{" "}
            <RouterLink routeName={ROUTE_NAMES.TRAITS}>traits</RouterLink>.
          </>
        }
      >
        <ResourceSummaryGrid>
          <ResourceSummaryGridCell title="Cohort">
            <RouterLink
              routeName={ROUTE_NAMES.COHORTS_SHOW}
              params={{ cohort: personaSet.cohort.id }}
              sx={{ fontSize: "fdy_lg" }}
            >
              <ResourceIcon.cohorts
                style={{
                  display: "inline-block",
                  marginRight: 4,
                }}
              />
              {personaSet.cohort.name}
            </RouterLink>
          </ResourceSummaryGridCell>
          <ResourceSummaryGridCell title="Clustering traits">
            <TagList>
              {sortedFields.map((f) => (
                <Tag key={f.value}>{f.label}</Tag>
              ))}
            </TagList>
          </ResourceSummaryGridCell>
        </ResourceSummaryGrid>
      </CardV2>

      {personaSet.lastUpdatedOutputAt === null ? (
        <PersonaSetPageSkeleton />
      ) : (
        <PersonasCard personaSet={personaSet} />
      )}

      <ResourceGraphCard resourceId={personaSet.id} />
    </CardStack>
  );
}

/**
 * Renders a detail page for a persona set.
 *
 * Shows the configuration of the persona set with an edit link,
 * and the personas that were discovered accompanied by various stats.
 **/
export function PersonaSetsDefinitionPage() {
  const { route } = useRoute();
  const { loading, data, error } = usePersonaSet(route.params.roster);

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  const personaSet = data?.personaSet;
  if (!personaSet) {
    throw new Error(
      `BUG: No persona set found, but sojourner did not throw an error`
    );
  }

  const { id, name } = personaSet;

  return (
    <PersonaSetsSidebarLayout
      personaSet={personaSet}
      title={name}
      lastCrumb={{
        label: name,
        routeName: ROUTE_NAMES.PERSONAS_ROSTER,
        params: { roster: id },
      }}
    >
      <PersonaSetDefinitionPageCards personaSet={personaSet} />
    </PersonaSetsSidebarLayout>
  );
}
