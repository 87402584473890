import { Auth0DecodedHash, Auth0Error, WebAuth } from "auth0-js";
import { LogArgument } from "rollbar";

import { env } from "../../env";
import { AuthService, AuthServiceOptions, NEVER_RESOLVE } from "./base";
import { AuthError } from "./errors";

interface Auth0SessionInfo {
  accessToken: string;
  appState?: string;
  accessTokenExpiry: number;
  idToken: string;
  idTokenExpiry: number;
}

interface Auth0Unauthorized {
  code: "login_required";
}

function isUnauthorized(
  session: Auth0SessionInfo | Auth0Unauthorized
): session is Auth0Unauthorized {
  return "code" in session;
}

const ONE_MINUTE = 60 * 1000;

// We specify a callback route to make it easy to know when we should
// parse out the auth0 access token. When the user logs on Auth0's site,
// they are redirected to `{faraday host}/callback`, which is picked up by
// this code and calls the `handleRedirectCallback` fn. This stores the
// token in memory and yields the `appState` parameter provided before the
// user logged in. We use this param to restore the route they were
// looking at before they were redirected away to Auth0's login page.
const CALLBACK_PATH = "/callback";

export class Auth0AuthService extends AuthService {
  _getToken: () => Promise<string>;
  _logout: () => Promise<never>;

  constructor(options: AuthServiceOptions) {
    super(options);
    const origin = window.location.origin;

    // If we are on .ai, assume that the user wants to use our login on
    // our custom domain, which will also change the issuer.
    //
    // TODO: correct broken issuers by re-authenticating with the other
    //       auth domain. That should work, right?
    const domain =
      origin.split(".").pop() === "ai"
        ? "login.faraday.ai"
        : env.AUTH0_CLIENT_DOMAIN;

    const client = new WebAuth({
      clientID: env.AUTH0_CLIENT_ID,
      domain,
      redirectUri: origin + CALLBACK_PATH,
      responseType: "token id_token",
    });

    let session: Promise<Auth0SessionInfo | Auth0Unauthorized>;

    const validateAuth0Payload = (
      err: null | Auth0Error,
      data: Auth0DecodedHash | null
    ): Auth0SessionInfo | Auth0Unauthorized | null => {
      if (err && err.code === "login_required")
        return { code: "login_required" };
      if (err) throw err;
      if (!data) throw new AuthError("[AuthError] No data in payload");

      if (!data.accessToken)
        throw new AuthError(`[AuthError] Missing accessToken in hash payload`);
      if (!data.expiresIn)
        throw new AuthError(`[AuthError] Missing expiry in hash payload`);
      if (!data.idToken)
        throw new AuthError(`[AuthError] Missing id token in hash payload`);

      return {
        accessToken: data.accessToken,
        accessTokenExpiry: Date.now() + data.expiresIn * 1000,
        appState: data.appState,
        idToken: data.idToken,
        idTokenExpiry: data.idTokenPayload.exp,
      };
    };

    const checkSession = (): Promise<Auth0SessionInfo | Auth0Unauthorized> =>
      new Promise((resolve, reject) => {
        client.checkSession({}, (err, data: Auth0DecodedHash) => {
          try {
            const result = validateAuth0Payload(err, data);
            if (result) resolve(result);
          } catch (e) {
            reject(e);
          }
        });
      });

    const reauthorizeIfRequired = async () => {
      const ses = await session;
      if (isUnauthorized(ses)) {
        const route = window.location.pathname + window.location.search;
        client.authorize({ appState: route });
        return NEVER_RESOLVE;
      }
      return ses;
    };

    // When we hit the defined Auth callback route, we attempt to parse the
    // URL fragment provided, to extract the id_token (a JWT), the access
    // token (not used, but may be used to access the management API), and
    // some useful expiry datetimes.
    //
    if (window.location.pathname === CALLBACK_PATH) {
      session = new Promise((resolve, reject) => {
        client.parseHash((err, data) => {
          window.location.hash = "";

          try {
            const result = validateAuth0Payload(err, data);
            if (result && !isUnauthorized(result)) {
              this.onLogIn(result.appState);
              resolve(result);
            } else {
              reject(new AuthError(`Something very strange happened.`));
            }
          } catch (e) {
            reject(e);
          }
        });
      });
    } else {
      session = checkSession();
    }

    // We define _getToken and _logout here to avoid exposing the Auth0 client
    // directly to anyone else. It's an implementation detail no other part of
    // the codebase should need to worry about, and giving away access to the
    // client value does have some (minor) security implications.
    //
    this._getToken = async () => {
      try {
        const { idToken, idTokenExpiry } = await reauthorizeIfRequired();

        // Check if the token is close to expiring. If it is, fetch a new one
        // before continuing. If the Auth0 SSO session has expired, this will
        // log the user out and the promise will never yield a value.
        //
        if (idTokenExpiry >= Date.now() - ONE_MINUTE) {
          const ses = reauthorizeIfRequired();
          session = ses;

          return (await ses).idToken;
        } else {
          return idToken;
        }
      } catch (e) {
        options.log?.error(e as LogArgument);
        client.logout({ returnTo: window.location.origin });
        return NEVER_RESOLVE;
      }
    };

    this._logout = () => {
      client.logout({ returnTo: window.location.origin });
      return NEVER_RESOLVE;
    };
  }

  async getToken(): Promise<string> {
    return this._getToken();
  }
  async logout(): Promise<never> {
    return this._logout();
  }
}
