import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  forwardRef,
} from "@chakra-ui/react";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";

export interface ButtonProps extends ChakraButtonProps {
  // For Heap analytics tagging
  analyticsName?: string;
  disabled?: boolean;
}

export const Button = forwardRef<ButtonProps, "button">(
  ({ analyticsName, ...props }, ref) => {
    const analyticsKey = useAnalyticsKey(analyticsName);
    return (
      <ChakraButton
        ref={ref}
        {...props}
        isDisabled={props.disabled || props.isDisabled || props.isLoading}
        {...analyticsAttrs(analyticsKey)}
      />
    );
  }
);
