import { ApolloError, DocumentNode, gql } from "@apollo/client";
import { Button, MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { capitalize } from "lodash";
import React, { useState } from "react";

import { ResourceStatus } from "../../__generated__/sojournerGlobalTypes";
import { typeAlias } from "../../constants/typeAlias";
import { useToast } from "../../hooks/useToast";
import { useSojournerApolloClient } from "../../services/sojournerApolloClient";
import { createResourceRoute } from "../../utils/createResourceRoute";
import { titleCase } from "../../utils/formatters";
import { AnimatedZapLogo } from "./AnimatedZapLogo";
import { InlineButtons } from "./InlineButtons";
import { ModalV2 } from "./ModalV2";
import { ResourceIcon } from "./ResourceIcon";
import { RouterLink } from "./RouterLink";
import {
  getGraphForResource,
  GraphDirection,
  sortByResourceType,
  useResourceGraphQuery,
} from "./useResourceGraph";

export type ResourceTypeName = `${ResourceType}`;

/**
 * Our archive mutations registry keyed on resource type
 */
export const archiveMutations: Partial<Record<ResourceType, DocumentNode>> = {
  cohorts: gql`
    mutation ArchiveCohortMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveCohort(archiveConfigInput: $archiveConfig, cohortId: $id)
    }
  `,
  connections: gql`
    mutation ArchiveConnectionMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveConnection(archiveConfigInput: $archiveConfig, connectionId: $id)
    }
  `,
  datasets: gql`
    mutation ArchiveDatasetMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveDataset(archiveConfigInput: $archiveConfig, datasetId: $id)
    }
  `,
  outcomes: gql`
    mutation ArchiveOutcomeMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveOutcome(archiveConfigInput: $archiveConfig, outcomeId: $id)
    }
  `,
  persona_sets: gql`
    mutation ArchivePersonaSetMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archivePersonaSet(archiveConfigInput: $archiveConfig, personaSetId: $id)
    }
  `,
  scopes: gql`
    mutation ArchiveScopeMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveScope(archiveConfigInput: $archiveConfig, scopeId: $id)
    }
  `,
  streams: gql`
    mutation ArchiveStreamMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveStream(archiveConfigInput: $archiveConfig, streamIdOrName: $id)
    }
  `,
  targets: gql`
    mutation ArchiveTargetMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveTarget(archiveConfigInput: $archiveConfig, targetId: $id)
    }
  `,
  traits: gql`
    mutation ArchiveTraitMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveTrait(archiveConfigInput: $archiveConfig, traitId: $id)
    }
  `,
  recommenders: gql`
    mutation ArchiveRecommenderMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      archiveRecommender(archiveConfigInput: $archiveConfig, recommenderId: $id)
    }
  `,
};

export const unarchiveMutations: Partial<Record<ResourceType, DocumentNode>> = {
  cohorts: gql`
    mutation UnarchiveCohortMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveCohort(archiveConfigInput: $archiveConfig, cohortId: $id)
    }
  `,
  connections: gql`
    mutation UnarchiveConnectionMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveConnection(archiveConfigInput: $archiveConfig, connectionId: $id)
    }
  `,
  datasets: gql`
    mutation UnarchiveDatasetMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveDataset(archiveConfigInput: $archiveConfig, datasetId: $id)
    }
  `,
  outcomes: gql`
    mutation UnarchiveOutcomeMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveOutcome(archiveConfigInput: $archiveConfig, outcomeId: $id)
    }
  `,
  persona_sets: gql`
    mutation UnarchivePersonaSetMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchivePersonaSet(archiveConfigInput: $archiveConfig, personaSetId: $id)
    }
  `,
  scopes: gql`
    mutation UnarchiveScopeMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveScope(archiveConfigInput: $archiveConfig, scopeId: $id)
    }
  `,
  streams: gql`
    mutation UnarchiveStreamMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveStream(archiveConfigInput: $archiveConfig, streamIdOrName: $id)
    }
  `,
  targets: gql`
    mutation UnarchiveTargetMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveTarget(archiveConfigInput: $archiveConfig, targetId: $id)
    }
  `,
  traits: gql`
    mutation UnarchiveTraitMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveTrait(archiveConfigInput: $archiveConfig, traitId: $id)
    }
  `,
  recommenders: gql`
    mutation UnarchiveRecommenderMutationHook(
      $id: ID!
      $archiveConfig: ArchiveConfigInput!
    ) {
      unarchiveRecommender(
        archiveConfigInput: $archiveConfig
        recommenderId: $id
      )
    }
  `,
};

export function ArchiveMenuButton({
  focusAfterCloseRef,
  resourceId,
  resourceType,
  name,
  archivedAt,
  status,
}: {
  focusAfterCloseRef?: React.RefObject<HTMLButtonElement>;
  resourceId: string;
  resourceType: ResourceTypeName;
  name: string;
  archivedAt?: string | null;
  status: ResourceStatus;
}) {
  const archiveModalStatus = useDisclosure();
  const submitType = archivedAt ? "unarchive..." : "archive...";
  return (
    <>
      <MenuItem onClick={archiveModalStatus.onOpen}>
        {capitalize(submitType)}
      </MenuItem>
      {archiveModalStatus.isOpen && (
        <ArchiveModal
          onClose={archiveModalStatus.onClose}
          focusAfterCloseRef={focusAfterCloseRef}
          resourceId={resourceId}
          resourceType={resourceType}
          name={name}
          archivedAt={archivedAt}
          status={status}
        />
      )}
    </>
  );
}

/**
 * Renders a modal for (un)archiving a resource.
 */
export function ArchiveModal({
  onClose,
  focusAfterCloseRef,
  resourceType,
  resourceId,
  name,
  archivedAt,
  status,
}: {
  onClose: () => void;
  focusAfterCloseRef?: React.RefObject<HTMLButtonElement>;
  resourceType: ResourceTypeName;
  resourceId: string;
  name: string;
  archivedAt?: string | null;
  status: ResourceStatus;
}) {
  const [updating, setUpdating] = useState<boolean>(false);

  const sojoClient = useSojournerApolloClient();
  const toast = useToast();
  const { data, loading: graphLoading } = useResourceGraphQuery({
    fetchPolicy: "network-only",
  });

  const relevantGraphUnfiltered = getGraphForResource({
    edges: data?.graph,
    resourceId,
    direction: archivedAt ? GraphDirection.UPSTREAM : GraphDirection.DOWNSTREAM,
  });
  // only need to warn user if the 'archived' status will change
  const relevantGraph = relevantGraphUnfiltered
    .filter((edge) => {
      return archivedAt
        ? edge.upstreamArchivedAt !== null
        : edge.downstreamArchivedAt === null;
    })
    .sort(
      sortByResourceType(
        archivedAt ? GraphDirection.UPSTREAM : GraphDirection.DOWNSTREAM
      )
    );

  const pluralDependencies = relevantGraph && relevantGraph.length > 1;
  const submitType = archivedAt ? "unarchive" : "archive";

  const andOtherResources =
    relevantGraph && relevantGraph.length
      ? ` and ${relevantGraph.length} other resource${
          pluralDependencies ? "s" : ""
        }`
      : "";

  const submitText =
    relevantGraph && relevantGraph.length
      ? `${capitalize(submitType)} ${relevantGraph.length + 1} resource${
          pluralDependencies ? "s" : ""
        }`
      : submitType;

  const dependencyExplanationText = !archivedAt
    ? ` is a dependency of ${relevantGraph.length} other${
        pluralDependencies ? "s" : ""
      }. If you archive ${pluralDependencies ? "them" : "it"}, the dependenc${
        pluralDependencies ? "ies" : "y"
      } will be archived as well.`
    : ` is dependent on ${relevantGraph.length} archived resource${
        pluralDependencies ? "s" : ""
      }. If you unarchive it, the other resource${
        pluralDependencies ? "s" : ""
      } will be unarchived as well.`;

  async function handleArchive() {
    const mutation = !archivedAt
      ? archiveMutations[resourceType]
      : unarchiveMutations[resourceType];

    if (!mutation) {
      throw new Error(`No archive mutation for resource '${resourceType}'`);
    }

    setUpdating(true);

    await sojoClient
      .mutate({
        mutation,
        variables: {
          id: resourceId,
          archiveConfig: {
            cascadeTo: relevantGraph.map((edge) =>
              archivedAt ? edge.upstreamId : edge.downstreamId
            ),
          },
        },
      })
      .then(() => {
        toast({
          status: "success",
          title: "Success",
          description: `${relevantGraph.length + 1} resource${
            pluralDependencies ? "s" : ""
          } have been ${submitType}d.`,
        });
      })
      .catch((e: ApolloError) => {
        toast({
          status: "error",
          title: `Failed to ${submitType}`,
          description: e.message,
        });
      })
      .finally(() => {
        setUpdating(false);
      });

    await sojoClient.refetchQueries({
      include: "active",
    });

    onClose();
  }

  const footer = (
    <InlineButtons>
      <Button variant="tertiary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant={archivedAt ? "primary" : "danger"}
        onClick={handleArchive}
        isDisabled={updating || status === ResourceStatus.NEW || graphLoading}
      >
        {capitalize(submitText)}
      </Button>
    </InlineButtons>
  );

  return (
    <ModalV2
      isOpen={true}
      onClose={onClose}
      finalFocusRef={focusAfterCloseRef}
      title={`Confirm ${submitType}`}
      footer={footer}
    >
      {graphLoading && <AnimatedZapLogo />}
      {!graphLoading && !relevantGraph?.length && (
        <Text>
          Are you sure you want to {submitType} <strong>{name}</strong>
          {andOtherResources}?
        </Text>
      )}
      {!graphLoading && status === ResourceStatus.NEW && (
        <Text color="fdy_red.500">
          Cannot archive the resource until the queued update starts.
        </Text>
      )}

      {!graphLoading && !!relevantGraph?.length && (
        <>
          <Text pb={4}>
            <strong>{name}</strong>
            {dependencyExplanationText}
          </Text>
          <ul>
            {relevantGraph.map((edge) => {
              // if archiving (!currentlyArchived), display downstream resources
              // if unarchiving, display upstream resources
              const edgeType = !archivedAt
                ? edge.downstreamType
                : edge.upstreamType;
              const edgeId = !archivedAt ? edge.downstreamId : edge.upstreamId;
              const edgeLiterate = !archivedAt
                ? edge.downstreamLiterate
                : edge.upstreamLiterate;

              if (!edgeType || !edgeId) return null;

              const apiResourceName = edgeType.toLowerCase() as ResourceType;

              const resourceRoute = createResourceRoute({
                id: edgeId,
                // only used for targets (since they don't have their own page, they're instead displayed on their pipeline's page)
                upstreamId: edge.upstreamId,
                type: apiResourceName,
              });

              const Icon = ResourceIcon[apiResourceName];
              const alias = typeAlias[apiResourceName] ?? apiResourceName;

              return (
                <li key={!archivedAt ? edge.downstreamId : edge.upstreamId}>
                  <RouterLink
                    routeName={resourceRoute.routeName}
                    params={resourceRoute.params}
                  >
                    {Icon && <Icon style={{ display: "inline-block" }} />}
                    <Text as="span" ml={2}>
                      {edgeLiterate} - {titleCase(alias)}
                    </Text>
                  </RouterLink>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </ModalV2>
  );
}
