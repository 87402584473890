import { Box, CloseButton, Heading } from "@chakra-ui/react";
import * as React from "react";

type SidebarSide = "right" | "left";

interface SidebarProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  side: SidebarSide;
  width: number;
}

function Sidebar({
  title,
  isOpen,
  onClose,
  children,
  width,
  side,
}: SidebarProps) {
  return (
    <Box
      aria-hidden={!isOpen}
      as="section"
      sx={{
        bg: "white",
        p: 8,
        border: "1px solid",
        borderColor: "fdy_gray.200",
        width,
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        transition: "transform .2s",
        transform:
          isOpen === false
            ? `translateX(${side === "right" ? "100%" : "-100%"})`
            : "",
        overflowY: "auto",
      }}
    >
      <Box
        as="header"
        sx={{
          mb: 4,
          position: "relative",
        }}
      >
        <Heading variant="h5">{title}</Heading>
        <CloseButton
          onClick={onClose}
          sx={{
            top: 0,
            right: 0,
            position: "absolute",
          }}
        />
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}

interface SidebarLayoutProps {
  rightSidebarProps: Omit<SidebarProps, "side">;
  children: React.ReactNode;
}

/**
 * Renders a region of content with an optional sidebar that can be toggled.
 */
export function SidebarLayout({
  children,
  rightSidebarProps,
}: SidebarLayoutProps) {
  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
        paddingRight: rightSidebarProps.isOpen ? rightSidebarProps.width : 0,
        overflow: "hidden", // hide sidebars off screen
        transition: ".2s",
      }}
    >
      <Box
        sx={{
          overflowX: "auto",
          height: "100%",
          paddingBottom: 16,
        }}
      >
        <Box
          sx={{
            minWidth: 800,
          }}
        >
          {children}
        </Box>
      </Box>
      <Sidebar {...rightSidebarProps} side="right" />
    </Box>
  );
}
