import { getAnalysisBreaks } from "../beta/getBoundsForAnalysis";
import { HistogramBin } from "./Histogram";
import { AnalysisDataset, Bounds, Field } from "./types";

interface Bin {
  index: number;
  key: string;
  value: number;
}

export function fieldDataToBins(
  data: AnalysisDataset,
  bounds: Bounds,
  field: Field
): HistogramBin[] {
  const breaks = getAnalysisBreaks(field);

  if (!breaks.length) {
    return [];
  }

  const result = Object.entries(data)
    // remove q1,q2,q3, etc.
    .filter(([key]) => key.startsWith("bin_"))
    .map(([key, value]): Bin => {
      // minus 1 because the object keys start at bin_1, not bin_0
      const index = Number(key.split("_")[1]) - 1;

      return {
        index,
        key,
        value,
      };
    })
    .sort((a, b) => a.index - b.index)
    .map((a) => {
      const min = breaks[a.index];
      const max = breaks[a.index + 1];

      return {
        min,
        max,
        percent: a.value,
      };
    })
    .filter((b): b is HistogramBin => {
      if (b.min === null || b.max === null) return false;

      return bounds[0] <= b.min && bounds[1] >= b.max;
    });

  return result;
}
