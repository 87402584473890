import { Text } from "@chakra-ui/react";
import { ChangeEvent, FormEvent, SetStateAction, useState } from "react";

import { useAccountConfigMap } from "../../../../../hooks/accountConfigHooks";
import { useFormErrors } from "../../../../../hooks/useFormErrors";
import { Button } from "../../../../ui/Button";
import { FormField } from "../../../../ui/FormField";
import { FormFieldset } from "../../../../ui/FormFieldset";
import { FormFieldStack } from "../../../../ui/FormFieldStack";
import { InlineButtons } from "../../../../ui/InlineButtons";
import { Input } from "../../../../ui/Input";
import { ModalV2 } from "../../../../ui/ModalV2";
import { Prose } from "../../../../ui/Prose";
import { ClassicExplanation } from "../shared/ClassicExplanation";
import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import {
  DatasetIdentitySetsPropertiesTable,
  IdentityProperties,
  IdentityPropertiesState,
} from "./DatasetsIdentitySetsPropertiesTable";
import {
  IdentitySetErrors,
  nameHelpText,
  validateIdentitySet,
} from "./identitySetUtils";

export type IdentitySets = Record<string, IdentityProperties | null>;

export type IdentitySetState = {
  name: string;
  properties: IdentityPropertiesState;
  classic?: boolean;
};

const defaultState: IdentitySetState = {
  name: "",
  properties: {},
};

export function DatasetsIdentitySetsModal({
  initialState = defaultState,
  detectedColumns,
  sampleData,
  managedDataset,
  onFinish,
  onClose,
}: {
  initialState: IdentitySetState;
  detectedColumns: DetectedColumn[];
  managedDataset: boolean;
  sampleData?: SampleData;
  onClose: () => void;
  onFinish: (state: IdentitySetState) => void;
}) {
  const { classic } = initialState;
  const config = useAccountConfigMap();

  const [name, setName] = useState<string>(initialState.name);
  const [properties, setProperties] = useState<IdentityPropertiesState>(
    initialState.properties
  );

  const { errors, setErrors, resetError, refs } =
    useFormErrors<IdentitySetErrors>();

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const errors = validateIdentitySet({ name, properties }, config);

    if (errors) {
      setErrors(errors);
      return;
    }

    onFinish({ name: name.trim(), properties });
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    resetError("name");
  };

  const handlePropertiesChange = (
    properties: SetStateAction<IdentityPropertiesState>
  ) => {
    setProperties(properties);
    resetError("properties");
  };

  return (
    <ModalV2
      isOpen
      onClose={onClose}
      onCloseStateToConfirm={{
        name,
        properties,
      }}
      title={name ? `${name} identity set` : "New identity set"}
      footer={
        <InlineButtons reverse>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={managedDataset || classic}
            analyticsName="finish"
          >
            Finish
          </Button>

          <Button variant="tertiary" onClick={onClose} analyticsName="cancel">
            Cancel
          </Button>
        </InlineButtons>
      }
      analyticsStackName="add-identity-set"
    >
      <form onSubmit={handleSubmit}>
        <FormFieldStack>
          <Text fontSize="fdy_lg">
            Most Datasets have one identity set including all forms of identity.
            Put email, physical address, etc. all into a single identity set.
            Create multiple identity sets only if you have mutually exclusive
            forms of identity - for example, separate shipping and billing.
          </Text>

          {classic ? <ClassicExplanation resourceType="identity set" /> : null}

          <FormField
            label="Identity set name"
            error={errors.name}
            helpText={nameHelpText}
            ref={refs.name}
          >
            <Input
              type="text"
              value={name}
              onChange={handleNameChange}
              analyticsName="name"
              disabled={managedDataset || classic}
              required
            />
          </FormField>

          <FormFieldset
            legend="Properties"
            ref={refs.properties}
            error={errors.properties}
            hint={
              <Prose>
                <p>
                  More identifiers generally result in a higher match rate. At
                  least one of the following sets must be provided:
                </p>
                <ul>
                  <li>email or hash</li>
                  <li>first name, last name, address, city, state</li>
                  <li>full name, address, city, state</li>
                  <li>phone</li>
                  <li>first name, last name, address, zip</li>
                  <li>full name, address, zip</li>
                </ul>
              </Prose>
            }
          >
            <DatasetIdentitySetsPropertiesTable
              properties={properties}
              setProperties={handlePropertiesChange}
              detectedColumns={detectedColumns}
              sampleData={sampleData}
            />
          </FormFieldset>
        </FormFieldStack>
      </form>
    </ModalV2>
  );
}
