import { Spacer } from "@chakra-ui/react";
import { FormEvent } from "react";

import { AccountConfigMap } from "../../../../hooks/accountConfigHooks";
import { Button } from "../../../ui/Button";
import { FormFieldset } from "../../../ui/FormFieldset";
import { InlineButtons } from "../../../ui/InlineButtons";
import { ConnectionTypeInfo, isConnectionType } from "../../connectionUtils";
import { TargetFragment_representation } from "../__generated__/TargetFragment";
import { ScopeDataset } from "../useScopeDependencies";
import { LookupTargetNotice } from "./LookupTargetNotice";
import { ScrollWrap } from "./ScrollWrap";
import { TargetHumanReadableRadios } from "./TargetHumanReadableRadios";
import {
  TargetRepresentationRadios,
  TargetRepresentationType,
} from "./TargetRepresentationRadios";

// the state shape required to pass to the API to create or update a target
// ie 'TargetInput' on graphql
export interface TargetFormRepresentationState {
  humanReadable: boolean | null;
  representation: TargetRepresentationType;
}

interface TargetFormRepresentationProps {
  humanReadable: boolean;
  // Because we can't change discriminator types in the API (yet), we have to
  // make these types read-only on Edit (can't change type - but you can change
  // options within the type).
  // See also: https://github.com/faradayio/openapi-interfaces/issues/34
  lockedRepresentationTypename?: TargetFragment_representation["__typename"];
  onCancel: () => void;
  onNext: () => void;
  representation: TargetRepresentationType;
  scopeDatasets: ScopeDataset[];
  setHumanReadable: (humanReadable: boolean) => void;
  setRepresentation: (representation: TargetRepresentationType) => void;
  connectionTypeInfo: ConnectionTypeInfo;
  accountConfigMap: AccountConfigMap;
}

/**
 * Render a form for editing or creating a connection target.
 */
export function TargetFormRepresentation({
  humanReadable,
  lockedRepresentationTypename,
  onCancel,
  onNext,
  representation,
  scopeDatasets,
  setHumanReadable,
  setRepresentation,
  connectionTypeInfo,
  accountConfigMap,
}: TargetFormRepresentationProps) {
  // set up form so native form validation can work
  function handleNext(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onNext();
  }

  const isLookupApi = isConnectionType(connectionTypeInfo, "lookup_api");

  return (
    <form onSubmit={handleNext}>
      <ScrollWrap>
        {isLookupApi && <LookupTargetNotice />}

        <FormFieldset legend="Choose how you want the pipeline's output to be formatted for this target.">
          <TargetRepresentationRadios
            connectionTypeInfo={connectionTypeInfo}
            datasets={scopeDatasets}
            representation={representation}
            onChange={setRepresentation}
            lockedTypename={lockedRepresentationTypename}
            accountConfigMap={accountConfigMap}
          />
        </FormFieldset>

        <Spacer my={6} />

        <FormFieldset legend="What kind of column headers do you want?">
          <TargetHumanReadableRadios
            disabled={isLookupApi}
            humanReadable={humanReadable}
            onChange={setHumanReadable}
          />
        </FormFieldset>
      </ScrollWrap>
      <InlineButtons sx={{ p: 6 }} reverse>
        <Button type="submit" variant="primary" analyticsName="next">
          Next
        </Button>
        <Button variant="tertiary" onClick={onCancel} analyticsName="cancel">
          Cancel
        </Button>
      </InlineButtons>
    </form>
  );
}
