import groupBy from "lodash/groupBy";
import upperFirst from "lodash/upperFirst";

import {
  TraitCategory,
  TraitPermission,
} from "../__generated__/sojournerGlobalTypes";
import { Trait } from "../hooks/useTraitsQuery";
import { TraitsMap } from "../hooks/useTraitsQuery";

/**
 * Render the trait literate if appropriate and available, otherwise return the trait name.
 */
export function traitLiterate(trait: {
  name: string;
  literate: string | null;
  category: TraitCategory | null;
}) {
  // users don't set literates for user defined traits so we use the name they set
  if (trait.category === TraitCategory.USER_DEFINED) {
    return trait.name;
  }

  return trait.literate ?? trait.name;
}

export function traitLiterateFromMap(name: string, traitsMap: TraitsMap) {
  const trait = traitsMap[name];
  return trait ? traitLiterate(trait) : name;
}

export function traitCategoryToLiterate(
  categoryName: TraitCategory | null
): string {
  if (!categoryName) return "";
  if (categoryName === TraitCategory.USER_DEFINED) return "User defined";
  return upperFirst(
    categoryName.toLowerCase().replace(/^fig_/, "").replace(/_/g, " ")
  );
}

/**
 * Group traits by category and sort them so user defined are first.
 */
export function groupAndSortTraitsByCategories(
  traits: Pick<Trait, "name" | "literate" | "category" | "permissions">[]
): {
  categoryLiterate: string;
  traits: {
    name: string;
    literate: string;
    permissions: TraitPermission[];
  }[];
}[] {
  const grouped = groupBy(
    traits,
    (t) => t.category ?? TraitCategory.USER_DEFINED
  );

  return Object.entries(grouped)
    .sort(([a], [b]) => {
      if (a === TraitCategory.USER_DEFINED) {
        return -1;
      }
      if (b === TraitCategory.USER_DEFINED) {
        return 1;
      }
      return a.localeCompare(b);
    })
    .map(([category, traits]) => {
      return {
        categoryLiterate: traitCategoryToLiterate(category as TraitCategory),
        traits: traits
          .map((t) => ({
            name: t.name,
            literate: traitLiterate(t),
            permissions: t.permissions,
          }))
          .sort((a, b) => a.literate.localeCompare(b.literate)),
      };
    });
}

export const allTraitPermissions: TraitPermission[] = [
  TraitPermission.ADD_TO_SCOPE_PAYLOAD,
  TraitPermission.DEFINE_COHORT,
];
