import { useState } from "react";

import {
  Privacy,
  ResourceStatus,
} from "../../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { useUnsavedChangesWarning } from "../../../../hooks/useUnsavedChangesWarning";
import { ApiOptions } from "../../../../services/connectionOptions";
import { Button } from "../../../ui/Button";
import { CardV2 } from "../../../ui/Card/CardV2";
import { CardStack } from "../../../ui/CardStack";
import { ResourceProgressBar } from "../../../ui/ResourceProgressBar";
import { RouterLink } from "../../../ui/RouterLink";
import { SkeletonCard } from "../../../ui/SkeletonCard";
import { UsageWarningNotice } from "../../../ui/UsageWarningNotice";
import { DatasetFragment } from "../../__generated__/DatasetFragment";
import { DatasetShowPageQuery_connections } from "../../__generated__/DatasetShowPageQuery";
import { DatasetsAdvancedForm } from "./advanced/DatasetsAdvancedForm";
import { connectionOptionsError } from "./connectionOptionsError";
import {
  DatasetsEventsCard,
  OutputToStreamsState,
} from "./events/DatasetsEventsCard";
import {
  DatasetsIdentitySetsCard,
  IdentitySets,
} from "./identity/DatasetsIdentitySetsCard";
import { DatasetsReferenceKeyForm } from "./referencekey/DatasetsReferenceKeyForm";
import { DatasetMissingConfigNotice } from "./shared/DatasetMissingConfigNotice";
import { SampleData } from "./shared/OptionWithSampleData";
import { DetectedColumn } from "./shared/types";
import {
  DatasetsTraitsCard,
  OutputToTraitsState,
} from "./traits/DatasetsTraitsCard";

export type DatasetFormState = {
  identitySets: IdentitySets;
  outputToStreams: OutputToStreamsState;
  outputToTraits: OutputToTraitsState;
  options: ApiOptions;
  referenceKeyColumns: string[] | null;
  upsertColumns: string[] | null;
  privacy: Privacy | null;
};

/** The minimal keys of a dataset we need for the dataset form and its children. */
export type DatasetFormDataset = Pick<
  DatasetFragment,
  "id" | "detectedColumns" | "sample" | "managed"
>;

export function DatasetForm({
  defaultState,
  onSave,
  saving,
  detectedColumns,
  datasetStatus,
  managed,
  mergeDataset,
  connection,
  datasetId,
  sampleData,
  referenceKeyColumn, // depreciated
  renderAboveSubmitButton,
  dataset,
}: {
  defaultState: DatasetFormState;
  onSave: (arg: DatasetFormState) => void;
  saving: boolean;
  detectedColumns: DetectedColumn[];
  datasetStatus: ResourceStatus;
  managed: boolean;
  mergeDataset: boolean;
  connection?: DatasetShowPageQuery_connections;
  datasetId: string;
  dataset?: DatasetFormDataset;
  sampleData?: SampleData;
  referenceKeyColumn: string | null; // depreciated
  renderAboveSubmitButton?: React.ReactNode;
}) {
  const [identitySets, setIdentitySets] = useState<IdentitySets>(
    defaultState.identitySets ?? {}
  );
  const [outputToTraits, setOutputToTraits] = useState<OutputToTraitsState>(
    defaultState.outputToTraits ?? {}
  );
  const [outputToStreams, setOutputToStreams] = useState<OutputToStreamsState>(
    defaultState.outputToStreams ?? {}
  );
  const [upsertColumn, setUpsertColumn] = useState<string | null>(
    defaultState?.upsertColumns?.length ? defaultState.upsertColumns[0] : null
  );
  const [privacy, setPrivacy] = useState<Privacy | null>(defaultState.privacy);
  const [options, setOptions] = useState<ApiOptions>(defaultState.options);
  const [advancedError, setAdvancedError] = useState<string>();

  const referenceKeyColumns = defaultState.referenceKeyColumns ?? [];

  //user warning message for unsaved changes
  const { setWarnBeforeNavigate } = useUnsavedChangesWarning({
    config: [
      identitySets,
      outputToStreams,
      outputToTraits,
      referenceKeyColumns,
      privacy,
      options,
      upsertColumn,
    ],
    route: ROUTE_NAMES.DATASETS,
  });

  function handleSubmit() {
    const error = connectionOptionsError(options);
    if (error) {
      setAdvancedError(error);
      return;
    }

    const input = {
      identitySets,
      outputToStreams,
      outputToTraits,
      referenceKeyColumns: referenceKeyColumns ?? null,
      privacy: privacy,
      options,
      upsertColumns: upsertColumn ? [upsertColumn] : null,
    };

    onSave(input);
    setWarnBeforeNavigate(false);
  }

  // force this to be a boolean
  const detectedColumnsReady = detectedColumns.length > 0;
  const detectedColumnsError =
    !detectedColumnsReady && datasetStatus === ResourceStatus.ERROR;
  const datasetConfig = {
    detectedColumnsReady,
    identitySets,
    outputToStreams,
    outputToTraits,
    mergeDataset,
  };

  const disabled = managed && !detectedColumnsError;
  return (
    <CardStack>
      <UsageWarningNotice resource="datasets" />
      <DatasetMissingConfigNotice datasetConfig={datasetConfig} />
      <CardV2
        title="Dataset definition"
        text={
          <>
            Datasets are how you describe your data to Faraday. By pulling data
            from a connection or a CSV file, datasets help Faraday identify the
            people in your data, recognize{" "}
            <RouterLink routeName={ROUTE_NAMES.EVENTS}>events</RouterLink>, and
            their associated properties, and define interesting{" "}
            <RouterLink routeName={ROUTE_NAMES.TRAITS}>traits</RouterLink>, from
            your data. They are used to create{" "}
            <RouterLink routeName={ROUTE_NAMES.COHORTS}>cohorts</RouterLink>,
            which are then used as the basis for your predictions such as{" "}
            <RouterLink routeName={ROUTE_NAMES.OUTCOMES}>outcomes</RouterLink>,
            and{" "}
            <RouterLink routeName={ROUTE_NAMES.PERSONAS}>
              persona sets
            </RouterLink>
            .
          </>
        }
      />

      {!detectedColumnsReady && !detectedColumnsError && (
        <>
          <CardV2 text="Please wait while Faraday is processing your Dataset.">
            <ResourceProgressBar status={datasetStatus} />
          </CardV2>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </>
      )}
      {detectedColumnsReady && !detectedColumnsError && (
        <>
          <DatasetsIdentitySetsCard
            identitySets={identitySets}
            setIdentitySets={setIdentitySets}
            detectedColumns={detectedColumns}
            sampleData={sampleData}
            managedDataset={managed}
          />

          <DatasetsEventsCard
            dataset={dataset}
            events={outputToStreams}
            setEvents={setOutputToStreams}
          />

          <DatasetsTraitsCard
            traits={outputToTraits}
            setTraits={setOutputToTraits}
            detectedColumns={detectedColumns}
            managedDataset={managed}
            sampleData={sampleData}
          />

          {referenceKeyColumns.length > 0 && (
            <DatasetsReferenceKeyForm
              datasetId={datasetId}
              referenceKeyColumn={referenceKeyColumn}
              referenceKeyColumns={referenceKeyColumns}
            />
          )}
        </>
      )}
      <DatasetsAdvancedForm
        upsertColumn={upsertColumn}
        setUpsertColumn={setUpsertColumn}
        detectedColumns={detectedColumns}
        privacy={privacy}
        setPrivacy={setPrivacy}
        connection={connection}
        options={options}
        setOptions={setOptions}
        error={advancedError}
        setError={setAdvancedError}
        disabled={disabled}
      />

      {renderAboveSubmitButton}

      <Button
        size="lg"
        width="100%"
        isDisabled={saving || disabled}
        isLoading={saving}
        loadingText="Saving dataset..."
        onClick={handleSubmit}
        analyticsName="save"
      >
        Save dataset
      </Button>
    </CardStack>
  );
}
