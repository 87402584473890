import { StylesConfig } from "react-select";

import { colors } from "../../../styles/chakra-theme-v2";

export type DatasetSelectOption = {
  value: string;
  label: string;
  isDisabled?: boolean;
  hint?: string;
  fillRate?: number | null;
};

export const reactSelectStyle: StylesConfig<DatasetSelectOption> = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
    backgroundColor: "#ffffff",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  control: (provided, state) => {
    const borderColor =
      state.isFocused || state.menuIsOpen
        ? colors.fdy_purple[500]
        : colors.fdy_gray[400];
    return {
      display: "flex",
      height: "3rem",
      padding: ".25rem",
      borderWidth: "1px",
      borderColor,
      borderRadius: "6px",
      "&:hover": { borderColor: colors.fdy_gray[600] },
    };
  },
  option: (provided, state) => {
    const backgroundColor = state.isFocused ? colors.fdy_gray[200] : "#ffffff";
    const color = state.isSelected
      ? colors.fdy_purple[500]
      : state.isDisabled
      ? colors.fdy_gray[600]
      : "#000000";
    return {
      ...provided,
      backgroundColor,
      color,
    };
  },
  menu: (provided) => ({
    minWidth: "min-content",
    maxWidth: 700,
    ...provided,
  }),
};
