import { MinusCircle } from "@phosphor-icons/react";

import { PopoverInfoPill, PopoverInfoPillPart } from "../../ui/PopoverInfoPill";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { PipelinesTableQuery_scopes as Scope } from "../__generated__/PipelinesTableQuery";
import { scopeInfo } from "../scopeInfo";
import { ScopePopulationBreakdown } from "../ScopePopulationBreakdown";

export function ScopePopulationPill({ scope }: { scope: Scope }) {
  const { cohortIds, exclusionCohortIds } = scope.population;
  const cohortCount = cohortIds.length || "Everyone";
  const exclusionCohortCount = exclusionCohortIds.length;
  return (
    <PopoverInfoPill
      popover={
        <ScopePopulationBreakdown
          scope={scope}
          caption={scopeInfo.population}
        />
      }
    >
      <PopoverInfoPillPart icon={ResourceIcon.cohorts} value={cohortCount} />
      {exclusionCohortCount > 0 && (
        <PopoverInfoPillPart icon={MinusCircle} value={exclusionCohortCount} />
      )}
    </PopoverInfoPill>
  );
}
