import { Box } from "@chakra-ui/react";

import {
  createOutcomeGradeGrid,
  findGradeInGridOrFallback,
  getOutcomeGrades,
  getOutcomeGradesGrid,
} from "./outcomeGradeUtils";

export function OutcomeGradeBadge({
  rocAuc,
  liftValue,
  usesFPD,
}: {
  rocAuc: number;
  liftValue?: number | null;
  usesFPD: boolean;
}) {
  const grades = getOutcomeGrades(usesFPD);
  const gradegrids = getOutcomeGradesGrid(usesFPD);
  const grid = createOutcomeGradeGrid(gradegrids);
  const grade = findGradeInGridOrFallback({
    grades,
    grid,
    roc: rocAuc,
    lift: liftValue,
  });

  return (
    <Box
      sx={{
        display: "inline-block",
        background: grade?.meta.colors.bg,
        color: grade?.meta.colors.fg,
        borderRadius: "md",
        textTransform: "capitalize",
        px: 2,
        py: 1,
        fontSize: "fdy_xs",
        fontWeight: 600,
      }}
    >
      {grade?.meta.label ?? "Unknown"}
    </Box>
  );
}
