import { Recommender } from "@fdy/faraday-js";

import { CardV2 } from "../../ui/Card/CardV2";
import { RecommenderAnalysisData } from "./RecommenderAnalysisData";
import { RecommenderAnalysisPerformance } from "./RecommenderAnalysisPerformance";

interface RecommenderAnalysisCardsProps {
  recommender: Recommender;
}

/**
 * Renders recommender analysis cards.
 *
 * Cards wrap performance and data analysis components so their queries/errors
 * can be thrown to suspense/boundaries of Cards.
 *
 * Duplicated queries within are ok because react-query will dedupe them.
 */
export function RecommenderAnalysisCards({
  recommender,
}: RecommenderAnalysisCardsProps) {
  // skip rendering if not yet built
  if (!recommender.last_updated_output_at) return null;

  return (
    <>
      <CardV2 title="Performance">
        <RecommenderAnalysisPerformance recommender={recommender} />
      </CardV2>

      <CardV2
        title="Data"
        text="Which traits contributed the most to this recommender depends on prediction scenario."
      >
        <RecommenderAnalysisData recommender={recommender} />
      </CardV2>
    </>
  );
}
