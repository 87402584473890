import { gql } from "@apollo/client";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef, useState } from "react";

import { useToast } from "../../../../hooks/useToast";
import { getApiBaseUrl } from "../../../../services/getApiBaseUrl";
import { useSojournerMutation } from "../../../../services/sojournerApolloClient";
import { downloadFile } from "../../../../utils/downloadFile";
import { useToken } from "../../../ui/AuthProvider/useToken";
import { useModalV2 } from "../../../ui/ModalV2";
import {
  DeleteUploadMutation,
  DeleteUploadMutationVariables,
} from "./__generated__/DeleteUploadMutation";

const deleteUploadMutation = gql`
  mutation DeleteUploadMutation($directory: String!, $filename: String!) {
    deleteUpload(directory: $directory, filename: $filename)
  }
`;

export function CsvActionsMenu({
  filePath,
  onFileDelete,
}: {
  filePath: string;
  onFileDelete: () => void;
}) {
  const toast = useToast();
  const { confirm } = useModalV2();
  const authToken = useToken();
  const [downloading, setDownloading] = useState<boolean>(false);

  const [deleteUpload, { loading }] = useSojournerMutation<
    DeleteUploadMutation,
    DeleteUploadMutationVariables
  >(deleteUploadMutation);

  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const label = `Toggle ${filePath} actions`;

  const directory = filePath.split("/")[0];
  const filename = filePath.split("/")[1];

  function confirmDelete() {
    confirm({
      label: "Delete",
      title: "Delete file",
      text: `Are you sure you want to permanently delete ${filename}? Removing files that are potentially in use by downstream resources can cause errors. If in doubt, contact support.`,
      loading,
      onConfirm: handleDelete,
    });
  }

  async function handleDelete() {
    await deleteUpload({
      variables: { directory, filename },
      update: () => {
        onFileDelete();
      },
    });
    // this endpoint deletes immediately instead of queueing a deletion worker
    // so we can say the delete was successful
    toast({
      status: "success",
      title: "Deletion successful",
      description: `${filename} has been deleted`,
    });
  }

  // it's possible to do this with graphql instead of fetch
  // but graphql was hitting a size limit (probably because it returns the file as {upload: fileContents}, instead of returning a file)
  async function handleDownload(authToken: string | null) {
    setDownloading(true);
    if (!authToken) throw new Error("could not authorize download");
    const baseUrl = getApiBaseUrl();
    const resp = await fetch(`${baseUrl}/uploads/${directory}/${filename}`, {
      headers: {
        authorization: `Bearer ${authToken}`,
      },
      method: "GET",
    });
    // Any http status code errors will be caught here.
    // NOT errors like cors or network errors. Fetch will throw those automatically.
    if (!resp.ok) {
      setDownloading(false);
      throw new Error(resp.statusText);
    }

    const content = await resp.text();

    // the api returns the file with filename = fdysec, but downloadFile overwrites that
    const secureFilename = filename.includes("fdysec")
      ? filename
      : "fdysec_" + filename;
    downloadFile({ filename: secureFilename, content, type: "csv" });
    setDownloading(false);
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        ref={menuBtnRef}
        aria-label={label}
        icon={<DotsThreeOutline weight="fill" />}
        variant="secondary"
        color="fdy_gray.600"
        size="sm"
        isLoading={downloading || loading}
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            handleDownload(authToken);
          }}
          disabled={downloading}
        >
          Download
        </MenuItem>
        <MenuItem
          onClick={confirmDelete}
          color="fdy_red.500"
          disabled={loading}
        >
          Delete...
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
