import {
  Box,
  SystemStyleObject,
  useDisclosure,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import {
  CheckCircle,
  Icon,
  Info,
  Warning,
  X,
  XCircle,
} from "@phosphor-icons/react";
import { ReactNode } from "react";

import { useId } from "./useId";

export type NoticeVariant = "success" | "error" | "warning" | "info";

const icons: Record<NoticeVariant, Icon> = {
  success: CheckCircle,
  error: XCircle,
  warning: Warning,
  info: Info,
};

interface NoticeProps {
  variant: NoticeVariant;
  title: string;
  icon?: Icon;
  description?: ReactNode;
  dismissable?: boolean;
  sx?: SystemStyleObject;
}

/**
 * Render an alert to catch user's attention.
 *
 * Used for notifying user when something has gone right or wrong.
 *
 * Like Alert but with a new name to not conflict with Chakra Alerts, which are used for _toast_ alerts.
 */
export function Notice({
  variant = "success",
  title,
  icon,
  description,
  dismissable,
  sx,
}: NoticeProps) {
  const titleId = useId();
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });

  const themeStyles = useMultiStyleConfig("Notice", {
    variant,
  });

  if (isOpen === false) return null;

  const styles = {
    container: {
      display: "flex",
      borderRadius: 6,
      border: "1px solid",
      px: 6,
      py: 4,
      ...themeStyles.container,
    },
    icon: {
      mr: 3,
      height: 6,
      ...themeStyles.icon,
    },
    title: {
      fontWeight: "bold",
      ...themeStyles.title,
    },
    description: {
      "a,button": {
        color: "inherit",
        textDecoration: "underline",
        fontWeight: "bold",
      },
      ...themeStyles.description,
    },
    closeButton: {
      ml: "auto",
      pl: 1,
      height: "23px",
      width: "23px",
      ...themeStyles.closeButton,
    },
  };

  const IconComponent = icon ?? icons[variant];

  return (
    <Box
      __css={styles.container}
      role="alert"
      sx={sx}
      aria-labelledby={titleId}
    >
      <Box __css={styles.icon}>
        <IconComponent size={20} weight="duotone" />
      </Box>
      <Box>
        {/* using `Box` because `Text` ignores __css? */}
        <Box as="span" __css={styles.title} id={titleId}>
          {title}
        </Box>{" "}
        <Box as="span" __css={styles.description}>
          {description}
        </Box>
      </Box>
      {dismissable && (
        <Box
          as="button"
          onClick={onClose}
          __css={styles.closeButton}
          aria-label="Dismiss"
        >
          <X />
        </Box>
      )}
    </Box>
  );
}
