import { useDisclosure } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { Button } from "../../ui/Button";
import { ModalV2 } from "../../ui/ModalV2";
import { PipelineConnectionList } from "./PipelineConnectionList";
import { TargetConnectionState } from "./PipelineTargetManager";

/**
 * Renders a button, which upon click, opens a modal of that account's connections.
 * From that list, a user can click a connection to add another target for it.
 */
export function AddTargetButton({
  onConnectionSelect,
}: {
  onConnectionSelect: (conn: TargetConnectionState) => void;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <ModalV2
        isOpen={isOpen}
        onClose={onClose}
        title="Add deployment"
        width={768}
        analyticsStackName="add-deployment-modal"
      >
        <PipelineConnectionList
          onConnectionSelect={(conn) => {
            onConnectionSelect(conn);
            onClose();
          }}
        />
      </ModalV2>

      <Button
        variant="secondary"
        leftIcon={<Plus weight="bold" />}
        onClick={onOpen}
        width="100%"
        analyticsName="add-deployment"
      >
        Add deployment
      </Button>
    </>
  );
}
