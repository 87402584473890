import { number } from "./formatters";

type options = {
  formatter?: (val: number) => string;
  suffix?: string;
};

export function formatPendingCount(
  count: number | null | undefined,
  options: options = {}
) {
  const { formatter = number, suffix } = options;

  if (count === null || count === undefined) {
    return "Pending...";
  }

  const formatted = formatter(count);

  if (suffix) {
    return `${formatted} ${suffix}`;
  }

  return formatted;
}
