import { expandJwtPayload, JwtAuthType, UnreachableCodeError } from "@fdy/jwt";

import { env } from "../../env";
import { Auth0AuthService } from "./auth0";
import { AuthService, AuthServiceOptions, decodeJwtPayload } from "./base";
import { findEmbeddedToken } from "./embedded";
import { AuthError } from "./errors";
import { ShareableURLAuthService } from "./shareableURL";
import { ShopifyAuthService, shopifyShopFromUrlSearchParams } from "./shopify";
import { SimpleJwtAuthService } from "./simpleJwt";

export { AuthService };

/**
 * Select and create an appropriate `AuthService` implementation.
 *
 * @param options Configuration options for our auth service.
 */
export function createAuthService(options: AuthServiceOptions): AuthService {
  // Decide how to log things. We'd honestly like to just use `console.log`
  // here, but `eslint` is configured to disallow that anywhere in Popsicle.
  const logger = options.log || console;

  // Very cautiously decide which hostnames count as embedded.
  let embedded_host_re = /^embed\.faraday\.io$/;
  if (env.ENVIRONMENT === "development") {
    // If we're running in dev mode, also treat ngrok as embedded.
    embedded_host_re = /^(?:embed\.faraday\.[a-z]+|[0-9a-fA-F]+\.ngrok\.io)$/;
  }

  // Very cautiously decide which hostnames are valid for link-sharing.
  // share.faraday.io AND share-pr-###.dev.faraday.ai are valid
  let linking_sharing_host_re =
    /^share(\.faraday\.io|-pr-\d+\.dev\.faraday\.ai)$/;

  if (env.ENVIRONMENT === "development") {
    // If we're running in dev mode, let share.faraday.test through
    linking_sharing_host_re = /^share\.faraday\.test$/;
  }

  // Select and create our auth service.
  if (window.location.hostname.match(embedded_host_re)) {
    // Look up our token if we have one.
    const token = findEmbeddedToken();
    if (token) {
      const payload = decodeJwtPayload(token);
      const expandedPayload = expandJwtPayload(payload);

      // Use `auth_type` to choose an `AuthService` implementation.
      switch (expandedPayload.auth_type) {
        case JwtAuthType.Auth0:
          // Something has gone horribly and suspiciously wrong.
          throw new AuthError(
            "[AuthError] SECURITY: Received an Auth0 token in the embedded app"
          );

        case JwtAuthType.Shopify:
          logger.log("Using ShopifyAuthService");
          return new ShopifyAuthService(
            expandedPayload.external_account_id,
            token,
            options
          );

        case JwtAuthType.SimpleJwt:
          logger.log("Using SimpleJwtAuthService");
          return new SimpleJwtAuthService(token, options);

        case JwtAuthType.ShareableUrlJwt:
          // Something has gone horribly and suspiciously wrong.
          throw new AuthError(
            "[AuthError] SECURITY: Received a link-sharing token in the embedded app"
          );

        default:
          // There should be no possible, consistent value for expandedPayload
          // at this point, which we can be indicated to TypeScript by passing
          // it to `UnreachableCodeError`. This takes a parameter type of
          // `never`, indicating that no possible value is acceptable. (`never`
          // is also the return value of `throw`, for example.)
          //
          // This will cause a compile-time error if we haven't handled all the
          // possible values of `expandedPayload`.
          //
          // This works because we've carefully set up `ExpandedJwtPayload` to
          // be a union of several different types, each of which only allows
          // certain values of `auth_type`. If we've covered all the possible
          // `auth_type` values above, then no possible struct type for
          // `expandedPayload` still exists in our type union, and TypeScript is
          // happy. But if we've missed one, then we still have a possible value
          // for `expandedPayload`, and that can't be passed to a function
          // taking `never`.
          throw new UnreachableCodeError(expandedPayload);
      }
    }

    // We don't have a token, so see if we have a `?shop=` in our URL.
    const shop = shopifyShopFromUrlSearchParams();
    if (shop) {
      logger.log(
        `[AuthError] Using ShopifyAuthService (with no token) because we have shop=${shop}`
      );
      return new ShopifyAuthService(shop, null, options);
    } else {
      throw new AuthError(
        "[AuthError] Could not figure out which embedded AuthService to use"
      );
    }
  } else if (window.location.hostname.match(linking_sharing_host_re)) {
    // Look up our token if we have one.
    const token = findEmbeddedToken();
    if (token) {
      const payload = decodeJwtPayload(token);
      const expandedPayload = expandJwtPayload(payload);

      // Use `auth_type` to choose an `AuthService` implementation.
      switch (expandedPayload.auth_type) {
        case JwtAuthType.Auth0:
          // Something has gone horribly and suspiciously wrong.
          throw new AuthError(
            "[AuthError] SECURITY: Received an Auth0 token on the link-sharing domain"
          );

        case JwtAuthType.Shopify:
          // Something has gone horribly and suspiciously wrong.
          throw new AuthError(
            "[AuthError] SECURITY: Received a Shopify token on the link-sharing domain"
          );

        case JwtAuthType.SimpleJwt:
          // Something has gone horribly and suspiciously wrong.
          throw new AuthError(
            "[AuthError] SECURITY: Received a SimpleJWT on the link-sharing domain"
          );

        case JwtAuthType.ShareableUrlJwt:
          logger.log("Using ShareableURLAuthService");
          return new ShareableURLAuthService(token, options);

        default:
          // There should be no possible, consistent value for expandedPayload
          // at this point, which we can be indicated to TypeScript by passing
          // it to `UnreachableCodeError`. This takes a parameter type of
          // `never`, indicating that no possible value is acceptable. (`never`
          // is also the return value of `throw`, for example.)
          //
          // This will cause a compile-time error if we haven't handled all the
          // possible values of `expandedPayload`.
          //
          // This works because we've carefully set up `ExpandedJwtPayload` to
          // be a union of several different types, each of which only allows
          // certain values of `auth_type`. If we've covered all the possible
          // `auth_type` values above, then no possible struct type for
          // `expandedPayload` still exists in our type union, and TypeScript is
          // happy. But if we've missed one, then we still have a possible value
          // for `expandedPayload`, and that can't be passed to a function
          // taking `never`.
          throw new UnreachableCodeError(expandedPayload);
      }
    } else {
      // no token in url
      throw new AuthError(
        "[AuthError] No token found on link-sharing domain; giving up"
      );
    }
  } else {
    logger.log("Using Auth0AuthService");
    return new Auth0AuthService(options);
  }
}
