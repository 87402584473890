import { Box, forwardRef, useMultiStyleConfig } from "@chakra-ui/react";
import { ReactNode } from "react";

import { ErrorText } from "./ErrorText";
import { LabelSuffix } from "./FormField";
import { useId } from "./useId";

type FormFieldsetProps = {
  legend: ReactNode;
  suffix?: string;
  hint?: ReactNode;
  children: ReactNode;
  size?: "sm" | "md";
  error?: string;
};

export const FormFieldset = forwardRef<
  FormFieldsetProps,
  // this is a lie but it makes applying refs for error handling way easier
  "div"
>((props, ref) => {
  const { legend, suffix, hint, children, size = "md", error } = props;

  const styles = useMultiStyleConfig("FormFieldset", {
    size,
  });

  const errorId = useId();

  return (
    <Box as="fieldset" aria-describedby={errorId} ref={ref}>
      <Box as="legend" sx={styles.legend}>
        {legend}
        {suffix && <LabelSuffix>{suffix}</LabelSuffix>}
      </Box>
      {hint && <Box sx={styles.text}>{hint}</Box>}

      {children}

      <ErrorText id={errorId} data-testid="error-text">
        {error}
      </ErrorText>
    </Box>
  );
});
