import "./utils/apolloLogs";

import React from "react";
import { createRoot } from "react-dom/client";
import Rollbar from "rollbar";

import { Root } from "./components/root/Root";
import { Analytics } from "./services/analytics/Analytics";
import {
  DevelopmentTracker,
  GoogleTagManager,
  Heap,
  Hubspot,
} from "./services/analytics/trackers";

/**
 * Set up MSW API mocking in the browser (only in development mode).
 * https://mswjs.io/docs/integrations/browser/#conditionally-enable-mocking
 * @usage `npm run dev-server:mocked`
 */
async function enableMocking() {
  if (!process.env.ENABLE_MSW || process.env.NODE_ENV !== "development") {
    return;
  }

  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

// Clear possible bfcache issue if user logs out then clicks back
// https://web.dev/articles/bfcache#update_stale_or_sensitive_data_after_bfcache_restore
// https://github.com/auth0/auth0-react/issues/562
window.addEventListener("pageshow", (event) => {
  // The above link suggests checking that an authentication cookie is not present,
  // then it should reload. But in my testing, it was appearing as if the cookie
  // was still found, so that wasn't working. The cookie is still properly
  // cleared after reload.
  // Instead, we reload the page if it's persisted.
  // Hopefully users rely on tabs more than leaving the app and coming back via
  // forward/backward button clicks. Even if they do return that way and get a
  // reload, it'll appear like our loading splash screen.
  if (event.persisted) {
    location.reload();
  }
});

// don't destructure process because it's replaced at build time
const NODE_ENV = process.env.NODE_ENV;

const rollbar = new Rollbar({
  accessToken: env.ROLLBAR_KEY,
  captureUncaught: true,
  captureUncaughtExceptions: true,
  captureUnhandledRejections: true,
  checkIgnore: function (isUncaught, args) {
    if (isUncaught) {
      // Uncaught exceptions are always reported in ErrorBoundary, so we don't have to
      // duplicate them.
      if (NODE_ENV !== "production") {
        console.info(`Ignoring uncaught exception: ${args[0]}`);
      }
      return true;
    }
    return false;
  },
  verbose: NODE_ENV !== "production",
  payload: {
    environment: env.ENVIRONMENT || NODE_ENV,
  },
  maxItems: 0, // unlimited
});

const analytics = new Analytics({
  trackers: [Hubspot, GoogleTagManager, Heap].concat(
    NODE_ENV === "production" ? [] : [DevelopmentTracker]
  ),
  rollbar,
});

rollbar.configure({
  reportLevel: NODE_ENV !== "production" ? "info" : "error",
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: env.VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
});

const container = document.getElementById("app");
const root = createRoot(container);

enableMocking().then(() => {
  root.render(<Root analytics={analytics} rollbar={rollbar} />);
});

// A utility function that will print out all the analytics-enabled nodes for a particular view, useful for people
// who want to set up new heap event definitions:
window.$analytics = function () {
  const nodes = Array.from(document.querySelectorAll("[data-analytics]"));
  console.table(
    nodes.map((n) => [n.getAttribute("data-analytics"), n.innerText, n])
  );
};
