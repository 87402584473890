import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "@chakra-ui/react";
import { Infinity, Warning } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { titleCase } from "../../../utils/formatters";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { Blankslate } from "../../ui/Blankslate";
import { CardV2 } from "../../ui/Card/CardV2";
import { CardStack } from "../../ui/CardStack";
import { SettingsLayout } from "../SettingsLayout";
import { UsagesQuery } from "./__generated__/UsagesQuery";
import { PaymentSetupButton } from "./PaymentSetupButton";
import { usagesQuery } from "./usagesUtils";

function apiToUi(text: string) {
  return titleCase(
    text
      .replace("Number of ", "")
      .replace(" allowed", "")
      .replace("scope", "pipeline")
      .replace("target", "deployment")
      .replace("active objectives (AOs)", "active predictions (APs)")
  ) as string;
}

function UsageTable() {
  const {
    data: usageData,
    loading,
    error,
  } = useSojournerQuery<UsagesQuery>(usagesQuery);

  if (error) throw error;

  if (loading) return <AnimatedZapLogo />;
  if (!usageData) return <Blankslate title="No usage data" />;

  const usages = usageData.usages;

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>
            <VisuallyHidden>Warning</VisuallyHidden>
          </Th>
          <Th>Resource</Th>
          <Th width={100}>Used</Th>
          <Th width={100}>Allowed</Th>
        </Tr>
      </Thead>
      <Tbody>
        {usages.map((usage) => {
          if (!usage.description) return;
          const overLimit =
            usage.usage !== null &&
            usage.limit !== null &&
            usage.usage > usage.limit;
          const textColor = overLimit ? "fdy_yellow.600" : "black";
          const bgColor = overLimit ? "fdy_yellow.300" : "white";
          return (
            <Tr key={usage.description}>
              <Td
                color={textColor}
                backgroundColor={bgColor}
                verticalAlign="center"
              >
                {overLimit ? <Warning /> : null}
              </Td>
              <Td color={textColor} backgroundColor={bgColor}>
                {apiToUi(usage.description)}
              </Td>
              <Td color={textColor} backgroundColor={bgColor}>
                {usage.usage ? usage.usage.toLocaleString() : 0}
              </Td>
              <Td color={textColor} backgroundColor={bgColor}>
                {usage.limit !== undefined && usage.limit !== null ? (
                  usage.limit.toLocaleString()
                ) : (
                  <Infinity>
                    <title>Infinite</title>
                  </Infinity>
                )}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export function BillingPage() {
  const title = "Usage & Billing";
  return (
    <SettingsLayout
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.SETTINGS_BILLING,
      }}
    >
      <CardStack>
        <CardV2
          title="Usage"
          text="See how much of your account's resources you're using."
        >
          <UsageTable />
        </CardV2>

        <CardV2
          title="Payment method"
          text="Add a payment method to your account."
        >
          <PaymentSetupButton />
        </CardV2>
      </CardStack>
    </SettingsLayout>
  );
}
