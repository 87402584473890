import { CohortFragment } from "../../cohorts/__generated__/CohortFragment";
import { useCohortQuery } from "../../cohorts/useCohortQuery";
import { OutcomeFragment } from "../__generated__/OutcomeFragment";

export function cohortUsesFirstPartyData(cohort: CohortFragment): boolean {
  const hasFpdTraits = cohort.traits.some((t) => !t.name.startsWith("fig/"));
  const hasStream = !!cohort.streamName;
  const hasStreamConditions = cohort.streamConditions.length !== 0;
  return hasFpdTraits || hasStream || hasStreamConditions;
}

export function useOutcomeUsesFirstPartyData(outcome: OutcomeFragment) {
  const { data, loading, error } = useCohortQuery(outcome.eligibleCohortId, {
    skip: outcome.lastUpdatedOutputAt === null, // hasn't built once, we won't have data to show that uses this anyway
  });

  if (error) throw error;

  return {
    loading,
    usesFPD: data?.cohort ? cohortUsesFirstPartyData(data.cohort) : false,
  };
}
