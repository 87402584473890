import { useMemo } from "react";

import { ModelingField } from "../../__generated__/sojournerGlobalTypes";
import { TraitsMap, useTraitsQuery } from "../../hooks/useTraitsQuery";

export function modelingFieldsToLabeledFields(
  fields: ModelingField[],
  traitsMap: TraitsMap
) {
  const labeledFields = [];

  for (const field of fields) {
    // convert FIG_FIELD_NAME to fig/field_name
    const name = field.toLowerCase().replace(/^fig_/, "fig/");

    const trait = traitsMap[name];

    // if there's no trait, it may be locked, so skip it
    if (!trait) continue;

    labeledFields.push({
      label: trait.literate ?? trait.name,
      name,
      value: field,
    });
  }

  return labeledFields;
}

/**
 * Returns a list of traits usable to generate a persona set from.
 *
 * Via codegen, we have a fixed list of which traits are usable for creating a persona set, but they don't have the human readable names.
 * This hook fetches the list of traits so we can match up the names and return the list with human and machine names.
 */
export function useModelingFields(existingFields?: ModelingField[]) {
  const { loadingTraits, traitsMap } = useTraitsQuery();

  const fields = existingFields ?? Object.values(ModelingField);

  const labeledFields = useMemo(() => {
    if (!traitsMap) return [];
    return modelingFieldsToLabeledFields(fields, traitsMap);
  }, [traitsMap, fields]);

  return {
    fields: labeledFields,
    loading: loadingTraits,
  };
}
