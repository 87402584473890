import { Icon } from "@phosphor-icons/react";
import { ReactElement } from "react";

import * as ACCOUNT_FEATURES from "../../constants/accountFeatureNames";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  AccountFeature,
  useAccountConfigMap,
} from "../../hooks/accountConfigHooks";
import { ResourceIcon } from "../ui/ResourceIcon";
import { NavigationBarGroup } from "./NavigationBarGroup";
import { NavigationBarLink } from "./NavigationBarOption";

interface DataNavLinkOptions {
  feature?: AccountFeature;
  icon: Icon;
  label: string;
  routeName: string;
  routeParams?: Record<string, string>;
}

const navItems: DataNavLinkOptions[] = [
  {
    icon: ResourceIcon.datasets,
    label: "Datasets",
    routeName: ROUTE_NAMES.DATASETS,
    feature: ACCOUNT_FEATURES.DATASETS_ENABLED,
  },
  {
    icon: ResourceIcon.streams,
    label: "Events",
    routeName: ROUTE_NAMES.EVENTS,
    feature: ACCOUNT_FEATURES.EVENTS_ENABLED,
  },
  {
    icon: ResourceIcon.traits,
    label: "Traits",
    routeName: ROUTE_NAMES.TRAITS,
    feature: ACCOUNT_FEATURES.TRAITS_ENABLED,
  },
  {
    icon: ResourceIcon.cohorts,
    label: "Cohorts",
    routeName: ROUTE_NAMES.COHORTS,
    feature: ACCOUNT_FEATURES.COHORTS_ENABLED,
  },
];

export function DataNav(): ReactElement {
  const featureMap = useAccountConfigMap();

  function renderOption({
    feature,
    icon,
    label,
    routeName,
    routeParams,
  }: DataNavLinkOptions) {
    if (feature && !featureMap[feature]) {
      return null;
    }

    return (
      <NavigationBarLink
        key={label}
        icon={icon}
        label={label}
        routeName={routeName}
        routeParams={routeParams}
        analyticsName={label.toLowerCase()}
      />
    );
  }

  return (
    <NavigationBarGroup label="Data">
      {navItems.map(renderOption).filter(Boolean)}
    </NavigationBarGroup>
  );
}
