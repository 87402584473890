import upperFirst from "lodash/upperFirst";

export type CodeLanguage = "shell" | "javascript" | "python" | "ruby";

// The HTTP methods we support for snippets
export type HttpMethod = "GET" | "POST" | "PATCH" | "DELETE";

/**
 * A lite representation of an HTTP request.
 * You could use a real Request object, but this is simpler to work with for our purposes.
 */
export type HttpRequest = {
  method: HttpMethod;
  url: string;
  headers: Record<string, string>;
  body?: unknown;
};

function shell({ method, url, headers, body }: HttpRequest) {
  return `curl --request ${method} \\
  --url ${url} \\
  ${Object.entries(headers)
    .map(([key, value]) => `--header "${key}: ${value}"`)
    .join(" \\\n  ")}${
    body ? ` \\\n  --data '${JSON.stringify(body, null, 2)}'` : ""
  }`;
}

function javascript({ method, url, headers, body }: HttpRequest) {
  return `const url = '${url}';
const options = {
  method: '${method}',
  headers: ${JSON.stringify(headers)},
${body ? `  body: ${JSON.stringify(body)}\n}` : "}"}

try {
  const response = await fetch(url, options);
  if(!response.ok) throw new Error('Network response was not ok');
  const data = await response.json();
  console.log(data);
} catch (error) {
  console.error(error);
}`;
}

function python({ method, url, headers, body }: HttpRequest) {
  return `import requests

url = '${url}'
headers = ${JSON.stringify(headers)}
${body ? `data = '${JSON.stringify(body)}'\n` : ""}
response = requests.${method.toLowerCase()}(url, headers=headers${
    body ? ", data=data" : ""
  })

print(response.json())`;
}

function ruby({ method, url, headers, body }: HttpRequest) {
  return `require 'net/http'
require 'uri'

url = URI.parse('${url}')
http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

request = Net::HTTP::${upperFirst(method.toLowerCase())}.new(url)
${Object.entries(headers)
  .map(([key, value]) => `request['${key}'] = '${value}'`)
  .join("\n")}
${body ? `request.body = '${JSON.stringify(body)}'\n` : ""}
response = http.request(request)
puts response.read_body`;
}

// TODO: update snippets to show error handling when !res.ok?
/**
 * Given a Request and a language, returns a code snippet for making the request
 * in the given language.
 */
export function requestToHttpSnippet(
  request: HttpRequest,
  language: CodeLanguage
): string {
  switch (language.toLowerCase()) {
    case "shell":
      return shell(request);
    case "javascript":
      return javascript(request);
    case "python":
      return python(request);
    case "ruby":
      return ruby(request);
    default:
      throw new Error(`Unsupported language: ${language}`);
  }
}
