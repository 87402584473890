import { gql } from "@apollo/client";
import { MockedResponse } from "@apollo/client/testing";
import { useMemo } from "react";

import {
  PrimitiveDataType,
  TraitCategory,
} from "../__generated__/sojournerGlobalTypes";
import { useSojournerQuery } from "../services/sojournerApolloClient";
import { allTraitPermissions } from "../utils/traitUtils";
import { AllTraitsQuery } from "./__generated__/AllTraitsQuery";
import { TraitFragment } from "./__generated__/TraitFragment";
import { TraitQuery, TraitQueryVariables } from "./__generated__/TraitQuery";

// omit typename to match trait selector components
export type Trait = Omit<TraitFragment, "__typename">;

// Partial is used because lookups may not find a trait
// and we want typescript to let devs know that.
export type TraitsMap = Partial<{
  [fdyFieldName: string]: Trait;
}>;

const TRAIT_FRAGMENT = gql`
  fragment TraitFragment on Trait2 {
    id
    name
    category
    type
    breaks
    unit
    literate
    description
    lookupTable
    permissions
    deprecated

    # categories aren't truly emitted on list query, so this be aware of that. You need to query the detail endpoint.
    # We should add paginate GET /traits then emit categories. If we did emit them now,
    # several geographic traits would have a ton of categories (like zip codes)
    # and it'd explode the response (maybe it'd be fine?)
    categories
  }
`;

const TRAITS_QUERY = gql`
  query AllTraitsQuery {
    # in openapi-to-graphql, any field with same name makes others get a number appended
    # https://github.com/IBM/openapi-to-graphql/issues/256
    # Task to fix this: https://app.asana.com/0/1203008782586563/1203295128372658/f
    traits: traits3 {
      ...TraitFragment
    }
  }
  ${TRAIT_FRAGMENT}
`;

/**
 * Query all traits.
 *
 * Because we don't have traits meta info available when fetching cohort.traits,
 * we need to fetch all traits and then join them on the client.
 */
export function useTraitsQuery(options?: { skip?: boolean }) {
  const { data, loading, error } = useSojournerQuery<AllTraitsQuery>(
    TRAITS_QUERY,
    {
      skip: options?.skip,
    }
  );
  if (error) throw error;

  const traitsMap = useMemo(() => {
    if (!data?.traits) return {};

    return data.traits.reduce<TraitsMap>((acc, trait) => {
      acc[trait.name] = trait;
      return acc;
    }, {});
  }, [data?.traits, loading]);

  return {
    loadingTraits: loading,
    traits: data?.traits ?? [],
    traitsMap,
  };
}

const TRAIT_QUERY = gql`
  query TraitQuery($id: ID!) {
    trait: trait2(traitId: $id) {
      ...TraitFragment
    }
  }
  ${TRAIT_FRAGMENT}
`;

export function useTraitQuery(traitId: string): {
  loading: boolean;
  trait?: TraitFragment | null;
} {
  const { data, loading, error } = useSojournerQuery<
    TraitQuery,
    TraitQueryVariables
  >(TRAIT_QUERY, {
    variables: { id: traitId },
  });

  if (error) throw error;

  return {
    loading,
    trait: data?.trait,
  };
}

// *************************
// TEST UTILS
// *************************

export const mockTraits: TraitFragment[] = [
  // number trait
  {
    __typename: "Trait2",
    id: "age",
    breaks: [],
    categories: [],
    category: TraitCategory.FIG_DEMOGRAPHY,
    description: "how old the person is",
    literate: "Age",
    name: "age",
    type: PrimitiveDataType.LONG,
    unit: null,
    lookupTable: null,
    permissions: allTraitPermissions,
    deprecated: false,
  },

  // boolean trait
  {
    __typename: "Trait2",
    id: "gardener",
    breaks: [],
    categories: [],
    category: TraitCategory.FIG_LIFESTYLE,
    description: "likes to garden",
    name: "gardener",
    literate: "Gardener",
    type: PrimitiveDataType.BOOLEAN,
    unit: null,
    lookupTable: null,
    permissions: allTraitPermissions,
    deprecated: false,
  },

  // multicategorical trait
  {
    __typename: "Trait2",
    id: "shopping_style",
    breaks: [],
    categories: ["online", "big box stores", "brick and mortar"],
    category: TraitCategory.FIG_LIFESTYLE,
    description: "where they shop",
    name: "shopping_style",
    literate: "Shopping style",
    type: PrimitiveDataType.STRING,
    unit: null,
    lookupTable: null,
    permissions: allTraitPermissions,
    deprecated: false,
  },

  // plain text trait
  {
    __typename: "Trait2",
    id: "plain_text_trait",
    breaks: [],
    categories: [],
    category: TraitCategory.FIG_LIFESTYLE,
    description: "plain text trait",
    name: "plain_text_trait",
    literate: "Plain text trait",
    type: PrimitiveDataType.STRING,
    unit: null,
    lookupTable: null,
    permissions: allTraitPermissions,
    deprecated: false,
  },

  //lookup table trait
  {
    __typename: "Trait2",
    id: "renter",
    breaks: [1, 2, 3, 4],
    categories: [],
    category: TraitCategory.FIG_LIFESTYLE,
    description: "trait with lookup table",
    name: "renter",
    literate: "Lookup table trait",
    type: PrimitiveDataType.LONG,
    unit: null,
    lookupTable: {
      "1": "Definite renter",
      "2": "Probably renter",
      "3": "Probably homeowner",
      "4": "Definite homeowner",
    },
    permissions: allTraitPermissions,
    deprecated: false,
  },
];

export function makeTraitQuery(
  trait: TraitFragment
): MockedResponse<TraitQuery> {
  return {
    request: {
      query: TRAIT_QUERY,
      variables: {
        id: trait.id,
      },
    },
    result: {
      data: {
        trait,
      },
    },
  };
}

export function makeTraitsQuery(
  traits: AllTraitsQuery["traits"] = mockTraits
): MockedResponse<AllTraitsQuery> {
  return {
    request: {
      query: TRAITS_QUERY,
    },
    result: {
      data: {
        traits,
      },
    },
  };
}
