import { gql } from "@apollo/client";

import { connectionFragment } from "./connectionFragment";

export const connectionsQuery = gql`
  query ConnectionsTableQuery {
    connections {
      targets {
        id
      }
      datasets {
        id
      }
      # Reuse details query so we can grab all the options.
      # If we only wanted to create a fragment for the options themselves, we may have to define possibleTypes.
      # https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
      ...ConnectionDetails
    }
  }
  ${connectionFragment}
`;
