import { CSSObject, Link } from "@chakra-ui/react";
import { ArrowSquareOut, Icon } from "@phosphor-icons/react";
import { ReactElement } from "react";

import { Link as RouterLink } from "./Link";

interface BaseLinkProps {
  label: string;
  icon?: Icon;
  bordered?: boolean;
  muted?: boolean;
}

interface ExternalLinkProps extends BaseLinkProps {
  type: "external";
  href: string;
}

interface InternalLinkProps extends BaseLinkProps {
  type: "internal";
  routeName: string;
  params?: Record<string, string>;
}

export type CalloutLinkProps = ExternalLinkProps | InternalLinkProps;

const borderStyles: CSSObject = {
  borderTop: "1px solid",
  borderColor: "fdy_gray.300",
  py: 3,
};

const baseStyles: CSSObject = {
  display: "flex",
  gap: 2,
  justifyContent: "space-between",
  alignItems: "center",
  textDecoration: "none",
  // fontWeight: "semibold",
  "> span": {
    textDecoration: "underline",
  },

  _hover: {
    "> span": {
      textDecoration: "none",
    },
  },
};

function getStyles(props: CalloutLinkProps): CSSObject {
  const { bordered = true } = props;
  const styles = bordered ? { ...baseStyles, ...borderStyles } : baseStyles;

  if (props.muted) {
    return { ...styles, color: "fdy_gray.600" };
  }
  return styles;
}

export function CalloutLink(props: CalloutLinkProps): ReactElement {
  const styles = getStyles(props);
  const Icon = props.icon ?? ArrowSquareOut;

  const children = (
    <>
      <span>{props.label}</span>
      <Icon />
    </>
  );

  if (props.type === "external") {
    return (
      <Link href={props.href} target="_blank" sx={styles} rel="noreferrer">
        {children}
      </Link>
    );
  }

  if (props.type === "internal") {
    return (
      <Link
        as={RouterLink}
        routeName={props.routeName}
        params={props.params}
        sx={styles}
      >
        {children}
      </Link>
    );
  }

  throw new Error("Unknown link type");
}
