import { Box } from "@chakra-ui/react";
import { DragEvent, ReactNode, useState } from "react";

const DropZoneIndicator = ({
  children,
  hovering,
}: {
  children: ReactNode;
  hovering: boolean;
}) => (
  <Box
    sx={{
      position: "relative",
      color: "fdy_gray.800",
      background: "fdy_gray.100",
      borderColor: "fdy_gray.700",
      borderStyle: "dashed",
      borderWidth: "1px",
      borderRadius: "6px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      my: 2,
      p: 8,
      _hover: {
        background: "fdy_gray.200",
        borderColor: "fdy_gray.800",
        cursor: "pointer",
      },
      '&[data-hovering="true"]': {
        background: "fdy_gray.200",
        borderColor: "fdy_gray.800",
      },
    }}
    data-hovering={hovering}
  >
    <Box>{children}</Box>
  </Box>
);

interface DropZoneProps {
  children?: ReactNode;
  onFileDrop: (files: FileList) => void;
  uploading?: string;
}

// TODO: merge with visuals of DatasetUploadDropZone
export function DropZone({ children, onFileDrop, uploading }: DropZoneProps) {
  // entered state is managed separately because hovering children triggers drag leave
  const [entered, setEntered] = useState(false);
  const [hovering, setHovering] = useState(false);

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    setEntered(true);
    setHovering(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    setHovering(entered);
    setEntered(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    setHovering(false);
    if (!uploading && onFileDrop && e.dataTransfer?.files) {
      onFileDrop(e.dataTransfer.files);
    }
  };

  const cancelEventBubble = (e: DragEvent) => {
    e.preventDefault();
  };

  return (
    <Box
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={cancelEventBubble}
      onDrop={handleDrop}
    >
      <DropZoneIndicator hovering={hovering || !!uploading}>
        {children}
      </DropZoneIndicator>
    </Box>
  );
}
