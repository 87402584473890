import { gql, useMutation } from "@apollo/client";
import { X } from "@phosphor-icons/react";

import { useToast } from "../../../hooks/useToast";
import { Button } from "../../ui/Button";
import { useModalV2 } from "../../ui/ModalV2";
import {
  KickUserMutation,
  KickUserMutationVariables,
} from "./__generated__/KickUserMutation";
import { MembersQuery_users } from "./__generated__/MembersQuery";
import { MEMBERS_QUERY } from "./MemberTable";

const KICK_USER_MUTATION = gql`
  mutation KickUserMutation($id: UUID!) {
    kick(id: $id)
  }
`;

export function KickUserButton({ user }: { user: MembersQuery_users }) {
  const toast = useToast();
  const [kickUser, { loading }] = useMutation<
    KickUserMutation,
    KickUserMutationVariables
  >(KICK_USER_MUTATION, {
    refetchQueries: [{ query: MEMBERS_QUERY }],
    onCompleted() {
      toast({
        status: "success",
        title: "User removed",
        description: `${user.email} has been removed from the team.`,
      });
    },
  });

  const { confirm } = useModalV2();

  function handleKick() {
    confirm({
      label: "Remove user",
      text: (
        <>
          Are you sure you want to remove <strong>{user.email}</strong> from the
          team?
        </>
      ),
      onConfirm: () => kickUser({ variables: { id: user.id } }),
      loading,
    });
  }

  return (
    <Button
      variant="tertiary"
      size="xs"
      onClick={handleKick}
      leftIcon={<X />}
      aria-label={`Remove ${user.email}`}
      analyticsName="kick-user"
    >
      Remove
    </Button>
  );
}
