import { Box, Text, Tooltip } from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { AccordionV2 } from "../../../ui/AccordionV2";
import { Button } from "../../../ui/Button";
import { CopyInputV2 } from "../../../ui/CopyInputV2";
import { LinkButton } from "../../../ui/LinkButton";
import { RouterLink } from "../../../ui/RouterLink";
import { TargetFragment_options_TargetOptionsHostedCsv } from "../__generated__/TargetFragment";
import { TargetCardProps } from "./TargetCard";
import { PreviewPill, TargetCardBase } from "./TargetCardBase";
import { TargetListItemConfig } from "./TargetListItemConfig";
import { TargetPreviewButton } from "./TargetPreviewButton";

function HostedCsvButtons({
  options,
  targetId,
}: {
  options: TargetFragment_options_TargetOptionsHostedCsv;
  targetId: string;
}) {
  const disabled = options.outputUrl === null;

  const persistentDownloadUrl = `https://api.faraday.ai/v1/targets/${targetId}/download.csv`;

  return (
    <Box>
      {options.outputUrl ? (
        <LinkButton
          variant="secondary"
          href={options.outputUrl}
          width="100%"
          analyticsName="download-hosted-csv"
        >
          Download CSV
        </LinkButton>
      ) : (
        <Tooltip
          hasArrow
          label="During updates, the download button will be disabled"
        >
          <Button variant="secondary" width="100%" disabled>
            Download CSV
          </Button>
        </Tooltip>
      )}
      <AccordionV2 allowToggle bg="fff" mt={3}>
        <AccordionV2.Item title="Persistent URL for ETL tools">
          <Box mb={4}>
            <Text>
              When using the URL below, add{" "}
              <RouterLink routeName={ROUTE_NAMES.SETTINGS_API}>
                your API key
              </RouterLink>{" "}
              where indicated.
            </Text>
          </Box>
          <Box mb={4}>
            <CopyInputV2
              value={
                disabled
                  ? "Building CSV..."
                  : persistentDownloadUrl + "?key=YOUR_API_KEY"
              }
              disabled={disabled}
            />
          </Box>
        </AccordionV2.Item>
      </AccordionV2>
    </Box>
  );
}

export function TargetCardHostedCsv(props: TargetCardProps) {
  const { target, scope, traitsMap, scopeDeps, connections } = props;

  const scopeEnabled = scope.preview === false;

  if (target.options.__typename !== "TargetOptionsHostedCsv")
    throw new Error(
      "Tried to render a hosted csv target with non-hosted csv options"
    );

  return (
    <TargetCardBase {...props}>
      <TargetListItemConfig
        target={target}
        scopeDeps={scopeDeps}
        traitsMap={traitsMap}
        connections={connections}
      />
      {scopeEnabled ? null : <PreviewPill />}
      <HostedCsvButtons options={target.options} targetId={target.id} />
      <Box mt={3}>
        {!scopeEnabled ? <TargetPreviewButton target={target} /> : null}
      </Box>
    </TargetCardBase>
  );
}
