import { Text } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

export const MutedText: React.FC<PropsWithChildren> = ({ children }) => (
  <Text
    as="span"
    sx={{
      fontStyle: "italic",
      color: "fdy_gray.600",
    }}
  >
    {children}
  </Text>
);
