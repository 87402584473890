import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Container,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Outcome } from "@fdy/faraday-js";
import { useQuery as useRCQuery } from "@tanstack/react-query";
import { format, formatDistanceToNow } from "date-fns";
import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useFdyClient } from "../../services/FdyClientProvider";
import { CodeSample } from "../ui/CodeSample";
import { PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { Pagination } from "../ui/Pagination-v2/Pagination";
import { PopoverInfoPill } from "../ui/PopoverInfoPill";
import { ResourceLink } from "../ui/ResourceLink";
import { ResourceTableList } from "../ui/ResourceTableList";
import { TextWithInfoTooltip } from "../ui/TextWithInfoTooltip";
import {
  InformQuery,
  InformQuery_apiCalls,
  InformQueryVariables,
} from "./__generated__/InformQuery";

const INFORM_QUERY = gql`
  query InformQuery($page: Int!) {
    account {
      id
      api_request_count
    }
    apiCalls: api_calls(page: $page) {
      id
      content
      created_at
      outcome_id
      outcome {
        id
        name
      }
      path
      response
      runtime
    }
  }
`;

function CodePopover({
  label,
  code,
}: {
  label: string;
  code: FdyApiCallJson | null;
}) {
  if (!code) return null;
  return (
    <PopoverInfoPill
      bodyPadding={1}
      popover={
        <Box width={"min-content"} maxWidth={500}>
          <CodeSample code={JSON.stringify(code, null, 2)} />
        </Box>
      }
    >
      {label}
    </PopoverInfoPill>
  );
}

function ApiCallRow({
  apiCall,
  outcomes,
}: {
  apiCall: InformQuery_apiCalls;
  outcomes: Outcome[] | undefined;
}) {
  const outcome = outcomes?.find((o) => o.id === apiCall.outcome_id);

  const runtimeMs = apiCall.runtime
    ? Number(apiCall.runtime * 1000).toLocaleString("en-us", {
        maximumFractionDigits: 0,
      })
    : 0;

  const classicOutcomeName = apiCall.outcome
    ? `${apiCall.outcome.name} (classic)`
    : null;

  const outcomeContent = outcome ? (
    <ResourceLink
      resource={{
        __typename: "Outcome",
        id: outcome.id,
        name: outcome.name,
      }}
    />
  ) : (
    classicOutcomeName
  );

  return (
    <Tr key={apiCall.id}>
      <Td>{apiCall.id}</Td>
      <Td>{outcomeContent}</Td>
      <Td>{apiCall.path}</Td>
      <Td>
        <CodePopover label="Request" code={apiCall.content} />
      </Td>
      <Td>
        <CodePopover label="Response" code={apiCall.response} />
      </Td>
      <Td>{runtimeMs}ms</Td>
      <Td>
        <TextWithInfoTooltip
          title={`${formatDistanceToNow(apiCall.created_at)} ago`}
          tooltip={format(apiCall.created_at, "yyyy-MM-dd HH:mm:ss")}
        />
      </Td>
    </Tr>
  );
}

const PAGE_SIZE = 30; // must match amount we get back from inform query

function InformTable() {
  const client = useFdyClient();
  const outcomesQuery = useRCQuery({
    queryKey: ["outcomes"],
    queryFn: () => client.outcomes.getOutcomes(),
  });

  const { route, router } = useRoute();
  const page = parseInt(route.params.page || 1);

  function handlePageNavigation(page: number) {
    router.navigate(route.name, { ...route.params, page });
  }

  const { data, loading, error } = useQuery<InformQuery, InformQueryVariables>(
    INFORM_QUERY,
    {
      variables: { page: page - 1 },
      fetchPolicy: "cache-first",
    }
  );

  return (
    <>
      <Table mb={4}>
        <Thead>
          <Tr>
            <Th>Request ID</Th>
            <Th>Outcome</Th>
            <Th>Path</Th>
            <Th>Request</Th>
            <Th>Response</Th>
            <Th>Runtime</Th>
            <Th>Timestamp</Th>
          </Tr>
        </Thead>
        <Tbody>
          <ResourceTableList
            error={error}
            loading={loading || outcomesQuery.isLoading}
            data={data?.apiCalls}
            blankSlateProps={{ title: "No API requests" }}
            renderData={(c) => (
              <ApiCallRow
                key={c.id}
                apiCall={c}
                outcomes={outcomesQuery.data}
              />
            )}
          />
        </Tbody>
      </Table>

      <Pagination
        disabled={loading}
        pageSize={PAGE_SIZE}
        total={data?.account.api_request_count ?? 0}
        current={page}
        onChange={handlePageNavigation}
      />
    </>
  );
}

export function Inform() {
  const title = "Inform";
  return (
    <PageLayout analyticsStackName="inform">
      <PageHeader
        title={title}
        crumbs={[
          {
            label: title,
            routeName: ROUTE_NAMES.INFORM,
          },
        ]}
      />
      <Container py={8}>
        <InformTable />
      </Container>
    </PageLayout>
  );
}
