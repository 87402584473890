import { ScaleLinear, scaleLinear } from "d3";
import { ReactElement, useMemo } from "react";
import { useMeasure } from "react-use";

import { theme } from "../../../constants/theme";
import { colors } from "../../../styles/chakra-theme-v2";
import { formatBinLabel } from "./analysisUtils";
import { getLabelCoords, transitionStyles } from "./chartUtils";
import { AnalysisDataset, Bounds, Field } from "./types";

const CHART_HEIGHT = 35;
const BAR_HEIGHT = 8;

/**
 * Merges labels if their values match others
 */
function mergeSameLabels(q1: number, q2: number, q3: number) {
  if (q1 === q2 && q2 === q3) {
    return [{ label: "q1, median, and q3", value: q2 }];
  }

  if (q1 === q2) {
    return [
      { label: "q1 and median", value: q2 },
      { label: "q3", value: q3 },
    ];
  }

  if (q2 === q3) {
    return [
      { label: "q1", value: q1 },
      { label: "median and q3", value: q2 },
    ];
  }

  return [
    { label: "q1", value: q1 },
    { label: "median", value: q2 },
    { label: "q3", value: q3 },
  ];
}

/**
 * Creates an array of labels to render for q1/q3 and median. All labels are positioned
 * on the x coordinate acting as the label center (with text align center) by default.
 *
 * - Sets the text alignment of the label if it's flush to either edge of the chart
 * - Sets `hidden` property if they would overlap other labels (except for median)
 * - Sets the x coordinate of the label
 */
function createLabels({
  data,
  field,
  scale,
  left,
  right,
}: {
  /** analysis chunk of data for the given field */
  data: AnalysisDataset;
  /** field so we can check the format type */
  field: Field;
  /** d3 linear scale */
  scale: ScaleLinear<number, number>;
  /** chart left coordinate */
  left: number;
  /** chart right coordinate */
  right: number;
}) {
  const { q1, q2, q3 } = data;
  const mergedLabels = mergeSameLabels(q1, q2, q3);

  const labels = mergedLabels.map((item) => {
    const label = formatBinLabel(field, item.value);

    // get the center position for the label
    const x = scale(item.value);

    const { textAnchor, labelRight, labelLeft } = getLabelCoords({
      x,
      label,
      left,
      right,
    });

    return {
      ...item,
      formattedValue: label,
      textAnchor,
      x,
      labelLeft,
      labelRight,
    };
  });

  // Now that labels know their own left/right coords,
  // hide them if they overlap other labels.
  const labelsWithHidden = labels.map((item, i, labels) => {
    const prev = labels[i - 1];
    const next = labels[i + 1];

    // Never want to hide labels if it contains median,
    // we should only be hiding q1/q3.
    if (item?.label.includes("median")) {
      return item;
    }

    if (
      (next && item.labelRight > next.labelLeft) ||
      (prev && item.labelLeft < prev.labelRight)
    ) {
      return {
        ...item,
        hidden: true,
      };
    }

    return item;
  });

  return labelsWithHidden;
}

/**
 * Ensure median rect is contained within the chart bounds.
 *
 * @param x - original x coordinate
 * @param size - width of median rect
 * @param chartRight - right edge of chart bounding box coordinate
 */
function getMedianX(x: number, size: number, chartRight: number) {
  // if median would be flush left
  if (x < size) {
    return x;
  }

  // if median would be flush right
  if (x > chartRight - size) {
    return x - size;
  }

  // otherwise center it on x
  return x - size / 2;
}

/**
 * Renders a gold, bordered rect for the median.
 * Also ensures it's flush to chart edges if it would overlap.
 */
function Median({ x, chartRight }: { x: number; chartRight: number }) {
  const size = 8;

  return (
    <rect
      x={getMedianX(x, size, chartRight)}
      y={0}
      height={size}
      width={size}
      fill={colors.white}
      stroke={colors.fdy_gray[400]}
      strokeWidth={2}
      rx={2}
      ry={2}
      style={transitionStyles}
    />
  );
}

/**
 * Renders a white rect for boxplot quartiles
 */
function Quartile({ x }: { x: number }) {
  return (
    <rect
      x={x - 2} // minus half the rect width for centering
      y={2}
      width={4}
      height={4}
      fill={theme.colors.white}
      rx={1}
      ry={1}
      style={transitionStyles}
    />
  );
}

interface LabelProps {
  label?: string;
  value?: number;
  formattedValue: string;
  textAnchor: string;
  x: number;
  hidden?: boolean;
}

function Label({ label, formattedValue, x, textAnchor, hidden }: LabelProps) {
  const isMedianLabel = label?.includes("median");
  const fontWeight = isMedianLabel ? 600 : undefined;

  // Adjust the x position a little to add 'padding' if the label would
  // be flush either edge of the chart.
  const offsetX: Record<string, number> = {
    start: x + 2,
    end: x - 2,
    middle: x,
  };

  const labelX = offsetX[textAnchor];

  return (
    <g
      style={{
        ...transitionStyles,
        transform: `translate(${labelX}px,0)`,
        display: hidden ? "none" : undefined,
      }}
    >
      <text
        x={0}
        y={22}
        textAnchor={textAnchor}
        style={{
          fontSize: 14,
          fontWeight,
          fill: theme.colors.dark_gray,
        }}
      >
        {formattedValue}
      </text>
      <text
        x={0}
        y={34}
        textAnchor={textAnchor}
        style={{
          fill: theme.colors.med_gray,
          fontSize: 12,
          fontWeight,
          textTransform: "uppercase",
        }}
      >
        {label}
      </text>
    </g>
  );
}

interface BoxplotProps {
  color: string;
  data: AnalysisDataset;
  bounds: Bounds;
  field: Field;
}

export function Boxplot({
  data,
  bounds,
  color,
  field,
}: BoxplotProps): ReactElement {
  const [ref, { width, left, right }] = useMeasure();
  // The typedef for useMeasure's ref param is slightly wrong. Shim it until
  // it can be fixed. see: <https://github.com/streamich/react-use/issues/1264>
  const refShim = (el: HTMLElement | null) => {
    if (el) ref(el);
  };

  const { min, max, q1, q2, q3 } = data;

  const scale = scaleLinear().domain(bounds).range([0, width]);

  const labels = useMemo(
    () => createLabels({ data, field, scale, left, right }),
    [data, field, scale, left, right]
  );

  return (
    <div ref={refShim} data-bound={bounds}>
      <svg
        width={width}
        height={CHART_HEIGHT}
        viewBox={`0 0 ${width} ${CHART_HEIGHT}`}
      >
        {/* background bar fill  */}
        <rect
          width={width}
          height={BAR_HEIGHT}
          x={0}
          y={0}
          fill={theme.colors.lighter_gray}
        />

        {/* the 'lines' of a whiskerplot as a rectangle */}
        <rect
          x={scale(min)}
          y={0}
          width={scale(max) - scale(min)}
          height={BAR_HEIGHT}
          fill={color}
          style={transitionStyles}
        />

        <Quartile x={scale(q1)} />

        <Quartile x={scale(q3)} />

        <Median x={scale(q2)} chartRight={right} />

        {labels.map((props, i) => (
          <Label key={i} {...props} />
        ))}
      </svg>
    </div>
  );
}
