import { gql } from "@apollo/client";
import { format } from "date-fns";

import { TargetPostInput } from "../../../__generated__/sojournerGlobalTypes";
import {
  HUBSPOT_USER_ADDED_PIPELINE_DEPLOYMENT,
  useHubSpotEvent,
} from "../../../hooks/useHubspotEvent";
import { useToast } from "../../../hooks/useToast";
import { useSojournerMutation } from "../../../services/sojournerApolloClient";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { TARGETS_QUERY } from "../PipelinesListPage/PipelinesTable";
import { PIPELINES_SHOW_PAGE_QUERY } from "../usePipelineQuery";
import {
  CreateTargetMutation,
  CreateTargetMutationVariables,
} from "./__generated__/CreateTargetMutation";
import {
  PIPELINE_TARGET_MANAGER_QUERY,
  TargetConnectionState,
} from "./PipelineTargetManager";
import { TargetForm } from "./TargetForm";
import {
  removeEmptyValues,
  targetFilterToPostInput,
} from "./TargetForm/TargetFilter/targetFilterUtils";
import { TargetStateForWire } from "./TargetForm/TargetFormAdvanced";
import { targetFragment } from "./targetFragment";

export const createTargetMutation = gql`
  mutation CreateTargetMutation($target: TargetPostInput!) {
    createTarget(targetPostInput: $target) {
      ...TargetFragment
    }
  }
  ${targetFragment}
`;

interface TargetNewFormAdvancedProps {
  scope: PipelineFragment;
  targetConnectionState: TargetConnectionState;
  onCompleted: () => void;
  onBack: () => void;
}

/**
 * Given a TargetStateForWire, convert it to
 * a TargetPostInput for POSTing to the API.
 *
 * The main difference is that we need to convert
 * null values to undefined for POSTing.
 */
function targetStateToPostInput({
  state,
  scope,
  targetConnectionState: { info, id },
}: {
  state: TargetStateForWire;
  scope: PipelineFragment;
  targetConnectionState: TargetConnectionState;
}): TargetPostInput {
  // Targets require a unique name on the account, but not much reason for
  // users to choose one so generate a unique one using datetime.
  const timestamp = format(new Date(), "MMM d, yyyy h:mm:ssa");
  const name = `${info.literate} ${timestamp}`;
  const options = removeEmptyValues(state.options);
  const filter = targetFilterToPostInput(state.filter);
  const input: TargetPostInput = {
    name,
    scopeId: scope.id,
    connectionId: id ?? undefined,
    representation: state.representation,
    humanReadable: state.humanReadable,
    customStructure: state.customStructure ?? undefined,
    limit: (state.limit as SojJSON | null) ?? undefined,
    options: {
      ...options,
      type: info.slug,
    },
    filter,
  };

  return input;
}

/**
 * Renders a form for creating a Target for a Connection.
 */
export function TargetNewForm({
  targetConnectionState,
  scope,
  onCompleted,
  onBack,
}: TargetNewFormAdvancedProps) {
  const toast = useToast();
  const track = useHubSpotEvent();

  const [createTarget, { loading }] = useSojournerMutation<
    CreateTargetMutation,
    CreateTargetMutationVariables
  >(createTargetMutation, {
    // when target is added, ensure it shows up in the existing list of targets
    refetchQueries: [
      {
        query: PIPELINE_TARGET_MANAGER_QUERY,
        variables: {
          scopeId: scope.id,
        },
      },
      // adding a new target should also update the list of targets in the pipeline table
      {
        query: TARGETS_QUERY,
      },
      {
        query: PIPELINES_SHOW_PAGE_QUERY,
        variables: {
          scopeId: scope.id,
        },
      },
    ],
    onCompleted(data) {
      if (!data?.createTarget) return;

      track(HUBSPOT_USER_ADDED_PIPELINE_DEPLOYMENT, {
        resource_name: data.createTarget.name,
        resource_id: data.createTarget.id,
        deployment_type: data.createTarget.options.type,
      });

      const { literate } = targetConnectionState.info;
      toast({
        status: "success",
        title: `${literate} deployment added!`,
        description: `${literate} will be in preview mode until the pipeline is enabled.`,
      });

      onCompleted();
    },
    update(cache) {
      // Some targets create their connections implicitly, so we need to
      // ensure the connections cache becomes stale so it's refetched from network.
      // Simply using 'refetchQueries' doesn't set the network status for components that use that query,
      // so UI would render and fail to look up the new target's connection.
      if (targetConnectionState.info.type === "publication") {
        cache.evict({ id: "ROOT_QUERY", fieldName: "connections" });
        cache.gc();
      }
    },
  });

  function onSave(state: TargetStateForWire) {
    const target = targetStateToPostInput({
      state,
      scope,
      targetConnectionState,
    });

    createTarget({
      variables: {
        target,
      },
    });
  }

  return (
    <TargetForm
      scope={scope}
      saving={loading}
      connectionTypeInfo={targetConnectionState.info}
      onSave={onSave}
      onCancel={onBack}
    />
  );
}
