import { gql, useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { ReactElement, useMemo, useState } from "react";
import { usePreviousDistinct } from "react-use";

import { SegmentSubtype } from "../../../__generated__/globalTypes";
import { theme } from "../../../constants/theme";
import { Skeleton } from "../../ui/Skeleton";
import { AnalysisChart } from "../analysis/AnalysisChart";
import { AnalysisMap, Field } from "../analysis/types";
import {
  ExploreBetaAnalysisQuery,
  ExploreBetaAnalysisQuery_segments_analysis,
  ExploreBetaAnalysisQueryVariables,
} from "./__generated__/ExploreBetaAnalysisQuery";
import { AttributeListDataWrap } from "./AttributeSidebar";
import { getBoundsForAnalysis } from "./getBoundsForAnalysis";
import { SegmentListBeta } from "./SegmentListBeta";
import { SegmentInfo, useExploreParams } from "./useExploreParams";

const FACETS_WIDTH = theme.space(43);

const StyledAnalysisGrid = styled.div`
  display: grid;
  height: calc(100vh - 115px); // page header height
  overflow: hidden;
  grid-template-columns: 1fr ${FACETS_WIDTH};
`;

const exploreAnalysisBetaQuery = gql`
  query ExploreBetaAnalysisQuery($segments: [SegmentInfo!]!) {
    segments_analysis(segments: $segments) {
      id
      target_id
      target_type
      subtype
      data
    }
  }
`;

/**
 * Finds the dataset to display based on the segment info then renders
 * the analysis chart for it.
 */
function SegmentChart({
  id,
  color,
  type,
  subtype,
  datasets,
  field,
  bounds,
}: {
  color: string;
  datasets: ExploreBetaAnalysisQuery_segments_analysis[];
  field: Field | null;
  bounds: [number, number];
} & SegmentInfo) {
  const segmentData = useMemo(() => {
    return datasets.find((s) => {
      return (
        s.target_id === id &&
        s.target_type === type &&
        (subtype ? s.subtype === subtype : true)
      );
    });
  }, [datasets, type, id, subtype]);

  // we should not get to this state if we disable adding the segment to begin with.
  if (!segmentData?.data) {
    return (
      <Box display="flex" alignItems="center" h="100%">
        <Skeleton width="100%" height={2} />
      </Box>
    );
  }

  if (!field) {
    return null;
  }

  const fieldData = segmentData.data[field?.name];

  if (!fieldData) {
    return (
      <p style={{ paddingTop: 24, fontStyle: "italic" }}>
        US population baseline for "{field.literate}" not found
      </p>
    );
  }

  return (
    <AnalysisChart
      bounds={bounds}
      field={field}
      data={fieldData}
      color={color}
    />
  );
}

export function ExploreAnalysisBeta(): ReactElement {
  const { segments: paramSegments } = useExploreParams();

  const segments = paramSegments;

  // track the currently selected field from the attribute/field list
  const [field, setField] = useState<Field | null>(null);

  // we have to get all segments data at once so we can find the bounds for everything
  const { data, error } = useQuery<
    ExploreBetaAnalysisQuery,
    ExploreBetaAnalysisQueryVariables
  >(exploreAnalysisBetaQuery, {
    variables: {
      segments: [
        ...segments.map((s) => ({
          target_id: s.id,
          target_type: s.type,
          subtype: s.subtype,
        })),
        {
          subtype: SegmentSubtype.national,
        },
      ],
    },
  });

  // Maintain data between refetches to prevent already loaded charts from
  // reanimating/flashing when we add a new segment.
  const prevData = usePreviousDistinct(data?.segments_analysis);

  const datasets = data?.segments_analysis ?? prevData ?? [];

  const rangeChartBounds = useMemo(
    () => getBoundsForAnalysis(datasets, field),
    [datasets, field]
  );

  const analysis = useMemo(() => {
    if (datasets.length === 0) return;
    return datasets.reduce<AnalysisMap>((acc, seg) => {
      return {
        ...acc,
        [seg.id]: seg.data,
      };
    }, {});
  }, [datasets]);

  if (error) throw error;

  return (
    <StyledAnalysisGrid>
      <SegmentListBeta
        segments={segments ?? []}
        renderAfterSegment={(segmentItemProps) => (
          <SegmentChart
            field={field}
            datasets={datasets}
            bounds={rangeChartBounds}
            {...segmentItemProps}
          />
        )}
        showBaseline
      />
      <AttributeListDataWrap
        analysis={analysis}
        field={field}
        setField={setField}
      />
    </StyledAnalysisGrid>
  );
}
