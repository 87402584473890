import { PencilSimple } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { CardV2 } from "../../ui/Card/CardV2";
import { LinkButton } from "../../ui/LinkButton";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { scopeInfo } from "../scopeInfo";
import { ScopePayloadBreakdown } from "../ScopePayloadBreakdown";

interface PipelinePayloadCardProps {
  scope: PipelineFragment;
}

export function PipelinePayloadCard({ scope }: PipelinePayloadCardProps) {
  return (
    <CardV2 title="Payload" text={scopeInfo.payload}>
      <ScopePayloadBreakdown scope={scope} />

      <LinkButton
        routeName={ROUTE_NAMES.PIPELINES_EDIT}
        params={{ id: scope.id }}
        variant="secondary"
        width="100%"
        leftIcon={<PencilSimple />}
        sx={{ mt: 6 }}
        analyticsName="edit-payload"
      >
        Edit
      </LinkButton>
    </CardV2>
  );
}
