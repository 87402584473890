import { Spinner } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CaretDown, X } from "@phosphor-icons/react";
import { MouseEvent, ReactElement, ReactNode } from "react";

import { theme } from "../../constants/theme";
import { colors } from "../../styles/chakra-theme-v2";
import {
  analyticsAttrs,
  useAnalyticsKey,
} from "../ui/Analytics/AnalyticsStack";
import { ErrorBoundary } from "../ui/ErrorBoundary";
import { SegmentCount } from "./beta/cards/SegmentCount";

const StyledCard = styled.div<{ active?: boolean }>`
  background: ${theme.colors.white};
  border-radius: ${theme.radii.md};
  border: 1px solid ${colors.fdy_gray[300]};
  box-shadow: ${theme.shadows.standard};
  overflow: hidden;
  width: ${theme.space(40)};

  ${(p) =>
    p.active &&
    css`
      box-shadow: 4px 0 0 ${colors.fdy_purple[200]};
    `}
`;

const StyledHeader = styled.div<{ bg?: string }>`
  background-color: ${(p) => (p.bg ? p.bg : colors.fdy_gray[800])};
  display: flex;
`;

/**
 * This mainly exists since some views truncate the card name,
 * so we have to apply flexbox fixes in order for ellipsis to show.
 */
const StyledHeaderContent = styled.div`
  min-width: 0;
  flex: 1;
`;

const StyledIcon = styled.div`
  color: ${theme.colors.black};
  margin-right: ${theme.space(1.5)};
`;

const StyledHeaderBtn = styled.button`
  padding: ${theme.space(2)};
  color: ${theme.colors.black};
  transition: background-color ${theme.durations.quick};

  &:hover:not([disabled]) {
    background: rgba(0, 0, 0, 0.05);
  }

  &[disabled] {
    opacity: 0.5;
  }
`;

const StyledToggleBtn = styled(StyledHeaderBtn)`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
`;

const StyledCloseBtn = styled(StyledHeaderBtn)`
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  margin-left: auto;
`;

const StyledTitle = styled.h3`
  font-size: ${theme.fontSizes.md};
  font-weight: bold;
  margin: 0; // TODO: remove global heading margins
`;

const StyledToggleIcon = styled.div<{ flip?: boolean }>`
  margin-left: auto;
  transition: transform ${theme.durations.quick};

  ${(p) =>
    p.flip &&
    css`
      transform: rotate(-180deg);
    `}
`;

const StyledFooter = styled.div`
  border-top: 1px solid ${theme.colors.light_gray};
  padding: ${theme.space(1)};
`;

const StyledHeaderInner = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.space(2)};
  // also used for setting icon color via currentColor of the svg
  color: ${theme.colors.black};
  width: 100%;
`;

interface CardProps {
  name?: ReactNode;
  count?: ReactNode | number;
  icon?: JSX.Element;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  color?: string;
  isOpen?: boolean;
  loading?: boolean;
  onRemoveClick?: (event: MouseEvent) => void;
  onToggleOpenClick?: (event: MouseEvent) => void;
  disabled?: boolean;
  /** apply active indicator styles */
  active?: boolean;
}

export function CardIcon({ icon }: { icon: ReactNode }): ReactElement {
  return <StyledIcon>{icon}</StyledIcon>;
}

export function Card({
  active,
  name,
  count,
  color,
  icon,
  onToggleOpenClick,
  onRemoveClick,
  isOpen,
  loading,
  disabled,
  header,
  body,
  footer,
}: CardProps): ReactElement {
  const analyticsKey = useAnalyticsKey("audience-card");
  if (loading) {
    return (
      <StyledCard>
        <StyledHeader bg={color}>
          <StyledHeaderInner>
            <Spinner size="sm" />
          </StyledHeaderInner>
        </StyledHeader>
      </StyledCard>
    );
  }

  const removeBtn = onRemoveClick ? (
    <StyledCloseBtn aria-label="Remove this segment" onClick={onRemoveClick}>
      <X />
    </StyledCloseBtn>
  ) : null;

  const headerContent = (
    <>
      {icon && <CardIcon icon={icon} />}
      <StyledHeaderContent>
        <StyledTitle>{name}</StyledTitle>
        {typeof count === "number" ? <SegmentCount count={count} /> : count}
      </StyledHeaderContent>
    </>
  );

  const defaultHeader = onToggleOpenClick ? (
    <StyledToggleBtn
      onClick={onToggleOpenClick}
      aria-expanded={isOpen ? "true" : "false"}
      disabled={disabled}
    >
      {headerContent}
      <StyledToggleIcon flip={isOpen}>
        <CaretDown />
      </StyledToggleIcon>
    </StyledToggleBtn>
  ) : (
    <StyledHeaderInner>{headerContent}</StyledHeaderInner>
  );

  return (
    <StyledCard active={active} {...analyticsAttrs(analyticsKey)}>
      <StyledHeader bg={color}>
        {header ? header : defaultHeader}
        {removeBtn}
      </StyledHeader>
      {isOpen && !disabled && (
        <>
          <ErrorBoundary>{body}</ErrorBoundary>
          {footer && <StyledFooter>{footer}</StyledFooter>}
        </>
      )}
    </StyledCard>
  );
}
