import { useState } from "react";

import {
  TargetAggregateGeographic,
  TargetAggregateIdentified,
  TargetTransformPresetAggregated,
  TargetTransformPresetHashed,
  TargetTransformPresetIdentified,
} from "../../../../__generated__/sojournerGlobalTypes";
import { useAccountConfigMap } from "../../../../hooks/accountConfigHooks";
import { useTraitsQuery } from "../../../../hooks/useTraitsQuery";
import { AnimatedZapLogo } from "../../../ui/AnimatedZapLogo";
import { PipelineFragment } from "../../__generated__/PipelineFragment";
import { ConnectionTypeInfo, isConnectionType } from "../../connectionUtils";
import { useScopeDependencies } from "../useScopeDependencies";
import {
  TargetFormAdvanced,
  TargetFormState,
  TargetStateForWire,
} from "./TargetFormAdvanced";
import { TargetFormRepresentation } from "./TargetFormRepresentation";
import { TargetRepresentationType } from "./TargetRepresentationRadios";

interface TargetFormProps {
  initialState?: TargetFormState;
  connectionTypeInfo: ConnectionTypeInfo;
  scope: PipelineFragment;
  saving: boolean;
  onSave: (state: TargetStateForWire) => void;
  onCancel: () => void;
}

export enum TargetModalStep {
  /** Step 1: target representation + human-readable */
  Representation = "representation",
  /** Step 2: advanced options (limit, structure, connection options) */
  Advanced = "advanced",
}

/**
 * Renders the target form for editing or creating a target.
 * The form renders as two steps:
 * 1. Target representation + human/machine readable
 * 2. Advanced options (limit, structure, connection options, filters)
 */
export function TargetForm({
  initialState,
  scope,
  saving,
  onSave,
  onCancel,
  connectionTypeInfo,
}: TargetFormProps) {
  const scopeDeps = useScopeDependencies(scope);

  const accountConfigMap = useAccountConfigMap();

  const { traitsMap, loadingTraits } = useTraitsQuery({
    skip: scope.payload.attributes.length === 0,
  });

  const [targetStep, setTargetStep] = useState<TargetModalStep>(
    TargetModalStep.Representation
  );

  const [representation, setRepresentation] =
    useState<TargetRepresentationType>(() => {
      if (initialState?.representation) return initialState.representation;

      // Lookup targets have limited representation modes. Start with a usable one.
      if (isConnectionType(connectionTypeInfo, "lookup_api")) {
        if (accountConfigMap["targets.identified_allowed"]) {
          return {
            __typename: "TargetModesIdentified",
            mode: "identified",
            identifiedAggregate: TargetAggregateIdentified.PERSON,
            identifiedPreset: TargetTransformPresetIdentified.DEFAULT,
          };
        }

        return {
          __typename: "TargetModesAggregated",
          mode: "aggregated",
          geographicAggregate: TargetAggregateGeographic.COUNTY,
          aggregatedPreset: TargetTransformPresetAggregated.DEFAULT,
          includeGeometry: false,
        };
      }

      return {
        __typename: "TargetModesHashed",
        hashedPreset: TargetTransformPresetHashed.DEFAULT,
        mode: "hashed",
      };
    });

  const [humanReadable, setHumanReadable] = useState<boolean>(
    initialState?.humanReadable ?? false
  );

  if (scopeDeps.loading || loadingTraits) return <AnimatedZapLogo />;

  // step 1: representation
  if (targetStep === TargetModalStep.Representation) {
    return (
      <TargetFormRepresentation
        accountConfigMap={accountConfigMap}
        connectionTypeInfo={connectionTypeInfo}
        scopeDatasets={scopeDeps.scopeDatasets}
        humanReadable={humanReadable}
        representation={representation}
        setHumanReadable={setHumanReadable}
        setRepresentation={setRepresentation}
        onCancel={onCancel}
        onNext={() => setTargetStep(TargetModalStep.Advanced)}
        // If we have initial state, we're editing, so lock the representation
        // type. Though, careful, if we add default initial state, this could break.
        lockedRepresentationTypename={initialState?.representation?.__typename}
      />
    );
  }

  // step 2: advanced options
  if (targetStep === TargetModalStep.Advanced) {
    return (
      <TargetFormAdvanced
        scopeDependencies={scopeDeps}
        initialState={initialState}
        scope={scope}
        saving={saving}
        onSave={onSave}
        onBack={() => setTargetStep(TargetModalStep.Representation)}
        connectionTypeInfo={connectionTypeInfo}
        representation={representation}
        humanReadable={humanReadable}
        traitsMap={traitsMap}
      />
    );
  }

  throw new Error("Invalid target step");
}
