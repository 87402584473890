import { FormControl, Link, Text } from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { Button } from "../../ui/Button";
import { InlineButtons } from "../../ui/InlineButtons";
import { ModalV2 } from "../../ui/ModalV2";
import { RouterLink } from "../../ui/RouterLink";
import {
  DatasetsTableQuery_connections as Connection,
  DatasetsTableQuery_datasets as Dataset,
} from "../__generated__/DatasetsTableQuery";
import { SelectConnection } from "./SelectConnection";

/**
 * Renders a form to choose which connection you want your dataset to use
 */
export function DatasetsCreateModalConnection({
  isOpen,
  onNext,
  onClose,
  connections,
  connectionId,
  setConnectionId,
}: {
  isOpen: boolean;
  onNext: () => void;
  onClose: () => void;
  datasets: Dataset[] | undefined;
  connections: Connection[] | undefined;
  connectionId: string;
  setConnectionId: (val: string) => void;
}) {
  if (!connections) return null;

  return (
    <ModalV2
      isOpen={isOpen}
      onClose={onClose}
      title="New dataset"
      footer={
        <InlineButtons>
          <Button variant="tertiary" onClick={onClose} analyticsName="cancel">
            Cancel
          </Button>
          <Button variant="primary" onClick={onNext} analyticsName="next">
            Next
          </Button>
        </InlineButtons>
      }
      analyticsStackName="dataset-connection-modal"
    >
      <Text>
        Choose a connection that will be the data source for this dataset. If
        you don't see a connection you would like to use, it can be added in{" "}
        <Link as={RouterLink} routeName={ROUTE_NAMES.CONNECTIONS}>
          Connections
        </Link>
        .
      </Text>
      <FormControl>
        <SelectConnection
          selected={connectionId}
          setSelected={(selected) => {
            setConnectionId(selected);
          }}
          connections={connections}
        />
      </FormControl>
    </ModalV2>
  );
}
