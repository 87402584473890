// Helpful assertion functions.
//
// These are particularly useful for showing TypeScript that a variable can only
// contain certain values.

/**
 * If `condition` is not true, then throw an error with `msg`.
 *
 * Inspired by the [TypeScript docs][docs]. This has return type `asserts
 * condition`, which is the same as `undefined`, except that it also tells
 * TypeScript that it's safe to assume the condition is true.
 *
 * @param condition Testing using `!condition`.
 * @param msg The message to throw.
 *
 * [docs]:
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html
 */
export function assert<T>(condition: T, msg: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function assertNonNullable<T>(
  v: T,
  message?: string
): asserts v is NonNullable<T> {
  if (v === null) throw new Error(message || "unexpected null");
  if (typeof v === "undefined")
    throw new Error(message || "unexpected undefined");
}

/**
 * Assert that all the values in an array are non-null.
 * Useful for asserting that a GraphQL response is valid, and that there are no
 * nullable items in the array (which the spec generator introduces though it's unlikely).
 *
 * Note: This is not a map or a filter, it's a loop. We will throw if _any_ of the
 * elements in the array are null or undefined. It's all or nothing.
 */
export function assertArrayAndUnwrap<T>(
  v: (T | null | undefined)[],
  message?: string
): T[] {
  for (const i of v) assertNonNullable(i, message);

  return v as T[];
}
