import { useFormControlContext } from "@chakra-ui/react";
import Select from "react-select";

import {
  analyticsAttrs,
  useAnalyticsKey,
} from "../../../../ui/Analytics/AnalyticsStack";
import {
  DatasetSelectOption,
  reactSelectStyle,
} from "../../../shared/reactSelectStyle";
import {
  formatOptionLabel,
  hintFromSampleData,
  SampleData,
} from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";

export function DatasetColumnSelect({
  label,
  value,
  onChange,
  detectedColumns,
  sampleData,
  disabled,
  analyticsName,
  required,
}: {
  label?: string;
  value: string | null;
  onChange: (value: string | null) => void;
  detectedColumns: DetectedColumn[];
  sampleData: SampleData | undefined;
  disabled?: boolean;
  analyticsName: string;
  required?: boolean;
}) {
  const analyticsKey = useAnalyticsKey(analyticsName);

  const controlContext = useFormControlContext();

  const columnOptions: DatasetSelectOption[] = detectedColumns.map((column) => {
    return {
      value: column.name,
      label: column.name,
      hint: hintFromSampleData({ sampleData, column }),
      fillRate: column.fillRate,
    };
  });

  function handleChangeColumn(
    newValue: { value: string; label: string } | null
  ): void {
    onChange(newValue?.value ?? null);
  }

  const selectedOption = columnOptions.find((opt) => opt.value === value);
  return (
    <Select<DatasetSelectOption>
      aria-label={label}
      placeholder="Select a column..."
      isClearable
      options={columnOptions}
      value={selectedOption}
      onChange={handleChangeColumn}
      isDisabled={disabled}
      styles={reactSelectStyle}
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
      required={required}
      inputId={controlContext?.id}
      {...analyticsAttrs(analyticsKey)}
    />
  );
}
