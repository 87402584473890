// The `AuthService` base class

import {
  decodeJwtPayloadUntrusted,
  JwtPayload,
  unsafeUnwrapUntrusted,
} from "@fdy/jwt";
import Rollbar from "rollbar";

import { ApolloGQLService } from "../ApolloGQLService";
import { EventTarget } from "../EventTarget";

/** Callback type for callbacks invoked after login. */
export type OnLogIn = (route?: string) => void;

/** Options for creating an `AuthService`. */
export type AuthServiceOptions = {
  /** The browser console, or a compatible implementation. */
  log?: Console | Rollbar;

  /** Called when the user is logged in. */
  onLogIn?: OnLogIn;
};

class LoginEvent extends Event {
  route?: string;
  constructor(route?: string) {
    super("login");
    this.route = route;
  }
}

/**
 * An `AuthService` manages JWT tokens for the user, allowing them to
 * authenticate to the server.
 */
export abstract class AuthService extends EventTarget<LoginEvent> {
  /** Logger for auth-related information. */
  protected log: Console | Rollbar;

  constructor({ log, onLogIn }: AuthServiceOptions) {
    super();
    this.log = log || console;

    this.addEventListener("login", (event) => {
      if (onLogIn) onLogIn(event.route);
    });
  }

  onLogIn(route?: string): void {
    this.dispatchEvent(new LoginEvent(route));
  }

  /**
   * If `routeName` is unauthorized, where should we redirect it?
   *
   * Here, "route name" refers to a value in the `ROUTE_NAMES` hash.
   *
   * @param _gqlClient Our GraphQL client, in case we want to ask the server.
   * @param _routeName The route the user tried to navigate to.
   * @returns The route name to redirect the user to, or `null` to let
   *   them go where they wanted.
   */
  async redirectionIfRouteIsUnauthorized(
    _gqlClient: ApolloGQLService,
    _routeName: string
  ): Promise<string | null> {
    return null;
  }

  /**
   * Get the token.
   *
   * Returns a token that can be passed to the server. This may block forever if
   * no token is available.
   */
  abstract getToken(): Promise<string>;

  /**
   * Log out. This should not return.
   */
  abstract logout(): Promise<never>;
}

/**
 * A promise which never returns.
 *
 * This is useful with `getToken()`.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NEVER_RESOLVE = new Promise<never>((_resolve, _reject) => {});

/**
 * Parse a JSON Web Token (JWT) into a `JwtPayload`.
 *
 * @param token The token to parse.
 */
export function decodeJwtPayload(token: string): JwtPayload {
  // It's OK to use `unsafeUnwrapUntrusted` on JWTs in Popsicle because we don't
  // even try to authenticate JWTs in the client. We leave all authentication up
  // to the server, which controls all the important resources in any case.
  return unsafeUnwrapUntrusted(decodeJwtPayloadUntrusted(token));
}

/**
 * Has this token expired?
 *
 * @param token An encoded token in JWT format.
 * @param safetyMarginSeconds If this token will expire within this many
 * seconds, treat it as expired. This allows us to refresh a token that might
 * expire before it reaches the server, or a slightly inaccurate client clock.
 * Defaults to a reasonable value > 0.
 */
export function isTokenExpired(
  token: string,
  safetyMarginSeconds = 600
): boolean {
  try {
    const decoded = decodeJwtPayload(token);
    if ("exp" in decoded && typeof decoded.exp === "number") {
      return new Date((decoded.exp - safetyMarginSeconds) * 1000) < new Date();
    } else {
      console.error("decoded JWT does not contain valid exp field");
      return true;
    }
  } catch (e) {
    // If we can't do this for whatever reason, assume the token is expired.
    console.error(`cannot decode JWT: ${e}`);
    return true;
  }
}
