import { Margin } from "@visx/xychart";
import { scaleLinear } from "d3";
import { useMemo } from "react";

import { ChartDatum, Persona } from "./personaFlowChartUtils";

/**
 * Renders each persona avatar vertically, centering each avatar in its ending
 * (most recent date) 'area' on the chart.
 */
export function PersonaFlowAxisRight({
  flow,
  personas,
  width,
  height,
  margin,
}: {
  flow: ChartDatum[];
  personas: Persona[];
  width: number;
  height: number;
  margin: Margin;
}) {
  // set the size (width and height) of the avatar images
  const imgSize = 48;

  // position the Y axis at the right edge the chart minus space for the avatars
  const groupTransform = `translate(${width - margin.right - imgSize / 2}, 0)`;

  // get the total of all the latest days for each persona
  // so we know how 'tall' the chart will be in total
  const mostRecentDay = flow[flow.length - 1];
  const totalOfLastDays = useMemo(() => {
    const { date, ...counts } = mostRecentDay;
    return Object.values(counts).reduce((acc, curr) => acc + curr, 0);
  }, [flow]);

  // create a d3 scale so we can map the count to a y position on the chart
  const maxY = height - margin.top - margin.bottom;
  const yScale = scaleLinear().domain([0, totalOfLastDays]).range([maxY, 0]); // reverse the range so the top of the chart is 0

  // store the previous count so we can calculate the next y position
  let prevCount = 0;

  return (
    <g transform={groupTransform}>
      {personas.map((persona) => {
        // get the last day's count since it's on the right of the chart.
        const count = mostRecentDay[persona.id];

        // get the count between the previous count (plus all other previous
        // counts since the areas are stacked) and the current count.
        const middleCount = count / 2 + prevCount;

        // turn the count into a px value based on the chart height
        const y = yScale(middleCount);

        // center the avatar vertically in the area
        const yCenterOnImg = y - imgSize / 2 + margin.top;

        // update the previous count so the next position can be calculated based on the stacked areas
        prevCount += count;

        // use foreign object because we can't apply border styles/etc to svg `image` element
        return (
          <foreignObject
            key={persona.id}
            x={0}
            y={yCenterOnImg}
            width={imgSize}
            height={imgSize}
            data-total={count}
          >
            <img
              // don't crash the page if the avatar is missing, just rendering empty image
              src={persona.avatar ?? ""}
              width={imgSize}
              height={imgSize}
              style={{
                borderRadius: 1000,
                border: "2px solid #fff",
              }}
            />
          </foreignObject>
        );
      })}
    </g>
  );
}
