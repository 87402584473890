import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { number } from "../../../utils/formatters";
import { PersonaAvatar } from "../analysis/PersonaAvatar";
import {
  ChartDatum,
  formatDate,
  formatPercentIncrease,
  Persona,
} from "./personaFlowChartUtils";

/**
 * Renders the tooltip content for the persona flow chart.
 * Used in PersonaFlowTooltip.
 */
export function PersonaFlowTooltipContent({
  persona,
  cursor,
  first,
  last,
}: {
  persona: Persona;
  cursor: ChartDatum;
  first: ChartDatum;
  last: ChartDatum;
}) {
  const id = persona.id;
  const firstCount = first[id];
  const cursorCount = cursor[id];
  const lastCount = last[id];

  const changeSinceFirstToCursor = formatPercentIncrease(
    firstCount,
    cursorCount
  );
  const changeSinceFirstToLast = formatPercentIncrease(firstCount, lastCount);

  return (
    <Box
      sx={{
        bg: "fdy_gray.200",
        p: 4,
        borderRadius: "6px",
      }}
    >
      <Box sx={{ display: "flex", gap: 4, alignItems: "center", mb: 4 }}>
        <PersonaAvatar persona={persona} />
        <Heading fontSize="fdy_md">{persona.name}</Heading>
      </Box>
      <Table>
        <Thead>
          <Tr>
            <Th>Metric</Th>
            <Th scope="col" fontWeight="normal">
              <strong>Cursor</strong>
              <br />
              <span>{formatDate(cursor.date)}</span>
            </Th>
            <Th scope="col" fontWeight="normal">
              <strong>Latest</strong>
              <br />
              <span>{formatDate(last.date)}</span>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Th scope="row">Persona members</Th>
            <Td>{number(cursorCount)}</Td>
            <Td>{number(lastCount)}</Td>
          </Tr>
          <Tr>
            <Th scope="row">Change since {formatDate(first.date)}</Th>
            <Td>{changeSinceFirstToCursor}</Td>
            <Td>{changeSinceFirstToLast}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
