import { Box, Heading, Link } from "@chakra-ui/react";
import { format } from "date-fns";
import { ReactNode } from "react";

import { ResourceStatus } from "../../../../__generated__/sojournerGlobalTypes";
import { TraitsMap } from "../../../../hooks/useTraitsQuery";
import { Connection } from "../../../connections/useConnectionsQuery";
import { ChatLink } from "../../../ui/ChatLink";
import { Notice } from "../../../ui/Notice";
import { Pill } from "../../../ui/Pill";
import { useId } from "../../../ui/useId";
import { PipelineFragment } from "../../__generated__/PipelineFragment";
import { getConnectionTypeInfoFromTargetOptions } from "../../connectionUtils";
import { TargetFragment as Target } from "../__generated__/TargetFragment";
import { isScopeRefreshing } from "../PipelineDeploymentCard";
import { ScopeDependencies } from "../useScopeDependencies";
import { TargetActions, TargetActionsMenu } from "./TargetActionsMenu";

export function findConnectionNameByTarget(
  connections: Connection[],
  target: Target
) {
  // hosted targets have null connection ids because they are implicit/hidden on the api
  // so get the name right from the connection_types.json
  if (!target.connectionId) {
    const info = getConnectionTypeInfoFromTargetOptions(target.options);
    return info.literate;
  }

  // otherwise if we have an id, find it by the existing connections
  const conn = connections.find((c) => c.id === target.connectionId);

  // no connection somehow, this shouldn't happen
  if (!conn) {
    throw new Error(
      "No connection found for target: " + JSON.stringify(target)
    );
  }

  return conn.name;
}

export function PreviewPill() {
  return (
    <>
      <Pill
        sx={{
          color: "white",
          backgroundColor: "fdy_orange.500",
          textTransform: "uppercase",
          fontWeight: "semibold",
          fontSize: "sm",
          marginRight: 2,
          marginBottom: 4,
        }}
      >
        Preview
      </Pill>
      Manual deployment only, limited up to 1000 rows.{" "}
      <Link
        href="https://faraday.ai/docs/abstractions/pipelines#preview-mode"
        isExternal
      >
        Docs
      </Link>
    </>
  );
}

export interface TargetCardBaseProps {
  connections: Connection[];
  scope: PipelineFragment;
  scopeDeps: ScopeDependencies;
  onEditClick: (target: Target) => void;
  traitsMap: TraitsMap;
  target: Target;
  children: ReactNode;
  actions?: TargetActions;
  notice?: ReactNode;
}

/**
 * Renders a single Target (we call them deployments to users) with an optional menu to edit or delete it.
 * Intended to be used as a base to render either a hosted csv, managed target, or generic target.
 *
 * If the scope is in preview mode, render a button to request a send of the preview data.
 * Otherwise render a timestamp of the last time the target was deployed to.
 */
export function TargetCardBase({
  target,
  scope,
  onEditClick,
  actions = {
    rename: true,
    delete: true,
    edit: true,
    archive: true,
    api: true,
  },
  notice,
  children,
}: TargetCardBaseProps) {
  const ariaId = useId();
  const hasError = target.status === ResourceStatus.ERROR;

  const isStale =
    target.status === ResourceStatus.RUNNING ||
    target.status === ResourceStatus.STARTING ||
    // scope output is more recent than the target
    new Date(scope.lastUpdatedOutputAt ?? 0) >
      new Date(target.lastUpdatedOutputAt ?? 0) ||
    // scope is refreshing
    isScopeRefreshing(scope) ||
    new Date(scope.lastReadInputAt ?? 0) >
      new Date(target.lastReadInputAt ?? 0);

  return (
    <Box
      as="article"
      sx={{
        p: 5,
        border: hasError ? "2px solid" : "1px solid",
        borderColor: hasError ? "error" : "fdy_gray.400",
        borderRadius: 6,
      }}
      aria-labelledby={ariaId}
      data-target-id={target.id}
    >
      <Box sx={{ display: "flex", mb: 4, gap: 2 }}>
        {target.archivedAt !== null && (
          <Pill
            sx={{
              color: "fdy_gray.700",
              backgroundColor: "fdy_gray.300",
              textTransform: "uppercase",
              fontWeight: "semibold",
              alignSelf: "center",
            }}
          >
            Archived
          </Pill>
        )}

        <Heading as="h3" variant="h5" fontWeight="normal" id={ariaId}>
          <strong>{target.name}</strong>
        </Heading>

        <Box ml="auto">
          <TargetActionsMenu
            target={target}
            onEditClick={() => onEditClick(target)}
            actions={actions}
          />
        </Box>
      </Box>

      <Notice
        variant={isStale ? "warning" : "success"}
        title="Output status: "
        description={
          <>
            {isStale
              ? `Pipeline and/or deployment configuration or data changed. Deployment will rebuild soon.`
              : `Deployment is in sync with pipeline configuration and the data is current.`}{" "}
            {target.status === ResourceStatus.READY &&
            target.statusChangedAt !== null ? (
              <span>
                Last built at:{" "}
                <time dateTime={target.statusChangedAt}>
                  {format(target.statusChangedAt, "MMMM d, yyyy h:mma")}
                </time>
              </span>
            ) : null}
          </>
        }
        dismissable={false}
        sx={{ mb: 6 }}
      />

      {notice}

      {hasError && (
        <Notice
          variant="error"
          title="Error!"
          description={
            <span>
              A problem has occured with this deployment. Please check its
              configuration or the connection. If the problem persists, please{" "}
              <ChatLink>contact support</ChatLink>. Error:{" "}
              {target.statusError ?? "unknown error"}
            </span>
          }
          sx={{ my: 4 }}
        />
      )}

      <Box mt={6}>{children}</Box>
    </Box>
  );
}
