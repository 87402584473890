import CreatableSelect from "react-select/creatable";

import { ApiOptions } from "../../../services/connectionOptions";
import {
  findConnectionTypeInfoBySlug,
  getOptionsForConnectionType,
} from "../../pipelines/connectionUtils";
import { FormField } from "../../ui/FormField";
import { InputType, TextField } from "../../ui/TextField";
import { DatasetsTableQuery_connections as Connection } from "../__generated__/DatasetsTableQuery";
import { reactSelectStyle } from "./reactSelectStyle";

interface ConnectionTypeFormProps {
  connection: Connection | undefined;
  options: ApiOptions;
  setOptions: (options: ApiOptions) => void;
  setName?: (name: string) => void;
  /**
   * There are times when we might not want to display existing contents of the
   * connection, for example when we are creating a new dataset from a connection.
   * Contents only include tables from the connection that we have already used for
   * a dataset. This means that any table they select can only cause an error.
   */
  displayContents: boolean;
}

export function ConnectionTypeForm({
  connection,
  options,
  setName,
  setOptions,
  displayContents = true,
}: ConnectionTypeFormProps) {
  const connectionType = findConnectionTypeInfoBySlug(
    connection ? connection.options.type : "hosted_csv"
  );
  const connectionFields = getOptionsForConnectionType({
    connectionTypeId: connectionType.id,
    resourceType: "datasets",
  });

  const connectionOptions =
    (displayContents
      ? connection?.contents.map((contents) => {
          return { value: contents.name, label: contents.name };
        })
      : undefined) ?? [];

  const handleTextFieldChange =
    (field: (typeof connectionFields)[number]) =>
    (value: string): void => {
      const temp = options;
      if (field.data_type === "integer") {
        temp[field.slug] = parseInt(value);
      } else if (field.data_type === "boolean") {
        switch (value) {
          case "true":
            temp[field.slug] = true;
            break;
          case "false":
            temp[field.slug] = false;
            break;
          default:
            temp[field.slug] = "";
            break;
        }
      } else {
        if (value === "") {
          // tell api to delete the previous value, if there was one
          // (del temp[field.slug] would keep the previous value instead of deleting
          temp[field.slug] = null;
        } else temp[field.slug] = value;
      }
      setOptions(temp);
    };

  return (
    <>
      {connectionFields.map((field) => {
        // Disable some fields in the UI that don't make sense to be editable.
        const disabled =
          connectionType.slug === "hosted_csv" &&
          field.slug === "upload_directory";

        if (field.slug === "merge" || field.slug === "migrate") return; // this is editable in the "data" page instead
        if (field.slug === "table_name" || field.slug === "prefix") {
          return (
            <FormField
              key={field.id}
              label={field.literate}
              helpText={field.help}
              dataType={field.data_type}
              htmlFor={field.id}
            >
              <CreatableSelect<(typeof connectionOptions)[number]>
                inputId={field.id}
                onChange={(newValue) => {
                  const temp = options;
                  temp[field.slug] = newValue?.value;
                  setOptions(temp);
                  setName?.(String(temp[field.slug] ?? "")); //default dataset name to table name
                }}
                placeholder={`Select or type in a ${field.literate.toLowerCase()}...`}
                name={field.slug}
                required
                options={connectionOptions}
                styles={reactSelectStyle}
                menuPortalTarget={document.body} // select options should appear outside modal
                defaultInputValue={
                  connectionOptions.find(
                    (conn) => conn.value === options[field.slug]
                  )?.value
                }
              />
            </FormField>
          );
        }

        return (
          <TextField
            key={field.id}
            label={field.literate}
            hint={field.help}
            required={field.required}
            pattern={field.validation_regex}
            name={field.slug}
            dataType={field.data_type}
            type={
              field.secret
                ? InputType.password
                : InputType[field.data_type as keyof typeof InputType]
            }
            title={`Please match the following format ${field.validation_regex}`}
            value={String(options[field.slug] ?? "")}
            onChange={handleTextFieldChange(field)}
            disabled={disabled}
          />
        );
      })}
    </>
  );
}
