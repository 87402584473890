import { Flex } from "@chakra-ui/react";

import { findConnectionTypeInfoBySlug } from "../../pipelines/connectionUtils";
import { Pill } from "../../ui/Pill";
import { RadioTiles } from "../../ui/RadioTiles";
import { VendorLogo } from "../../ui/VendorLogo";
import { DatasetsTableQuery_connections as Connection } from "../__generated__/DatasetsTableQuery";

export const DefaultConnectionTypes = Object.freeze({
  hostedCSV: "-1",
  merge: "-2",
});

export const hostedCSV = {
  __typename:
    "DatasetsTableQuery_connections_options_ConnectionOptionsHostedCsv",
  id: DefaultConnectionTypes.hostedCSV,
  name: "CSV",
  options: { type: "hosted_csv" },
} as unknown as Connection;

export const merged = {
  __typename: "DatasetsTableQuery_connections_options_ConnectionOptionsMerge",
  id: DefaultConnectionTypes.merge,
  name: "Merge",
  options: { type: "merge" },
} as unknown as Connection;

export function SelectConnection({
  selected,
  setSelected,
  connections,
}: {
  selected: string;
  setSelected: (connectionId: string) => void;
  connections: Connection[];
}) {
  const allConnections = connections
    .filter(
      (conn) =>
        // Filtering out hosted csv connections because
        // 1. the api treats them differently (datasets will find or create connection for you)
        // 2. many accounts have multiple hosted_csv connections when they should have only one
        // we need to fix 2, but in the meantime, this workaround is less messy, and is known to handle 1 correctly
        conn.options.type !== "classic" &&
        conn.options.type !== "hosted_csv" &&
        conn.options.type !== "merge"
    )
    .concat([hostedCSV, merged]);
  const radioOptions = allConnections.map((conn) => {
    const connectionType = findConnectionTypeInfoBySlug(conn.options.type);
    return {
      value: conn.id,
      label: conn.name,
      rightContent: (
        <Flex gap={2}>
          {conn.options.type === "merge" && (
            <Pill
              sx={{
                color: "fdy_purple.500",
                backgroundColor: "fdy_purple.100",
                textTransform: "uppercase",
                fontWeight: "semibold",
                fontSize: "sm",
              }}
            >
              Advanced
            </Pill>
          )}
          <VendorLogo path={connectionType.logo_url} dimension={24} />
        </Flex>
      ),
      description:
        connectionType.new_connection_description ??
        "Share access with Faraday.",
      analyticsName: conn.options.type,
    };
  });
  return (
    <RadioTiles
      name="connection_id"
      options={radioOptions}
      onChange={(value) => {
        setSelected(value);
      }}
      selected={selected}
      analyticsName="connection-type"
    />
  );
}
