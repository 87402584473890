import { ReactElement } from "react";

import { Prose } from "../../ui/Prose";

export function DatasetInfoSidebar(): ReactElement {
  return (
    <Prose>
      <p>
        Faraday runs on streams of transaction data and streams of other event
        data provided by clients. Datasets are how we ingress this data.
      </p>

      <p>
        Faraday makes predictions using a combination of{" "}
        <strong>customer data</strong> you provide and{" "}
        <strong>consumer data</strong> which we provide. Your data is necessary
        because it tells us how to recognize desirable outcomes like purchasing,
        and important groups of people like your customers.
      </p>

      <p>
        Because your data is about <em>people</em>, Faraday needs to understand
        how to recognize these people. We call these <em>identity sets</em>.
        Typically, you will have columns in your data with information like
        names, addresses, emails, and phone numbers. This is your opportunity to
        tell Faraday that, for example, your <code>fn</code> column is a "First
        name".
      </p>

      <p>
        Faraday needs to understand what behaviors are being exhibited in your
        data. We call these <em>events</em>. For example, if you've uploaded
        order data, each row represents an <em>order event</em>. And each of
        these events was experience by an individual person.
      </p>

      <p>
        Finally, Faraday may need to know about certain relevant <em>traits</em>{" "}
        exhibited by these people. While not always necessary, you can provide
        information about somebody's preferences or their status, as traits, and
        other Faraday systems will opportunistically use this data.
      </p>
    </Prose>
  );
}
