import {
  CohortStreamConditionInput,
  CohortTraitInput,
} from "../../../../__generated__/sojournerGlobalTypes";
import {
  ConditionRow,
  ConditionsBuilder,
  NUMBER_OPERATORS,
  OperatorsOption,
} from "../../../ui/ConditionsBuilder";
import { CohortFilterEditorProps, FilterProperties } from ".";

function getDefaultNumericOperatorValue(
  filterProperties: FilterProperties,
  opt: OperatorsOption
) {
  if (!filterProperties.breaks) return 0;

  switch (opt.operator) {
    case "gt":
    case "gte":
      return filterProperties.breaks[0] ?? 0;
    case "lt":
    case "lte":
      return filterProperties.breaks[filterProperties.breaks.length - 1] ?? 0;
  }
}

export function CohortFilterEditorNumber<
  CohortConditionInput extends CohortTraitInput | CohortStreamConditionInput
>({
  filterProperties,
  condition,
  onChange,
  conditionsToWire,
  conditionsFromWire,
}: CohortFilterEditorProps<CohortConditionInput>) {
  function handleConditionBuilderChange(conditions: ConditionRow[]) {
    onChange(conditionsToWire(condition, conditions));
  }

  const init = conditionsFromWire(condition);

  if (init.length === 0) {
    init.push({
      operator: "nnull",
      type: "boolean",
      value: true,
    });
  }

  // Add default values for operators, so when user adds 'gt' operator,
  // they get the top most value set automatically so they have an idea of the max.
  const operators = NUMBER_OPERATORS.map((opt) => {
    return {
      ...opt,
      defaultValue: getDefaultNumericOperatorValue(filterProperties, opt),
    };
  });

  return (
    <ConditionsBuilder
      unit={filterProperties.unit ?? undefined}
      initialConditions={init}
      operators={operators}
      onChange={handleConditionBuilderChange}
    />
  );
}
