import { gql } from "@apollo/client";

import { useToast } from "../../../../hooks/useToast";
import { useSojournerMutation } from "../../../../services/sojournerApolloClient";
import { Button } from "../../../ui/Button";
import { TargetFragment as Target } from "../__generated__/TargetFragment";

export const CREATE_TARGET_PREVIEW_MUTATION = gql`
  mutation CreateTargetPreviewMutation($targetId: ID!) {
    createTargetPreview(targetId: $targetId) {
      message
    }
  }
`;

export function TargetPreviewButton({ target }: { target: Target }) {
  const toast = useToast();

  // wire up test deployment call
  const [testDeploy, { loading }] = useSojournerMutation(
    CREATE_TARGET_PREVIEW_MUTATION,
    {
      onCompleted() {
        toast({
          status: "success",
          title: "Sending test data",
          description:
            "If your pipeline is finished building, test data should appear in your deployment shortly.",
        });
      },
    }
  );

  function handleDeployClick() {
    testDeploy({
      variables: {
        targetId: target.id,
      },
    });
  }

  return (
    <Button
      variant="tertiary"
      onClick={handleDeployClick}
      isLoading={loading}
      loadingText="Requesting test..."
      width="100%"
      analyticsName="test-deployment"
    >
      Test deployment
    </Button>
  );
}
