import { gql } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import { MinusCircle } from "@phosphor-icons/react";

import { useSojournerQuery } from "../../services/sojournerApolloClient";
import { formatPendingCount } from "../../utils/formatPendingCount";
import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../ui/ConfigBreakdownTable";
import { ResourceIcon } from "../ui/ResourceIcon";
import { PipelinesTableQuery_scopes as Scope } from "./__generated__/PipelinesTableQuery";
import {
  ScopePopulationQuery,
  ScopePopulationQuery_scope_populationCohorts,
  ScopePopulationQuery_scope_populationExclusionCohorts,
} from "./__generated__/ScopePopulationQuery";
import { ResourceLinks } from "./ResourceLinks";

export const SCOPE_POPULATION_QUERY = gql`
  query ScopePopulationQuery($id: ID!) {
    scope(scopeId: $id) {
      id
      name
      populationCohorts {
        id
        name
        populationCount
      }
      populationExclusionCohorts {
        id
        name
        populationCount
      }
    }
  }
`;

type PopulationCohort =
  | ScopePopulationQuery_scope_populationCohorts
  | ScopePopulationQuery_scope_populationExclusionCohorts;

const renderResourceLinkName = (resource: PopulationCohort): string =>
  ` (${formatPendingCount(resource.populationCount)})`;

export function ScopePopulationBreakdown({
  scope,
  caption,
}: {
  scope: Scope;
  caption?: string;
}) {
  const { data, loading, error } = useSojournerQuery<ScopePopulationQuery>(
    SCOPE_POPULATION_QUERY,
    {
      variables: { id: scope.id },
    }
  );
  if (error) throw error;
  if (loading) return <Spinner />;
  if (!data?.scope) throw new Error("BUG: No scope data");

  const { populationCohorts, populationExclusionCohorts } = data.scope;

  return (
    <ConfigBreakdownTable caption={caption}>
      <ConfigBreakdownTableRow
        icon={ResourceIcon.cohorts}
        header="Included"
        value={
          populationCohorts.length === 0 ? (
            "Everyone"
          ) : (
            <ResourceLinks<PopulationCohort>
              resources={populationCohorts}
              renderAfterLink={renderResourceLinkName}
            />
          )
        }
      />
      {populationExclusionCohorts.length > 0 && (
        <ConfigBreakdownTableRow
          icon={MinusCircle}
          header="Excluded"
          value={
            <ResourceLinks
              resources={populationExclusionCohorts}
              renderAfterLink={renderResourceLinkName}
            />
          }
        />
      )}
    </ConfigBreakdownTable>
  );
}
