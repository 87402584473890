import { ReactElement } from "react";

import { HistogramBoxplot } from "./HistogramBoxplot";
import { PercentChart } from "./PercentChart";
import { StackedPercentChart } from "./StackedPercentChart";
import { AnalysisDataset, Bounds, Field } from "./types";

interface AnalysisChartProps {
  bounds: Bounds;
  color: string;
  data: AnalysisDataset;
  field?: Field;
}

export function AnalysisChart({
  field,
  data,
  color,
  bounds,
}: AnalysisChartProps): ReactElement | null {
  if (!field || !bounds) return null;

  const type = field.filter_type;

  const chartProps = {
    bounds,
    color,
    data,
    field,
  };

  if (type === "boolean") {
    return <PercentChart {...chartProps} />;
  }

  if (type === "range") {
    return <HistogramBoxplot {...chartProps} />;
  }

  if (type === "categorical") {
    return <StackedPercentChart {...chartProps} />;
  }

  return null;
}
