import { Text, Tooltip } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export function PendingValue<T extends string | Date | number>({
  value,
  formatter,
  placeholder = "Pending...",
  tooltip = "Value will populate on next successful build",
}: {
  value: T | null | undefined;
  formatter?: (val: T) => ReactNode;
  placeholder?: string;
  tooltip?: string;
}) {
  if (value === null || value === undefined)
    return (
      <Tooltip label={tooltip}>
        <Text as="span" color="fdy_gray.600">
          {placeholder}
        </Text>
      </Tooltip>
    );

  if (formatter) return <>{formatter(value)}</>;

  return <>{value}</>;
}
