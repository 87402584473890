import "reactflow/dist/base.css";

import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FrameCorners } from "@phosphor-icons/react";

import { Blankslate } from "../Blankslate";
import { CardV2 } from "../Card/CardV2";
import { ChatLink } from "../ChatLink";
import { ModalV2 } from "../ModalV2";
import { ResourceGraphChart } from "../ResourceGraphChart";
import { useGraph } from "./graph-queries";

const title = "Dependencies";

interface ResourceGraphCardProps {
  resourceId: string;
}

/**
 * Fetches and displays a graph of dependencies for a given resource.
 * Optionally allows the user to view the graph in full screen modal.
 */
function ResourceGraphCardChart({ resourceId }: ResourceGraphCardProps) {
  const { data } = useGraph();
  const modalState = useDisclosure();

  if (!data?.length) {
    return <Blankslate filled title="No dependencies found" />;
  }

  return (
    <>
      <ResourceGraphChart
        graphEdges={data}
        resourceId={resourceId}
        zoomOnScroll={false}
      />
      <Flex justifyContent="space-between" py={2}>
        <Text sx={{ color: "fdy_gray.600" }}>
          Drag to pan the graph. Need help resolving dependency issues?{" "}
          <ChatLink>Chat with us</ChatLink>
        </Text>
        <Button
          leftIcon={<FrameCorners />}
          size="sm"
          variant="icon"
          onClick={modalState.onOpen}
        >
          Full screen
        </Button>
      </Flex>

      {modalState.isOpen && (
        <ModalV2
          title={title}
          onClose={modalState.onClose}
          isOpen
          fullScreen
          analyticsStackName="resource-graph-modal"
        >
          <ResourceGraphChart
            graphEdges={data}
            resourceId={resourceId}
            height="100%"
            fitAllNodesInView
          />
        </ModalV2>
      )}
    </>
  );
}

export function ResourceGraphCard({ resourceId }: ResourceGraphCardProps) {
  return (
    <CardV2 title={title}>
      {/* this is nested so the Card's suspense boundary is used for loading state */}
      <ResourceGraphCardChart resourceId={resourceId} />
    </CardV2>
  );
}
