import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { assertArrayAndUnwrap } from "../../../../utils/assertions";
import { RocChart } from "../../../ui/RocChart";
import { TabularDataTable } from "../../../ui/TabularDataTable";
import { OutcomeAnalysisPerformanceModel } from "../__generated__/OutcomeAnalysisPerformanceModel";
import { OutcomeLiftCurveChart } from "./OutcomeLiftCurveChart";
import { OutcomeLiftRocGridChart } from "./OutcomeLiftRocGridChart";
import { OutcomePerformanceText } from "./OutcomePerformanceText";
import { OutcomeRocBarChart } from "./OutcomeRocBarChart";

export function OutcomePerformanceCharts({
  performance,
  usesFPD,
}: {
  performance: OutcomeAnalysisPerformanceModel;
  usesFPD: boolean;
}) {
  return (
    <>
      <OutcomePerformanceText
        rocAuc={performance.rocAuc}
        liftValue={performance.liftValue}
        usesFPD={usesFPD}
      />

      <Tabs size="sm">
        <TabList>
          <Tab>Performance</Tab>
          <Tab>Metrics</Tab>
          <Tab>Lift curve</Tab>
          <Tab>Lift table</Tab>
          <Tab>ROC</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {performance.liftValue !== null && performance.rocAuc !== null ? (
              <OutcomeLiftRocGridChart
                liftValue={performance.liftValue}
                rocAuc={performance.rocAuc}
                usesFPD={usesFPD}
              />
            ) : (
              <OutcomeRocBarChart
                rocAuc={performance.rocAuc}
                usesFPD={usesFPD}
              />
            )}
          </TabPanel>

          <TabPanel>
            <TabularDataTable
              headers={performance.metricsTable.headers}
              rows={performance.metricsTable.rows}
            />
          </TabPanel>

          <TabPanel>
            <OutcomeLiftCurveChart
              title="Lift chart"
              yLabel="Lift over random"
              xLabel="Target percentile"
              data={assertArrayAndUnwrap(performance.liftCurve)}
            />
          </TabPanel>

          <TabPanel>
            <TabularDataTable
              headers={performance.liftTable.headers}
              rows={performance.liftTable.rows}
            />
          </TabPanel>

          <TabPanel>
            <RocChart data={assertArrayAndUnwrap(performance.rocCurve)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
