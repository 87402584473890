import { gql, useQuery } from "@apollo/client";
import { ReactElement } from "react";

import { SegmentType } from "../../../__generated__/globalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { useExploreParams } from "../beta/useExploreParams";
import { ExploreCohortsQuery } from "./__generated__/ExploreCohortsQuery";
import { EmptyPresets } from "./EmptyPresets";
import { PresetList } from "./PresetList";
import { filterExplorableResources } from "./presetTabsUtils";

const exploreCohortsQuery = gql`
  query ExploreCohortsQuery {
    cohorts {
      id
      literate
      slug
      count
      explore_enabled
    }
  }
`;

/**
 * Renders the Cohorts presets within the PresetTabs
 */
export function ExploreCohorts(): ReactElement {
  const { addSegment } = useExploreParams();

  const { data, error } = useQuery<ExploreCohortsQuery>(exploreCohortsQuery, {
    fetchPolicy: "cache-and-network",
  });

  if (error) throw error;

  if (!data) return <AnimatedZapLogo />;

  const cohorts = filterExplorableResources(
    data.cohorts.filter((c) => c.explore_enabled)
  );

  let body;
  if (!cohorts.length) {
    body = (
      <EmptyPresets
        routeName={ROUTE_NAMES.COHORTS}
        icon={ResourceIcon.cohorts}
        name="cohorts"
        cta="cohorts console"
      />
    );
  } else {
    body = (
      <PresetList>
        {cohorts.map((cohort) => {
          return (
            <PresetList.Button
              key={cohort.id}
              icon={<ResourceIcon.cohorts />}
              label={cohort.literate ?? cohort.slug}
              onClick={() =>
                addSegment({
                  id: cohort.id,
                  type: SegmentType.Cohort,
                })
              }
            />
          );
        })}
      </PresetList>
    );
  }

  return (
    <PresetList.Actionable
      routeName={ROUTE_NAMES.COHORTS}
      label="Manage cohorts"
      analyticsName="cohorts"
    >
      {body}
    </PresetList.Actionable>
  );
}
