import Select from "react-select";

import {
  analyticsAttrs,
  useAnalyticsKey,
} from "../../../../ui/Analytics/AnalyticsStack";
import {
  DatasetSelectOption,
  reactSelectStyle,
} from "../../../shared/reactSelectStyle";
import { DetectedColumn } from "../shared/types";
import {
  DataMapFormatOption,
  datasetFormatOptionsByDataType,
} from "./datasetFormatOptionsByDataType";
import { EventProperty } from "./DatasetsEventsModal";

function getFormatOptions({
  detectedColumns,
  property,
}: {
  detectedColumns: DetectedColumn[];
  property: EventProperty;
}): DataMapFormatOption[] {
  if (!property.column_name) return [];

  const column = detectedColumns.find(
    (column) => column.name === property.column_name
  );

  if (typeof column?.dataType !== "string") return [];

  const formats = datasetFormatOptionsByDataType[column.dataType];

  return formats.filter((v) => {
    // if the format is already set to the property, show it
    if (v.value === property.format) return true;

    if (v.deprecated) return false;

    if (property.name === "datetime") return v.type === "date";
    if (property.name === "value") return v.type === "number";
    return v.type !== "date";
  });
}

function getFormatHelpText({
  selectedColumn,
  formatOptions,
}: {
  selectedColumn: string | null;
  formatOptions: DataMapFormatOption[];
}) {
  if (!selectedColumn) return "Select a column first.";
  // why not always say auto detected as fallback?
  if (!formatOptions.length) return "Auto-detected.";
  return "Select a format";
}

export function PropertyFormatSelect({
  value,
  onChange,
  property,
  detectedColumns,
  disabled,
  required,
  analyticsName,
  label,
}: {
  label: string;
  value: string | null;
  property: EventProperty;
  onChange: (value: string | null) => void;
  detectedColumns: DetectedColumn[];
  disabled?: boolean;
  required?: boolean;
  analyticsName: string;
}) {
  const analyticsKey = useAnalyticsKey(analyticsName);

  const formatOptions = getFormatOptions({
    detectedColumns,
    property,
  });

  const formatHelpText = getFormatHelpText({
    selectedColumn: property.column_name,
    formatOptions,
  });

  const handleChangeFormat = (
    newValue: { value: string; label: string } | null
  ) => {
    onChange(newValue?.value ?? null);
  };

  const selectedOption = formatOptions.find((opt) => opt.value === value);
  return (
    <Select<DatasetSelectOption>
      aria-label={label}
      isClearable
      isDisabled={disabled || !formatOptions.length}
      placeholder={formatHelpText}
      value={selectedOption}
      onChange={handleChangeFormat}
      options={formatOptions}
      styles={reactSelectStyle}
      menuPortalTarget={document.body}
      {...analyticsAttrs(analyticsKey)}
      required={formatOptions.length > 0 && required} // can't be required if there are no options
    />
  );
}
