import { Box } from "@chakra-ui/react";
import * as React from "react";

/**
 * Wrapper for modal content. This is used to
 * make the content scrollable while keeping the buttons in view.
 * Should be used with 'flush' variant modals.
 */
export const ScrollWrap: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box maxHeight={800} overflowY="auto" p={6} pb={0}>
      {children}
    </Box>
  );
};
