import measureText from "calculate-size";
import { CSSProperties } from "react";

import { theme } from "../../../constants/theme";

const LABEL_FONT_SIZE = 14;

interface GetLabelCoordsOptions {
  /** x coord the label is intented to be centered on */
  x: number;

  /** text label to measure width of */
  label: string;

  /** left bounding box coordinate of the chart */
  left: number;

  /** right bounding box coordinate of the chart */
  right: number;

  /** label font size */
  fontSize?: number;
}

interface LabelCords {
  /** width of the label text, measured by rendering to DOM via utility */
  textWidth: number;

  /** final alignment of the text label, `middle` unless label would overlap chart edges  */
  textAnchor: string;

  /** if the label overlaps left edge of chart */
  overlapsLeft: boolean;

  /** if the label overlaps right edge of chart */
  overlapsRight: boolean;

  /** right coord of label */
  labelLeft: number;

  /** left coord of label */
  labelRight: number;

  /** label overlap as state  */
  overlap: "left" | "right" | "none";
}

/**
 * Given known coordinates of the chart, expected x coordinate to center a label on,
 * and a varying length of text with an unknown width,
 * return the appropriate positioning coordinates and text alignment.
 */
export function getLabelCoords({
  x,
  label,
  left,
  right,
  fontSize = LABEL_FONT_SIZE,
}: GetLabelCoordsOptions): LabelCords {
  const { width: textWidth } = measureText(label, {
    font: `${fontSize}px ${theme.fonts.body}`,
  });

  // check if label would overflow out of the chart left or right
  let labelLeft = x - textWidth / 2;
  let labelRight = x + textWidth / 2;

  const overlapsRight = labelRight > right;
  const overlapsLeft = labelLeft < left;
  const overlap = overlapsRight ? "right" : overlapsLeft ? "left" : "none";

  // update the label left/right since it may not be centered anymore
  labelLeft = overlapsRight ? x - textWidth : labelLeft;
  labelRight = overlapsLeft ? x + textWidth : labelRight;

  // get the appropriate text alignment based on overlap state
  const textAnchor = {
    left: "start",
    right: "end",
    none: "middle",
  }[overlap];

  return {
    overlap,
    textWidth,
    textAnchor,
    overlapsLeft,
    overlapsRight,
    labelLeft,
    labelRight,
  };
}

/** shared animation config for charts */
export const transitionStyles: CSSProperties = {
  transition: theme.durations.medium,
};
