import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  Ref,
} from "react";

import { theme } from "../../../constants/theme";
import { colors } from "../../../styles/chakra-theme-v2";
import { WordBreak } from "../../ui/WordBreak";

const StyledItemButton = styled.button<{ active: boolean }>`
  font-size: ${theme.fontSizes.md};
  font-weight: 600;
  line-height: ${theme.lineHeights.heading};
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${theme.colors.white};
  border-left: 4px solid transparent;
  border-radius: ${theme.radii.md};
  box-shadow: ${theme.shadows.standard};
  margin-bottom: ${theme.space(2)};
  padding: ${theme.space(2)};
  transition: box-shadow 0.2s, background-color 0.2s, color 0.2s;

  &:focus {
    outline: 2px solid ${colors.fdy_purple[500]};
  }

  &:hover {
    background: ${colors.fdy_gray[100]};
  }

  ${(p) =>
    p.active &&
    css`
      box-shadow: -4px 0 0 ${colors.fdy_purple[500]}, ${theme.shadows.standard};
      color: ${theme.colors.black};
    `}
`;

const StyledLabel = styled.div`
  padding-right: 8px;
`;

interface FacetButtonProps {
  active: boolean;
  label: string;
  afterLabel?: ReactNode;
  onClick: MouseEventHandler;
  onKeyDown: KeyboardEventHandler;
  tabIndex: number;
}

export const FacetButton = forwardRef(function (
  { active, label, afterLabel, onClick, onKeyDown, tabIndex }: FacetButtonProps,
  ref: Ref<HTMLButtonElement>
): ReactElement {
  return (
    <StyledItemButton
      ref={ref}
      data-active={active}
      active={active}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
    >
      <StyledLabel>
        <WordBreak breakAfter="/" text={label} />
      </StyledLabel>
      {afterLabel && <div>{afterLabel}</div>}
    </StyledItemButton>
  );
});
