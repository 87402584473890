import { useEffect } from "react";
import { useError } from "react-use";

import {
  getToastMessageFromError,
  logErrorToRollbar,
} from "../components/ui/ErrorBoundary/errorUtils";
import { useRollbar } from "../components/ui/RollbarProvider";
import { useToast } from "./useToast";

/**
 * A hook for mapping mostly user friendly network errors to toast alerts.
 *
 * If the error is not handled here it will be thrown to the error boundary.
 *
 * Ideally we'd just put this into the error boundary, but those remount
 * children on error, so users would unexpectedly lose state.
 * See: https://github.com/facebook/react/issues/26259
 */
export function useApiErrorToaster(
  err: unknown,
  {
    handleUnknownAs: fallback = "throw",
  }: {
    /**
     * Whether to throw unknown errors to error boundary or simply show the
     * message as toast and log to rollbar.
     */
    handleUnknownAs?: "toast" | "throw";
  } = {}
) {
  const dispatchError = useError();
  const toast = useToast();
  const rollbar = useRollbar();

  useEffect(() => {
    if (err instanceof Error) onError(err);
  }, [err]);

  function onError(error: Error) {
    const message = getToastMessageFromError(error);

    if (message) {
      toast({
        status: "error",
        title: "Unable to save",
        description: message,
        duration: 10000, // validation messages should stay visibile longer
      });
    } else if (fallback === "throw") {
      dispatchError(error);
    } else if (fallback === "toast") {
      logErrorToRollbar({
        error,
        rollbar,
        level: "critical",
      });

      toast({
        title: "Unknown error",
        description: error.message,
      });
    } else {
      throw new Error(`Unknown fallback type: ${fallback}`);
    }
  }
}
