import { SystemStyleObject } from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import * as React from "react";

import { gqlTypenameToResourceType } from "../../constants/gqlTypenameToResourceType";
import { createResourceRoute } from "../../utils/createResourceRoute";
import { IconWithText } from "./IconWithText";
import { ResourceIcon } from "./ResourceIcon";
import { RouterLink } from "./RouterLink";

type GqlResource = {
  __typename: string;
  id: string;
  name: string;
};

type RestResource = {
  id: string;
  name: string;
  resource_type: string;
};

/**
 * Given a resource that's GQL shape, create a link to the resource's detail page.
 */
export function ResourceLink({
  resource,
  upstreamId,
  children,
  showResourceIcon,
  ...rest
}: {
  resource: GqlResource | RestResource;
  upstreamId?: string;
  children?: React.ReactNode;
  sx?: SystemStyleObject;
  showResourceIcon?: boolean;
}) {
  const type =
    "__typename" in resource
      ? gqlTypenameToResourceType[resource.__typename]
      : (resource.resource_type as ResourceType);
  const linkProps = createResourceRoute({
    id: resource.id,
    type,
    upstreamId,
  });

  let label = children ?? resource.name;
  if (showResourceIcon) {
    const Icon = ResourceIcon[type];
    label = (
      <IconWithText>
        <Icon />
        {label}
      </IconWithText>
    );
  }

  return (
    <RouterLink
      routeName={linkProps.routeName}
      params={linkProps.params}
      {...rest}
    >
      {label}
    </RouterLink>
  );
}
