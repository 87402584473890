import { Box, Text } from "@chakra-ui/react";

import { useTestMode } from "../../../hooks/useTestMode";
import { Button } from "../../ui/Button";

export function LeaveTestModeNotice({ title }: { title: string }) {
  const { toggleTestMode } = useTestMode();

  return (
    <Box>
      <Text mb={2}>{title}</Text>
      <Button
        variant="tertiary"
        onClick={toggleTestMode}
        analyticsName="leave-test-mode"
      >
        Leave test mode
      </Button>
    </Box>
  );
}
