import { StreamAnalysisTimeSeriesDatum } from "@fdy/faraday-js";

import { yearMonthDaySlug } from "../../../utils/formatters";
import { StreamAnalysisTimeFrame } from "./EventStreamAnalysisCard";

/**
 * Filters the data based on the given time frame.
 */
function filterByTimeframe(
  data: StreamAnalysisTimeSeriesDatum[],
  timeFrame: StreamAnalysisTimeFrame,
  today: Date
) {
  if (timeFrame === StreamAnalysisTimeFrame.LastThreeYears) {
    const threeYearsAgo = new Date(today);
    threeYearsAgo.setFullYear(today.getFullYear() - 3);
    return data.filter((event) => new Date(event.date) >= threeYearsAgo);
  }
  return data;
}

/**
 * Injects empty datums for every day between the last datum and today.
 */
function injectMissingDates(
  result: StreamAnalysisTimeSeriesDatum[],
  today: Date
) {
  const lastDatum = result[result.length - 1];
  const currentDate = new Date(lastDatum.date);
  currentDate.setDate(currentDate.getDate() + 1);

  const missingDates = [];
  while (currentDate < today) {
    missingDates.push({
      date: yearMonthDaySlug(currentDate),
      count: 0,
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Ensure today's date is included
  missingDates.push({
    date: yearMonthDaySlug(today),
    count: 0,
  });

  return [...result, ...missingDates];
}

/**
 * Prepares time series data by filtering based on the given time frame and
 * ensuring today's date is included if not already present.
 */
export function prepareTimeSeriesData(
  data: StreamAnalysisTimeSeriesDatum[],
  timeFrame: StreamAnalysisTimeFrame
) {
  const today = new Date();
  const formattedToday = yearMonthDaySlug(today);

  const result = filterByTimeframe(data, timeFrame, today);

  // If today's date is not in the data, inject empty datums for every day between the last datum and today.
  if (result.length > 0 && data.at(-1)?.date !== formattedToday) {
    return injectMissingDates(result, today);
  }

  return result;
}
