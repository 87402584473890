export function camelCaseToSnakeCase(str: string) {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export function snakeCaseObjectKeys<T>(
  obj: Record<string, T>
): Record<string, T> {
  // cannot use T here :/
  const snakeCaseObj: Record<string, unknown> = {};
  for (const key in obj) {
    const newKey = camelCaseToSnakeCase(key);
    snakeCaseObj[newKey] = obj[key];
  }
  return snakeCaseObj as Record<string, T>;
}

/**
 * This is a very basic equivalent of the rubys `tableize`
 * It turns a string into a pluralized camel cased string.
 */
export function tableize(name: string): string {
  return name
    .replace(/([^-A-Za-z0-9_]+)/gi, "_")
    .replace(/^-/, "_")
    .concat(name.endsWith("s") ? "" : "s")
    .toLowerCase();
}
