import { gql } from "@apollo/client";

import { useSojournerQuery } from "../../services/sojournerApolloClient";
import {
  PipelinesShowPageQuery,
  PipelinesShowPageQueryVariables,
} from "./__generated__/PipelinesShowPageQuery";
import { pipelineFragment } from "./pipelineFragment";

export const PIPELINES_SHOW_PAGE_QUERY = gql`
  query PipelinesShowPageQuery($scopeId: ID!) {
    scope(scopeId: $scopeId) {
      ...PipelineFragment
    }
  }
  ${pipelineFragment}
`;

/**
 * Query the details of a pipeline aka scope.
 *
 * Useful for detail/show page and edit pages.
 */
export function usePipelineQuery(
  scopeId: string,
  config?: {
    pollInterval?: number;
  }
) {
  return useSojournerQuery<
    PipelinesShowPageQuery,
    PipelinesShowPageQueryVariables
  >(PIPELINES_SHOW_PAGE_QUERY, {
    variables: {
      scopeId,
    },
    pollInterval: config?.pollInterval,
  });
}
