import { ReactElement, useEffect } from "react";
import { useRoute } from "react-router5";
import { useError } from "react-use";

import { useAccountBasics } from "../../../hooks/accountConfigHooks";
import { useToast } from "../../../hooks/useToast";
import { Button } from "../../ui/Button";

export function PaymentSetupButton(): ReactElement {
  const accountBasics = useAccountBasics();
  const dispatchError = useError();
  const { route } = useRoute();
  const toast = useToast();

  useEffect(() => {
    if (route.params.paymentAdded === "true") {
      toast({
        title: "Payment method added",
        status: "success",
      });
    } else if (route.params.paymentAdded === "false") {
      toast({
        title: "Error adding payment method",
        status: "error",
      });
    }
  }, [route]);

  async function createCheckoutSession() {
    // this will create a new Stripe "Checkout" session, and return the URL to
    // the payment info form. We then forward our user to that page so they can
    // fill it out.
    const resp = await fetch("/pay/add-payment-method", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({
        account_id: accountBasics.id,
        success_url: `${window.location.href}?paymentAdded=true`,
        cancel_url: `${window.location.href}?paymentAdded=false`,
      }),
    });

    if (!resp.ok) {
      dispatchError(
        new Error(
          `We couldn't get access to a Stripe checkout session due to error: ${await resp.text()}`
        )
      );
      return;
    }

    const body = await resp.json();
    // Go to stripe checkout page

    if ("url" in body && body.url.match(/billing\.faraday\.ai/)) {
      window.location.href = body.url;
    }
  }

  return (
    <Button
      onClick={createCheckoutSession}
      width="100%"
      variant="secondary"
      analyticsName="add-payment-method"
    >
      Add payment method
    </Button>
  );
}
