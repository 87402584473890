import { AccordionV2 } from "../../../ui/AccordionV2";
import { CardV2 } from "../../../ui/Card/CardV2";
import { SkeletonCard } from "../../../ui/SkeletonCard";
import { OutcomeFragment } from "../../__generated__/OutcomeFragment";
import { OutcomeAnalysisPerformanceModel } from "../__generated__/OutcomeAnalysisPerformanceModel";
import { useOutcomeAnalysisQuery } from "../useOutcomeAnalysisQuery";
import { useOutcomeUsesFirstPartyData } from "../useOutcomeUsesFirstPartyData";
import { OutcomeGradeBadge } from "./OutcomeGradeBadge";
import { OutcomeLiftRocGridChart } from "./OutcomeLiftRocGridChart";
import { OutcomePerformanceCharts } from "./OutcomePerformanceCharts";
import { OutcomePerformanceText } from "./OutcomePerformanceText";
import { OutcomeRocBarChart } from "./OutcomeRocBarChart";

interface OutcomePerformanceCardProps {
  outcome: OutcomeFragment;
}

function OutcomePerformanceFallback({
  rocAuc,
  liftValue,
  usesFPD,
}: {
  rocAuc: number | null;
  liftValue: number | null;
  usesFPD: boolean;
}) {
  if (!rocAuc) return null;

  const chart =
    liftValue === null ? (
      <OutcomeRocBarChart rocAuc={rocAuc} usesFPD={usesFPD} />
    ) : (
      <OutcomeLiftRocGridChart
        rocAuc={rocAuc}
        liftValue={liftValue}
        usesFPD={usesFPD}
      />
    );

  return (
    <CardV2 title="Performance">
      <OutcomePerformanceText
        rocAuc={rocAuc}
        liftValue={liftValue}
        usesFPD={usesFPD}
      />
      {chart}
    </CardV2>
  );
}

function OutcomePerformanceAccordionItem({
  eligibleCohortName,
  performance,
  title,
  usesFPD,
}: {
  performance: OutcomeAnalysisPerformanceModel;
  eligibleCohortName: string | null;
  title?: string;
  usesFPD: boolean;
}) {
  const { tenureStart, tenureEnd, rocAuc, liftValue } = performance;

  const tenurePrefix =
    tenureStart === null && tenureEnd === null
      ? `Before joining`
      : tenureStart !== null && tenureEnd === null
      ? `${tenureStart}+ days in`
      : tenureEnd !== null
      ? `${tenureStart ?? 0}-${tenureEnd} days in`
      : null;

  const titleNode = (
    <span style={{ fontWeight: "normal" }}>
      {tenurePrefix} <strong>{eligibleCohortName}</strong>
    </span>
  );

  return (
    <AccordionV2.Item
      title={title ?? titleNode}
      afterTitle={
        <OutcomeGradeBadge
          usesFPD={usesFPD}
          rocAuc={rocAuc}
          liftValue={liftValue}
        />
      }
    >
      <OutcomePerformanceCharts performance={performance} usesFPD={usesFPD} />
    </AccordionV2.Item>
  );
}

export function OutcomePerformanceCard({
  outcome,
}: OutcomePerformanceCardProps) {
  const { data, loading } = useOutcomeAnalysisQuery(outcome);

  const { loading: cohortLoading, usesFPD } =
    useOutcomeUsesFirstPartyData(outcome);

  if (loading || cohortLoading) return <SkeletonCard />;

  const { outcomeAnalysis } = data ?? {};

  if (!outcomeAnalysis) {
    return (
      <OutcomePerformanceFallback
        rocAuc={outcome.rocAuc}
        liftValue={outcome.liftValue}
        usesFPD={usesFPD}
      />
    );
  }

  const { overallPerformance, tenurePerformances } = outcomeAnalysis ?? {};

  // If there is no overall performance, don't render the card.
  // It would be included for either dynamic or non-dynamic outcomes.
  if (!overallPerformance) return null;

  // if the outcome is non-dynamic, render the single overall performance chart
  if (overallPerformance?.recognizedIndividuals && !tenurePerformances) {
    return (
      <CardV2 title="Performance">
        <OutcomePerformanceCharts
          usesFPD={usesFPD}
          performance={overallPerformance.recognizedIndividuals}
        />
      </CardV2>
    );
  }

  const cardText = (
    <>
      Dynamic prediction is active. Predictions will depend on how long a person
      has been a member of <strong>{outcome.eligibleCohortName}</strong>.
    </>
  );

  return (
    <CardV2 title="Performance" text={cardText}>
      <AccordionV2 defaultIndex={0} allowToggle>
        {overallPerformance?.recognizedIndividuals && (
          <OutcomePerformanceAccordionItem
            title="Overview"
            performance={overallPerformance.recognizedIndividuals}
            usesFPD={usesFPD}
            eligibleCohortName={outcome.eligibleCohortName}
          />
        )}

        {tenurePerformances?.recognizedIndividuals.map((perf, i) => {
          if (!perf) return null;
          return (
            <OutcomePerformanceAccordionItem
              key={i}
              performance={perf}
              usesFPD={usesFPD}
              eligibleCohortName={outcome.eligibleCohortName}
            />
          );
        })}
      </AccordionV2>
    </CardV2>
  );
}
