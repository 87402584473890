import { gql, useQuery } from "@apollo/client";
import { ReactElement, useMemo } from "react";

import { SegmentType } from "../../../__generated__/globalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { PersonaImage } from "../../ui/PersonaImage";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { useExploreParams } from "../beta/useExploreParams";
import {
  ExplorePersonasQuery,
  ExplorePersonasQuery_cohorts_roster,
  ExplorePersonasQuery_cohorts_roster_personas,
} from "./__generated__/ExplorePersonasQuery";
import { EmptyPresets } from "./EmptyPresets";
import { PresetList } from "./PresetList";
import { filterExplorableResources } from "./presetTabsUtils";

export const explorePersonasQuery = gql`
  query ExplorePersonasQuery {
    active_roster {
      id
      explore_enabled
      personas {
        ...personaFragment
      }
    }
    cohorts {
      id
      literate
      explore_enabled
      roster {
        id
        explore_enabled
        personas {
          ...personaFragment
        }
      }
    }
  }
  fragment personaFragment on Persona {
    id
    name
    image_filename
    count
  }
`;

function getExplorableRosters(data?: ExplorePersonasQuery) {
  if (!data) return [];

  // Some accounts may have the active account roster point to the same roster built
  // from a cohort. This could happen when an account uses cohort rosters but also needs Inform product,
  // which requires setting the account level roster.
  // We may also have accounts not fully adopting the cohort based rosters so they still have account level roster.
  // In these cases, prioritize showing the cohort roster over the account level roster since it has a semantic cohort name.
  const cohortsContainsAccountRoster = data.cohorts.some(
    (cohort) => cohort.roster?.id === data.active_roster?.id
  );

  // bundle up the account level roster with the list of cohorts, so we can easily render
  // empty state and the list of cohorts and their personas
  const allRosters =
    data.active_roster && !cohortsContainsAccountRoster
      ? [
          {
            id: data.active_roster.id,
            literate: "Account personas",
            roster: data.active_roster,
          },
          ...data.cohorts,
        ]
      : data.cohorts;

  // ensure we only show cohorts with personas that have explore caches
  const cohorts = allRosters.filter(
    (c) =>
      c.roster?.explore_enabled &&
      filterExplorableResources(c.roster?.personas ?? []).length
  );

  return cohorts;
}

/**
 * Renders account roster and cohorts with each related roster/persona set.
 */
export function ExplorePersonas(): ReactElement {
  const { addSegment } = useExploreParams();
  const { data, error } = useQuery<ExplorePersonasQuery>(explorePersonasQuery, {
    fetchPolicy: "cache-and-network",
  });

  const cohorts = useMemo(() => getExplorableRosters(data), [data]);

  if (error) throw error;

  if (!data) return <AnimatedZapLogo />;

  function renderPersona(
    persona: ExplorePersonasQuery_cohorts_roster_personas
  ) {
    return (
      <PresetList.Button
        key={persona.id}
        icon={
          <PersonaImage
            filename={persona.image_filename}
            size="small"
            circular
          />
        }
        label={persona.name}
        onClick={() => {
          addSegment({
            id: persona.id,
            type: SegmentType.Persona,
          });
        }}
      />
    );
  }

  function renderCohort(cohort: {
    id: string;
    literate: string;
    roster: ExplorePersonasQuery_cohorts_roster | null;
  }) {
    const personas = filterExplorableResources(cohort.roster?.personas ?? []);

    if (!personas.length) return null;

    return (
      <PresetList.Section
        icon={ResourceIcon.cohorts}
        key={cohort.id}
        label={cohort.literate}
      >
        {personas.map(renderPersona)}
      </PresetList.Section>
    );
  }

  let body;
  if (!cohorts.length) {
    body = (
      <EmptyPresets
        routeName={ROUTE_NAMES.PERSONAS}
        icon={ResourceIcon.persona_sets}
        name="personas"
        cta="personas console"
      />
    );
  } else {
    body = <PresetList>{cohorts.map(renderCohort)}</PresetList>;
  }

  return (
    <PresetList.Actionable
      routeName={ROUTE_NAMES.PERSONAS}
      label="Manage personas"
      analyticsName="personas"
    >
      {body}
    </PresetList.Actionable>
  );
}
