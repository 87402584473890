import { List, ListItem } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import { Dispatch, SetStateAction, useState } from "react";

import { useStreamListQuery } from "../../../../../hooks/api";
import { ActionableCardItem } from "../../../../ui/ActionableCardItem";
import { Button } from "../../../../ui/Button";
import { CardV2 } from "../../../../ui/Card/CardV2";
import { UsageWarningNotice } from "../../../../ui/UsageWarningNotice";
import { DatasetFormDataset as DatasetForForm } from "../DatasetForm";
import { DatasetEventCondition } from "./DatasetsEventConditionsForm";
import { DatasetEventState, DatasetsEventsModal } from "./DatasetsEventsModal";
import {
  DatasetsEventsSelectStreamModal,
  StreamSelectStepResult,
} from "./DatasetsEventsSelectStreamModal";
import { EventPropertyTeaser } from "./EventPropertyTeaser";
import { DatasetEventDataMap } from "./PropertiesTableRow";

export enum DatasetEventModalStep {
  selectStream = "selectStream",
  existingStream = "existingStream",
  newStream = "newStream",
  none = "none",
}

export enum EventStreamNewOrExisting {
  newStream = "newStream",
  existingStream = "existingStream",
}

export type DatasetEventConfig = {
  classic?: boolean;
  data_map: DatasetEventDataMap;
  conditions: DatasetEventCondition[];
};

export type OutputToStreamsState = {
  [eventName: string]: DatasetEventConfig | null;
};

export function DatasetsEventsCard({
  events,
  setEvents,
  dataset,
}: {
  events: OutputToStreamsState;
  setEvents: Dispatch<SetStateAction<OutputToStreamsState>>;
  dataset?: DatasetForForm;
}) {
  const [openModal, setOpenModal] = useState<DatasetEventModalStep>(
    DatasetEventModalStep.none
  );

  const [editingEvent, setEditingEvent] = useState<DatasetEventState>();

  const streamsQuery = useStreamListQuery();

  const streams = streamsQuery.data ?? [];

  const handleEventSelectNext = (result: StreamSelectStepResult) => {
    if (result.action === EventStreamNewOrExisting.newStream) {
      setOpenModal(DatasetEventModalStep.newStream);
    } else if (result.action === EventStreamNewOrExisting.existingStream) {
      // this adds to existing event but doesn't need to set existing properties or conditions
      setEditingEvent({
        name: result.name,
        conditions: [],
        properties: {},
      });

      setOpenModal(DatasetEventModalStep.existingStream);
    } else {
      throw new Error("Invalid action");
    }
  };

  const handleEventSetupFinish = (eventState: DatasetEventState) => {
    setEvents((prev) => ({
      ...prev,
      [eventState.name]: {
        data_map: eventState.properties,
        conditions: eventState.conditions,
      },
    }));

    setEditingEvent(undefined);

    setOpenModal(DatasetEventModalStep.none);
  };

  const handleDeleteEvent = (eventName: string) => {
    setEvents((events) => ({
      ...events,
      // must be null in order to remove when merge-patching
      [eventName]: null,
    }));
  };

  const handleEditEvent = (
    event: {
      classic?: boolean;
      data_map: DatasetEventDataMap;
      conditions: DatasetEventCondition[];
    },
    eventName: string
  ) => {
    setEditingEvent({
      name: eventName,
      properties: event.data_map,
      conditions: event.conditions,
      classic: event.classic,
    });
    setOpenModal(DatasetEventModalStep.existingStream);
  };

  const handleAddEvent = () => {
    // TODO: move stream create/select to step 2 so this isn't needed either?
    if (streams.length) {
      setOpenModal(DatasetEventModalStep.selectStream);
    } else {
      setOpenModal(DatasetEventModalStep.newStream);
    }
  };

  const handleEventEditClose = () => {
    setOpenModal(DatasetEventModalStep.none);
    setEditingEvent(undefined);
  };

  const currentStream = streams.find(
    (stream) => stream.name === editingEvent?.name
  );

  return (
    <CardV2
      title="Events"
      suffix="Recommended"
      text="Events show Faraday how to recognize actions taking place in your data, 
      such as purchases, renewals, click events, upsells, etc. Dates are often the 
      most useful piece of data for events."
    >
      <UsageWarningNotice resource="streams" />

      <List>
        {Object.entries(events).map(([eventName, event]) => {
          if (!event) return null;
          return (
            <ListItem mb={4} key={eventName}>
              <ActionableCardItem
                menuBtnLabel={`${eventName} options`}
                title={eventName}
                content={<EventPropertyTeaser event={event} />}
                onEdit={() => handleEditEvent(event, eventName)}
                onDelete={() => handleDeleteEvent(eventName)}
              />
            </ListItem>
          );
        })}
      </List>

      {!dataset?.managed && (
        <Button
          width="100%"
          variant="secondary"
          leftIcon={<Plus weight="bold" />}
          disabled={streamsQuery.isLoading}
          onClick={handleAddEvent}
          analyticsName="add-event-button"
        >
          Add event
        </Button>
      )}

      {openModal === DatasetEventModalStep.selectStream && (
        <DatasetsEventsSelectStreamModal
          onClose={() => setOpenModal(DatasetEventModalStep.none)}
          onNext={handleEventSelectNext}
        />
      )}

      {(openModal === DatasetEventModalStep.newStream ||
        openModal === DatasetEventModalStep.existingStream) && (
        <DatasetsEventsModal
          streams={streams}
          dataset={dataset}
          currentStream={currentStream}
          initialState={editingEvent}
          openModal={openModal}
          onNext={handleEventSetupFinish}
          onClose={handleEventEditClose}
        />
      )}
    </CardV2>
  );
}
