import { gql, useMutation, useQuery } from "@apollo/client";
import { Box, Container, Flex, Input } from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import * as React from "react";
import { useRouter } from "react-router5";

import { UpdateAccountSetupStatus } from "../../__generated__/globalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { LoadingSplash } from "../root/LoadingSplash";
import { Button } from "../ui/Button";
import { CardV2 } from "../ui/Card/CardV2";
import { FormField } from "../ui/FormField";
import { FormFieldset } from "../ui/FormFieldset";
import { LinkButton } from "../ui/LinkButton";
import { PageLayout } from "../ui/PageLayout";
import {
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from "./__generated__/UpdateAccountMutation";
import { UserDetails } from "./__generated__/UserDetails";

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="128" height="23" fill="none">
    <path fill="#F72F96" d="M0 18.193 13.447 4.808v13.385H0Z" />
    <path fill="#B641FF" d="M29.256 4.808 15.81 18.193V4.808h13.447Z" />
    <path
      fill="#000"
      d="M35.92 18.138V.123h11.565v3.2h-7.483v4.762h5.921v3.074h-5.92v6.979H35.92Zm16.525.277c-2.394 0-4.41-1.159-4.41-3.93 0-3.074 2.823-4.233 6.854-4.233h1.486v-.53c0-1.561-.478-2.418-2.141-2.418-1.436 0-2.091.73-2.243 1.864H48.54c.226-3.124 2.696-4.51 5.92-4.51 3.226 0 5.518 1.31 5.518 4.888v8.592h-3.552V16.55c-.756 1.058-1.915 1.864-3.981 1.864Zm1.083-2.545c1.613 0 2.847-.881 2.847-2.343V12.42h-1.41c-2.117 0-3.352.453-3.352 1.864 0 .958.58 1.587 1.915 1.587Zm9.082 2.268V4.96h3.653v2.52c.832-1.764 2.117-2.697 4.258-2.722v3.402c-2.695-.026-4.258.856-4.258 3.376v6.6H62.61Zm13.112.277c-2.393 0-4.409-1.159-4.409-3.93 0-3.074 2.822-4.233 6.853-4.233h1.487v-.53c0-1.561-.48-2.418-2.142-2.418-1.436 0-2.091.73-2.242 1.864h-3.452c.227-3.124 2.696-4.51 5.92-4.51 3.226 0 5.519 1.31 5.519 4.888v8.592h-3.553V16.55c-.756 1.058-1.915 1.864-3.98 1.864Zm1.084-2.545c1.612 0 2.847-.881 2.847-2.343V12.42h-1.411c-2.117 0-3.351.453-3.351 1.864 0 .958.58 1.587 1.915 1.587Zm14.02 2.545c-3.2 0-5.72-2.293-5.72-6.677v-.201c0-4.334 2.495-6.879 5.795-6.879 2.117 0 3.427.933 4.157 2.268v-7.937h3.629v19.149h-3.629v-2.192c-.68 1.335-2.292 2.47-4.232 2.47Zm1.083-2.847c1.915 0 3.25-1.285 3.25-3.956v-.201c0-2.62-1.159-3.956-3.15-3.956-2.04 0-3.174 1.386-3.174 4.006v.202c0 2.62 1.26 3.905 3.074 3.905Zm13.069 2.847c-2.393 0-4.409-1.159-4.409-3.93 0-3.074 2.822-4.233 6.853-4.233h1.487v-.53c0-1.561-.479-2.418-2.142-2.418-1.436 0-2.091.73-2.242 1.864h-3.452c.227-3.124 2.696-4.51 5.921-4.51s5.518 1.31 5.518 4.888v8.592h-3.553V16.55c-.756 1.058-1.915 1.864-3.981 1.864Zm1.084-2.545c1.612 0 2.847-.881 2.847-2.343V12.42h-1.411c-2.117 0-3.351.453-3.351 1.864 0 .958.579 1.587 1.915 1.587Zm10.375 6.753 2.318-5.82-5.24-11.842h3.93l3.2 7.911 2.948-7.911h3.502l-7.13 17.662h-3.528Z"
    />
  </svg>
);

const USER_DETAILS = gql`
  query UserDetails {
    account {
      id
      name
      setup_status
    }
    user {
      id
      email
    }
  }
`;

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccountMutation(
    $name: String!
    $setupStatus: UpdateAccountSetupStatus!
  ) {
    update_account(name: $name, setup_status: $setupStatus) {
      id
      setup_status
      name
    }
  }
`;

type AccountSetupState = {
  companyName: string;
};

const defaultState: AccountSetupState = {
  companyName: "",
};

function AccountInfoForm({
  initialState = defaultState,
}: {
  initialState?: AccountSetupState;
}) {
  const router = useRouter();

  const [accountInfo, setAccountInfo] =
    useState<AccountSetupState>(initialState);

  const [
    updateAccount,
    { loading: updateAccountLoading, error: updateAccountError },
  ] = useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(
    UPDATE_ACCOUNT_MUTATION,
    {
      onCompleted() {
        router.navigate(ROUTE_NAMES.HOME);
      },
    }
  );

  if (updateAccountError) {
    throw updateAccountError;
  }

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    // Name validation handled by input attributes.
    // Any cleaning should be done in kopeng as well.
    const name = accountInfo.companyName.trim();

    updateAccount({
      variables: {
        name,
        setupStatus: UpdateAccountSetupStatus.onboarded,
      },
    });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const val = e.target.value;
    setAccountInfo((s) => ({
      ...s,
      companyName: val,
    }));
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <FormFieldset
        legend="Tell us about yourself"
        hint="This helps us get your account set up."
      >
        <FormField label="Company name">
          <Input
            value={accountInfo.companyName}
            onChange={handleNameChange}
            required
          />
        </FormField>
      </FormFieldset>
      <Button
        mt={4}
        type="submit"
        disabled={updateAccountLoading}
        isLoading={updateAccountLoading}
        analyticsName="finish"
      >
        Next
      </Button>
    </form>
  );
}

export function AccountSetup() {
  const { data, loading } = useQuery<UserDetails>(USER_DETAILS);

  if (loading) {
    return <LoadingSplash />;
  }

  if (!data?.user) {
    throw new Error("Failed to load user details");
  }

  const initialState =
    data?.account.name !== data?.user.email
      ? {
          companyName: data.account.name,
        }
      : undefined;

  return (
    <PageLayout analyticsStackName="account-setup">
      <Box h="100%" w="100%">
        <Flex
          bg="white"
          p={4}
          borderBottom="1px gray"
          alignItems="center"
          justifyContent="space-between"
        >
          <Logo />
          <LinkButton
            variant="tertiary"
            size="sm"
            routeName={ROUTE_NAMES.LOGOUT}
            analyticsName="logout"
          >
            Log out
          </LinkButton>
        </Flex>
        <Container p={16} maxW="container.lg">
          <CardV2
            title="👋 Welcome to Faraday"
            text="Let's get you started with your very first prediction use case."
          >
            <AccountInfoForm initialState={initialState} />
          </CardV2>
        </Container>
      </Box>
    </PageLayout>
  );
}
