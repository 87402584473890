import gql from "graphql-tag";

import { connectionOptionsTypesFragment } from "../../connectionOptionsFragments";
import { useSojournerQuery } from "../../services/sojournerApolloClient";
import {
  ConnectionsQuery,
  ConnectionsQuery_connections,
} from "./__generated__/ConnectionsQuery";

export type Connection = ConnectionsQuery_connections;

export const CONNECTIONS_QUERY = gql`
  query ConnectionsQuery {
    connections {
      id
      name
      managed
      archivedAt
      directionality
      status
      ...ConnectionOptionsTypes
    }
  }
  ${connectionOptionsTypesFragment}
`;

export function useConnectionsQuery() {
  const { data, loading, error } =
    useSojournerQuery<ConnectionsQuery>(CONNECTIONS_QUERY);
  if (error) throw error;

  return {
    connections: data?.connections ?? [],
    loadingConnections: loading,
  };
}
