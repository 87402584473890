import { EDGE_COLOR, EDGE_COLOR_SUBDUED, HIGHLIGHT_COLOR } from "../config";
import { MarkerName } from "../types";

const DotMarker = ({ id, fill }: { id: MarkerName; fill: string }) => {
  const size = 8;
  return (
    <marker
      id={id}
      viewBox={`0 0 ${size * 2} ${size * 2}`}
      markerHeight={size}
      markerWidth={size}
      refX={size / 1.5}
      refY={size}
    >
      <circle cx={size} cy={size} r={size / 2} fill={fill} />
    </marker>
  );
};

// Define custom markers for edges.
// We use these instead of styling the node handles beacuse it's easier to control
// especially for the "highlighted" state.
export const Markers = () => (
  <svg
    style={{
      position: "absolute",
      top: 0,
      left: 0,
    }}
  >
    <defs>
      <DotMarker id={MarkerName.Dot} fill={EDGE_COLOR} />
      <DotMarker id={MarkerName.DotHighlight} fill={HIGHLIGHT_COLOR} />
      <DotMarker id={MarkerName.DotSubdued} fill={EDGE_COLOR_SUBDUED} />
    </defs>
  </svg>
);
