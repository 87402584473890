import { useState } from "react";
import { useError } from "react-use";

import { OutcomePostInput } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { useSojournerApolloClient } from "../../services/sojournerApolloClient";
import { useModalV2 } from "../ui/ModalV2";
import { RouterLink } from "../ui/RouterLink";
import {
  OutcomesQuery,
  OutcomesQuery_outcomes as Outcome,
} from "./__generated__/OutcomesQuery";
import { outcomesQuery } from "./outcomesQuery";

// POST input values are undefined, but GET from gql api returns null
// so we need to compare null and undefined values specifically.
function compare(
  val1: string | null | undefined,
  val2: string | null | undefined
) {
  const a = val1 ?? null;
  const b = val2 ?? null;
  return a === b;
}

export function useCreateOutcomeWithDuplicateWarning() {
  const client = useSojournerApolloClient();
  const { confirm } = useModalV2();
  const dispatchError = useError();
  // Separate loading flag because using apollo's loading flag is less obvious what's going on.
  // (we'd have to set up a useQuery hook that  skips and checks network status
  // _and_ uses refetch. None of that makes much sense for this use case.)
  const [loading, setLoading] = useState(false);

  async function findDuplicateOutcome(
    input: OutcomePostInput
  ): Promise<void | Outcome> {
    setLoading(true);

    // dont fetch until we need outcomes
    const { data, error } = await client.query<OutcomesQuery>({
      query: outcomesQuery,
    });

    setLoading(false);

    if (error) return dispatchError(error);

    return data.outcomes.find(
      (outcome) =>
        compare(outcome.eligibleCohortId, input.eligibleCohortId) &&
        compare(outcome.attainmentCohortId, input.attainmentCohortId) &&
        compare(outcome.attritionCohortId, input.attritionCohortId) &&
        outcome.featureBlocklist.every((feature) =>
          input.featureBlocklist?.includes(feature)
        ) &&
        compare(outcome.biasMitigation?.age, input.biasMitigation?.age) &&
        compare(outcome.biasMitigation?.gender, input.biasMitigation?.gender)
    );
  }

  async function createWithWarning({
    input,
    onCreate,
    loading,
  }: {
    input: OutcomePostInput;
    onCreate: () => void;
    loading: boolean;
  }) {
    const dupeOutcome = await findDuplicateOutcome(input);

    // If no duplicate outcome, just create it
    if (!dupeOutcome) return onCreate();

    confirm({
      label: "Duplicate outcome",
      text: (
        <>
          You already have an outcome with this configuration:{" "}
          <RouterLink
            routeName={ROUTE_NAMES.OUTCOMES_DEFINITION}
            params={{ outcome: dupeOutcome.id }}
          >
            <strong>{dupeOutcome.name}</strong>
          </RouterLink>
          . Are you sure you want to create another?
        </>
      ),
      confirmLabel: "Create anyway",
      loading,
      onConfirm() {
        onCreate();
      },
    });
  }

  return {
    createWithWarning,
    findingDuplicate: loading,
  };
}
