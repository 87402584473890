import { ChatLink } from "../../../../ui/ChatLink";
import { Notice } from "../../../../ui/Notice";

/*
 * We want a select box with all available options
 * But we don't want to block the user from using the UI
 * if the backend is still calculating the available options.
 * If they're not ready yet, use a textfield instead
 */
export function ClassicExplanation({ resourceType }: { resourceType: string }) {
  return (
    <Notice
      variant="warning"
      title={`Classic ${resourceType}`}
      description={
        <>
          This {resourceType} was created or edited internally by Faraday, so it
          is read-only. Please <ChatLink>contact support</ChatLink> if you would
          like to edit or delete it.
        </>
      }
      sx={{ my: 4 }}
    />
  );
}
