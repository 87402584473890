import { gql } from "@apollo/client";
import { BarSeries } from "@visx/xychart";

import { TraitCategory } from "../../../../__generated__/sojournerGlobalTypes";
import { Trait } from "../../../../hooks/useTraitsQuery";
import { useSojournerQuery } from "../../../../services/sojournerApolloClient";
import { colors } from "../../../../styles/chakra-theme-v2";
import { percent } from "../../../../utils/formatters";
import { AnimatedZapLogo } from "../../../ui/AnimatedZapLogo";
import { AxisBottom } from "../../../ui/charts-v2/AxisBottom";
import { AxisLeft } from "../../../ui/charts-v2/AxisLeft";
import { Chart, RenderTooltipCallback } from "../../../ui/charts-v2/Chart";
import { useRollbar } from "../../../ui/RollbarProvider";
import {
  TraitAnalysisQuery,
  TraitAnalysisQuery_dimensions_bins,
  TraitAnalysisQueryVariables,
} from "./__generated__/TraitAnalysisQuery";

export const traitAnalysisQuery = gql`
  query TraitAnalysisQuery($id: ID!) {
    dimensions: analysisDimensionsTrait(traitId: $id) {
      traitName
      bins {
        __typename
        ... on AnalysisDimensionsTraitBinText {
          category: value
          count
          percent
        }

        ... on AnalysisDimensionsTraitBinBoolean {
          value
          count
          percent
        }

        ... on AnalysisDimensionsTraitBinNumber {
          min
          max
          count
          percent
        }

        ... on AnalysisDimensionsTraitBinDate {
          minDate: min
          maxDate: max
          count
          percent
        }
      }
    }
  }
`;

interface Datum {
  x: string;
  y: number;
  group_percent: number;
}

// TODO: sort these in api instead.
function sortTraitAnalysisDimensions(
  data: (TraitAnalysisQuery_dimensions_bins | null)[]
) {
  const sorted = [...data];
  if (!data || !data[0]) return data;
  sorted.sort((a, b) => {
    if (!a || !b) return 0;
    if (
      a.__typename === "AnalysisDimensionsTraitBinText" &&
      b.__typename === "AnalysisDimensionsTraitBinText"
    ) {
      return a.category.localeCompare(b.category);
    }
    if (
      a.__typename === "AnalysisDimensionsTraitBinNumber" &&
      b.__typename === "AnalysisDimensionsTraitBinNumber"
    ) {
      return a.min - b.min;
    }
    // we know API gives dates in YYYY-MM-DD format, so we can sort alphabetically
    if (
      a.__typename === "AnalysisDimensionsTraitBinDate" &&
      b.__typename === "AnalysisDimensionsTraitBinDate"
    ) {
      return a.minDate.localeCompare(b.minDate);
    }
    return 0;
  });
  return sorted;
}

function getLookupTableValue(
  lookupTable: SojJSON | null,
  key: string
): string | null {
  if (!lookupTable) return null;

  if (typeof lookupTable === "string") {
    return null;
  }

  if (key in lookupTable) {
    const val = lookupTable[key];
    if (typeof val === "string") {
      return val;
    }
  }
  return null;
}

function prepareData(
  data: (TraitAnalysisQuery_dimensions_bins | null)[],
  lookupTable: Trait["lookupTable"]
): Datum[] {
  const result: Datum[] = [];

  const resultSorted = sortTraitAnalysisDimensions(data);

  for (const d of resultSorted) {
    if (!d) continue;

    switch (d.__typename) {
      case "AnalysisDimensionsTraitBinBoolean": {
        result.push({
          x: String(d.value),
          y: d.percent,
          group_percent: d.percent,
        });
        break;
      }

      case "AnalysisDimensionsTraitBinText": {
        const displayValue = getLookupTableValue(lookupTable, d.category);

        result.push({
          x: displayValue ?? d.category,
          y: d.percent,
          group_percent: d.percent,
        });
        break;
      }

      case "AnalysisDimensionsTraitBinNumber": {
        const lookupValue = getLookupTableValue(lookupTable, String(d.min));
        const displayValue = lookupValue
          ? lookupValue
          : d.max
          ? `${String(d.min)}-${String(d.max)}`
          : `${String(d.min)}+`;
        result.push({
          x: displayValue,
          y: d.percent,
          group_percent: d.percent,
        });
        break;
      }

      case "AnalysisDimensionsTraitBinDate": {
        result.push({
          x: d.maxDate
            ? `${String(d.minDate)}-${String(d.maxDate)}`
            : `${String(d.minDate)}-today`,
          y: d.percent,
          group_percent: d.percent,
        });
        break;
      }

      default:
        throw new Error("Unknown type");
    }
  }

  return result;
}

const renderTooltip: RenderTooltipCallback<Datum> = ({ tooltipData }) => {
  if (!tooltipData?.nearestDatum?.key) {
    return null;
  }

  return `${tooltipData.nearestDatum.datum.x} (${percent(
    tooltipData.nearestDatum.datum.y
  )})`;
};

export function CohortTraitPreview({ trait }: { trait: Trait }) {
  const rollbar = useRollbar();
  const { data, loading, error } = useSojournerQuery<
    TraitAnalysisQuery,
    TraitAnalysisQueryVariables
  >(traitAnalysisQuery, {
    variables: {
      id: trait.id,
    },
  });

  const tableData = prepareData(
    data?.dimensions?.bins ?? [],
    trait.lookupTable
  );

  // Trait preview is nice to have, but not required.
  if (error) {
    rollbar.warn(
      `Failed to load trait preview for trait: ${trait.name}. ${error}`
    );
    return null;
  }
  // if trait is user defined, we don't have data to show anyway
  if (trait.category === TraitCategory.USER_DEFINED) return null;
  if (loading) return <AnimatedZapLogo />;
  if (!data?.dimensions?.bins?.length) return null; // no data found. No need to crash the app

  return (
    <Chart
      title={`${trait.literate ?? trait.name} (US Population)`}
      height={300}
      xScale={{ type: "band", padding: 0.2 }}
      yScale={{ type: "linear" }}
      renderTooltip={renderTooltip}
    >
      <BarSeries
        data={tableData}
        dataKey="coverage"
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        colorAccessor={() => colors.fdy_purple[200]}
      />

      <AxisLeft label="US population with this trait" tickFormat={percent} />
      <AxisBottom
        label={`${trait.literate ?? trait.name} ${
          trait.unit ? "(" + trait.unit + ")" : ""
        }`}
        numTicks={1} // can see the rest by hovering. Prevents ugly overlap for categoricals with long names
      />
    </Chart>
  );
}
