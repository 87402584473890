import { Box, Container, Heading, Text } from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";
import * as React from "react";

import { useId } from "../../ui/useId";

export const PersonaGridRowDivider: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Box
    sx={{
      _notLast: {
        borderBottom: "1px solid",
        borderColor: "fdy_gray.200",
      },
    }}
  >
    {children}
  </Box>
);

export function PersonaGridRowHeader({ title }: { title: string }) {
  return (
    <Text
      as="span"
      sx={{
        fontSize: "fdy_sm",
        fontWeight: "bold",
      }}
    >
      {title}
    </Text>
  );
}

export const PersonaGridRowCell: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Text as="span" sx={{ fontSize: "fdy_sm" }}>
    {children}
  </Text>
);

export function PersonaSetAnalysisGridSection({
  label,
  children,
  rightContent,
}: {
  label: string;
  children: ReactNode;
  rightContent?: ReactNode;
}) {
  const id = useId("persona-set-analysis-section");

  return (
    <section aria-labelledby={id}>
      <Box
        as="header"
        sx={{
          bg: "fdy_gray.200",
          py: 5,
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading
            sx={{
              fontSize: "fdy_md",
              fontWeight: "semibold",
            }}
            id={id}
          >
            {label}
          </Heading>

          {rightContent && <Box>{rightContent}</Box>}
        </Container>
      </Box>
      <div>{children}</div>
    </section>
  );
}

export function PersonaSetAnalysisGridRow({
  columns,
  children,
}: {
  columns: number;
  children: ReactNode;
}) {
  return (
    <Box
      sx={{
        bg: "white",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `18rem repeat(${columns - 1}, 1fr) 1rem`,
            gap: 8,
            py: 3,
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
}
