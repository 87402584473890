import { IconButton, Td, Tr } from "@chakra-ui/react";
import { Lock, Trash } from "@phosphor-icons/react";
import snakeCase from "lodash/snakeCase";

import { Input } from "../../../../ui/Input";
import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import { DatasetColumnSelect } from "./DatasetColumnSelect";
import { EventProperty } from "./DatasetsEventsModal";
import { PropertyFormatSelect } from "./PropertyFormatSelect";

export type EventPropertyDetails = {
  column_name: string | null;
  format: string | null;
};

export type DatasetEventDataMap = {
  [key: string]: EventPropertyDetails | null;
};

export function PropertiesTableRow({
  index,
  property,
  onChange,
  disabled,
  detectedColumns,
  locked,
  sampleData,
  onRemove,
}: {
  index: number;
  property: EventProperty;
  onChange: (property: EventProperty) => void;
  onRemove: (property: EventProperty) => void;
  detectedColumns: DetectedColumn[];
  disabled?: boolean;
  locked?: boolean;
  sampleData?: SampleData;
}) {
  const row = index + 1;

  return (
    <Tr>
      <Td>
        <DatasetColumnSelect
          label={`Property column ${row}`}
          value={property.column_name ?? ""}
          onChange={(col) =>
            onChange({
              ...property,
              column_name: col,
              // if the name is empty, set it to the column name
              name: property.name ? property.name : col ? snakeCase(col) : null,
            })
          }
          detectedColumns={detectedColumns}
          sampleData={sampleData}
          analyticsName="custom-property-column"
          required={!property.locked}
        />
      </Td>
      <Td>
        <Input
          aria-label={`Property name ${row}`}
          value={property.name ?? ""}
          onChange={(event) =>
            onChange({ ...property, name: event.target.value })
          }
          disabled={disabled || locked}
          required
          analyticsName="custom-property-name"
          isRequired
        />
      </Td>
      <Td>
        <PropertyFormatSelect
          label={`Property format ${row}`}
          property={property}
          value={property.format ?? ""}
          onChange={(format) => onChange({ ...property, format })}
          detectedColumns={detectedColumns}
          analyticsName="custom-property-format"
        />
      </Td>
      <Td>
        <IconButton
          aria-label={`Remove property ${row}`}
          variant="ghost"
          onClick={() => onRemove(property)}
          isDisabled={locked || disabled}
        >
          {locked ? <Lock /> : <Trash />}
        </IconButton>
      </Td>
    </Tr>
  );
}
