import {
  InputGroup,
  InputProps,
  InputRightElement,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";

import { FdyReactSelect, FdySelectOption } from "../FdyReactSelect";
import { Input } from "../Input";
import { ConditionRow, ValueInputProps } from "./types";

function InputWithSuffix(
  props: InputProps & {
    suffix?: string;
  }
) {
  const { suffix, ...rest } = props;
  const input = <Input {...rest} />;
  if (suffix) {
    return (
      <InputGroup>
        {input}
        <InputRightElement>{suffix}</InputRightElement>
      </InputGroup>
    );
  }

  return input;
}

/**
 * Renders the input for a builder condition row.
 *
 * Depending on the operator, a different input is rendered:
 *
 * - List operators (e.g. `in`) render a multi-select if given a picklist.
 *   Otherwise render a text input for entering comma separated values.
 * - String operators (e.g. `eq`) render a text input.
 * - Number operators (e.g. `gt`) render a number input.
 * - If the operator is `null`, no input is rendered.
 */
export function ConditionsBuilderValueInput({
  id,
  condition,
  onChange,
  largeCsvInput,
  picklist,
  options,
  unit,
  min,
  max,
  step,
  virtualized,
}: ValueInputProps) {
  function updateConditionValue(value: ConditionRow["value"]) {
    onChange((prev) =>
      prev.map((c) => {
        if (c.operator === condition.operator) {
          c.value = value;
          return c;
        }
        return c;
      })
    );
  }

  function onMultiSelectChange(values: readonly FdySelectOption[]) {
    updateConditionValue(values.length ? values.map((v) => v.value) : null);
  }

  function onCsvChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const items = e.target.value.split(",");
    updateConditionValue(items.length ? items : null);
  }

  function onNumericChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const numOrNull = value === "" ? null : parseFloat(value);
    updateConditionValue(numOrNull);
  }

  function onTextChange(e: ChangeEvent<HTMLInputElement>): void {
    updateConditionValue(e.target.value);
  }

  function getNumericSuffix() {
    switch (condition.operator) {
      case "gt":
      case "gte":
        return "min";
      case "lt":
      case "lte":
        return "max";
    }
  }

  if (
    options?.length &&
    (condition.value === null || typeof condition.value === "string")
  ) {
    return (
      <Select
        id={id}
        value={String(condition.value ?? "")}
        onChange={(e) => updateConditionValue(e.target.value)}
      >
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
    );
  }

  if (condition.type === "list" && picklist?.length) {
    const selectedItems =
      condition.value?.map((v) => ({ label: v, value: v })) ?? [];

    return (
      <FdyReactSelect<FdySelectOption, true>
        inputId={id}
        isMulti
        options={picklist}
        value={selectedItems}
        onChange={onMultiSelectChange}
        virtualized={virtualized && picklist.length > 500}
        // TODO: this may be nicer to have set, but it's less clear what to do
        // when you are done selecting values and the condition builder dropdown
        // is over the 'next' button (like on cohort traits modal)

        // closeMenuOnSelect={false}
      />
    );
  }

  // TODO: should we use pattern prop to validate?
  if (condition.type === "list") {
    const props = {
      id,
      value: Array.isArray(condition.value) ? condition.value.join(",") : "",
      onChange: onCsvChange,
      autoFocus: true,
    };

    if (largeCsvInput) {
      return <Textarea {...props} />; // add unit??
    }

    return <InputWithSuffix {...props} suffix={unit} />;
  }

  // we don't need a ui for boolean because it's value should always be true
  if (condition.type === "boolean") {
    return null;
  }

  if (condition.type === "numeric") {
    const suffix = getNumericSuffix() + (unit ? ` ${unit}` : "");

    return (
      <InputWithSuffix
        id={id}
        type="number"
        value={condition.value ?? ""}
        onChange={onNumericChange}
        suffix={suffix}
        autoFocus
        min={min}
        max={max}
        step={step}
      />
    );
  }

  if (condition.type === "text") {
    return (
      <InputWithSuffix
        id={id}
        value={condition.value ?? ""}
        onChange={onTextChange}
        autoFocus
        suffix={unit}
      />
    );
  }

  // if user hasn't chosen an operator yet, return nothing
  return null;
}
