import { List, ListItem } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import { Dispatch, SetStateAction, useState } from "react";

import { ROUTE_NAMES } from "../../../../../constants/routeNames";
import { ActionableCardItem } from "../../../../ui/ActionableCardItem";
import { Button } from "../../../../ui/Button";
import { CardV2 } from "../../../../ui/Card/CardV2";
import { RouterLink } from "../../../../ui/RouterLink";
import { identitySetsContainClassic } from "../../sharedUtils";
import { ClassicExplanation } from "../shared/ClassicExplanation";
import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import {
  DatasetsIdentitySetsModal,
  IdentitySetState,
} from "./DatasetsIdentitySetsModal";
import { IdentityProperties } from "./DatasetsIdentitySetsPropertiesTable";
import { propertiesFromWire, propertiesToWire } from "./identitySetUtils";

export type IdentitySets = Record<string, IdentityProperties | null>;

export function DatasetsIdentitySetsCard({
  identitySets,
  setIdentitySets,
  detectedColumns,
  sampleData,
  managedDataset,
}: {
  identitySets: IdentitySets;
  setIdentitySets: Dispatch<SetStateAction<IdentitySets>>;
  detectedColumns: DetectedColumn[];
  managedDataset: boolean;
  sampleData?: SampleData;
}) {
  const [editingIdentitySet, setEditingIdentitySet] =
    useState<IdentitySetState>();

  function handleIdentitySetFinish(identitySetState: IdentitySetState) {
    if (!editingIdentitySet)
      throw new Error("editingIdentitySet is undefined somehow");

    setIdentitySets((state) => ({
      ...state,
      // ensure previous name is nulled out in case it was renamed
      [editingIdentitySet.name.trim()]: null,
      [identitySetState.name.trim()]: propertiesToWire(
        identitySetState.properties
      ),
    }));

    setEditingIdentitySet(undefined);
  }

  function handleAddIdentitySet() {
    setEditingIdentitySet({
      name: Object.keys(identitySets).length ? "" : "default",
      properties: {},
    });
  }

  // if a dataset's connection is classic, no id sets can be edited
  // best way to figure out if it's classic is to check if the returned idset's fields are marked "classic"
  const classic = identitySetsContainClassic(identitySets);

  return (
    <CardV2
      title="Identity"
      suffix="Recommended"
      text={
        <>
          Identity sets show Faraday how to identify people in your data. With
          this information, you can create{" "}
          <RouterLink routeName={ROUTE_NAMES.COHORTS}>cohorts</RouterLink> of
          your customers (or anyone else identified in your data) and{" "}
          <RouterLink routeName={ROUTE_NAMES.OUTCOMES}>outcome</RouterLink>{" "}
          models to predict things about these individuals.{" "}
          <strong>Identity sets are almost always required. </strong>
        </>
      }
    >
      {classic ? <ClassicExplanation resourceType="identity set" /> : null}

      <List>
        {Object.keys(identitySets).map((idSetName) => {
          // deleted identity set
          const idSet = identitySets[idSetName];
          if (!idSet) return;

          const classic = idSet.email === "classic";

          return (
            <ListItem key={idSetName} mb={4}>
              <ActionableCardItem
                menuBtnLabel={`${idSetName} options`}
                actionsDisabled={classic}
                title={idSetName}
                onEdit={() => {
                  setEditingIdentitySet({
                    name: idSetName,
                    properties: propertiesFromWire(idSet),
                    classic,
                  });
                }}
                onDelete={() => {
                  setIdentitySets({
                    ...identitySets,
                    [idSetName]: null,
                  });
                }}
              />
            </ListItem>
          );
        })}
      </List>

      {managedDataset || classic ? null : (
        <Button
          width="100%"
          variant="secondary"
          leftIcon={<Plus weight="bold" />}
          onClick={handleAddIdentitySet}
          data-testid="idset_add"
          analyticsName="add-identity-set"
        >
          Add identity set
        </Button>
      )}

      {editingIdentitySet && (
        <DatasetsIdentitySetsModal
          initialState={editingIdentitySet}
          detectedColumns={detectedColumns}
          managedDataset={managedDataset}
          sampleData={sampleData}
          onFinish={handleIdentitySetFinish}
          onClose={() => setEditingIdentitySet(undefined)}
        />
      )}
    </CardV2>
  );
}
