import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { RecommendersEditPage } from "./RecommendersEditPage";
import { RecommendersListPage } from "./RecommendersListPage";
import { RecommendersNewPage } from "./RecommendersNewPage";
import { RecommendersShowPage } from "./RecommendersShowPage";

export function Recommenders() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.RECOMMENDERS]: RecommendersListPage,
    [ROUTE_NAMES.RECOMMENDERS_NEW]: RecommendersNewPage,
    [ROUTE_NAMES.RECOMMENDERS_SHOW]: RecommendersShowPage,
    [ROUTE_NAMES.RECOMMENDERS_EDIT]: RecommendersEditPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.RECOMMENDERS} showToast />
  );

  return <Page />;
}
