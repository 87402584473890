import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { AccountSetup } from "./AccountSetup";

export function Onboarding() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.ONBOARDING]: AccountSetup,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.ONBOARDING} />
  );

  return <Page />;
}
