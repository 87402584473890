import { Text } from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { Button } from "../../ui/Button";
import { InlineButtons } from "../../ui/InlineButtons";
import { ModalV2 } from "../../ui/ModalV2";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { RouterLink } from "../../ui/RouterLink";
import { DatasetsTableQuery_datasets } from "../__generated__/DatasetsTableQuery";

// use a new type instead of DatasetShowPageQuery_dataset_options_DatasetOptionsMerge_merge
// because the values can *temporarily* be null
export type DatasetMergeFormat = {
  datasetId: string | null;
  joinColumn: string | null;
  __typename?: string;
} | null;

export function needToChangeMergeInput(
  merge: DatasetMergeFormat[],
  existingDatasets: DatasetsTableQuery_datasets[]
) {
  const invalidDatasets: Record<
    string,
    Record<string, string | undefined>
  > = {};
  const mergeFiltered = merge.filter(
    (config) => config?.datasetId && config.joinColumn
  );
  // filter because mergeConfig's datsetId and joinColumn can be null while editing
  mergeFiltered.map((mergeConfig) => {
    const dataset = existingDatasets.find(
      (d) => d.id === mergeConfig?.datasetId
    );
    // shouldn't get here, but just in case, throw an error
    if (!dataset)
      throw new Error(`dataset ${mergeConfig?.datasetId} does not exist`);
    const isInvalid = {
      identitySet:
        Object.keys(dataset.identitySets).length > 0
          ? "Identity set(s) will be moved to the merge dataset"
          : undefined,
      outputToStreams:
        Object.keys(dataset.outputToStreams || {}).length > 0
          ? "Event(s) will be moved to the merge dataset"
          : undefined,
      outputToTraits:
        Object.keys(dataset.outputToTraits || {}).length > 0
          ? "Trait(s) will be moved to the merge dataset"
          : undefined,
      preview: dataset.preview ? "The input dataset will be rerun" : undefined,
    };
    if (Object.values(isInvalid).some((val) => val)) {
      invalidDatasets[dataset.id] = isInvalid;
    }
  });
  return invalidDatasets;
}

export function DatasetMergeChangeConfigWarning({
  isOpen,
  onSave,
  onCancel,
  mergeConfig,
  existingDatasets,
  error,
  loading,
}: {
  isOpen: boolean;
  onSave: () => void;
  onCancel: () => void;
  mergeConfig: DatasetMergeFormat[];
  existingDatasets: DatasetsTableQuery_datasets[];
  error: boolean;
  loading: boolean;
}) {
  const whatNeedsToChange = needToChangeMergeInput(
    mergeConfig,
    existingDatasets
  );

  return (
    <ModalV2
      isOpen={isOpen}
      onClose={onCancel}
      title="Move config to merge dataset"
      footer={
        <InlineButtons>
          <Button variant="tertiary" onClick={onCancel} analyticsName="cancel">
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={onSave}
            isLoading={loading}
            isDisabled={loading || error}
            analyticsName="confirm"
          >
            Save dataset
          </Button>
        </InlineButtons>
      }
      analyticsStackName="merge-dataset-warning-modal"
    >
      <Text fontSize="fdy_lg" mb={4} color="fdy_red.500">
        To build the merge dataset, the following changes will be made. Is that
        ok? (This action cannot be automatically undone, so ensure you have the
        correct datasets selected.)
      </Text>
      <ul>
        {Object.keys(whatNeedsToChange).map((datasetId) => {
          return (
            <li key={datasetId} style={{ marginTop: 8 }}>
              <RouterLink
                routeName={ROUTE_NAMES.DATASETS_DEFINITION}
                params={{ id: datasetId }}
              >
                <ResourceIcon.datasets />
                <Text as="span" ml={2}>
                  {existingDatasets.find((d) => d.id === datasetId)?.name ||
                    datasetId}{" "}
                  - Dataset
                </Text>
              </RouterLink>
              <ul>
                {Object.keys(whatNeedsToChange[datasetId]).map((changeType) => {
                  return (
                    <li key={`${datasetId}_${changeType}`}>
                      {whatNeedsToChange[datasetId][changeType]}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </ModalV2>
  );
}
