import { gql } from "@apollo/client";
import { VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useError } from "react-use";

import { useToken } from "../../../ui/AuthProvider/useToken";
import { Button } from "../../../ui/Button";
import { CardV2 } from "../../../ui/Card/CardV2";
import { DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv } from "../../__generated__/DatasetShowPageQuery";
import {
  DatasetUploadDropZone,
  UploadState,
} from "../../shared/DatasetUploadDropZone";
import { useCsvUploader } from "../../shared/useCsvUploader";
import { DatasetsDataCsvFilesTable } from "./DatasetsDataCsvFilesTable";

export const uploadedFileQuery = gql`
  query UploadedFileQuery {
    uploads {
      createdAt
      fileSize
      subpath
    }
  }
`;

export function DatasetDataTabHosted({
  datasetOptions,
}: {
  datasetOptions: DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv;
}) {
  const authToken = useToken();
  const dispatchError = useError();
  const { uploadState, upload } = useCsvUploader();
  const [fileList, setFileList] = useState<FileList>();

  async function handleFileDropzoneChange(fileList: FileList | null) {
    if (fileList) setFileList(fileList);
    // else - the upload failed. The upload dropzone displays the error, so no need to do anything here
  }

  async function onSubmit() {
    if (!fileList || !fileList[0] || !authToken) return;
    try {
      await upload({
        fileList,
        authToken,
        uploadDirectory: datasetOptions.uploadDirectory,
      });
      setFileList(undefined);
    } catch (err) {
      if (err instanceof Error) {
        dispatchError(err as Error);
      } else {
        throw err;
      }
    }
  }

  return (
    <VStack alignItems="stretch" spacing={8}>
      <CardV2
        title="Upload more data"
        text="CSV files will need to match the same format used in previous uploads"
      >
        <DatasetUploadDropZone
          onFilesChange={handleFileDropzoneChange}
          uploadStatus={uploadState}
        />
        <Button
          variant="secondary"
          isLoading={uploadState === UploadState.uploading}
          loadingText="Uploading..."
          disabled={!fileList?.length}
          onClick={onSubmit}
          width="100%"
          analyticsName="upload-csv-file"
        >
          Upload
        </Button>
      </CardV2>

      <CardV2
        title="Files in dataset"
        text="Manage your files currently used in this dataset"
      >
        <DatasetsDataCsvFilesTable
          options={datasetOptions}
          uploadState={uploadState}
        />
      </CardV2>
    </VStack>
  );
}
