import { MouseEvent, useCallback, useEffect, useState } from "react";

export interface HubSpotConversationsHook {
  /** Whether HubSpotConversations is available.  */
  enabled: boolean;
  /** Open the HubSpotConversations chat widget. */
  open: (event?: MouseEvent) => void;
}

/**
 * HubSpotConversations should not be enabled for hipaa accounts,
 * so though the global typedef claims it is on window, it may not be.
 *
 * Use this hook to check if it's available and for opening the chat.
 */
export function useHubSpotConversations(): HubSpotConversationsHook {
  const [enabled, setEnabled] = useState(false);

  const open = useCallback(
    (event?: MouseEvent) => {
      event?.preventDefault();
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.open();
      } else {
        console.warn("HubSpotConversations not available");
      }
    },
    [enabled]
  );

  const onConversationsReady = useCallback(() => {
    setEnabled(true);
  }, []);

  useEffect(() => {
    if (window.HubSpotConversations) {
      onConversationsReady();
    } else {
      window.hsConversationsOnReady = [onConversationsReady];
    }
  }, []);

  return {
    enabled,
    open,
  };
}

// Types sourced from this support response:
// https://community.hubspot.com/t5/CMS-Development/Embed-Chat-Into-A-ReactJS-SPA/m-p/275754
// Recommended implementation for React: https://gist.github.com/zebapy/1a4255df5fa7489c8c36a2b0f1e82bb7
// See their SDK docs for more info: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
declare global {
  interface Window {
    hsConversationsSettings?: Record<string, unknown>;
    hsConversationsOnReady?: Array<() => void>;
    HubSpotConversations?: {
      on: unknown;
      off: unknown;
      widget: {
        status: () => { loaded: boolean; pending: boolean };
        load: (params?: { widgetOpen: boolean }) => void;
        remove: () => void;
        open: () => void;
        close: () => void;
        refresh: (openToNewThread?: boolean) => void;
      };
    };
  }
}
