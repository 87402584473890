import { DataMap, Stream } from "@fdy/faraday-js";
import uniqueId from "lodash/uniqueId";

import { EventProperty } from "./DatasetsEventsModal";
import { DatasetEventDataMap } from "./PropertiesTableRow";

// blessed fields are fields that have special meaning in the backend
function isBlessedField(property: string) {
  return property === "datetime" || property === "value";
}

// event streams can receive properties from multiple datasets
// properties that come from a dataset other than this one are "locked"
// meaning they can't be deleted (unless you also delete it on the other dataset)
// but you can set a column / format
function isLockedField({
  property,
  stream,
  properties,
  datasetId,
}: {
  property: string;
  stream: Stream | undefined;
  properties: DatasetEventDataMap;
  datasetId?: string;
}): boolean {
  if (properties[property] === null) return false; // the property was deleted

  const streamProperties = stream?.properties;

  if (!streamProperties) return false;

  const streamPropertyDetails = streamProperties[property] as
    | { emitted_by_datasets?: { dataset_id: string }[] }
    | undefined;

  if (!streamPropertyDetails || !streamProperties.emitted_by_datasets)
    return false;

  // This field is set by another dataset, so it is locked.
  return (
    streamPropertyDetails.emitted_by_datasets?.some(
      (p) => p.dataset_id !== datasetId
    ) ?? false
  );
}

export const generateUniquePropertyId = () => uniqueId();

/**
 * Convert the stream properties and dataset properties to the format expected by the UI
 */
export function outputToStreamDatamapFromWire({
  properties,
  stream,
  datasetId,
}: {
  properties: DatasetEventDataMap;
  stream?: Stream;
  datasetId?: string;
}): EventProperty[] {
  const streamProperties: EventProperty[] = [];
  const streamProps = stream?.properties ?? {};
  for (const name in streamProps) {
    const details = streamProps[name];
    if (details?.emitted_by_datasets?.some((p) => p.dataset_id === datasetId)) {
      continue;
    }

    streamProperties.push({
      id: generateUniquePropertyId(),
      name,
      column_name: null,
      format: null,
      blessed: isBlessedField(name),
      locked: true,
    });
  }

  const customProperties: EventProperty[] = [];
  for (const name in properties) {
    const datamapCol = properties[name];

    if (!datamapCol) continue;

    customProperties.push({
      id: generateUniquePropertyId(),
      name,
      column_name: datamapCol.column_name,
      format: datamapCol.format,
      locked: isLockedField({
        property: name,
        stream,
        properties,
        datasetId,
      }),
      blessed: isBlessedField(name),
    });
  }

  // Inject blessed fields that may not be in the existing properties
  // This is since the controls are always shown. We'll remove them if user doesn't set them anyway.
  const blessedFields = ["datetime", "value"];
  for (const name of blessedFields) {
    if (!customProperties.find((p) => p.name === name)) {
      customProperties.push({
        id: generateUniquePropertyId(),
        name,
        column_name: null,
        format: null,
        locked: false,
        blessed: true,
      });
    }
  }

  return [...streamProperties, ...customProperties];
}

/**
 * Convert the UI properties to the format expected by the API
 */
export function outputToStreamDatamapToWire(
  properties: EventProperty[],
  initialProperties?: DatasetEventDataMap
): DataMap {
  const updatedProperties: DataMap = {};
  for (const property of properties) {
    if (!property.name || !property.column_name) continue;
    updatedProperties[property.name] = {
      column_name: property.column_name,
      format: property.format,
    };
  }

  // Add properties from initialProperties that are no longer in properties array
  if (initialProperties) {
    for (const name of Object.keys(initialProperties)) {
      if (!Object.prototype.hasOwnProperty.call(updatedProperties, name)) {
        // convert empty properties to null to delete them when merge patching
        updatedProperties[name] = null;
      }
    }
  }

  return updatedProperties;
}
