import { useEffect, useState } from "react";
import { createRouter, Options, Route, Router, State } from "router5";
import browserPlugin from "router5-plugin-browser";
import listenersPlugin from "router5-plugin-listeners";

import { Analytics } from "../../../services/analytics/Analytics";
import { ApolloGQLService } from "../../../services/ApolloGQLService";
import { AuthService } from "../../../services/authService";
import {
  redirectIfUnauthorizedMiddleware,
  redirectMiddleware,
} from "./middlewares";

interface Router5Dependencies {
  auth: AuthService;
  gqlClient: ApolloGQLService;
  analytics: Analytics;
}

/**
 * This hook creates a potted Router5 instance that will get added to the
 * React context. This allows a subtree of the app to be managed by
 * Router5 while at the top level we can start using React Router. This
 * approach was taken because the Router5 routes are tightly coupled to
 * our authentication logic, while the new routes we need in popsicle need
 * to be available to non-authenticated users.
 *
 * @param routes
 * @param config
 * @param dependencies
 */
export function useRouter5Router(
  routes: Route[],
  config: Partial<Options>,
  { auth, gqlClient, analytics }: Router5Dependencies
): Router<Router5Dependencies> | null {
  const [router, setRouter] = useState<Router<Router5Dependencies> | null>(
    null
  );

  useEffect(() => {
    const routeChangeListener = (toState: State, fromState: State) => {
      // If no analytics instance, do nothing
      if (!analytics) return;

      // If the route change is a "reload", do not log
      if (toState === fromState) return;

      analytics.trackPage(toState);
    };

    const router = createRouter<Router5Dependencies>(routes, config);
    router.useMiddleware(redirectMiddleware(routes));
    router.useMiddleware(redirectIfUnauthorizedMiddleware(routes));
    router.usePlugin(listenersPlugin());
    router.usePlugin(browserPlugin());

    // TODO: The type declaration for `addListener` doesn't match the docs.
    // Re-check after the next router upgrade.
    //
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    router.addListener(routeChangeListener);

    router.setDependencies({ auth, gqlClient, analytics });

    const loginHandler = ({ route }: { route?: string }) => {
      // This callback is invoked if we have gone out to auth0 for a new session
      // and then come back to popsicle. The `route` provided as the argument to
      // this callback will be the route the user was on before they
      // authenticated. Note that this route might be undefined in the case of
      // embedded non-auth0 auth providers.
      if (!route) return;

      // The route _should_ always match something in the router5 routing table
      // but if it doesn't just ignore it.
      const match = router.matchUrl(route);
      if (!match) return;

      const { name, params } = match;

      // Fix for a race condition: by this moment, the router has probably
      // already started and is figuring out how to navigate to the default
      // application route. Cancel that, we don't want it. Then navigate to our
      // saved route, after parsing it into a route-by-name and params.
      router.cancel();
      router.navigate(name, params, { replace: true });
    };

    auth.addEventListener("login", loginHandler);

    // keep track of the path specified in the browser URL
    const startingPath = window.location.pathname + window.location.search;
    router.start(() => {
      // if the path specified in the initial URL is a valid route, go there
      const state = router.matchPath(startingPath);
      // `router.navigate` will call our middleware, so users will be
      // brought to an appropriate route if they try to go somewhere they are
      // not authorized
      if (state) router.navigate(state.name, state.params);

      setRouter(router);
    });

    return () => {
      // TODO: The type declaration for `removeListener` doesn't match the docs.
      // Re-check after the next router upgrade.
      //
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      router.removeListener(routeChangeListener);
      auth.removeEventListener("login", loginHandler);
      router.stop();
      setRouter(null);
    };
  }, [routes, auth, gqlClient, analytics]);

  return router;
}
