import { Td } from "@chakra-ui/react";
import { Suspense } from "react";
import * as React from "react";

import { AnimatedZapLogo } from "../AnimatedZapLogo";
import { Blankslate, BlankslateProps } from "../Blankslate";
import { ErrorBoundary, ErrorPage } from "../ErrorBoundary";
import { ErrorPageProps } from "../ErrorBoundary/ErrorPage";

/**
 * Render a table row with a blank slate inside.
 *
 * Ensure the cell fills the entire width of the table by using
 * a `colspan` attribute with a high number.
 */
export function TableBlankslate(props: BlankslateProps) {
  return (
    <tr>
      <Td colSpan={9999} align="center">
        <Blankslate {...props} />
      </Td>
    </tr>
  );
}

/**
 * Render a table row with a loading indicator inside.
 *
 * Ensure the cell fills the entire width of the table by using
 * a `colspan` attribute with a high number.
 */
export function TableLoading() {
  return (
    <tr>
      <Td colSpan={9999} align="center">
        <AnimatedZapLogo />
      </Td>
    </tr>
  );
}

export function TableError(props: ErrorPageProps) {
  return (
    <tr>
      <Td colSpan={999} align="center" p="4rem">
        <ErrorPage {...props} />
      </Td>
    </tr>
  );
}

/**
 * Some table contents can throw loading or errors to suspense or error
 * boundaries respectively, so wrap their contents in this component.
 *
 * @example
 * <Table>
 *   <Thead>...</Thead>
 *   <Tbody>
 *     <TableBodyBoundaries>
 *       <ResourceTableList />
 *     </TableBodyBoundaries>
 *   </Tbody>
 * </Table>
 */
export function TableBodyBoundaries({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Suspense fallback={<TableLoading />}>
      <ErrorBoundary fallback={(props) => <TableError {...props} />}>
        {children}
      </ErrorBoundary>
    </Suspense>
  );
}
