import { ChangeEvent } from "react";
import * as React from "react";

import { CardV2 } from "../../ui/Card/CardV2";
import { FormField } from "../../ui/FormField";
import { Input } from "../../ui/Input";
import { PipelineFormState } from "./PipelineForm";
import { pipelineFormIds, pipelineFormLabels } from "./pipelineFormUtils";
import { ResetScopeError, ScopeErrors } from "./usePipelineValidator";

interface PipelineFormNameCardProps {
  formState: PipelineFormState;
  setFormState: React.Dispatch<React.SetStateAction<PipelineFormState>>;
  errors: ScopeErrors | undefined;
  resetError: ResetScopeError;
}

export function PipelineFormNameCard({
  formState,
  setFormState,
  errors,
  resetError,
}: PipelineFormNameCardProps) {
  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.value;
    setFormState((state) => ({
      ...state,
      name,
    }));
    resetError("name");
  }

  return (
    <CardV2>
      <FormField
        htmlFor={pipelineFormIds.name}
        label={pipelineFormLabels.name}
        helpText={
          <>
            A strong pipeline name is descriptive of its intent, and might be a
            summary of the population and payload, such as "Customer engagement
            for credit card users with personas."
          </>
        }
        error={errors?.name}
      >
        <Input
          id={pipelineFormIds.name}
          value={formState.name}
          onChange={handleNameChange}
          autoComplete="off"
          analyticsName="name"
        />
      </FormField>
    </CardV2>
  );
}
