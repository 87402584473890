import { Resource, ResourceStatus } from "@fdy/faraday-js";

import { ResourceStatus as GqlResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { gqlTypenameToResourceType } from "../../../constants/gqlTypenameToResourceType";

// several cases around the app we don't have these timestamps, so ignore them for now, just to make implementing this simpler.
export type ResourceWithoutTimestamps = Omit<
  Resource,
  "created_at" | "updated_at"
>;

export type MixedResource = ResourceWithoutTimestamps | GqlResource;

export type GqlResource = {
  __typename: string;
  id: string;
  status: GqlResourceStatus;
  statusError: string | null;
  lastUpdatedConfigAt: string | null;
  lastUpdatedOutputAt: string | null;
  lastReadInputAt: string | null;
  archivedAt: string | null;
};

/**
 * Given either a resource (from Faraday-JS, plain REST shape) or a GqlResource (GraphQL shape),
 * return the resource in the REST shape.
 */
export function normalizeResourceDetails(
  resource: MixedResource
): ResourceWithoutTimestamps {
  if ("__typename" in resource) {
    return {
      id: resource.id,
      resource_type: gqlTypenameToResourceType[resource.__typename],
      status: resource.status.toLowerCase() as ResourceStatus,
      status_error: resource.statusError ?? undefined,
      last_updated_config_at: resource.lastUpdatedConfigAt ?? undefined,
      last_updated_output_at: resource.lastUpdatedOutputAt ?? undefined,
      last_read_input_at: resource.lastReadInputAt ?? undefined,
      archived_at: resource.archivedAt ?? undefined,
    };
  }

  return resource;
}
