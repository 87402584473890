import { gql, MutationHookOptions } from "@apollo/client";

import { BiasMitigationInput } from "../../__generated__/sojournerGlobalTypes";
import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { components } from "../../sojourner-oas-types";
import {
  UpdateOutcomeMutationHook,
  UpdateOutcomeMutationHookVariables,
} from "./__generated__/UpdateOutcomeMutationHook";
import { outcomeFragment } from "./outcomeFragment";

type OutcomeMergePatch = components["schemas"]["OutcomeMergePatch"];
type OutcomePredictors = components["schemas"]["OutcomePredictorsMergePatch"];

export type OutcomeFormState = {
  name: string;
  attainment_cohort_id: string;
  eligible_cohort_id: string;
  attrition_cohort_id: string;
  feature_blocklist: string[];
  predictors: OutcomePredictors | null;
  bias_mitigation: BiasMitigationInput | null;
};

export const UPDATE_OUTCOME = gql`
  mutation UpdateOutcomeMutationHook($id: ID!, $outcome: String!) {
    updateOutcome(applicationJsonMergePatchInput: $outcome, outcomeId: $id) {
      ...OutcomeFragment
    }
  }
  ${outcomeFragment}
`;

export function useUpdateOutcome(
  options?: MutationHookOptions<
    UpdateOutcomeMutationHook,
    UpdateOutcomeMutationHookVariables
  >
) {
  const [mutate, { loading: updating }] = useSojournerMutation<
    UpdateOutcomeMutationHook,
    UpdateOutcomeMutationHookVariables
  >(UPDATE_OUTCOME, options);

  function updateOutcome(id: string, input: OutcomeMergePatch) {
    return mutate({
      variables: {
        id,
        outcome: JSON.stringify(input),
      },
    });
  }

  return {
    updateOutcome,
    updating,
  };
}
