import { PrimitiveDataType } from "../../../../__generated__/sojournerGlobalTypes";
import { Trait } from "../../../../hooks/useTraitsQuery";
import { conditionToSummary } from "../../../../utils/conditionToSummary";
import { titleCase } from "../../../../utils/formatters";
import { booleanTraitFromWire } from "../CohortFilterEditor/CohortFilterEditorBoolean";
import { TraitCondition } from "../CohortForm";
import { CohortSummaryTagsSection } from "../CohortSummaryTagsSection";
import { traitConditionToSummary } from "./utils";

export function CohortTraitSummary({
  condition,
  trait,
}: {
  condition: Omit<TraitCondition, "name">;
  trait?: Trait | undefined;
}) {
  const c = condition;
  let result: string[] = [];

  // special case for boolean traits
  // the rest of the traits user similar conditions widgets
  if (trait?.type === PrimitiveDataType.BOOLEAN) {
    const val = booleanTraitFromWire(c);
    result.push(val?.map((v) => titleCase(v.toLowerCase())).join(" or "));
  } else {
    result = [
      ...result,
      ...(trait ? traitConditionToSummary(trait, c) : conditionToSummary(c)),
    ];
  }

  if (c.optional) {
    result.push("Optional");
  }

  return <CohortSummaryTagsSection tags={result} />;
}
