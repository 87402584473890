import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef } from "react";

import { ResourceStatus } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { Link } from "../ui/Link";
import { RenameModal } from "../ui/RenameModal";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";
import { useUpdateCohort } from "./useUpdateCohort";

type ActionMenuOptions = {
  view?: boolean;
  rename?: boolean;
  api?: boolean;
  info?: () => void;
  duplicate?: boolean;
  delete?: boolean;
  archive?: boolean;
};

const defaultOptions = {
  view: false,
  rename: false,
  api: false,
  duplicate: false,
  delete: false,
  archive: false,
};

export function CohortActionsMenu({
  cohort,
  options,
  onDeleteComplete,
  buttonProps,
}: {
  cohort?: {
    id: string;
    name: string;
    status: ResourceStatus;
    archivedAt: string | null;
  };
  options?: ActionMenuOptions;
  onDeleteComplete?: () => void;
  buttonProps?: Pick<IconButtonProps, "my" | "variant">;
}) {
  const enabledOptions = { ...defaultOptions, ...options };
  const renameModalState = useDisclosure();
  const apiShortcutsModalProps = useDisclosure();
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const label = cohort ? `Toggle ${cohort.name} actions` : `Toggle actions`;

  const { updateCohort, updating } = useUpdateCohort({
    onCompleted() {
      renameModalState.onClose();
    },
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label={label}
          icon={<DotsThreeOutline weight="fill" />}
          variant="secondary"
          color="fdy_gray.600"
          size="sm"
          {...buttonProps}
        />
        <MenuList>
          {enabledOptions.info && (
            <MenuItem onClick={enabledOptions.info}>About cohorts</MenuItem>
          )}
          {enabledOptions.view && cohort && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.COHORTS_SHOW}
              params={{ cohort: cohort.id }}
            >
              View details / edit
            </MenuItem>
          )}
          {enabledOptions.duplicate && cohort && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.COHORT_NEW}
              params={{ duplicate: cohort.id }}
            >
              Duplicate
            </MenuItem>
          )}
          {enabledOptions.rename && (
            <MenuItem onClick={renameModalState.onOpen}>Rename</MenuItem>
          )}
          {enabledOptions.api && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API shortcuts
            </MenuItem>
          )}
          {enabledOptions.archive && cohort && (
            <ArchiveMenuButton
              name={cohort.name}
              resourceId={cohort.id}
              resourceType="cohorts"
              archivedAt={cohort.archivedAt}
              focusAfterCloseRef={menuBtnRef}
              status={cohort.status}
            />
          )}
          {enabledOptions.delete && cohort && (
            <ResourceDeleteButton
              name={cohort.name}
              resourceId={cohort.id}
              resourceType="cohorts"
              onDeleteComplete={onDeleteComplete}
              isMenuItem
              resourceStatus={cohort.status}
            />
          )}
        </MenuList>
      </Menu>

      {/* must be outside Menu otherwise pressing tab inside the Modal doesn't work */}

      {enabledOptions.rename && renameModalState.isOpen && cohort && (
        <RenameModal
          title="Rename cohort"
          defaultValue={cohort.name}
          onClose={renameModalState.onClose}
          updateFn={(name) => updateCohort(cohort.id, { name })}
          updating={updating}
          focusAfterCloseRef={menuBtnRef}
        />
      )}
      {enabledOptions.api && apiShortcutsModalProps.isOpen && cohort && (
        <ApiShortcutsModal
          resource={{ id: cohort.id, resource_type: ResourceType.Cohorts }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
