import { ROUTE_NAMES } from "../../../constants/routeNames";
import { CardV2 } from "../../ui/Card/CardV2";
import { SettingsLayout } from "../SettingsLayout";
import { FilesTable } from "./FilesTable";

export function FilesPage() {
  const title = "Files";
  return (
    <SettingsLayout
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.SETTINGS_FILES,
      }}
    >
      <CardV2 title={title}>
        <FilesTable />
      </CardV2>
    </SettingsLayout>
  );
}
