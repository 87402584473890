import { useApolloClient } from "@apollo/client";
import { PersonaMergePatch } from "@fdy/faraday-js";
import { useMutation } from "@tanstack/react-query";

import { useFdyClient } from "../../services/FdyClientProvider";
import { useSojournerApolloClient } from "../../services/sojournerApolloClient";
import { explorePersonasQuery } from "../explore/presets/ExplorePersonas";
import { PERSONA_SET_HOOK_QUERY } from "./usePersonaSet";

export function useUpdatePersona({
  personaSetId,
  personaId,
  onSuccess,
}: {
  personaSetId: string;
  personaId: string;
  onSuccess?: () => void;
}) {
  const fdyClient = useFdyClient();
  const kopengClient = useApolloClient();
  const sojoClient = useSojournerApolloClient();

  const updatePersonaMutation = useMutation({
    mutationFn: (data: PersonaMergePatch) =>
      fdyClient.personaSets.updatePersona(personaSetId, personaId, data),

    onSuccess: () => {
      // refetch at least 1 query for kopeng so cache is updated
      kopengClient.refetchQueries({
        include: [explorePersonasQuery],
      });

      // refetch at least 1 query for sojo so cache is updated
      sojoClient.refetchQueries({
        include: [PERSONA_SET_HOOK_QUERY],
      });

      onSuccess?.();
    },
  });

  return updatePersonaMutation;
}
