import {
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { Button } from "../../../../ui/Button";
import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import { EventProperty } from "./DatasetsEventsModal";
import { PropertiesTableRow } from "./PropertiesTableRow";

export function PropertiesTable({
  properties,
  detectedColumns,
  disabled,
  sampleData,
  onPropertyChange,
  onRemoveProperty,
  onAddProperty,
}: {
  properties: EventProperty[];
  onPropertyChange: (property: EventProperty) => void;
  onRemoveProperty: (property: EventProperty) => void;
  onAddProperty: () => void;
  detectedColumns: DetectedColumn[];
  disabled?: boolean;
  sampleData?: SampleData;
}) {
  return (
    <Table my={4} size="xs">
      <Thead>
        <Tr>
          <Th width="30%">Field in dataset</Th>
          <Th width="30%">Property name</Th>
          <Th width="30%">Format (optional)</Th>
          <Th width="10%">
            <VisuallyHidden>Delete</VisuallyHidden>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {properties.map((property, i) => (
          <PropertiesTableRow
            index={i}
            key={property.id}
            property={property}
            onChange={onPropertyChange}
            onRemove={onRemoveProperty}
            detectedColumns={detectedColumns}
            disabled={disabled}
            sampleData={sampleData}
            locked={property.locked}
          />
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={4} p={2} pl={4}>
            <Button
              variant="link"
              leftIcon={<Plus weight="bold" />}
              justifyContent="left"
              width="100%"
              disabled={disabled}
              onClick={onAddProperty}
              analyticsName="add-event-property"
            >
              Add property
            </Button>
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
}
