import { Box } from "@chakra-ui/react";
import { CaretDown } from "@phosphor-icons/react";
import { useMemo } from "react";

import { FEATURES_SHOWN_LIMIT } from "../../../../hooks/useProgressiveDisclosureItems";
import { assertArrayAndUnwrap } from "../../../../utils/assertions";
import { AccordionV2 } from "../../../ui/AccordionV2";
import { Button } from "../../../ui/Button";
import { OutcomeAnalysisFeature } from "../__generated__/OutcomeAnalysisFeature";
import { OutcomeDataCardFeatureAccordionItem } from "./OutcomeDataCardFeatureAccordionItem";

export interface OutcomeDataCardFeatureAccordionProps {
  features: (OutcomeAnalysisFeature | null)[];
  outcomeName: string;
  eligibleCohortName: string | null;
  shown: number;
  onShowMore: (shown: number) => void;
}

export function OutcomeDataCardFeatureAccordion({
  features,
  outcomeName,
  eligibleCohortName,
  shown,
  onShowMore,
}: OutcomeDataCardFeatureAccordionProps) {
  // cleaning ts types mostly
  const feats = assertArrayAndUnwrap(features);

  // Calculate max importance since we want percent charts to fill most of their area.
  // It's more helpful to read them relative to each other than to read the exact value.
  const maxImportance = useMemo(
    () => Math.max(...feats.map((f) => f.importance)),
    [feats]
  );

  const shownFeatures = useMemo(() => feats.slice(0, shown), [feats, shown]);

  const hasMoreToShow = shown < features.length;

  return (
    <>
      <AccordionV2 allowToggle>
        {shownFeatures.map((feature) => {
          return (
            <OutcomeDataCardFeatureAccordionItem
              key={feature.name}
              feature={feature}
              maxImportance={maxImportance}
              outcomeName={outcomeName}
              eligibleCohortName={eligibleCohortName}
            />
          );
        })}
      </AccordionV2>

      {hasMoreToShow && (
        <Box textAlign="center">
          <Button
            mt={4}
            variant="ghost"
            leftIcon={<CaretDown />}
            onClick={() => onShowMore(shown + FEATURES_SHOWN_LIMIT)}
            analyticsName="show-more-features"
          >
            Show more
          </Button>
        </Box>
      )}
    </>
  );
}
