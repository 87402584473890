import { ReactElement, useRef } from "react";

interface VendorLogoProps {
  name?: string | null;
  dimension?: number;
  path?: string | null;
  inline?: boolean;
}

export function VendorLogo({
  name,
  dimension,
  path,
  inline,
}: VendorLogoProps): ReactElement | null {
  const imgRef = useRef<HTMLImageElement>(null);
  const px = dimension ?? 32;

  if (!name && !path) return null;

  return (
    <img
      aria-hidden={!name}
      alt={name ? `Vendor logo for ${name}` : "Vendor logo"}
      ref={imgRef}
      src={
        path
          ? `/${path}`
          : `/images/vendors/${name === "adwords" ? "g_ads" : name}.svg`
      }
      height={px}
      width={px}
      onError={() =>
        imgRef.current ? (imgRef.current.style.visibility = "hidden") : ""
      }
      style={{
        display: inline ? "inline-block" : "block",
        verticalAlign: "middle",
        objectFit: "contain",
      }}
    />
  );
}
