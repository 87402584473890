import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { FormEvent, useRef, useState } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useUnsavedChangesWarning } from "../../hooks/useUnsavedChangesWarning";
import { ApiOptions } from "../../services/connectionOptions";
import {
  ConnectionTypeInfo,
  connectionTypeInfosByType,
  findConnectionTypeInfoById,
  findConnectionTypeInfoBySlug,
  getOptionsForConnectionType,
} from "../pipelines/connectionUtils";
import { Button } from "../ui/Button";
import { CardV2 } from "../ui/Card/CardV2";
import { Prose } from "../ui/Prose";
import { RadioTileOptions, RadioTiles } from "../ui/RadioTiles";
import { InputType, TextField } from "../ui/TextField";
import { VendorLogo } from "../ui/VendorLogo";

const heading_style = {
  fontSize: "fdy_lg",
  color: "fdy_gray.800",
  mb: 4,
};

/**
 * Errors we report to the user upon form submission, before we hit the API.
 */
export enum ConnectionFormError {
  typeMissing = "Please select a connection type",
  nameMissing = "Please enter a unique name for your connection",
  optionsMissing = "Please fill out all required fields",
}

// TODO: switch to faraday-js. See https://app.asana.com/0/1203313451389847/1205112852491125/f

export type ConnectionFormState = {
  name: string;
  options: ApiOptions;
};

type ConfigurableConnectionType = "replication" | "managed";

const configurableConnectionTypes: ConfigurableConnectionType[] = [
  "replication",
  "managed",
];

const connectionTypeFormInfo: Record<
  ConfigurableConnectionType,
  Record<"heading" | "description", string>
> = {
  replication: {
    heading: "Standard connections",
    description:
      "These connections to databases, data warehouses, and cloud file buckets are available to all Faraday users.",
  },
  managed: {
    heading: "Faraday-managed connections",
    description:
      "Faraday will manage these incoming and outgoing connections for you as part of your contract. Please contact your account manager if you want to add an additional managed connection to your plan.",
  },
};

// Don't include secret options in the form state since we don't want to send back <secret> in patch
function removeSecretsFromOptions(
  optionsState: ApiOptions = { type: "" },
  optionsInfo: ReturnType<typeof getOptionsForConnectionType>
): ApiOptions {
  const isSecretOption = (slug: string) =>
    optionsInfo.some((o) => o.slug === slug && o.secret);

  return Object.entries(optionsState).reduce((acc, [slug, value]) => {
    // skip secret options
    if (isSecretOption(slug)) return acc;
    acc[slug] = value;
    return acc;
  }, {} as ApiOptions);
}

type ConnectionFormProps = {
  defaultState?: ConnectionFormState;
  onSave: (arg: ConnectionFormState) => void;
  loading: boolean;
  disabled?: boolean;
  /**
   * Allow leaving secret fields empty, so users can update other options
   * without needing to know the original password.
   */
  allowEmptySecrets?: boolean;
};

export function ConnectionForm({
  defaultState,
  onSave,
  loading,
  disabled,
  allowEmptySecrets,
}: ConnectionFormProps) {
  const [name, setName] = useState<string | undefined>(defaultState?.name);

  const [connectionTypeInfo, setConnectionTypeInfo] = useState<
    ConnectionTypeInfo | undefined
  >(
    defaultState
      ? findConnectionTypeInfoBySlug(defaultState.options.type)
      : undefined
  );

  const connectionOptions = connectionTypeInfo
    ? getOptionsForConnectionType({
        connectionTypeId: connectionTypeInfo.id,
        resourceType: "connections",
      })
    : [];

  const [options, setOptions] = useState<ApiOptions | undefined>(() =>
    // this should only impact editing view when we don't want to send back '<secret>' in the patch
    removeSecretsFromOptions(defaultState?.options, connectionOptions)
  );
  const [formErrors, setFormErrors] = useState<ConnectionFormError[]>([]);
  const connectionTypeSelectionRef = useRef<null | HTMLDivElement>(null);

  //user warning message for unsaved changes
  const { setWarnBeforeNavigate } = useUnsavedChangesWarning({
    config: [name, connectionTypeInfo, options],
    route: ROUTE_NAMES.CONNECTIONS,
  });

  /**
   * When user selects a connection type, update state and scroll the
   * related options into view
   */
  function handleConnectionTypeChange(value: string) {
    const newConnectionTypeInfo = findConnectionTypeInfoById(value);
    setFormErrors([]);
    setConnectionTypeInfo(newConnectionTypeInfo);
    setOptions({
      type: newConnectionTypeInfo.slug,
    }); //remove options from previously selected connection type
    connectionTypeSelectionRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  // disallow editing connection type if it's set already e.g. we got it from queried data (defaultState)
  const canChangeConnectionType = !defaultState;

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const memo: ConnectionFormError[] = [];
    if (!connectionTypeInfo) {
      memo.push(ConnectionFormError.typeMissing);
    }
    if (!name?.trim()) {
      memo.push(ConnectionFormError.nameMissing);
    }
    if (!options) {
      memo.push(ConnectionFormError.optionsMissing);
    }
    if (memo.length) {
      setFormErrors(memo);
    } else if (name && options) {
      onSave({
        name,
        options,
      });
      setWarnBeforeNavigate(false);
    } else {
      throw Error("BUG: form error undetected.");
    }
  }

  const connectionTypeRadioOptions = configurableConnectionTypes.reduce(
    (acc, type) => {
      acc[type as ConfigurableConnectionType] = connectionTypeInfosByType(
        type
      ).map((conn) => ({
        value: conn.id,
        label: conn.literate,
        rightContent:
          <VendorLogo path={conn.logo_url} dimension={24} /> ?? undefined,
        description:
          conn.new_connection_description ??
          conn.description ??
          "Share access with Faraday.",
        analyticsName: `${conn.slug}`,
      }));
      return acc;
    },
    {} as Record<ConfigurableConnectionType, RadioTileOptions[]>
  );

  // turn off autocomplete because username or password fields shouldn't be confused with others saved on the site
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Box display="grid" gap={6}>
        <CardV2
          title={`${defaultState ? "Edit your" : "Create a"} connection`}
          text="Connections are configuration for connecting data between Faraday and an external location."
        >
          {canChangeConnectionType ? (
            <Box>
              <FormControl
                isInvalid={formErrors.some(
                  (e) => e === ConnectionFormError.typeMissing
                )}
              >
                {configurableConnectionTypes.map((type) => (
                  <Box key={type} mb={8}>
                    <Heading sx={heading_style}>
                      {connectionTypeFormInfo[type]["heading"]}
                    </Heading>
                    <Text color="fdy_gray.800">
                      {connectionTypeFormInfo[type]["description"]}
                    </Text>
                    <RadioTiles
                      name={`${type}_connection_id`}
                      options={connectionTypeRadioOptions[type].sort((a, b) =>
                        a.label.localeCompare(b.label)
                      )}
                      onChange={handleConnectionTypeChange}
                      selected={connectionTypeInfo?.id}
                    />
                  </Box>
                ))}
                <FormErrorMessage>
                  {formErrors.find(
                    (e) => e === ConnectionFormError.typeMissing
                  )}
                </FormErrorMessage>
              </FormControl>
            </Box>
          ) : null}

          <Divider />
          <div ref={connectionTypeSelectionRef}>
            {connectionTypeInfo && options && (
              <ConnectionTypeForm
                connectionTypeInfo={connectionTypeInfo}
                options={options}
                setOptions={setOptions}
                error={formErrors.find(
                  (e) => e === ConnectionFormError.optionsMissing
                )}
                allowEmptySecrets={allowEmptySecrets}
              />
            )}
          </div>
        </CardV2>

        <CardV2>
          <TextField
            label="Name"
            name="connectionName"
            hint="Choose a name for this connection."
            type={InputType.text}
            required
            value={name ?? ""}
            error={formErrors.find(
              (e) => e === ConnectionFormError.nameMissing
            )}
            onChange={(name) => {
              setName(name);
              if (name) {
                setFormErrors(
                  formErrors.filter(
                    (e) => e !== ConnectionFormError.nameMissing
                  )
                );
              }
            }}
            analyticsName="name"
          />
        </CardV2>

        <Button
          type="submit"
          loadingText="Saving connection..."
          width="100%"
          disabled={disabled || loading}
          isLoading={loading}
          size="lg"
          analyticsName="save"
        >
          Save connection
        </Button>
      </Box>
    </form>
  );
}

export function ConnectionTypeForm({
  connectionTypeInfo,
  options,
  setOptions,
  error,
  allowEmptySecrets,
}: {
  connectionTypeInfo: ConnectionTypeInfo;
  options: ApiOptions;
  setOptions: (options: ApiOptions) => void;
  error?: string;
  allowEmptySecrets?: boolean;
}) {
  const connectionOptions = getOptionsForConnectionType({
    connectionTypeId: connectionTypeInfo.id,
    resourceType: "connections",
  });

  const inputTypeMap: Record<string, string> = {
    text: "text",
    integer: "number",
  };

  return (
    <Box>
      <Heading sx={heading_style}>
        <Text as="span" fontWeight="normal">
          Step 1:
        </Text>
        {"  "}
        Configure your {connectionTypeInfo.literate} instance
      </Heading>

      {connectionTypeInfo.type === "replication" ? (
        <Text>
          Please see{" "}
          <Link
            isExternal
            href={`https://faraday.ai/docs/connections/${connectionTypeInfo.slug}`}
          >
            setup instructions
          </Link>
        </Text>
      ) : (
        <Prose>
          <div
            // fdyugc: Faraday developers own setup instructions in vannevar
            dangerouslySetInnerHTML={{
              __html: connectionTypeInfo.setup_instructions,
            }}
          />
        </Prose>
      )}

      <Divider />

      <Heading sx={heading_style}>
        <Text as="span" fontWeight="normal">
          Step 2:
        </Text>
        {"  "}
        Enter your connection parameters
      </Heading>

      <FormErrorMessage>{error}</FormErrorMessage>

      {connectionOptions.map((option) => (
        <TextField
          key={option.id}
          label={option.literate}
          hint={
            // Some help text contains html links, so need to dangerouslySetInnerHTML to render them.
            // We should own the help text documents, so it should be safe.
            <Text dangerouslySetInnerHTML={{ __html: option.help ?? "" }} />
          }
          required={
            allowEmptySecrets && option.secret ? false : option.required
          }
          pattern={option.validation_regex}
          name={option.slug}
          dataType={option.data_type}
          type={
            option.secret
              ? InputType.password
              : (inputTypeMap[option.data_type] as unknown as InputType)
          }
          title={`Please match the following format ${option.validation_regex}`}
          value={String(option.secret ? "" : options[option.slug] || "")}
          onChange={(value) => {
            const temp = { ...options };
            const preparedVal = value.trim() === "" ? null : value;
            if (option.data_type === "integer" && preparedVal) {
              temp[option.slug] = parseInt(preparedVal);
            } else {
              temp[option.slug] = preparedVal;
            }
            setOptions(temp);
          }}
        />
      ))}
    </Box>
  );
}
