import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorInfo, PureComponent, ReactElement, ReactNode } from "react";
import Rollbar from "rollbar";

import { FdyClientError } from "../../../services/FdyClientProvider";
import { Redirect } from "../Redirect";
import { useRollbar } from "../RollbarProvider";
import { ErrorPage, ErrorPageProps } from "./ErrorPage";
import { logErrorToRollbar } from "./errorUtils";

type RenderFallback = ReactNode | ((props: ErrorPageProps) => ReactNode);

interface ErrorBoundaryClassProps {
  children: ReactNode;
  rollbar: Rollbar;
  onReset?: () => void;
  fallback?: RenderFallback;
}

interface ErrorBoundaryClassState {
  error: Error | null;
  errorId?: string | undefined;
  apiErrorId?: string | undefined;
  redirect: boolean;
}

const emptyState: ErrorBoundaryClassState = {
  error: null,
  errorId: undefined,
  apiErrorId: undefined,
  redirect: false,
};

class ErrorBoundaryClass extends PureComponent<
  ErrorBoundaryClassProps,
  ErrorBoundaryClassState
> {
  constructor(props: ErrorBoundaryClassProps) {
    super(props);
    this.state = {
      ...emptyState,
    };
  }

  // This isn't doing much but it quiets React warnings.
  // React docs say to use this to set the state and separately use componentDidCatch to log the error to services,
  // but we want to display the error IDs from rollbar, so we do it all in componentDidCatch. Is that bad? Idk.
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.handleError(error, errorInfo);
  }

  handleError(error: Error | FdyClientError, errorInfo: ErrorInfo) {
    const { rollbar } = this.props;

    // Don't send duplicate errors to rollbar on re-renders
    if (this.state.errorId && this.state.error === error) {
      return;
    }

    const { errorId, apiErrorId, notFoundError } = logErrorToRollbar({
      error,
      errorInfo,
      rollbar,
    });

    if (notFoundError) {
      this.setState({ redirect: true });
    } else if (errorId) {
      this.setState({
        error,
        errorId,
        apiErrorId,
      });
    }
  }

  render(): ReactNode {
    const { error, errorId, apiErrorId, redirect } = this.state;
    const { children, fallback } = this.props;

    if (redirect) {
      return <Redirect showToast replace />;
    }

    if (error) {
      const props: ErrorPageProps = {
        errorId: errorId,
        apiErrorId: apiErrorId,
        onBack: this.handleBack,
        onReset: this.handleReset,
        onReload: this.handleReload,
      };

      if (typeof fallback === "function") {
        return fallback(props);
      } else if (fallback) {
        return fallback;
      }

      return <ErrorPage {...props} />;
    }

    return children;
  }

  private handleBack = (): void => {
    this.handleReset();
    window.history.back();
  };

  private handleReset = (): void => {
    this.setState({
      ...emptyState,
    });
    this.props.onReset?.();
  };

  private handleReload = (): void => {
    window.location.reload();
  };
}

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: RenderFallback;
}

export function ErrorBoundary({
  children,
  fallback,
}: ErrorBoundaryProps): ReactElement {
  const rollbar = useRollbar();
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundaryClass
          rollbar={rollbar}
          onReset={reset}
          fallback={fallback}
        >
          {children}
        </ErrorBoundaryClass>
      )}
    </QueryErrorResetBoundary>
  );
}
