import { ThemeComponents } from "@chakra-ui/react";

/**
 * Chakra Component specific defaults/overrides
 * https://chakra-ui.com/docs/theming/component-style
 */
export const components: ThemeComponents = {
  Accordion: {
    parts: ["container", "button", "icon", "panel"],
    baseStyle: {
      container: {
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: "fdy_gray.300",
        _last: {
          borderBottomWidth: 1,
        },
      },
      button: {
        py: 4,
        px: 3,
        color: "fdy_gray.700",
        ".chakra-accordion__icon": { transform: "rotate(-90deg)" },
        '&[aria-expanded="true"]': {
          color: "fdy_purple.500",
          bg: "fdy_gray.100",
          ".chakra-accordion__icon": {
            transform: "rotate(0deg)",
          },
        },
      },
      icon: {
        mr: 3,
      },
      panel: {
        p: 4,
      },
    },
  },
  Container: {
    defaultProps: {
      size: "fdy_md",
    },
    sizes: {
      fdy_md: {
        maxW: "calc(1280px + 3rem * 2)",
        px: "3rem",
      },
      fdy_lg: {
        maxW: "calc(1480px + 3rem * 2)",
        px: "3rem",
      },
    },
  },
  Heading: {
    baseStyle: {
      fontWeight: "bold",
    },
    defaultProps: {
      // set all heading sizes to same so line height is consistent
      size: "md",
    },
    variants: {
      h3: {
        fontSize: "fdy_2xl",
      },
      h4: {
        fontSize: "fdy_xl",
      },
      h5: {
        fontSize: "fdy_lg",
      },
    },
    sizes: {
      // necessary to override response heading styles
      md: { lineHeight: "heading" },
    },
  },
  Checkbox: {
    defaultProps: {
      colorScheme: "purple",
    },
  },
  /**
   * Chakra has a colorScheme prop which lets you pass a top level color name ('purple')
   * and it will attempt to use colors from the scale under that color (100-900)
   * to automatically set alternative states, but the Faraday design only needs
   * a few button variants/colors, so we'll just use `variants` feature on its own.
   * Not to mention the colorScheme picks colors we may not want for other states.
   */
  Button: {
    baseStyle: {
      borderRadius: 6,
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "transparent",
      lineHeight: "body",
      boxShadow: "diffuse",
      _hover: {
        boxShadow: "diffuse-hover",
      },

      ".chakra-button__icon": {
        // only sets left placed icons. If we use right placed icons, we'll have to fix this.
        marginInlineEnd: 1,
      },

      // We need a selector for IconButton, but our options are basically limited
      // to making a new proxy component that internally uses Chakra's IconButton
      // just so we can add a class. In most cases, normal buttons won't use aria-label,
      // so this is a reasonable tradeoff.
      "&[aria-label]": {
        px: 3,
        py: 2,
      },
    },
    defaultProps: {
      variant: "primary",
      size: "md",
    },
    variants: {
      primary: {
        bg: "fdy_purple.500",
        color: "white",
        _hover: {
          bg: "fdy_purple.400",
          // Annoyingly, on disabled button hover, chakra sets the bg to `initial`, which is wrong for our variants.
          // Ensure we keep the same background. We'll likely have to do this for most buttons.
          _disabled: {
            bg: "fdy_purple.400",
          },
        },
      },
      secondary: {
        bg: "transparent",
        borderColor: "fdy_purple.500",
        color: "fdy_purple.500",
        _hover: {
          borderColor: "fdy_purple.400",
          color: "fdy_purple.400",
          bg: "fdy_purple.100",
          _disabled: {
            bg: "fdy_purple.100",
          },
        },
      },
      tertiary: {
        bg: "fdy_gray.200",
        color: "fdy_gray.700",
        _hover: {
          color: "fdy_gray.800",
          bg: "fdy_gray.300",
          _disabled: {
            bg: "fdy_gray.300",
          },
        },
      },
      ghost: {
        bg: "transparent",
        color: "fdy_purple.500",
        _hover: {
          color: "fdy_purple.600",
          bg: "fdy_gray.200",
          _disabled: {
            bg: "fdy_gray.200",
          },
        },
        _active: {
          bg: "fdy_gray.200",
        },
      },
      link: {
        bg: "transparent",
        color: "fdy_purple.500",
        boxShadow: "none",
        _hover: {
          color: "fdy_purple.600",
          textDecoration: "underline",
          bg: "transparent",
          boxShadow: "none",
        },
        _active: {
          textDecoration: "underline",
        },
      },
      danger: {
        bg: "fdy_red.500",
        color: "white",
        _hover: {
          bg: "fdy_red.400",
          _disabled: {
            bg: "fdy_red.400",
          },
        },
      },
      pill: {
        borderRadius: "full",
        bg: "fdy_gray.300",
        textColor: "black",
        fontWeight: "normal",
        _active: {
          bg: "fdy_purple.500",
          textColor: "white",
        },
        _hover: {
          bg: "fdy_purple.400",
          textColor: "white",
        },
      },
      icon: {
        bg: "transparent",
        color: "fdy_gray.700",
        boxShadow: "none",
        _hover: {
          color: "fdy_gray.800",
          boxShadow: "none",
        },
      },
    },
    sizes: {
      sm: {
        py: 2,
        px: 4,
        fontSize: "fdy_sm",
        // height: "2.3125rem",
      },
      md: {
        px: 6,
        py: 3,
        fontSize: "fdy_md",
        height: "3rem",
      },
      lg: {
        py: 4,
        px: 8,
        fontSize: "fdy_lg",
        height: "4rem",
      },
    },
  },
  Switch: {
    baseStyle: {
      track: {
        bg: "fdy_gray.600",
        _checked: {
          bg: "fdy_green.500",
        },
        _invalid: {
          bg: "fdy_red.500",
        },
      },
    },
    variants: {
      orange: {
        track: {
          bg: "fdy_gray.600",
          _checked: {
            bg: "fdy_orange.500",
          },
          _invalid: {
            bg: "fdy_red.500",
          },
        },
      },
    },
    sizes: {
      lg: {
        track: {
          p: 1,
        },
        thumb: {},
      },
    },
  },
  Table: {
    defaultProps: {
      variant: "fdy",
      size: "md",
    },
    baseStyle: {},
    sizes: {
      xs: {
        th: { p: 2 },
        td: { p: 2 },
      },
      sm: {
        tbody: {
          th: {
            p: 3,
          },
        },
      },
      md: {
        th: {
          p: 4,
        },
        td: {
          p: 4,
        },
        tbody: {
          th: {
            p: 4,
          },
        },
      },
    },
    variants: {
      fdy: {
        table: {
          // Note: this won't show up if you use chakra's TableContainer.
          // border style + border radius is not working on chakra table for some reason,
          // so use box shadow for now.
          boxShadow: "0 0 0 1px var(--chakra-colors-fdy_gray-400)",
          borderRadius: "md",
        },
        // add round corners to top of table
        thead: {
          th: {
            borderBottom: "1px solid",
            borderColor: "fdy_gray.600",
            "&:first-of-type": { borderTopLeftRadius: 6 },
            "&:last-of-type": { borderTopRightRadius: 6 },
          },
        },
        // add round corners to bottom of table
        tbody: {
          tr: {
            "&:last-of-type": {
              td: {
                "&:first-of-type": { borderBottomLeftRadius: 6 },
                "&:last-of-type": { borderBottomRightRadius: 6 },
              },
            },
          },
          th: {
            bg: "fdy_gray.100",
            fontWeight: "normal",
            verticalAlign: "top",
            whiteSpace: "nowrap",
          },
        },
        th: {
          bg: "white",
          fontSize: "fdy_sm",
          fontWeight: "bold",
          letterSpacing: 0,
          lineHeight: "body",
          textTransform: "none",
        },
        td: {
          bg: "white",
          verticalAlign: "top",
          fontSize: "fdy_sm",
          lineHeight: "body",
          transition: "background-color .1s ease-in-out",
        },
        tr: {
          // add row dividers
          "&:not(:last-of-type)": {
            borderBottom: "1px solid",
            borderColor: "fdy_gray.300",
          },
          _hover: {
            td: {
              bg: "fdy_gray.100",
            },
          },
        },
        caption: {
          fontSize: "fdy_sm",
          textAlign: "left",
          color: "fdy_gray.700",
          fontWeight: "normal",
          p: 0,
        },
      },
    },
  },
  Menu: {
    defaultProps: {
      variant: "fdy",
    },
    variants: {
      fdy: {
        button: {},
        list: {
          p: 2,
          borderRadius: "md",
          shadow: "md",
        },
        item: {
          lineHeight: "body",
          fontSize: "fdy_md",
          py: 2,
          px: 4,
          borderRadius: 6,
          _focus: {
            bg: "fdy_gray.200",
          },
          _hover: {
            bg: "fdy_gray.200",
          },
        },
      },
    },
  },
  Badge: {
    sizes: {
      sm: {
        px: 1,
        py: 0.5,
        fontSize: "fdy_sm",
      },
      lg: {
        px: 2,
        py: 1,
        fontSize: "fdy_xl",
      },
    },
  },
  Breadcrumb: {
    parts: ["container", "link", "separator"],
    defaultProps: {
      variant: "fdy",
    },
    variants: {
      fdy: {
        container: {
          ol: {
            display: "flex",
            alignItems: "center",
          },
        },
        link: {
          px: 2,
          fontWeight: "bold",
          fontSize: "fdy_sm",
          color: "fdy_purple.500",
          display: "inline-flex",
          alignItems: "center",
          _hover: {
            color: "fdy_purple.400",
          },
          // home icon should be first child, so some tweaks to visually align it
          "li:first-of-type > &": {
            p: 1,
          },

          // style active page
          '&[aria-current="page"]': {
            color: "fdy_gray.700",
          },
        },

        separator: {
          color: "fdy_gray.400",
        },
      },
    },
  },
  Modal: {
    baseStyle: {
      dialog: {
        minW: "lg",
      },
      header: {
        fontSize: "fdy_md",
        fontWeight: "bold",
        color: "fdy_gray.700",
        borderBottom: "1px solid",
        borderColor: "fdy_gray.300",
      },
      closeButton: {
        top: 3,
        right: 3,
        color: "fdy_gray.700",
      },
      body: {
        p: 6,
      },
      footer: {
        display: "block", // override default which is flex
      },
    },
    variants: {
      flush: {
        body: {
          p: 0,
        },
      },
    },
  },
  Textarea: {
    baseStyle: {},
    defaultProps: {
      variant: "fdy",
    },
    variants: {
      fdy: {
        boxShadow: "diffuse",
        border: "1px solid",
        borderColor: "fdy_gray.400",
        _focus: {
          borderColor: "fdy_purple.500",
          boxShadow: "none",
        },
        _hover: {
          borderColor: "fdy_gray.600",
        },
        _invalid: {
          borderColor: "fdy_red.500",
        },
        _active: {
          borderColor: "black",
        },
      },
    },
  },
  Input: {
    baseStyle: {},
    defaultProps: {
      variant: "fdy",
      size: "md",
      errorBorderColor: "error",
    },
    variants: {
      fdy: {
        field: {
          boxShadow: "diffuse",
          border: "1px solid",
          borderColor: "fdy_gray.400",
          _focus: {
            borderColor: "fdy_purple.500",
            boxShadow: "none",
          },
          _hover: {
            "&:not([disabled])": {
              borderColor: "fdy_gray.600",
            },
          },
          _invalid: {
            borderColor: "fdy_red.500",
          },
        },
      },
    },
    sizes: {
      md: {
        field: {
          py: 3,
          px: 4,
          height: "3rem",
        },
      },
    },
  },
  Link: {
    defaultProps: {
      variant: "fdy",
    },
    variants: {
      fdy: {
        color: "primary",
        textDecoration: "underline",
        _hover: {
          textDecoration: "none",
        },
      },
    },
  },
  Tooltip: {
    defaultProps: {
      variant: "fdy",
    },
    variants: {
      fdy: {
        // tooltip is not able to use theme colors
        bg: "fdy_gray.900",
        "--popper-arrow-bg": "var(--chakra-colors-fdy_gray-900)",
        px: 3,
        py: 2,
        fontSize: "fdy_xs",
        fontWeight: "bold",
        borderRadius: 6,
      },
    },
  },
  Alert: {
    defaultProps: {
      variant: "fdy",
    },
    baseStyle: {
      container: {
        bg: "white",
        py: 4,
        px: 6,
        borderLeft: "8px solid",
        borderRadius: 6,
        boxShadow: "modal",
      },
      title: {
        fontWeight: "bold",
        fontSize: "fdy_md",
        color: "fdy_gray.900",
        mb: 1,
      },
      icon: {
        width: "2rem",
        height: "2rem",
        mr: 6,
      },
      description: {
        color: "fdy_gray.700",
      },
    },
    // These variants are meant for toast alerts. If we start to use alerts in flow of content, they may
    // not be styled same way, and we may want to prefix the variants like `toast_success`.
    variants: {
      success: {
        container: { borderLeftColor: "fdy_green.500" },
        icon: { color: "fdy_green.500" },
      },
      error: {
        container: { borderLeftColor: "fdy_red.500" },
        icon: { color: "fdy_red.500" },
      },
      warning: {
        container: { borderLeftColor: "fdy_yellow.500" },
        icon: { color: "fdy_yellow.500" },
      },
      info: {
        container: { borderLeftColor: "fdy_gray.600" },
        icon: { color: "fdy_gray.600" },
      },
    },
  },
  Notice: {
    variants: {
      success: {
        container: { bg: "fdy_green.300", borderColor: "fdy_green.600" },
        icon: { color: "fdy_green.600" },
        title: { color: "fdy_green.600" },
        description: { color: "fdy_green.600" },
        closeButton: { color: "fdy_green.600" },
      },
      error: {
        container: { bg: "fdy_red.200", borderColor: "fdy_red.500" },
        icon: { color: "fdy_red.500" },
        title: { color: "fdy_red.500" },
        description: { color: "fdy_red.500" },
        closeButton: { color: "fdy_red.500" },
      },
      warning: {
        container: { bg: "fdy_yellow.300", borderColor: "fdy_yellow.500" },
        icon: { color: "fdy_yellow.600" },
        title: { color: "fdy_yellow.600" },
        description: { color: "fdy_yellow.600" },
        closeButton: { color: "fdy_yellow.600" },
      },
      info: {
        container: { bg: "fdy_gray.200", borderColor: "fdy_gray.500" },
        icon: { color: "fdy_gray.500" },
      },
    },
  },
  Form: {
    baseStyle: {
      helperText: {
        color: "fdy_gray.700",
        lineHeight: "inherit",
        fontSize: "fdy_md",
        mt: -1, // remove same margin as FormLabel has for bottom margin
        mb: 4,
      },
    },
  },
  FormLabel: {
    baseStyle: {
      fontSize: "fdy_md",
      fontWeight: "bold",
      mb: 1,
    },
    sizes: {
      lg: {
        fontSize: "fdy_xl",
        mb: 2,
      },
    },
  },
  FormError: {
    baseStyle: {
      text: {
        color: "error",
        fontWeight: "bold",
      },
    },
  },
  Divider: {
    baseStyle: {
      opacity: 1,
      my: 8,
    },
  },
  Drawer: {
    baseStyle: {
      header: {
        fontSize: "fdy_lg",
        pt: 8,
        pb: 2,
        px: 8,
      },
      body: {
        pt: 2,
        pb: 8,
        px: 8,
      },
      closeButton: {
        top: 8,
        right: 8,
      },
    },
  },
  Radio: {
    parts: ["container", "control", "label"],
    baseStyle: {
      container: {
        alignItems: "flex-start",
        // fixes issue where radio scrolls page to top when checked
        // https://stackoverflow.com/questions/24299567/radio-button-causes-browser-to-jump-to-the-top
        "& .chakra-radio__input": {
          top: "0",
        },
      },
      control: {
        mt: 1,
        borderColor: "fdy_gray.600",
        _checked: {
          bg: "white",
          borderColor: "primary",
          "&:before": {
            bg: "primary",
          },
        },
      },
    },
    variants: {
      stacked: {
        container: {
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        },
        label: {
          textAlign: "center",
          marginLeft: 0,
        },
      },
      pill: {
        container: {
          gap: 2,
          pb: 4,
        },
        control: {
          display: "none",
        },
        label: {
          py: 1,
          px: 4,
          color: "fdy_gray.800",
          bg: "fdy_gray.200",
          borderRadius: 100,
          fontWeight: "semibold",
          _hover: {
            bg: "fdy_gray.300",
            color: "fdy_gray.900",
          },
          _checked: {
            color: "white",
            bg: "fdy_purple.500",
          },
        },
      },
    },
  },
  Select: {
    defaultProps: {
      focusBorderColor: "fdy_purple.500",
    },
    baseStyle: {
      field: {
        minHeight: "3rem", // height doesn't work: https://github.com/chakra-ui/chakra-ui/issues/1757
        backgroundColor: "white",
        boxShadow: "diffuse",
        _focus: {
          boxShadow: "outline",
          backgroundColor: "transparent",
        },
      },
    },
  },
  Tabs: {
    parts: ["tab", "tablist", "tabpanel", "tabpanels"],
    defaultProps: {
      variant: "line",
    },
    baseStyle: {
      // Set some base styles that mostly impact spacing and sizing
      // variants will later apply styles.
      // Definng sizes here
      tab: {
        py: 4,
        px: 12,
        textTransform: "uppercase",
        fontSize: "fdy_md",
        fontWeight: "bold",
        textDecoration: "none",
        letterSpacing: ".02em",
        transition: "all 0.2s ease-in-out",
      },
      tabpanel: {
        px: 0,
      },
    },
    variants: {
      // Now define variants that adjust colors mostly. Sizing should be controlled via sizes below.
      // https://github.com/chakra-ui/chakra-ui/issues/5295
      line: {
        tab: {
          color: "fdy_gray.600",
          borderColor: "fdy_gray.400",
          borderBottom: "4px solid",
          _hover: {
            bg: "fdy_gray.300",
            color: "fdy_gray.700",
          },
          _selected: {
            color: "fdy_gray.800",
            borderColor: "fdy_magenta.500",
          },
        },
      },
    },
    sizes: {
      sm: {
        tab: {
          fontSize: "fdy_sm",
          py: 2,
          px: 6,
        },
      },
    },
  },
  Tag: {
    defaultProps: {
      variant: "fdy",
    },
    variants: {
      fdy: {
        container: {
          fontSize: "fdy_sm",
          borderRadius: "full",
          bg: "fdy_gray.200",
          py: 1,
          px: 2,
        },
      },
    },
  },
  FormFieldset: {
    baseStyle: {
      legend: {
        fontWeight: "bold",
      },
      text: {
        color: "fdy_gray.700",
      },
    },
    sizes: {
      // small size mimics FormField, useful when you have checkboxes/radios alongside other text input form fields
      sm: {
        legend: {
          fontSize: "fdy_md",
          mb: 1,
        },
        text: {
          fontSize: "fdy_md",
          mt: -1, // remove same margin as legend has for bottom margin in case there's no help text, legend should still have spacing
          mb: 4,
        },
      },

      // i think lg should be the default. medium (i.e. normal field label size) would be more common
      md: {
        legend: {
          fontSize: "fdy_lg",
          mb: 1,
        },
        text: {
          fontSize: "fdy_md",
          mb: 4,
        },
      },
    },
  },
  Popover: {
    baseStyle: {
      content: {
        boxShadow: "diffuse",
      },
      body: {
        p: 4,
      },
    },
  },
};
