import { gql } from "@apollo/client";

import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { useRollbar } from "../../ui/RollbarProvider";
import { OutcomeFragment } from "../__generated__/OutcomeFragment";
import {
  OutcomeAnalysisQuery,
  OutcomeAnalysisQueryVariables,
} from "./__generated__/OutcomeAnalysisQuery";

const outcomeAnalysisPerformanceFragment = gql`
  fragment OutcomeAnalysisPerformanceModel on OutcomePerformanceModel {
    tenureStart
    tenureEnd
    rocAuc
    metricsTable {
      headers
      rows
    }
    liftValue
    liftTable {
      headers
      rows
    }
    liftCurve {
      x
      y
      yMin
      yMax
    }
    rocCurve {
      x
      y
    }
  }
`;

const outcomeAnalysisFeatureFragment = gql`
  fragment OutcomeAnalysisFeature on OutcomeAnalysisFeature {
    name
    literate
    importance
    directionality {
      __typename
      ... on AnalysisFeatureDirectionalityBoolean {
        density
        dataType
        impact
        value
      }
      ... on AnalysisFeatureDirectionalityDate {
        density
        dataType
        impact
        # prevent conflicts across union types
        dateMin: min
        dateMax: max
      }
      ... on AnalysisFeatureDirectionalityText {
        density
        dataType
        impact
        # prevent conflicts across union types
        text: value
      }
      ... on AnalysisFeatureDirectionalityNumber {
        dataType
        max
        min
        impact
        density
      }
    }
  }
`;

const OUTCOME_ANALYSIS_QUERY = gql`
  query OutcomeAnalysisQuery($outcomeId: ID!) {
    outcomeAnalysis(outcomeId: $outcomeId) {
      overallPerformance {
        recognizedIndividuals {
          ...OutcomeAnalysisPerformanceModel
        }
      }
      tenurePerformances {
        recognizedIndividuals {
          ...OutcomeAnalysisPerformanceModel
        }
      }

      overallFeatures {
        recognizedIndividuals {
          ...OutcomeAnalysisFeature
        }
      }
      tenureFeatures {
        recognizedIndividuals {
          tenureStart
          tenureEnd
          features {
            ...OutcomeAnalysisFeature
          }
        }
      }
      bias {
        summary {
          dataUnbiased
          powerUnbiased
          predictionsUnbiased
          fairnessFair
        }
        data {
          age {
            level
            candidates {
              x
              y
            }
            negatives {
              x
              y
            }
            positives {
              x
              y
            }
          }
          gender {
            level
            candidates {
              x
              y
            }
            negatives {
              x
              y
            }
            positives {
              x
              y
            }
          }
        }
        power {
          dimensions
          values
          metrics {
            level
            name
            value
          }
        }
        predictions {
          dimensions
          values
          metrics {
            level
            name
            value
          }
        }
        fairness {
          dimensions
          values
          metrics {
            level
            name
            value
          }
        }
      }
    }
  }
  ${outcomeAnalysisPerformanceFragment}
  ${outcomeAnalysisFeatureFragment}
`;

// Split analysis query to another query so useOutcomeQuery doesn't load it on
// edit page and 2 parallel queries is better.
export function useOutcomeAnalysisQuery(outcome: OutcomeFragment) {
  const rollbar = useRollbar();

  // If the outcome has never been built, don't bother querying because the
  // analysis calculation will likely be 404/not built yet.
  const neverBuilt = outcome.lastUpdatedOutputAt === null;

  const { data, loading, error } = useSojournerQuery<
    OutcomeAnalysisQuery,
    OutcomeAnalysisQueryVariables
  >(OUTCOME_ANALYSIS_QUERY, {
    variables: {
      outcomeId: outcome.id,
    },
    // Important: apollo caching the giant analysis response takes a ton of CPU and time, so just disable it.
    fetchPolicy: "no-cache",
    skip: neverBuilt,
  });

  // we expect 404 some errors while we backfill outcomes to have their analysis calc.
  // otherwise throw the error
  if (error) {
    if (error.graphQLErrors.some((e) => e.extensions?.error === "NOT_FOUND")) {
      rollbar.warn(error);
    } else {
      throw error;
    }
  }

  return {
    data,
    loading,
  };
}
