import { Box, Link } from "@chakra-ui/react";
import { UnreachableCodeError } from "@fdy/jwt";

import { Direction } from "../../../../../__generated__/sojournerGlobalTypes";
import { FormFieldset } from "../../../../ui/FormFieldset";
import {
  TargetFragment_limit,
  TargetFragment_limit_TargetLimitPercentile,
  TargetFragment_limit_TargetLimitRowCount,
} from "../../__generated__/TargetFragment";
import { TargetLimitPercentile } from "./TargetLimitPercentile";
import { TargetLimitRowCount } from "./TargetLimitRowCount";
import { LimitEnum, TargetLimitTypeSelect } from "./TargetLimitTypeSelect";

// Can make other type fields nullable based on a list of keys
type NullableKeys<T, K extends keyof T> = Omit<T, K> & {
  [P in K]?: T[P] | null;
};

// Slightly redo the types here to make their input values nullable so users can
// clear the input field naturally.
export type TargetLimitStateRowCount = NullableKeys<
  TargetFragment_limit_TargetLimitRowCount,
  "threshold"
>;

export type TargetLimitStatePercentile = NullableKeys<
  TargetFragment_limit_TargetLimitPercentile,
  "percentileMin" | "percentileMax" | "percentileOutcomeId"
>;

export type TargetLimitState =
  | TargetLimitStateRowCount
  | TargetLimitStatePercentile;

export type LimitTypename = TargetLimitState["__typename"];

export const LIMIT_INITIAL_STATE: TargetLimitState = {
  __typename: "TargetLimitRowCount",
  method: "row_count",
  rowCountOutcomeId: null,
  direction: null,
  threshold: null,
};

export function parseNumberValue(value: string): number | null {
  return value === "" ? null : parseInt(value, 10);
}

/**
 * Return the outcomeId from the limit. We need a helper function to disentangle the types.
 * For row count limits, outcomeId is optional. For percentile limits, it's required.
 */
export function outcomeIdFromLimit(limit: TargetFragment_limit): string | null {
  if (limit.__typename === "TargetLimitRowCount") {
    return limit.rowCountOutcomeId;
  } else if (limit.__typename === "TargetLimitPercentile") {
    return limit.percentileOutcomeId;
  }
  throw new UnreachableCodeError(limit);
}

export const formIds = {
  direction: "direction",
  outcome_id: "outcome_id",
  threshold: "threshold",
  percentile_min: "percentile_min",
  percentile_max: "percentile_max",
};

export const labels = {
  limitType: "Limit type",
  outcomeId: "Outcome",
  threshold: "Threshold",
  percentileMin: "Minimum percentile score",
  percentileMax: "Maximum percentile score",
};

type PayloadOutcomes = { id: string; name: string }[];

export interface TargetLimitProps {
  payloadOutcomes: PayloadOutcomes;
  limit: TargetLimitState;
  onChange: (state: TargetLimitState) => void;
  lockedTypename?: LimitTypename;
  showPercentileLimit: boolean;
}

/**
 * Given a `target.limit`, returns an enum string which denotes
 * one of the choices available to users in the form.
 */
function limitToLimitEnum(limit: TargetLimitState): LimitEnum {
  if (limit.__typename === "TargetLimitPercentile") {
    return LimitEnum.PercentileRange;
  } else if (limit.__typename === "TargetLimitRowCount") {
    if (limit.direction === Direction.ASCENDING) {
      return LimitEnum.BottomCount;
    } else if (limit.direction === Direction.DESCENDING) {
      return LimitEnum.TopCount;
    }
  }
  return LimitEnum.TopCount;
}

/**
 * Renders the inputs for modifying a target limit.
 */
export function TargetLimit({
  payloadOutcomes,
  limit,
  onChange,
  lockedTypename,
  showPercentileLimit,
}: TargetLimitProps) {
  const limitType = limitToLimitEnum(limit);

  return (
    <FormFieldset
      legend="Limit the number of results you receive in your deployment"
      hint={
        <>
          For detailed guidance in limiting deployments,{" "}
          <Link href="https://faraday.ai/docs/abstractions/deployments#deployment-limits">
            see our documentation.
          </Link>
        </>
      }
    >
      <Box mb={4}>
        <TargetLimitTypeSelect
          limitType={limitType}
          onChange={onChange}
          limit={limit}
          lockedTypename={lockedTypename}
          showPercentileLimit={showPercentileLimit}
        />
      </Box>

      {limit.__typename === "TargetLimitPercentile" ? (
        <TargetLimitPercentile
          limit={limit}
          onChange={onChange}
          payloadOutcomes={payloadOutcomes}
        />
      ) : (
        <TargetLimitRowCount
          payloadOutcomes={payloadOutcomes}
          limit={limit}
          onChange={onChange}
          limitType={limitType}
        />
      )}
    </FormFieldset>
  );
}
