import { GraphEdge, ResourceType } from "@fdy/faraday-js";
import { groupBy } from "lodash";
import { ConnectionLineType, Edge, Node } from "reactflow";

import {
  EDGE_COLOR,
  GRAPH_LAYOUT,
  GROUP_HEADING_OFFSET,
  MARKER_END,
  MARKER_START,
  NODE_GAP_X,
  NODE_GAP_Y,
  NODE_HEIGHT,
  NODE_WIDTH,
} from "../config";
import {
  GraphLayoutColumn,
  GroupNodeData,
  ResourceGraphNodeType,
  ResourceNodeData,
} from "../types";

const calculateXOffset = (index: number) =>
  index * NODE_WIDTH + (index * NODE_GAP_X + NODE_GAP_X);

function createGroupNodes(layout: GraphLayoutColumn[]): Node<GroupNodeData>[] {
  return layout.map((col) => {
    const layoutIndex = layout.findIndex((group) => group.label === col.label);
    return {
      id: col.label,
      type: ResourceGraphNodeType.Group,
      data: { label: col.label },
      position: {
        x: calculateXOffset(layoutIndex),
        y: GROUP_HEADING_OFFSET,
      },
      style: {
        border: 0,
      },
    };
  });
}

/**
 * Position the nodes in the graph based on their type and index within the type.
 */
function positionNodes(
  nodes: Node<ResourceNodeData>[],
  layout: GraphLayoutColumn[]
) {
  const groups = groupBy(
    nodes,
    (t) => layout.find((group) => group.types.includes(t.data.type))?.label
  );

  return Object.entries(groups).flatMap(([group, nodes]) => {
    return nodes.map((node, index) => {
      const xIndex = layout.findIndex((g) => g.label === group);
      return {
        ...node,
        position: {
          x: calculateXOffset(xIndex),
          y: index * (NODE_HEIGHT + NODE_GAP_Y),
        },
      };
    });
  });
}

// The order in which resources should be displayed in the graph.
// This helps ensure that nodes added have ideal upstream IDs when needed.
// For example, a scope should have the upstream ID of the target it belongs to,
// but targets can have upstream dataset links.
const graphSortOrder: ResourceType[] = [
  ResourceType.Accounts,
  ResourceType.Connections,
  ResourceType.Datasets,
  ResourceType.Streams,
  ResourceType.Traits,
  ResourceType.Places,
  ResourceType.Cohorts,
  ResourceType.Outcomes,
  ResourceType.PersonaSets,
  ResourceType.Recommenders,
  ResourceType.Scopes,
  ResourceType.Targets,
];

const sortGraph = (a: GraphEdge, b: GraphEdge) => {
  const aIndex = graphSortOrder.indexOf(a.upstream_type);
  const bIndex = graphSortOrder.indexOf(b.upstream_type);

  if (aIndex === -1 || bIndex === -1) {
    return 0;
  }

  return aIndex - bIndex;
};

export function createNodesAndEdgesForResource(
  graphEdges: GraphEdge[],
  resourceId: string
) {
  const nodesMap = new Map<string, Node<ResourceNodeData>>();
  const edges = new Map<string, Edge>();

  const addNode = ({
    id,
    name,
    type,
    status,
    current,
    upstreamId,
    lastReadInputAt,
    lastUpdatedConfigAt,
    lastUpdatedOutputAt,
    statusError,
    archived,
  }: ResourceNodeData) => {
    nodesMap.set(id, {
      id,
      type: ResourceGraphNodeType.Resource,
      data: {
        id,
        type,
        name,
        current,
        status,
        statusError,
        upstreamId,
        lastReadInputAt,
        lastUpdatedConfigAt,
        lastUpdatedOutputAt,
        archived,
      },
      position: {
        x: 0,
        y: 0,
      },
    });
  };

  const addEdge = (source: string, target: string) => {
    const id = `${source}__${target}`;
    edges.set(id, {
      id,
      type: ConnectionLineType.SmoothStep,
      source,
      target,
      markerStart: MARKER_START,
      markerEnd: MARKER_END,
      style: {
        stroke: EDGE_COLOR,
        strokeWidth: 2,
      },
    });
  };

  // Insert all edges and nodes into the graph, even if it appears redundant.
  // Adding everything is safe since IDs are unique in maps.
  // Sorting ensures downstream nodes have the upstream ID of the preceding
  // resource type based on the sort order.
  graphEdges.sort(sortGraph).forEach((graphEdge) => {
    addEdge(graphEdge.upstream_id, graphEdge.downstream_id);

    addNode({
      id: graphEdge.upstream_id,
      name: graphEdge.upstream_literate,
      type: graphEdge.upstream_type,
      status: graphEdge.upstream_status,
      statusError: graphEdge.upstream_status_error,
      lastReadInputAt: graphEdge.upstream_last_read_input_at,
      lastUpdatedConfigAt: graphEdge.upstream_last_updated_config_at,
      lastUpdatedOutputAt: graphEdge.upstream_last_updated_output_at,
      current: graphEdge.upstream_id === resourceId,
      archived: !!graphEdge.upstream_archived_at,
    });

    addNode({
      id: graphEdge.downstream_id,
      name: graphEdge.downstream_literate,
      type: graphEdge.downstream_type,
      status: graphEdge.downstream_status,
      statusError: graphEdge.downstream_status_error,
      lastReadInputAt: graphEdge.downstream_last_read_input_at,
      lastUpdatedConfigAt: graphEdge.downstream_last_updated_config_at,
      lastUpdatedOutputAt: graphEdge.downstream_last_updated_output_at,
      upstreamId: graphEdge.upstream_id,
      current: graphEdge.downstream_id === resourceId,
      archived: !!graphEdge.downstream_archived_at,
    });
  });

  return {
    nodes: Array.from(nodesMap.values()),
    edges: Array.from(edges.values()),
  };
}

/**
 * Given a resource ID and graph edges, create a list of nodes and edges for the resource graph.
 * - The graph is built by traversing the graph edges to find all nodes connected to the resource.
 * - The graph edges are filtered to remove any obscure connections.
 * - The nodes are then positioned in the graph based on their type and index within the type.
 * - Group nodes are created based on the layout configuration.
 */
export function createResourceGraphNodesAndEdges(
  graphEdges: GraphEdge[],
  resourceId: string
) {
  const { nodes, edges } = createNodesAndEdgesForResource(
    graphEdges,
    resourceId
  );

  // only use the graph layout that has nodes in it
  const usedLayout = GRAPH_LAYOUT.filter((group) => {
    return nodes.some((node) => group.types.includes(node.data.type));
  });

  return {
    // create the group nodes after all the nodes since we won't immediately know which types are used
    nodes: positionNodes(nodes, usedLayout),
    groupNodes: createGroupNodes(usedLayout),
    edges,
  };
}
