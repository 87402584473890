import styled from "@emotion/styled";
import { Flask } from "@phosphor-icons/react";
import { ReactElement } from "react";

import { theme } from "../../constants/theme";
import {
  HUBSPOT_USER_DISABLED_TEST_MODE,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useTestMode } from "../../hooks/useTestMode";
import { colors } from "../../styles/chakra-theme-v2";

const StyledBanner = styled.div`
  display: flex;
  background: ${colors.fdy_yellow[500]};
  padding: ${theme.space(1)};
  padding-left: ${theme.space(6)};
  padding-right: ${theme.space(6)};
  align-items: center;
`;

const StyledBannerText = styled.p`
  padding-left: ${theme.space(2)};
  flex: 1;
`;

const StyledBannerButton = styled.button`
  border-radius: ${theme.radii.md};
  padding: ${theme.space(0.5)};
  padding-left: ${theme.space(2)};
  padding-right: ${theme.space(2)};
  font-size: ${theme.fontSizes.sm};
  font-weight: bold;
  border: 1px solid;
  border-color: transparent;
  line-height: ${theme.lineHeights.body};
  background: transparent;
  border-color: ${colors.fdy_gray[900]};
  color: ${colors.fdy_gray[900]};
  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
`;

export function TestModeBanner(): ReactElement | null {
  const { toggleTestMode, isInTestMode } = useTestMode();
  const track = useHubSpotEvent();

  if (!isInTestMode) return null;

  return (
    <StyledBanner role="alert" aria-live="polite">
      <Flask />
      <StyledBannerText>
        Your account is in <b>test mode</b>, using artificial data.
      </StyledBannerText>
      <StyledBannerButton
        onClick={() => {
          toggleTestMode();
          track(HUBSPOT_USER_DISABLED_TEST_MODE, undefined);
        }}
      >
        Leave test mode
      </StyledBannerButton>
    </StyledBanner>
  );
}
