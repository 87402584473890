import { ReactElement } from "react";

import { Boxplot } from "./Boxplot";
import { fieldDataToBins } from "./fieldDataToBins";
import { Histogram } from "./Histogram";
import { AnalysisDataset, Bounds, Field } from "./types";

interface HistogramBoxplotProps {
  color: string;
  data: AnalysisDataset;
  bounds: Bounds;
  field: Field;
}

export function HistogramBoxplot({
  color,
  data,
  bounds,
  field,
}: HistogramBoxplotProps): ReactElement {
  const bins = fieldDataToBins(data, bounds, field);

  return (
    <div>
      <Histogram color={color} bounds={bounds} bins={bins} field={field} />
      <Boxplot color={color} bounds={bounds} field={field} data={data} />
    </div>
  );
}
