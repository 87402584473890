import { Box, Text, useId } from "@chakra-ui/react";
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
} from "react";

import { components } from "../../../../sojourner-oas-types";
import { AccordionV2 } from "../../../ui/AccordionV2";
import { Button } from "../../../ui/Button";
import { ErrorText } from "../../../ui/ErrorText";
import { InlineButtons } from "../../../ui/InlineButtons";
import { ModalV2 } from "../../../ui/ModalV2";
import { EventState } from "../CohortForm";
import { CohortStreamsQuery_streams } from "./__generated__/CohortStreamsQuery";
import { CohortEventProperties } from "./CohortEventProperties";
import { CohortEventsFrequency } from "./CohortEventsFrequency";
import { CohortEventsRecency } from "./CohortEventsRecency";
import { CohortEventsValue } from "./CohortEventsValue";

export type EventNumberChangeHandler = (
  name: keyof Pick<
    EventState,
    "maxCount" | "maxDays" | "maxValue" | "minCount" | "minDays" | "minValue"
  >
) => (e: ChangeEvent<HTMLInputElement>) => void;

export type EventSelectChangeHandler = (
  name: keyof Pick<EventState, "streamName" | "occurrence">
) => (e: ChangeEvent<HTMLSelectElement>) => void;

export function CohortEventSetupModal({
  stream,
  backButtonLabel,
  error,
  event,
  onBack,
  onCancel,
  onEventChange,
  onFinish,
  title,
}: {
  stream: CohortStreamsQuery_streams | undefined;
  backButtonLabel: string;
  error?: string;
  event: EventState;
  onBack: () => void;
  onCancel: () => void;
  onEventChange: Dispatch<SetStateAction<EventState | undefined>>;
  onFinish: () => void;
  title: string;
}) {
  const formId = useId();
  const streamLiterate = event.streamName;

  const streamProperties = stream?.properties
    ? (stream.properties as components["schemas"]["StreamProperties"])
    : null;

  const handleNumberInputChange: EventNumberChangeHandler = useCallback(
    (name) => (e) => {
      const val = e.target.value;
      onEventChange((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          [name]: val === "" ? null : Number(val),
        };
      });
    },
    [onEventChange]
  );

  const handleSelectChange: EventSelectChangeHandler = useCallback(
    (name) => (e) => {
      const val = e.target.value;
      onEventChange((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          [name]: val === "" ? undefined : val,
        };
      });
    },
    [onEventChange]
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onFinish();
  };

  return (
    <ModalV2
      title={title}
      onClose={onCancel}
      isOpen
      footer={
        <Box>
          <ErrorText>{error}</ErrorText>
          <InlineButtons reverse>
            <Button
              type="submit"
              variant="primary"
              form={formId}
              analyticsName="finish"
            >
              Finish
            </Button>
            <Button variant="tertiary" onClick={onBack} analyticsName="back">
              {backButtonLabel}
            </Button>
          </InlineButtons>
        </Box>
      }
      analyticsStackName="events-setup"
    >
      <form onSubmit={handleSubmit} id={formId}>
        <Text fontSize="fdy_lg" mb={5}>
          Add optional conditions to your <strong>{streamLiterate}</strong>{" "}
          event to filter who you would like to include in this Cohort.
        </Text>

        <AccordionV2>
          {streamProperties &&
            Object.keys(streamProperties).includes("datetime") && (
              <AccordionV2.Item
                title="Recency"
                subtitle="When events happen"
                analyticsName="recency"
              >
                <CohortEventsRecency
                  event={event}
                  onFieldChange={handleNumberInputChange}
                  onSelectChange={handleSelectChange}
                />
              </AccordionV2.Item>
            )}
          <AccordionV2.Item
            title="Frequency"
            subtitle="How often events happen"
            analyticsName="frequency"
          >
            <CohortEventsFrequency
              event={event}
              onFieldChange={handleNumberInputChange}
            />
          </AccordionV2.Item>
          {streamProperties &&
            Object.keys(streamProperties).includes("value") && (
              <AccordionV2.Item
                title="Earned value"
                subtitle="Include individuals based on currently earned cumulative value"
                analyticsName="value"
              >
                <CohortEventsValue
                  event={event}
                  onFieldChange={handleNumberInputChange}
                />
              </AccordionV2.Item>
            )}
          {streamProperties && (
            <AccordionV2.Item
              title="Properties"
              subtitle="Advanced conditions"
              analyticsName="properties"
            >
              <CohortEventProperties
                streamProperties={streamProperties}
                streamConditions={event.streamConditions}
                onStreamConditionsChange={(newStreamConditions) =>
                  onEventChange({
                    ...event,
                    streamConditions: newStreamConditions,
                  })
                }
              />
            </AccordionV2.Item>
          )}
        </AccordionV2>
      </form>
    </ModalV2>
  );
}
