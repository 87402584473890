import { gql, useQuery } from "@apollo/client";
import { Grid, Link } from "@chakra-ui/react";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { ReactElement } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { CardV2 } from "../../ui/Card/CardV2";
import { CardStack } from "../../ui/CardStack";
import { CopyInputV2 } from "../../ui/CopyInputV2";
import { FormField } from "../../ui/FormField";
import { Notice } from "../../ui/Notice";
import { PasswordInput } from "../../ui/PasswordInput";
import { SettingsLayout } from "../SettingsLayout";
import {
  SettingsApiQuery,
  SettingsApiQuery_account,
} from "./__generated__/SettingsApiQuery";
import { AccountDeleteButton } from "./AccountDeleteButton";
import { AccountNameCard } from "./AccountNameCard";
import { LeaveTestModeNotice } from "./LeaveTestModeNotice";

const SETTINGS_ACCOUNT_QUERY = gql`
  query SettingsApiQuery {
    account {
      id
      name
      api_key
      test_mode
      test_account {
        id
        api_key
      }
      deleted_at
    }
  }
`;

interface ApiKeyFieldProps {
  label: string;
  apiKey?: string | null;
}

function ApiKeyField({ label, apiKey }: ApiKeyFieldProps) {
  if (!apiKey) return null;

  return (
    <FormField label={`${label} environment`}>
      <CopyInputV2
        value={apiKey ?? ""}
        buttonLabel="Copy"
        renderInput={(props) => (
          <PasswordInput
            {...props}
            visibilityButtonLabel={`${label} API key`}
          />
        )}
      />
    </FormField>
  );
}

function AccountApiKeyCard({
  account,
}: {
  account: SettingsApiQuery_account;
}): ReactElement {
  const docsHref = "https://faraday.ai/docs/reference";

  const helpText = (
    <>
      Use your API key to authenticate requests to the Faraday API.
      <br /> For more information, see our{" "}
      <Link href={docsHref} rel="noreferrer" target="_blank">
        API reference <ArrowSquareOut style={{ display: "inline" }} />
      </Link>{" "}
      docs.
    </>
  );

  return (
    <CardV2 title="API key" text={helpText}>
      <Grid gap={4}>
        <ApiKeyField apiKey={account.test_account?.api_key} label="Test" />
        <ApiKeyField apiKey={account.api_key} label="Production" />
      </Grid>
    </CardV2>
  );
}

function AccountDeletionCard({
  account,
  onDeleteCompleted,
}: {
  account: SettingsApiQuery_account;
  onDeleteCompleted: () => void;
}) {
  return (
    <CardV2
      title="Account deletion"
      text="Mark your account for deletion. This will delete both your test and production environments within 24 hours."
    >
      {account.deleted_at ? (
        <Notice
          variant="warning"
          title="Account scheduled for deletion."
          description={
            <>
              This account will be deleted on{" "}
              {new Date(account?.deleted_at).toDateString()}. If you want to
              reverse this, please contact support.
            </>
          }
        />
      ) : account.test_mode === true ? (
        <LeaveTestModeNotice title="To delete your account, switch to the production environment." />
      ) : (
        <AccountDeleteButton
          accountId={account.id}
          onCompleted={onDeleteCompleted}
        />
      )}
    </CardV2>
  );
}

// This is a separate component so settings layout can render on its own without
// showing large spinner. That way user sees context of the page and nav immediately.
function SettingsAccountCards() {
  const { data, error, loading, refetch } = useQuery<SettingsApiQuery>(
    SETTINGS_ACCOUNT_QUERY
  );

  if (loading) return <AnimatedZapLogo />;
  if (error) throw error;
  if (!data) throw new Error("Account failed to load");

  return (
    <CardStack>
      <AccountNameCard account={data.account} onComplete={refetch} />
      <AccountApiKeyCard account={data.account} />
      <AccountDeletionCard account={data.account} onDeleteCompleted={refetch} />
    </CardStack>
  );
}

export function AccountPage(): ReactElement {
  const title = "Account";
  return (
    <SettingsLayout
      title={title}
      lastCrumb={{ label: title, routeName: ROUTE_NAMES.SETTINGS_ACCOUNT }}
    >
      <SettingsAccountCards />
    </SettingsLayout>
  );
}
