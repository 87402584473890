import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef } from "react";

import { ResourceStatus } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_UPDATED_PERSONA_SET,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { Link } from "../ui/Link";
import { RenameModal } from "../ui/RenameModal";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";
import { useUpdatePersonaSet } from "./useUpdatePersonaSet";

type ActionMenuOptions = {
  view?: boolean;
  rename?: boolean;
  info?: () => void;
  api?: boolean;
  archive?: boolean;
  delete?: boolean;
};

const defaultOptions = {
  view: false,
  rename: false,
  api: false,
  archive: false,
  delete: false,
};

export const makeActionsMenuLabel = (name?: string) =>
  name ? `Toggle ${name} actions` : "Toggle actions";

export function PersonaSetActionsMenu({
  personaSet,
  options,
  buttonProps,
  onDeleteComplete,
}: {
  personaSet?: {
    id: string;
    name: string;
    status: ResourceStatus;
    archivedAt?: string | null;
  };
  options?: ActionMenuOptions;
  buttonProps?: Pick<IconButtonProps, "my" | "variant">;
  onDeleteComplete?: () => void;
}) {
  const enabledOptions = { ...defaultOptions, ...options };
  const renameModalState = useDisclosure();
  const apiShortcutsModalProps = useDisclosure();
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const track = useHubSpotEvent();

  const { updatePersonaSet, updating } = useUpdatePersonaSet({
    onCompleted(data) {
      if (!data?.updatePersonaSet) return;

      renameModalState.onClose();

      track(HUBSPOT_USER_UPDATED_PERSONA_SET, {
        resource_id: data.updatePersonaSet.id,
        resource_name: data.updatePersonaSet.name,
      });
    },
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label={makeActionsMenuLabel(personaSet?.name)}
          icon={<DotsThreeOutline weight="fill" />}
          color="fdy_gray.600"
          size="sm"
          variant="secondary"
          {...buttonProps}
        />
        <Portal>
          <MenuList>
            {enabledOptions.info && (
              <MenuItem onClick={enabledOptions.info}>
                About persona sets
              </MenuItem>
            )}

            {enabledOptions.view && personaSet && (
              <MenuItem
                as={Link}
                routeName={ROUTE_NAMES.PERSONAS_ROSTER}
                params={{ roster: personaSet.id }}
              >
                View details
              </MenuItem>
            )}

            {enabledOptions.rename && personaSet && (
              <MenuItem onClick={renameModalState.onOpen}>Rename</MenuItem>
            )}

            {enabledOptions.api && personaSet && (
              <MenuItem onClick={apiShortcutsModalProps.onOpen}>
                API shortcuts
              </MenuItem>
            )}

            {enabledOptions.archive && personaSet && (
              <ArchiveMenuButton
                name={personaSet.name}
                resourceId={personaSet.id}
                resourceType="persona_sets"
                archivedAt={personaSet.archivedAt}
                focusAfterCloseRef={menuBtnRef}
                status={personaSet.status}
              />
            )}

            {enabledOptions.delete && personaSet && (
              <ResourceDeleteButton
                name={personaSet.name ?? "Persona set"}
                resourceId={personaSet.id}
                resourceType="persona_sets"
                onDeleteComplete={onDeleteComplete}
                isMenuItem
                resourceStatus={personaSet.status}
              />
            )}
          </MenuList>
        </Portal>
      </Menu>

      {/* must be outside Menu otherwise event handling conflicts with modal's */}

      {enabledOptions.rename && renameModalState.isOpen && personaSet && (
        <RenameModal
          title="Rename persona set"
          defaultValue={personaSet.name}
          onClose={renameModalState.onClose}
          updateFn={(name) => updatePersonaSet(personaSet.id, { name })}
          updating={updating}
          focusAfterCloseRef={menuBtnRef}
        />
      )}
      {enabledOptions.api && apiShortcutsModalProps.isOpen && personaSet && (
        <ApiShortcutsModal
          resource={{
            id: personaSet.id,
            resource_type: ResourceType.PersonaSets,
          }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
