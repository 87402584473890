import { Table, Tbody, Th, Thead, Tr, VisuallyHidden } from "@chakra-ui/react";
import { CaretDown, CaretUp, CaretUpDown } from "@phosphor-icons/react";
import { useMemo } from "react";
import { useLocalStorage } from "react-use";

import { IconWithText } from "../IconWithText";
import { TextWithInfoTooltip } from "../TextWithInfoTooltip";

// Base resource is a generic object with unknown keys (TS didn't like Record<string, unknown>)
interface Resource {}

type SortState<Datum extends Resource> = {
  /**
   * The key of the data in the resource that this column represents.
   * Used to access the data in the resource and sort it.
   */
  key: keyof Datum;

  /**
   * If true, the data will be sorted in descending order.
   * Otherwise, the data will be sorted in ascending order.
   */
  desc: boolean;
};

type ColumnSortFn = "string" | "number" | "date";

type CustomSortFn<Datum extends Resource> = (
  a: Datum,
  b: Datum,
  desc: boolean
) => number;

export type SortableHeader<Datum extends Resource> = {
  /**
   * The key of the data in the resource that this column represents.
   * If not provided, the column will not be sortable.
   */
  key?: keyof Datum;

  /**
   * Defines the data type of the column. This is used to determine how to sort
   * the data in the column.
   * If not provided, the column will be treated as a string.
   */
  sortingFn?: ColumnSortFn | CustomSortFn<Datum>;

  /**
   * The label to display in the header of the column.
   */
  label: string;

  /**
   * The width of the column in pixels.
   */
  width?: number;

  /**
   * If true, the column will be visually hidden but still accessible to screen
   * Should not be used with sortable columns.
   */
  visuallyHidden?: boolean;

  /**
   * The text to display in the tooltip.
   * Optional: if not provided, no tooltip will be displayed.
   */
  tooltipText?: string;

  /**
   * If set to false, the header column will not be rendered.
   */
  rendered?: boolean;
};

type SortableTableProps<Datum extends Resource> = {
  /**
   * The default sort configuration for the table.
   * Try to use most frequently sorted column by users.
   */
  defaultSort: SortState<Datum>;

  /**
   * The headers to display in the table.
   * Defines which columns are sortable and how to display them.
   */
  headers: SortableHeader<Datum>[];

  /**
   * The data to sort and display in the table.
   */
  data: Datum[];

  /**
   * Callback to render the data rows of the table.
   */
  renderData: (rows: Datum[]) => JSX.Element;

  /**
   * The key to use for storing the sort configuration in local storage.
   * Should be unique across all sortable tables in the application.
   */
  storageKey: string;
};

const sortTableData = <Datum extends Resource>({
  headers,
  data,
  sortConfig,
}: {
  headers: SortableHeader<Datum>[];
  data: Datum[];
  sortConfig: SortState<Datum>;
}) => {
  const dataType: SortableHeader<Datum>["sortingFn"] =
    headers.find((header) => header.key === sortConfig.key)?.sortingFn ??
    "string";

  return [...data].sort((a, b) => {
    const { key, desc } = sortConfig;

    const aValue = a[key];
    const bValue = b[key];

    if (typeof dataType === "function") {
      return dataType(a, b, sortConfig.desc);
    }

    if (dataType === "string") {
      return desc
        ? String(bValue).localeCompare(String(aValue))
        : String(aValue).localeCompare(String(bValue));
    }

    if (dataType === "number") {
      return desc
        ? Number(bValue) - Number(aValue)
        : Number(aValue) - Number(bValue);
    }

    if (dataType === "date") {
      return desc
        ? // string casting so it works for date strings or instances
          new Date(String(bValue)).getTime() -
            new Date(String(aValue)).getTime()
        : new Date(String(aValue)).getTime() -
            new Date(String(bValue)).getTime();
    }

    return 0;
  });
};

type TableHeadingProps<Datum extends Resource> = {
  header: SortableHeader<Datum>;
  tooltipText?: string;
  sortConfig: SortState<Datum>;
  onClick: () => void;
};

const MaybeTooltip = ({
  tooltip,
  children,
}: {
  tooltip?: string;
  children: React.ReactNode;
}) => {
  if (tooltip) {
    return <TextWithInfoTooltip title={children} tooltip={tooltip} />;
  }

  return <>{children}</>;
};

const TableHeading = <Datum extends Resource>({
  header,
  sortConfig,
  onClick,
}: TableHeadingProps<Datum>) => {
  if (header.sortingFn && !header.key) {
    throw new Error(
      `Column with data type "${header.sortingFn}" must have a key to be sortable.`
    );
  }

  if (header.rendered === false) {
    return null;
  }

  // visually hidden columns should not be sortable
  if (header.visuallyHidden) {
    return (
      <Th style={{ width: header.width }}>
        <VisuallyHidden>{header.label}</VisuallyHidden>
      </Th>
    );
  }

  const labelNode = (
    <MaybeTooltip tooltip={header.tooltipText}>{header.label}</MaybeTooltip>
  );

  // key denotes that the column is sortable
  if (header.key) {
    const directionIcon = sortConfig.desc ? <CaretDown /> : <CaretUp />;

    const sortIcon =
      sortConfig.key === header.key ? directionIcon : <CaretUpDown />;

    return (
      <Th style={{ width: header.width }}>
        <button
          onClick={onClick}
          aria-sort={sortConfig.desc ? "descending" : "ascending"}
          style={{ fontWeight: "inherit" }}
        >
          <IconWithText>
            {labelNode}
            {sortIcon}
          </IconWithText>
        </button>
      </Th>
    );
  }

  return <Th style={{ width: header.width }}>{labelNode}</Th>;
};

/**
 * A sortable table component that allows users to sort the data by clicking on the headers.
 * Clicking on the same header will toggle the sort direction.
 */
export function SortableTable<Datum extends Resource>({
  storageKey,
  headers,
  data,
  renderData,
  defaultSort,
}: SortableTableProps<Datum>) {
  const [sortConfigStored, setSortConfig] = useLocalStorage<SortState<Datum>>(
    `sortable_table__${storageKey}`,
    defaultSort
  );

  const sortConfig = sortConfigStored ?? defaultSort;

  const sortedData = useMemo(() => {
    return sortTableData({ headers, data, sortConfig });
  }, [headers, data, sortConfig]);

  const handleHeaderClick = (header: SortableHeader<Datum>) => {
    // if clicking on the same header, toggle the direction
    if (sortConfig.key === header.key && header.key) {
      setSortConfig({
        key: header.key,
        desc: !sortConfig.desc,
      });
    }
    // otherwise, set the sort config to ascending
    else if (header.key) {
      setSortConfig({
        key: header.key,
        desc: false,
      });
    }
  };

  return (
    <Table>
      <Thead>
        <Tr>
          {headers.map((header) => (
            <TableHeading<Datum>
              key={header.label}
              header={header}
              sortConfig={sortConfig}
              onClick={() => handleHeaderClick(header)}
            />
          ))}
        </Tr>
      </Thead>
      <Tbody>{renderData(sortedData)}</Tbody>
    </Table>
  );
}
