import { Flex, Switch } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Check } from "@phosphor-icons/react";
import { useId, useMemo } from "react";
import Select, {
  components,
  GroupBase,
  InputProps,
  OptionProps,
  StylesConfig,
} from "react-select";

import { theme } from "../../constants/theme";
import { useTestMode } from "../../hooks/useTestMode";
import chakraThemeV2, { colors, fontSizes } from "../../styles/chakra-theme-v2";
import { AccountSwitcherQuery } from "./__generated__/AccountSwitcherQuery";

type AccountSwitcherSelectOption = {
  label: string;
  value: string;
  active: boolean;
};

const StyledAccountItem = styled.button`
  display: flex;
  width: 100%;
  text-align: left;
  padding: ${theme.space()};
  color: ${colors.fdy_gray[200]};
  font-size: ${fontSizes.fdy_sm};
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-family: ${chakraThemeV2.fonts.body};
  transition: 0.2s ease-in-out;
  background-color: ${colors.fdy_gray[800]};
  border-radius: 6px;
  &:focus,
  &:hover {
    background-color: ${colors.fdy_gray[900]};
    color: ${colors.fdy_gray[100]};
  }
`;
function AccountSwitchToggle() {
  const switchId = useId();
  const { toggleTestMode, isInTestMode, toggling } = useTestMode();

  return (
    <div
      style={{
        overflow: "hidden",
        paddingLeft: theme.space(),
        paddingRight: theme.space(),
        backgroundColor: colors.fdy_gray[800],
      }}
    >
      <StyledAccountItem as="div">
        <label
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          htmlFor={switchId}
        >
          Test mode
        </label>

        <Switch
          id={switchId}
          isChecked={isInTestMode}
          onChange={toggleTestMode}
          isDisabled={toggling}
        />
      </StyledAccountItem>
    </div>
  );
}

const selectStyles: StylesConfig<AccountSwitcherSelectOption, false> = {
  control: (base) => ({
    ...base,
    minWidth: 240,
    margin: 8,
    boxShadow: "none",
    backgroundColor: colors.fdy_gray[900],
    color: colors.fdy_gray[200],
    border: 0,
    ":hover": {
      border: "none",
      boxShadow: "none",
    },
    cursor: "text",
  }),
  menu: () => ({
    boxShadow: "none",
    backgroundColor: colors.fdy_gray[800],
    maxHeight: theme.space(80),
    overflow: "auto",
    padding: theme.space(1),
  }),
  container: (base) => ({
    ...base,
    backgroundColor: colors.fdy_gray[800],
  }),
  dropdownIndicator: () => ({ display: "none" }),
  option: (base, { isFocused }) => ({
    ...base,
    color: colors.fdy_gray[200],
    backgroundColor: isFocused ? colors.fdy_gray[900] : colors.fdy_gray[800],
    borderRadius: "6px",
    ":focus,:hover": {
      backgroundColor: colors.fdy_gray[900],
      color: colors.fdy_gray[100],
    },
    paddingLeft: theme.space(),
    fontFamily: chakraThemeV2.fonts.body,
    textAlign: "left",
    transition: "0.2s ease-in-out",
    cursor: "pointer",
    fontSize: fontSizes.fdy_sm,
  }),
  input: (base) => ({
    ...base,
    color: colors.fdy_gray[200],
  }),
};

function Option<
  Option extends AccountSwitcherSelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: OptionProps<Option, IsMulti, Group>) {
  return (
    <components.Option {...props}>
      <Flex
        sx={{
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {props.children}
        {props.data.active ? (
          <div
            style={{
              width: "20px",
              height: "20px",
            }}
          >
            <Check color={colors.fdy_gray[200]} size={22} />
          </div>
        ) : null}
      </Flex>
    </components.Option>
  );
}

export function AccountSwitcherSelect({
  account,
  accounts,
  handleAccountSwitch,
}: {
  account: AccountSwitcherQuery["account"];
  accounts: AccountSwitcherQuery["user"]["accounts"];
  handleAccountSwitch: (accountId: string) => void;
}) {
  const sortedOptions = useMemo(() => {
    return accounts
      .filter((a) => !a.test_mode)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((a) => ({
        value: a.id,
        label: a.name,
        active: account.id === a.id || account.id === a.test_account?.id,
      }));
  }, [accounts]);
  const CustomInput = <
    Option extends AccountSwitcherSelectOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
  >(
    props: InputProps<Option, IsMulti, Group>
  ) => <components.Input {...props} autoFocus />;
  return (
    <>
      {account.test_mode || account.test_account?.id ? (
        <AccountSwitchToggle />
      ) : null}
      <Select<AccountSwitcherSelectOption>
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator: null,
          IndicatorSeparator: null,
          Input: CustomInput,
          Option,
        }}
        options={sortedOptions}
        onChange={(selected) => {
          if (selected) {
            handleAccountSwitch(selected.value);
          }
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        placeholder="Search accounts..."
        tabSelectsValue={false}
        menuIsOpen
        styles={selectStyles}
      />
    </>
  );
}
