import { Box, Tooltip } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

/**
 * Renders text with a tooltip if the text would be truncated.
 * Source: https://github.com/chakra-ui/chakra-ui/issues/4202#issuecomment-858481502
 */
export function OverflownText({ children }: { children: React.ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = ref.current!;
    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  return (
    <Tooltip label={children} isDisabled={!isOverflown}>
      <Box position="relative" isTruncated ref={ref}>
        {children}
      </Box>
    </Tooltip>
  );
}
