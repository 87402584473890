import { gql } from "@apollo/client";

import { useSojournerQuery } from "../../services/sojournerApolloClient";
import {
  OutcomesShowPageQuery,
  OutcomesShowPageQueryVariables,
} from "./__generated__/OutcomesShowPageQuery";
import { outcomeFragment } from "./outcomeFragment";

export const outcomesShowPageQuery = gql`
  query OutcomesShowPageQuery($outcomeId: ID!) {
    outcome(outcomeId: $outcomeId) {
      ...OutcomeFragment
    }
  }
  ${outcomeFragment}
`;

export function useOutcomeQuery(outcomeId: string) {
  return useSojournerQuery<
    OutcomesShowPageQuery,
    OutcomesShowPageQueryVariables
  >(outcomesShowPageQuery, {
    variables: {
      outcomeId,
    },
  });
}
