import { useRef } from "react";

import { uuid } from "../../utils/uuid";

/**
 * Returns a string that will be a stable id across re-renders, with an optional
 * prefix to act as a human readable identifier.
 * @param prefix
 */
export function useId(prefix?: string): string {
  const idRef = useRef<string>();
  if (!idRef.current) {
    idRef.current = `${prefix || "gen"}__${uuid()}`;
  }

  return idRef.current;
}
