import { InputType, TextField } from "../../ui/TextField";

export function validateDatasetName(name: string) {
  const trimmedName = name.trim();

  if (!trimmedName) {
    return "Please name this dataset to continue";
  }

  if (trimmedName.length > 64) {
    return "Name must be 64 characters or less";
  }
}

export function DatasetsCreateNameField({
  name,
  setName,
  error,
  setError,
}: {
  name: string;
  setName: (val: string) => void;
  error: string;
  setError: (val: string) => void;
}) {
  return (
    <TextField
      label="Dataset name"
      name="dataset_name"
      error={error}
      type={InputType.text}
      required={true}
      value={name}
      onChange={(value) => {
        setName(value);
        if (value) {
          setError("");
        }
      }}
      analyticsName="dataset-name"
    />
  );
}
