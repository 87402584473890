import { ReactElement } from "react";

import { DocsLinkList, DocsLinkListProps } from "../ui/DocsLinkList";
import { Prose } from "../ui/Prose";

const helpLinks: DocsLinkListProps["links"] = [
  {
    type: "external",
    href: "https://faraday.ai/docs/abstractions/pipelines",
    label: "How to create pipelines",
  },
  {
    type: "external",
    href: "https://faraday.ai/docs/abstractions/deployments",
    label: "Adding deployments",
  },
];

export function PipelineInfoSidebar(): ReactElement {
  return (
    <>
      <Prose>
        <p>
          Pipelines allow you to easily define what predictions you want, who
          you want them on, and where you want them to be deployed.
        </p>

        <p>
          From there, Faraday will automatically keep your pipeline up to date,
          deploying new versions of predictions as needed.
        </p>

        <p>When creating a pipeline, you will get a preview of the result.</p>
      </Prose>

      <DocsLinkList links={helpLinks} />
    </>
  );
}
