import {
  Edge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  Node,
} from "reactflow";

import {
  EDGE_COLOR_SUBDUED,
  HIGHLIGHT_COLOR,
  MARKER_END_HIGHLIGHT,
  MARKER_END_SUBDUED,
  MARKER_START_HIGHLIGHT,
  MARKER_START_SUBDUED,
} from "../config";
import { ResourceGraphNodeType, ResourceNodeData } from "../types";

function findConnectedNodes(
  nodes: Node[],
  edges: Edge[],
  selectedNode: Node | null
) {
  if (!selectedNode) return [];

  return nodes.filter((node) => {
    const outgoers = getIncomers(selectedNode, nodes, edges);
    const incomers = getOutgoers(selectedNode, nodes, edges);

    const outgoerIds = outgoers.map((node) => node.id);
    const incomerIds = incomers.map((node) => node.id);

    return (
      selectedNode.id === node.id ||
      outgoerIds.includes(node.id) ||
      incomerIds.includes(node.id)
    );
  });
}

/**
 * Creates styles for connected nodes to highlight the selected node.
 * Only the selected node and its connected nodes are highlighted (depth of 1).
 *
 * Note: If these were inline styles, which many reactflow examples use, they would
 * not be coupled to Chakra styling features and easier to trace, but I ran into re-rendering
 * problems when trying to implement it that way. Edges with inline styles work fine though.
 */
export function highlightNodeStyles(
  nodes: Node[],
  edges: Edge[],
  selectedNode: Node | null
) {
  if (!selectedNode) return {};

  const connectedNodes = findConnectedNodes(nodes, edges, selectedNode);

  const nodeClasses = connectedNodes
    .map((node) => `& [data-id="${node.id}"]`)
    .join(",");

  const connectedStyles = {
    ".react-flow__node": {
      transition: "opacity .1s ease-in-out",
    },

    // visually de-prioritize all nodes
    [`.react-flow__node-${ResourceGraphNodeType.Resource}`]: {
      zIndex: -1, // render below edges
      opacity: 0.2,
    },

    // then highlight the selected node and its connected nodes
    [nodeClasses]: {
      opacity: 1,
      zIndex: 1,
    },
  };

  return connectedStyles;
}

/**
 * Given a list of edges and a selected node, highlight the edges that are
 * connected to the selected node and reduce the appearance of the other edges.
 *
 * Highlighted edges must be last in the list so they show up on top of other
 * edges (since svg does not support z-index).
 *
 * If no node is selected, all edges are returned with their default styles.
 */
export function highlightConnectedEdges(
  edges: Edge[],
  selectedNode: Node<ResourceNodeData> | null
): Edge[] {
  if (!selectedNode) return edges;

  const connectedEdges = getConnectedEdges([selectedNode], edges);

  const highlightedEdges = [];
  const subduedEdges = [];

  for (const edge of edges) {
    if (connectedEdges.includes(edge)) {
      highlightedEdges.push({
        ...edge,
        style: {
          ...edge.style,
          stroke: HIGHLIGHT_COLOR,
        },
        markerStart: MARKER_START_HIGHLIGHT,
        markerEnd: MARKER_END_HIGHLIGHT,
      });
    } else {
      subduedEdges.push({
        ...edge,
        style: {
          ...edge.style,
          stroke: EDGE_COLOR_SUBDUED,
        },
        markerStart: MARKER_START_SUBDUED,
        markerEnd: MARKER_END_SUBDUED,
      });
    }
  }

  return [
    ...subduedEdges,
    // last so they show up on top of other edges (since svg does not support z-index)
    ...highlightedEdges,
  ];
}
