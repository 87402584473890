import { Box, Heading, Text } from "@chakra-ui/react";
import { ReactNode, Suspense } from "react";

import { AnalyticsStack } from "../Analytics/AnalyticsStack";
import { AnimatedZapLogo } from "../AnimatedZapLogo";
import { ErrorBoundary } from "../ErrorBoundary";
import { useId } from "../useId";

interface CardV2Props {
  title?: string;
  text?: ReactNode;
  suffix?: ReactNode;
  children?: ReactNode;
  analyticsStackName?: string;
}

/**
 * A generic 'card' container/island of content, with title and text.
 * Meant for use in new ChakraUI based design.
 */
export function CardV2({
  title,
  suffix,
  text,
  children,
  analyticsStackName,
}: CardV2Props) {
  const id = useId("card");
  return (
    <AnalyticsStack value={analyticsStackName}>
      <Box
        as="section"
        p={8}
        borderRadius="md"
        bg="white"
        border="1px solid"
        borderColor="fdy_gray.200"
        aria-labelledby={title ? id : undefined}
      >
        {title || text ? (
          <Box as="header" mb={children ? 8 : undefined}>
            <Box display="flex" gap={2} alignItems="center">
              {title && (
                <Heading as="h2" variant="h4" id={id}>
                  {title}
                </Heading>
              )}

              {suffix && (
                <Text
                  as="span"
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "fdy_sm",
                    color: "fdy_gray.700",
                  }}
                >
                  {suffix}
                </Text>
              )}
            </Box>

            {text && (
              <Text
                fontSize="fdy_lg"
                mt={title ? 2 : undefined}
                color="fdy_gray.700"
              >
                {text}
              </Text>
            )}
          </Box>
        ) : null}
        <Suspense fallback={<AnimatedZapLogo />}>
          {children ? <ErrorBoundary>{children}</ErrorBoundary> : null}
        </Suspense>
      </Box>
    </AnalyticsStack>
  );
}
