import React, { useMemo, useState } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useAccountConfigMap } from "../../../hooks/accountConfigHooks";
import { useTraitsQuery } from "../../../hooks/useTraitsQuery";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { ResourceTableList } from "../../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../../ui/ResourceTableTabs";
import { SortableHeader, SortableTable } from "../../ui/SortableTable";
import {
  CohortsListPageQuery,
  CohortsListPageQuery_cohorts,
} from "./__generated__/CohortsListPageQuery";
import { Cohort, cohortsListPageQuery } from "./CohortsListPage";
import { CohortsTableRow } from "./CohortsTableRow";

const BLANK_SLATE_PROPS = {
  title: "No Cohorts found",
  text: "Cohorts are based on Event Streams and sometimes Traits, which are emitted by Datasets. They are used to create Outcomes and Pipelines.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Start by adding a Dataset if you haven't already.",
  },
};

export const showInExplore =
  "Toggle a resource to enable it in Explore. Explore lets you use traits to compare your cohorts, persona sets, and to the U.S. population baseline to each other.";

export function CohortsTable() {
  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);
  const config = useAccountConfigMap();
  const exploreEnabled = config["explore.enabled"];

  // query all cohorts
  const { data, loading, error } = useSojournerQuery<CohortsListPageQuery>(
    cohortsListPageQuery,
    {
      pollInterval: 10000,
    }
  );

  // Because our `GET /cohorts` does not send the meta info about traits
  // along with the trait _conditions_, we need to query all traits so we can join on client side
  // to get meta info about the traits, like literate.
  const { traitsMap, loadingTraits } = useTraitsQuery();

  const filteredCohorts = useMemo(
    () => filterResourceTabs(data?.cohorts, activeTab),
    [data, activeTab]
  );

  // Only show Places to users that have them preconfigured
  // (so we don't busy the listing page for general users).
  const showPlaces =
    data?.cohorts.some((c) => c.placeConditions.length) ?? false;

  const renderCohortRow = (cohort: Cohort) => {
    return (
      <CohortsTableRow
        traitsMap={traitsMap}
        cohort={cohort}
        exploreEnabled={exploreEnabled}
        showPlaces={showPlaces}
      />
    );
  };

  const tableHeaders: SortableHeader<CohortsListPageQuery_cohorts>[] = [
    {
      key: "name",
      label: "Name",
      width: 200,
    },
    {
      key: "populationCount",
      label: "Population",
      sortingFn: "number",
    },
    {
      key: "streamName",
      label: "Event",
      sortingFn: "string",
    },
    {
      label: "Traits",
      width: 200,
    },
    {
      label: "Places",
      rendered: showPlaces,
    },
    {
      key: "status",
      label: "Status",
      sortingFn: "string",
    },
    {
      key: "createdAt",
      label: "Created",
      sortingFn: "date",
    },
    {
      key: "lastUpdatedConfigAt",
      label: "Modified",
      sortingFn: "date",
    },
    {
      key: "lastUpdatedOutputAt",
      label: "Processed",
      sortingFn: "date",
    },
    {
      label: "Explore",
      rendered: exploreEnabled,
      tooltipText: showInExplore,
    },
    {
      label: "Actions",
      width: 64,
      visuallyHidden: true,
    },
  ];

  return (
    <>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data?.cohorts}
      />
      <SortableTable
        storageKey="cohorts"
        defaultSort={{
          key: "createdAt",
          desc: true,
        }}
        data={filteredCohorts}
        headers={tableHeaders}
        renderData={(data) => (
          <ResourceTableList<Cohort>
            data={data}
            loading={loading || loadingTraits}
            renderData={renderCohortRow}
            error={error}
            blankSlateProps={BLANK_SLATE_PROPS}
          />
        )}
      />
    </>
  );
}
