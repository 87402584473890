import { Box, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import {
  analyticsAttrs,
  useAnalyticsKeyGenerator,
} from "./Analytics/AnalyticsStack";

interface RadioOption<Value extends string, ExtraData = undefined> {
  label: string;
  value: Value;
  sublabel?: string;
  helpText?: ReactNode;
  disabled?: boolean;
  renderAfter?: (props: { data: ExtraData; selected: boolean }) => ReactNode;
}

export interface RadioGroupV2Props<
  Value extends string,
  ExtraData = undefined
> {
  options: readonly RadioOption<Value, ExtraData>[];
  name: string;
  value?: Value;
  defaultValue?: string;
  renderAfterData?: ExtraData;
  onChange?: (value: Value) => void;
  inline?: boolean;
  disabled?: boolean;
  analyticsName?: string;
}

export function RadioGroupV2<Value extends string, ExtraData = undefined>({
  options,
  value,
  name,
  onChange,
  defaultValue,
  renderAfterData,
  inline,
  disabled,
  analyticsName,
}: RadioGroupV2Props<Value, ExtraData>): ReactElement {
  const getAnalyticsKey = useAnalyticsKeyGenerator(analyticsName);
  return (
    <RadioGroup
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      isDisabled={disabled}
    >
      <Flex gap={4} flexDirection={inline ? "row" : "column"}>
        {options.map((opt) => {
          const after = opt.renderAfter
            ? opt.renderAfter({
                data: renderAfterData as ExtraData,
                selected: opt.value === value,
              })
            : null;

          return (
            <Box key={opt.value}>
              <Radio
                name={name}
                value={opt.value}
                isDisabled={opt.disabled}
                {...analyticsAttrs(getAnalyticsKey(opt.value.toLowerCase()))}
              >
                <Flex>
                  <Text color="fdy_gray.900" as="span" fontWeight="bold">
                    {opt.label}
                  </Text>
                  {opt.sublabel && (
                    <>
                      <Text as="span" color="fdy_gray.700" mx={1}>
                        &mdash;
                      </Text>
                      <Text color="fdy_gray.700" as="span">
                        {opt.sublabel}
                      </Text>
                    </>
                  )}
                </Flex>
                <Text color="fdy_gray.700" fontSize="fdy_sm">
                  {opt.helpText}
                </Text>
              </Radio>
              {after && (
                <Box pl={6} pt={4}>
                  {after}
                </Box>
              )}
            </Box>
          );
        })}
      </Flex>
    </RadioGroup>
  );
}
