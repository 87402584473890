import { Box } from "@chakra-ui/react";

import { ManagedExplanation } from "../../../ui/ManagedExplanation";
import { ConnectionSetupLink } from "./ConnectionSetupLink";
import { TargetCardProps } from "./TargetCard";
import { PreviewPill, TargetCardBase } from "./TargetCardBase";
import { TargetListItemConfig } from "./TargetListItemConfig";
import { TargetPreviewButton } from "./TargetPreviewButton";

export function TargetCardManaged(props: TargetCardProps) {
  const { target, scope, traitsMap, scopeDeps, connections } = props;
  const scopeEnabled = scope.preview === false;
  return (
    <TargetCardBase
      {...props}
      notice={<ManagedExplanation resourceType="deployment" />}
      actions={{ edit: false, delete: true }}
    >
      <TargetListItemConfig
        target={target}
        scopeDeps={scopeDeps}
        traitsMap={traitsMap}
        connections={connections}
      />
      {scopeEnabled ? null : <PreviewPill />}
      <ConnectionSetupLink target={props.target} />
      <Box mt={3}>
        {!scopeEnabled ? <TargetPreviewButton target={target} /> : null}
      </Box>
    </TargetCardBase>
  );
}
