import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef } from "react";

import { ResourceStatus } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { Link } from "../ui/Link";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";

type ActionMenuOptions = {
  view?: boolean;
  edit?: boolean;
  api?: boolean;
  delete?: boolean;
  archive?: boolean;
};

const defaultOptions = {
  view: false,
  edit: false,
  api: false,
  delete: false,
  archive: false,
};

/**
 * Renders a dropdown menu with various actions a user can take on a given Connection.
 */
export function ConnectionsActionsMenu({
  connection,
  onDeleteComplete,
  options,
  buttonProps,
}: {
  connection: {
    id: string;
    name: string;
    status: ResourceStatus;
    archivedAt?: string | null;
  };
  options: ActionMenuOptions;
  onDeleteComplete?: () => void;
  buttonProps?: Pick<IconButtonProps, "my" | "variant">;
}) {
  const enabledOptions = { ...defaultOptions, ...options };
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const apiShortcutsModalProps = useDisclosure();

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label="Toggle Connection actions"
          icon={<DotsThreeOutline weight="fill" />}
          variant="secondary"
          color="fdy_gray.600"
          size="sm"
          {...buttonProps}
        />

        <MenuList>
          {enabledOptions.view && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.CONNECTIONS_SHOW}
              params={{ id: connection.id }}
            >
              View details
            </MenuItem>
          )}

          {enabledOptions.edit && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.CONNECTIONS_EDIT}
              params={{ id: connection.id }}
            >
              Edit
            </MenuItem>
          )}

          {enabledOptions.api && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API shortcuts
            </MenuItem>
          )}

          {enabledOptions.archive && connection && (
            <ArchiveMenuButton
              name={connection.name}
              resourceId={connection.id}
              resourceType="connections"
              archivedAt={connection.archivedAt}
              focusAfterCloseRef={menuBtnRef}
              status={connection.status}
            />
          )}

          {enabledOptions.delete && (
            <ResourceDeleteButton
              isMenuItem
              resourceType="connections"
              resourceId={connection.id}
              name={connection.name}
              onDeleteComplete={onDeleteComplete}
              resourceStatus={connection.status}
            />
          )}
        </MenuList>
      </Menu>

      {enabledOptions.api && apiShortcutsModalProps.isOpen && (
        <ApiShortcutsModal
          resource={{
            id: connection.id,
            resource_type: ResourceType.Connections,
          }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
