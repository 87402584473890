import { Box, Heading, List, ListItem, useId } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import isEqual from "lodash/isEqual";
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";

import { CohortTraitInput } from "../../../../__generated__/sojournerGlobalTypes";
import { Trait, useTraitsQuery } from "../../../../hooks/useTraitsQuery";
import { ActionableCardItem } from "../../../ui/ActionableCardItem";
import { Button } from "../../../ui/Button";
import { TraitSearchCriteria } from "../../../ui/TraitSelectorModal";
import { TraitCondition, TraitConditionState } from "../CohortForm";
import { FormSection } from "../FormSection";
import { CohortTraitsSelectModal } from "./CohortTraitSelectModal";
import { CohortTraitsSetupModal } from "./CohortTraitsSetupModal";
import { CohortTraitSummary } from "./CohortTraitSummary";
import { traitHasAtLeastOneOperator } from "./utils";

function groupTraitsByOptionality(traits: TraitConditionState): {
  required: TraitCondition[];
  optional: TraitCondition[];
} {
  const required: TraitCondition[] = [];
  const optional: TraitCondition[] = [];

  if (traits) {
    traits.forEach((trait) => {
      if (trait.optional) {
        optional.push(trait);
      } else {
        required.push(trait);
      }
    });
  }
  return { required, optional };
}

/**
 * Render a list of traits, labeled with a heading.
 * Each trait renders its actions list in the '...' menu.
 */
function TraitGroupList({
  heading,
  conditions,
  onEdit,
  onDelete,
}: {
  heading: ReactNode;
  conditions: TraitCondition[];
  onEdit: (condition: TraitCondition) => void;
  onDelete: (condition: TraitCondition) => void;
}) {
  // apply id to describe the section (useful for testing)
  const id = useId();

  const { traitsMap } = useTraitsQuery();

  if (conditions.length === 0) return null;

  return (
    <Box as="section" mt={8} aria-labelledby={id}>
      <Heading sx={{ fontWeight: "normal", fontSize: "fdy_md", mb: 2 }} id={id}>
        {heading}
      </Heading>
      <List display="grid" gap={2}>
        {conditions.map((cond, i) => {
          const { name } = cond;

          const trait = traitsMap[name];
          const literate = trait?.literate ?? name;

          return (
            <ListItem key={name + i}>
              <ActionableCardItem
                title={literate}
                content={<CohortTraitSummary trait={trait} condition={cond} />}
                menuBtnLabel={`${literate} trait options`}
                onDelete={() => onDelete(cond)}
                onEdit={() => onEdit(cond)}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

enum TraitModalMode {
  /** No modal open */
  Closed,
  /** Selecting a trait */
  Select,
  /** Modifying that newly selected trait */
  Setup,
  /** Modifying an existing trait */
  Edit,
}

/**
 * Renders a card that contains the list of cohort traits (fdy_field_conditions) applied to the cohort.
 * Users can add a new trait or modify/remove an existing trait from the list.
 * Changes to this list are surfaced through a callback.
 */
export function CohortTraitsCard({
  traits,
  onTraitsChange,
  error,
  availableTraits,
}: {
  availableTraits: Trait[];
  traits: TraitConditionState;
  onTraitsChange: Dispatch<SetStateAction<TraitConditionState>>;
  error?: string;
}) {
  // Keep track of which modal is open.
  const [modalMode, setModalMode] = useState<TraitModalMode>(
    TraitModalMode.Closed
  );

  // Store the search criteria for the trait selection modal
  // so it's remembered for user when they click back after selecting a trait.
  const [searchCriteria, setSearchCriteria] = useState<TraitSearchCriteria>({
    query: "",
    categories: [],
  });

  // Track any errors we may want to display to user.
  const [traitSelectError, setTraitSelectError] = useState<string>();

  // track error for the setup/edit modal
  const [traitEditError, setTraitEditError] = useState<string>();

  // Store the currently selected trait so we can pass it to the set up step.
  const [selectedTrait, setSelectedTrait] = useState<Trait>();

  // store the index of the trait we will update. So we can update it in the list of traits.
  const [traitEditIndex, setTraitEditIndex] = useState<number>();

  // Store what trait is currently being edited.
  // Used when we select a new trait to then edit, or when editing a currently applied trait
  const [traitEditCondition, setTraitEditCondition] =
    useState<TraitCondition>();

  // Organize traits into required and optional groups.
  const traitGroups = useMemo(() => groupTraitsByOptionality(traits), [traits]);

  /**
   * Clean up all state for trait setup or edit modals.
   */
  function cleanupModals() {
    setTraitSelectError(undefined);
    setTraitEditError(undefined);
    setSelectedTrait(undefined);
    setTraitEditIndex(undefined);
    setTraitEditCondition(undefined);
    setModalMode(TraitModalMode.Closed);
    setSearchCriteria({ query: "", categories: [] });
  }

  /**
   * Handles when user clicks the 'add trait' button.
   */
  function handleAddTraitClick() {
    setModalMode(TraitModalMode.Select);
  }

  /**
   * Handle when user clicks 'next' on the trait selection modal.
   * We set the currently edited trait to be that of the trait currently active in the list of available traits.
   * Then we can proceed to edit that trait's conditions (gt, lt, etc) in the 'next' modal.
   */
  function handleAddTraitNext() {
    if (!selectedTrait) {
      setTraitSelectError("Please select a trait to continue");
      return;
    }

    setTraitEditCondition({
      name: selectedTrait.name,
      optional: false,
    });

    setModalMode(TraitModalMode.Setup);
  }

  /**
   * Handle when values of a trait condition are changed via the trait setup/edit modal.
   */
  function handleTraitEditChange(condition: CohortTraitInput) {
    setTraitEditCondition((prev) => {
      if (!prev) return prev;
      return {
        ...condition,
        // ensure optional doesn't get modified, because that state change
        // happens separately from other trait condition changes.
        optional: condition.optional ?? prev.optional,
      };
    });
  }

  /**
   * Handle clicking 'back' on the trait setup modal.
   * Should return the user to the trait selection modal.
   */
  function handleTraitEditBack() {
    setModalMode(TraitModalMode.Select);
  }

  function validateTrait(next: (condition: CohortTraitInput) => void) {
    if (!traitEditCondition) {
      // user should not be able to get here without a trait condition
      throw new Error("No trait condition to add to list");
    }

    if (traitHasAtLeastOneOperator(traitEditCondition) === false) {
      setTraitEditError("At least one condition is required");
      return;
    }

    // Disallow adding duplicate traits to lists.
    // Users can add two of the same trait if one is required and the other is optional,
    // otherwise duplicate required traits will likely conflict with eachother.
    const existingTrait = traits?.find(
      (t, i) =>
        t.name === traitEditCondition.name &&
        t.optional === traitEditCondition.optional &&
        traitEditIndex !== i
    );

    if (existingTrait) {
      setTraitEditError(
        "Trait already applied as required. Please make it optional."
      );
      return;
    }

    next(traitEditCondition);
  }

  /**
   * Handle when user clicks 'finish' on the final step of the trait setup modals.
   */
  function handleSetupTraitFinish(condition: CohortTraitInput) {
    onTraitsChange((current) => [...(current ?? []), condition]);

    cleanupModals();
  }

  /**
   * Update the exact trait which was edited.
   * This is a workaround to using a unique ID for each trait
   * and means we cannot allow 2 traits with the exact same conditions (why would you have that?).
   */
  function handleEditTraitFinish(condition: CohortTraitInput) {
    if (traitEditIndex === undefined) {
      throw new Error("No trait index to update");
    }

    onTraitsChange((prev) => {
      return prev?.map((cond, i) => {
        if (i === traitEditIndex) {
          return condition;
        }
        return cond;
      });
    });

    cleanupModals();
  }

  /**
   * Handle changing a trait from required to optional or vice versa.
   */
  function handleTraitOptionalityChange(optional: boolean) {
    if (!traitEditCondition) {
      throw new Error("No trait condition to update");
    }

    setTraitEditCondition({
      ...traitEditCondition,
      optional,
    });
  }

  /**
   * Handle canceling adding a new trait.
   */
  function handleAddTraitCancel() {
    cleanupModals();
  }

  /**
   * Handle when user clicks 'edit' on a trait.
   */
  function handleTraitEditClick(condition: TraitCondition) {
    const trait = availableTraits.find((t) => t.name === condition.name);

    // are we supposed to allow editing a trait
    // that is no longer returned from GET /traits?
    // maybe display it as immutable?
    if (!trait) {
      throw new Error("Trait no longer exists");
    }

    const idx = traits?.findIndex((t) => isEqual(t, condition));

    if (idx === undefined || idx === -1) {
      throw new Error("Trait not found in list of applied traits.");
    }

    setTraitEditIndex(idx);
    setSelectedTrait(trait);
    setTraitEditCondition(condition);
    setModalMode(TraitModalMode.Edit);
  }

  /**
   * Handle removing a trait from the list of applied traits.
   */
  function handleDeleteTrait(condition: TraitCondition) {
    onTraitsChange((prev) => prev?.filter((t) => t !== condition));
  }

  /**
   * Handle when user clicks 'cancel' on the trait edit modal.
   */
  function handleEditModalCancel() {
    cleanupModals();
  }

  function handleTraitSelect(trait: Trait) {
    setSelectedTrait(trait);
    setTraitSelectError(undefined);
  }

  return (
    <>
      <FormSection
        title="Traits"
        suffix="Less common"
        text="You can also require that people currently exhibit certain traits, or attributes, in order to become a member of this cohort. For example, you can set geographic, demographic, and other constraints here."
        error={error}
      >
        <Button
          variant="secondary"
          leftIcon={<Plus weight="bold" />}
          onClick={handleAddTraitClick}
          width="100%"
          analyticsName="add-trait"
        >
          Add trait
        </Button>

        <TraitGroupList
          heading={
            <>
              <strong>All</strong> of these traits <strong>must apply</strong>
            </>
          }
          conditions={traitGroups.required}
          onEdit={handleTraitEditClick}
          onDelete={handleDeleteTrait}
        />

        <TraitGroupList
          heading={
            <>
              <strong>At least one</strong> of these traits{" "}
              <strong>must apply</strong>
            </>
          }
          conditions={traitGroups.optional}
          onEdit={handleTraitEditClick}
          onDelete={handleDeleteTrait}
        />
      </FormSection>

      {modalMode === TraitModalMode.Select && (
        <CohortTraitsSelectModal
          searchCriteria={searchCriteria}
          setSearchCriteria={setSearchCriteria}
          selectedTrait={selectedTrait}
          onTraitSelect={handleTraitSelect}
          availableTraits={availableTraits}
          onNext={handleAddTraitNext}
          onCancel={handleAddTraitCancel}
          error={traitSelectError}
        />
      )}

      {modalMode === TraitModalMode.Setup &&
        selectedTrait &&
        traitEditCondition && (
          <CohortTraitsSetupModal
            title="Add trait"
            backButtonLabel="Back"
            condition={traitEditCondition}
            onBack={handleTraitEditBack}
            onCancel={handleEditModalCancel}
            onChange={handleTraitEditChange}
            onFinish={() => validateTrait(handleSetupTraitFinish)}
            onOptionalChange={handleTraitOptionalityChange}
            selectedTrait={selectedTrait}
            error={traitEditError}
          />
        )}

      {modalMode === TraitModalMode.Edit &&
        selectedTrait &&
        traitEditCondition && (
          <CohortTraitsSetupModal
            title="Edit trait"
            backButtonLabel="Cancel"
            condition={traitEditCondition}
            onBack={handleEditModalCancel}
            onCancel={handleEditModalCancel}
            onChange={handleTraitEditChange}
            onFinish={() => validateTrait(handleEditTraitFinish)}
            onOptionalChange={handleTraitOptionalityChange}
            selectedTrait={selectedTrait}
            error={traitEditError}
          />
        )}
    </>
  );
}
