import { useCallback } from "react";

import { useEventTracker } from "../components/ui/Analytics/AnalyticsProvider";
import { useTestMode } from "./useTestMode";

// Hubspot specific tracking events.
export const HUBSPOT_USER_ADDED_PIPELINE_DEPLOYMENT = "pe6194453_user_added_pipeline_deployment"; // prettier-ignore
export const HUBSPOT_USER_COMPLETED_RECIPE = "pe6194453_user_completed_recipe";
export const HUBSPOT_USER_CREATED_COHORT = "pe6194453_user_created_cohort";
export const HUBSPOT_USER_CREATED_CONNECTION = "pe6194453_user_created_connection"; // prettier-ignore
export const HUBSPOT_USER_CREATED_DATASET = "pe6194453_user_created_dataset";
export const HUBSPOT_USER_CREATED_OUTCOME = "pe6194453_user_created_outcome";
export const HUBSPOT_USER_CREATED_PERSONA_SET ="pe6194453_user_created_personas"; // prettier-ignore
export const HUBSPOT_USER_CREATED_PIPELINE = "pe6194453_user_created_pipeline";
export const HUBSPOT_USER_DISABLED_PIPELINE = "pe6194453_user_disabled_pipeline"; // prettier-ignore
export const HUBSPOT_USER_DISABLED_TEST_MODE ="pe6194453_user_disabled_test_mode"; // prettier-ignore
export const HUBSPOT_USER_ENABLED_PIPELINE = "pe6194453_user_enabled_pipeline";
export const HUBSPOT_USER_INVITED_TEAMMATE = "pe6194453_user_invited_teammate";
export const HUBSPOT_USER_SELECTED_RECIPE = "pe6194453_user_selected_recipe";
export const HUBSPOT_USER_UPDATED_COHORT = "pe6194453_user_updated_cohort";
export const HUBSPOT_USER_UPDATED_CONNECTION = "pe6194453_user_updated_connection"; // prettier-ignore
export const HUBSPOT_USER_UPDATED_DATASET = "pe6194453_user_updated_dataset";
export const HUBSPOT_USER_UPDATED_OUTCOME = "pe6194453_user_updated_outcome";
export const HUBSPOT_USER_UPDATED_PERSONA_SET = "pe6194453_user_updated_persona_set"; // prettier-ignore
export const HUBSPOT_USER_UPDATED_PIPELINE = "pe6194453_user_updated_pipeline";
export const HUBSPOT_USER_CREATED_RECOMMENDER = "pe6194453_user_created_recommender"; // prettier-ignore
export const HUBSPOT_USER_UPDATED_RECOMMENDER = "pe6194453_user_updated_recommender"; // prettier-ignore

// All events have these properties
type BaseEvent = {
  account_id: string;
  test_mode: string;
};

// Generic event used when resources are involved
type ResourceEvent = {
  resource_id: string;
  resource_name: string;
};

// Bind event names to their expected properties
// These are defined in HubSpot admin
// https://app.hubspot.com/events/6194453/manage
type EventPropertiesMap = {
  [HUBSPOT_USER_ADDED_PIPELINE_DEPLOYMENT]: ResourceEvent;
  [HUBSPOT_USER_COMPLETED_RECIPE]: { recipe_name: string };
  [HUBSPOT_USER_CREATED_COHORT]: ResourceEvent;
  [HUBSPOT_USER_CREATED_CONNECTION]: {
    resource_id: string;
    connection_type: string;
  };
  [HUBSPOT_USER_CREATED_DATASET]: ResourceEvent;
  [HUBSPOT_USER_CREATED_OUTCOME]: ResourceEvent;
  [HUBSPOT_USER_CREATED_PERSONA_SET]: ResourceEvent;
  [HUBSPOT_USER_CREATED_PERSONA_SET]: ResourceEvent;
  [HUBSPOT_USER_CREATED_PIPELINE]: ResourceEvent;
  [HUBSPOT_USER_CREATED_PIPELINE]: ResourceEvent;
  [HUBSPOT_USER_DISABLED_PIPELINE]: ResourceEvent;
  [HUBSPOT_USER_DISABLED_TEST_MODE]: undefined;
  [HUBSPOT_USER_ENABLED_PIPELINE]: ResourceEvent;
  [HUBSPOT_USER_INVITED_TEAMMATE]: { invited_email: string };
  [HUBSPOT_USER_SELECTED_RECIPE]: { recipe_name: string };
  [HUBSPOT_USER_UPDATED_COHORT]: ResourceEvent;
  [HUBSPOT_USER_UPDATED_CONNECTION]: ResourceEvent;
  [HUBSPOT_USER_UPDATED_DATASET]: ResourceEvent;
  [HUBSPOT_USER_UPDATED_OUTCOME]: ResourceEvent;
  [HUBSPOT_USER_UPDATED_PERSONA_SET]: ResourceEvent;
  [HUBSPOT_USER_UPDATED_PIPELINE]: ResourceEvent;
  [HUBSPOT_USER_CREATED_RECOMMENDER]: ResourceEvent;
  [HUBSPOT_USER_UPDATED_RECOMMENDER]: ResourceEvent;
};

type EventName = keyof EventPropertiesMap;

type TrackHubSpotEventCallback = <
  T extends EventName,
  K extends EventPropertiesMap[T]
>(
  eventName: T,
  event: K
) => void;

/**
 * Wrapper around the useEventTracker hook that provides a type-safe way to track
 * HubSpot events.
 *
 * Each event name has required properties, which are defined in HubSpot admin to set up and define.
 * https://app.hubspot.com/events/6194453/manage
 */
export function useHubSpotEvent(): TrackHubSpotEventCallback {
  const track = useEventTracker();
  const { isInTestMode, accountId } = useTestMode();

  const trackHubspotEvent = useCallback<TrackHubSpotEventCallback>(
    (eventName, event) => {
      // account id is required to track events, so we can't track events until we have it
      if (!accountId) return;

      const defaultEventProperties: BaseEvent = {
        account_id: accountId,
        test_mode: isInTestMode.toString(),
      };

      // Forward the event to the analytics services (hubspot should be one of them)
      track(eventName, {
        ...event,
        ...defaultEventProperties,
      });
    },
    [accountId, isInTestMode, track]
  );

  return trackHubspotEvent;
}
