import { gql } from "@apollo/client";

import { useToast } from "../../../hooks/useToast";
import {
  ApiOptions,
  graphqlOptionsToSpec,
} from "../../../services/connectionOptions";
import { useSojournerMutation } from "../../../services/sojournerApolloClient";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import {
  ConnectionTypeInfo,
  getTargetOptionsByConnectionType,
} from "../connectionUtils";
import { TargetFragment } from "./__generated__/TargetFragment";
import {
  UpdateTargetMutation,
  UpdateTargetMutationVariables,
} from "./__generated__/UpdateTargetMutation";
import { TargetForm } from "./TargetForm";
import { targetFilterFromWire } from "./TargetForm/TargetFilter/targetFilterFromWire";
import {
  TargetFormState,
  TargetStateForWire,
} from "./TargetForm/TargetFormAdvanced";
import { presetFor } from "./TargetForm/targetStructureUtils";
import { targetFragment } from "./targetFragment";
import { targetStateToPatchInput } from "./targetStateToPatchInput";

export const updateTargetMutation = gql`
  mutation UpdateTargetMutation($targetId: ID!, $input: String!) {
    updateTarget(targetId: $targetId, applicationJsonMergePatchInput: $input) {
      ...TargetFragment
    }
  }
  ${targetFragment}
`;

/**
 * Groom the received `target.options` into a format that can be used to update the target.
 * - Graphql sends us options as camelCase, but all the option field name slugs are snake case,
 *   so transform the option keys to match before we store the state.
 * - We also receive some readonly fields, so we need to remove them before storing them in state.
 */
function optionsFromWire(target: TargetFragment): ApiOptions {
  const apiOptions = graphqlOptionsToSpec({ ...target.options });
  return getTargetOptionsByConnectionType(target.options.type).reduce(
    (acc, option) => {
      acc[option.slug] = apiOptions[option.slug];
      return acc;
    },
    {} as ApiOptions
  );
}

interface TargetEditFormProps {
  scope: PipelineFragment;
  target: TargetFragment;
  connectionTypeInfo: ConnectionTypeInfo;
  onCancel: () => void;
  onSaved: () => void;
}

/**
 * Form for editing an existing target.
 * Wires up the mutation to update the target on the API.
 * Knows how to convert the target state to the format expected by the API.
 */
export function TargetEditForm({
  scope,
  target,
  onSaved,
  onCancel,
  connectionTypeInfo,
}: TargetEditFormProps) {
  const toast = useToast();

  const [updateTarget, { loading }] = useSojournerMutation<
    UpdateTargetMutation,
    UpdateTargetMutationVariables
  >(updateTargetMutation, {
    onCompleted() {
      toast({
        status: "success",
        title: "Deployment updated",
      });

      // close the add target modal when the target is updated
      onSaved();
    },
  });

  function onSave(state: TargetStateForWire) {
    const input = JSON.stringify(
      targetStateToPatchInput(state, connectionTypeInfo)
    );

    updateTarget({
      variables: {
        targetId: target.id,
        input,
      },
    });
  }

  const initialState: TargetFormState = {
    options: optionsFromWire(target),
    limit: target.limit,
    transformPreset: presetFor(target.representation),
    customStructure: target.customStructure,
    filter: targetFilterFromWire(target.filter),
    humanReadable: target.humanReadable ?? false,
    representation: target.representation,
  };

  return (
    <TargetForm
      scope={scope}
      initialState={initialState}
      connectionTypeInfo={connectionTypeInfo}
      onSave={onSave}
      onCancel={onCancel}
      saving={loading}
    />
  );
}
