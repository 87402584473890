import capitalize from "lodash/capitalize";

import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../../ui/ConfigBreakdownTable";
import { PopoverInfoPill, PopoverInfoPillPart } from "../../ui/PopoverInfoPill";
import { Skeleton } from "../../ui/Skeleton";
import { getConnectionTypeInfoFromTargetOptions } from "../connectionUtils";
import { TargetFragment } from "../deployment/__generated__/TargetFragment";
import { scopeInfo } from "../scopeInfo";

function ScopeTargetsBreakdown({ targets }: { targets: TargetFragment[] }) {
  return (
    <ConfigBreakdownTable caption={scopeInfo.targets}>
      {targets.map((target) => {
        const info = getConnectionTypeInfoFromTargetOptions(target.options);
        return (
          <ConfigBreakdownTableRow
            key={target.id}
            icon={{ logo: target.options.type }}
            header={info.literate}
            value={capitalize(target.representation.mode)}
          />
        );
      })}
    </ConfigBreakdownTable>
  );
}

export function ScopeTargetsPill({
  targets,
  loading,
}: {
  targets: TargetFragment[];
  loading: boolean;
}) {
  if (loading) {
    return <Skeleton height={6} width={12} borderRadius="full" />;
  }

  if (targets.length === 0) {
    return null;
  }

  const countsByTargetType = targets.reduce((acc, target) => {
    const type = target.options.type;
    if (!acc[type]) acc[type] = 0;
    acc[type]++;
    return acc;
  }, {} as Record<string, number>);

  return (
    <PopoverInfoPill popover={<ScopeTargetsBreakdown targets={targets} />}>
      {Object.entries(countsByTargetType).map(([type, count]) => (
        <PopoverInfoPillPart key={type} icon={{ logo: type }} value={count} />
      ))}
    </PopoverInfoPill>
  );
}
