import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";

import { OutcomeBiasMitigationStrategy } from "../../__generated__/sojournerGlobalTypes";
import { CardV2 } from "../ui/Card/CardV2";
import { Cardv2Section } from "../ui/Card/Cardv2Section";
import { RouterLink } from "../ui/RouterLink";
import { SwitchV2 } from "../ui/Switch/SwitchV2";
import {
  DividedRadioGroup,
  DividedRadioGroupOption,
} from "./DividedRadioGroup";
import { OutcomeFormState } from "./useUpdateOutcome";

export type BiasMitigation = OutcomeFormState["bias_mitigation"];

const BIAS_OPTIONS: DividedRadioGroupOption<OutcomeBiasMitigationStrategy>[] = [
  {
    label: "None",
    helpText: "Ignore bias",
    value: OutcomeBiasMitigationStrategy.NONE,
    analyticsName: "none",
  },
  {
    label: "Equality",
    helpText: "Neutralize bias",
    value: OutcomeBiasMitigationStrategy.EQUALITY,
    analyticsName: "equality",
  },
  {
    label: "Equity",
    helpText: "Invert bias",
    value: OutcomeBiasMitigationStrategy.EQUITY,
    analyticsName: "equity",
  },
];

function biasToDefaultStrategy(
  bias: BiasMitigation
): OutcomeBiasMitigationStrategy {
  // we don't allow mixed strategies (age=equity, gender=equality)
  // yet so just return the first one we find
  if (!bias) return OutcomeBiasMitigationStrategy.NONE;
  const { age, gender } = bias;
  if (
    age === OutcomeBiasMitigationStrategy.EQUALITY ||
    gender === OutcomeBiasMitigationStrategy.EQUALITY
  ) {
    return OutcomeBiasMitigationStrategy.EQUALITY;
  }
  if (
    age === OutcomeBiasMitigationStrategy.EQUITY ||
    gender === OutcomeBiasMitigationStrategy.EQUITY
  ) {
    return OutcomeBiasMitigationStrategy.EQUITY;
  }
  return OutcomeBiasMitigationStrategy.NONE;
}

function biasToDefaultDimensions(bias: BiasMitigation): BiasDimensionsState {
  if (!bias) {
    return {
      age: false,
      gender: false,
    };
  }

  return {
    age: bias.age !== OutcomeBiasMitigationStrategy.NONE,
    gender: bias.gender !== OutcomeBiasMitigationStrategy.NONE,
  };
}

interface BiasDimensionsState extends Record<keyof BiasMitigation, boolean> {
  age: boolean;
  gender: boolean;
}

/**
 * Renders bias mitigation controls for an outcome.
 * Meant to be used in the OutcomeForm.
 */
export function OutcomeFormBias({
  bias,
  onChange,
}: {
  bias: BiasMitigation;
  onChange: (value: BiasMitigation) => void;
}) {
  const [strategy, setStrategy] = useState<OutcomeBiasMitigationStrategy>(() =>
    biasToDefaultStrategy(bias)
  );

  // It's easier to reason about the UI state when the dimensions are booleans,
  // not tri-state values that also depend on the value of the strategy.
  const [dimensions, setDimensions] = useState<BiasDimensionsState>(() =>
    biasToDefaultDimensions(bias)
  );

  // emit the current bias mitigation config when it changes
  useEffect(() => {
    onChange({
      age: dimensions.age ? strategy : OutcomeBiasMitigationStrategy.NONE,
      gender: dimensions.gender ? strategy : OutcomeBiasMitigationStrategy.NONE,
    });
  }, [strategy, dimensions]);

  // unset the strategy if all dimensions are "none" / unchecked
  useEffect(() => {
    if (!dimensions.age && !dimensions.gender) {
      setStrategy(OutcomeBiasMitigationStrategy.NONE);
    }
  }, [dimensions]);

  function handleDimensionChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;

    setDimensions({
      ...dimensions,
      [name]: checked,
    });
  }

  return (
    <CardV2
      title="Bias"
      text={
        <>
          Significant differences between the attainment and eligibility groups
          may cause this outcome to exhibit bias in the sensitive dimensions
          shown below. See our{" "}
          <RouterLink
            href="https://faraday.ai/docs/abstractions/outcomes#outcome-bias"
            target="_blank"
          >
            docs
          </RouterLink>{" "}
          for bias control information.
        </>
      }
    >
      <Text fontSize="fdy_sm" mb={6} color="fdy_gray.700">
        Bias is not <strong>necessarily</strong> problematic—for example, your
        product could be objectively and exclusively relevant to a certain
        subgroup of the population. You can also get detailed reporting and
        adjust these settings after the outcome is activated.
      </Text>

      <Table mb={8}>
        <Thead>
          <Tr>
            <Th>Sensitive dimension</Th>
            <Th>Description</Th>
            <Th>Mitigate?</Th>
          </Tr>
        </Thead>
        <Tbody>
          {["age", "gender"].map((dim) => {
            return (
              <Tr key={dim}>
                <Td bg="fdy_gray.100" textTransform="capitalize">
                  {dim}
                </Td>
                <Td>Certain {dim} values may be privileged</Td>
                <Td align="right" width={100}>
                  <SwitchV2
                    name={dim}
                    label={`Mitigate bias for ${dim}`}
                    visuallyHideLabel
                    onChange={handleDimensionChange}
                    isChecked={dimensions[dim as keyof BiasMitigation]}
                    analyticsName={`bias-mitigation-${dim}`}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Cardv2Section
        title="Mitigation"
        text="Faraday uses automated statistical techniques to mitigate bias in the dimensions selected above."
        fieldset
      >
        <DividedRadioGroup<OutcomeBiasMitigationStrategy>
          name="strategy"
          value={strategy}
          onChange={setStrategy}
          options={BIAS_OPTIONS}
          disabled={!dimensions.age && !dimensions.gender}
          analyticsName="bias-mitigation"
        />
      </Cardv2Section>
    </CardV2>
  );
}
