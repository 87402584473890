import { ChartBar } from "@phosphor-icons/react";
import { ReactElement } from "react";

import { EVERYONE_COUNT } from "../../../../constants/everyoneCount";
import { colors } from "../../../../styles/chakra-theme-v2";
import { Card } from "../../Card";

export function NationalSegment(): ReactElement {
  return (
    <Card
      name="US population baseline"
      count={EVERYONE_COUNT}
      color={colors.white}
      icon={<ChartBar weight="fill" size={28} />}
    />
  );
}
