import { BarGroup, BarSeries } from "@visx/xychart";
import capitalize from "lodash/capitalize";

import { assertArrayAndUnwrap } from "../../../../utils/assertions";
import { AxisBottom } from "../../../ui/charts-v2/AxisBottom";
import { AxisLeft } from "../../../ui/charts-v2/AxisLeft";
import { Chart } from "../../../ui/charts-v2/Chart";
import { OutcomeAnalysisQuery_outcomeAnalysis_bias_data_gender } from "../__generated__/OutcomeAnalysisQuery";
import { BIAS_CHART_COLORS } from "./outcomeAnalysisBiasUtils";

export function BiasDataGenderProportionChart({
  data,
}: {
  data: OutcomeAnalysisQuery_outcomeAnalysis_bias_data_gender;
}) {
  const datasets = [
    {
      label: "Positives",
      data: assertArrayAndUnwrap(data.positives),
      color: BIAS_CHART_COLORS.positives,
    },
    {
      label: "Negatives",
      data: assertArrayAndUnwrap(data.negatives),
      color: BIAS_CHART_COLORS.negatives,
    },
    {
      label: "Candidates",
      data: assertArrayAndUnwrap(data.candidates),
      color: BIAS_CHART_COLORS.candidates,
    },
  ].filter((d) => d.data.length > 0); // don't render empty datasets

  return (
    <Chart
      title="Gender bias amongst target population"
      xScale={{ type: "band" }}
      yScale={{ type: "linear" }}
      height={400}
      legend={{ items: datasets }}
    >
      <BarGroup>
        {datasets.map((dataset) => {
          return (
            <BarSeries
              key={dataset.label}
              dataKey={dataset.label}
              data={dataset.data}
              colorAccessor={() => dataset.color}
              xAccessor={(d) => d.x}
              yAccessor={(d) => d.y}
            />
          );
        })}
      </BarGroup>
      <AxisLeft label="Proportion" />
      <AxisBottom label="Gender" tickFormat={(t) => capitalize(t as string)} />
    </Chart>
  );
}
