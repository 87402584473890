import { useQuery } from "@tanstack/react-query";

import { useFdyClient } from "../../../services/FdyClientProvider";

const defaultOptions = { suspense: true };

// Consider updating the api to accept a resource ID param so it
// doesn't return the whole account graph
export const useGraph = (options: { suspense?: boolean } = {}) => {
  const client = useFdyClient();

  const mergedOptions = { ...defaultOptions, ...options };

  return useQuery({
    queryKey: ["graph"],
    queryFn: () => client.graph.getGraph(),
    suspense: mergedOptions.suspense,
  });
};
