import {
  ConditionRow,
  Operator,
  OperatorGroup,
  OperatorsOption,
} from "./types";

// We don't yet support two operator options in the select dropdown.
// A use case might be when user wants to enter a csv of values OR use a
// picklist (both are `in` operators). For now, guard against having duplicates.
export function validateOperatorUniqueness(operators: OperatorsOption[]) {
  const oprs = operators.map((o) => o.operator);
  if (new Set(oprs).size !== oprs.length) {
    throw new Error(`Duplicate operators found: ${JSON.stringify(oprs)}`);
  }
}

// check that each condition has a related operator otherwise we shouldn't let the user modify it
export function validateConditionsHaveOperators(
  operators: OperatorsOption[],
  conditions: ConditionRow[]
) {
  conditions.forEach((c) => {
    // skip conditions that have yet to set their operator
    if (c.operator === null) return;

    const opt = operators.find((o) => o.operator === c.operator);

    if (!opt) {
      throw new Error(`Condition ${JSON.stringify(c)} has no related operator`);
    }
  });
}

function operatorToType(operator: Operator): OperatorGroup {
  switch (operator) {
    case "gt":
    case "gte":
    case "lt":
    case "lte":
      return "numeric";
    case "in":
    case "nin":
      return "list";
    case "null":
    case "nnull":
      return "boolean";
    case "matches":
    case "neq":
    case "eq":
      return "text";
  }
}

/**
 * Given an operator, return the default condition row config for that operator.
 * If given null, return an empty condition row. This can happen user sets the 'select' back to null
 * or has added a branch new condition row
 */
export function createNewConditionRow(
  operators: OperatorsOption[],
  newOperator: Operator | null
): ConditionRow {
  if (newOperator === null) {
    return {
      type: null,
      operator: null,
      value: null,
    };
  }

  const newOperatorOption = operators.find((o) => o.operator === newOperator);

  // type guard - it shouldn't go missing
  if (!newOperatorOption) {
    throw new Error(`No operator found for ${newOperator}`);
  }

  const type = operatorToType(newOperator);

  // Boolean operators _always_ get set to true. Right now, there's little reason for them to be false.
  // Fallback to default value from the operator options list or to null.
  const value =
    type === "boolean" ? true : newOperatorOption?.defaultValue ?? null;

  return {
    type,
    operator: newOperator,
    value,
  } as ConditionRow;
}
