import { ApolloError } from "@apollo/client";
import { Fragment, useState } from "react";

import { BlankslateProps } from "./Blankslate";
import {
  logErrorToRollbar,
  parseGraphqlErrors,
} from "./ErrorBoundary/errorUtils";
import { useRollbar } from "./RollbarProvider";
import { TableBlankslate, TableError, TableLoading } from "./TableV2/parts";

type ResourceType = {
  id: string;
};

interface ResourceTableListProps<Resource extends ResourceType> {
  data: Resource[] | undefined;
  loading?: boolean;
  error?: ApolloError | undefined;
  renderData: (res: Resource) => JSX.Element;
  blankSlateProps: BlankslateProps;
}

/**
 * A component that handles loading, error, and data states for our resource
 * list tables.
 */
export function ResourceTableList<Resource extends ResourceType>({
  loading,
  error,
  data,
  renderData,
  blankSlateProps,
}: ResourceTableListProps<Resource>) {
  const [errorId, setErrorId] = useState<string | undefined>(undefined);
  const rollbar = useRollbar();

  if (error) {
    // Since this is a list page, the query just errored. Which likely means there
    // is a resource that failed validation at the sojourner level, but there is nothing
    // that we can do about it here. Sojo will get better reporting and metrics so we catch
    // these as they go out.

    // log to rollbar (once)
    if (!errorId && error instanceof ApolloError) {
      const { errorId } = logErrorToRollbar({ error, rollbar });
      setErrorId(errorId);
    }

    const { apiErrorId } = parseGraphqlErrors(error);
    return <TableError apiErrorId={apiErrorId} />;
  }

  if (loading) return <TableLoading />;

  if (!data?.length) {
    return <TableBlankslate {...blankSlateProps} />;
  }

  return (
    <>
      {data.map((res) => {
        return <Fragment key={res.id}>{renderData(res)}</Fragment>;
      })}
    </>
  );
}
