import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Gear, Icon } from "@phosphor-icons/react";
import { MouseEventHandler, ReactElement, ReactNode } from "react";

import { theme } from "../../../constants/theme";
import { colors } from "../../../styles/chakra-theme-v2";
import { LinkButton } from "../../ui/LinkButton";
import { Scroll } from "../../ui/Scroll";

const StyledIcon = styled.div`
  margin-right: ${theme.space(1)};
`;

const StyledItem = styled.li``;

const StyledListSection = styled.ul<{ tree?: boolean }>`
  ${(p) =>
    p.tree &&
    css`
      ${StyledItem} {
        position: relative;

        // shared pseudo element styles
        &:before,
        &:after {
          display: block;
          content: "";
          z-index: 20;
          position: absolute;
          left: 24px;
          color: ${theme.colors.light_gray};
        }

        // Add L shaped lines to denote hierachy.
        //
        // |- Folder name
        // |
        // |- Folder name
        &:before {
          // Sorry for magical numbers :(
          // If spacing is adjusted for the list items,
          // these will likely have to be updated.
          top: 8px;
          height: 12px;
          width: 8px;
          border-bottom: 1px solid currentColor;
          border-left: 1px solid currentColor;
        }

        // Add vertical connector lines between each 'folder'
        // |-
        // |   <-- css adds this
        // |-
        &:not(:last-child):after {
          top: 16px;
          width: 1px;
          height: 30px;
          border-left: 1px solid currentColor;
        }
      }
    `}
`;

const StyledSectionTitle = styled.h3`
  margin: 0;
  color: ${theme.colors.black};
`;

const StyledListSectionItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${theme.space(1.5)};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  padding-left: ${theme.space(2)};
  padding-right: ${theme.space(2)};
  padding-bottom: ${theme.space(1)};
  padding-top: ${theme.space(1)};
  font-size: ${theme.fontSizes.sm};
`;

/* preset list */

const StyledPresetList = styled.ul`
  padding-top: ${theme.space(0.5)};
  padding-bottom: ${theme.space(0.5)};
`;

interface PresetListProps {
  children: ReactNode;
}

export function PresetList({ children }: PresetListProps): ReactElement {
  return <StyledPresetList>{children}</StyledPresetList>;
}

/* preset list group */

interface PresetListSectionProps {
  label: string;
  icon: Icon;
  children: ReactNode;
  /** apply folder lines before each item */
  tree?: boolean;
}

function PresetListSection({
  icon: Icon,
  label,
  children,
  tree,
}: PresetListSectionProps) {
  return (
    <StyledListSectionItem>
      <StyledHeader>
        <StyledIcon>
          <Icon />
        </StyledIcon>
        <StyledSectionTitle>{label}</StyledSectionTitle>
      </StyledHeader>
      <StyledListSection tree={tree}>{children}</StyledListSection>
    </StyledListSectionItem>
  );
}

/* single button */

const StyledPresetButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${theme.space(1)};
  width: 100%;
  padding-block: ${theme.space(1)};
  padding-inline: ${theme.space(2)};
  text-align: left;

  ${StyledListSection} & {
    padding-left: ${theme.space(5)};
  }

  &:hover {
    background-color: ${colors.fdy_gray[100]};
  }
`;

interface PresetButtonProps {
  loading?: boolean;
  label: string;
  icon?: ReactNode;
  onClick: MouseEventHandler;
  disabled?: boolean;
  tooltip?: string;
}

function PresetListButton({
  icon,
  label,
  onClick,
  disabled,
}: PresetButtonProps) {
  return (
    <StyledItem>
      <StyledPresetButton onClick={onClick} disabled={disabled}>
        {icon}
        {label}
      </StyledPresetButton>
    </StyledItem>
  );
}

const StyledListWithActionBody = styled(Scroll)`
  border-bottom: 1px solid ${theme.colors.light_gray};
`;

function PresetListWithAction({
  children,
  routeName,
  label,
  analyticsName,
}: {
  children: ReactNode;
  routeName: string;
  label: string;
  analyticsName?: string;
}) {
  return (
    <div>
      <StyledListWithActionBody maxHeight={300}>
        {children}
      </StyledListWithActionBody>
      <LinkButton
        leftIcon={<Gear />}
        variant="ghost"
        routeName={routeName}
        w="100%"
        analyticsName={analyticsName}
      >
        {label}
      </LinkButton>
    </div>
  );
}

PresetList.Section = PresetListSection;
PresetList.Button = PresetListButton;
PresetList.Actionable = PresetListWithAction;
