import { Box, IconButton, Select, Text } from "@chakra-ui/react";
import { Trash } from "@phosphor-icons/react";

import { CohortStreamConditionInput } from "../../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { components } from "../../../../sojourner-oas-types";
import { colors } from "../../../../styles/chakra-theme-v2";
import { Blankslate } from "../../../ui/Blankslate";
import { Button } from "../../../ui/Button";
import { ConditionRow } from "../../../ui/ConditionsBuilder";
import { FormFieldset } from "../../../ui/FormFieldset";
import { RouterLink } from "../../../ui/RouterLink";
import { CohortStreamPropertyEditor } from "./CohortStreamPropertiesEditor";

/** Renders a section that allows setting stream conditions on a cohort */
export function CohortEventProperties({
  streamConditions,
  onStreamConditionsChange,
  streamProperties,
}: {
  streamProperties: components["schemas"]["StreamProperties"];
  streamConditions: CohortStreamConditionInput[];
  onStreamConditionsChange: (
    streamConditions: CohortStreamConditionInput[]
  ) => void;
}) {
  /** Get the properties that we can show in the drop down select */
  const availableProperties = Object.fromEntries(
    Object.entries(streamProperties).filter(
      ([propertyName]) =>
        // filter out any properties that we already have conditions for
        !streamConditions.some((c) => c.property === propertyName)
    )
  );

  /** Whether we have any properties that you can make conditions with. */
  const eventHasProperties = Object.keys(streamProperties).length > 0;

  return (
    <FormFieldset
      legend="Do certain event properties matter?"
      hint={
        <>
          Set conditions for limiting qualifying events based on properties you
          choose. For example, a “gift buyers” cohort could exclusively include
          people who experience “transaction” events with the “gift” property
          set to true. You can add custom properties to events in{" "}
          <RouterLink routeName={ROUTE_NAMES.DATASETS}>Datasets</RouterLink>.
        </>
      }
    >
      <Box display="grid" gap="4">
        {streamConditions.map((c, i) => (
          <EventPropertyFilter
            key={`${c.property}${i}`}
            availableProperties={{
              ...availableProperties,
              [c.property]: streamProperties[c.property],
            }}
            setCondition={(condition) => {
              onStreamConditionsChange(
                streamConditions.map((sc) => {
                  if (sc === c) return condition;
                  return sc;
                })
              );
            }}
            condition={c}
            onDelete={() => {
              onStreamConditionsChange(
                streamConditions.filter((sc) => sc !== c)
              );
            }}
          />
        ))}
      </Box>

      {eventHasProperties ? (
        <Button
          mt={3}
          variant="tertiary"
          disabled={Object.keys(availableProperties).length === 0}
          size="sm"
          onClick={() =>
            onStreamConditionsChange([
              ...streamConditions,
              getDefaultEmptyConditionForProperty(
                Object.keys(availableProperties)[0],
                Object.values(availableProperties)[0]
              ),
            ])
          }
          analyticsName="add-event-property-condition"
        >
          Add property condition
        </Button>
      ) : (
        <Blankslate
          title="Event has no custom properties set up"
          text="Properties must be defined on this event type before you can filter on them."
          button={{
            children: "Set up properties in Datasets",
            routeName: ROUTE_NAMES.DATASETS,
          }}
          filled
        />
      )}
    </FormFieldset>
  );
}

function getDefaultEmptyConditionForProperty(
  name: string,
  property: components["schemas"]["StreamPropertyDetails"]
): CohortStreamConditionInput {
  if (property.categories && property.categories.length > 0) {
    return { property: name, in: [] };
  }
  return {
    property: name,
    matches: "",
  };
}

/** This component is a box representing the filtering on one stream property.
 * It shows a dropdown to select the stream property, as well as the entry boxes
 * for applying the desired filters (greater than, matches, etc)
 */
function EventPropertyFilter({
  availableProperties,
  condition,
  setCondition,
  onDelete,
}: {
  availableProperties: components["schemas"]["StreamProperties"];
  condition: CohortStreamConditionInput;
  setCondition: (prevState: CohortStreamConditionInput) => void;
  onDelete: () => void;
}) {
  const removeBtn = (
    <IconButton
      variant="icon"
      color="fdy_gray.700"
      aria-label={`Remove ${condition.property} condition`}
      icon={<Trash />}
      onClick={onDelete}
    />
  );

  return (
    <Box
      pb={4}
      px={4}
      border="1px solid"
      borderColor={colors.fdy_gray[400]}
      borderRadius="md"
      bg="fdy_gray.100"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text as="strong">Event property</Text>
        {removeBtn ?? null}
      </Box>

      <Select
        mb={4}
        mr={2}
        value={condition.property}
        onChange={(e) => {
          setCondition({ ...condition, property: e.target.value });
        }}
      >
        {Object.keys(availableProperties)
          .sort((a, b) => a.localeCompare(b))
          .map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
      </Select>

      <CohortStreamPropertyEditor
        streamPropertyName={condition.property}
        streamProperty={availableProperties[condition.property]}
        onChange={setCondition}
        condition={condition}
      />
    </Box>
  );
}

export function streamConditionsToWire(
  condition: CohortStreamConditionInput,
  conditionList: ConditionRow[]
): CohortStreamConditionInput {
  const newCondition: CohortStreamConditionInput = {
    property: condition.property,
    optional: condition.optional,
  };

  conditionList.forEach((cond) => {
    if (!cond.operator || cond.value === null || cond.value === undefined) {
      return;
    }

    // Cohort conditions don't support neq, but we use it as a workaround to
    // produce eq: false for some condition builders in target filters, so need to skip it here.
    if (cond.operator === "neq") return;

    switch (cond.type) {
      case "boolean":
        newCondition[cond.operator] = cond.value;
        break;
      case "list":
        newCondition[cond.operator] = cond.value;
        break;
      case "numeric":
        newCondition[cond.operator] = cond.value;
        break;
      case "text":
        newCondition[cond.operator] = cond.value;
        break;
    }
  });

  return newCondition;
}
