import { RecommenderMergePatch } from "@fdy/faraday-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRoute, useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useApiErrorToaster } from "../../hooks/useApiErrorToaster";
import {
  HUBSPOT_USER_UPDATED_RECOMMENDER,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { useFdyClient } from "../../services/FdyClientProvider";
import { recommenderKeys } from "./recommenderKeys";
import {
  RecommenderFormStateToWire,
  RecommendersForm,
} from "./RecommendersForm";
import { RecommendersSidebarLayout } from "./RecommendersSidebarLayout";

export function RecommendersEditPage() {
  const client = useFdyClient();
  const router = useRouter();
  const { route } = useRoute();
  const queryClient = useQueryClient();
  const toast = useToast();
  const track = useHubSpotEvent();

  const id = route.params.id;

  const recommenderQuery = useQuery(recommenderKeys.show(id), () =>
    client.recommenders.getRecommender(id)
  );

  const updateRecommenderMutation = useMutation((data: RecommenderMergePatch) =>
    client.recommenders.updateRecommender(id, data)
  );
  useApiErrorToaster(updateRecommenderMutation.error);

  if (!recommenderQuery.data)
    throw new Error(
      "BUG: No recommender found, but sojourner did not throw an error"
    );

  const existingRecommender = recommenderQuery.data;

  async function handleSave(state: RecommenderFormStateToWire) {
    const updatedRecommender = await updateRecommenderMutation.mutateAsync(
      state
    );

    await queryClient.invalidateQueries([
      recommenderKeys.list,
      recommenderKeys.show(id),
    ]);

    track(HUBSPOT_USER_UPDATED_RECOMMENDER, {
      resource_id: updatedRecommender.id,
      resource_name: updatedRecommender.name,
    });

    toast({
      status: "success",
      title: `${updatedRecommender.name} successfully updated!`,
      description:
        "Recommender has been updated and will rebuild soon if necessary.",
    });

    router.navigate(ROUTE_NAMES.RECOMMENDERS_SHOW, {
      id: updatedRecommender.id,
    });
  }

  const title = `Edit ${existingRecommender.name}`;
  return (
    <RecommendersSidebarLayout
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.RECOMMENDERS_EDIT,
        params: { id: existingRecommender.id },
      }}
    >
      <RecommendersForm
        initialState={existingRecommender}
        onSave={handleSave}
        saving={updateRecommenderMutation.isLoading}
      />
    </RecommendersSidebarLayout>
  );
}
