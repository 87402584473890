import {
  CohortStreamConditionInput,
  CohortTraitInput,
  PrimitiveDataType,
} from "../../../../__generated__/sojournerGlobalTypes";
import { Trait } from "../../../../hooks/useTraitsQuery";
import { components } from "../../../../sojourner-oas-types";
import { ConditionRow } from "../../../ui/ConditionsBuilder";
import { ConditionDefintion } from "../utils";
import {
  BooleanFilterState,
  CohortFilterEditorBoolean,
} from "./CohortFilterEditorBoolean";
import { CohortFilterEditorCategorical } from "./CohortFilterEditorCategorical";
import { CohortFilterEditorNumber } from "./CohortFilterEditorNumber";
import { CohortFilterEditorText } from "./CohortFilterEditorText";

/**
 * Traits and StreamPropety conditions are essentially identical - we store them
 * both as fdy_fields and fdy_field_conditions. But the API has to treat them differently
 * in two different ways - 1) the typing, returning the CohortTraitInput vs CohortStreamConditionInput
 * 2) one uses `property` and the other uses `name` as the field where we refer to what the
 * things is called. This is a generic class that can handle both. For specific implementations
 * see the CohortTraitsEditor and the CohortStreamPropertyEditor.
 */

/* The intersection of event properties and traits - capturing the metadata that we care
about and use the show the user relevant info like categories, types, etc. */
export type FilterProperties =
  | Trait
  | components["schemas"]["StreamPropertyDetails"];

export interface CohortFilterEditorProps<CohortConditionInput> {
  filterName: string;
  filterProperties: FilterProperties;
  condition: CohortConditionInput;
  onChange: (prevState: CohortConditionInput) => void;
  conditionsToWire: (
    condition: CohortConditionInput,
    conditions: ConditionRow[]
  ) => CohortConditionInput;
  conditionsFromWire: (condition: ConditionDefintion) => ConditionRow[];
  booleanConditionToWire: (
    propertyName: string,
    selectedOptions: BooleanFilterState
  ) => CohortConditionInput;
}

/**
 * Given a stream property or a trait, display the relevant operators in a combo
 * box, letting the user select operators and provide the filtering values that they
 * are interested in.
 */
export function CohortFilterEditor<
  CohortConditionInput extends CohortTraitInput | CohortStreamConditionInput
>(props: CohortFilterEditorProps<CohortConditionInput>) {
  // FIXME: we should warn user if they are trying to filter by a trait or
  // stream property that is no longer available on their account. It can cause
  // filterProperties to be undefined.
  if (!props.filterProperties?.type) {
    return <CohortFilterEditorText {...props} />;
  }

  // These duplicative case statements are because our StreamPropertyDetails is
  // returned as a JSON blob over our gql api and Trait can be strongly typed, and
  // the graphql schema converts PrimitiveDataType to enum and capitalizes it.
  // It's ugly, I hate it too.
  switch (props.filterProperties.type) {
    case PrimitiveDataType.STRING:
    case "string": {
      if (
        props.filterProperties.categories &&
        props.filterProperties.categories.length > 0
      ) {
        return <CohortFilterEditorCategorical {...props} />;
      } else {
        return <CohortFilterEditorText {...props} />;
      }
    }

    case PrimitiveDataType.BOOLEAN:
    case "boolean":
      return <CohortFilterEditorBoolean {...props} />;

    // TODO: some double or long trait types accept plain text??
    case PrimitiveDataType.DOUBLE:
    case PrimitiveDataType.LONG:
    case "double":
    case "long":
      return <CohortFilterEditorNumber {...props} />;
    default:
      return <CohortFilterEditorText {...props} />;
  }
}
