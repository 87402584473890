type Callback<E extends Event> = (e: E) => void;

export class EventTarget<E extends Event> {
  private listeners: Record<string, Callback<E>[]> = {};

  addEventListener(type: string, callback: Callback<E>): void {
    if (!(type in this.listeners)) {
      this.listeners = {
        ...this.listeners,
        [type]: [callback],
      };
    } else {
      this.listeners = {
        ...this.listeners,
        [type]: this.listeners[type].concat(callback),
      };
    }
  }

  removeEventListener(type: string, callback: Callback<E>): void {
    if (!(type in this.listeners)) return;

    this.listeners = {
      ...this.listeners,
      [type]: this.listeners[type].filter((c) => c !== callback),
    };
  }

  dispatchEvent(event: E): boolean {
    if (!(event.type in this.listeners)) {
      return true;
    }
    this.listeners[event.type].forEach((listener) => {
      listener.call(this, event);
    });
    return !event.defaultPrevented;
  }
}
