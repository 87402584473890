import camelCase from "lodash/camelCase";

import { CohortTraitInput } from "../../../__generated__/sojournerGlobalTypes";
import { components } from "../../../sojourner-oas-types";
import { assertNonEmptyArray } from "../../../utils/assertNonEmptyArray";
import { exists } from "../../../utils/exists";
import { ConditionRow } from "../../ui/ConditionsBuilder/types";
import { CohortFormDatasetsQuery_datasets } from "./__generated__/CohortFormDatasetsQuery";
export type OutputToStreams = components["schemas"]["OutputToStreams"];

export type ConditionDefintion = Omit<CohortTraitInput, "name">;

export function conditionsFromWire<
  CohortConditionInput extends Omit<CohortTraitInput, "name">
>(condition: CohortConditionInput): ConditionRow[] {
  const rows: ConditionRow[] = [];

  if (exists(condition.eq)) {
    rows.push({
      type: "text",
      value: condition.eq,
      operator: "eq",
    });
  }

  if (exists(condition.matches)) {
    rows.push({
      type: "text",
      value: condition.matches,
      operator: "matches",
    });
  }

  if (exists(condition.nnull)) {
    rows.push({
      type: "boolean",
      value: condition.nnull,
      operator: "nnull",
    });
  }

  if (exists(condition.null)) {
    rows.push({
      type: "boolean",
      value: condition.null,
      operator: "null",
    });
  }

  if (exists(condition.in) && assertNonEmptyArray(condition.in)) {
    rows.push({
      type: "list",
      value: condition.in,
      operator: "in",
    });
  }

  if (exists(condition.nin) && assertNonEmptyArray(condition.nin)) {
    rows.push({
      type: "list",
      value: condition.nin,
      operator: "nin",
    });
  }

  if (exists(condition.gt)) {
    rows.push({
      type: "numeric",
      value: condition.gt,
      operator: "gt",
    });
  }

  if (exists(condition.gte)) {
    rows.push({
      type: "numeric",
      value: condition.gte,
      operator: "gte",
    });
  }

  if (exists(condition.lt)) {
    rows.push({
      type: "numeric",
      value: condition.lt,
      operator: "lt",
    });
  }

  if (exists(condition.lte)) {
    rows.push({
      type: "numeric",
      value: condition.lte,
      operator: "lte",
    });
  }

  return rows;
}

/**
 * Returns tuue if any dataset has the matching stream name and it contains a
 * `value` column in its datamap
 */
export function datasetHasStreamWithValue(
  datasets: CohortFormDatasetsQuery_datasets[],
  streamName: string
): boolean {
  for (const dataset of datasets) {
    const outputToStreams = dataset.outputToStreams as OutputToStreams;

    const camelCaseStream = camelCase(streamName);

    if (outputToStreams[camelCaseStream]?.data_map.value?.column_name) {
      return true;
    }
  }
  return false;
}

export function truncateText(s: string, maxLength: number): string {
  return `${s.substring(0, maxLength)}${s.length >= maxLength ? "..." : ""}`;
}
