import { Atom, Binoculars, Icon, MagicWand } from "@phosphor-icons/react";
import { ReactElement } from "react";

import {
  EXPLORE_ENABLED,
  INFORM_ENABLED,
  PIPELINES_ENABLED,
  RECIPES_ENABLED,
} from "../../constants/accountFeatureNames";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  AccountFeature,
  useAccountConfigMap,
} from "../../hooks/accountConfigHooks";
import { ResourceIcon } from "../ui/ResourceIcon";
import { NavigationBarGroup } from "./NavigationBarGroup";
import { NavigationBarLink } from "./NavigationBarOption";

interface ProductLinkOptions {
  icon: Icon;
  label: string;
  feature?: AccountFeature;
  routeName: string;
  routeParams?: Record<string, string>;
}

const products: ProductLinkOptions[] = [
  {
    icon: MagicWand,
    label: "Recipes",
    feature: RECIPES_ENABLED,
    routeName: ROUTE_NAMES.RECIPES,
  },
  {
    icon: ResourceIcon.scopes,
    label: "Pipelines",
    feature: PIPELINES_ENABLED,
    routeName: ROUTE_NAMES.PIPELINES,
  },
  {
    icon: Binoculars,
    label: "Explore",
    feature: EXPLORE_ENABLED,
    routeName: ROUTE_NAMES.EXPLORE,
  },
  {
    icon: Atom,
    label: "Inform",
    feature: INFORM_ENABLED,
    routeName: ROUTE_NAMES.INFORM,
  },
];

export function ProductNav(): ReactElement {
  const featureMap = useAccountConfigMap();

  function renderOption({
    icon,
    label,
    feature,
    routeName,
    routeParams,
  }: ProductLinkOptions) {
    if (feature && !featureMap[feature]) return null;
    return (
      <NavigationBarLink
        key={label}
        icon={icon}
        label={label}
        routeName={routeName}
        routeParams={routeParams}
        analyticsName={label.toLowerCase()}
      />
    );
  }
  return (
    <NavigationBarGroup label="Products" hideLabel>
      {products.map(renderOption).filter(Boolean)}
    </NavigationBarGroup>
  );
}
