import { StyleProps, Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export function Pill(props: PropsWithChildren<{ sx: StyleProps }>) {
  const { sx, ...defaultProps } = props;
  return (
    <Text
      {...defaultProps}
      as="span"
      sx={{
        borderRadius: "full",
        px: 3,
        fontWeight: "bold",
        display: "inline-block",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        ...sx,
      }}
    >
      {props.children}
    </Text>
  );
}
