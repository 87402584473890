import { Box, Text } from "@chakra-ui/react";

import { Persona } from "../personaSetFragment";
import { PersonaAvatar } from "./PersonaAvatar";
import { PersonaSetAnalysisGridRow } from "./PersonaSetAnalysisGrid";

/**
 * Render a row of personas, each with a name and avatar.
 */
export function PersonaSetAnalysisHeader({
  personas,
}: {
  personas: Persona[];
}) {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        width: "100%",
        bg: "white",
        zIndex: 10,
        borderBottom: "1px solid",
        borderColor: "fdy_gray.200",
      }}
    >
      <PersonaSetAnalysisGridRow columns={personas.length + 1}>
        <Box>{/* TODO: export button goes here? */}</Box>

        {personas.map((persona) => (
          <Box
            key={persona.id}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PersonaAvatar persona={persona} />
            <Box>
              <Text
                sx={{
                  fontWeight: "bold",
                  fontsize: "fdy_lg",
                  ml: 4,
                }}
              >
                {persona.name}
              </Text>
            </Box>
          </Box>
        ))}
      </PersonaSetAnalysisGridRow>
    </Box>
  );
}
