import {
  Box,
  CSSObject,
  Link,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Book } from "@phosphor-icons/react";
import { useState } from "react";

import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { CodeSample } from "../../../ui/CodeSample";
import { CopyInputV2 } from "../../../ui/CopyInputV2";
import { RouterLink } from "../../../ui/RouterLink";
import { TargetCardProps } from "./TargetCard";
import { TargetCardBase } from "./TargetCardBase";

const styles: CSSObject = {
  borderBottom: "1px solid",
  borderColor: "fdy_gray.300",
  display: "flex",
  columnGap: 3,
  py: 3,
  textDecoration: "none",
  _hover: {
    "> span": {
      textDecoration: "underline",
    },
  },
};

type LookupApiDetails = {
  identifier: string;
  key: string;
  value?: string;
};

function CurlBox({ targetId }: { targetId: string }) {
  const curlCommand = `curl --request POST \\
  --url https://api.faraday.ai/v1/targets/${targetId}/lookup \\
  --header 'Authorization: Bearer PRODUCTION_API_KEY' \\
  --header 'Content-Type: application/json' \\
  --data '{
    "person_first_name": "Michael",
    "person_last_name": "Faraday",
    "house_number_and_street": "123 Science Dr",
    "city": "Phoenix",
    "state": "Farazona",
    "postcode": "00043"
  }'`;
  return <CodeSample code={curlCommand} />;
}

function LookupApiButtons({ targetId }: { targetId: string }) {
  const persistentDownloadUrl = `https://api.faraday.ai/v1/targets/${targetId}/lookup`;

  return (
    <Box mb={4}>
      <CopyInputV2 value={persistentDownloadUrl} />
    </Box>
  );
}

const lookupApiDetails: LookupApiDetails[] = [
  {
    identifier: "First Name",
    key: "person_first_name",
    value: "Michael",
  },
  {
    identifier: "Last Name",
    key: "person_last_name",
    value: "Faraday",
  },
  {
    identifier: "Email",
    key: "email",
    value: "michael.faraday@example.com",
  },
  {
    identifier: "SHA-256 email hash",
    key: "email_hash",
    value: "7269489524eb3a39f3dccd72dd8b6e676fe550faed9a27f9a942bcdd76e50ad9",
  },
  {
    identifier: "Address",
    key: "house_number_and_street",
    value: "123 Science Dr",
  },
  {
    identifier: "City",
    key: "city",
    value: "Phoenix",
  },
  {
    identifier: "State",
    key: "state",
    value: "Farazona",
  },
  {
    identifier: "Zipcode",
    key: "postcode",
    value: "00043",
  },
];

function LookupApiDetailsTable(props: TargetCardProps) {
  const { scope } = props;
  return (
    <Table>
      <Thead>
        <Tr>
          <Th width={200}>Identifier</Th>
          <Th width={200}>Key</Th>
          <Th>Value</Th>
        </Tr>
      </Thead>
      <Tbody>
        {lookupApiDetails.map((detail, i) => {
          return (
            <Tr key={i}>
              <Td bg="fdy_gray.100">{detail.identifier}</Td>
              <Td bg="fdy_gray.100">{detail.key}</Td>
              <Td>{scope.preview === false ? "-" : detail.value}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

function LookupApiTable(props: TargetCardProps) {
  const { target } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const tabs = [
    {
      label: "Details",
      panel: <LookupApiDetailsTable {...props} />,
    },
    {
      label: "Curl",
      panel: <CurlBox targetId={target.id} />,
    },
  ];

  return (
    <Tabs size="sm" index={tabIndex} onChange={(index) => setTabIndex(index)}>
      <TabList>
        {tabs.map((tab) => (
          <Tab key={tab.label}>{tab.label}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab.label}>{tab.panel}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}

export function TargetCardLookupAPI(props: TargetCardProps) {
  const { target, scope } = props;

  const scopeEnabled = scope.preview === false;

  if (target.options.__typename !== "TargetOptionsLookupApi")
    throw new Error(
      "Tried to render a lookup api target with non-lookup options"
    );

  const noticeDescription = scopeEnabled ? (
    <span>
      <strong>This target is live.</strong>
      You can test this API endpoint using real data.
    </span>
  ) : (
    <span>
      <strong>
        This target is in test mode until the pipeline is enabled.
      </strong>{" "}
      You can test this API endpoint using one artificial sample record.
    </span>
  );
  return (
    <TargetCardBase {...props}>
      <LookupApiButtons targetId={target.id} />
      <Link
        href="https://faraday.ai/developers/key-concepts/lookup-api"
        isExternal
        sx={styles}
      >
        <Book />
        <span>API reference</span>
      </Link>
      <Text sx={{ my: 4 }}>
        {noticeDescription}{" "}
        <RouterLink routeName={ROUTE_NAMES.SETTINGS_API}>
          Your API key
        </RouterLink>{" "}
        is required.
      </Text>
      <LookupApiTable {...props} />
    </TargetCardBase>
  );
}
