import {
  forwardRef,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";

import { Link, LinkProps } from "./Link";

export type RouterLinkProps = LinkProps & ChakraLinkProps;

export const RouterLink = forwardRef<RouterLinkProps, "a">(
  ({ children, ...rest }, ref) => {
    return (
      <ChakraLink as={Link} ref={ref} {...rest}>
        {children}
      </ChakraLink>
    );
  }
);
