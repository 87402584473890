import {
  Flex,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Icon } from "@phosphor-icons/react";
import { ReactNode } from "react";

import { Scroll } from "./Scroll";
import { Truncate } from "./Truncate";
import { VendorLogo } from "./VendorLogo";

/**
 * Displays a small 2 column table with optional headers row and caption.
 * Usage is often when you want to display a small table of key/value pairs, where keys have accompanying icons.
 * Children should be `ConfigBreakdownTableRow` components.
 */
export function ConfigBreakdownTable({
  children,
  headers,
  caption,
}: {
  children: ReactNode;
  headers?: string[];
  caption?: string;
}) {
  return (
    <Table size="sm">
      {headers && headers.length > 0 && (
        <Thead>
          <Tr>
            {headers.map((header) => (
              <Th key={header}>{header}</Th>
            ))}
          </Tr>
        </Thead>
      )}
      <Tbody wordBreak="break-word">{children}</Tbody>
      {caption && <TableCaption>{caption}</TableCaption>}
    </Table>
  );
}

export function ConfigBreakdownTableRow({
  icon: Icon,
  header,
  value,
}: {
  icon?: Icon | { logo: string };
  header: string | ReactNode;
  value: ReactNode;
}) {
  return (
    <Tr>
      <Th scope="row" maxWidth={200} p={3}>
        <Flex gap={2} alignItems="center">
          {Icon && "logo" in Icon ? (
            <VendorLogo name={Icon.logo} dimension={16} inline />
          ) : (
            Icon && <Icon />
          )}
          {typeof header === "string" ? (
            <Truncate title={header}>{header}</Truncate>
          ) : (
            header
          )}
        </Flex>
      </Th>
      <Td p={3}>
        <Scroll maxHeight={500}>{value}</Scroll>
      </Td>
    </Tr>
  );
}
