import { Text } from "@chakra-ui/react";
import { Sparkle } from "@phosphor-icons/react";

import { Truncate } from "../../../ui/Truncate";

export function AnalysisFeatureTitle({
  name,
  literate,
  category,
}: {
  name: string;
  literate: string;
  category?: string;
}) {
  const showSparkles = category
    ? category === "user_defined"
    : !name.startsWith("fig/");

  return (
    <Text
      as="span"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      {showSparkles && <Sparkle style={{ flex: "0 0 auto" }} />}
      <Truncate title={literate}>{literate}</Truncate>
    </Text>
  );
}
