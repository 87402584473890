// TypeScript declarations for Kopeng's `/api/gql/environment.js`.

/**
 * Runtime environment.
 *
 * Kopeng serves a script `/api/gql/environment.js` that sets a global `env`
 * variable. This is loaded by a `<script>` tag before the rest of our
 * application.
 */
type RuntimeEnv = {
  AUTH0_CLIENT_ID: string;
  AUTH0_CLIENT_DOMAIN: string;
  ENVIRONMENT: string;
  SHOPIFY_API_KEY: string;
  LIGHT_MAP_ID: string;
  LIGHT_REF_LAYER_ID: string;
  MAPBOX_ACCESS_TOKEN: string;
  PR_NUMBER: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
};

declare global {
  interface Window {
    env: RuntimeEnv;
  }
}

export const env = window.env ?? {};
