import { gql } from "@apollo/client";
import {
  Box,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../../../../constants/routeNames";
import { useSojournerQuery } from "../../../../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "../../../../ui/AnimatedZapLogo";
import { Button } from "../../../../ui/Button";
import { CardV2 } from "../../../../ui/Card/CardV2";
import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../../../../ui/ConfigBreakdownTable";
import {
  PopoverInfoPill,
  PopoverInfoPillPart,
} from "../../../../ui/PopoverInfoPill";
import { ResourceIcon } from "../../../../ui/ResourceIcon";
import { RouterLink } from "../../../../ui/RouterLink";
import { useUpdateDataset } from "../../useUpdateDataset";
import {
  ReferencedTargetsQuery,
  ReferencedTargetsQuery_scopes,
} from "./__generated__/ReferencedTargetsQuery";

const referencedTargetsQuery = gql`
  query ReferencedTargetsQuery {
    scopes {
      id
      name
      targets {
        id
        name
        scopeId
        representation {
          __typename
          ... on TargetModesReferenced {
            mode
            reference {
              datasetId
              columnName
            }
            referenceDatasetId
          }
        }
      }
    }
  }
`;

function PipelinesBreakdown({
  column,
  pipelines,
}: {
  column: string;
  pipelines: ReferencedTargetsQuery_scopes[];
}) {
  return (
    <ConfigBreakdownTable
      headers={["Pipeline", "Referenced deployments"]}
      caption={`The number shown for each pipeline is 
      the number of deployments configured in referenced mode against 
      ${column} in this dataset.`}
    >
      {pipelines.map((pipeline) => {
        return (
          <ConfigBreakdownTableRow
            key={pipeline.id}
            icon={ResourceIcon.scopes}
            header={
              <Link
                as={RouterLink}
                routeName={ROUTE_NAMES.PIPELINES_VIEW}
                params={{ id: pipeline.id }}
                fontWeight="bold"
              >
                {pipeline.name}
              </Link>
            }
            value={pipeline.targets.length}
          />
        );
      })}
    </ConfigBreakdownTable>
  );
}

function PipelinesPill({
  column,
  pipelines,
}: {
  column: string;
  pipelines: ReferencedTargetsQuery_scopes[];
}) {
  if (!Object.keys(pipelines).length) return <div>————</div>;

  return (
    <PopoverInfoPill
      popover={<PipelinesBreakdown column={column} pipelines={pipelines} />}
    >
      <PopoverInfoPillPart
        icon={ResourceIcon.scopes}
        value={Object.keys(pipelines).length}
      />
    </PopoverInfoPill>
  );
}

function ReferenceKeyRow({
  referencedPipelines,
  datasetId,
  depreciatedReferenceKeyColumn,
  column,
  deleteReferenceKey,
}: {
  referencedPipelines: ReferencedTargetsQuery_scopes[];
  datasetId: string;
  depreciatedReferenceKeyColumn: string | null;
  column: string;
  deleteReferenceKey: (key: string) => void;
}) {
  const pipelines: ReferencedTargetsQuery_scopes[] = [];
  referencedPipelines.forEach((pipeline) => {
    const affectedTargets = pipeline.targets.filter((target) => {
      const representation = target.representation;
      if (representation.__typename !== "TargetModesReferenced") return false;
      return (
        representation.reference.columnName === column ||
        (column === depreciatedReferenceKeyColumn &&
          representation.referenceDatasetId === datasetId)
      );
    });
    if (affectedTargets.length) {
      pipelines.push({ ...pipeline, targets: affectedTargets });
    }
  });

  function onDelete() {
    deleteReferenceKey(column);
  }

  return (
    <Tr>
      <Td fontFamily="monospace">{column}</Td>
      <Td>
        <PipelinesPill pipelines={pipelines} column={column} />
      </Td>
      <Td textAlign="right">
        <Button
          variant="link"
          fontWeight="normal"
          onClick={onDelete}
          isDisabled={!!Object.keys(pipelines).length}
          title="Cannot deregister reference columns 
          that are currently in use. 
          First, remove all deployments that reference this column, 
          then return here to deregister it."
          analyticsName="deregister-reference-column"
        >
          Deregister
        </Button>
      </Td>
    </Tr>
  );
}

export function DatasetsReferenceKeyForm({
  datasetId,
  referenceKeyColumn,
  referenceKeyColumns,
}: {
  datasetId: string;
  referenceKeyColumn: string | null; // depreciated
  referenceKeyColumns: string[];
}) {
  const { data, loading } = useSojournerQuery<ReferencedTargetsQuery>(
    referencedTargetsQuery
  );

  const { updateDataset, updating } = useUpdateDataset();

  function deleteReferenceKey(referenceKey: string) {
    const newReferenceKeys = [...referenceKeyColumns].filter(
      (col) => col !== referenceKey
    );
    updateDataset(datasetId, {
      reference_key_columns: newReferenceKeys.length ? newReferenceKeys : null,
    });
  }

  if (loading || updating || !data) return <AnimatedZapLogo />;

  const referencedPipelines = data.scopes.filter((scope) =>
    scope.targets.some(
      (target) =>
        target.representation.__typename === "TargetModesReferenced" &&
        target.representation.reference.datasetId === datasetId
    )
  );

  return (
    <Box>
      <CardV2
        title="Reference columns"
        text="Some pipeline deployments identify people with reference to this dataset,
    rather than with contact identifiers (name, address, etc)."
      >
        <Table mb={5}>
          <Thead>
            <Tr>
              <Th>Column</Th>
              <Th>Pipelines</Th>
              <Th>
                <VisuallyHidden>Actions</VisuallyHidden>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {referenceKeyColumns.map((col) => {
              return (
                <ReferenceKeyRow
                  key={col}
                  column={col}
                  datasetId={datasetId}
                  depreciatedReferenceKeyColumn={referenceKeyColumn}
                  referencedPipelines={referencedPipelines}
                  deleteReferenceKey={deleteReferenceKey}
                />
              );
            })}
          </Tbody>
        </Table>
        <Text as="span" color="fdy_gray.700">
          To register a new reference column, add a deployment to any pipeline
          in Referenced mode and choose a column from this dataset to reference
          against.
        </Text>
      </CardV2>
    </Box>
  );
}
