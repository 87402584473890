import { gql, useApolloClient } from "@apollo/client";
import { Download } from "@phosphor-icons/react";
import { ReactElement, useState } from "react";

import { downloadFile } from "../../../utils/downloadFile";
import { Button } from "../../ui/Button";
import {
  AnalysisExportQuery,
  AnalysisExportQueryVariables,
} from "./__generated__/AnalysisExportQuery";

const analysisExportQuery = gql`
  query AnalysisExportQuery($ids: [UUID!]!) {
    analysis(audience_ids: $ids) {
      csv
    }
  }
`;

interface AnalysisExportProps {
  audiences: string[];
}

/**
 * Renders a button which, upon click, fetches the audience analysis CSV string
 * from graphql and triggers a CSV file download of that string.
 *
 * Uses apollo client directly instead of a lazyQuery. The latter would force us to
 * have a `useEffect` hook to trigger download on data load, which feels disconnected.
 * It would also cause automatic redownloads on live reloads if the data is loaded.
 */
export function AnalysisExport({
  audiences,
}: AnalysisExportProps): ReactElement {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  async function handleDownloadClick() {
    setLoading(true);
    try {
      const result = await client.query<
        AnalysisExportQuery,
        AnalysisExportQueryVariables
      >({
        query: analysisExportQuery,
        variables: { ids: audiences },
        // don't store the csv string in case it bogs the apollo cache down
        fetchPolicy: "no-cache",
      });

      if (result.data.analysis.csv) {
        downloadFile({
          filename: "faraday-analysis.csv",
          content: result.data.analysis.csv,
          type: "text/csv",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Button
      leftIcon={<Download />}
      variant="tertiary"
      onClick={handleDownloadClick}
      isLoading={loading}
      disabled={loading}
      analyticsName="analysis-export"
    >
      Export
    </Button>
  );
}
