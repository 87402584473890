import { camelCaseToSnakeCase } from "../utils/casing";

/**
 * These connection options are auto-aliased in GraphQL queries to avoid
 * namespace collisions. For example, if a connection has an option named
 * `username`, it will be aliased to `optionalUsername` in the GraphQL query.
 * This is b/c some other connection will have `username` as a required option.
 */
export const connectionOptionsAliases: Record<string, string> = {
  // If the fields array contains username or password, alias them with an 'optional' prefix:
  username: "optionalUsername",
  password: "optionalPassword",
  schema: "optionalSchema", // Not required for SQL server-like database connections
};

/**
 * API options are the `options` that are passed to the API.
 *
 * Currently applicable to datasets, connections and targets.
 */
export type ApiOptions = {
  /** type/slug of connection i.e. 'hosted_csv'  */
  type: string;
} & Omit<Record<string, unknown>, "__typename">;

/**
 * Converts graphql options received from the API via GraphQL to API spec.
 *
 * This does two things:
 * - converts camelCase to snake_case
 * - removes the `__typename` field
 *
 * Currently applicable to datasets, connections and targets.
 */
export function graphqlOptionsToSpec(
  options: Record<string, unknown> & { type: string; __typename: string }
): ApiOptions {
  return Object.entries(options).reduce((acc, [key, value]) => {
    if (key !== "__typename") {
      acc[camelCaseToSnakeCase(key)] = value;
    }
    return acc;
  }, {} as ApiOptions);
}
