import { useDisclosure } from "@chakra-ui/react";

import { TraitsMap } from "../../../hooks/useTraitsQuery";
import { Connection } from "../../connections/useConnectionsQuery";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { TargetFragment } from "./__generated__/TargetFragment";
import { TargetCard } from "./TargetCard";
import { TargetEditModal } from "./TargetEditModal";
import { ScopeDependencies } from "./useScopeDependencies";

/**
 * Render a target card with an edit button that opens a modal to edit.
 */
export function EditableTargetCard({
  target,
  scope,
  scopeDeps,
  connections,
  traitsMap,
}: {
  target: TargetFragment;
  scope: PipelineFragment;
  scopeDeps: ScopeDependencies;
  connections: Connection[];
  traitsMap: TraitsMap;
}) {
  const { onClose, onOpen, isOpen } = useDisclosure();

  return (
    <>
      <TargetCard
        scope={scope}
        scopeDeps={scopeDeps}
        connections={connections}
        traitsMap={traitsMap}
        target={target}
        onEditClick={onOpen}
      />

      <TargetEditModal
        scope={scope}
        target={target}
        onCancel={onClose}
        isOpen={isOpen}
      />
    </>
  );
}
