import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { TraitsListPage } from "./TraitsListPage";

/**
 * Traits console lets users view and configure their traits.
 */
export function Traits() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.TRAITS]: TraitsListPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.TRAITS} />
  );

  return <Page />;
}
