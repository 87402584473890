import { ModalV2 } from "../ModalV2";
import { ApiShortcuts, MinimalResource } from "./ApiShortcuts";

/**
 * Simple component to render the API shortcuts because we want the inner
 * component to throw loaders and errors to the modal boundary, not crash the
 * whole page.
 */
export function ApiShortcutsModal({
  onClose,
  resource,
}: {
  onClose: () => void;
  resource: MinimalResource;
}) {
  return (
    <ModalV2
      isOpen={true}
      onClose={onClose}
      title="API shortcuts"
      analyticsStackName="api-shortcuts-modal"
    >
      <ApiShortcuts resource={resource} />
    </ModalV2>
  );
}
