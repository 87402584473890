import { Text } from "@chakra-ui/react";
import { PencilSimple } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { formatPendingCount } from "../../../utils/formatPendingCount";
import { CardV2 } from "../../ui/Card/CardV2";
import { LinkButton } from "../../ui/LinkButton";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { scopeInfo } from "../scopeInfo";
import { ScopePopulationBreakdown } from "../ScopePopulationBreakdown";

interface PipelinePopulationCardProps {
  scope: PipelineFragment;
}

export function PipelinePopulationCard({ scope }: PipelinePopulationCardProps) {
  return (
    <CardV2 title="Population" text={scopeInfo.population}>
      <ScopePopulationBreakdown scope={scope} />

      <Text my={6}>
        <strong>Net population</strong>:{" "}
        {formatPendingCount(scope.populationCount)}
        <br />
        <small>
          The total number of unique individuals that your predictions are
          targeting in this pipeline.
        </small>
      </Text>

      <LinkButton
        routeName={ROUTE_NAMES.PIPELINES_EDIT}
        params={{ id: scope.id }}
        variant="secondary"
        leftIcon={<PencilSimple />}
        width="100%"
        analyticsName="edit-population"
      >
        Edit
      </LinkButton>
    </CardV2>
  );
}
