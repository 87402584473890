import capitalize from "lodash/capitalize";

import { colors } from "../../../../styles/chakra-theme-v2";

export const BIAS_CHART_COLORS = {
  positives: colors.fdy_green[500],
  negatives: colors.fdy_magenta[500],
  candidates: colors.fdy_gray[500],
};

export function biasLevelToLiterate(slug: string) {
  return capitalize(slug.replace(/_/g, " "));
}
