import { FC, PropsWithChildren } from "react";
import Confetti from "react-confetti";
import { createStateContext, useWindowSize } from "react-use";

import { colors } from "../../styles/chakra-theme-v2";

const [useConfettiContext, ConfettiProvider] = createStateContext({
  popped: false,
});

function ConfettiPopper() {
  const [state, setState] = useConfettiContext();
  const { width, height } = useWindowSize();

  const sourceWidth = width / 6;

  if (state.popped) {
    return (
      <Confetti
        width={width}
        height={height}
        numberOfPieces={200}
        colors={[
          colors.fdy_green[500],
          colors.fdy_purple[500],
          colors.fdy_yellow[500],
        ]}
        // spawn from the bottom of the screen
        confettiSource={{
          w: sourceWidth,
          h: 10,
          x: width / 2 - sourceWidth / 2, // center
          y: height,
        }}
        initialVelocityY={25}
        initialVelocityX={10}
        gravity={0.5}
        recycle={false} // run once
        onConfettiComplete={() => setState({ popped: false })}
      />
    );
  }

  return null;
}

/**
 * Wrapper to provide confetti popper context while rendering the confetti canvas component.
 */
export const ConfettiPopperProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfettiProvider>
      {children}
      <ConfettiPopper />
    </ConfettiProvider>
  );
};

/**
 * Returns a function that can be called to trigger confetti pop.
 */
export function useConfettiPopper(): { pop: () => void; popped: boolean } {
  const [state, setState] = useConfettiContext();

  return {
    ...state,
    pop: () => setState({ popped: true }),
  };
}

/**
 * Return just the provider for the confetti state, so we can avoid having to test canvas rendering.
 */
export const MockConfettiProvider: FC<PropsWithChildren> = ({ children }) => {
  return <ConfettiProvider>{children}</ConfettiProvider>;
};
