import { Box, Flex, Spinner } from "@chakra-ui/react";
import {
  CaretDown,
  CheckSquare,
  Icon,
  LockSimple,
  MinusSquare,
  Square,
  X,
} from "@phosphor-icons/react";
import { useCallback } from "react";
import ReactSelect, {
  ClearIndicatorProps,
  components,
  createFilter,
  DropdownIndicatorProps,
  GroupBase,
  GroupHeadingProps,
  MenuListProps,
  MultiValueProps,
  MultiValueRemoveProps,
  OnChangeValue,
  OptionProps,
  Props,
  StylesConfig,
} from "react-select";
import type {} from "react-select/base"; // https://react-select.com/typescript#custom-select-props
import { FixedSizeList } from "react-window";

import { colors, fontSizes } from "../../styles/chakra-theme-v2";

// Define custom props so they are accessible in customized components.
// https://react-select.com/typescript#custom-select-props
declare module "react-select/base" {
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>
  > {
    isInvalid?: boolean;
    limitShownMultiValue?: number;
    selectableGroups?: boolean;
  }
}

/**
 * Though this looks 100% custom, react-select does expect some of these keys
 * like `isDisabled`.
 * At a glance, I couldn't find a default type from react-select to extend.
 */
export type FdySelectOption = {
  label: string;
  value: string;
  isDisabled?: boolean;
  /** Displays lock icon instead of checkbox. You may want to set isDisabled too if this is true. */
  showLockIcon?: boolean;
};

/**
 * Though this looks 100% custom, react-select does expect some of these keys
 * like options and label.
 * At a glance, I couldn't find a default type from react-select to extend.
 */
export type FdySelectGroupBase<Option extends FdySelectOption> = {
  label: string;
  options: Option[];
  icon?: Icon;
};

const fdySelectStyles = (
  virtualized: boolean
): StylesConfig<FdySelectOption, true> => ({
  control: (base, state) => ({
    ...base,
    paddingBlock: 4,
    paddingInline: 4,
    borderColor: state.isFocused
      ? colors.fdy_purple[500]
      : state.innerProps["aria-invalid"]
      ? colors.fdy_red[500]
      : colors.fdy_gray[400],
    boxShadow: "none",
    "&:hover": {
      borderColor: colors.fdy_purple[500],
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: colors.fdy_purple[500],
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: fontSizes.fdy_md,
    fontWeight: 600,
    color: colors.white,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    cursor: "pointer",
  }),
  clearIndicator: (base) => ({
    ...base,
    cursor: "pointer",
  }),
  menuPortal: (base) => ({
    ...base,
    // above modal
    zIndex: 10000,
  }),
  menuList: (base) => ({
    ...base,
    overflow: virtualized ? "hidden" : "auto", // Prevents default scrolling behavior for virtualized list
    padding: 0, // Ensures proper alignment for virtualized items
  }),

  option: (base, { isDisabled, isFocused }) => ({
    ...base,
    paddingInline: 16,
    paddingBlock: 8,
    cursor: isDisabled ? "not-allowed" : "pointer",
    backgroundColor: isDisabled
      ? undefined
      : isFocused
      ? colors.fdy_gray[200]
      : colors.white,
    color: isDisabled ? colors.fdy_gray[600] : colors.black,
    "&:hover": {
      backgroundColor: isDisabled ? undefined : colors.fdy_gray[200],
    },
  }),
  multiValueRemove: (base) => ({
    ...base,
    cursor: "pointer",
    color: colors.white,
    "&:hover": {
      color: colors.white,
      backgroundColor: colors.fdy_purple[400],
    },
  }),
  groupHeading: () => ({
    fontWeight: 600,
    color: colors.black,
    fontSize: fontSizes.fdy_md,
    textTransform: "none",
  }),
});

function VirtualizedMenuList<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: MenuListProps<Option, IsMulti, Group>) {
  const itemHeight = 35;
  const { options, children = [], maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * itemHeight;
  console.log("using virtualized menu list");
  return (
    <div>
      <FixedSizeList
        height={maxHeight}
        width="100%"
        itemCount={(children as React.ReactNode[]).length}
        itemSize={itemHeight}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => (
          <div style={style}>{(children as React.ReactNode[])[index]}</div>
        )}
      </FixedSizeList>
    </div>
  );
}

export const fdySelectOptionIconSize = 20;

function Option<
  Option extends FdySelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: OptionProps<Option, IsMulti, Group>) {
  // disabling the default the mouse hovering events helps improve performance and the effects are replaced by custom styles
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps}>
      <Flex
        sx={{
          gap: 2,
          alignItems: "center",
          pl: props.selectProps.selectableGroups ? 4 : 0,
        }}
      >
        {props.data.showLockIcon ? (
          <LockSimple size={fdySelectOptionIconSize} />
        ) : props.isSelected ? (
          <CheckSquare
            weight="fill"
            color={colors.fdy_purple[500]}
            size={fdySelectOptionIconSize}
          />
        ) : (
          <Square size={fdySelectOptionIconSize} />
        )}
        {props.children}
      </Flex>
    </components.Option>
  );
}

function ClearIndicator<
  Option extends FdySelectOption,
  IsMulti extends boolean = false,
  Group extends FdySelectGroupBase<Option> = FdySelectGroupBase<Option>
>(props: ClearIndicatorProps<Option, IsMulti, Group>) {
  return (
    <components.ClearIndicator {...props}>
      <X />
    </components.ClearIndicator>
  );
}

function DropdownIndicator<
  Option extends FdySelectOption,
  IsMulti extends boolean = false,
  Group extends FdySelectGroupBase<Option> = FdySelectGroupBase<Option>
>(props: DropdownIndicatorProps<Option, IsMulti, Group>) {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown />
    </components.DropdownIndicator>
  );
}

function MultiValueRemove<
  Option extends FdySelectOption,
  IsMulti extends boolean = false,
  Group extends FdySelectGroupBase<Option> = FdySelectGroupBase<Option>
>(props: MultiValueRemoveProps<Option, IsMulti, Group>) {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        ...props.innerProps,
        // Reassign aria-label otherwise react-select renders label children,
        // and when using format label, it may not be a string, resulting in
        // 'Remove [object object]'.
        "aria-label": `Remove ${props.data.label}`,
      }}
    >
      <X />
    </components.MultiValueRemove>
  );
}

/**
 * Optionally limits the number of multi-values shown. If the limit is reached, a label
 * will be shown indicating the number of hidden values.
 */
function MultiValue<
  Option extends FdySelectOption,
  IsMulti extends boolean = false,
  Group extends FdySelectGroupBase<Option> = FdySelectGroupBase<Option>
>(props: MultiValueProps<Option, IsMulti, Group>) {
  const { index, getValue, selectProps } = props;
  const limit = selectProps.limitShownMultiValue;

  if (limit === undefined) return <components.MultiValue {...props} />;

  const hiddenLength = getValue().length - limit;

  if (index < limit) {
    return <components.MultiValue {...props} />;
  }
  if (index === limit) {
    return (
      <Box
        sx={{
          ml: 1,
          py: 1,
          px: 2,
          fontSize: "fdy_sm",
          bg: "fdy_gray.200",
          rounded: "full",
        }}
      >{`+${hiddenLength} more`}</Box>
    );
  }

  return null;
}

function LoadingIndicator() {
  return <Spinner size="sm" />;
}

type CheckedStatus = "all" | "some" | "none";
const groupCheckIcons: Record<CheckedStatus, Icon> = {
  none: Square,
  some: MinusSquare,
  all: CheckSquare,
};

// Note: a lot of this doesn't work for Single value selects since group
// selection is inherently multi value so if you need that, you'll need to
// update this component.
function GroupHeading<
  Option extends FdySelectOption,
  IsMulti extends boolean = false,
  Group extends FdySelectGroupBase<Option> = FdySelectGroupBase<Option>
>(props: GroupHeadingProps<Option, IsMulti, Group>) {
  const { onChange, value, isMulti, selectableGroups } = props.selectProps;
  const groupOptions = props.data.options;

  const selectedOptionValues = Array.isArray(value)
    ? value.map((v) => v.value)
    : [];

  const allChecked = groupOptions.every((v) =>
    selectedOptionValues.includes(v.value)
  );

  const someChecked = groupOptions.some((t) =>
    selectedOptionValues.includes(t.value)
  );

  const handleGroupClick = useCallback(() => {
    const usableGroupOptions = groupOptions.filter((o) => !o.isDisabled);

    // if single value, can't add multiple items anyway
    if (!isMulti) return;

    if (Array.isArray(value)) {
      // if all items in group are selected, unselect that group
      if (allChecked) {
        const newVal = value.filter(
          (v) => !usableGroupOptions.map((o) => o.value).includes(v.value)
        );

        onChange(newVal as unknown as OnChangeValue<Option, IsMulti>, {
          action: "deselect-option",
          // we don't care about this meta right now and react-select doesn't
          // support multiple checked at once, so just pass undefined
          option: undefined,
        });
      }
      // If some or no items already selected, merge with current.
      else {
        const newOptions = usableGroupOptions.filter(
          (v) => !selectedOptionValues.includes(v.value)
        );

        // idk a cleaner way to apply this type but it works
        const newVal = [...value, ...newOptions] as unknown as OnChangeValue<
          Option,
          IsMulti
        >;

        onChange(newVal, {
          action: "select-option",
          option: undefined,
        });
      }
    }
  }, [
    onChange,
    value,
    isMulti,
    groupOptions,
    selectedOptionValues,
    allChecked,
  ]);

  const styles = {
    gap: 2,
    py: 2,
    px: 4,
    alignItems: "center",
    width: "100%",
  };

  if (selectableGroups) {
    const checkedStatus = allChecked ? "all" : someChecked ? "some" : "none";
    const CheckedStatusIcon = groupCheckIcons[checkedStatus];
    const allOptionsDisabled = groupOptions.every((o) => o.isDisabled);
    return (
      <components.GroupHeading {...props}>
        <Flex
          as="button"
          disabled={allOptionsDisabled}
          onClick={handleGroupClick}
          sx={{
            ...styles,
            _hover: { bg: "fdy_gray.200" },
            _disabled: {
              cursor: "not-allowed",
              color: "fdy_gray.600",
              _hover: { bg: "none" },
            },
          }}
        >
          {allOptionsDisabled ? (
            <LockSimple size={fdySelectOptionIconSize} />
          ) : (
            <CheckedStatusIcon size={fdySelectOptionIconSize} />
          )}
          {props.children}
        </Flex>
      </components.GroupHeading>
    );
  }

  const GroupHeadingIcon = props.data.icon;
  return (
    <Flex sx={styles}>
      {GroupHeadingIcon && <GroupHeadingIcon />}
      <components.GroupHeading {...props} />
    </Flex>
  );
}

type FdyReactSelectProps<
  Option extends FdySelectOption,
  IsMulti extends boolean = false,
  Group extends FdySelectGroupBase<Option> = FdySelectGroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  virtualized?: boolean;
};

/**
 * Wrap react-select so we can define core settings for Faraday's use cases
 * and style preferences.
 */
export function FdyReactSelect<
  Option extends FdySelectOption,
  IsMulti extends boolean = false,
  Group extends FdySelectGroupBase<Option> = FdySelectGroupBase<Option>
>({
  virtualized = false, // Default virtualized to false
  ...props
}: FdyReactSelectProps<Option, IsMulti, Group>) {
  let virtualizedMenuList = {};
  if (virtualized) {
    virtualizedMenuList = { MenuList: VirtualizedMenuList };
  }
  return (
    <ReactSelect<Option, IsMulti, Group>
      {...props}
      filterOption={createFilter({ ignoreAccents: false })} //when typing characters react-select will by default strip accents from the options by calling a function stripDiacritics twice for every character which can effect performance especially when its not really relevant for our use case, so we disable it
      aria-invalid={props["aria-invalid"] || props.isInvalid}
      closeMenuOnSelect={!props.isMulti}
      hideSelectedOptions={false}
      // below props can't be overridden when using this wrapper since we spread ^.
      // only render MenuList: VirtualizedMenuList component if options length is greater than 300 otherwise just use default MenuList
      components={{
        // don't define inline:
        // https://react-select.com/components#defining-components
        Option,
        ClearIndicator,
        DropdownIndicator,
        MultiValueRemove,
        LoadingIndicator,
        MultiValue,
        GroupHeading,
        ...virtualizedMenuList,
        IndicatorSeparator: null,
      }}
      // ensure menu shows outside modal scroll areas
      menuPortalTarget={document.body}
      // Typing for styles config is not obvious to me. Knowing our narrowed
      // types in it seems less useful so just ignore it.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      styles={fdySelectStyles(virtualized)}
    />
  );
}
