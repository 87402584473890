import { useState } from "react";

import {
  DatasetsTableQuery_connections as Connection,
  DatasetsTableQuery_datasets as Dataset,
} from "../__generated__/DatasetsTableQuery";
import { DatasetsCreateModalConnection } from "./DatasetsCreateModalConnection";
import { DatasetsCreateModalConnectionOptions } from "./DatasetsCreateModalConnectionOptions";
import { DatasetsCreateModalHostedCsv } from "./DatasetsCreateModalHostedCsv";
import { DatasetsCreateModalMerge } from "./DatasetsCreateModalMerge";
import { DefaultConnectionTypes, hostedCSV, merged } from "./SelectConnection";

enum AvailableModals {
  connectionSetup,
  connectionSetupHosted,
  connectionSetupMerge,
  connectionSetupOptions,
}

/**
 * Renders a form to create a new dataset
 */
export function DatasetsCreateModal({
  onClose,
  datasets,
  connections,
}: {
  onClose: () => void;
  datasets: Dataset[] | undefined;
  connections: Connection[] | undefined;
}) {
  const [connectionId, setConnectionId] = useState<string>(
    DefaultConnectionTypes.hostedCSV
  );
  const [selectedModal, setSelectedModal] = useState<AvailableModals>(
    AvailableModals.connectionSetup
  );

  if (!connections) return null;

  function getSelectedConnection(selected: string) {
    // we create these connection types for the user
    // so they may not be in the 'connections' list yet
    if (selected === DefaultConnectionTypes.hostedCSV) return hostedCSV;
    if (selected === DefaultConnectionTypes.merge) return merged;
    // user shouldn't see this. They had no connections, yet managed to pick a connection
    if (!connections) throw Error("unknown connection");
    const foundConnection = connections.find((conn) => conn.id === selected);
    // user shouldn't see this. They managed to pick a connection not in their list of connections
    if (!foundConnection) throw Error("unknown connection");
    return foundConnection;
  }
  return (
    <>
      <DatasetsCreateModalConnection
        isOpen={selectedModal === AvailableModals.connectionSetup}
        onClose={onClose}
        onNext={() => {
          const connection = getSelectedConnection(connectionId);
          if (connection?.options.type === "hosted_csv") {
            setSelectedModal(AvailableModals.connectionSetupHosted);
          } else if (connection?.options.type === "merge") {
            setSelectedModal(AvailableModals.connectionSetupMerge);
          } else {
            setSelectedModal(AvailableModals.connectionSetupOptions);
          }
        }}
        datasets={datasets}
        connections={connections}
        connectionId={connectionId}
        setConnectionId={setConnectionId}
      />
      <DatasetsCreateModalConnectionOptions
        isOpen={selectedModal === AvailableModals.connectionSetupOptions}
        onClose={onClose}
        onBack={() => setSelectedModal(AvailableModals.connectionSetup)}
        datasets={datasets}
        connections={connections}
        connectionId={connectionId}
      />

      <DatasetsCreateModalHostedCsv
        isOpen={selectedModal === AvailableModals.connectionSetupHosted}
        onClose={onClose}
        onBack={() => setSelectedModal(AvailableModals.connectionSetup)}
      />

      <DatasetsCreateModalMerge
        isOpen={selectedModal === AvailableModals.connectionSetupMerge}
        onClose={onClose}
        onBack={() => setSelectedModal(AvailableModals.connectionSetup)}
        datasets={datasets}
        connections={connections}
      />
    </>
  );
}
