import { ArrowSquareOut } from "@phosphor-icons/react";
import { ReactElement } from "react";

import { Button } from "./Button";

export interface DocsLinkProps {
  resourceType: string;
  size?: "sm" | "md";
}

/*
 * Renders a button that links to the Faraday docs for a given resource type.
 */

export function DocsLink({
  resourceType,
  size = "md",
}: DocsLinkProps): ReactElement {
  return (
    <Button
      as="a"
      size={size}
      href={`https://faraday.ai/docs/abstractions/${resourceType}`}
      target="_blank"
      rel="noreferrer"
      variant="secondary"
      analyticsName="docs"
      rightIcon={<ArrowSquareOut />}
    >
      Docs
    </Button>
  );
}
