// if hex used for victor tiles, it must be 6 chars
const colors = {
  bittersweet: "#ff795d",
  eggplant: "#bc2a66",
  heliotrope: "#c569ff",
  magenta: "#e85ea5",
  teal: "#49ada6",
  light_teal: "#9cd3cf",
  gold: "#e9d362",
  light_gold: "#fdfbef",
  light_gray: "#cccccc",
  /** @deprecated use pale_gray */
  lighter_gray: "#eeeeee",
  pale_gray: "#eeeeee",
  med_gray: "#999999",
  med_light_gray: "#b8b8b8",
  darker_gray: "#565656",
  dark_gray: "#666666",
  darkest_gray: "#222222",
  white: "#fff",
  black: "#231f20",
  off_white:
    "#f8f8f8" /* rename to faint_gray or ideally some sort of gray_lightest if we rename figma */,
  off_black: "#444",
  red: "#ff0000",

  focusRing: "rgba(153, 153, 153, 0.5)",
} as const;

export const theme = {
  colors,

  /**
   * Generate a spacing unit using base multiplier for adhering to 8px grid system.
   */
  space: (unit = 1, multiplier = 8): string | number => {
    const value = unit * multiplier;

    return value === 0 ? value : `${value}px`;
  },

  fonts: {
    body: "Aktiv Grotesk, sans-serif",
    monospace: "monospace",
  },

  // All relative to body font size of 14px
  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    xxl: "1.5rem", // 24px
  },

  fontWeights: {
    normal: 400,
    bold: 700,
    black: 900,
  },

  lineHeights: {
    solid: 1,
    heading: 1.2,
    body: 1.5,
  },

  radii: {
    sm: "2px",
    md: "4px",
    lg: "8px",
    xl: "16px",
    // Arbitrary large number which ensures fully rounded borders. 100% does not work for this.
    full: "9999px",
  },

  // em units used as it should scale with font size
  letterSpacings: {
    sm: "0.01em",
    md: "0.05em",
  },

  durations: {
    quick: "120ms",
    medium: "250ms",
    long: "500ms",
  },

  shadows: {
    standard: "0 2px 4px rgba(0, 0, 0, 0.1)",
    nondirectional: "0 0 4px rgba(0, 0, 0, 0.1)",
    subtle: "0px 4px 8px rgba(0, 0, 0, 0.16)" /** from figma tokens */,
    active: `0 0 0 2px ${colors.gold}`,
    activeLeft: `-4px 0 0 ${colors.gold}`,
    activeLeftInset: `inset 4px 0 0 ${colors.gold}`,
    focusRing: "0 0 0 2px rgba(153, 153, 153, 0.5)",
  },

  breakpoints: {
    xl: "1600px",
  },

  opacities: {
    disabled: 0.5,
  },

  // Casting as const makes VS Code autocomplete/tooltips show design token values
} as const;
