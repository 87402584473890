import { env } from "../env";

export const API_URL_PRODUCTION = "https://api.faraday.ai/v1";

export function getApiBaseUrl() {
  if (env.PR_NUMBER) {
    return `https://api-${env.PR_NUMBER}.dev.faraday.ai/v1`;
  }

  if (process.env.NODE_ENV === "production") {
    // during acceptance testing, the app is built with the production env
    // but the api is still on the test domain (unlike branch deploys)
    if (window.location.href.includes("app.faraday.test")) {
      return "https://api.faraday.test/v1";
    }
    // otherwise use the production url
    return API_URL_PRODUCTION;
  }

  return "https://api.faraday.test:48283/v1";
}
