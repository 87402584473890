import { gql, MutationHookOptions } from "@apollo/client";
import { TargetMergePatch } from "@fdy/faraday-js";

import { useSojournerMutation } from "../../../../services/sojournerApolloClient";
import { targetFragment } from "../../deployment/targetFragment";
import {
  UpdateTargetMutationHook,
  UpdateTargetMutationHookVariables,
} from "./__generated__/UpdateTargetMutationHook";

export const UPDATE_TARGET = gql`
  mutation UpdateTargetMutationHook($id: ID!, $target: String!) {
    updateTarget(applicationJsonMergePatchInput: $target, targetId: $id) {
      ...TargetFragment
    }
  }
  ${targetFragment}
`;

export function useUpdateTarget(
  options?: MutationHookOptions<
    UpdateTargetMutationHook,
    UpdateTargetMutationHookVariables
  >
) {
  const [mutate, { loading: updating }] = useSojournerMutation<
    UpdateTargetMutationHook,
    UpdateTargetMutationHookVariables
  >(UPDATE_TARGET, options);

  function updateTarget(id: string, input: TargetMergePatch) {
    return mutate({
      variables: {
        id,
        target: JSON.stringify(input),
      },
    });
  }

  return {
    updateTarget,
    updating,
  };
}
