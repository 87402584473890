import { ParentSizeModern } from "@visx/responsive";
import { XYChart } from "@visx/xychart";
import { useMemo, useState } from "react";

import { colors } from "../../../styles/chakra-theme-v2";
import { AxisBottomDates } from "../../ui/charts-v2/AxisBottomDates";
import { sortPersonas } from "../analysis/utils";
import { PersonaFlowAxisRight } from "./PersonaFlowAxisRight";
import {
  Persona,
  PersonaFlow,
  removeEmptyDays,
  transformFlowForStacks,
} from "./personaFlowChartUtils";
import { PersonaFlowStackedAreas } from "./PersonaFlowStackedAreas";
import { PersonaFlowTooltip } from "./PersonaFlowTooltip";

/**
 * Renders a stacked area chart for each persona in the set.
 * The bottom axis is the date range, and the left axis is the number of members in the persona out of 100%.
 */
export function PersonaFlowChart({
  flow,
  personas,
}: {
  flow: PersonaFlow[];
  personas: Persona[];
}) {
  const [hoverKey, setHoverKey] = useState<string>();
  const chartFlow = useMemo(() => {
    const transformed = transformFlowForStacks(flow);
    return removeEmptyDays(transformed);
  }, [flow]);
  const sortedPersonas = sortPersonas(personas);

  const margin = {
    top: 32,
    left: 32,
    right: 32,
    bottom: 48,
  };

  return (
    <ParentSizeModern
      style={{
        minWidth: 400,
        height: 600,
        border: "1px solid",
        borderColor: colors.fdy_gray[300],
        borderRadius: 6,
      }}
    >
      {({ width, height }) => (
        <XYChart
          accessibilityLabel="Personas over time"
          width={width}
          height={height}
          xScale={{ type: "time" }}
          yScale={{ type: "linear" }}
          margin={margin}
          onPointerMove={(e) => {
            setHoverKey(e.key);
          }}
          onPointerOut={() => {
            setHoverKey(undefined);
          }}
        >
          <PersonaFlowStackedAreas
            personas={sortedPersonas}
            hoverKey={hoverKey}
            flow={chartFlow}
          />
          <AxisBottomDates />
          <PersonaFlowAxisRight
            flow={chartFlow}
            personas={sortedPersonas}
            width={width}
            height={height}
            margin={margin}
          />
          <PersonaFlowTooltip flow={chartFlow} personas={sortedPersonas} />
        </XYChart>
      )}
    </ParentSizeModern>
  );
}
