import { gql } from "@apollo/client";

import { useSojournerQuery } from "../../services/sojournerApolloClient";
import {
  PersonaSetHookQuery,
  PersonaSetHookQueryVariables,
} from "./__generated__/PersonaSetHookQuery";
import { personaSetFragment } from "./personaSetFragment";

export const PERSONA_SET_HOOK_QUERY = gql`
  query PersonaSetHookQuery($id: ID!) {
    personaSet(personaSetId: $id) {
      ...PersonaSetFragment
    }
  }
  ${personaSetFragment}
`;

export function usePersonaSet(personaSetId: string) {
  return useSojournerQuery<PersonaSetHookQuery, PersonaSetHookQueryVariables>(
    PERSONA_SET_HOOK_QUERY,
    {
      variables: {
        id: personaSetId,
      },
    }
  );
}
