import { Divider, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { PencilSimple } from "@phosphor-icons/react";

import { OutcomeBiasMitigationStrategy } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { CardV2 } from "../../ui/Card/CardV2";
import { Cardv2Section } from "../../ui/Card/Cardv2Section";
import { LinkButton } from "../../ui/LinkButton";
import { RouterLink } from "../../ui/RouterLink";
import { SkeletonCard } from "../../ui/SkeletonCard";
import {
  OutcomesShowPageQuery_outcome,
  OutcomesShowPageQuery_outcome_biasMitigation as Mitigaion,
} from "../__generated__/OutcomesShowPageQuery";
import { OutcomeAnalysisBiasReport } from "./OutcomeAnalysisBiasReport";
import { useOutcomeAnalysisQuery } from "./useOutcomeAnalysisQuery";

interface OutcomeBiasCardProps {
  outcome: OutcomesShowPageQuery_outcome;
}

function OutcomeBiasMitigation({
  bias,
}: {
  bias: Mitigaion | null | undefined;
}) {
  const config: Mitigaion = bias ?? {
    __typename: "BiasMitigation",
    age: OutcomeBiasMitigationStrategy.NONE,
    gender: OutcomeBiasMitigationStrategy.NONE,
  };

  return (
    <Cardv2Section
      title="Mitigation"
      text="All bias listed above is being actively mitigated as indicated below."
    >
      <Table>
        <Thead>
          <Tr>
            <Th w={200}>Sensitive dimension</Th>
            <Th>Mitigation</Th>
          </Tr>
        </Thead>
        <Tbody>
          {["age", "gender"].map((dimension) => {
            const strategy = config[dimension as keyof Mitigaion];
            return (
              <Tr key={dimension} textTransform="capitalize">
                <Td bg="fdy_gray.100">{dimension}</Td>
                <Td>{strategy?.toLowerCase()}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Cardv2Section>
  );
}

/**
 * Renders the bias mitigation report and settings for an outcome.
 */
export function OutcomeBiasCard({ outcome }: OutcomeBiasCardProps) {
  const { data, loading } = useOutcomeAnalysisQuery(outcome);

  // It's fine user sees skeleton for nothing to ultimately display. This should be on older outcomes mainly.
  if (loading) return <SkeletonCard />;

  return (
    <CardV2
      title="Bias"
      text={
        <>
          Significant differences between the attainment and eligibility groups
          may cause this outcome to exhibit bias in the sensitive dimensions
          shown below. See our{" "}
          <RouterLink
            href="https://faraday.ai/docs/abstractions/outcomes#outcome-bias"
            target="_blank"
          >
            docs
          </RouterLink>{" "}
          for bias control information.
        </>
      }
    >
      {data?.outcomeAnalysis?.bias && (
        <>
          <OutcomeAnalysisBiasReport
            outcome={outcome}
            report={data.outcomeAnalysis.bias}
          />
          <Divider my={4} />
        </>
      )}

      <OutcomeBiasMitigation bias={outcome.biasMitigation} />
      <LinkButton
        width="100%"
        variant="secondary"
        routeName={ROUTE_NAMES.OUTCOMES_EDIT}
        params={{ outcome: outcome.id }}
        leftIcon={<PencilSimple />}
        mt={4}
        analyticsName="bias-edit-outcome"
      >
        Edit
      </LinkButton>
    </CardV2>
  );
}
