import { Flex } from "@chakra-ui/react";
import * as React from "react";

export function IconWithText({ children }: { children: React.ReactNode }) {
  return (
    <Flex gap={1} alignItems="center">
      {children}
    </Flex>
  );
}
