import * as React from "react";

import { MutedText } from "../ui/MutedText";
import { ResourceLink } from "../ui/ResourceLink";

type Resource = {
  __typename: string;
  id: string;
  name: string;
};

export function ResourceLinks<T extends Resource>({
  resources,
  renderAfterLink,
}: {
  resources: T[];
  renderAfterLink?: (resource: T) => React.ReactNode;
}) {
  if (resources.length === 0) {
    return <MutedText>None</MutedText>;
  }

  return (
    <ul style={{ display: "grid", gap: 4 }}>
      {resources.map((resource) => (
        <li key={resource.id}>
          <ResourceLink resource={resource}>{resource.name}</ResourceLink>
          {renderAfterLink?.(resource)}
        </li>
      ))}
    </ul>
  );
}
