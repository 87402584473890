import { ReactNode } from "react";

import { colors, fontSizes } from "../../../../styles/chakra-theme-v2";

interface Marker {
  /** x coordinate to place marker. Bottom left is 0,0  */
  x: number;
  /** y coordinate to place marker. Bottom left is 0,0 */
  y: number;
}

type Dataset = {
  meta: {
    label: string;
    colors: {
      bg: string;
      fg: string;
    };
  };
}[];

export interface GridChartProps {
  xLabel: ReactNode;
  yLabel: ReactNode;
  marker?: Marker;
  datasets: Dataset[];
}

function CellMarker({ label }: { label: string }) {
  return (
    <>
      {/* multi-ring dot marker */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#000",
          height: 16,
          width: 16,
          borderRadius: "999rem",
          boxShadow: "0 0 0 8px #fff, 0 0 0 16px rgba(255,255,255,.5)",
        }}
      />

      {/*
        Mock tooltip with arrow. Chakra's was being too janky when opening in
        accordions and scrolling. We _probably_ don't need smart tooltip placement.
      */}
      {/* arrow */}
      <div
        style={{
          position: "absolute",
          top: "80%",
          left: "50%",
          transform: "translate(-50%, -50%) rotate(45deg)",
          width: 4,
          height: 4,
          background: "#000",
        }}
      />

      {/* tip */}
      <div
        style={{
          position: "absolute",
          top: "80%",
          left: "50%",
          transform: "translateX(-50%)",
          background: "#000",
          color: "white",
          padding: ".5rem",
          fontSize: fontSizes.fdy_sm,
          fontWeight: 600,
          borderRadius: 6,
          whiteSpace: "nowrap",
          zIndex: 10,
        }}
      >
        {label}
      </div>
    </>
  );
}

function GridCells({
  marker,
  datasets,
}: {
  marker?: Marker;
  datasets: Dataset[];
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      {datasets.map((y, yIndex) => {
        return (
          <div
            key={yIndex}
            style={{
              display: "flex",
              gap: 2,
              flex: 1,
            }}
          >
            {y.map((x, xIndex) => {
              return (
                <div
                  key={`${yIndex}-${xIndex}`}
                  style={{
                    flex: 1,
                    backgroundColor: x.meta.colors.bg,
                    position: "relative",
                    aspectRatio: "1/1",
                  }}
                >
                  {marker?.x === xIndex && marker?.y === yIndex && (
                    <CellMarker label={x.meta.label} />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function GridLabelsWrap({
  xLabel,
  yLabel,
  children,
}: {
  xLabel: ReactNode;
  yLabel: ReactNode;
  children: ReactNode;
}) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridTemplateRows: "1fr auto",
        alignItems: "center",
        maxWidth: 600,
        border: "1px solid",
        borderColor: colors.fdy_gray[300],
        padding: "1rem",
        borderRadius: 6,
      }}
      aria-hidden
    >
      <div
        style={{
          writingMode: "vertical-rl",
          transform: "rotate(180deg)",
          textAlign: "center",
          // matching font sizes of svg charts
          fontSize: 12,
          paddingLeft: "1rem",
        }}
      >
        {yLabel}
      </div>
      <div>
        {children}
        <div
          style={{
            textAlign: "center",
            paddingTop: "1rem",
            fontSize: 12,
          }}
        >
          {xLabel}
        </div>
      </div>
    </div>
  );
}

/**
 * Renders a grid of cells with a marker at the its passed X and Y coordinates.
 */
export function GridChart({
  xLabel,
  yLabel,
  marker,
  datasets,
}: GridChartProps) {
  return (
    <GridLabelsWrap xLabel={xLabel} yLabel={yLabel}>
      <GridCells marker={marker} datasets={datasets} />
    </GridLabelsWrap>
  );
}
