import connectionTypeOptions from "../../../../vannevar/connection_types_options.json";

// if you can't put the ConnectionTypeForm into a <form>, use this instead to validate
export function connectionOptionsError(options: SojJSON): string | undefined {
  for (const [property, value] of Object.entries(options)) {
    const validation = connectionTypeOptions.find(
      (option) => option.slug === property
    );
    if (!validation) {
      // if you didn't find a connectionTypeOption, it's probably __typename or type
      // no validation specified for these, so it should pass
      continue;
    }
    if (!value) {
      if (validation.required)
        return `Advanced option '${property}' is required`;
      // nulls will fail validation_regex, so exit the loop here
      continue;
    }
    if (validation.validation_regex) {
      // if you hit this condition, it's probably a bug
      if (typeof value !== "string" && typeof value !== "number")
        return `Advanced option '${property}' is incorrect format`;
      // check validation_regex
      if (!new RegExp(validation.validation_regex).test(value.toString()))
        return `Advanced option '${property}' must match the following format: ${validation.validation_regex}`;
    }
  }
  return;
}
