import { ToastComponentProps, useToast } from "../../hooks/useToast";
import { SwitchV2 } from "../ui/Switch/SwitchV2";
import { useUpdatePersonaSet } from "./useUpdatePersonaSet";

export const personaSetExploreToast: ToastComponentProps = {
  status: "success",
  title: "Persona set enabled for Explore",
  description:
    "Persona set will be available in Explore soon. We will email you when it's ready.",
};

export const makeExploreSwitchLabel = (name: string) =>
  `Show ${name} in Explore`;

interface PersonaSetExploreSwitchProps {
  personaSet: {
    id: string;
    explore: boolean | null;
    name: string;
  };
}

/**
 * Render a switch to enable/disable a persona set for Explore
 */
export function PersonaSetExploreSwitch({
  personaSet,
}: PersonaSetExploreSwitchProps) {
  const toast = useToast();

  const { updatePersonaSet, updating } = useUpdatePersonaSet({
    onCompleted: (data) => {
      if (data.updatePersonaSet?.explore) {
        toast(personaSetExploreToast);
      }
    },
  });

  const checked = personaSet.explore ?? false;

  function handleToggle() {
    updatePersonaSet(personaSet.id, {
      explore: !checked,
    });
  }

  return (
    <SwitchV2
      label={makeExploreSwitchLabel(personaSet.name)}
      visuallyHideLabel
      isChecked={checked}
      onChange={handleToggle}
      isDisabled={updating}
    />
  );
}
