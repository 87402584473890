import { Box, Text } from "@chakra-ui/react";
import { ChangeEvent } from "react";

import {
  CohortStreamConditionInput,
  CohortTraitInput,
} from "../../../../__generated__/sojournerGlobalTypes";
import { Checkbox } from "../../../ui/Checkbox";
import { CohortFilterEditorProps } from ".";

enum BooleanCriterion {
  TRUE = "TRUE",
  FALSE = "FALSE",
  MISSING = "MISSING",
}

export type BooleanFilterState = BooleanCriterion[];

type FilterBooleanCheckbox = {
  label: string;
  hint: string;
  value: BooleanCriterion;
};

const BOOLEAN_TRAIT_CHECKBOXES: FilterBooleanCheckbox[] = [
  {
    label: "True",
    hint: "include anyone with this trait",
    value: BooleanCriterion.TRUE,
  },
  {
    label: "False",
    hint: "include anyone who doesn't have this trait",
    value: BooleanCriterion.FALSE,
  },
  {
    label: "Missing",
    hint: "include anyone with null / who is unknown to have this trait",
    value: BooleanCriterion.MISSING,
  },
];

/**
 * Convert boolean cohort trait condition to state for the checkboxes UI
 */
export function booleanTraitFromWire(
  cohortTrait: Omit<CohortTraitInput, "name">
): BooleanFilterState {
  if (cohortTrait.eq?.toLowerCase() === "true") {
    return [BooleanCriterion.TRUE];
  }
  if (cohortTrait.eq?.toLowerCase() === "false") {
    return [BooleanCriterion.FALSE];
  }
  if (cohortTrait.null === true) {
    return [BooleanCriterion.MISSING];
  }
  if (
    cohortTrait.nin?.length === 1 &&
    cohortTrait.nin?.map((s) => s?.toLowerCase()).includes("true")
  ) {
    return [BooleanCriterion.FALSE, BooleanCriterion.MISSING];
  }

  if (
    cohortTrait.nin?.length === 1 &&
    cohortTrait.nin?.map((s) => s?.toLowerCase()).includes("false")
  ) {
    return [BooleanCriterion.MISSING, BooleanCriterion.TRUE];
  }

  if (
    (cohortTrait.in?.length === 2 &&
      cohortTrait.in?.map((s) => s?.toLowerCase()).includes("true") &&
      cohortTrait.in?.map((s) => s?.toLowerCase()).includes("false")) ||
    cohortTrait.nnull
  ) {
    return [BooleanCriterion.FALSE, BooleanCriterion.TRUE];
  }

  return [];
}

export function booleanFilterToWire<
  WireFormat extends CohortTraitInput | CohortStreamConditionInput
>(filters: WireFormat, val: BooleanFilterState): WireFormat {
  if (val.length === 1) {
    if (val.includes(BooleanCriterion.TRUE)) filters.eq = "true";
    if (val.includes(BooleanCriterion.FALSE)) filters.eq = "false";
    if (val.includes(BooleanCriterion.MISSING)) filters.null = true;
  } else if (val.length === 2) {
    if (
      val.includes(BooleanCriterion.FALSE) &&
      val.includes(BooleanCriterion.MISSING)
    ) {
      filters.nin = ["true"];
    }

    if (
      val.includes(BooleanCriterion.TRUE) &&
      val.includes(BooleanCriterion.MISSING)
    ) {
      filters.nin = ["false"];
    }

    if (
      val.includes(BooleanCriterion.FALSE) &&
      val.includes(BooleanCriterion.TRUE)
    ) {
      filters.in = ["false", "true"];
    }
  }

  return filters;
}

export function CohortFilterEditorBoolean<
  CohortConditionInput extends CohortTraitInput | CohortStreamConditionInput
>({
  filterName,
  condition,
  onChange,
  booleanConditionToWire,
}: CohortFilterEditorProps<CohortConditionInput>) {
  const val = booleanTraitFromWire(condition);

  const handleCheckChange =
    (c: FilterBooleanCheckbox) => (e: ChangeEvent<HTMLInputElement>) => {
      let selectedOptions = val;

      // if checked and not in the list, add it
      if (e.target.checked && !val.includes(c.value)) {
        selectedOptions.push(c.value);
      }
      //  if not checked and in the list, remove it
      else if (!e.target.checked && val.includes(c.value)) {
        selectedOptions = selectedOptions.filter((o) => o !== c.value);
      }

      onChange(booleanConditionToWire(filterName, selectedOptions));
    };

  return (
    <Box sx={{ display: "grid", gap: 4 }}>
      {BOOLEAN_TRAIT_CHECKBOXES.map((c) => {
        // User can only select 2 checkboxes at a time.
        // There's no reason for selecting all 3 (they should just remove this trait)
        const disabled = val.length === 2 && !val.includes(c.value);
        return (
          <Checkbox
            key={c.label}
            value={c.value}
            onChange={handleCheckChange(c)}
            isChecked={val.includes(c.value)}
            isDisabled={disabled}
            analyticsName="cohort-boolean-filter"
          >
            <span>{c.label}</span>
            <Text
              as="span"
              sx={{ color: "fdy_gray.600", fontStyle: "italic", ml: 2 }}
            >
              {c.hint}
            </Text>
          </Checkbox>
        );
      })}
    </Box>
  );
}
