import { ReactElement } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Prose } from "../ui/Prose";
import { RouterLink } from "../ui/RouterLink";

export function OutcomeInfoSidebar(): ReactElement {
  return (
    <Prose>
      <p>
        Outcomes represent business goals you want to predict the likelihood of
        (“propensity”).
      </p>

      <p>
        For example, you could define an outcome representing the likelihood
        that a given person will become a customer, or a lead's likelihood to
        convert, or an existing customer's likelihood to buy again.
      </p>

      <h3>Defining an outcome</h3>

      <p>
        In order to define an outcome, you choose up to 3 groups of people to
        serve in different roles. Faraday then uses these groups to
        automatically build and evaluate machine learning models that predict
        your outcome.
      </p>

      <ul>
        <li>
          The most important group is called <strong>attainment</strong>—these
          are the people who have already attained this outcome in the past.
        </li>
        <li>
          You can also optionally choose an <strong>attrition</strong>{" "}
          group—these are people that explicitly failed to attain the outcome.
        </li>
        <li>
          Finally, you can optionally choose an <strong>eligibility</strong>{" "}
          group representing all candidates for the outcome.
        </li>
      </ul>

      <h3>Using an outcome</h3>

      <p>
        In order to make predictions using your outcomes,{" "}
        <RouterLink routeName={ROUTE_NAMES.PIPELINES_NEW}>
          create a pipeline.
        </RouterLink>{" "}
        Make sure to add your outcome to the pipeline's payload and choose a
        convenient target for deployment.
      </p>
    </Prose>
  );
}
