import { Recommender } from "@fdy/faraday-js";

import { objectEntries } from "../../../utils/objectEntries";
import { AccordionV2 } from "../../ui/AccordionV2";
import { Blankslate } from "../../ui/Blankslate";
import { useRecommenderAnalysis } from "../recommenderQueries";
import { RecommenderAnalysisPerformanceCharts } from "./RecommenderAnalysisPerformanceCharts";
import { modelGroupToLiterate } from "./recommenderUtils";

interface RecommenderAnalysisPerformanceProps {
  recommender: Recommender;
}

export function RecommenderAnalysisPerformance({
  recommender,
}: RecommenderAnalysisPerformanceProps) {
  const { data } = useRecommenderAnalysis(recommender);

  if (!data?.performance?.meta_model) {
    return (
      <Blankslate
        filled
        title="No performance data"
        text="Performance data will be available once the recommender has been built."
      />
    );
  }

  const { overall, ...rest } = data.performance.meta_model;

  return (
    <AccordionV2 defaultIndex={0} allowToggle>
      {overall && (
        <AccordionV2.Item
          key="overall"
          title={modelGroupToLiterate("overall", recommender.stream_name)}
        >
          <RecommenderAnalysisPerformanceCharts charts={overall} />
        </AccordionV2.Item>
      )}

      {objectEntries(rest)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([label, value]) => {
          if (!value) return;
          return (
            <AccordionV2.Item
              key={label}
              title={modelGroupToLiterate(label, recommender.stream_name)}
            >
              <RecommenderAnalysisPerformanceCharts charts={value} />
            </AccordionV2.Item>
          );
        })}
    </AccordionV2>
  );
}
