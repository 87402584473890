/**
 * Download a file with a content blob and a clicked <a href="" download> link trick
 */
export function downloadFile({
  filename,
  content,
  type,
}: {
  filename: string;
  /** Text content of the file to download */
  content: string;
  /** Blob file type e.g. text/csv */
  type: string;
}): void {
  const url = URL.createObjectURL(new Blob([content], { type }));

  const anchor = document.createElement("a");

  anchor.setAttribute("href", url);
  anchor.setAttribute("download", filename);
  anchor.setAttribute("hidden", "hidden");

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);
}
