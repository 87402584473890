import { Icon } from "@phosphor-icons/react";
import { ReactElement } from "react";

import * as ACCOUNT_FEATURES from "../../constants/accountFeatureNames";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  AccountFeature,
  useAccountConfigMap,
} from "../../hooks/accountConfigHooks";
import { ResourceIcon } from "../ui/ResourceIcon";
import { NavigationBarGroup } from "./NavigationBarGroup";
import { NavigationBarLink } from "./NavigationBarOption";

interface PredictionsLinkOptions {
  feature?: AccountFeature;
  icon: Icon;
  label: string;
  routeName: string;
  routeParams?: Record<string, string>;
}

const navItems: PredictionsLinkOptions[] = [
  {
    icon: ResourceIcon.outcomes,
    label: "Outcomes",
    routeName: ROUTE_NAMES.OUTCOMES,
    feature: ACCOUNT_FEATURES.OUTCOMES_ENABLED,
  },
  {
    icon: ResourceIcon.persona_sets,
    label: "Persona sets",
    routeName: ROUTE_NAMES.PERSONAS,
    feature: ACCOUNT_FEATURES.PERSONAS_ENABLED,
  },
  {
    icon: ResourceIcon.recommenders,
    label: "Recommenders",
    routeName: ROUTE_NAMES.RECOMMENDERS,
    feature: ACCOUNT_FEATURES.RECOMMENDERS_ENABLED,
  },
];

export function PredictionsNav(): ReactElement {
  const featureMap = useAccountConfigMap();

  function renderOption({
    feature,
    icon,
    label,
    routeName,
    routeParams,
  }: PredictionsLinkOptions) {
    if (feature && !featureMap[feature]) {
      return null;
    }

    return (
      <NavigationBarLink
        key={label}
        icon={icon}
        label={label}
        routeName={routeName}
        routeParams={routeParams}
        analyticsName={label.toLowerCase()}
      />
    );
  }

  return (
    <NavigationBarGroup label="Predictions">
      {navItems.map(renderOption).filter(Boolean)}
    </NavigationBarGroup>
  );
}
