import { ResourceType } from "@fdy/faraday-js";
import { Cube, Sparkle } from "@phosphor-icons/react";
import { ComponentType } from "react";
import { EdgeMarker, MarkerType, NodeProps } from "reactflow";

import { colors } from "../../../styles/chakra-theme-v2";
import { ResourceIcon } from "../ResourceIcon";
import { GroupNode } from "./components/GroupNode";
import { ResourceNode } from "./components/ResourceNode";
import { GraphLayoutColumn, MarkerName, ResourceGraphNodeType } from "./types";

export const GRAPH_HEIGHT = 400;
export const HIGHLIGHT_COLOR = colors.fdy_purple[500];
export const EDGE_COLOR_SUBDUED = colors.fdy_gray[300];
export const EDGE_COLOR = colors.fdy_gray[600];
export const NODE_BORDER_COLOR = colors.fdy_gray[200];
export const NODE_BORDER_COLOR_CURRENT = colors.fdy_purple[500];
export const NODE_WIDTH = 260;
export const NODE_HEIGHT = 100;
export const NODE_GAP_X = 64;
export const NODE_GAP_Y = 8;
export const GROUP_HEADING_OFFSET = -30;

export const MARKER_START = MarkerName.Dot;
export const MARKER_START_HIGHLIGHT = MarkerName.DotHighlight;
export const MARKER_START_SUBDUED = MarkerName.DotSubdued;

export const MARKER_END: EdgeMarker = {
  type: MarkerType.Arrow,
  width: 20,
  height: 20,
  strokeWidth: 1,
  color: EDGE_COLOR,
};
export const MARKER_END_HIGHLIGHT: EdgeMarker = {
  ...MARKER_END,
  color: HIGHLIGHT_COLOR,
};
export const MARKER_END_SUBDUED: EdgeMarker = {
  ...MARKER_END,
  color: EDGE_COLOR_SUBDUED,
};

/**
 * Defines the column + row order of resources in the graph.
 */
export const GRAPH_LAYOUT: GraphLayoutColumn[] = [
  {
    label: "Connections",
    icon: ResourceIcon.connections,
    types: [ResourceType.Connections],
  },
  {
    label: "Datasets",
    icon: ResourceIcon.datasets,
    types: [ResourceType.Datasets],
  },
  {
    label: "Artifacts",
    icon: Cube,
    types: [ResourceType.Streams, ResourceType.Traits, ResourceType.Places],
  },
  {
    label: "Cohorts",
    icon: ResourceIcon.cohorts,
    types: [ResourceType.Cohorts],
  },
  {
    label: "Predictions",
    icon: Sparkle,
    types: [
      ResourceType.Outcomes,
      ResourceType.Recommenders,
      ResourceType.PersonaSets,
    ],
  },
  {
    label: "Pipelines",
    icon: ResourceIcon.scopes,
    types: [ResourceType.Scopes],
  },
  {
    label: "Deployment",
    icon: ResourceIcon.targets,
    types: [ResourceType.Targets],
  },
];

export const NODE_TYPES: Record<
  ResourceGraphNodeType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ComponentType<NodeProps<any>>
> = {
  resource: ResourceNode,
  group: GroupNode,
};
