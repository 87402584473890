import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { PipelinesEditPage } from "./PipelinesEditPage";
import { PipelinesListPage } from "./PipelinesListPage";
import { PipelinesNewPage } from "./PipelinesNewPage";
import { PipelinesShowPage } from "./PipelinesShowPage";

/**
 * Pipelines console lets users configure their Scopes.
 *
 * Scopes replace our old delivery/monitor/inform system.
 *
 * Scopes can be roughly summarized as:
 * - Who do you want to make predictions for?
 * - What kind of predictions do you want?
 * - How do you want to access the predictions?
 *
 * Scopes spec:
 * https://docs.google.com/document/d/1XmI7Zsk9s5m8b_NWedijtBnN0mF92NTE_bh6okmxKy4/edit#heading=h.8ftqy2ewsjfc
 *
 * More info:
 * https://faraday.ai/developers/reference/getscopes
 */
export function Pipelines() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.PIPELINES]: PipelinesListPage,
    [ROUTE_NAMES.PIPELINES_NEW]: PipelinesNewPage,
    [ROUTE_NAMES.PIPELINES_VIEW]: PipelinesShowPage,
    [ROUTE_NAMES.PIPELINES_EDIT]: PipelinesEditPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.PIPELINES} />
  );

  return <Page />;
}
