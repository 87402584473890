import { gql } from "@apollo/client";

export const PERSONAS_LIST_QUERY = gql`
  query PersonasListQuery {
    personaSets {
      id
      name
      explore
      archivedAt
      status
      statusError
      createdAt
      lastUpdatedOutputAt
      lastUpdatedConfigAt
      lastReadInputAt
      cohort {
        id
        name
      }
      personas {
        id
      }
    }
  }
`;
