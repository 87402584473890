import { Box, Heading, Text } from "@chakra-ui/react";
import { Stream } from "@fdy/faraday-js";
import React from "react";

import { colors } from "../../../styles/chakra-theme-v2";
import { date, number } from "../../../utils/formatters";
import { CardV2 } from "../../ui/Card/CardV2";
import { PendingValue } from "../../ui/PendingValue";

const SummaryItem = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        padding: 4,
        bg: colors.fdy_gray[100],
        borderRadius: "md",
        border: "1px solid",
        borderColor: colors.fdy_gray[200],
      }}
    >
      <Heading as="h3" sx={{ fontSize: "fdy_md", fontWeight: "bold" }}>
        {title}
      </Heading>
      <Text fontSize="fdy_xl" fontWeight="normal">
        {children}
      </Text>
    </Box>
  );
};

export const EventStreamSummaryCard = ({ stream }: { stream: Stream }) => {
  const hasDates = stream.properties?.datetime;
  const tooltipText = "Value will populate on next successful build";

  return (
    <CardV2 title="Summary">
      <Box
        sx={{
          display: "flex",
          gap: 8,
        }}
      >
        <SummaryItem title="First event">
          <PendingValue
            value={stream.oldest_date}
            formatter={date}
            placeholder={
              hasDates ? "Pending..." : "Unavailable for dateless events"
            }
            tooltip={hasDates ? tooltipText : undefined}
          />
        </SummaryItem>
        <SummaryItem title="Event count">
          <PendingValue value={stream.event_count} formatter={number} />
        </SummaryItem>
        <SummaryItem title="Most recent event">
          <PendingValue
            value={stream.newest_date}
            formatter={date}
            placeholder={
              hasDates ? "Pending..." : "Unavailable for dateless events"
            }
            tooltip={hasDates ? tooltipText : undefined}
          />
        </SummaryItem>
      </Box>
    </CardV2>
  );
};
