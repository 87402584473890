import { UnreachableCodeError } from "@fdy/jwt";

import {
  TargetStructureTransformationInput,
  TargetTransformPresetAggregated,
  TargetTransformPresetHashed,
  TargetTransformPresetIdentified,
  TargetTransformPresetReferenced,
} from "../../../../__generated__/sojournerGlobalTypes";
import { ConnectionTypeSlug } from "../../../../vannevar/discriminator_mappings";
import vannevarRules from "../../../../vannevar/target_structure_transformations.json";
import {
  TargetMode,
  TargetRepresentationType,
} from "./TargetRepresentationRadios";

/**
 * The API shape + params needed by the form control
 */
export interface TargetStructureTransformationFormInput
  extends TargetStructureTransformationInput {
  /**
   * Determines if the transform should include or omit the specified column.
   */
  show: boolean;
  /**
   * What the user wants to rename the column to.
   */
  newOutputCol: string;
}

export type TransformPresetType =
  | TargetTransformPresetAggregated
  | TargetTransformPresetHashed
  | TargetTransformPresetIdentified
  | TargetTransformPresetReferenced;

export interface TargetStructureProps {
  representation: TargetRepresentationType;
  /** If true, `structure` is custom. Else it's `default`. */
  hasCustomStructure: boolean;
  structure: TargetStructureTransformationFormInput[];
  defaultStructure: TargetStructureTransformationInput[] | undefined;
  transformPreset: TransformPresetType;
  onChangeCustomStructure: (
    customStructure: TargetStructureTransformationFormInput[]
  ) => void;
  onChangeTransformPreset: (preset: TransformPresetType) => void;
  customizable: boolean;
}

/**
 * Returns the transformation preset (ex. default, linkedin) from a target's representation
 */
export function presetFor(
  representation: TargetRepresentationType
): TransformPresetType | null {
  const representationType = representation.__typename;
  if (representationType === "TargetModesAggregated") {
    return representation.aggregatedPreset;
  } else if (representationType === "TargetModesHashed") {
    return representation.hashedPreset;
  } else if (representationType === "TargetModesIdentified") {
    return representation.identifiedPreset;
  } else if (representationType === "TargetModesReferenced") {
    return representation.referencedPreset;
  } else {
    throw new UnreachableCodeError(representationType);
  }
}

type AggregatedPresets = (keyof typeof TargetTransformPresetAggregated)[];
type HashedPresets = (keyof typeof TargetTransformPresetHashed)[];
type IdentifiedPresets = (keyof typeof TargetTransformPresetIdentified)[];
type ReferencedPresets = (keyof typeof TargetTransformPresetReferenced)[];
export type TargetPresets =
  | AggregatedPresets
  | HashedPresets
  | IdentifiedPresets
  | ReferencedPresets;
type TargetPreset = TargetPresets[number];

/**
 * Map connection type slugs to their respective presets
 */
export const connectionTypePresetMapping: Partial<
  Record<ConnectionTypeSlug, TransformPresetType>
> = {
  linkedin_ads: TargetTransformPresetIdentified.LINKEDIN,
  google_ads: TargetTransformPresetHashed.GOOGLE_ADS,
  facebook_custom_audiences: TargetTransformPresetHashed.FACEBOOK,
  pinterest_ads: TargetTransformPresetHashed.PINTEREST,
  tiktok: TargetTransformPresetHashed.TIKTOK,
  hubspot: TargetTransformPresetIdentified.HUBSPOT,
  iterable: TargetTransformPresetIdentified.ITERABLE,
  klaviyo: TargetTransformPresetIdentified.KLAVIYO,
  poplar: TargetTransformPresetIdentified.POPLAR,
  salesforce: TargetTransformPresetIdentified.SALESFORCE,
  segment: TargetTransformPresetIdentified.SEGMENT,
};

/**
 * Defines the presets available for selection based on the given representation mode.
 */
export const presetsForMode: Record<TargetMode, TargetPresets> = {
  aggregated: Object.keys(TargetTransformPresetAggregated) as AggregatedPresets,
  hashed: Object.keys(TargetTransformPresetHashed) as HashedPresets,
  identified: Object.keys(TargetTransformPresetIdentified) as IdentifiedPresets,
  referenced: Object.keys(TargetTransformPresetReferenced) as ReferencedPresets,
};

export const DEFAULT_PRESET_STRING = "DEFAULT"; // same for all preset types

interface TargetStructureRule {
  mode: TargetMode;
  preset: TargetPreset;
  literate: string;
  rules: {
    scope_col: string;
    output_col?: string;
    // there's other stuff but we don't care at the moment
  }[];
}
export function ruleForPreset(mode: TargetMode, preset: TargetPreset) {
  for (const tst of vannevarRules["data"]) {
    if (
      tst.mode.toLowerCase() === mode.toLowerCase() &&
      tst.slug.toLowerCase() === preset.toLowerCase()
    ) {
      return {
        mode: TargetMode[tst.mode as keyof typeof TargetMode],
        preset: tst.slug.toUpperCase() as TargetPreset,
        literate: tst.literate,
        rules: tst.rules,
      } as TargetStructureRule;
    }
  }
  throw new Error(`No rule for ${mode}: ${preset}`);
}
