import { useEffect } from "react";

export function useDocumentTitle(
  title: string,
  template = "%s - Faraday"
): void {
  const defaultTitle = "Faraday";

  useEffect(() => {
    document.title = title ? template.replace("%s", title) : defaultTitle;

    return () => {
      document.title = defaultTitle;
    };
  }, [title, template]);
}
