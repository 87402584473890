import { ResourceStatus } from "@fdy/faraday-js";
import { ReactElement } from "react";

import { ResourceStatus as GQLResourceStatus } from "../../__generated__/sojournerGlobalTypes";
import { ProgressBar } from "./ProgressBar";

function approxProgress(datasetStatus: ResourceStatus | GQLResourceStatus) {
  switch (datasetStatus) {
    case ResourceStatus.New || GQLResourceStatus.NEW:
      return 25;
    case ResourceStatus.Starting || GQLResourceStatus.STARTING:
      return 50;
    case ResourceStatus.Running || GQLResourceStatus.RUNNING:
      return 75;
    case ResourceStatus.Ready || GQLResourceStatus.READY:
      return 100; // won't see this for long
    default:
      return 0;
  }
}

export function ResourceProgressBar({
  status,
}: {
  status: ResourceStatus | GQLResourceStatus;
}): ReactElement {
  return <ProgressBar value={approxProgress(status)} />;
}
