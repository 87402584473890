import { gql } from "@apollo/client";
import { useRoute } from "react-router5";

import {
  RecencyOccurrence,
  ResourceStatus,
} from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_UPDATED_COHORT,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { components } from "../../sojourner-oas-types";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { ResourceGraphCard } from "../ui/ResourceGraphCard";
import { CohortFragment } from "./__generated__/CohortFragment";
import { CohortForm, CohortsFormState } from "./CohortForm";
import { CohortsSidebarLayout } from "./CohortsSidebarLayout";
import { trimAndFilterEmptyValues } from "./cohortUtils";
import { useCohortQuery } from "./useCohortQuery";
import { useUpdateCohort } from "./useUpdateCohort";

function gqlOccurenceToApiOccurence(
  occ?: RecencyOccurrence
): components["schemas"]["RecencyOccurrence"] | undefined {
  if (occ === RecencyOccurrence.LAST) {
    return "last";
  } else if (occ === RecencyOccurrence.FIRST) {
    return "first";
  }
  return undefined;
}

export type CohortMergePatch = components["schemas"]["CohortMergePatch"];
export function cohortStateToPatchInput({
  name,
  event,
  traits,
  placeConditions,
}: CohortsFormState): CohortMergePatch {
  const input: CohortMergePatch = {
    name,
  };

  input.stream_name = event?.streamName ?? null;

  input.recency = event?.occurrence
    ? {
        occurrence: gqlOccurenceToApiOccurence(event.occurrence),
        min_days: event?.minDays ?? null,
        max_days: event?.maxDays ?? null,
      }
    : null;

  input.min_count = event?.minCount ?? null;
  input.max_count = event?.maxCount ?? null;
  input.min_value = event?.minValue ?? null;
  input.max_value = event?.maxValue ?? null;

  if (traits?.length) {
    input.traits = traits.map((trait) => ({
      name: trait.name,
      optional: trait.optional ?? undefined,
      _matches: trait.matches ?? undefined,
      _eq: trait.eq ? trait.eq : undefined,
      _gt: trait.gt === null ? undefined : trait.gt,
      _gte: trait.gte === null ? undefined : trait.gte,
      _lt: trait.lt === null ? undefined : trait.lt,
      _lte: trait.lte === null ? undefined : trait.lte,
      _nin: trimAndFilterEmptyValues(trait.nin),
      _in: trimAndFilterEmptyValues(trait.in),
      _nnull: trait.nnull ?? undefined,
      _null: trait.null ?? undefined,
    }));
  } else {
    input.traits = null;
  }

  if (event?.streamConditions && event.streamConditions.length) {
    input.stream_conditions = event.streamConditions.map((condition) => ({
      property: condition.property,
      optional: condition.optional ?? undefined,
      _matches: condition.matches ?? undefined,
      _eq: condition.eq ? condition.eq : undefined,
      _gt: condition.gt === null ? undefined : condition.gt,
      _gte: condition.gte === null ? undefined : condition.gte,
      _lt: condition.lt === null ? undefined : condition.lt,
      _lte: condition.lte === null ? undefined : condition.lte,
      _nin: trimAndFilterEmptyValues(condition.nin),
      _in: trimAndFilterEmptyValues(condition.in),
      _nnull: condition.nnull ?? undefined,
      _null: condition.null ?? undefined,
    }));
  } else {
    input.stream_conditions = null;
  }

  if (placeConditions?.length) {
    input.place_conditions = placeConditions.map((pc) => ({
      place_id: pc.placeId,
      distance: pc.distance ?? undefined,
      invert: pc.invert ?? undefined,
    }));
  }

  return input;
}

export function cohortToFormState(cohort: CohortFragment): CohortsFormState {
  return {
    name: cohort.name,
    event: {
      minCount: cohort.minCount,
      maxCount: cohort.maxCount,
      occurrence: cohort.recency?.occurrence,
      minDays: cohort.recency?.minDays ?? null,
      maxDays: cohort.recency?.maxDays ?? null,
      streamName: cohort.streamName,
      streamConditions: cohort.streamConditions.map((c) => ({
        // must store as undefined to prevent unnecessarily sending back null to the API
        eq: c.eq ?? undefined,
        matches: c.matches ?? undefined,
        gt: c.gt ?? undefined,
        gte: c.gte ?? undefined,
        lt: c.lt ?? undefined,
        lte: c.lte ?? undefined,
        nin: c.nin.length ? c.nin : undefined,
        in: c.in.length ? c.in : undefined,
        nnull: c.nnull ?? undefined,
        null: c.null ?? undefined,
        optional: c.optional ?? undefined,
        property: c.property,
      })),
      minValue: cohort.minValue,
      maxValue: cohort.maxValue,
    },
    traits: cohort.traits.map((t) => ({
      // conditions builder prefers `undefined` to determine if it should not show a value in the list.
      // `null` means the value is explicitly set to null.
      name: t.name,
      optional: t.optional ?? undefined,
      eq: t.eq ?? undefined,
      matches: t.matches ?? undefined,
      gt: t.gt ?? undefined,
      gte: t.gte ?? undefined,
      lt: t.lt ?? undefined,
      lte: t.lte ?? undefined,
      nin: t.nin.length ? t.nin : undefined,
      in: t.in.length ? t.in : undefined,
      nnull: t.nnull ?? undefined,
      null: t.null ?? undefined,
    })),
    placeConditions: cohort.placeConditions.map((c) => ({
      // must store as undefined to prevent unnecessarily sending back null to the API
      placeId: c.placeId,
      distance: c.distance ?? undefined,
      invert: c.invert ?? undefined,
    })),
  };
}

/**
 * Renders the cohort show/edit page.
 */
export function CohortsShowPage() {
  const { route } = useRoute();
  const cohortId = route.params.cohort;
  const toast = useToast();
  const { data, loading, error } = useCohortQuery(cohortId);
  const track = useHubSpotEvent();

  const { updateCohort, updating } = useUpdateCohort({
    onCompleted() {
      toast({
        status: "success",
        title: "Cohort saved",
        description:
          "Your cohort has been saved. It may take some time before you see updated totals.",
      });
    },
    update(cache, { data }) {
      // Force the local job status back to RUNNING because it may not be set as such
      // immediately when the updated cohort is returned over the API.
      // This way we can tell the user that the cohort is rebuilding.
      if (data?.updateCohort) {
        cache.writeFragment({
          id: `Cohort:${data.updateCohort.id}`,
          fragment: gql`
            fragment CohortStatus on Cohort {
              status
            }
          `,
          data: {
            ...data.updateCohort,
            status: ResourceStatus.STARTING,
          },
        });
        track(HUBSPOT_USER_UPDATED_COHORT, {
          resource_id: data.updateCohort.id,
          resource_name: data.updateCohort.name,
        });
      }
    },
  });

  function handleSave(state: CohortsFormState) {
    const cohortPatch = cohortStateToPatchInput(state);

    updateCohort(cohortId, cohortPatch);
  }

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  const cohort = data?.cohort;
  if (!cohort) {
    throw new Error(
      "BUG: No cohort found, but sojourner did not throw an error"
    );
  }

  const initialCohort = cohortToFormState(cohort);

  const title = cohort.name;

  return (
    <CohortsSidebarLayout
      cohort={cohort}
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.COHORTS_SHOW,
        params: {
          cohort: cohortId,
        },
      }}
    >
      <CohortForm
        locked={cohort.classic === true}
        initialState={initialCohort}
        onSave={handleSave}
        saving={updating}
        renderAboveSubmitButton={<ResourceGraphCard resourceId={cohortId} />}
      />
    </CohortsSidebarLayout>
  );
}
