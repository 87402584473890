import { Box, SystemStyleObject } from "@chakra-ui/react";
import * as React from "react";

// Avoid styling anything like section, form inputs, etc. Stick to basic text elements.
const styles: SystemStyleObject = {
  mb: 4,
  p: {
    color: "fdy_gray.700",
    _notLast: {
      mb: 4,
    },
  },
  h3: {
    color: "fdy_gray.700",
    fontWeight: "bold",
  },
  ul: {
    color: "fdy_gray.700",
    pl: 4,
    mb: 4,
    // Have to assign to `li` otherwise globals override it on `ol`. TODO:
    // change globals (carefully).
    "& > li": { listStyle: "disc" },
  },
  ol: {
    color: "fdy_gray.700",
    pl: 4,
    mb: 4,
    // Have to assign to `li` otherwise globals override it on `ol`. TODO:
    // change globals (carefully).
    "& > li": { listStyle: "decimal" },
  },
  a: {
    color: "primary",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
};

/**
 * Styles generic child text elements.
 * Think of it like styling content coming from a CMS where you cannot control
 * attributes or classnames of the content within.
 *
 * Avoid passing any child components or non-text-like html tag (p, h1-16, ul, a, etc) tags within this component.
 *
 * Inspired by tailwind typography `prose` class
 * https://tailwindcss.com/docs/typography-plugin
 */
export function Prose({ children }: { children: React.ReactNode }) {
  return <Box sx={styles}>{children}</Box>;
}
