import { ChangeEvent, ChangeEventHandler, useCallback, useState } from "react";
import { useDebounce } from "react-use";

function filterItem(textInput: string, itemString: string): boolean {
  return itemString.toLowerCase().includes(textInput.toLowerCase());
}

interface UseFilterItemsProps<Item> {
  items: Item[];
  itemToString: (item: Item) => string;
}

interface UseFilterItemsHook<Item> {
  items: Item[];
  getInputProps: () => {
    value: string;
    onChange: ChangeEventHandler;
  };
  reset: () => void;
  searchTerm: string;
}

/**
 * Light weight hook for searching an array of items by their string value
 * compared with a controlled text input value. Change event handler on the input is
 * debounced as to not rerender until user is done typing.
 */
export function useFilterItems<Item>({
  items,
  itemToString,
}: UseFilterItemsProps<Item>): UseFilterItemsHook<Item> {
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setTerm] = useState("");

  useDebounce(
    () => {
      setTerm(inputValue.trim());
    },
    400,
    [inputValue]
  );

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [items]
  );

  const reset = () => {
    setInputValue("");
  };

  const getInputProps = () => {
    return {
      value: inputValue,
      onChange: onInputChange,
    };
  };

  const filterItems = items.filter((item) =>
    filterItem(searchTerm, itemToString(item))
  );

  return {
    items: filterItems,
    getInputProps,
    reset,
    searchTerm,
  };
}
