import { Tooltip } from "@chakra-ui/react";

import { date } from "../../utils/formatters";

export const TimeAgo = (props: { date?: string | Date | null }) => {
  if (!props.date) return null;

  const dateTime =
    typeof props.date === "string" ? props.date : props.date.toISOString();
  return (
    <Tooltip label={date.dateAndTime(props.date)}>
      <time
        style={{
          fontSize: 14,
        }}
        dateTime={dateTime}
      >
        {date.relative(props.date)} ago
      </time>
    </Tooltip>
  );
};
