import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { CohortsAnalysisPage } from "./analysis";
import { CohortsListPage } from "./CohortsListPage";
import { CohortsNewPage } from "./CohortsNewPage";
import { CohortsShowPage } from "./CohortsShowPage";

export function Cohorts() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.COHORTS]: CohortsListPage,
    [ROUTE_NAMES.COHORTS_SHOW]: CohortsShowPage, // this is also edit page
    [ROUTE_NAMES.COHORT_ANALYSIS]: CohortsAnalysisPage,
    [ROUTE_NAMES.COHORT_NEW]: CohortsNewPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.COHORTS} showToast />
  );

  return <Page />;
}
