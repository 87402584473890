import { format } from "date-fns";

import { percent } from "../../../utils/formatters";
import { itemsAreNonNull } from "../analysis/utils";
import { PersonaSetFlowPageQuery_personaSetAnalysisFlow } from "./__generated__/PersonaSetFlowPageQuery";

export type PersonaFlow = PersonaSetFlowPageQuery_personaSetAnalysisFlow;

/**
 * Type just the fields we need from the persona type throughout the chart.
 */
export type Persona = {
  id: string;
  name: string | null;
  avatar: string | null;
};

/**
 *
 * This is a common shape for d3 stacked data. It's easier to work with
 * and could let us migrate to vanilla d3 easier later if we want. (visx xy chart is a huge dependency)
 *
 * A single datum for the chart. Each persona will have a property on this
 * object with the count for that persona on that date.
 * @example
 * {
 *   date: new Date("2021-01-01"),
 *   "4369cf14-9e9a-402f-8646-67f24984bea7": 10,
 *   "56fcc289-9565-47ff-97e5-a76614d50251": 20,
 * }
 */
export type ChartDatum = {
  date: Date;
} & {
  [personaId: string]: number;
};

/**
 * Our uniform date format for the chart.
 */
export function formatDate(date: string | Date) {
  return format(date, "MMM d, yyyy");
}

/**
 * Finds the increase in percentage between two numbers and formats it as a
 * percentage i.e. 0.5 -> 50%.
 */
export function formatPercentIncrease(start: number, end: number): string {
  const difference = end - start;
  // can't divide by 0
  if (start === 0) return "0%";
  const percentage = difference / start;
  return percent(percentage);
}

/**
 * Transform API response for persona flow into a format that can be used by d3
 * stacked area chart.
 */
export function transformFlowForStacks(flow: PersonaFlow[]): ChartDatum[] {
  return flow.reduce<ChartDatum[]>((acc, curr) => {
    // Our graphql layer is saying day can be null, but it shouldn't be.
    if (!itemsAreNonNull(curr.days)) {
      throw new Error("Persona flow days cannot contain a null day");
    }

    // For each day in a persona's flow, add it to our accumulator array.
    curr.days.forEach((d) => {
      // If we already have a datum for this date, update it.
      const existing = acc.find(
        (a) => a.date.getTime() === new Date(d.date).getTime()
      );

      if (existing) {
        existing[curr.personaId] = d.count;
      } else {
        // Otherwise, create a new datum.
        acc.push({
          date: new Date(d.date),
          [curr.personaId]: d.count,
        } as ChartDatum);
      }
    });

    return acc;
  }, []);
}

/**
 * Finds all days with 0 counts at the beginning of the flow and removes them.
 *
 * i.e. monday-wednesday have 0 counts, thursday has 10 counts, friday has 0 counts, saturday has 20 counts
 * then it removes monday-wednesday and returns thursday-saturday.
 *
 * Keeping the days with 0 counts after the first non-zero day is important for
 * the stacked area chart to render as expected.
 */
export function removeEmptyDays(flow: ChartDatum[]): ChartDatum[] {
  // find the first day that has a non-zero count for any persona
  const firstNonEmptyDay = flow.findIndex((d) => {
    const { date, ...counts } = d;
    return Object.values(counts).some((v) => v > 0);
  });

  // slice off the empty days at the beginning
  return flow.slice(firstNonEmptyDay);
}
