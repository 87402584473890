import { AuthService, createAuthService } from "../../../services/authService";
import { useRollbar } from "../RollbarProvider";

// don't destructure process because it's replaced at build time
const NODE_ENV = process.env.NODE_ENV;

let lazyAuth: AuthService;

export function useAuthFactory(): AuthService {
  const rollbar = useRollbar();

  if (!lazyAuth) {
    lazyAuth = createAuthService({
      log: NODE_ENV === "production" ? rollbar : console,
    });
  }

  return lazyAuth;
}
