import { gql } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { ModelingField } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { useUnsavedChangesWarning } from "../../hooks/useUnsavedChangesWarning";
import { useSojournerQuery } from "../../services/sojournerApolloClient";
import { AccordionV2 } from "../ui/AccordionV2";
import {
  analyticsAttrs,
  useAnalyticsKey,
} from "../ui/Analytics/AnalyticsStack";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { Blankslate } from "../ui/Blankslate";
import { Button } from "../ui/Button";
import { CardV2 } from "../ui/Card/CardV2";
import { FdyReactSelect, FdySelectOption } from "../ui/FdyReactSelect";
import { FormField } from "../ui/FormField";
import { Input } from "../ui/Input";
import { RouterLink } from "../ui/RouterLink";
import { Select } from "../ui/Select";
import { PersonaSetFormQuery } from "./__generated__/PersonaSetFormQuery";
import { useModelingFields } from "./useModelingFields";

export const PERSONA_SET_FORM_QUERY = gql`
  query PersonaSetFormQuery {
    cohorts {
      name
      id
      archivedAt
    }
  }
`;

export const personaSetFormLabels = {
  cohortId: "Choose a cohort",
  modelingFields: "Clustering traits",
  name: "Persona set name",
};

export interface PersonaSetFormState {
  cohortId: string;
  modelingFields: ModelingField[];
  name: string;
}

// default clustering traits from backend:
// https://github.com/faradayio/fdy/blob/f041184aa6a9167b457223c52c947df59d8250fa/workers/model_train/workers/roster_requested.py#L39

export const DEFAULT_CLUSTERING_TRAITS = [
  ModelingField.FIG_AGE,
  ModelingField.FIG_GENDER,
  ModelingField.FIG_HOUSEHOLD_INCOME,
  ModelingField.FIG_HOUSEHOLD_SIZE,
  ModelingField.FIG_HOUSING_DENSITY,
  ModelingField.FIG_MARITAL_STATUS,
  ModelingField.FIG_SHOPPING_STYLES,
];

/**
 * Renders a form for creating a new persona set.
 *
 * Users can set a cohort to build the persona set from, set custom modeling
 * fields if they want, and finally name the persona set.
 *
 * A specific set of modeling fields are allowed, and stored as a constant.
 * This form queries the GET /traits endpoint so we can match up those field names to trait literates,
 * so users see human friendly names.
 */
export function PersonaSetForm({
  saving,
  onSave,
}: {
  saving: boolean;
  onSave: (personaSet: PersonaSetFormState) => void;
}) {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    formState,
  } = useForm<PersonaSetFormState>({
    defaultValues: {
      name: "",
      cohortId: "",
      modelingFields: DEFAULT_CLUSTERING_TRAITS,
    },
  });

  // user warning message for unsaved changes
  const { setWarnBeforeNavigate } = useUnsavedChangesWarning({
    config: [formState.isDirty],
    route: ROUTE_NAMES.PERSONAS,
  });

  const { data, loading, error } = useSojournerQuery<PersonaSetFormQuery>(
    PERSONA_SET_FORM_QUERY
  );

  const { loading: loadingModelingFields, fields } = useModelingFields();

  const handleModelingFieldsChange = (items: readonly FdySelectOption[]) => {
    const modelingFields = items.map((item) => item.value as ModelingField);
    setValue("modelingFields", modelingFields);
  };

  const resetClusteringTraits = () => {
    setValue("modelingFields", DEFAULT_CLUSTERING_TRAITS);
  };

  const modelingFields = watch("modelingFields");
  const modelingFieldsValue = fields.filter((option) =>
    modelingFields.includes(option.value)
  );

  const cohorts = data?.cohorts ?? [];

  const clusteringTraitsAnalyticsKey = useAnalyticsKey("clustering-traits");

  if (error) throw error;
  if (loading || loadingModelingFields) return <AnimatedZapLogo />;

  if (cohorts.length === 0) {
    return (
      <Blankslate
        title="No Cohorts yet"
        text="You need to create a cohort before you can create a persona set."
        button={{ routeName: ROUTE_NAMES.COHORT_NEW, children: "New cohort" }}
      />
    );
  }

  const sortedCohorts = [...cohorts]
    .filter(
      (cohort) =>
        !cohort.archivedAt || cohort.id === formState.defaultValues?.cohortId
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  function customHandleSubmit(values: PersonaSetFormState) {
    const formValues = values;
    onSave(formValues);
    setWarnBeforeNavigate(false);
  }

  return (
    <form onSubmit={handleSubmit(customHandleSubmit)}>
      <Box display="grid" gap={6}>
        <CardV2
          title="Persona set definition"
          text={
            <>
              Faraday will use AI to uncover the handful of thematic groups
              within the cohort you select, based on the most effective{" "}
              <RouterLink routeName={ROUTE_NAMES.TRAITS}>traits</RouterLink>{" "}
              among individuals within the cohort. You can use the resulting
              persona set to guide personalized outreach, segment & analyze
              customers, and more.
            </>
          }
        >
          <FormField
            label={personaSetFormLabels.cohortId}
            helpText={
              <>
                Which cohort should we organize into personas? You can create
                new cohorts with{" "}
                <RouterLink routeName={ROUTE_NAMES.COHORTS}>cohorts</RouterLink>
                .
              </>
            }
            error={errors.cohortId?.message}
          >
            <Select
              {...register("cohortId", { required: "Cohort is required" })}
              analyticsName="cohort"
            >
              <option value="">Choose cohort...</option>
              {sortedCohorts.map((cohort) => (
                <option key={cohort.id} value={cohort.id}>
                  {cohort.name}
                </option>
              ))}
            </Select>
          </FormField>

          <AccordionV2 allowToggle mt={6}>
            <AccordionV2.Item title="Advanced" analyticsName="advanced">
              <FormField
                label={personaSetFormLabels.modelingFields}
                helpText="By default, Faraday will automatically consider the most effective traits when discovering your personas. Advanced users can override our recommendation and specify their own traits. Note that the system may consider additional traits you do not list in order to improve performance."
                suffix="optional"
              >
                <FdyReactSelect<FdySelectOption, true>
                  isMulti
                  value={modelingFieldsValue}
                  options={fields}
                  onChange={handleModelingFieldsChange}
                  {...analyticsAttrs(clusteringTraitsAnalyticsKey)}
                  // TODO: test in branch deploy bc traits don't show up locally
                />

                <Button
                  variant="tertiary"
                  size="sm"
                  onClick={resetClusteringTraits}
                  mt={2}
                  analyticsName="reset-clustering-traits"
                >
                  Reset to Faraday defaults
                </Button>
              </FormField>
            </AccordionV2.Item>
          </AccordionV2>
        </CardV2>

        <CardV2>
          <FormField
            label={personaSetFormLabels.name}
            helpText={
              <>
                A strong persona set name is descriptive of its intent, and
                might be a summary of the year it was created and the cohort
                used, such as "2024 mortgage shoppers."
              </>
            }
            error={errors.name?.message}
          >
            <Input
              {...register("name", {
                validate(value) {
                  const val = value.trim();
                  if (val === "") return "Name is required";
                  if (val.length > 64)
                    return "Name must be 64 characters or less";
                },
              })}
              analyticsName="name"
            />
          </FormField>
        </CardV2>

        <Button
          type="submit"
          size="lg"
          isDisabled={saving}
          isLoading={saving}
          loadingText="Saving persona set..."
          width="100%"
          analyticsName="save"
        >
          Save persona set
        </Button>
      </Box>
    </form>
  );
}
