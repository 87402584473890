import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
} from "react";

import { Analytics } from "../../../services/analytics/Analytics";

export const AnalyticsContext = createContext<Analytics | null>(null);

interface AnalyticsProviderProps {
  analytics: Analytics;
  children: ReactNode;
}

/**
 * Insert the analytics wrapper into the React tree.
 */
export function AnalyticsProvider({
  analytics,
  children,
}: AnalyticsProviderProps): ReactElement {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}

/**
 * Try to get the analytics wrapper out of context. If it has not been
 * specified, fail. If you need to retrieve the analytics harness and
 * not throw an error if it hasn't been configured, import the context
 * value directly and use the `useContext` hook instead.
 */
export function useAnalytics(): Analytics {
  const analytics = useContext(AnalyticsContext);
  if (!analytics) throw new RangeError(`No analytics utility in react tree`);

  return analytics;
}

type TrackEventCallback = (
  event: string,
  metadata?: {
    category?: string;
    [key: string]: unknown;
  }
) => void;

/**
 * Return a function that lets you track a custom event in the analytics
 * wrapper. If the wrapper has not been specified, this function does
 * nothing.
 */
export function useEventTracker(): TrackEventCallback {
  const analytics = useAnalytics();

  return useCallback<TrackEventCallback>(
    (event, metadata) => {
      if (analytics && analytics.trackEvent) {
        analytics.trackEvent({
          event,
          ...metadata,
        });
      }
    },
    [analytics]
  );
}

/**
 * A mock analytics provider that does nothing.
 * Used for testing.
 */
export function MockAnalyticsProvider({ children }: { children: ReactNode }) {
  const analytics = {
    // noop for tests
    trackEvent: (..._args: unknown[]) => {
      // console.log("trackEvent", _args);
    },
    // We should probably mock the whole class using jest but for fixing
    // the test cases this is impacting, that's not needed right now.
  } as Analytics;

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}
