import { gql } from "@apollo/client";
import { ButtonGroup, Container } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { DocsLink } from "../../ui/DocsLink";
import { LinkButton } from "../../ui/LinkButton";
import { PageHeader } from "../../ui/PageHeader";
import { PageIntroText } from "../../ui/PageIntroText";
import { PageLayout } from "../../ui/PageLayout";
import { UsageWarningNotice } from "../../ui/UsageWarningNotice";
import { cohortFragment } from "../cohortFragment";
import { CohortsListPageQuery_cohorts } from "./__generated__/CohortsListPageQuery";
import { CohortsTable } from "./CohortsTable";

export type Cohort = CohortsListPageQuery_cohorts;

export const cohortsListPageQuery = gql`
  query CohortsListPageQuery {
    cohorts {
      ...CohortFragment
    }
  }
  ${cohortFragment}
`;

export function CohortsListPage() {
  const headerCta = (
    <ButtonGroup>
      <DocsLink resourceType="cohorts" />
      <LinkButton
        routeName={ROUTE_NAMES.COHORT_NEW}
        leftIcon={<Plus weight="bold" />}
        analyticsName="new-cohort"
      >
        New cohort
      </LinkButton>
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="cohorts-list">
      <PageHeader
        title="Cohorts"
        rightContent={headerCta}
        crumbs={[
          {
            label: "Cohorts",
            routeName: ROUTE_NAMES.COHORTS,
          },
        ]}
      />
      <Container size={"fdy_lg"} py={6}>
        <UsageWarningNotice resource="cohorts" />
        <PageIntroText>
          Cohorts are specific groups of people that are important to your
          business, such as customers and leads, and are the building blocks of
          your customer predictions. You can define new cohorts with any
          combination of your data and Faraday's built-in traits.
        </PageIntroText>
        <CohortsTable />
      </Container>
    </PageLayout>
  );
}
