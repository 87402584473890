import { gql, useQuery } from "@apollo/client";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { ReactElement } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { date } from "../../../utils/formatters";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { Blankslate } from "../../ui/Blankslate";
import { CardV2 } from "../../ui/Card/CardV2";
import { SettingsLayout } from "../SettingsLayout";
import { CustomGeographiesQuery } from "./__generated__/CustomGeographiesQuery";

const CUSTOM_GEOGRAPHIES_QUERY = gql`
  query CustomGeographiesQuery {
    custom_geographies {
      id
      area
      name
      creator {
        id
        name
      }
      created_at
      the_geom_geojson
    }
  }
`;

const SQUARE_METERS_TO_SQUARE_MILES = 3.861e-7;

function GeographiesTable() {
  const { data, loading, error } = useQuery<CustomGeographiesQuery>(
    CUSTOM_GEOGRAPHIES_QUERY
  );

  if (loading) return <AnimatedZapLogo />;
  if (error) throw error;
  const geographies = data?.custom_geographies ?? [];
  if (geographies.length === 0) return <Blankslate title="No geographies" />;

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Area</Th>
          <Th>Created</Th>
        </Tr>
      </Thead>
      <Tbody>
        {geographies.map((geo) => {
          const formattedArea = geo.area
            ? Number(geo.area * SQUARE_METERS_TO_SQUARE_MILES).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }
              ) + " mi²"
            : null;

          return (
            <Tr key={geo.id}>
              <Td>{geo.name}</Td>
              <Td>{formattedArea}</Td>
              <Td>{geo.created_at ? date(geo.created_at) : null}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export function GeographiesPage(): ReactElement {
  const title = "Custom geographies";
  return (
    <SettingsLayout
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.SETTINGS_GEOGRAPHIES,
      }}
    >
      <CardV2 title={title}>
        <GeographiesTable />
      </CardV2>
    </SettingsLayout>
  );
}
