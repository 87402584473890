export const possibleTypes = {
  CohortCalculationOrError: ["CohortCalculation", "CalculationError"],
  Error: ["CalculationError"],
  PersonaDimension: [
    "PersonaHistogramDimension",
    "PersonaCategoricalDimension",
    "PersonaBooleanDimension",
    "PersonaHistogramDateDimension",
  ],
  CampaignDefinition: ["CampaignJSONDefinition", "CampaignAudienceDefinition"],
  SourceTableCalculationOrError: ["SourceTableCalculation", "CalculationError"],
  SourceCalculationInterface: ["SourceTableCalculation", "SourceCalculation"],
  SourceCalculationOrError: ["SourceCalculation", "CalculationError"],
};
