import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import { RecencyOccurrence } from "../../../../__generated__/sojournerGlobalTypes";
import { AnalyticsStack } from "../../../ui/Analytics/AnalyticsStack";
import { FormFieldset } from "../../../ui/FormFieldset";
import { Input } from "../../../ui/Input";
import { Select } from "../../../ui/Select";
import { EventState } from "../CohortForm";
import {
  EventNumberChangeHandler,
  EventSelectChangeHandler,
} from "./CohortEventsSetupModal";

export const recencyLabels = {
  occurence: "What event to pay attention to",
  minDays: "Event occurred at least N days ago",
  maxDays: "Event occurred no more than N days ago",
};

const RECENCY_OCCURENCE_OPTIONS = [
  {
    label: "Any",
    value: "",
  },
  {
    label: "First",
    value: RecencyOccurrence.FIRST,
  },
  {
    label: "Last",
    value: RecencyOccurrence.LAST,
  },
];

/**
 * Renders form inputs for editing the recency of a cohort event.
 */
export function CohortEventsRecency({
  event,
  onSelectChange,
  onFieldChange,
}: {
  event: EventState;
  onFieldChange: EventNumberChangeHandler;
  onSelectChange: EventSelectChangeHandler;
}) {
  const { occurrence, minDays, maxDays } = event;

  return (
    <AnalyticsStack value="recency">
      <FormFieldset
        legend="Does it matter when this event occurred?"
        hint="Use this if only certain events should qualify. For example, your recent
        customers would have made their first order 30 days ago. You can leave
        these options blank."
      >
        <FormControl mb={4}>
          <FormLabel>{recencyLabels.occurence}</FormLabel>
          <Select
            value={occurrence}
            onChange={onSelectChange("occurrence")}
            analyticsName="occurrence"
          >
            {RECENCY_OCCURENCE_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label} {event.streamName}
              </option>
            ))}
          </Select>
        </FormControl>
        <Flex gap={4}>
          <FormControl isDisabled={occurrence === undefined}>
            <FormLabel>{recencyLabels.minDays}</FormLabel>
            <InputGroup>
              <Input
                value={minDays ?? ""}
                onChange={onFieldChange("minDays")}
                type="number"
                pattern="[0-9]*"
                min={1}
                analyticsName="min-days"
              />
              <InputRightElement>Min days</InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl isDisabled={occurrence === undefined}>
            <FormLabel>{recencyLabels.maxDays}</FormLabel>
            <InputGroup>
              <Input
                value={maxDays ?? ""}
                onChange={onFieldChange("maxDays")}
                type="number"
                pattern="[0-9]*"
                min={minDays ? minDays + 1 : 1}
                analyticsName="max-days"
              />
              <InputRightElement>Max days</InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>
      </FormFieldset>
    </AnalyticsStack>
  );
}
