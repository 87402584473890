import { Text } from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { FunctionComponent, ReactNode, Suspense } from "react";

import {
  useCohortListQuery,
  useConnectionListQuery,
  useDatasetListQuery,
  useScopeListQuery,
  useStreamListQuery,
  useTargetListQuery,
} from "../../../../hooks/api";
import { formatPendingCount } from "../../../../utils/formatPendingCount";
import { largeNumber } from "../../../../utils/formatters";
import { findConnectionTypeInfoBySlug } from "../../../pipelines/connectionUtils";
import { Skeleton } from "../../Skeleton";
import { ResourceNodeData } from "../types";

type TeaserProps = { id: string };

const TeaserBites = ({ children }: { children: ReactNode }) => (
  <Text
    as="p"
    sx={{
      fontSize: "fdy_sm",
      color: "fdy_gray.700",
      display: "flex",
      gap: 2,
    }}
  >
    {children}
  </Text>
);

const ConnectionTeaser = (props: TeaserProps) => {
  const { data } = useConnectionListQuery();
  const connection = data?.find((x) => x.id === props.id);
  if (!connection) return null;
  const info = findConnectionTypeInfoBySlug(connection.options.type);
  return (
    <TeaserBites>
      <span>{info.literate}</span>
      {connection.contents ? (
        <span>{connection.contents.length} tables</span>
      ) : null}
    </TeaserBites>
  );
};

const DatasetTeaser = (props: TeaserProps) => {
  const { data } = useDatasetListQuery();
  const dataset = data?.find((x) => x.id === props.id);
  if (!dataset) return null;

  // children of merge datasets don't get counts so return something generic instead
  if (dataset.merge_datasets?.length) {
    return <TeaserBites>Merge dataset</TeaserBites>;
  }

  if (
    dataset.row_count === undefined ||
    dataset.identified_count === undefined
  ) {
    return <TeaserBites>Calculating...</TeaserBites>;
  }

  return (
    <TeaserBites>
      <span>{largeNumber(dataset.row_count)} rows</span>
      <span>{largeNumber(dataset.identified_count)} individuals</span>
    </TeaserBites>
  );
};

const CohortTeaser = (props: TeaserProps) => {
  const { data } = useCohortListQuery();
  const cohort = data?.find((x) => x.id === props.id);
  if (!cohort) return null;
  return (
    <TeaserBites>
      {formatPendingCount(cohort.population_count, {
        formatter: largeNumber,
        suffix: "individuals",
      })}
    </TeaserBites>
  );
};

const PersonaSetTeaser = (_props: TeaserProps) => {
  return <TeaserBites>Persona set</TeaserBites>;
};

const OutcomeTeaser = (_props: TeaserProps) => {
  return <TeaserBites>Outcome</TeaserBites>;
};

const RecommenderTeaser = (_props: TeaserProps) => {
  return <TeaserBites>Recommender</TeaserBites>;
};

const ScopeTeaser = (props: TeaserProps) => {
  const { data } = useScopeListQuery();
  const scope = data?.find((x) => x.id === props.id);
  if (!scope) return null;
  return (
    <TeaserBites>
      {formatPendingCount(scope?.population_count, {
        formatter: largeNumber,
        suffix: "individuals",
      })}
    </TeaserBites>
  );
};

const TargetTeaser = (props: TeaserProps) => {
  const { data } = useTargetListQuery();
  const target = data?.find((x) => x.id === props.id);
  if (!target) return null;
  const connInfo = findConnectionTypeInfoBySlug(target.options.type);
  return <TeaserBites>{connInfo.literate}</TeaserBites>;
};

const StreamTeaser = (props: TeaserProps) => {
  const { data } = useStreamListQuery();
  const stream = data?.find((x) => x.id === props.id);
  if (!stream) return null;

  return (
    <TeaserBites>
      {formatPendingCount(stream.event_count, {
        formatter: largeNumber,
        suffix: "events",
      })}
    </TeaserBites>
  );
};

const TraitTeaser = (_props: TeaserProps) => {
  return <TeaserBites>Trait</TeaserBites>;
};

const PlaceTeaser = (_props: TeaserProps) => {
  return <TeaserBites>Place</TeaserBites>;
};

type TeaserMap = Record<ResourceType, FunctionComponent<TeaserProps> | null>;
const teaserComponentMap: TeaserMap = {
  cohorts: CohortTeaser,
  scopes: ScopeTeaser,
  persona_sets: PersonaSetTeaser,
  connections: ConnectionTeaser,
  datasets: DatasetTeaser,
  outcomes: OutcomeTeaser,
  recommenders: RecommenderTeaser,
  targets: TargetTeaser,
  streams: StreamTeaser,
  traits: TraitTeaser,
  places: PlaceTeaser,
  accounts: null,
};

type ResourceNodeTeaserProps = Pick<ResourceNodeData, "id" | "type">;

/**
 * Renders a teaser for a resource node based on the resource type.
 * Each teaser uses the list query for the resource type to fetch the resource
 * data since single resource queries would be too many when there are many
 * nodes. We can rely on react-query deduping the queries so that we don't
 * fetch the same data multiple times.
 */
export function ResourceNodeTeaser({ id, type }: ResourceNodeTeaserProps) {
  const Teaser = teaserComponentMap[type];
  if (!Teaser) return null;

  return (
    <Suspense fallback={<Skeleton height={4} width={40} />}>
      <Teaser id={id} />
    </Suspense>
  );
}
