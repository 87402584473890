import { Flex, InputProps, useClipboard } from "@chakra-ui/react";
import { ReactElement } from "react";

import { Button } from "./Button";
import { Input } from "./Input";

interface CopyInputProps {
  /** The text to copy */
  value: string;

  /** Label of the copy button */
  buttonLabel?: string;

  /** Label of the copy button when value has been copied */
  buttonLabelCopied?: string;

  /** disable the unput and copy button */
  disabled?: boolean;

  renderInput?: (props: InputProps) => ReactElement;
}

/**
 * Renders an input with a value and a button to copy the value to the clipboard.
 * - After copying, the button swaps labels to the copied state label then back after 1s.
 */
export function CopyInputV2({
  value,
  buttonLabel = "Copy",
  buttonLabelCopied = "Copied!",
  disabled,
  renderInput,
}: CopyInputProps): ReactElement {
  const { hasCopied, onCopy } = useClipboard(value);

  const inputProps = {
    value,
    isReadOnly: true,
    disabled,
  };

  return (
    <Flex
      sx={{
        input: {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          height: 12,
          opacity: disabled ? 0.5 : 1,
        },
        "& > button": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          height: 12,
        },
      }}
    >
      {renderInput ? (
        renderInput(inputProps)
      ) : (
        <Input {...inputProps} analyticsName="copy-input" />
      )}
      <Button
        onClick={onCopy}
        disabled={disabled}
        variant="tertiary"
        analyticsName="copy-input"
      >
        {hasCopied ? buttonLabelCopied : buttonLabel}
      </Button>
    </Flex>
  );
}
