import { gql, MutationHookOptions } from "@apollo/client";

import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { components } from "../../sojourner-oas-types";
import {
  UpdateScopeMutationHook,
  UpdateScopeMutationHookVariables,
} from "./__generated__/UpdateScopeMutationHook";
import { pipelineFragment } from "./pipelineFragment";

export type ScopeMergePatch = components["schemas"]["ScopeMergePatch"];

export const updateScopeMutation = gql`
  mutation UpdateScopeMutationHook($id: ID!, $scope: String!) {
    updateScope(applicationJsonMergePatchInput: $scope, scopeId: $id) {
      ...PipelineFragment
    }
  }
  ${pipelineFragment}
`;

export function useUpdateScope(
  options?: MutationHookOptions<
    UpdateScopeMutationHook,
    UpdateScopeMutationHookVariables
  >
) {
  const [mutate, { loading: updating }] = useSojournerMutation<
    UpdateScopeMutationHook,
    UpdateScopeMutationHookVariables
  >(updateScopeMutation, options);

  function updateScope(
    id: string,
    input: ScopeMergePatch,
    options?: MutationHookOptions<
      UpdateScopeMutationHook,
      UpdateScopeMutationHookVariables
    >
  ) {
    return mutate({
      variables: {
        id,
        scope: JSON.stringify(input),
      },
      ...options,
    });
  }

  return {
    updateScope,
    updating,
  };
}
