import { GridChart } from "./GridChart";
import {
  createOutcomeGradeGrid,
  findGradeCellInGrid,
  getOutcomeGradesGrid,
} from "./outcomeGradeUtils";

interface OutcomeLiftRocGridChartProps {
  liftValue: number;
  rocAuc: number;
  usesFPD: boolean;
}

const ROC_LABEL = (
  <>
    Better <strong>ability to predict</strong> this outcome &rarr;
  </>
);
const LIFT_LABEL = (
  <>
    More <strong>business value</strong> due to predictive lift &rarr;
  </>
);

/**
 * Renders a grid chart with a marker at the intersection of the lift and ROC.
 * X axis is lift, Y axis is ROC.
 * Each cell is filled with a color corresponding to the grade of the cell.
 */
export function OutcomeLiftRocGridChart({
  liftValue,
  rocAuc,
  usesFPD,
}: OutcomeLiftRocGridChartProps) {
  const grades = getOutcomeGradesGrid(usesFPD);
  const grid = createOutcomeGradeGrid(grades);
  const grade = findGradeCellInGrid({
    grid,
    roc: rocAuc,
    lift: liftValue,
  });

  return (
    <GridChart
      datasets={grid}
      xLabel={LIFT_LABEL}
      yLabel={ROC_LABEL}
      marker={
        grade
          ? {
              x: grade.x,
              y: grade.y,
            }
          : undefined
      }
    />
  );
}
