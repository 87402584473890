import { assertArrayAndUnwrap } from "../../../../../utils/assertions";
import { isEmpty } from "./targetFilterUtils";
import { TargetFilterPostInput, TargetFilterRow } from "./types";

type ConditionMap = Partial<{
  gt: number;
  lt: number;
  gte: number;
  lte: number;
  eq: string | number | boolean;
  neq: string | number;
  in: string[];
  nin: string[];
  null: boolean;
  nnull: boolean;
  matches: string;
}>;

function conditionRowsToMap(
  conditions: TargetFilterRow["conditions"]
): ConditionMap {
  return conditions.reduce<ConditionMap>((acc, condition) => {
    if (!condition.operator) return acc;
    return { ...acc, [condition.operator]: condition.value };
  }, {});
}

function buildNumberCondition(conds: ConditionMap) {
  return {
    eq: conds.eq ? Number(conds.eq) : null,
    gt: conds.gt ?? null,
    gte: conds.gte ?? null,
    lt: conds.lt ?? null,
    lte: conds.lte ?? null,
    nnull: conds.nnull ?? null,
    null: conds.null ?? null,
  };
}

/**
 * Returns a TargetFilterPostInput object that can be sent to the API.
 * The default return value is suitable for PATCH requests, but not POST.
 *
 * Use targetFilterToPostInput to get a POST request filter.
 */
export function targetFilterToWire(
  rows: TargetFilterRow[]
): TargetFilterPostInput | undefined {
  if (rows.length === 0) return undefined;

  const result: TargetFilterPostInput = {};

  for (const row of rows) {
    // remove empty conditions
    const conditions = row.conditions.filter(
      (cond) => cond.operator && !isEmpty(cond.value)
    );

    // skip rows without any conditions
    if (conditions.length === 0) {
      continue;
    }

    // turn the conditions array into an object of operator -> value
    const conds = conditionRowsToMap(conditions);

    switch (row.type) {
      case "cohortMembership":
        {
          // cohort membership should only have one condition
          // i.e. true or false for that cohort membership.
          // There's no reason to have more operators for cohort membership.
          if (conditions.length !== 1) {
            throw new Error("cohort row should only have one condition");
          }

          const eq = conditions[0].value === "true";
          result.cohortMembership ??= [];
          result.cohortMembership.push({
            cohortId: row.payload_elem_id,
            eq,
          });
        }
        break;

      case "persona":
        {
          // Each persona row should only have one condition,
          // via how the UI composes the filters.
          // Users should add a new persona set filter for each individual
          // persona they want to filter by.
          if (conditions.length !== 1) {
            throw new Error("persona row should only have one condition");
          }
          const cond = conditions[0];
          const eq = cond.operator === "eq";
          const personaId = cond.value;

          // mostly for type safety
          if (typeof personaId !== "string")
            throw new Error("personaId must be an ID (string)");

          result.persona ??= [];
          result.persona.push({
            personaId,
            personaSetId: row.payload_elem_id,
            eq,
          });
        }
        break;

      case "outcomeScore": {
        result.outcomeScore ??= [];
        result.outcomeScore.push({
          outcomeId: row.payload_elem_id,
          ...buildNumberCondition(conds),
        });
        break;
      }

      case "outcomeProbability": {
        result.outcomeProbability ??= [];
        result.outcomeProbability.push({
          outcomeId: row.payload_elem_id,
          ...buildNumberCondition(conds),
        });
        break;
      }

      case "outcomePercentile": {
        result.outcomePercentile ??= [];
        result.outcomePercentile.push({
          outcomeId: row.payload_elem_id,
          ...buildNumberCondition(conds),
        });
        break;
      }

      case "trait": {
        result.trait ??= [];
        result.trait.push({
          ...buildNumberCondition(conds),
          eq: conds.eq ? String(conds.eq) : null,
          neq: conds.neq ? String(conds.neq) : null,
          matches: conds.matches ?? null,
          in: conds.in ? assertArrayAndUnwrap(conds.in) : [],
          nin: conds.nin ? assertArrayAndUnwrap(conds.nin) : [],
          name: row.payload_elem_id,
        });
        break;
      }

      case "recommenderRank": {
        result.recommenderRank ??= [];
        result.recommenderRank.push({
          recommenderId: row.payload_elem_id,
          ...buildNumberCondition(conds),
        });
        break;
      }

      case "recommenderUncalibratedProbability": {
        result.recommenderUncalibratedProbability ??= [];
        result.recommenderUncalibratedProbability.push({
          recommenderId: row.payload_elem_id,
          ...buildNumberCondition(conds),
        });
        break;
      }

      default:
        throw new Error(`Unknown filter type: ${row.type}`);
    }
  }

  return result;
}
