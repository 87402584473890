export const datasetTooltips = {
  count:
    "Each row in your data can describe a person, and multiple rows can describe the same person. We provide both counts so you can see how your data is being interpreted.",
  output:
    "Your datasets produce artifacts that can be used to configure the Faraday platfom. Events are instances of a type of experience that people can have more than once, such as transactions. Traits are properties of the person themselves, such as their acquisition channel.",
  enrichment:
    "Some of the people in your dataset may be recognized by other trait providers on your account. This allows your predictions to make use of associated data for better performance.",
  matchRate:
    "This is the number of matched individuals divided by the number of individuals with identifying information.",
  latestSync:
    "This specifies when Faraday last saw a change in your dataset. Typically, Faraday checks once a day for updates. If your dataset updated, but the data has not synced recently, please contact support.",
};
