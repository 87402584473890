import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { ReactNode } from "react";

/**
 * Render a 2 column grid of content, useful for laying out details of a resource.
 *
 * @example
 * ```tsx
 * <ResourceSummaryGrid>
 *   <ResourceSummaryGridCell title="Name">
 *     ...
 *   </ResourceSummaryGridCell>
 *   <ResourceSummaryGridCell title="Description">
 *     ...
 *   </ResourceSummaryGridCell>
 * </ResourceSummaryGrid>
 * ```
 */
export function ResourceSummaryGrid({ children }: { children: ReactNode }) {
  return (
    <SimpleGrid columns={2} gap={8}>
      {children}
    </SimpleGrid>
  );
}

/**
 * Render a cell meant to go within a ResourceSummaryGrid.
 */
export function ResourceSummaryGridCell({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <Box>
      <Heading as="h3" sx={{ fontSize: "fdy_md", mb: 1 }}>
        {title}
      </Heading>
      {children}
    </Box>
  );
}
