import { Global } from "@emotion/react";

import AktivGroteskVariable from "../../fonts/AktivGrotesk-Variable.woff2";

const webfonts = `
  @font-face {
    font-family: "Aktiv Grotesk";
    font-weight: 100 900;
    src: url(${AktivGroteskVariable}) format("woff2-variations");
  }
`;

export const GlobalStyles = () => <Global styles={webfonts} />;
