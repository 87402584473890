import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { ReactElement, ReactNode, useState } from "react";

import { theme } from "../../constants/theme";
import chakraThemeV2, { colors, fontSizes } from "../../styles/chakra-theme-v2";
import { useRollbar } from "../ui/RollbarProvider";
import { AccountSwitcherQuery } from "./__generated__/AccountSwitcherQuery";
import {
  SwitchAccountMutation,
  SwitchAccountMutationVariables,
} from "./__generated__/SwitchAccountMutation";
import { AccountSwitcherSelect } from "./AccountSwitcherSelect";

export const ACCOUNT_SWITCHER_FRAGMENT = gql`
  fragment AccountSwitcherFragment on Query {
    account {
      id
      name
      test_mode
      test_account {
        id
        test_mode
      }
    }
    user {
      id
      accounts {
        id
        name
        test_mode
        test_account {
          id
        }
      }
    }
  }
`;
const SWITCH_ACCOUNT_MUTATION = gql`
  mutation SwitchAccountMutation($id: UUID!) {
    switch_account(account_id: $id) {
      id
    }
  }
`;

export const ACCOUNT_SWITCHER_QUERY = gql`
  query AccountSwitcherQuery {
    ...AccountSwitcherFragment
  }
  ${ACCOUNT_SWITCHER_FRAGMENT}
`;

const StyledAccountName = styled.span`
  padding-inline: ${theme.space(1)};
  padding-block: ${theme.space(2)};
  color: ${colors.fdy_gray[100]};
`;

const StyledAccountSwitcherButton = styled(StyledAccountName)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.space(1)};
`;

export function AccountSwitcher({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const rollbar = useRollbar();
  const [isOpen, setIsOpen] = useState(false);
  const { data, error } = useQuery<AccountSwitcherQuery>(
    ACCOUNT_SWITCHER_QUERY
  );

  const [switchAccount] = useMutation<
    SwitchAccountMutation,
    SwitchAccountMutationVariables
  >(SWITCH_ACCOUNT_MUTATION);

  async function handleAccountSwitch(id: string) {
    await switchAccount({ variables: { id } });
    // refresh the page to force apollo/react-query client stores to reset
    window.location.reload();
  }

  if (error) rollbar.error(error);

  // Don't render the button for the switcher if:
  //  1. The request errors out for some reason.
  //  2. The data hasn't loaded yet
  //  3. The user has no accounts they may switch to (i.e. 1 or fewer) AND does not have a test mode account
  if (
    error ||
    !data ||
    (data.user.accounts.length < 2 &&
      !data.account.test_mode &&
      !data.account.test_account?.id)
  ) {
    return <StyledAccountName>{children}</StyledAccountName>;
  }

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      placement="bottom-end"
    >
      <PopoverTrigger>
        <StyledAccountSwitcherButton as="button" aria-label="Switch account">
          <span>{children}</span>
          {isOpen ? <CaretUp weight="fill" /> : <CaretDown weight="fill" />}
        </StyledAccountSwitcherButton>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          aria-label="Your accounts"
          sx={{
            border: "1px solid",
            borderColor: "fdy_gray.700",
          }}
        >
          <PopoverHeader
            aria-label="Switch account/mode"
            sx={{
              backgroundColor: "fdy_gray.800",
              color: colors.fdy_gray[200],
              fontSize: fontSizes.fdy_sm,
              textOverflow: "ellipsis",
              fontWeight: 700,
              fontFamily: chakraThemeV2.fonts.body,
              border: "none",
              borderRadius: "4px 4px 0 0", //just need the top corners rounded so they blend in
            }}
          >
            Switch account/mode
          </PopoverHeader>
          <AccountSwitcherSelect
            account={data.account}
            accounts={data.user.accounts}
            handleAccountSwitch={handleAccountSwitch}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
