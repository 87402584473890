import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { ReactNode } from "react";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";

export interface AccordionV2ItemProps {
  title: ReactNode;
  subtitle?: ReactNode;
  subtitleDivider?: string;
  afterTitle?: ReactNode;
  tooltip?: string;
  children: ReactNode;
  disabled?: boolean;
  analyticsName?: string;
}

export interface AccordionV2Props extends AccordionProps {
  children?: ReactNode;
}

/**
 * Renders a chakra accordion with some faraday specific styling and structure
 */
export function AccordionV2({ children, ...rest }: AccordionV2Props) {
  return (
    <Accordion allowToggle={rest.allowMultiple ? undefined : true} {...rest}>
      {children}
    </Accordion>
  );
}

function AccordionV2Item({
  title,
  subtitle,
  children,
  afterTitle,
  disabled,
  tooltip,
  analyticsName,
  subtitleDivider = " — ",
}: AccordionV2ItemProps) {
  const analyticsKey = useAnalyticsKey(analyticsName);
  return (
    <AccordionItem isDisabled={disabled}>
      <Tooltip label={tooltip}>
        <AccordionButton
          sx={{
            display: "grid",
            gridTemplateColumns: afterTitle ? "auto 1fr 1fr" : "auto 1fr",
            gap: 1,
          }}
          {...analyticsAttrs(analyticsKey)}
        >
          <AccordionIcon />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              minWidth: 0, // ensures any truncation within title works correctly
            }}
          >
            <strong
              style={{ flexBasis: "auto", minWidth: 0, textAlign: "left" }}
            >
              {title}
            </strong>
            {subtitle && subtitleDivider}
            {subtitle}
          </Box>
          {afterTitle && <Box textAlign="right">{afterTitle}</Box>}
        </AccordionButton>
      </Tooltip>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItem>
  );
}

AccordionV2.Item = AccordionV2Item;
