import { Box } from "@chakra-ui/react";

type ChartLegendItem = {
  shape?: "line" | "square";
  dashed?: boolean;
  label: string;
  color: string;
};

export interface ChartLegendProps {
  items: ChartLegendItem[];
}

function LegendShape({ shape = "square", color, dashed }: ChartLegendItem) {
  switch (shape) {
    case "square":
      return (
        <svg width="1rem" height="1rem">
          <rect width="1rem" height="1rem" fill={color} />
        </svg>
      );

    case "line":
      return (
        // using svg so dash array can be same as chart lines. You can't control dashes of a plain css border.
        <svg width="2rem" height="1rem">
          <line
            x1="0"
            y1="50%"
            x2="100%"
            y2="50%"
            stroke={color}
            strokeDasharray={dashed ? "4,4" : undefined}
            strokeWidth="2"
          />
        </svg>
      );

    default:
      throw new Error("Unknown legend shape");
  }
}

export function ChartLegend({ items }: ChartLegendProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 4,
        marginBlock: 4,
      }}
    >
      {items.map((item) => (
        <Box
          key={item.label}
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            fontSize: "fdy_sm",
          }}
        >
          <LegendShape {...item} />

          {item.label}
        </Box>
      ))}
    </Box>
  );
}
