import { colors } from "../../../../styles/chakra-theme-v2";

type DataType = "1pd" | "3pd";

type GradeType =
  | "misconfigured"
  | "moderate"
  | "good"
  | "great"
  | "strong"
  | "excellent"
  | "remarkable";

type GradeMeta = {
  label: string;
  colors: {
    bg: string;
    fg: string;
  };
  description: string;
};

export interface Grade {
  /** Meta data used to present the grade to the user */
  meta: GradeMeta;

  /** The range a ROC score is in to result in this grade */
  rocRange: [number | null, number | null];

  /** Some ranges (like ROC) check the max is <=, not just <  */
  rocMaxInclusive?: boolean;
  liftMaxInclusive?: boolean;

  /** The range a lift value is in to result in this grade */
  liftRange?: [number | null, number | null];
}

const gradeMetaData: Record<GradeType, GradeMeta> = {
  misconfigured: {
    label: "Misconfigured",
    colors: {
      bg: colors.fdy_red[500],
      fg: colors.white,
    },
    description:
      "The signal appears inverted. You may want to contact support for advice.",
  },
  moderate: {
    label: "Moderate",
    colors: {
      bg: colors.fdy_green[380],
      fg: colors.fdy_green[900],
    },
    description:
      "While this outcome is hard to predict, you can expect some lift.",
  },
  good: {
    label: "Good",
    colors: {
      bg: colors.fdy_green[460],
      fg: colors.fdy_green[900],
    },
    description: "Lift should be apparent.",
  },
  great: {
    label: "Great",
    colors: {
      bg: colors.fdy_green[533],
      fg: colors.fdy_green[900],
    },
    description: "Expect consistent lift.",
  },
  strong: {
    label: "Strong",
    colors: {
      bg: colors.fdy_green[600],
      fg: colors.white,
    },
    description: "You should enjoy reliable, clearly noticeable lift.",
  },
  excellent: {
    label: "Excellent",
    colors: {
      bg: colors.fdy_green[750],
      fg: colors.white,
    },
    description: "Expect consistent, unusually strong lift.",
  },
  remarkable: {
    label: "Remarkable",
    colors: {
      bg: colors.fdy_green[900],
      fg: colors.white,
    },
    description: "One of the strongest outcomes on the Faraday platform.",
  },
};

export const outcomeGradesGrid: Record<DataType, Grade[][]> = {
  "1pd": [
    [
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [null, 0],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.moderate,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [0, 0.33],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.good,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [0.33, 1.15],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.great,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [1.15, 1.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.excellent,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [1.5, 2.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.excellent,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [2.5, null],
      },
    ],
    [
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [null, 0],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.moderate,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [0, 0.33],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.good,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [0.33, 1.15],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.great,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [1.15, 1.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.strong,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [1.5, 2.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.excellent,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [2.5, null],
      },
    ],
    [
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [null, 0],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [0, 0.33],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [0.33, 1.15],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [1.15, 1.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [1.5, 2.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [2.5, null],
      },
    ],
  ],
  "3pd": [
    [
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [null, 0],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.moderate,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [0, 0.43],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.good,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [0.43, 1.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.great,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [1.5, 3.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.excellent,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [3.5, 5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.excellent,
        rocRange: [0.6, 1],
        rocMaxInclusive: true,
        liftRange: [5, null],
      },
    ],
    [
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [null, 0],
      },
      {
        meta: gradeMetaData.moderate,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [0, 0.43],
      },
      {
        meta: gradeMetaData.good,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [0.43, 1.5],
      },
      {
        meta: gradeMetaData.great,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [1.5, 3.5],
      },
      {
        meta: gradeMetaData.strong,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [3.5, 5],
      },
      {
        meta: gradeMetaData.excellent,
        rocRange: [0.5, 0.6],
        rocMaxInclusive: true,
        liftRange: [5, null],
      },
    ],
    [
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [null, 0],
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [0, 0.43],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [0.43, 1.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [1.5, 3.5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [3.5, 5],
        liftMaxInclusive: true,
      },
      {
        meta: gradeMetaData.misconfigured,
        rocRange: [0, 0.5],
        rocMaxInclusive: true,
        liftRange: [5, null],
      },
    ],
  ],
};

// When outcome doesn't have lift values, these are used to show a ROC based bar chart.
export const outcomeROCGrades: Record<DataType, Grade[]> = {
  "1pd": [
    {
      meta: gradeMetaData.misconfigured,
      rocRange: [0, 0.5],
    },
    {
      meta: gradeMetaData.moderate,
      rocRange: [0.5, 0.6],
    },
    {
      meta: gradeMetaData.good,
      rocRange: [0.6, 0.63],
    },
    {
      meta: gradeMetaData.great,
      rocRange: [0.63, 0.68],
    },
    {
      meta: gradeMetaData.strong,
      rocRange: [0.68, 0.74],
    },
    {
      meta: gradeMetaData.excellent,
      rocRange: [0.74, 1.0],
      rocMaxInclusive: true,
    },
  ],
  "3pd": [
    {
      meta: gradeMetaData.misconfigured,
      rocRange: [0, 0.5],
    },
    {
      meta: gradeMetaData.moderate,
      rocRange: [0.5, 0.6],
    },
    {
      meta: gradeMetaData.good,
      rocRange: [0.6, 0.64],
    },
    {
      meta: gradeMetaData.great,
      rocRange: [0.64, 0.71],
    },
    {
      meta: gradeMetaData.strong,
      rocRange: [0.71, 0.75],
    },
    {
      meta: gradeMetaData.excellent,
      rocRange: [0.75, 1.0],
      rocMaxInclusive: true,
    },
  ],
};

export const outcomeGradeWarnings: Record<DataType, Grade> = {
  "1pd": {
    meta: gradeMetaData.remarkable,
    rocRange: [0.85, 1],
    liftRange: [8.0, null],
    rocMaxInclusive: true,
  },
  "3pd": {
    meta: gradeMetaData.remarkable,
    rocRange: [1, null],
    liftRange: [12.5, null],
  },
};
