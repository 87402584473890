import { useApolloClient } from "@apollo/client";
import { ReactElement, ReactNode } from "react";
import { RouterProvider } from "react-router5";
import { Options, Route, Router } from "router5";

import { ApolloGQLService } from "../../../services/ApolloGQLService";
import { useAnalytics } from "../Analytics/AnalyticsProvider";
import { useAuth } from "../AuthProvider/AuthProvider";
import { useRouter5Router } from "./useRouter5Router";

interface Router5ProviderProps {
  children: ReactNode;
  config: Partial<Options>;
  routes: Route[];
}

export function Router5Provider({
  children,
  config,
  routes,
}: Router5ProviderProps): ReactElement | null {
  const auth = useAuth();
  const gqlClient = useApolloClient();
  const analytics = useAnalytics();
  const router = useRouter5Router(routes, config, {
    auth,
    gqlClient: gqlClient as ApolloGQLService,
    analytics,
  });

  if (!router) return null; // dunno if this is the right call

  return (
    <RouterProvider router={router as unknown as Router}>
      {children}
    </RouterProvider>
  );
}
