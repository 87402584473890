import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import { AnalyticsStack } from "../../../ui/Analytics/AnalyticsStack";
import { FormFieldset } from "../../../ui/FormFieldset";
import { Input } from "../../../ui/Input";
import { EventState } from "../CohortForm";
import { EventNumberChangeHandler } from "./CohortEventsSetupModal";

export const frequencyLabels = {
  minCount: "Event occurred at least N times",
  maxCount: "Event occurred no more than N times",
};

/**
 * Renders form inputs for editing the frequency of a cohort event.
 */
export function CohortEventsFrequency({
  event,
  onFieldChange,
}: {
  event: EventState;
  onFieldChange: EventNumberChangeHandler;
}) {
  const { streamName, minCount, maxCount } = event;

  return (
    <AnalyticsStack value="frequency">
      <FormFieldset
        legend="Does the frequency of events matter?"
        hint="Set conditions for how often a qualifying event has occured. 
      For example, your recent customers who have between 3 and 6 orders. 
      Note that counts are determined before event property conditions are evaluated. 
      If you wish to restrict this cohort to people who match a count condition 
      and an event property condition at the same time, 
      you must apply the property condition at the event stream level."
      >
        <Flex gap={4}>
          <FormControl>
            <FormLabel>{frequencyLabels.minCount}</FormLabel>
            <InputGroup>
              <Input
                value={minCount ?? ""}
                onChange={onFieldChange("minCount")}
                type="number"
                pattern="[0-9]*"
                min={1}
                analyticsName="min-count"
              />
              <InputRightElement>Min {streamName}</InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>{frequencyLabels.maxCount}</FormLabel>
            <InputGroup>
              <Input
                value={maxCount ?? ""}
                onChange={onFieldChange("maxCount")}
                type="number"
                pattern="[0-9]*"
                min={minCount ? minCount + 1 : 1}
                analyticsName="max-count"
              />
              <InputRightElement>Max {streamName}</InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>
      </FormFieldset>
    </AnalyticsStack>
  );
}
