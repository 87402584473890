import { CalloutLink } from "../../../ui/CalloutLink";
import { getConnectionTypeInfoFromTargetOptions } from "../../connectionUtils";
import { TargetFragment } from "../__generated__/TargetFragment";

export function ConnectionSetupLink({ target }: { target: TargetFragment }) {
  const connectionTypeInfo = getConnectionTypeInfoFromTargetOptions(
    target.options
  );

  return (
    <CalloutLink
      type="external"
      href={`https://faraday.ai/docs/connections/${connectionTypeInfo.slug}`}
      label="Setup instructions"
    />
  );
}
