import { Container, Flex } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { DocsLink } from "../ui/DocsLink";
import { Breadcrumb, PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { SidebarLayout } from "../ui/SidebarLayout/SidebarLayout";
import { useSidebarLayoutState } from "../ui/SidebarLayout/useSidebarLayoutState";
import { PipelineFragment } from "./__generated__/PipelineFragment";
import { PipelineInfoSidebar } from "./PipelineInfoSidebar";
import { PipelinesActionsMenu } from "./PipelinesActionsMenu";
import { ScopePreviewToggler } from "./ScopePreviewToggler";

interface PipelineLayoutProps {
  title: string;
  scope?: PipelineFragment;
  children: ReactNode;
  lastCrumb?: Breadcrumb;
}

/**
 * Renders the shared elements of a pipeline show, edit, and create page.
 */
export function PipelineLayout({
  title,
  scope,
  children,
  lastCrumb,
}: PipelineLayoutProps): ReactElement {
  const router = useRouter();

  const sidebarProps = useSidebarLayoutState("pipelines-right", {
    defaultIsOpen: true,
  });

  const crumbs: Breadcrumb[] = [
    {
      label: "Pipelines",
      routeName: ROUTE_NAMES.PIPELINES,
    },
  ];

  if (scope) {
    crumbs.push({
      label: scope.name,
      routeName: ROUTE_NAMES.PIPELINES_VIEW,
      params: { id: scope.id },
    });
  }

  if (lastCrumb) crumbs.push(lastCrumb);

  const actions = (
    <Flex gap={4} alignItems="flex-end">
      {scope ? <ScopePreviewToggler scope={scope} size="lg" /> : null}
      <DocsLink resourceType="pipelines" size="sm" />
      <PipelinesActionsMenu
        scope={scope}
        options={{
          info: sidebarProps.onToggle,
          rename: true,
          api: !!scope,
          delete: true,
          archive: true,
        }}
        onDeleteComplete={() => router.navigate(ROUTE_NAMES.PIPELINES)}
      />
    </Flex>
  );

  return (
    <PageLayout analyticsStackName="pipelines">
      <PageHeader
        title={title}
        rightContent={actions}
        crumbs={crumbs}
        backBtnProps={{
          label: "Back to Pipelines",
          routeName: ROUTE_NAMES.PIPELINES,
        }}
      />
      <SidebarLayout
        rightSidebarProps={{
          isOpen: sidebarProps.isOpen,
          onClose: sidebarProps.onToggle,
          title: "About pipelines",
          children: <PipelineInfoSidebar />,
          width: 360,
        }}
      >
        <Container py={6}>{children}</Container>
      </SidebarLayout>
    </PageLayout>
  );
}
