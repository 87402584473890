import { Box, Flex } from "@chakra-ui/react";
import { ChangeEvent } from "react";

import { FormField } from "../../../../ui/FormField";
import { Input } from "../../../../ui/Input";
import {
  formIds,
  labels,
  parseNumberValue,
  TargetLimitProps,
  TargetLimitStateRowCount,
} from "./TargetLimit";
import { TargetLimitOutcomeSelect } from "./TargetLimitOutcomeSelect";
import { LimitEnum } from "./TargetLimitTypeSelect";

type TargetLimitRowCountProps = Pick<
  TargetLimitProps,
  "onChange" | "payloadOutcomes"
> & {
  limit: TargetLimitStateRowCount;
  limitType: LimitEnum;
};

export function TargetLimitRowCount({
  limit,
  onChange,
  limitType,
  payloadOutcomes,
}: TargetLimitRowCountProps) {
  function handleThresholdChange(event: ChangeEvent<HTMLInputElement>) {
    onChange({
      ...limit,
      threshold: parseNumberValue(event.target.value),
    });
  }

  function handleOutcomeChange(outcomeId: string | null) {
    onChange({
      ...limit,
      rowCountOutcomeId: outcomeId,
    });
  }

  return (
    <Flex gap={4}>
      <TargetLimitOutcomeSelect
        payloadOutcomes={payloadOutcomes}
        value={limit.rowCountOutcomeId}
        onChange={handleOutcomeChange}
        required={limitType === LimitEnum.BottomCount}
      />

      <Box width="264px">
        <FormField
          label={labels.threshold}
          htmlFor={formIds.threshold}
          visuallyHiddenLabel
        >
          <Input
            type="number"
            placeholder="Row count e.g. 1000"
            value={limit.threshold ?? ""}
            onChange={handleThresholdChange}
            min={1}
            pattern="[0-9]+"
            id={formIds.threshold}
            analyticsName="limit-row-count"
          />
        </FormField>
      </Box>
    </Flex>
  );
}
