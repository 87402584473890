import { NEVER_RESOLVE } from "./base";
import { EmbeddedAuthService } from "./embedded";
import { AuthError } from "./errors";

/**
 * An auth service for JWTs that are directly signed by external identity
 * providers using their own keys.
 */
export class SimpleJwtAuthService extends EmbeddedAuthService {
  protected handleMissingToken(): Promise<never> {
    throw new AuthError("[AuthError] no #fdy_token was provided");
  }

  protected handleExpiredToken(_token: string): Promise<never> {
    this.log.error("[AuthError] expired JWT token; reloading");
    document.location.reload();
    return NEVER_RESOLVE;
  }
}
