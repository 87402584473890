import { Flex, Tag, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export function TagList({ tags }: { tags: ReactNode[] }) {
  return (
    <Flex as="ul" gap={2} flexWrap="wrap">
      {tags.map((t, i) => (
        <Tag as="li" key={i}>
          <Text as="span" noOfLines={1}>
            {t}
          </Text>
        </Tag>
      ))}
    </Flex>
  );
}
