import { gql, useQuery } from "@apollo/client";

import { AdminQuery } from "./__generated__/AdminQuery";

const adminQuery = gql`
  query AdminQuery {
    user {
      admin
    }
  }
`;

/** is logged in user an admin? */
export function useAdmin(): {
  admin: boolean;
  loading: boolean;
} {
  const { data, loading } = useQuery<AdminQuery>(adminQuery, {
    fetchPolicy: "cache-first",
  });

  return {
    loading,
    admin: data?.user.admin || false,
  };
}
