import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { PersonaSetsAnalysisPage } from "./analysis";
import { PersonaSetFlowPage } from "./flow/PersonaSetFlowPage";
import { PersonaSetsDefinitionPage } from "./PersonaSetsDefinitionPage";
import { PersonaSetsListPage } from "./PersonaSetsListPage";
import { PersonaSetsNewPage } from "./PersonaSetsNewPage";

export function Personas() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.PERSONAS]: PersonaSetsListPage,
    [ROUTE_NAMES.PERSONAS_NEW]: PersonaSetsNewPage,
    [ROUTE_NAMES.PERSONAS_ROSTER]: PersonaSetsDefinitionPage,
    [ROUTE_NAMES.PERSONAS_ANALYSIS]: PersonaSetsAnalysisPage,
    [ROUTE_NAMES.PERSONAS_FLOW]: PersonaSetFlowPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.PERSONAS} />
  );

  return <Page />;
}
