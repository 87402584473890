import { useEffect, useRef } from "react";
import { useBeforeUnload } from "react-use";

import { unsavedChangeMessage } from "../components/ui/Link";
import { useNavigationWarning } from "../components/ui/NavigationWarningProvider";
/**
 * Tracks a resource's state and will present a window warning message
 * if user attempts to leave page without saving after state change. Returns
 * a setState function that should be placed where the page is being saved.
 */
export function useUnsavedChangesWarning({
  config,
}: {
  config: unknown[];
  route: string;
}) {
  const resourceFirstRender = useRef<boolean>(true);
  const { warnBeforeNavigate, setWarnBeforeNavigate } = useNavigationWarning();

  useEffect(() => {
    if (resourceFirstRender.current) {
      resourceFirstRender.current = false;
    } else {
      setWarnBeforeNavigate(true);
    }
  }, config);

  useBeforeUnload(warnBeforeNavigate, unsavedChangeMessage);
  return { setWarnBeforeNavigate };
}
