import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { ConnectionsCreatePage } from "./ConnectionsCreatePage";
import { ConnectionsEditPage } from "./ConnectionsEditPage";
import { ConnectionsListPage } from "./ConnectionsListPage";
import { ConnectionsShowPage } from "./ConnectionsShowPage";

/**
 * Connections console lets users view and configure their connections.
 */
export function Connections() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.CONNECTIONS]: ConnectionsListPage,
    [ROUTE_NAMES.CONNECTIONS_NEW]: ConnectionsCreatePage,
    [ROUTE_NAMES.CONNECTIONS_EDIT]: ConnectionsEditPage,
    [ROUTE_NAMES.CONNECTIONS_SHOW]: ConnectionsShowPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.CONNECTIONS} />
  );

  return <Page />;
}
