import { ButtonProps, Text } from "@chakra-ui/react";

import { useHubSpotConversations } from "../../hooks/useHubSpotConversations";

/**
 * A button styled like a link.
 * If the client is authorized to use hubspot chat, the link will be enabled
 * and will open hubspot chat when clicked
 */
export function ChatLink(props: ButtonProps) {
  const { enabled, open: openChat } = useHubSpotConversations();
  return (
    <Text
      as="button"
      sx={{
        color: "fdy_purple.500",
        textDecoration: "underline",
        _disabled: { opacity: 0.5 },
        _hover: { textDecoration: "none" },
      }}
      onClick={openChat}
      disabled={!enabled}
    >
      {props.children}
    </Text>
  );
}
