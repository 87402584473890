import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";

import { OutcomeFragment } from "../../__generated__/OutcomeFragment";
import { OutcomeAnalysisQuery_outcomeAnalysis_bias as BiasReport } from "../__generated__/OutcomeAnalysisQuery";
import { BiasSubpopulationTable } from "./BiasSubpopulationTable";
import { OutcomeBiasHistoricalData } from "./OutcomeBiasHistoricalData";
import { OutcomeBiasSummary } from "./OutcomeBiasSummary";

export function OutcomeAnalysisBiasReport({
  report,
  outcome,
}: {
  report: BiasReport;
  outcome: OutcomeFragment;
}) {
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      label: "Summary",
      panel: (
        <OutcomeBiasSummary
          outcome={outcome}
          summary={report.summary}
          onDetailClick={setTabIndex}
        />
      ),
    },
    {
      label: "Data",
      panel: <OutcomeBiasHistoricalData data={report.data} />,
    },
    {
      label: "Power",
      panel: <BiasSubpopulationTable subpopulations={report.power} />,
    },
    {
      label: "Predictions",
      panel: <BiasSubpopulationTable subpopulations={report.predictions} />,
    },
    {
      label: "Fairness",
      panel: <BiasSubpopulationTable subpopulations={report.fairness} />,
    },
  ];

  return (
    <Tabs size="sm" index={tabIndex} onChange={(index) => setTabIndex(index)}>
      <TabList>
        {tabs.map((tab) => (
          <Tab key={tab.label}>{tab.label}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab.label}>{tab.panel}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
