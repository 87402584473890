import { VisuallyHidden } from "@chakra-ui/react";
import * as React from "react";
import { useForm } from "react-hook-form";

import { Button } from "./Button";
import { FormField } from "./FormField";
import { InlineButtons } from "./InlineButtons";
import { Input } from "./Input";
import { ModalV2 } from "./ModalV2";
import { useId } from "./useId";

interface FormState {
  name: string;
}

/**
 * Renders a modal for updating a resource's name.
 */
export function RenameModal({
  title,
  defaultValue,
  onClose,
  focusAfterCloseRef,
  updateFn,
  updating,
}: {
  title: string;
  defaultValue: string;
  onClose: () => void;
  focusAfterCloseRef?: React.RefObject<HTMLButtonElement>;
  updateFn: (name: string) => void;
  updating?: boolean;
}) {
  const { register, formState, handleSubmit } = useForm<FormState>({
    defaultValues: {
      name: defaultValue,
    },
  });

  function handleRenameSubmit({ name }: FormState) {
    updateFn(name.trim());
  }

  // use a form id so the submit button can exist outside the <form>
  const formId = useId("rename-modal-form");

  const footer = (
    <InlineButtons>
      <Button variant="tertiary" onClick={onClose} analyticsName="cancel">
        Cancel
      </Button>
      <Button
        type="submit"
        form={formId}
        isDisabled={updating}
        analyticsName="confirm"
      >
        {updating ? "Updating name..." : "Update name"}
      </Button>
    </InlineButtons>
  );

  const inputProps = register("name", {
    validate: (value) => {
      const name = value.trim();
      if (!name) {
        return "Name is required";
      }

      if (name.length > 64) {
        return "Name must be 64 characters or less";
      }

      return true;
    },
  });

  return (
    <ModalV2
      isOpen={true}
      onClose={onClose}
      finalFocusRef={focusAfterCloseRef}
      title={title}
      footer={footer}
      analyticsStackName="rename-modal"
    >
      <form
        id={formId}
        onSubmit={handleSubmit(handleRenameSubmit)}
        autoComplete="off"
      >
        <FormField
          label={<VisuallyHidden>Name</VisuallyHidden>}
          error={formState.errors.name?.message}
        >
          <Input
            type="text"
            placeholder="Name"
            autoFocus
            {...inputProps}
            analyticsName="name"
          />
        </FormField>
      </form>
    </ModalV2>
  );
}
