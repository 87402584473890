import {
  createRef,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
} from "react";
import { useCallback, useState } from "react";

type FormErrorRefs<Errors extends Record<string, string>> = {
  // Sometimes we apply refs to fieldsets but I don't know how to make this more dynamic
  // so we're just going to assume it's always a div.
  // FormFieldset component will tell a little lie about what ref it accepts.
  [K in keyof Errors]: RefObject<HTMLDivElement>;
};

type UseFormErrorsHook<Errors extends Record<string, string>> = {
  errors: Errors;
  resetError: (key: keyof Errors) => void;
  setErrors: Dispatch<SetStateAction<Errors>>;
  refs: FormErrorRefs<Errors>;
};

/**
 * Custom hook to manage form errors and scroll to the first error if ref is applied.
 */
export const useFormErrors = <
  Errors extends Record<string, string>
>(): UseFormErrorsHook<Errors> => {
  const [errors, setErrors] = useState<Errors>({} as Errors);

  const refs = Object.keys(errors).reduce(
    (acc, key) => ({
      ...acc,
      [key]: createRef(),
    }),
    {} as FormErrorRefs<Errors>
  );

  const resetError = useCallback(
    (key: keyof Errors) => {
      setErrors((prev) => ({
        ...prev,
        [key]: undefined,
      }));
    },
    [setErrors]
  );

  // scroll to the first error
  useEffect(() => {
    if (!refs) return;

    // if there are no errors, do nothing
    if (Object.values(errors).filter((v) => v).length === 0) {
      return;
    }

    // scroll to the first error
    const firstError = Object.keys(errors)[0];
    if (firstError) {
      refs[firstError].current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [errors, refs]);

  return {
    errors,
    resetError,
    setErrors,
    refs,
  };
};
