import { gql, useQuery } from "@apollo/client";
import { MockedResponse } from "@apollo/client/testing";
import { useMemo } from "react";

import * as ACCOUNT_FEATURES from "../constants/accountFeatureNames";
import { AccountConfigQuery } from "./__generated__/AccountConfigQuery";

export type AccountFeature =
  (typeof ACCOUNT_FEATURES)[keyof typeof ACCOUNT_FEATURES];
export type AccountConfigMap = Record<AccountFeature, boolean>;

type AccountFeaturesHook =
  | { loading: true }
  | { loading: false; config: AccountConfigMap; api_key: string | null };

type AccountBasics = { id: string | null; name: string };

export const ACCOUNT_CONFIG_FRAGMENT = gql`
  fragment AccountConfigFragment on Query {
    account {
      id
      name
      config
      api_key
    }
  }
`;

export const accountConfigQuery = gql`
  query AccountConfigQuery {
    ...AccountConfigFragment
  }
  ${ACCOUNT_CONFIG_FRAGMENT}
`;

/**
 * A low-level hook for getting just the account config value. Probably not
 * very useful on its own, instead, consider using `useAccountConfigMap` which
 * will always return a useful value to you.
 */
export function useAccountConfig(): AccountFeaturesHook {
  const { data, error } = useQuery<AccountConfigQuery>(accountConfigQuery, {
    fetchPolicy: "cache-first",
  });

  if (error) throw error;
  return useMemo(() => {
    if (!data) {
      return { loading: true };
    } else {
      return {
        loading: false,
        config: data.account.config as AccountConfigMap,
        api_key: data.account.api_key,
      };
    }
  }, [data]);
}

export function useAccountBasics(): AccountBasics {
  const { data } = useQuery<AccountConfigQuery>(accountConfigQuery, {
    fetchPolicy: "cache-first",
  });
  // user should not encounter this because account query should be cached
  // by time a user wants this hook later on
  if (!data) throw new Error("Account details are missing.");
  return {
    id: data.account.id,
    name: data.account.name,
  };
}

/**
 * Get a map of account config values to their present value associated
 * with the current account. If the current account hasn't been loaded,
 * all values will be `false`, which may be counter-intuitive or not
 * useful for your use case. In which case, please use `useAccountConfig`.
 */
export function useAccountConfigMap(): AccountConfigMap {
  const config = useAccountConfig();
  return useMemo(() => {
    if (config.loading) {
      const featureNames = Object.values(ACCOUNT_FEATURES);
      return featureNames.reduce(
        (cfg, feature) => ({ ...cfg, [feature]: false }),
        {}
      ) as AccountConfigMap;
    } else {
      return config.config;
    }
  }, [config]);
}

export function makeAccountConfigQuery(
  config: Partial<AccountConfigMap> = {}
): MockedResponse<AccountConfigQuery> {
  return {
    request: {
      query: accountConfigQuery,
    },
    result: {
      data: {
        __typename: "Query",
        account: {
          __typename: "Account",
          id: "f0c0fc71-aca6-44d5-b917-9a06f299f803",
          name: "Account Name", // Add the name property here
          config,
          api_key: null,
        },
      },
    },
  };
}
