import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Redirect } from "../ui/Redirect";
import { EventsShowPage } from "./EventShowPage/EventsShowPage";
import { EventsListPage } from "./EventsListPage/EventsListPage";

/**
 * Events console lets users view and configure their events.
 */
export function Events() {
  const { route } = useRoute();

  const routes = {
    [ROUTE_NAMES.EVENTS]: EventsListPage,
    [ROUTE_NAMES.EVENTS_SHOW]: EventsShowPage,
  };

  const Page = routes[route.name] ?? (
    <Redirect routeName={ROUTE_NAMES.EVENTS} />
  );

  return <Page />;
}
