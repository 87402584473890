import { Chat, GearSix, Icon, Lifebuoy, SignOut } from "@phosphor-icons/react";
import { ReactElement } from "react";

import * as ACCOUNT_FEATURES from "../../constants/accountFeatureNames";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  AccountFeature,
  useAccountConfigMap,
} from "../../hooks/accountConfigHooks";
import { useHubSpotConversations } from "../../hooks/useHubSpotConversations";
import { ResourceIcon } from "../ui/ResourceIcon";
import { NavigationBarGroup } from "./NavigationBarGroup";
import {
  NavigationBarAnchor,
  NavigationBarButton,
  NavigationBarLink,
} from "./NavigationBarOption";

interface AccountNavLinkOptions {
  feature?: AccountFeature;
  icon: Icon;
  label: string;
  routeName: string;
  routeParams?: Record<string, string>;
  analyticsName?: string;
}

const navItems: AccountNavLinkOptions[] = [
  {
    icon: ResourceIcon.connections,
    label: "Connections",
    routeName: ROUTE_NAMES.CONNECTIONS,
    feature: ACCOUNT_FEATURES.CONNECTIONS_ENABLED,
    analyticsName: "connections",
  },
  {
    icon: ResourceIcon.places,
    label: "Locations",
    routeName: ROUTE_NAMES.LOCATIONS,
    analyticsName: "locations",
    feature: ACCOUNT_FEATURES.LOCATIONS_ENABLED,
  },
  {
    icon: GearSix,
    label: "Settings",
    routeName: ROUTE_NAMES.SETTINGS,
    analyticsName: "settings",
  },
];

export function AccountNav(): ReactElement {
  const featureMap = useAccountConfigMap();
  const hubspot = useHubSpotConversations();

  function renderOption({
    feature,
    icon,
    label,
    routeName,
    routeParams,
  }: AccountNavLinkOptions) {
    if (feature && !featureMap[feature]) {
      return null;
    }
    return (
      <NavigationBarLink
        key={label}
        icon={icon}
        label={label}
        routeName={routeName}
        routeParams={routeParams}
        analyticsName={label.toLowerCase()}
      />
    );
  }

  return (
    <NavigationBarGroup label="Account">
      {navItems.map(renderOption).filter(Boolean)}
      {hubspot.enabled ? (
        <NavigationBarButton
          icon={Chat}
          label="Support chat"
          onClick={hubspot.open}
          analyticsName="get-help"
        />
      ) : null}
      <NavigationBarAnchor
        icon={Lifebuoy}
        label="Support tickets"
        href="https://support.faraday.ai/"
      />
      <NavigationBarLink
        icon={SignOut}
        label="Log out"
        routeName={ROUTE_NAMES.LOGOUT}
        analyticsName="log-out"
      />
    </NavigationBarGroup>
  );
}
