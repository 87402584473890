import { gql } from "@apollo/client";

import {
  PersonaSetFragment,
  PersonaSetFragment_personas,
} from "./__generated__/PersonaSetFragment";

export type PersonaSet = PersonaSetFragment;
export type Persona = PersonaSetFragment_personas;

export const personaSetFragment = gql`
  fragment PersonaSetFragment on PersonaSet {
    id
    name
    status
    statusError
    explore
    archivedAt
    cohort {
      id
      name
      status
    }
    modelingFields
    personas {
      id
      avatar
      name
      details {
        center
        bins
      }
      individualsCount
    }
    lastUpdatedOutputAt
    lastUpdatedConfigAt
    lastReadInputAt
    createdAt
  }
`;
