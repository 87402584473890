import { gql, useMutation, useQuery } from "@apollo/client";
import { MockedResponse } from "@apollo/client/testing";

import { TraitCategory } from "../__generated__/sojournerGlobalTypes";
import { DimensionTrait } from "../components/personas/analysis/types";
import {
  RosterFieldsQuery,
  RosterFieldsQueryVariables,
} from "./__generated__/RosterFieldsQuery";
import {
  UpdateRosterFieldsMutation,
  UpdateRosterFieldsMutationVariables,
} from "./__generated__/UpdateRosterFieldsMutation";
import { useToast } from "./useToast";

const ROSTER_FIELDS_QUERY = gql`
  query RosterFieldsQuery($rosterId: UUID!) {
    roster_by_id(id: $rosterId) {
      id
      analysis_fields {
        id
        name
      }
    }
  }
`;

const UPDATE_ROSTER_FIELDS_MUTATION = gql`
  mutation UpdateRosterFieldsMutation(
    $rosterId: UUID!
    $roster: RosterUpdateInput!
  ) {
    update_roster(roster_id: $rosterId, roster: $roster) {
      id
      analysis_fields {
        id
        name
      }
    }
  }
`;

function traitNameToKopengFieldName(trait: DimensionTrait) {
  if (trait.category === TraitCategory.USER_DEFINED) {
    return `fdy_trait_${trait.name}`;
  }

  return trait.name.replace("fig/", "");
}

function traitNameFromKopengFieldName(name: string) {
  if (name.startsWith("fdy_trait_")) {
    return name.replace(/^fdy_trait_/, "");
  }

  return `fig/${name}`;
}

/**
 * A hook to fetch and update the analysis fields for a roster.
 *
 * Kopeng sends back either fdy_trait_<name> for user defined trait or <name> for fig fields.
 * To match back to what sojourner uses to manage traits (<name> for user defined, and fig/<name> for FIG fields)
 * we need to remove the fdy_trait_ prefix for user defined traits
 * or add the fig/ prefix for FIG fields.
 *
 * Using kopeng is a stopgap here since we don't care for adding public
 * endpoints for updating fields like this.
 */
export function useConfigurablePersonaSetAnalysisTraitNames({
  rosterId,
  onUpdateComplete,
}: {
  rosterId: string;
  onUpdateComplete: () => void;
}) {
  const queryProps = useQuery<RosterFieldsQuery, RosterFieldsQueryVariables>(
    ROSTER_FIELDS_QUERY,
    {
      variables: { rosterId },
    }
  );
  if (queryProps.error) throw queryProps.error;

  const toast = useToast();

  const [updateRosterFields, mutationProps] = useMutation<
    UpdateRosterFieldsMutation,
    UpdateRosterFieldsMutationVariables
  >(UPDATE_ROSTER_FIELDS_MUTATION, {
    refetchQueries: [
      {
        query: ROSTER_FIELDS_QUERY,
        variables: { rosterId },
      },
    ],
    onError() {
      toast({
        status: "error",
        title: "Error updating persona set traits",
        description: "Please try again later or contact support",
      });
    },
    onCompleted() {
      onUpdateComplete();
    },
  });

  // Just return the field names and add the `fig/` prefix
  // We shouldn't care about other meta about fields passed over kopeng anymore.
  // If we do, get them from sojourner traits endpoint.
  const activeFields = queryProps.data?.roster_by_id?.analysis_fields ?? [];
  const activeFieldNames = activeFields.map((f) => f.name);

  function updateRosterAnalysisFields(fields: string[]) {
    updateRosterFields({
      variables: {
        rosterId,
        roster: {
          analysis_fields: fields,
        },
      },
    });
  }

  function addAnalysisTrait(trait: DimensionTrait) {
    updateRosterAnalysisFields([
      ...activeFieldNames,
      traitNameToKopengFieldName(trait),
    ]);
  }

  function removeAnalysisTrait(trait: DimensionTrait) {
    updateRosterAnalysisFields(
      activeFieldNames.filter((n) => n !== traitNameToKopengFieldName(trait))
    );
  }

  const rosterAnalysisTraitNames = activeFieldNames.map(
    traitNameFromKopengFieldName
  );

  return {
    rosterAnalysisTraitNames,
    loading: queryProps.loading,
    updating: mutationProps.loading,
    addAnalysisTrait,
    removeAnalysisTrait,
  };
}

// #########################
// TEST UTILS
// #########################

export function makeRosterFieldsQuery(
  data: RosterFieldsQuery,
  variables: RosterFieldsQueryVariables
): MockedResponse<RosterFieldsQuery> {
  return {
    request: {
      query: ROSTER_FIELDS_QUERY,
      variables,
    },
    result: {
      data,
    },
  };
}

export function makeUpdateRosterFieldsMutation(
  variables: UpdateRosterFieldsMutationVariables
) {
  return {
    request: {
      query: UPDATE_ROSTER_FIELDS_MUTATION,
      variables,
    },
    result: {
      data: {},
    },
  };
}
