import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { CardV2 } from "../../ui/Card/CardV2";
import { CohortsSidebarLayout } from "../CohortsSidebarLayout";
import { useCohortQuery } from "../useCohortQuery";
import { CohortMembershipChart } from "./CohortMembershipChart";

/**
 * Renders the cohort analysis page which shows cohort membership over time chart.
 */
export function CohortsAnalysisPage() {
  const { route } = useRoute();
  const cohortId = route.params.cohort;

  const { error, data, loading } = useCohortQuery(cohortId);
  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  const cohort = data?.cohort;
  if (!cohort) {
    throw new Error(
      "BUG: No cohort found, but sojourner did not throw an error"
    );
  }

  const title = cohort.name;

  return (
    <CohortsSidebarLayout
      cohort={cohort}
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.COHORTS_SHOW,
        params: { cohort: cohortId },
      }}
    >
      <CardV2
        title="Change over time"
        text="This chart shows the number of people in the cohort over time."
      >
        <CohortMembershipChart cohortId={cohortId} />
      </CardV2>
    </CohortsSidebarLayout>
  );
}
