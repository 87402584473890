import { Box, IconButton } from "@chakra-ui/react";
import { Plus, Trash } from "@phosphor-icons/react";
import { Dispatch, SetStateAction } from "react";

import { components } from "../../../../../sojourner-oas-types";
import { colors } from "../../../../../styles/chakra-theme-v2";
import { Button } from "../../../../ui/Button";
import {
  ALL_OPERATORS,
  ConditionRow,
  ConditionsBuilder,
  Operator,
  OperatorGroup,
} from "../../../../ui/ConditionsBuilder";
import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import { DatasetColumnSelect } from "./DatasetColumnSelect";

// graphql does not have a type for this
// because all of output_to_streams is a SojJSON
// & { "": null } handles temp state where user hasn't picked operator yet
export type DatasetEventCondition =
  components["schemas"]["DatasetStreamCondition"];

/**
 * convert each condition in conditions
 * to a format where it can be used by ConditionBuilder
 */
type EditableCondition = {
  column_name: string;
  conditions: ConditionRow[];
  optional?: boolean;
};

const OPERATOR_GROUPS: Record<OperatorGroup, Operator[]> = {
  numeric: ["gt", "lt", "gte", "lte"],
  text: ["eq", "neq", "matches"],
  boolean: ["null", "nnull"],
  list: ["in", "nin"],
  unknown: [],
};

// find the key in the mapping that contains the operator
function findOperatorGroup(operator: Operator): OperatorGroup {
  const group = Object.keys(OPERATOR_GROUPS).find((key) => {
    const operators = OPERATOR_GROUPS[key as OperatorGroup];
    return operators?.includes(operator);
  });

  if (!group) return "unknown" as OperatorGroup;

  return group as OperatorGroup;
}

function conditionToConditionRows(
  condition: Partial<DatasetEventCondition>
): ConditionRow[] {
  const rows: ConditionRow[] = [];

  for (const [operator, value] of Object.entries(condition)) {
    // handle case where user has just added a new condition
    // but hasn't selected operator yet
    if (value === null) {
      rows.push({
        type: null,
        operator: null,
        value: null,
      } as ConditionRow);
      continue;
    }
    if (Array.isArray(value) && value.length === 0) continue;

    const operatorName = operator.replace("_", "");
    const type = findOperatorGroup(operatorName as Operator);
    rows.push({
      type,
      // everywhere else the ConditionBuilder is used, graphql removes the underscores
      // but here, conditions are in output_to_streams, which is a SojJSON
      // underscores are not removed from SojJsons
      operator: operatorName,
      value,
    } as ConditionRow);
  }

  return rows;
}

function conditionsFromWire(
  eventConditions: DatasetEventCondition[] | null
): EditableCondition[] {
  if (!eventConditions) return [];

  const rows = eventConditions?.map((condition) => {
    const { optional, column_name, ...operators } = condition;

    const rowConditions = conditionToConditionRows(operators);
    return { column_name, conditions: rowConditions, optional };
  });
  return rows;
}

function conditionsToWire(
  conditions: EditableCondition[]
): DatasetEventCondition[] {
  const rows = conditions.map((condition) => {
    const apiCondition: Record<
      string,
      string | number | boolean | string[] | null
    > = {
      column_name: condition.column_name,
    };
    if (condition.optional) {
      apiCondition.optional = condition.optional;
    }
    condition.conditions.forEach((conditionRow) => {
      const apiOperator = conditionRow.operator
        ? "_" + conditionRow.operator
        : // not allowed on submit,
          // but will temporarily be set when condition is first added
          "";
      apiCondition[apiOperator] =
        conditionRow.value !== "" && conditionRow.value !== undefined
          ? conditionRow.value
          : null;
    });
    return apiCondition as DatasetEventCondition;
  });
  return rows;
}

export function DatasetsEventConditionsForm({
  conditions,
  setConditions,
  clearError,
  detectedColumns,
  sampleData,
  disabled = false,
}: {
  conditions: DatasetEventCondition[];
  setConditions: Dispatch<SetStateAction<DatasetEventCondition[]>>;
  clearError: () => void;
  detectedColumns: DetectedColumn[];
  sampleData: SampleData | undefined;
  disabled: boolean;
}) {
  const conditionsFormatted = conditionsFromWire(conditions);

  function handleChangeColumn(idx: number, newValue: string | null) {
    if (!newValue) return;

    const conditionsCopy = [...conditionsFormatted];
    const editedCondition = conditionsCopy[idx];
    editedCondition.column_name = newValue;
    conditionsCopy[idx] = editedCondition;
    setConditions(conditionsToWire(conditionsCopy));
    clearError();
  }

  function handleChangeCondition(idx: number, newValue: ConditionRow[]) {
    const conditionsCopy = [...conditionsFormatted];
    const editedCondition = conditionsCopy[idx];
    editedCondition.conditions = newValue;
    conditionsCopy[idx] = editedCondition;
    setConditions(conditionsToWire(conditionsCopy));
    clearError();
  }

  function handleAddCondition() {
    const conditionsCopy = [...conditionsFormatted];
    conditionsCopy.push({
      column_name: "",
      conditions: [{ type: null, operator: null, value: null }],
    });
    setConditions(conditionsToWire(conditionsCopy));
  }

  function handleDeleteCondition(idx: number) {
    const conditionsCopy = [...conditionsFormatted];
    conditionsCopy.splice(idx, 1);
    setConditions(conditionsToWire(conditionsCopy));
  }

  return (
    <>
      {conditionsFormatted.map((condition, idx) => {
        return (
          <Box
            key={idx}
            mb={4}
            pb={4}
            px={4}
            border="1px solid"
            borderColor={colors.fdy_gray[400]}
            borderRadius="md"
            bg="fdy_gray.100"
          >
            <Box
              py={4}
              display="flex"
              gap={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <DatasetColumnSelect
                sampleData={sampleData}
                detectedColumns={detectedColumns}
                value={condition.column_name}
                onChange={(newVal) => handleChangeColumn(idx, newVal)}
                label={`Condition dataset column ${idx + 1}`}
                analyticsName="event-conditions-column"
                disabled={disabled}
                required
              />

              <IconButton
                variant="icon"
                color="fdy_gray.700"
                aria-label={`Remove condition ${idx}`}
                icon={<Trash />}
                onClick={() => handleDeleteCondition(idx)}
              />
            </Box>
            <ConditionsBuilder
              onChange={(newVal) => handleChangeCondition(idx, newVal)}
              initialConditions={condition.conditions}
              operators={ALL_OPERATORS}
            />
          </Box>
        );
      })}
      <Button
        width="100%"
        variant="secondary"
        onClick={handleAddCondition}
        analyticsName="add-event-condition"
        leftIcon={<Plus weight="bold" />}
      >
        Add condition
      </Button>
    </>
  );
}
