import { Connection } from "../../../connections/useConnectionsQuery";
import { TargetFragment } from "../__generated__/TargetFragment";
import { TargetCardBaseProps } from "./TargetCardBase";
import { TargetCardGeneric } from "./TargetCardGeneric";
import { TargetCardHostedCsv } from "./TargetCardHostedCsv";
import { TargetCardLookupAPI } from "./TargetCardLookupAPI";
import { TargetCardManaged } from "./TargetCardManaged";

function isTargetConnectionManaged(
  connections: Connection[],
  target: TargetFragment
) {
  // hosted targets have null connection ids because they are implicit/hidden on the api
  // so get the name right from the connection_types.json
  if (!target.connectionId) {
    return false;
  }

  // otherwise if we have an id, find it by the existing connections
  const conn = connections.find((c) => c.id === target.connectionId);

  // no connection somehow, this shouldn't happen
  if (!conn) {
    throw new Error(
      "No connection found for target: " + JSON.stringify(target)
    );
  }

  return conn.managed;
}

export type TargetCardProps = Omit<TargetCardBaseProps, "children">;

/**
 * Renders the correct target card based on the target's connection type or if it is managed.
 */
export function TargetCard(props: TargetCardProps) {
  const { target, connections } = props;

  if (target.managed || isTargetConnectionManaged(connections, target)) {
    return <TargetCardManaged {...props} />;
  }

  if (target.options.__typename === "TargetOptionsHostedCsv") {
    return <TargetCardHostedCsv {...props} />;
  }

  if (target.options.__typename === "TargetOptionsLookupApi") {
    return <TargetCardLookupAPI {...props} />;
  }

  return <TargetCardGeneric {...props} />;
}
