import {
  Box,
  Flex,
  Input,
  Switch,
  Text,
  Tooltip,
  UseTooltipProps,
} from "@chakra-ui/react";
import { DotsSix, Icon, Info } from "@phosphor-icons/react";
import { Reorder, useDragControls, useMotionValue } from "framer-motion";
import * as React from "react";

import {
  TargetStructureProps,
  TargetStructureTransformationFormInput,
} from "./targetStructureUtils";

interface EditableColumnProps {
  isDragging: boolean;
  onDragStart: () => void;
  onDragEnd: () => void;
  structure: TargetStructureProps["structure"];
  onChangeCustomStructure: TargetStructureProps["onChangeCustomStructure"];
}

interface EditableColumnRowProps {
  column: TargetStructureTransformationFormInput;
  onEditName: (outputCol: string) => void;
  toggleShowColumn: () => void;
  isDragging: boolean;
  onDragStart: () => void;
  onDragEnd: () => void;
}

export const targetStructureColStyle = {
  width: "58px",
  py: 2,
  pl: 2,
};

interface EditableColumnIconProps {
  icon: Icon;
  tooltipLabel: React.ReactNode;
  tooltipProps?: UseTooltipProps;
  onPointerDown?: (event: React.PointerEvent) => void;
  draggable?: boolean;
}

function EditableColumnIcon({
  icon: Icon,
  tooltipLabel,
  tooltipProps,
  onPointerDown,
  draggable,
}: EditableColumnIconProps) {
  return (
    <Flex
      justify="center"
      alignItems="center"
      {...targetStructureColStyle}
      pl={0}
    >
      <Tooltip label={tooltipLabel} {...tooltipProps}>
        <Flex
          justify="center"
          alignItems="center"
          pt={1}
          sx={{ color: "fdy_gray.600", cursor: draggable ? "grab" : undefined }}
          onPointerDown={onPointerDown}
        >
          <Icon size={20} weight="bold" />
        </Flex>
      </Tooltip>
    </Flex>
  );
}

function EditableColumnRow({
  column,
  onEditName,
  toggleShowColumn,
  isDragging,
  onDragStart,
  onDragEnd,
}: EditableColumnRowProps) {
  const dragControls = useDragControls();
  const y = useMotionValue(0);
  return (
    <Reorder.Item
      id={column.outputCol} // this is unique if transform rules are configured correctly
      value={column}
      dragListener={false}
      dragControls={dragControls}
      style={{ y }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <Flex py={2} m={0} alignItems="center" height={50}>
        <EditableColumnIcon
          icon={DotsSix}
          tooltipLabel="Drag to reorder"
          tooltipProps={{ closeOnMouseDown: true, isDisabled: isDragging }}
          onPointerDown={(event) => dragControls.start(event)}
          draggable
        />
        <Box {...targetStructureColStyle} ml={1} mr={3}>
          <Switch
            isChecked={column.show}
            onChange={toggleShowColumn}
            aria-label={`Show ${column.scopeCol} in output`}
          />
        </Box>
        <Box flex="1" {...targetStructureColStyle} m={0} pl={1}>
          {isDragging ? (
            <Text
              as="span"
              userSelect="none"
              pointerEvents="none"
              sx={{ fontSize: "fdy_sm", p: 2, pl: 4, height: "auto" }}
            >
              {column.newOutputCol}
            </Text>
          ) : (
            <Input
              aria-label={column.outputCol}
              sx={{ fontSize: "fdy_sm", p: 2, pl: 4, height: "auto" }}
              value={column.newOutputCol}
              onChange={(event) => onEditName(event.target.value)}
              pattern="^[_a-z][_a-z0-9]*$"
              placeholder="Must contain only letters, numbers, and underscores"
            />
          )}
        </Box>
        <EditableColumnIcon
          icon={Info}
          tooltipLabel={
            !column.show ? (
              "This column will not be included in the output."
            ) : column.outputCol === column.newOutputCol ? (
              "This column will be included in the output."
            ) : (
              <Text>
                This column will be included in the output. It will be renamed
                from <strong>{column.outputCol}</strong>
                {" to "}
                <strong>{column.newOutputCol}</strong>
              </Text>
            )
          }
        />
      </Flex>
    </Reorder.Item>
  );
}

export function EditableColumns({
  isDragging,
  onDragStart,
  onDragEnd,
  structure,
  onChangeCustomStructure,
}: EditableColumnProps) {
  return (
    <Reorder.Group
      style={{ paddingTop: "4px", paddingBottom: "4px" }}
      axis="y"
      onReorder={onChangeCustomStructure}
      values={structure}
    >
      {structure.map((column, idx) => (
        <EditableColumnRow
          isDragging={isDragging}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onEditName={(newOutputCol) =>
            onChangeCustomStructure([
              ...structure.slice(0, idx),
              { ...structure[idx], newOutputCol },
              ...structure.slice(idx + 1),
            ])
          }
          toggleShowColumn={() =>
            onChangeCustomStructure([
              ...structure.slice(0, idx),
              { ...structure[idx], show: !structure[idx].show },
              ...structure.slice(idx + 1),
            ])
          }
          key={column.outputCol}
          column={column}
        />
      ))}
    </Reorder.Group>
  );
}
