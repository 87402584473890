import { ResourceStatus } from "../../__generated__/sojournerGlobalTypes";
import { useAccountConfigMap } from "../../hooks/accountConfigHooks";
import {
  HUBSPOT_USER_DISABLED_PIPELINE,
  HUBSPOT_USER_ENABLED_PIPELINE,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { useConfettiPopper } from "../ui/ConfettiPopper";
import { SwitchV2, SwitchV2Props } from "../ui/Switch/SwitchV2";
import { PipelineFragment } from "./__generated__/PipelineFragment";
import { useUpdateScope } from "./useUpdateScope";

// prettier-ignore
const TOOLTIPS = {
  undeployable: "Your pipeline is currently in preview mode. Add your first deployment to activate it.",
  unpaid: "Your pipeline is currently in preview mode. Enter payment information on the Settings page to activate it.",
};

/**
 * Renders either a button or a switch to toggle the preview flag on a scope.
 * When the scope is in preview mode, it will only deploy to a subset of the population.
 * Enabling the scope will also trigger some confetti to _really_ brighten up the user's day.
 */
export function ScopePreviewToggler({
  scope,
  size,
  visuallyHideLabel,
}: {
  scope: Pick<
    PipelineFragment,
    "id" | "targets" | "preview" | "name" | "lastUpdatedOutputAt" | "status"
  >;
  size?: SwitchV2Props["size"];
  visuallyHideLabel?: boolean;
}) {
  const toast = useToast();
  const featureMap = useAccountConfigMap();
  const { pop } = useConfettiPopper();
  const track = useHubSpotEvent();

  const { updateScope, updating } = useUpdateScope({
    onCompleted(data) {
      if (!data.updateScope) return;

      const { id, preview, name } = data.updateScope;

      // if pipeline/scope deploys are turned off i.e. preview = true
      if (preview) {
        track(HUBSPOT_USER_DISABLED_PIPELINE, {
          resource_id: id,
          resource_name: name,
        });
        toast({
          status: "info",
          title: `${name} disabled`,
          description: `${name} is now in preview mode and will only generate 1,000 rows of data.`,
        });
      } else {
        pop();
        track(HUBSPOT_USER_ENABLED_PIPELINE, {
          resource_id: id,
          resource_name: name,
        });
        toast({
          status: "success",
          title: `${name} enabled`,
          description: `${name} has left preview mode and will now include the full prediction payload.`,
        });
      }
    },
  });

  function handleTogglePreview() {
    updateScope(scope.id, { preview: !scope.preview });
  }

  const hasTargets = scope.targets.length > 0;
  const nonPreviewScopesAllowed = featureMap["scopes.non_preview_allowed"];
  const previewMode = scope.preview === true;
  const hasError = scope.status === ResourceStatus.ERROR;
  const canToggle = nonPreviewScopesAllowed ? hasTargets : false;

  // If the scope is out of preview mode, then never disable the toggle.
  // Otherwise, if it is currently updating, or doesn't have permissions to enable
  // then disable it.
  const elemsDisabled = previewMode && (updating || !canToggle);

  const tooltip = !nonPreviewScopesAllowed
    ? TOOLTIPS.unpaid
    : !hasTargets
    ? TOOLTIPS.undeployable
    : undefined;

  return (
    <SwitchV2
      size={size}
      label="Preview mode"
      onChange={handleTogglePreview}
      isChecked={previewMode}
      hasError={hasError}
      isDisabled={elemsDisabled}
      visuallyHideLabel={visuallyHideLabel}
      tooltip={tooltip}
      variant="orange"
    />
  );
}
