import { ReactElement, ReactNode, useCallback } from "react";

import { createContextStack } from "./createContextStack";

const { useStack, Stack } = createContextStack<string>();
const SEP = "/";

/**
 * Returns a function that will generate a fully-qualified analytics key path
 * for a provided name. This is lazy version of `useAnalyticsKey` that can be
 * used for complex components that have multiple components that need analytics
 * keys attached to them.
 *
 * If this hook is not passed a prefix, no analytics keys will be generated.
 * This facilitates the case where a component has an `analyticsName` prop, but
 * no analyticsName is passed. In that case, the component should be
 * "invisible" to analytics.
 */
export function useAnalyticsKeyGenerator(
  prefix?: string | null
): (nameOrNames?: string | string[]) => string | null {
  const stack = useStack();

  return useCallback(
    (nameOrNames) => {
      // No analytics context set up, don't do anything:
      if (stack.length === 0) return null;

      // No prefix passed, indicating we don't have a sub-namespace for this
      // component, so don't return analytics names.
      if (!prefix) return null;

      // No name passed, which is fine, we just return null back:
      if (!nameOrNames) return null;

      return stack.concat(prefix, nameOrNames).join(SEP);
    },
    [stack]
  );
}

/**
 * Return a fully-qualified analytics key path for a given component with the
 * provided name. You may pass a single name as a string, or a list of names as
 * an array. If no name or names are passed (i.e. undefined or null), then the
 * resulting analytics key will also be null. This supports the use case that
 * a component takes an `analyticsName` prop but that prop is not provided. In
 * that case, the component should be "invisible" to analytics.
 *
 * @param nameOrNames
 */
export function useAnalyticsKey(
  nameOrNames?: string | string[] | null
): string | null {
  const stack = useStack();

  if (!nameOrNames) return null;

  return stack.concat(nameOrNames).join(SEP);
}

interface AnalyticsStackProps {
  /**
   * The name of the analytics stack.
   *
   * It is optional because other components might want to define an analytics stack
   * of their own and allow an optional analytics name via their props.
   */
  value?: string;
  children: ReactNode;
}

/**
 * Introduce a new name into the analytics stack.
 */
export function AnalyticsStack({
  value,
  children,
}: AnalyticsStackProps): ReactElement {
  if (!value) return <>{children}</>;
  else return <Stack value={value}>{children}</Stack>;
}

/**
 * Return a raw HTML data-attribute for a given analyticsKey. Accepts a null
 * or empty value in the case that this element should not be given an
 * analytics key.
 */
export function analyticsAttrs(
  key?: string | null
  // eslint-disable-next-line @typescript-eslint/ban-types
): { "data-analytics": string } | {} {
  if (key) {
    return { "data-analytics": key };
  } else {
    return {};
  }
}
