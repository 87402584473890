import { RecommenderAnalysisPerformanceMetaModel } from "@fdy/faraday-js";

type RecommenderAnalysisGroup = keyof RecommenderAnalysisPerformanceMetaModel;

export const modelGroupToLiterate = (
  group: RecommenderAnalysisGroup,
  streamName: string
) => {
  const groupMapping = {
    overall: "Overall performance",
    new_and_recognized_individuals: `Among recognized individuals with no ${streamName} events to date`,
    new_and_unrecognized_individuals: `Among unrecognized individuals with no ${streamName} events to date`,
    repeat_and_recognized_individuals: `Among recognized individuals with 1+ ${streamName} events to date`,
    repeat_and_unrecognized_individuals: `Among unrecognized individuals with 1+ ${streamName} events to date`,
  };

  return groupMapping[group] ?? group;
};
