import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Resource } from "@fdy/faraday-js";
import { useState } from "react";

import { useAccountConfig } from "../../../hooks/accountConfigHooks";
import { getApiBaseUrl } from "../../../services/getApiBaseUrl";
import { SwitchV2 } from "../Switch/SwitchV2";
import { openApiSpecToRequestSnippets } from "./openApiSpecToApiRequests";
import { RequestCodeSnippet } from "./RequestCodeSnippet";
import { CodeLanguage } from "./requestToHttpSnippet";
import { SojournerApiSpecJson } from "./SojournerApiSpecJson";

interface RequestShortcutTabsProps {
  spec: SojournerApiSpecJson;
  resourceData: Resource;
}

const CODE_LANGUAGES: CodeLanguage[] = [
  "shell",
  "javascript",
  "python",
  "ruby",
];

/**
 * Renders a tabbed interface for displaying multiple code samples for a single
 * resource type. Tabs are listed by language, and each tab contains main CRUD
 * operations for the resource type.
 */
export function RequestShortcutTabs({
  spec,
  resourceData,
}: RequestShortcutTabsProps) {
  const config = useAccountConfig();
  const apiKey = config.loading === false ? config.api_key : null;

  const [showApiKey, setShowApiKey] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(CODE_LANGUAGES[0]);

  return (
    <Tabs
      onChange={(index) => setActiveLanguage(CODE_LANGUAGES[index])}
      index={CODE_LANGUAGES.indexOf(activeLanguage)}
    >
      <Flex justifyContent="space-between">
        <TabList>
          {CODE_LANGUAGES.map((language) => (
            <Tab key={language}>{language}</Tab>
          ))}
        </TabList>

        {apiKey ? (
          <SwitchV2
            label="Show API key"
            isChecked={showApiKey}
            onChange={(e) => setShowApiKey(e.target.checked)}
          />
        ) : null}
      </Flex>

      <TabPanels>
        {CODE_LANGUAGES.map((language) => {
          const requests = openApiSpecToRequestSnippets({
            spec,
            resource: resourceData,
            apiKey: showApiKey ? apiKey : null,
            baseUrl: getApiBaseUrl(),
          });

          return (
            <TabPanel key={language}>
              {requests.map((request, i) => (
                <Box key={i} sx={{ _notLast: { mb: 4 } }}>
                  <RequestCodeSnippet
                    request={request}
                    language={language}
                    resource={resourceData}
                  />
                </Box>
              ))}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
}
