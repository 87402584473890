import { gql, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { ReactElement, Suspense, useEffect, useRef } from "react";
import { useRoute } from "react-router5";

import { ROUTER_REDIRECT_FRAGMENT } from "../../constants/routes";
import { ACCOUNT_CONFIG_FRAGMENT } from "../../hooks/accountConfigHooks";
import { ANALYTICS_FRAGMENT } from "../../services/analytics/Analytics";
import { Cohorts } from "../cohorts";
import { Connections } from "../connections/Connections";
import { Datasets } from "../datasets/Datasets";
import { Events } from "../events/Events";
import { ExplorePage as Explore } from "../explore";
import { Inform } from "../inform/Inform";
import { Locations } from "../locations/Locations";
import { ACCOUNT_SWITCHER_FRAGMENT } from "../navigation/AccountSwitcher";
import { NavigationBar } from "../navigation/NavigationBar";
import { TestModeBanner } from "../navigation/TestModeBanner";
import { Onboarding } from "../onboarding/Onboarding";
import { Outcomes } from "../outcomes";
import { Personas } from "../personas";
import { Pipelines } from "../pipelines";
import { Recipes } from "../recipes/Recipes";
import { Recommenders } from "../recommenders";
import { Settings } from "../settings/Settings";
import { Traits } from "../traits/Traits";
import { useAnalytics } from "../ui/Analytics/AnalyticsProvider";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { ErrorBoundary } from "../ui/ErrorBoundary";
import { useRollbar } from "../ui/RollbarProvider";
import { RootQuery } from "./__generated__/RootQuery";
import { LoadingSplash } from "./LoadingSplash";

export const rootQuery = gql`
  query RootQuery {
    account {
      id
      name
    }
    user {
      id
      name
      email
    }
    ...AnalyticsFragment
    ...RouterRedirectFragment
    ...AccountSwitcherFragment
    ...AccountConfigFragment
  }
  # Front load most 'global' fragments so user should see 1 overall loading
  # splash for general parts of the app.
  ${ANALYTICS_FRAGMENT}
  ${ROUTER_REDIRECT_FRAGMENT}
  ${ACCOUNT_SWITCHER_FRAGMENT}
  ${ACCOUNT_CONFIG_FRAGMENT}
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`;

const StyledRootBody = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  z-index: 0;
  height: 100vh;
`;

const BODY_COMPONENTS = {
  cohorts: Cohorts,
  connections: Connections,
  datasets: Datasets,
  events: Events,
  explore: Explore,
  inform: Inform,
  locations: Locations,
  onboarding: Onboarding,
  outcomes: Outcomes,
  personas: Personas,
  pipelines: Pipelines,
  recipes: Recipes,
  recommenders: Recommenders,
  settings: Settings,
  traits: Traits,
} as const;

type RootRouteNode = keyof typeof BODY_COMPONENTS;

export function OldRoot(): ReactElement {
  const analytics = useAnalytics();
  const { route } = useRoute();
  const rollbar = useRollbar();
  const { data, error, loading } = useQuery<RootQuery>(rootQuery);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!data) return;

    rollbar.configure({
      payload: {
        person: {
          id: data.user.id,
          email: data.user.email ?? undefined,
          account: data.account.name,
        },
      },
    });
    analytics.configure({
      account: data.account,
      user: data.user,
    });
  }, [data]);

  useEffect(() => {
    // Scroll to top when navigating to a new route within `/recipes`.
    // Because the recipes routes scroll within this `body` parent element,
    // we can't just use window.scrollTo() to achieve this effect.
    // I think most other consoles avoid this by first rendering a loading page
    // which is smaller than the viewport, but for recipes we render the full
    // page and scroll within it.
    // We use `location.pathname` instead of the react-router
    // route for consistency with the other code in this file.
    if (window.location.pathname.startsWith("/recipes")) {
      bodyRef.current?.scrollTo(0, 0);
    }
  }, [window.location.pathname]);

  if (error) throw error;

  if (!data || !route || loading) {
    return <LoadingSplash />;
  }

  const [rootNode] = route.name.split(".");

  const Body = BODY_COMPONENTS[rootNode as RootRouteNode];

  if (rootNode === "onboarding") {
    return <Onboarding />;
  }

  return (
    <StyledRoot>
      <NavigationBar account={data.account} />
      <StyledRootBody ref={bodyRef}>
        <ErrorBoundary key={route.name}>
          <Suspense fallback={<AnimatedZapLogo />}>
            <TestModeBanner />
            {Body ? <Body /> : null}
          </Suspense>
        </ErrorBoundary>
      </StyledRootBody>
    </StyledRoot>
  );
}
