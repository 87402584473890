import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { ResourceStatus as ResourceStatusFdyJs } from "@fdy/faraday-js";

import { ResourceStatus as ResourceStatusGql } from "../../__generated__/sojournerGlobalTypes";

export enum ResourceTab {
  All = "All",
  Ready = "Ready",
  Building = "Building",
  Error = "Error",
  Archived = "Archived",
}

interface ResourceForTabFilter {
  status: ResourceStatusGql | ResourceStatusFdyJs;
  // handle both faraday-js and gql formatting
  archivedAt?: string | Date | undefined | null;
  archived_at?: string | Date | undefined | null;
}

export function filterResourceTabs<T extends ResourceForTabFilter>(
  data: T[] | undefined,
  activeTab: ResourceTab
): T[] {
  if (!data) return [];
  switch (activeTab) {
    case ResourceTab.Archived:
      return data.filter(
        (resource) => resource.archivedAt || resource.archived_at
      );
    case ResourceTab.All:
      return data.filter(
        (resource) => !resource.archivedAt && !resource.archived_at
      );
    case ResourceTab.Ready:
      return data.filter(
        (resource) =>
          !resource.archivedAt &&
          !resource.archived_at &&
          [ResourceStatusGql.READY, ResourceStatusFdyJs.Ready].includes(
            resource.status
          )
      );
    case ResourceTab.Building:
      return data.filter(
        (resource) =>
          !resource.archivedAt &&
          !resource.archived_at &&
          [
            ResourceStatusGql.NEW,
            ResourceStatusGql.STARTING,
            ResourceStatusGql.RUNNING,
            ResourceStatusFdyJs.New,
            ResourceStatusFdyJs.Starting,
            ResourceStatusFdyJs.Running,
          ].includes(resource.status)
      );
    case ResourceTab.Error:
      return data.filter(
        (resource) =>
          !resource.archivedAt &&
          !resource.archived_at &&
          [ResourceStatusGql.ERROR, ResourceStatusFdyJs.Error].includes(
            resource.status
          )
      );
  }
}

type ResourceTableTabProps<T extends ResourceForTabFilter> = {
  activeTab: ResourceTab;
  setActiveTab: (newVal: ResourceTab) => void;
  data: T[] | undefined;
};

export function ResourceTableTabs<T extends ResourceForTabFilter>({
  data,
  activeTab,
  setActiveTab,
}: ResourceTableTabProps<T>) {
  return (
    <RadioGroup
      variant="pill"
      size="sm"
      value={activeTab}
      // RadioGroup onChange specifically wants a number or string, not enum
      // but I want the user to give a valid ResourceTabs value
      // so do this casting to make the typescript happy
      onChange={(newVal) => setActiveTab(newVal as ResourceTab)}
    >
      <Stack direction="row">
        {Object.values(ResourceTab).map((tab) => {
          const count = filterResourceTabs(data, tab).length;
          return (
            <Radio key={tab} value={tab}>
              {tab} ({count})
            </Radio>
          );
        })}
      </Stack>
    </RadioGroup>
  );
}
