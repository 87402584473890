import { ROUTE_NAMES } from "../../constants/routeNames";
import { TabLinks } from "../ui/TabLinks";

export function PersonaSetTabs({
  personaSet,
}: {
  personaSet: {
    id: string;
  };
}) {
  const tabs = [
    {
      label: "Definition",
      routeName: ROUTE_NAMES.PERSONAS_ROSTER,
      params: {
        roster: personaSet.id,
      },
    },
    {
      label: "Analysis",
      routeName: ROUTE_NAMES.PERSONAS_ANALYSIS,
      params: {
        roster: personaSet.id,
      },
    },
    {
      label: "Flow",
      routeName: ROUTE_NAMES.PERSONAS_FLOW,
      params: {
        roster: personaSet.id,
      },
    },
  ];

  return <TabLinks tabs={tabs} />;
}
