import { useDisclosure, UseDisclosureProps } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocalStorage } from "react-use";

// ideally a unique list of all the sidebars throughout the app, to avoid
// collisions when storing state in local storage
type SidebarName =
  | "cohorts-right"
  | "datasets-right"
  | "outcomes-right"
  | "personas-right"
  | "pipelines-right"
  | "recommenders-right";

type SidebarState = Record<SidebarName, boolean>;

const SIDEBAR_LOCAL_STORAGE_KEY = "faraday.sidebar-layout-state";

/**
 * Wraps chakra useDisclosure to provide a state for the sidebar layout.
 * Stores the state in local storage so it can be restored on reload or page changes.
 */
export const useSidebarLayoutState = (
  name: SidebarName,
  rest: UseDisclosureProps
) => {
  const [value, setValue] = useLocalStorage<SidebarState>(
    SIDEBAR_LOCAL_STORAGE_KEY
  );

  // use the localstorage value if one exists, otherwise use the default value
  function getDefaultOpen() {
    if (value && name in value) {
      return value[name];
    }

    return rest.defaultIsOpen;
  }

  const props = useDisclosure({
    ...rest,
    defaultIsOpen: getDefaultOpen(),
  });

  // sync the localstorage value with the state of the sidebar
  useEffect(() => {
    if (value === undefined) return; // should never happen, but it's to appease the type checker
    setValue({
      ...value,
      [name]: props.isOpen,
    });
  }, [props.isOpen]);

  return props;
};
