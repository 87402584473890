import { gql, useQuery } from "@apollo/client";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { format } from "date-fns";

import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { Blankslate } from "../../ui/Blankslate";
import { Button } from "../../ui/Button";
import { FilesQuery, FilesQuery_files } from "./__generated__/FilesQuery";

function FilesTableRow({ file }: { file: FilesQuery_files }) {
  return (
    <Tr>
      {/* some file names are long, set a max width */}
      <Td style={{ maxWidth: 600 }}>{file.name}</Td>
      <Td>{file.creator?.name ?? file.creator?.email ?? ""}</Td>
      <Td width={200}>
        {file.created_at ? format(file.created_at, "MMM d, yyyy") : null}
      </Td>
      <Td width={100}>
        {file.download_url ? (
          <Button
            as="a"
            size="xs"
            variant="ghost"
            href={file.download_url}
            download
            analyticsName="download-file"
          >
            Download
          </Button>
        ) : null}
      </Td>
    </Tr>
  );
}

const FILES_QUERY = gql`
  query FilesQuery {
    files {
      id
      name
      created_at
      creator {
        id
        name
        email
      }
      download_url
      status
    }
  }
`;

export function FilesTable() {
  const { loading, error, data } = useQuery<FilesQuery>(FILES_QUERY, {
    pollInterval: 5000,
  });

  if (error) throw error;

  const files = data?.files ?? [];

  if (loading) return <AnimatedZapLogo />;
  if (!files.length) return <Blankslate title="No files found" />;

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>File name</Th>
          <Th>Added by</Th>
          <Th>Date added</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {files.map((file) => (
          <FilesTableRow key={file.id} file={file} />
        ))}
      </Tbody>
    </Table>
  );
}
