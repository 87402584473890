import { ApolloError, gql, useMutation } from "@apollo/client";
import { Textarea } from "@chakra-ui/react";
import { ChangeEvent, FormEvent, useState } from "react";

import {
  HUBSPOT_USER_INVITED_TEAMMATE,
  useHubSpotEvent,
} from "../../../hooks/useHubspotEvent";
import { useToast } from "../../../hooks/useToast";
import { plural } from "../../../utils/formatters";
import { Button } from "../../ui/Button";
import { FormField } from "../../ui/FormField";
import { useRollbar } from "../../ui/RollbarProvider";
import { MEMBERS_QUERY } from "./MemberTable";

// Split on whitespace or commas, then do a stupid check to see if the results look like email addresses:
function parseInvitees(inviteeString: string) {
  return Array.from(
    new Set(
      inviteeString
        .split(/[\s,]+/)
        .filter((e) => e && e.match(/^[^@]+@[^.]+\..+$/))
    )
  );
}

const inviteUserMutation = gql`
  mutation inviteUsers($user: InvitedUser!) {
    invite(user: $user) {
      id
    }
  }
`;

export function InviteUserForm() {
  const track = useHubSpotEvent();
  const [inviteUser] = useMutation(inviteUserMutation);

  const [textarea, setTextarea] = useState("");
  const [loading, setLoading] = useState(false);
  const rollbar = useRollbar();
  const toast = useToast();

  function handleTextareaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setTextarea(e.target.value);
  }

  async function onInvite(email: string) {
    await inviteUser({
      variables: { user: { email } },
      refetchQueries: [{ query: MEMBERS_QUERY }],
    });
    track(HUBSPOT_USER_INVITED_TEAMMATE, { invited_email: email });
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const invitees = parseInvitees(textarea);

    if (invitees.length === 0) {
      toast({
        status: "error",
        title: "Email not found",
        description: "Please enter at least one valid email address to invite.",
      });
      return;
    }

    setLoading(true);

    let errors = {};
    await Promise.all(
      invitees.map(async (email) => {
        try {
          await onInvite(email);
        } catch (e) {
          if (e instanceof Error || e instanceof ApolloError) {
            rollbar.warn("Failed to invite user. Check formatting.", { email });
            errors = {
              ...errors,
              [email]: e.valueOf(),
            };
          }
        }
      })
    );

    const successEmails = invitees.filter((email) => !(email in errors));
    const errorEmails = Object.keys(errors);

    if (successEmails.length) {
      toast({
        status: "success",
        title: `Invited ${successEmails.length} ${plural(
          successEmails.length,
          "user",
          "users"
        )}`,
        description: `Invites were sent to: ${successEmails.join(", ")}`,
      });
      setTextarea("");
    }

    if (errorEmails.length) {
      const errorMessages = Object.entries(errors).reduce(
        (acc, [email, error]) => `${acc}\n${email}: ${error}`,
        ""
      );

      toast({
        status: "error",
        title: `Failed to invite ${errorEmails.length} ${plural(
          errorEmails.length,
          "user",
          "users"
        )}`,
        description: errorMessages,
      });
      setTextarea(errorEmails.join(", "));
    }

    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        size="lg"
        label="Invite users"
        helpText="Enter one or more email addresses separated by commas."
      >
        <Textarea
          value={textarea}
          onChange={handleTextareaChange}
          placeholder="abbie@example.com, aaron@example.com, ..."
          required
        />
      </FormField>

      <Button
        type="submit"
        disabled={loading}
        loadingText="Inviting users..."
        isLoading={loading}
        mt={2}
        analyticsName="invite-users"
      >
        Invite users
      </Button>
    </form>
  );
}
