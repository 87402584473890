import { AreaSeries, AreaStack } from "@visx/xychart";
import { curveCardinal } from "d3";

import { colors } from "../../../styles/chakra-theme-v2";
import { ChartDatum, Persona } from "./personaFlowChartUtils";

/**
 * Renders a stack of area charts where each area is a persona.
 * Must be rendered inside an XYChart.
 */
export function PersonaFlowStackedAreas({
  flow,
  personas,
  hoverKey,
}: {
  flow: ChartDatum[];
  personas: Persona[];
  hoverKey: string | undefined;
}) {
  return (
    <AreaStack offset="expand" curve={curveCardinal} renderLine>
      {personas.map((p) => {
        return (
          <AreaSeries
            key={p.id}
            data={flow}
            dataKey={p.id}
            yAccessor={(d) => d[p.id]}
            xAccessor={(d) => d.date}
            fill={
              hoverKey === p.id
                ? colors.fdy_purple[500]
                : colors.fdy_purple[200]
            }
            style={{ transition: "fill 0.2s ease-in-out" }}
            lineProps={{
              strokeWidth: 4,
              stroke: "#fff",
            }}
          />
        );
      })}
    </AreaStack>
  );
}
