import { Box, Table, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { useState } from "react";

import { Blankslate } from "../../../ui/Blankslate";
import { CardV2 } from "../../../ui/Card/CardV2";
import { Pagination } from "../../../ui/Pagination-v2/Pagination";
import { DatasetShowPageQuery_dataset } from "../../__generated__/DatasetShowPageQuery";

const PAGE_SIZE = 10;

export function DatasetDataTabOther({
  dataset,
}: {
  dataset: DatasetShowPageQuery_dataset;
}) {
  const [page, setPage] = useState<number>(1);
  const datasetOptions = dataset?.options as unknown as Record<string, unknown>;

  function renderFiles(dataset: DatasetShowPageQuery_dataset) {
    const files = [...(dataset.updates ?? [])];

    if (!files || !files.length)
      return (
        <Tr>
          <Td colSpan={2} align="center">
            <Blankslate
              title="No updates found"
              text="Please go to Connections to confirm your connection is working"
            />
          </Td>
        </Tr>
      );
    return (
      <>
        {files
          .sort((a, b) => {
            if (a.datetime < b.datetime) return 1;
            if (a.datetime > b.datetime) return -1;
            return 0;
          })
          .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
          .map((file, idx) => (
            <Tr key={`file_${idx}`}>
              <Td>{new Date(file.datetime).toLocaleDateString()}</Td>
              <Td>{file.rowsAdded}</Td>
            </Tr>
          ))}
      </>
    );
  }

  const tableName = datasetOptions.tableName ?? datasetOptions.prefix;

  return (
    <VStack alignItems="stretch" spacing={8}>
      <Box>
        <CardV2
          title="Updates"
          text={
            <>
              {datasetOptions.type}
              {tableName ? ` - ${tableName}` : null}
            </>
          }
        >
          <Table mb={4}>
            <Thead>
              <Tr>
                <Th>Upload date</Th>
                <Th>Rows</Th>
              </Tr>
            </Thead>
            <Tbody>{renderFiles(dataset)}</Tbody>
          </Table>
          {dataset.updates ? (
            <Pagination
              total={dataset.updates ? dataset.updates.length : 0}
              pageSize={PAGE_SIZE}
              current={page}
              onChange={(newPage) => {
                setPage(newPage);
              }}
            />
          ) : null}
        </CardV2>
      </Box>
    </VStack>
  );
}
