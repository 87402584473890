import { gql } from "@apollo/client";
import Rollbar from "rollbar";
import { State } from "router5";

import { UUID_REG } from "../../utils/uuid";
import {
  AnalyticsFragment_account,
  AnalyticsFragment_user,
} from "./__generated__/AnalyticsFragment";

export const ANALYTICS_FRAGMENT = gql`
  fragment AnalyticsFragment on Query {
    account {
      id
      name
      enabled_products
      features
      hipaa
      created_at
      hubspot_company_id
    }
    user {
      id
      name
      email
      intercom_verification_hash
    }
  }
`;

interface TrackEventOpts {
  event: string;
  category?: string;
  [key: string]: unknown;
}
interface TrackPageOpts {
  path: string;
  route: string;
}

interface AnalyticsConfigInfo {
  account: AnalyticsFragment_account;
  user: AnalyticsFragment_user;
}

export interface Tracker {
  name: string;
  attach?: () => Promise<void>;
  configure?: (info: AnalyticsConfigInfo) => void | Promise<void>;
  trackEvent: (opts: TrackEventOpts) => void | Promise<void>;
  trackPage: (opts: TrackPageOpts) => void | Promise<void>;
}

interface AnalyticsOpts {
  trackers: Tracker[];
  rollbar: Rollbar;
}

/**
 * A utility for wrapping many different analytics provider hooks. We have two
 * entrypoints into the analytics system: tracking navigation changes, which is
 * handled transparently by the router, and tracking custom events. In React
 * code, events can be tracked with the `useEventTracker` hook.
 */
export class Analytics {
  private attached: Promise<void> | null = null;
  private readonly rollbar: Rollbar;
  private trackers: Tracker[];

  constructor(opts: AnalyticsOpts) {
    this.trackers = opts.trackers;
    this.rollbar = opts.rollbar;
  }

  // For each provided tracker, attach it to the document. Do not call this
  // from external code it will be called by `.configure` automatically. This
  // function may be called multiple times with no problems.
  private attach() {
    if (!this.attached) {
      this.attached = Promise.all(
        this.trackers.map((t) =>
          t.attach?.().catch((e) => {
            // Logging to rollbar here because we definitely care when a
            // tracker fails to load, but we don't really care if any single
            // analytics event fails to get sent to the server.
            this.rollbar.warning(e);
            return;
          })
        )
      ).then(() => {
        return;
      });
    }
    return this.attached;
  }

  // Configure the analytics tracker with user and account information. Call
  // this at the root level of the webapp with the exported graphql fragment.
  // May be called multiple times for the same analytics instance, in the case
  // that the user or account information changes.
  async configure({ account, user }: AnalyticsConfigInfo): Promise<void> {
    if (account.hipaa) {
      // This account has protected health information and we should not attach
      // any trackers to it for any reason. To offer up the same contract to
      // consuming code, though, we stub out the list of trackers and then
      // proceed as normal.
      this.trackers = [];
    }

    await this.attach();
    await Promise.all(
      this.trackers.map((t) => t.configure?.({ account, user }))
    );
  }

  // Track an arbitrary event to every attached analytics tracker.
  async trackEvent(evt: TrackEventOpts): Promise<void> {
    if (!this.attached) return;
    await this.attached;

    await Promise.all(
      this.trackers.map((t) => Promise.resolve(t.trackEvent(evt)))
    );
  }

  // Track a page transition. Should probably only be called by the router.
  async trackPage(state: State): Promise<void> {
    if (!this.attached) return;
    await this.attached;

    // Strip all UUIDs because we want to aggregate behavior across accounts,
    // and UUIDs are almost always specific to a particular account.
    const route = state.path.replace(UUID_REG, "uuid");

    await Promise.all(
      this.trackers.map((t) =>
        Promise.resolve(
          t.trackPage({
            path: state.path,
            route,
          })
        )
      )
    );
  }
}
