import { Progress } from "@chakra-ui/react";
import { ReactElement } from "react";

/**
 * Renders Chakra Progress bar with custom styles and our visual preferences (striped and animated)
 * https://v1.chakra-ui.com/docs/components/feedback/progress
 *
 * NOTE(zebapy): I tried adding these properties to theme defaultProps
 * but wasn't having much luck, so I opted to make a custom component.
 */
export function ProgressBar({ value }: { value: number }): ReactElement {
  return (
    <Progress
      colorScheme="fdy_teal"
      hasStripe
      isAnimated
      value={value}
      sx={{
        bg: "fdy_gray.100",
        height: 6,
        borderRadius: 6,
        "[role=progressbar]": {
          borderRadius: 0,
        },
      }}
    />
  );
}
