import { Link, Td, Tr } from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { RouterLink } from "../../ui/RouterLink";
import { TimeAgo } from "../../ui/TimeAgo";
import { PipelinesTableQuery_scopes as Scope } from "../__generated__/PipelinesTableQuery";
import { TargetFragment } from "../deployment/__generated__/TargetFragment";
import { PipelinesActionsMenu } from "../PipelinesActionsMenu";
import { ScopePayloadPill } from "./ScopePayloadPill";
import { ScopePopulationPill } from "./ScopePopulationPill";
import { ScopeTargetsPill } from "./ScopeTargetsPill";

/**
 * Render a single row for the Pipelines (scopes) table/list page.
 * Each row shows the preview on/off switch, scope name, info about contents of the scope,
 * and actions menu for edit, delete, and duplicate.
 */
export function PipelineTableRow({
  scope,
  targets,
  targetsLoading,
}: {
  scope: Scope;
  targets: TargetFragment[];
  targetsLoading: boolean;
}) {
  return (
    <Tr>
      <Td>
        <Link
          as={RouterLink}
          routeName={ROUTE_NAMES.PIPELINES_VIEW}
          params={{ id: scope.id }}
          fontWeight="bold"
        >
          {scope.name}
        </Link>
      </Td>
      <Td>
        <ScopePopulationPill scope={scope} />
      </Td>
      <Td>
        <ScopePayloadPill scope={scope} />
      </Td>
      <Td>
        <ScopeTargetsPill targets={targets} loading={targetsLoading} />
      </Td>
      <Td>
        <ResourceStatusBadge
          resource={scope}
          titlePrefix={scope.preview ? "Preview" : undefined}
        />
      </Td>
      <Td>
        <TimeAgo date={scope.createdAt} />
      </Td>
      <Td>
        <TimeAgo date={scope.lastUpdatedConfigAt} />
      </Td>
      <Td>
        <TimeAgo date={scope.lastUpdatedOutputAt} />
      </Td>
      <Td textAlign="right">
        <PipelinesActionsMenu
          scope={scope}
          options={{
            view: true,
            rename: true,
            api: true,
            edit: true,
            delete: true,
            archive: true,
          }}
          buttonProps={{ variant: "icon", my: -2 }}
        />
      </Td>
    </Tr>
  );
}
