import { ROUTE_NAMES } from "../../constants/routeNames";
import { TabLinks } from "../ui/TabLinks";
import { CohortFragment } from "./__generated__/CohortFragment";

export function CohortTabs({ cohort }: { cohort: CohortFragment }) {
  const tabs = [
    {
      label: "Definition",
      routeName: ROUTE_NAMES.COHORTS_SHOW,
      params: {
        cohort: cohort.id,
      },
    },
    {
      label: "Analysis",
      routeName: ROUTE_NAMES.COHORT_ANALYSIS,
      params: {
        cohort: cohort.id,
      },
    },
  ];

  return <TabLinks tabs={tabs} />;
}
