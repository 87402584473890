import { Notice } from "../ui/Notice";

export function PipelineRecipeNotice() {
  return (
    <Notice
      variant="success"
      title="Recipe complete!"
      description={
        <>
          <p style={{ marginBlock: ".5rem" }}>
            Once the pipeline is finished building, you can check out the
            predictions.
          </p>
          <ul
            style={{
              listStyle: "disc",
              marginLeft: "1rem",
            }}
          >
            <li>
              In the <strong>Deployment</strong> area below, click Test
              deployment.
            </li>
            <li>
              Check out the deployed predictions to make sure they came through.
            </li>
            <li>
              Activate your new pipeline: in the top right, click the{" "}
              <strong>Preview mode</strong> toggle to enable it.
            </li>
          </ul>
        </>
      }
    />
  );
}
