import { UsagesQuery } from "../../components/settings/billing/__generated__/UsagesQuery";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { useSojournerQuery } from "../../services/sojournerApolloClient";
import { usagesQuery } from "../settings/billing/usagesUtils";
import { Notice } from "../ui/Notice";
import { RouterLink } from "./RouterLink";

/**
 * Warn user if *any* trait grant has reached its usage limit
 *
 */
export function TraitUsageWarningNotice() {
  const { data, error } = useSojournerQuery<UsagesQuery>(usagesQuery);

  if (error) throw error;

  const usages = data?.usages;
  const hasHitLimit = usages?.some((usage) => {
    if (usage.usage === null || usage.limit === null) return;
    if (usage.name?.startsWith("traits.") && usage.usage > usage.limit) {
      return true;
    }
  });

  if (!hasHitLimit) {
    return null;
  }

  return (
    <Notice
      variant="warning"
      title="Trait Append limit reached!"
      description={
        <>
          You are including traits in pipeline payloads in excess of specified
          limits. Overage fees will apply.{" "}
          <RouterLink routeName={ROUTE_NAMES.SETTINGS_BILLING}>
            See details
          </RouterLink>
        </>
      }
      dismissable={false}
      sx={{ mb: 6 }}
    />
  );
}
