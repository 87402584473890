import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { CardStack } from "../../ui/CardStack";
import { ResourceGraphCard } from "../../ui/ResourceGraphCard";
import { OutcomeLayout } from "../OutcomeLayout";
import { useOutcomeQuery } from "../useOutcomeQuery";
import { OutcomeBiasCard } from "./OutcomeBiasCard";
import { OutcomeDataCard } from "./OutcomeDataCard";
import { OutcomeDefinitionCard } from "./OutcomeDefinitionCard";
import { OutcomePerformanceCard } from "./OutcomePerformanceCard";

export function OutcomesShowPage() {
  const { route } = useRoute();

  const outcomeId = route.params.outcome;
  const { data, loading, error } = useOutcomeQuery(outcomeId);

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  const outcome = data?.outcome;
  if (!outcome)
    throw new Error(
      "BUG: No outcome found, but sojourner did not throw an error"
    );

  return (
    <OutcomeLayout
      outcome={outcome}
      crumbs={[
        {
          label: "Outcomes",
          routeName: ROUTE_NAMES.OUTCOMES,
        },
        {
          label: outcome.name,
          routeName: ROUTE_NAMES.OUTCOMES_DEFINITION,
          params: { outcome: outcome.id },
        },
      ]}
      backBtnProps={{
        label: "Outcomes",
        routeName: ROUTE_NAMES.OUTCOMES,
      }}
    >
      <CardStack>
        <OutcomeDefinitionCard outcome={outcome} />
        <OutcomePerformanceCard outcome={outcome} />
        <OutcomeDataCard outcome={outcome} />
        <OutcomeBiasCard outcome={outcome} />
        <ResourceGraphCard resourceId={outcome.id} />
      </CardStack>
    </OutcomeLayout>
  );
}
