import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import { AnalyticsStack } from "../../../ui/Analytics/AnalyticsStack";
import { FormFieldset } from "../../../ui/FormFieldset";
import { Input } from "../../../ui/Input";
import { EventState } from "../CohortForm";
import { EventNumberChangeHandler } from "./CohortEventsSetupModal";

export const valueLabels = {
  minValue: "Minimum value",
  maxValue: "Maximum value",
};

/**
 * Renders form inputs for editing the value of cohort event.
 */
export function CohortEventsValue({
  event,
  onFieldChange,
}: {
  event: EventState;
  onFieldChange: EventNumberChangeHandler;
}) {
  const { minValue, maxValue } = event;

  return (
    <AnalyticsStack value="value">
      <FormFieldset
        legend="Does earned value matter?"
        hint="Qualify individuals into this cohort based on earned value. For example, customers who have spent at least $500 with you over time. To target a single events value (for example, those with an order over $500) use the Properties section below."
      >
        <Flex gap={4}>
          <FormControl>
            <FormLabel>{valueLabels.minValue}</FormLabel>
            <InputGroup>
              <Input
                value={minValue ?? ""}
                onChange={onFieldChange("minValue")}
                type="number"
                step={0.01}
                pattern="[0-9.]*"
                min={1.0}
                analyticsName="min-value"
              />
              <InputRightElement>Min $</InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>{valueLabels.maxValue}</FormLabel>
            <InputGroup>
              <Input
                value={maxValue ?? ""}
                onChange={onFieldChange("maxValue")}
                type="number"
                step={0.01}
                pattern="[0-9.]*"
                min={minValue ? minValue + 0.01 : 1}
                analyticsName="max-value"
              />
              <InputRightElement>Max $</InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>
      </FormFieldset>
    </AnalyticsStack>
  );
}
