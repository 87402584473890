import { ButtonGroup, Container } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AlertStack } from "../../ui/AlertStack";
import { DependencyStatusAlert } from "../../ui/DependencyStatusAlert";
import { DocsLink } from "../../ui/DocsLink";
import { ManagedExplanation } from "../../ui/ManagedExplanation";
import { PageHeader } from "../../ui/PageHeader";
import { PageLayout } from "../../ui/PageLayout";
import { ResourceArchivedAlert } from "../../ui/ResourceStatus/ResourceArchivedAlert";
import { ResourceStatusAlert } from "../../ui/ResourceStatus/ResourceStatusAlert";
import { SidebarLayout, useSidebarLayoutState } from "../../ui/SidebarLayout";
import { DatasetFragment } from "../__generated__/DatasetFragment";
import { DatasetsActionsMenu } from "../shared/DatasetsActionsMenu";
import { DatasetInfoSidebar } from "./DatasetInfoSidebar";
import { DatasetTabs } from "./DatasetTabs";

/**
 * Renders the layout that wraps dataset create, edit, and show pages.
 */
export function DatasetLayout({
  dataset,
  children,
}: {
  dataset: DatasetFragment;
  children: ReactNode;
}) {
  const sidebarProps = useSidebarLayoutState("datasets-right", {
    defaultIsOpen: false,
  });
  const router = useRouter();
  const id = router.getState().params.id;

  const actions = (
    <ButtonGroup>
      <DocsLink resourceType="datasets" size="sm" />
      <DatasetsActionsMenu
        dataset={dataset}
        options={{
          info: sidebarProps.onToggle,
          rename: !dataset.managed,
          api: true,
          delete: true,
          archive: true,
        }}
        onDeleteComplete={() => {
          router.navigate(ROUTE_NAMES.DATASETS);
        }}
      />
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="datasets">
      <PageHeader
        title={dataset.name}
        crumbs={[
          {
            label: "Datasets",
            routeName: ROUTE_NAMES.DATASETS,
          },
          {
            label: dataset.name,
            routeName: ROUTE_NAMES.DATASETS_DEFINITION,
            params: { id },
          },
        ]}
        backBtnProps={{
          label: "Datasets",
          routeName: ROUTE_NAMES.DATASETS,
        }}
        rightContent={actions}
      />
      <SidebarLayout
        rightSidebarProps={{
          isOpen: sidebarProps.isOpen,
          onClose: sidebarProps.onToggle,
          title: "About datasets",
          children: <DatasetInfoSidebar />,
          width: 400,
        }}
      >
        <Container py={6}>
          <DatasetTabs dataset={dataset} />
          <AlertStack>
            <ResourceArchivedAlert resource={dataset} />
            <DependencyStatusAlert resource={dataset} />
            <ResourceStatusAlert resource={dataset} />
          </AlertStack>
          {dataset.managed && <ManagedExplanation resourceType="dataset" />}
          {children}
        </Container>
      </SidebarLayout>
    </PageLayout>
  );
}
