import { gql } from "@apollo/client";
import isEqual from "lodash/isEqual";
import { ReactElement } from "react";
import { useRoute, useRouter } from "react-router5";

import { ResourceStatus } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_UPDATED_PIPELINE,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { assertNonEmptyArray } from "../../utils/assertNonEmptyArray";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { useModalV2 } from "../ui/ModalV2";
import { SCOPE_DATASETS_QUERY } from "./deployment/useScopeDependencies";
import { PipelineForm, PipelineFormState } from "./PipelineForm/PipelineForm";
import { PipelineLayout } from "./PipelineLayout";
import { SCOPE_PAYLOAD_QUERY } from "./ScopePayloadBreakdown";
import { SCOPE_POPULATION_QUERY } from "./ScopePopulationBreakdown";
import { usePipelineQuery } from "./usePipelineQuery";
import { useUpdateScope } from "./useUpdateScope";

function configChanged(prev: PipelineFormState, next: PipelineFormState) {
  const { name: prevName, ...prevConfig } = prev;
  const { name: nextName, ...nextConfig } = next;
  return !isEqual(prevConfig, nextConfig);
}

/**
 * Render the pipeline/scope page, but with the definition as editable form.
 */
export function PipelinesEditPage(): ReactElement {
  const { route } = useRoute();
  const scopeId = route.params.id;
  const { data, loading, error } = usePipelineQuery(scopeId);
  const { navigate } = useRouter();
  const { confirm } = useModalV2();
  const track = useHubSpotEvent();
  const toast = useToast();

  const { updateScope, updating } = useUpdateScope({
    refetchQueries: [
      {
        query: SCOPE_PAYLOAD_QUERY,
        variables: { id: scopeId },
      },
      {
        query: SCOPE_POPULATION_QUERY,
        variables: { id: scopeId },
      },
      {
        query: SCOPE_DATASETS_QUERY,
        variables: { id: scopeId },
      },
    ],

    onCompleted(data) {
      if (!data.updateScope) return;

      const { id, name } = data.updateScope;

      track(HUBSPOT_USER_UPDATED_PIPELINE, {
        resource_id: id,
        resource_name: name,
      });

      toast({
        status: "success",
        title: `${name} pipeline updated!`,
        description:
          "Your pipeline has been saved. It may take some time before you see updated totals.",
      });

      // navigate back to show scope page
      navigate(ROUTE_NAMES.PIPELINES_VIEW, { id });
    },
  });

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  if (!data?.scope) return <div>No pipeline found</div>;
  const { scope } = data;

  // pipeline form state only manages arrays of UUID, so pluck them out as needed
  const defaultState: PipelineFormState = {
    everyoneSelected: scope.population.cohortIds.length === 0,
    name: scope.name,
    payloadOutcomeIds: scope.payload.outcomeIds,
    payloadPersonaSetIds: scope.payload.personaSetIds,
    payloadCohortIds: scope.payload.cohortIds,
    populationCohortIds: assertNonEmptyArray(scope.population.cohortIds)
      ? scope.population.cohortIds
      : [],
    populationExclusionCohortIds: scope.population.exclusionCohortIds,
    outcomeExplainability: scope.payload.explainability ?? false,
    payloadRecommenderIds: scope.payload.recommenderIds,
    attributes: scope.payload.attributes,
  };

  function updateScopeAndCache(formState: PipelineFormState) {
    updateScope(
      scopeId,
      {
        name: formState.name,
        payload: {
          outcome_ids: formState.payloadOutcomeIds,
          persona_set_ids: formState.payloadPersonaSetIds,
          cohort_ids: formState.payloadCohortIds,
          explainability: formState.outcomeExplainability,
          recommender_ids: formState.payloadRecommenderIds,
          attributes: formState.attributes,
        },
        population: {
          cohort_ids: formState.populationCohortIds,
          exclusion_cohort_ids: formState.populationExclusionCohortIds,
        },
      },
      {
        // Because updating a scope won't immediately set the job status back to running,
        // try to get ahead of it in the client cache, so the user sees the loading page again.
        // This is a bit of a hack. I suspect a user could see the 'built' scope if they refresh quickly
        // and the update still hasn't revised the job status in the db.
        update(cache, { data }) {
          if (data?.updateScope) {
            cache.writeFragment({
              id: `Scope:${scopeId}`,
              fragment: gql`
                fragment PipelineStatus on Scope {
                  status
                }
              `,
              data: {
                status: ResourceStatus.RUNNING,
              },
            });
          }
        },
      }
    );
  }

  function handleSave(formState: PipelineFormState) {
    if (
      configChanged(defaultState, formState) &&
      // only truly care about config change if scope has targets
      scope.targets.length > 0
    ) {
      confirm({
        label: "Edit pipeline definition",
        title: "Are you sure you want to edit the pipeline definition?",
        text: "Changing the definition of the pipeline is a permanent change and will require rebuilding the pipeline. Are you certain you want to redefine this pipeline?",

        onConfirm() {
          updateScopeAndCache(formState);
        },
      });
    } else {
      updateScopeAndCache(formState);
    }
  }

  return (
    <PipelineLayout
      scope={scope}
      title={scope.name}
      lastCrumb={{
        label: "Edit",
        routeName: ROUTE_NAMES.PIPELINES_EDIT,
        params: { id: scope.id },
      }}
    >
      <PipelineForm
        defaultState={defaultState}
        onSave={handleSave}
        saving={updating}
      />
    </PipelineLayout>
  );
}
