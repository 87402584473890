import { Stream } from "@fdy/faraday-js";
import { useMemo, useState } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useStreamListQuery } from "../../../hooks/api";
import { ResourceTableList } from "../../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../../ui/ResourceTableTabs";
import { SortableHeader, SortableTable } from "../../ui/SortableTable";
import { EventTableRow } from "./EventsTableRow";

const BLANK_SLATE_PROPS = {
  title: "No event streams found",
  text: "Event Streams are emitted by Datasets.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Use Datasets to add Event Streams.",
  },
};

const tableHeaders: SortableHeader<Stream>[] = [
  {
    key: "name",
    label: "Name",
    width: 200,
  },
  {
    label: "Datasets",
    width: 100,
  },
  {
    label: "Properties",
    width: 100,
  },
  {
    label: "Event count",
    key: "event_count",
    // TODO: fix sortable table to do this automatically for empty values
    sortingFn: (a, b, descending) => {
      const diff = (a.event_count ?? 0) - (b.event_count ?? 0);
      return descending ? -diff : diff;
    },
  },
  {
    label: "Most recent event",
    key: "newest_date",
    sortingFn: "date",
    width: 200,
  },
  {
    key: "status",
    label: "Status",
    sortingFn: "string",
  },
  {
    key: "created_at",
    label: "Created",
    sortingFn: "date",
  },
  {
    key: "last_updated_config_at",
    label: "Modified",
    sortingFn: "date",
  },
  {
    key: "last_updated_output_at",
    label: "Processed",
    sortingFn: "date",
  },
  {
    label: "Actions",
    width: 64,
    visuallyHidden: true,
  },
];

/**
 * Render list of account's events with various meta data,
 */
export function EventsTable() {
  const streamsQuery = useStreamListQuery();

  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);

  const filteredEvents = useMemo(
    () => filterResourceTabs(streamsQuery.data, activeTab),
    [streamsQuery.data, activeTab]
  );

  const renderEventRow = (stream: Stream) => {
    return <EventTableRow key={stream.id} stream={stream} />;
  };

  return (
    <>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={streamsQuery.data}
      />
      <SortableTable
        storageKey="streams"
        defaultSort={{
          key: "created_at",
          desc: true,
        }}
        data={filteredEvents}
        headers={tableHeaders}
        renderData={(data) => (
          <ResourceTableList<Stream>
            data={data}
            renderData={renderEventRow}
            blankSlateProps={BLANK_SLATE_PROPS}
          />
        )}
      />
    </>
  );
}
