import { Link, Td, Text, Tr } from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { findConnectionTypeInfoBySlug } from "../../pipelines/connectionUtils";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { RouterLink } from "../../ui/RouterLink";
import { TimeAgo } from "../../ui/TimeAgo";
import { VendorLogo } from "../../ui/VendorLogo";
import { ConnectionsTableQuery_connections as Connection } from "../__generated__/ConnectionsTableQuery";
import { ConnectionsActionsMenu } from "../ConnectionsActionsMenu";
/**
 * Render a single row for the Connections table/list page.
 * Each row shows connection name, number of targets, and names of errored targets
 * and actions menu for edit, delete, and duplicate.
 */
export function ConnectionTableRow({ connection }: { connection: Connection }) {
  const connectionType = findConnectionTypeInfoBySlug(connection.options.type);

  return (
    <Tr>
      <Td>
        <Link
          as={RouterLink}
          routeName={ROUTE_NAMES.CONNECTIONS_SHOW}
          params={{ id: connection.id }}
          fontWeight="bold"
          display="flex"
        >
          <VendorLogo path={connectionType?.logo_url} dimension={20} />
          <Text ml={4}>{connection.name}</Text>
        </Link>
      </Td>
      <Td>{connection.datasets.length}</Td>
      <Td>{connection.targets.length}</Td>
      <Td>
        <ResourceStatusBadge resource={connection} />
      </Td>
      <Td>
        <TimeAgo date={connection.createdAt} />
      </Td>
      <Td>
        <TimeAgo date={connection.lastUpdatedConfigAt} />
      </Td>
      <Td>
        <TimeAgo date={connection.lastUpdatedOutputAt} />
      </Td>
      <Td textAlign="right">
        <ConnectionsActionsMenu
          connection={connection}
          options={{
            view: true,
            edit: !connection.managed,
            api: true,
            delete: true,
            archive: true,
          }}
          buttonProps={{ variant: "icon", my: -2 }}
        />
      </Td>
    </Tr>
  );
}
