import { Button, ButtonGroup, Container } from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useAccountConfig } from "../../hooks/accountConfigHooks";
import { getApiBaseUrl } from "../../services/getApiBaseUrl";
import { DocsLink } from "../ui/DocsLink";
import { PageHeader } from "../ui/PageHeader";
import { PageIntroText } from "../ui/PageIntroText";
import { PageLayout } from "../ui/PageLayout";
import { UsageWarningNotice } from "../ui/UsageWarningNotice";
import { TraitsTable } from "./TraitsTable";

export function TraitsListPage() {
  const config = useAccountConfig();
  const apiKey = config.loading === false ? config.api_key : null;

  const actions = (
    <ButtonGroup>
      <DocsLink resourceType="traits" />
      <Button
        as="a"
        download
        href={`${getApiBaseUrl()}/traits.csv?key=${apiKey}`}
      >
        Save as CSV
      </Button>
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="traits-list">
      <PageHeader
        title="Traits"
        crumbs={[
          {
            label: "Traits",
            routeName: ROUTE_NAMES.TRAITS,
          },
        ]}
        rightContent={actions}
      />
      <Container size={"fdy_lg"} py={6}>
        <UsageWarningNotice resource="traits" />
        <PageIntroText>
          Traits are interesting features about individuals and/or their
          households, sourced from both your data and the Faraday Identity
          Graph.
        </PageIntroText>
        <TraitsTable />
      </Container>
    </PageLayout>
  );
}
