import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactElement } from "react";
import { useMeasure } from "react-use";

import { theme } from "../../../constants/theme";
import { percent } from "../../../utils/formatters";
import { getLabelCoords, transitionStyles } from "./chartUtils";
import { AnalysisDataset, Field } from "./types";

const CHART_HEIGHT = 64;
const BAR_HEIGHT = 8;
const LABEL_FONT_SIZE = 14;

interface CategoricalBin {
  label: string;
  value: number;
}

type Dimensions = {
  barX: number;
  barWidth: number;
  value: number;
  label: string;
  labelX: number;
  labelFits: boolean;
  textAnchor: string;
}[];

/**
 * Given some bins, creates the bar widths and offsets and
 * all the label positioning values by measuring label text width.
 */
function getChartDimensions({
  bins,
  width,
  left,
  right,
}: {
  bins: CategoricalBin[];
  width: number;
  left: number;
  right: number;
}) {
  const gap = 2;
  const dimensions: Dimensions = [];
  let barX = 0;

  // Calculate total gap width
  const totalGapWidth = gap * (bins.length - 1);

  // Adjust the available width after accounting for the gaps
  const availableWidth = width - totalGapWidth;

  for (const bin of bins) {
    const { label, value } = bin;

    // Calculate barWidth based on the available width
    const barWidth = value * availableWidth;

    // assumed center point for the label
    const x = barX + barWidth / 2;

    const labelText = `${label} ${percent(value)}`;

    const { textAnchor, textWidth, overlap } = getLabelCoords({
      label: labelText,
      x,
      left,
      right,
    });

    // if label fits, we'll just show it
    const labelFits = textWidth < barWidth;

    // If overlapping, position the label 1/4 from edge
    // of bar so more of long labels fit in view.
    const labelX = {
      right: x + barWidth / 4,
      left: x - barWidth / 4,
      none: x,
    }[overlap];

    dimensions.push({
      textAnchor,
      labelFits,
      barX,
      barWidth,
      value: bin.value,
      label: bin.label,
      labelX,
    });

    barX += barWidth + gap;
  }

  return dimensions;
}

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Convert label from `value_THING` to `Thing`
 *
 * If field has categories, we can assume we're using FdyFields for the
 * new explore/beta UI. The analysis view has a slightly different calculation
 * format for these `value_` fields, so we need to handle that here.
 */
const toChartLabel = (k: string, field: Field) => {
  const labelOrCategoryIndex = k.replace(/^value_/, "");

  if (field.categories?.length) {
    const labelIndex = Number(labelOrCategoryIndex);
    return field.categories[labelIndex];
  }

  if (labelOrCategoryIndex.match(/^[A-Z]+$/)) {
    return capitalize(labelOrCategoryIndex);
  }

  return labelOrCategoryIndex;
};

function PercentBars({
  bins,
  dimensions,
  height,
  color,
}: {
  bins: CategoricalBin[];
  dimensions: Dimensions;
  height: number;
  color: string;
}) {
  return (
    <>
      {bins.map((_bin, i) => {
        const { barX, barWidth } = dimensions[i];
        return (
          <rect
            key={i}
            width={barWidth}
            height={height}
            x={barX}
            y={0}
            fill={color}
            style={transitionStyles}
          />
        );
      })}
    </>
  );
}

const StyledLabel = styled.text`
  // Unset cursor otherwise we get transitions beetween selection cursor
  // and pointer when hovering the label.
  pointer-events: none;
`;

const StyledLabelLine = styled.rect`
  opacity: 0;
`;

const StyledLabelGroup = styled.g<{ showLabelOnHover?: boolean }>`
  ${(p) =>
    p.showLabelOnHover &&
    css`
      ${StyledLabel},
      ${StyledLabelLine} {
        opacity: 0;
      }

      &:hover ${StyledLabel}, &:hover ${StyledLabelLine} {
        opacity: 1;
        transition: opacity ${theme.durations.quick};
      }
    `}
`;

function PercentBarLabels({
  bins,
  dimensions,
  vCenter,
}: {
  bins: CategoricalBin[];
  dimensions: Dimensions;
  vCenter: number;
}) {
  return (
    <>
      {bins.map(({ label, value }, i) => {
        const { barWidth, barX, labelX, labelFits, textAnchor } = dimensions[i];

        const percentLabel = percent(value);

        const connectorHeight = 8;

        // Align the label above the bar if it's hidden, so hovering to show does not
        // cause the label to collide with already visible labels.
        const labelY = labelFits
          ? BAR_HEIGHT + LABEL_FONT_SIZE + 4
          : // bar height, rough font height, and some margin
            BAR_HEIGHT - LABEL_FONT_SIZE - 4;

        return (
          <StyledLabelGroup key={i} showLabelOnHover={!labelFits}>
            {/* Render an invisible rect for larger
                hover zone to show label when mousing over bars. */}
            <rect
              width={barWidth}
              x={barX}
              y={-vCenter}
              height={CHART_HEIGHT}
              opacity={0}
            />
            {/* add a lil connector line from bar to label. Also only shown on hover. */}
            <StyledLabelLine
              x={labelX}
              y={-connectorHeight}
              height={connectorHeight}
              width={1}
              fill={theme.colors.light_gray}
            />
            <StyledLabel
              y={labelY}
              x={labelX}
              textAnchor={textAnchor}
              color={theme.colors.dark_gray}
              fontSize={14}
              opacity={labelFits ? 1 : 0}
            >
              <tspan fontWeight="600">{label}</tspan>{" "}
              <tspan>{percentLabel}</tspan>
            </StyledLabel>
          </StyledLabelGroup>
        );
      })}
    </>
  );
}

interface StackedPercentChartProps {
  color: string;
  data: AnalysisDataset;
  field: Field;
}

/**
 * Renders a single bar, split up by categories and their percentage of the total bar.
 * Labels render under each bar if they fit within the bar segment, otherwise hidden.
 * Hidden labels are shown above the bar on hover of their segment.
 */
export function StackedPercentChart({
  data,
  color,
  field,
}: StackedPercentChartProps): ReactElement {
  const [ref, chartBounds] = useMeasure();

  // The typedef for useMeasure's ref param is slightly wrong. Shim it until
  // it can be fixed. see: <https://github.com/streamich/react-use/issues/1264>
  const refShim = (el: HTMLElement | null) => {
    if (el) ref(el);
  };

  // Default width otherwise svg might render with negative numbers,
  // causing DOM warnings.
  const { width = 0, left, right } = chartBounds;

  // Get only `value_` keys from the dataset object since it contains `coverage`.
  const keys = Object.keys(data)
    .filter((k) => k.startsWith("value_"))
    .filter((k) => data[k]);

  const bins = keys
    .map((k) => ({
      label: toChartLabel(k, field),
      value: data[k],
    }))
    .sort((a, b) => a.value - b.value);

  // position main group so center of bar is center of chart height
  const vCenter = CHART_HEIGHT / 2 - BAR_HEIGHT / 2;

  const dimensions = getChartDimensions({ bins, width, left, right });

  return (
    <div ref={refShim}>
      <svg
        width={width}
        height={CHART_HEIGHT}
        viewBox={`0 0 ${width} ${CHART_HEIGHT}`}
      >
        <g transform={`translate(0, ${vCenter})`}>
          <rect
            width={width}
            height={BAR_HEIGHT}
            x={0}
            y={0}
            fill={theme.colors.lighter_gray}
          />
          <PercentBars
            height={BAR_HEIGHT}
            bins={bins}
            dimensions={dimensions}
            color={color}
          />
          <PercentBarLabels
            vCenter={vCenter}
            bins={bins}
            dimensions={dimensions}
          />
        </g>
      </svg>
    </div>
  );
}
