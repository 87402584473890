import { createContext, ReactElement, ReactNode, useContext } from "react";

import { AuthService } from "../../../services/authService";
import { NEVER_RESOLVE } from "../../../services/authService/base";
import { useAuthFactory } from "./useAuthFactory";

const AuthContext = createContext<AuthService | null>(null);

export function useAuth(): AuthService {
  const auth = useContext(AuthContext);
  if (!auth) throw new RangeError(`Couldn't find AuthService in React context`);
  return auth;
}

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps): ReactElement {
  const auth = useAuthFactory();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

/**
 * Set up mock provider for using in tests
 */

export const MOCK_AUTH_TOKEN = "mock-auth-token";
class MockAuthService extends AuthService {
  async getToken() {
    return MOCK_AUTH_TOKEN;
  }
  async logout() {
    return NEVER_RESOLVE;
  }
}

export function MockAuthProvider({
  children,
}: AuthProviderProps): ReactElement {
  const mockAuth = new MockAuthService({
    log: console,
  });

  return (
    <AuthContext.Provider value={mockAuth}>{children}</AuthContext.Provider>
  );
}
