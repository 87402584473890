import { ResourceType } from "@fdy/faraday-js";

import { UsagesQuery } from "../../components/settings/billing/__generated__/UsagesQuery";
import { typeAlias } from "../../constants/typeAlias";
import { useSojournerQuery } from "../../services/sojournerApolloClient";
import { usagesQuery } from "../settings/billing/usagesUtils";
import { Notice } from "../ui/Notice";

// turns enum into a string union type for easier use in the prop
type ResourceName = `${ResourceType}`;

const resources: Partial<Record<ResourceType, string>> = {
  cohorts: "cohorts.max_count",
  datasets: "datasets.max_count",
  targets: "targets.max_count",
  connections: "connections.max_count",
  outcomes: "outcomes.max_count",
  scopes: "scopes.max_count",
  streams: "streams.max_count",
  persona_sets: "persona_sets.max_count",
  recommenders: "recommenders.max_count",
};

/**
 * Warn user if a given resource has reached its usage limit
 *
 */
export function UsageWarningNotice({ resource }: { resource: ResourceName }) {
  const { data, error } = useSojournerQuery<UsagesQuery>(usagesQuery);

  if (error) throw error;

  const usages = data?.usages;

  const hasHitLimit = usages?.some((usage) => {
    if (usage.usage === null || usage.limit === null) return;
    if (usage.name === resources[resource]) {
      if (usage.usage >= usage.limit) {
        return true;
      }
    }
  });

  if (!hasHitLimit) return null;

  return (
    <Notice
      variant="warning"
      title="Usage limit reached."
      description={`You cannot create any more ${typeAlias[resource]}s. Delete some existing ones, or contact support to increase your limit.`}
      dismissable={false}
      sx={{ mb: 6 }}
    />
  );
}
