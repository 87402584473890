// Shared abstract base class for all embedded versions of Faraday.
// See https://github.com/faradayio/fdy/blob/master/docs/EMBEDDED_AUTH_DESIGN.md
// for more design considerations.

import { gql } from "@apollo/client";

import { assert } from "../../utils/assertions";
import { ApolloGQLService } from "../ApolloGQLService";
import { IdentityProviderQuery } from "./__generated__/IdentityProviderQuery";
import {
  AuthService,
  AuthServiceOptions,
  isTokenExpired,
  NEVER_RESOLVE,
} from "./base";
import { AuthError } from "./errors";

const identityProviderQuery = gql`
  query IdentityProviderQuery {
    account {
      id
      identity_provider {
        id
        route
      }
    }
  }
`;

/** Try to find a JWT token for the current user. */
export function findEmbeddedToken(): string | null {
  // Find our token.
  const hash = window.location.hash;
  if (hash && hash.match(/^#fdy_token=/)) {
    // We were passed a token in our URL hash.
    console.info("i found a fdy_token in the url");
    const [_, fdyToken] = hash.split("=", 2);
    window.location.hash = "";
    return fdyToken;
  } else {
    return null;
  }
}

/**
 * Partial information about an identity provider returned by type
 * `IdentityProviderQuery` generated from './identity_provider.query.gql'.
 *
 * The type expression means "take the type of the declared
 * `data.account.identity_provider` field, and exclude the possibility that it
 * might be `null` or `undefined`." This will return a partial query type that
 * only includes the relevant data loaded by `IdentityProviderQuery`.
 */
type IdentityProviderInfo = NonNullable<
  IdentityProviderQuery["account"]["identity_provider"]
>;

/**
 * Abstract base class for all different kinds of embedded authentication.
 */
export abstract class EmbeddedAuthService extends AuthService {
  /**
   * This is a string if we could find a token, or `null` if we couldn't. In the
   * latter case, we're probably just waiting for a redirect to actually happen.
   */
  private token: string | null;

  private identityProvider: IdentityProviderInfo | null;

  constructor(token: string | null, options: AuthServiceOptions) {
    super(options);
    this.token = token;
    this.identityProvider = null;

    // If we don't have a token, figure out what to do about it, if anything.
    if (!this.token) {
      // `handleMissingToken` returns a promise that would block forever, but we
      // can't block forever because we're a constructor and we can't use
      // `async`.
      const _promise: Promise<never> = this.handleMissingToken();
    }

    // Call our `onLogIn` handler if we have one.
    //
    // TODO: I'm not sure we should even call this, because `onLogIn` may be
    // very auth0-lock specific.
    if (this.onLogIn && this.token !== null) {
      this.onLogIn();
    }
  }

  async getToken(): Promise<string> {
    if (this.token === null) {
      // We don't have token, probably because a redirect is already in
      // progress. So just return a promise that never resolves.
      this.log.log("not returning from getToken because we don't have one");
      return NEVER_RESOLVE;
    } else if (isTokenExpired(this.token)) {
      // `handleExpiredToken` will trigger a redirect, which doesn't take
      // effect immediately. So stall for time until it does.
      return this.handleExpiredToken(this.token);
    } else {
      // Yay, we have a valid token!
      return this.token;
    }
  }

  /**
   * Look up the identity provider for the current user's account.
   *
   * This is cached.
   */
  protected async getIdentityProvider(
    gqlClient: ApolloGQLService
  ): Promise<IdentityProviderInfo> {
    if (this.identityProvider === null) {
      const { data, errors, loading } =
        await gqlClient.query<IdentityProviderQuery>({
          query: identityProviderQuery,
          fetchPolicy: "cache-first",
        });
      if (typeof errors !== "undefined" && errors.length > 0) {
        throw errors[0];
      }
      assert(
        !loading && data,
        "expected gqlClient.query to block until it had data"
      );
      const identity_provider = data.account.identity_provider;
      if (!identity_provider) {
        throw new AuthError(
          "[AuthError] expected identity provider for embedded account"
        );
      }
      this.identityProvider = identity_provider;
    }
    return this.identityProvider;
  }

  async redirectionIfRouteIsUnauthorized(
    gqlClient: ApolloGQLService,
    routeName: string
  ): Promise<string | null> {
    this.log.log("fetch identity provider");
    const identityProvider = await this.getIdentityProvider(gqlClient);
    this.log.log(
      `loaded identity provider with route ${identityProvider.route}`
    );
    if (routeName !== identityProvider.route) {
      return identityProvider.route;
    } else {
      return null;
    }
  }

  /**
   * We don't have a token, so decide what to do.
   *
   * This should never return.
   */
  protected abstract handleMissingToken(): Promise<never>;

  /**
   * Our token has expired, so decide what to do.
   *
   * This should never return.
   */
  protected abstract handleExpiredToken(token: string): Promise<never>;

  /**
   * Treat a logout as a missing token, because many logouts are triggered by
   * network errors.
   *
   * TODO: Is there a danger of a reload loop?
   */
  logout(): Promise<never> {
    return this.handleMissingToken();
  }
}
