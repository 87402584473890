import { useCallback, useState } from "react";

import { getApiBaseUrl } from "../../../services/getApiBaseUrl";
import { UploadState } from "./DatasetUploadDropZone";

type CsvUploadCallback = ({
  fileList,
  uploadDirectory,
  authToken,
}: {
  fileList: FileList;
  uploadDirectory: string;
  authToken: string;
}) => Promise<void>;

/**
 * when given a file (imported via DropZone), this will upload the file to the API /uploads endpoint
 * and provide status on the file upload.
 *
 * The api only supports one file uploaded at a time, so this doesn't have to support multiple either
 */
export function useCsvUploader(): {
  uploadState: UploadState;
  upload: CsvUploadCallback;
} {
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.idle);

  const upload = useCallback<CsvUploadCallback>(
    async ({ fileList, uploadDirectory, authToken }) => {
      if (!authToken) {
        setUploadState(UploadState.error);
        return;
      }
      if (!fileList || !fileList[0]) return;
      setUploadState(UploadState.uploading);
      const uploadedFile = fileList[0];

      // uploads endpoint has restrictions on file name and upload dir name
      // but we don't want to place those restrictions on the dataset name
      // (which is where we get the dir name from) and don't want to make them
      // rename their file just to upload it so, replace bad chars with _, and
      // replace any leading - with _
      const cleanFileName = uploadedFile.name
        .replace(/([^-A-Za-z0-9_.]+)/gi, "_")
        .replace(/^-/, "_");

      const cleanDirName = uploadDirectory
        .replace(/([^-A-Za-z0-9_]+)/gi, "_")
        .replace(/^-/, "_");

      // wrap in try/catch so we can handle non http status code errors that
      // happen when fetch calls
      try {
        await csvUploadFetch({
          token: authToken,
          fileContents: uploadedFile,
          directory: cleanDirName,
          filename: cleanFileName,
        });

        setUploadState(UploadState.done);
      } catch (err) {
        setUploadState(UploadState.error);

        throw err;
      }
    },
    [uploadState]
  );

  return {
    uploadState,
    upload,
  };
}

/**
 * it is not recommended to use graphql for file uploads
 * so we use fetch here instead
 */
async function csvUploadFetch({
  directory,
  filename,
  fileContents,
  token,
}: {
  directory: string;
  filename: string;
  fileContents: File;
  token: string;
}) {
  const baseUrl = getApiBaseUrl();
  const resp = await fetch(`${baseUrl}/uploads/${directory}/${filename}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
    body: fileContents,
    method: "POST",
  });

  // Any http status code errors will be caught here.
  // NOT errors like cors or network errors. Fetch will throw those automatically.
  if (!resp.ok) {
    throw new Error(resp.statusText);
  }

  return resp;
}
