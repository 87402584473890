import { Box, CSSObject } from "@chakra-ui/react";
import * as React from "react";

interface InlineButtonsProps {
  children: React.ReactNode;

  /** swap flexbox order of  all children, so pressing tab goes to button on right first */
  reverse?: boolean;

  sx?: CSSObject;
}

/**
 * Render a chakra button group with Faraday style specifics.
 *
 * The v1 chakra button group doesn't support theming to set these properties,
 * so we need a component.
 */
export function InlineButtons({
  reverse,
  children,
  sx,
  ...rest
}: InlineButtonsProps) {
  const styles: CSSObject = {
    ...sx,
    display: "flex",
    gap: 6,
    width: "100%",
    // force inside buttons to fill area
    "> .chakra-button": {
      width: "100%",
    },
    flexDirection: reverse ? "row-reverse" : "row",
  };

  return (
    <Box {...rest} sx={styles}>
      {children}
    </Box>
  );
}
