export const API_ENABLED = "api.enabled";
export const COHORTS_ENABLED = "cohorts.enabled";
export const CONNECTIONS_ENABLED = "connections.enabled";
export const CUSTOM_GEOGRAPHY_ENABLED = "custom_geography.enabled";
export const DASHBOARD_ENABLED = "dashboard.enabled";
export const DATASETS_ENABLED = "datasets.enabled";
export const EXPLORE_ENABLED = "explore.enabled";
export const EVENTS_ENABLED = "events.enabled";
export const FILES_ENABLED = "files.enabled";
export const INFORM_ENABLED = "inform.enabled";
export const LOCATIONS_ENABLED = "locations.enabled";
export const MEMBERS_ENABLED = "members.enabled";
export const OUTCOMES_ENABLED = "outcomes.enabled";
export const PERSONAS_ENABLED = "personas.enabled";
export const PERSONAS_HIDE_PRODUCT_ADOPTION = "personas.hide_product_adoption";
export const PIPELINES_ENABLED = "pipelines.enabled";
export const RECIPES_ENABLED = "recipes.enabled";
export const RECOMMENDERS_ENABLED = "recommenders.enabled";
export const SOURCES_ENABLED = "sources.enabled";
export const TARGETS_MAX_UNKNOWN = "targets.unknown_people_limit";
export const TRAITS_ENABLED = "traits.enabled";
export const NON_PREVIEW_SCOPES_ENABLED = "scopes.non_preview_allowed";
export const COHORT_MEMBERSHIP_SCOPES_ENABLED = "scopes.cohort_membership_allowed"; // prettier-ignore
export const SCOPES_EVERYBODY_ALLOWED = "scopes.everybody_allowed";
export const SCOPES_EXPLAINABILITY_ENABLED = "scopes.explainability_enabled";
export const TARGETS_IDENTIFIED_ALLOWED = "targets.identified_allowed";
export const ADDRESS_ONLY_DATA_ENABLED = "address_only_data.enabled";
export const IDENTITY_ENRICHMENT_ENABLED = "identity_enrichment.enabled";
