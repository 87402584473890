import {
  Box,
  Flex,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";
import { ReactElement } from "react";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";
import { Input } from "./Input";

const styles = {
  heading: {
    fontSize: "fdy_md",
    fontWeight: "bold",
    color: "fdy_gray.800",
    _checked: {
      color: "fdy_purple.500",
      opacity: 1,
    },
  },
};

export interface RadioTileOptions {
  /**
   * Each radio button in the radio tile set should be configured with a RadioTileOption
   */

  /**
   * the value that will be submitted to the form if this radio button is selected
   */
  value: string;
  /**
   * the visible label for this radio button
   */
  label: string;
  /**
   * more details explaining this radio button, that will be displayed in the card
   */
  description: string;
  /**
   * an optional additional UI element in the top-right of the card
   */
  rightContent: ReactElement | undefined;
  /**
   * an optional analytics name for this radio button
   */
  analyticsName?: string;
}

interface RadioTilesProps {
  /**
   * the configuration options for each tile (see RadioTileOptions)
   */
  options: RadioTileOptions[];
  /**
   * the name of the radio group
   */
  name: string;
  /**
   * function to call when a new radio button is selected
   */
  onChange: (value: string) => void;
  /**
   * which radio button is selected
   */
  selected: string | undefined;
  /**
   * an optional analytics name for the radio group
   */
  analyticsName?: string;
}

/**
 * A single card in a set of RadioTiles
 */
function RadioCard(
  props: UseRadioProps & {
    rightContent: ReactElement | undefined;
    label: string;
    children: string | ReactElement;
    analyticsName?: string;
  }
) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const dot = (
    <Box
      {...checkbox}
      cursor="pointer"
      border="2px solid"
      borderColor="fdy_gray.500"
      borderRadius="full"
      width={4}
      height={4}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _checked={{
        borderColor: "fdy_purple.500",
        opacity: 1,
      }}
    >
      <Box
        {...checkbox}
        width={2}
        height={2}
        borderRadius="full"
        _checked={{
          background: "fdy_purple.500",
          cursor: "pointer",
          boxShadow: "md",
          opacity: 1,
        }}
      />
    </Box>
  );

  return (
    <Box as="label">
      <Input {...input} analyticsName={props.analyticsName} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        borderRadius="md"
        boxShadow="md"
        width={350}
        height="100%"
        _checked={{
          color: "fdy_purple.500",
          borderColor: "fdy_purple.500",
          opacity: 1,
        }}
        px={3}
        py={3}
      >
        <Flex alignItems="start" gap={2}>
          <Box>{dot}</Box>
          <Box width="100%">
            <Flex mb={1}>
              <Text as="span" {...checkbox} sx={styles.heading}>
                {props.label}
              </Text>
              <Box ml="auto">{props.rightContent}</Box>
            </Flex>
            {props.children}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

/**
 * Creates a set of radio buttons that look like cards.
 * The card format allows more detail to be included for each option,
 * while still being accessible
 */
export function RadioTiles({
  options = [],
  name,
  onChange,
  selected,
  analyticsName,
}: RadioTilesProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    value: selected,
  });

  const group = getRootProps();

  const analyticsKey = useAnalyticsKey(analyticsName);

  return (
    <Flex
      {...group}
      wrap="wrap"
      gap={4}
      mt={4}
      {...analyticsAttrs(analyticsKey)}
    >
      {options.map((opt) => {
        const radio = getRadioProps({
          value: opt.value,
        });
        return (
          <RadioCard
            key={opt.value}
            label={opt.label}
            rightContent={opt.rightContent}
            analyticsName={opt.analyticsName}
            {...radio}
          >
            {opt.description}
          </RadioCard>
        );
      })}
    </Flex>
  );
}
