import { PrimitiveDataType } from "@fdy/faraday-js";

import { components } from "../../../../../sojourner-oas-types";
import { DatasetSelectOption } from "../../../shared/reactSelectStyle";

export type DataMapColumnFormat = components["schemas"]["DataMapColumnFormat"];

export interface DataMapFormatOption extends DatasetSelectOption {
  value: string;
  label: string;
  type: "string" | "date" | "number" | "list";
  deprecated?: true;
  /**
   * Date-fns format string we use for testing dates.
   * From https://date-fns.org/v3.6.0/docs/isMatch
   */
  dateFnsFormat?: string;
}

export const dataMapFormats: Record<DataMapColumnFormat, DataMapFormatOption> =
  {
    currency_cents: {
      value: "currency_cents",
      label: "cents",
      type: "number",
    },
    currency_dollars: {
      value: "currency_dollars",
      label: "dollars",
      type: "number",
    },
    yyyymm: {
      value: "yyyymm",
      label: "YYYYMM",
      type: "date",
      dateFnsFormat: "yyyyMM",
    },
    yyyymmdd: {
      value: "yyyymmdd",
      label: "YYYYMMDD",
      type: "date",
      dateFnsFormat: "yyyyMMdd",
    },
    date_seconds_since_epoch_utc: {
      value: "date_seconds_since_epoch_utc",
      label: "seconds since Jan 1, 1970",
      type: "date",
      dateFnsFormat: "t",
    },
    date_milliseconds_since_epoch_utc: {
      value: "date_milliseconds_since_epoch_utc",
      label: "milliseconds since Jan 1, 1970",
      type: "date",
      dateFnsFormat: "T",
    },
    mm_dd_yy_slash: {
      value: "mm_dd_yy_slash",
      label: "MM/DD/YY",
      type: "date",
      dateFnsFormat: "MM/dd/yy",
    },
    mm_dd_yyyy_slash: {
      value: "mm_dd_yyyy_slash",
      label: "MM/DD/YYYY",
      type: "date",
      dateFnsFormat: "MM/dd/yyyy",
    },
    mm_dd_yy_dash: {
      value: "mm_dd_yy_dash",
      label: "MM-DD-YY",
      type: "date",
      dateFnsFormat: "MM-dd-yy",
    },
    mm_dd_yyyy_dash: {
      value: "mm_dd_yyyy_dash",
      label: "MM-DD-YYYY",
      type: "date",
      dateFnsFormat: "MM-dd-yyyy",
    },
    yyyy_mm_dd_slash: {
      value: "yyyy_mm_dd_slash",
      label: "YYYY/MM/DD",
      type: "date",
      dateFnsFormat: "yyyy/MM/dd",
    },
    yy_mm_dd_slash: {
      value: "yy_mm_dd_slash",
      label: "YY/MM/DD",
      type: "date",
      dateFnsFormat: "yy/MM/dd",
    },
    yyyy_mm_dd_dash: {
      value: "yyyy_mm_dd_dash",
      label: "YYYY-MM-DD",
      type: "date",
      dateFnsFormat: "yyyy-MM-dd",
    },
    yy_mm_dd_dash: {
      value: "yy_mm_dd_dash",
      label: "YY-MM-DD",
      type: "date",
      dateFnsFormat: "yy-MM-dd",
    },
    dd_mm_yyyy_slash: {
      value: "dd_mm_yyyy_slash",
      label: "DD/MM/YYYY",
      type: "date",
      dateFnsFormat: "dd/MM/yyyy",
    },
    dd_mm_yy_slash: {
      value: "dd_mm_yy_slash",
      label: "DD/MM/YY",
      type: "date",
      dateFnsFormat: "dd/MM/yy",
    },
    dd_mm_yyyy_dash: {
      value: "dd_mm_yyyy_dash",
      label: "DD-MM-YYYY",
      type: "date",
      dateFnsFormat: "dd-MM-yyyy",
    },
    dd_mm_yy_dash: {
      value: "dd_mm_yy_dash",
      label: "DD-MM-YY",
      type: "date",
      dateFnsFormat: "dd-MM-yy",
    },
    date_iso8601: {
      value: "date_iso8601",
      label: "YYYY-MM-DD (deprecated)",
      deprecated: true,
      type: "date",
      dateFnsFormat: "yyyy-MM-dd",
    },
    date_month_day_fullyear: {
      value: "date_month_day_fullyear",
      label: "MM/DD/YYYY (deprecated)",
      deprecated: true,
      type: "date",
      dateFnsFormat: "MM/dd/yyyy",
    },
    date_month_day_shortyear: {
      value: "date_month_day_shortyear",
      label: "MM/DD/YY (deprecated)",
      deprecated: true,
      type: "date",
      dateFnsFormat: "MM/dd/yy",
    },
    date_month_day_fullyear_hours_minutes: {
      value: "date_month_day_fullyear_hours_minutes",
      label: "MM/DD/YYYY hh:mm (deprecated)",
      deprecated: true,
      type: "date",
      dateFnsFormat: "MM/dd/yyyy H:m",
    },
    date_month_day_fullyear_hours_minutes_seconds: {
      value: "date_month_day_fullyear_hours_minutes_seconds",
      label: "MM/DD/YYYY hh:mm:ss (deprecated)",
      deprecated: true,
      type: "date",
      dateFnsFormat: "MM/dd/yyyy H:m:s",
    },
    list_comma_separated: {
      value: "list_comma_separated",
      label: "comma separated [blouse,shirt,pants]",
      type: "list",
    },
    list_semicolon_separated: {
      value: "list_semicolon_separated",
      label: "semicolon separated [blouse;shirt;pants]",
      type: "list",
    },
    list_single_value: {
      value: "list_single_value",
      label: "single value in array [pants]",
      type: "list",
    },
    yyyy_mm_dash: {
      value: "yyyy_mm_dash",
      label: "YYYY-MM",
      type: "date",
      dateFnsFormat: "yyyy-MM",
    },
    yyyy_mm_slash: {
      value: "yyyy_mm_slash",
      label: "YYYY/MM",
      type: "string",
      dateFnsFormat: "yyyy/MM",
    },
    static_date_iso8601: {
      value: "static_date_iso8601",
      label: "YYYY-MM-DD",
      type: "string",
      dateFnsFormat: "yyyy-MM-dd",
    },
  };

export const datasetFormatOptionsByDataType: Record<
  PrimitiveDataType,
  DataMapFormatOption[]
> = {
  double: [
    dataMapFormats.currency_cents,
    dataMapFormats.currency_dollars,
    dataMapFormats.yyyymm,
    dataMapFormats.yyyymmdd,
  ],
  long: [
    dataMapFormats.currency_cents,
    dataMapFormats.currency_dollars,
    dataMapFormats.date_seconds_since_epoch_utc,
    dataMapFormats.date_milliseconds_since_epoch_utc,
    dataMapFormats.yyyymm,
    dataMapFormats.yyyymmdd,
  ],
  string: [
    dataMapFormats.mm_dd_yy_slash,
    dataMapFormats.mm_dd_yyyy_slash,
    dataMapFormats.mm_dd_yy_dash,
    dataMapFormats.mm_dd_yyyy_dash,
    dataMapFormats.yyyy_mm_dd_slash,
    dataMapFormats.yy_mm_dd_slash,
    dataMapFormats.yyyy_mm_dd_dash,
    dataMapFormats.yy_mm_dd_dash,
    dataMapFormats.yyyymm,
    dataMapFormats.yyyymmdd,
    dataMapFormats.dd_mm_yyyy_slash,
    dataMapFormats.dd_mm_yy_slash,
    dataMapFormats.dd_mm_yyyy_dash,
    dataMapFormats.dd_mm_yy_dash,
    dataMapFormats.date_iso8601,
    dataMapFormats.date_month_day_fullyear,
    dataMapFormats.date_month_day_shortyear,
    dataMapFormats.date_month_day_fullyear_hours_minutes,
    dataMapFormats.date_month_day_fullyear_hours_minutes_seconds,
    dataMapFormats.date_seconds_since_epoch_utc,
    dataMapFormats.date_milliseconds_since_epoch_utc,
    dataMapFormats.list_comma_separated,
    dataMapFormats.list_semicolon_separated,
    dataMapFormats.list_single_value,
    dataMapFormats.currency_cents,
    dataMapFormats.currency_dollars,
  ],
  boolean: [],
  date: [],
};
