import {
  CohortStreamConditionInput,
  CohortTraitInput,
} from "../../../../__generated__/sojournerGlobalTypes";
import {
  ConditionRow,
  ConditionsBuilder,
  TEXT_OPERATORS,
} from "../../../ui/ConditionsBuilder";
import { CohortFilterEditorProps } from ".";

export function CohortFilterEditorText<
  CohortConditionInput extends CohortTraitInput | CohortStreamConditionInput
>({
  condition,
  onChange,
  conditionsToWire,
  conditionsFromWire,
}: CohortFilterEditorProps<CohortConditionInput>) {
  function handleConditionBuilderChange(conditions: ConditionRow[]) {
    onChange(conditionsToWire(condition, conditions));
  }

  const init = conditionsFromWire(condition);

  if (init.length === 0) {
    init.push({
      operator: "nnull",
      type: "boolean",
      value: true,
    });
  }

  return (
    <ConditionsBuilder
      operators={TEXT_OPERATORS}
      initialConditions={init}
      onChange={handleConditionBuilderChange}
      largeCsvInput
    />
  );
}
