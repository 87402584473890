import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

/**
 * Wrapper for separating vertical stack of cards with a gap.
 * To be used with CardV2.
 *
 * @example
 * <CardStack>
 *  <CardV2>...</CardV2>
 *  <CardV2>...</CardV2>
 * </CardStack>
 */
export function CardStack({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: "grid",
        gap: 8,
      }}
    >
      {children}
    </Box>
  );
}
