import {
  ConditionRow,
  Operator,
  OperatorGroup,
  OperatorValue,
} from "../../../../ui/ConditionsBuilder/types";
import { TargetFragment_filter } from "../../__generated__/TargetFragment";
import { TargetFilterRow, TargetFilterState, TargetFilterType } from "./types";

// Target filter operators are slightly different depending on the type of the
// target filter. This mapping is used to determine which operators are valid
// for a given target filter type.
// We only need mappings for outcomes and traits, since cohorts and personas
// mostly filter by IDs, not values like numbers or strings.
type OperatorMapping = Partial<Record<OperatorGroup, Operator[]>>;

const NUMERIC_OPERATOR_GROUPS: OperatorMapping = {
  numeric: ["eq", "gt", "lt", "gte", "lte"],
  boolean: ["null", "nnull"],
  list: ["in", "nin"],
  text: ["matches"],
};

const TRAIT_OPERATOR_GROUPS: OperatorMapping = {
  numeric: ["gt", "lt", "gte", "lte"],
  text: ["eq", "neq", "matches"],
  boolean: ["null", "nnull"],
  list: ["in", "nin"],
};

// find the key in the mapping that contains the operator
function findOperatorGroup(
  mapping: OperatorMapping,
  operator: Operator
): OperatorGroup {
  const group = Object.keys(mapping).find((key) => {
    const operators = mapping[key as OperatorGroup];
    return operators?.includes(operator);
  });

  if (!group)
    throw new Error(
      `Operator ${operator} not found in mapping ${JSON.stringify(mapping)}`
    );

  return group as OperatorGroup;
}

/**
 * Convert a condition object from the target filter wire format to the
 * conditions builder format.
 */
function targetConditionToConditionRows(
  mapping: OperatorMapping,
  conditions: Partial<Record<Operator, OperatorValue>>
): ConditionRow[] {
  const rows: ConditionRow[] = [];

  for (const [operator, value] of Object.entries(conditions)) {
    const type = findOperatorGroup(mapping, operator as Operator);

    if (value === null || (Array.isArray(value) && value.length === 0))
      continue;

    rows.push({
      type,
      operator,
      value,
      // fudging a lot of types here, but otherwise it feels like more effort
      // than it's worth to get the types right
    } as ConditionRow);
  }

  return rows;
}

type FilterConditions = TargetFragment_filter[TargetFilterType][number];
type Transformer = (condition: FilterConditions) => TargetFilterRow;

const transformFilterTraitsToFilterRows: Transformer = (condition) => {
  if (condition.__typename !== "TargetFilterTraitConditions")
    throw new Error("Expected trait condition");

  const { __typename, name, ...operators } = condition;

  const rowConditions = targetConditionToConditionRows(
    TRAIT_OPERATOR_GROUPS,
    operators
  );

  return {
    type: "trait",
    payload_elem_id: name,
    conditions: rowConditions,
  };
};

const transformFilterPersonasToFilterRows: Transformer = (condition) => {
  if (condition.__typename !== "TargetFilterPersonaSetPersonaIdConditions")
    throw new Error("Expected persona condition");

  const { eq, personaId, personaSetId } = condition;

  // persona only has one true operator (eq), but for the UI we provide (neq) to give the user a clear option to include/exclude a persona
  return {
    type: "persona",
    payload_elem_id: personaSetId,
    conditions: [
      {
        type: "text",
        operator: eq ? "eq" : "neq",
        value: personaId,
      },
    ],
  };
};

const transformFilterOutcomeScoresToFilterRows: Transformer = (conditions) => {
  if (conditions.__typename !== "TargetFilterOutcomeScoreConditions")
    throw new Error("Expected outcome score condition");

  const { __typename, outcomeId, ...operators } = conditions;

  const rowConditions = targetConditionToConditionRows(
    NUMERIC_OPERATOR_GROUPS,
    operators
  );

  return {
    type: "outcomeScore",
    payload_elem_id: conditions.outcomeId,
    conditions: rowConditions,
  };
};

const transformFilterOutcomeProbabilitiesToFilterRows: Transformer = (
  conditions
) => {
  if (conditions.__typename !== "TargetFilterOutcomeProbabilityConditions")
    throw new Error("Expected outcome probability condition");

  const { __typename, outcomeId, ...operators } = conditions;

  const rowConditions = targetConditionToConditionRows(
    NUMERIC_OPERATOR_GROUPS,
    operators
  );

  return {
    type: "outcomeProbability",
    payload_elem_id: conditions.outcomeId,
    conditions: rowConditions,
  };
};

const transformFilterOutcomePercentilesToFilterRows: Transformer = (
  condition
) => {
  if (condition.__typename !== "TargetFilterOutcomePercentileConditions")
    throw new Error("Expected outcome percentile condition");

  const { __typename, outcomeId, ...operators } = condition;

  const rowConditions = targetConditionToConditionRows(
    NUMERIC_OPERATOR_GROUPS,
    operators
  );

  return {
    type: "outcomePercentile",
    payload_elem_id: condition.outcomeId,
    conditions: rowConditions,
  };
};

const transformFilterCohortMembershipToFilterRows: Transformer = (
  condition
) => {
  if (condition.__typename !== "TargetFilterCohortMembershipConditions")
    throw new Error("Expected cohort membership condition");

  const { cohortId } = condition;

  return {
    type: "cohortMembership",
    payload_elem_id: cohortId,
    conditions: [
      {
        type: "text",
        operator: "eq",
        value: condition.eq ? "true" : "false",
      },
    ],
  };
};

const transformFilterRecommenderRankToFilterRows: Transformer = (
  conditions
) => {
  if (conditions.__typename !== "TargetFilterRecommenderRankConditions")
    throw new Error("Expected recommender rank condition");

  const { __typename, recommenderId, ...operators } = conditions;

  const rowConditions = targetConditionToConditionRows(
    NUMERIC_OPERATOR_GROUPS,
    operators
  );

  return {
    type: "recommenderRank",
    payload_elem_id: conditions.recommenderId,
    conditions: rowConditions,
  };
};

const transformFilterRecommenderUncalibratedProbabilityToFilterRows: Transformer =
  (conditions) => {
    if (
      conditions.__typename !==
      "TargetFilterRecommenderUncalibratedProbabilityConditions"
    )
      throw new Error(
        "Expected recommender uncalibrated probability condition"
      );

    const { __typename, recommenderId, ...operators } = conditions;

    const rowConditions = targetConditionToConditionRows(
      NUMERIC_OPERATOR_GROUPS,
      operators
    );

    return {
      type: "recommenderUncalibratedProbability",
      payload_elem_id: conditions.recommenderId,
      conditions: rowConditions,
    };
  };

const conditionTransformers: Record<TargetFilterType, Transformer> = {
  cohortMembership: transformFilterCohortMembershipToFilterRows,
  outcomePercentile: transformFilterOutcomePercentilesToFilterRows,
  outcomeScore: transformFilterOutcomeScoresToFilterRows,
  outcomeProbability: transformFilterOutcomeProbabilitiesToFilterRows,
  recommenderRank: transformFilterRecommenderRankToFilterRows,
  recommenderUncalibratedProbability:
    transformFilterRecommenderUncalibratedProbabilityToFilterRows,
  persona: transformFilterPersonasToFilterRows,
  trait: transformFilterTraitsToFilterRows,
};

/**
 * Transform a target filter from the wire format (API response) to the editable UI state format.
 */
export function targetFilterFromWire(
  targetFilter: TargetFragment_filter | null
): TargetFilterState {
  if (!targetFilter) return [];

  const rows: TargetFilterState = [];

  Object.entries(conditionTransformers).forEach(([filterType, transformer]) => {
    const conditionRows = targetFilter[filterType as TargetFilterType];
    conditionRows?.forEach((condition) => {
      rows.push(transformer(condition));
    });
  });

  return rows;
}
