// FIXME: perhaps we can combine this with dataset's ClassicExplanation?
// they're mostly the same, but some of the wording is different, which may justify keeping them separate

import { ChatLink } from "../ui/ChatLink";
import { Notice } from "../ui/Notice";

/*
 * Explain what a "managed" resource is, and how to change it.
 */
export function ManagedExplanation({ resourceType }: { resourceType: string }) {
  return (
    <Notice
      variant="warning"
      title={`Managed ${resourceType}`}
      description={
        <>
          This {resourceType} is managed by Faraday, so it is read-only. Please{" "}
          <ChatLink>contact support</ChatLink> if you would like to edit it.
        </>
      }
      sx={{ my: 4 }}
    />
  );
}
