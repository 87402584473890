import { Link, Text } from "@chakra-ui/react";
import {
  ScopeAnalysisPayloadResource,
  ScopeAnalysisProbabilityDistributionDatum,
} from "@fdy/faraday-js";
import { BookOpen, CheckCircle, ClockCountdown } from "@phosphor-icons/react";
import { LineSeries } from "@visx/xychart";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { colors } from "../../../styles/chakra-theme-v2";
import { singular } from "../../../utils/singular";
import { AccordionV2 } from "../../ui/AccordionV2";
import { CardV2 } from "../../ui/Card/CardV2";
import { AxisBottom } from "../../ui/charts-v2/AxisBottom";
import { AxisLeft } from "../../ui/charts-v2/AxisLeft";
import { Chart } from "../../ui/charts-v2/Chart";
import { IconWithText } from "../../ui/IconWithText";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { ResourceLink } from "../../ui/ResourceLink";
import { PipelineFragment as Scope } from "../__generated__/PipelineFragment";
import { useScopeAnalysisQuery } from "./useScopeAnalysisQuery";
import {
  useScopePayloadOutcomesQuery,
  useScopePayloadRecommendersQuery,
} from "./useScopePayloadQuery";

const PipelinesDiagnosticsDocsLink = () => (
  <Link href="https://faraday.ai/docs/abstractions/pipelines#pipeline-diagnostics">
    <IconWithText>
      <BookOpen />
      Read more about this check
    </IconWithText>
  </Link>
);

const ProbabilityDistributionChart = ({
  data,
  yLabel,
  xLabel,
}: {
  data: ScopeAnalysisProbabilityDistributionDatum[];
  yLabel: string;
  xLabel: string;
}) => {
  return (
    <Chart
      xScale={{
        type: "linear",
        zero: false,
      }}
      yScale={{ type: "linear", zero: false }}
      height={400}
      legend={{
        items: [
          {
            shape: "line",
            color: colors.fdy_purple[500],
            label: "Pipeline population",
          },
          {
            shape: "line",
            color: colors.fdy_purple[500],
            label: "Eligible population",
            dashed: true,
          },
        ],
      }}
    >
      <LineSeries
        dataKey="scope"
        data={data}
        xAccessor={(d) => d.bin_mid_point}
        yAccessor={(d) => d.scope}
        stroke={colors.fdy_purple[500]}
      />
      <LineSeries
        dataKey="eligible"
        data={data}
        xAccessor={(d) => d.bin_mid_point}
        yAccessor={(d) => d.eligible}
        stroke={colors.fdy_purple[500]}
        strokeDasharray="2,6"
      />
      <AxisLeft label={yLabel} />
      <AxisBottom label={xLabel} />
    </Chart>
  );
};

const DiagnosticChartHelpText = ({
  resource,
}: {
  resource: {
    id: string;
    name: string;
    resource_type: string;
  };
}) => {
  const resourceName = singular(resource.resource_type);

  return (
    <>
      <Text py={4}>
        <strong>The two curves on this graph should be similar.</strong>{" "}
        Differently shaped curves indicates that this pipeline’s population does
        not resemble your{" "}
        <ResourceLink resource={resource} sx={{ fontWeight: 600 }} />{" "}
        {resourceName}’s eligible population.
      </Text>
      <PipelinesDiagnosticsDocsLink />
    </>
  );
};

const OutcomeDiagnosticItem = ({
  scope,
  resource,
}: {
  scope: Scope;
  resource: ScopeAnalysisPayloadResource;
}) => {
  const outcomesQuery = useScopePayloadOutcomesQuery(scope.id);
  const outcome = outcomesQuery.data?.find((o) => o.id === resource.id);
  if (!outcome) {
    console.warn("Outcome not found in scope payload");
    return null;
  }

  return (
    <AccordionV2.Item
      title={
        <IconWithText>
          <ResourceIcon.outcomes />
          {outcome.name}
        </IconWithText>
      }
      subtitle="probability distribution"
      subtitleDivider=" "
    >
      <ProbabilityDistributionChart
        yLabel="Portion of people"
        xLabel={`Probability of attaining ${outcome.name}`}
        data={resource.probability_distribution}
      />
      <DiagnosticChartHelpText resource={outcome} />
    </AccordionV2.Item>
  );
};

const RecommenderDiagnostic = ({
  scope,
  resource,
}: {
  scope: Scope;
  resource: ScopeAnalysisPayloadResource;
}) => {
  const recommendersQuery = useScopePayloadRecommendersQuery(scope.id);
  const recommender = recommendersQuery.data?.find((r) => r.id === resource.id);
  if (!recommender) {
    console.warn("Recommender not found in scope payload");
    return null;
  }

  return (
    <AccordionV2.Item
      title={
        <IconWithText>
          <ResourceIcon.recommenders />
          {recommender.name}
        </IconWithText>
      }
      subtitle="probability distribution"
      subtitleDivider=" "
    >
      <ProbabilityDistributionChart
        xLabel={`Probability of the ${recommender.name} recommendation occurring next`}
        yLabel="Portion of recommendations"
        data={resource.probability_distribution}
      />
      <DiagnosticChartHelpText resource={recommender} />
    </AccordionV2.Item>
  );
};

const ScopeDiagnostics = ({ scope }: { scope: Scope }) => {
  const { data } = useScopeAnalysisQuery(scope.id);

  if (
    scope.status !== ResourceStatus.READY ||
    (!data?.outcomes?.length && !data?.recommenders?.length)
  ) {
    return (
      <IconWithText>
        <ClockCountdown weight="fill" color={colors.fdy_gray[400]} size={24} />
        <Text>
          <strong>Pending.</strong> Pipeline needs to finish building or rebuild
          to see diagnostics.
        </Text>
      </IconWithText>
    );
  }

  return (
    <AccordionV2 defaultIndex={0}>
      {data?.outcomes?.map((outcome) => (
        <OutcomeDiagnosticItem
          key={outcome.id}
          scope={scope}
          resource={outcome}
        />
      ))}
      {data?.recommenders?.map((recommender) => (
        <RecommenderDiagnostic
          key={recommender.id}
          scope={scope}
          resource={recommender}
        />
      ))}
    </AccordionV2>
  );
};

const GoodDiagnosticsNote = () => {
  return (
    <IconWithText>
      <CheckCircle weight="fill" color={colors.fdy_green[600]} size={24} />
      <Text>
        <strong>You’re good</strong>. No checks necessary for this pipeline.
      </Text>
    </IconWithText>
  );
};

export function PipelineDiagnosticsCard({ scope }: { scope: Scope }) {
  const showDiagnostics =
    scope.payload.outcomeIds.length > 0 ||
    scope.payload.recommenderIds.length > 0;

  return (
    <CardV2
      title="Diagnostics"
      text="These checks help you ensure your pipeline is correctly configured."
    >
      {showDiagnostics ? (
        <ScopeDiagnostics scope={scope} />
      ) : (
        <GoodDiagnosticsNote />
      )}
    </CardV2>
  );
}
