import { ButtonGroup, Container } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { AlertStack } from "../ui/AlertStack";
import { DependencyStatusAlert } from "../ui/DependencyStatusAlert";
import { DocsLink } from "../ui/DocsLink";
import { Breadcrumb, PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { ResourceArchivedAlert } from "../ui/ResourceStatus/ResourceArchivedAlert";
import { ResourceStatusAlert } from "../ui/ResourceStatus/ResourceStatusAlert";
import { SidebarLayout, useSidebarLayoutState } from "../ui/SidebarLayout";
import { OutcomesQuery_outcomes } from "./__generated__/OutcomesQuery";
import { OutcomeInfoSidebar } from "./OutcomeInfoSidebar";
import { OutcomeActionsMenu } from "./OutcomesActionsMenu";

/**
 * Renders the layout that wraps outcome create, edit, and show pages.
 */
export function OutcomeLayout({
  outcome,
  crumbs,
  backBtnProps,
  children,
}: {
  outcome?: OutcomesQuery_outcomes;
  crumbs: Breadcrumb[];
  backBtnProps: {
    routeName: string;
    label: string;
    routeParams?: Record<string, string>;
  };
  children: ReactNode;
}) {
  const sidebarProps = useSidebarLayoutState("outcomes-right", {
    defaultIsOpen: false,
  });
  const router = useRouter();

  const actions = (
    <ButtonGroup>
      <DocsLink resourceType="outcomes" size="sm" />
      <OutcomeActionsMenu
        outcome={outcome}
        options={{
          info: sidebarProps.onToggle,
          technical_report: true,
          rename: true,
          api: true,
          delete: true,
          archive: true,
        }}
        onDeleteComplete={() => {
          router.navigate(ROUTE_NAMES.OUTCOMES);
        }}
      />
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="outcomes">
      <PageHeader
        title={outcome?.name ?? "New outcome"}
        rightContent={actions}
        crumbs={crumbs}
        backBtnProps={backBtnProps}
      />
      <SidebarLayout
        rightSidebarProps={{
          isOpen: sidebarProps.isOpen,
          onClose: sidebarProps.onToggle,
          title: "About outcomes",
          children: <OutcomeInfoSidebar />,
          width: 400,
        }}
      >
        <Container py={6}>
          {outcome && (
            <AlertStack>
              <ResourceArchivedAlert resource={outcome} />
              <DependencyStatusAlert resource={outcome} />
              <ResourceStatusAlert resource={outcome} />
            </AlertStack>
          )}

          {children}
        </Container>
      </SidebarLayout>
    </PageLayout>
  );
}
