import { gql } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_CREATED_PIPELINE,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { Blankslate } from "../ui/Blankslate";
import { UsageWarningNotice } from "../ui/UsageWarningNotice";
import {
  PipelineCreateMutation,
  PipelineCreateMutationVariables,
} from "./__generated__/PipelineCreateMutation";
import { PipelineForm, PipelineFormState } from "./PipelineForm/PipelineForm";
import { PipelineLayout } from "./PipelineLayout";
import { pipelinesQuery } from "./pipelinesQuery";

export const pipelineCreateMutation = gql`
  mutation PipelineCreateMutation($input: ScopePostInput!) {
    createScope(scopePostInput: $input) {
      id
      name
    }
  }
`;

/**
 * Renders a page for creating a new pipeline/scope.
 */
export function PipelinesNewPage(): ReactElement {
  const router = useRouter();
  const track = useHubSpotEvent();
  const toast = useToast();

  const [createScope, { loading }] = useSojournerMutation<
    PipelineCreateMutation,
    PipelineCreateMutationVariables
  >(pipelineCreateMutation, {
    refetchQueries: [{ query: pipelinesQuery }],
    onCompleted(data) {
      // FIXME: mutation responses should rarely be null. Can we fix this in the API?
      if (!data.createScope) return;

      const { id, name } = data.createScope;

      // redirect to detail page
      router.navigate(ROUTE_NAMES.PIPELINES_VIEW, {
        id,
      });

      track(HUBSPOT_USER_CREATED_PIPELINE, {
        resource_id: id,
        resource_name: name,
      });

      toast({
        status: "success",
        title: `${name} pipeline created`,
        description:
          "The pipeline will need to build before we can provide net population totals. We'll send you an email when it's ready.",
      });
    },
  });

  function onFormSave(state: PipelineFormState) {
    createScope({
      variables: {
        input: {
          name: state.name,
          population: {
            cohortIds: state.populationCohortIds,
            exclusionCohortIds: state.populationExclusionCohortIds,
          },
          payload: {
            outcomeIds: state.payloadOutcomeIds,
            recommenderIds: state.payloadRecommenderIds,
            personaSetIds: state.payloadPersonaSetIds,
            cohortIds: state.payloadCohortIds,
            explainability: state.outcomeExplainability,
            attributes: state.attributes,
          },
          preview: true,
        },
      },
    });
  }

  const title = "New pipeline";

  return (
    <PipelineLayout
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.PIPELINES_NEW,
      }}
    >
      <UsageWarningNotice resource={"scopes"} />
      <Box mb={6}>
        <PipelineForm onSave={onFormSave} saving={loading} />
      </Box>

      <Blankslate
        title="Deployment"
        text="You’ll choose how to deploy your pipeline next"
        filled
      />
    </PipelineLayout>
  );
}
