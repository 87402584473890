import { Text } from "@chakra-ui/react";
import { Stream } from "@fdy/faraday-js";
import { ChangeEvent, FormEvent, useState } from "react";

import { useFormErrors } from "../../../../../hooks/useFormErrors";
import { Button } from "../../../../ui/Button";
import { FormField } from "../../../../ui/FormField";
import { FormFieldset } from "../../../../ui/FormFieldset";
import { FormFieldStack } from "../../../../ui/FormFieldStack";
import { InlineButtons } from "../../../../ui/InlineButtons";
import { Input } from "../../../../ui/Input";
import { ModalV2 } from "../../../../ui/ModalV2";
import { useId } from "../../../../ui/useId";
import { DatasetFormDataset } from "../DatasetForm";
import { ClassicExplanation } from "../shared/ClassicExplanation";
import { isSampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import { BlessedPropertyFields } from "./BlessedPropertyFields";
import {
  DatasetEventCondition,
  DatasetsEventConditionsForm,
} from "./DatasetsEventConditionsForm";
import { DatasetEventModalStep } from "./DatasetsEventsCard";
import {
  generateUniquePropertyId,
  outputToStreamDatamapFromWire,
  outputToStreamDatamapToWire,
} from "./outputToStreamUtils";
import { PropertiesTable } from "./PropertiesTable";
import { DatasetEventDataMap } from "./PropertiesTableRow";
import {
  DatasetEventsError,
  namePatternHelpText,
  validateOutputToStreamEvent,
} from "./validateOutputToStreamEvent";

export type DatasetEventState = {
  name: string;
  properties: DatasetEventDataMap;
  conditions: DatasetEventCondition[];

  /**
   * Classic events use out of date configuration that is hidden to users.
   * We still show them in the UI for users to see the configuration, but they can't be edited.
   */
  classic?: boolean;
};

export type EventProperty = {
  /** Unique ID only used for state management when editing dataset output to streams */
  id: string;

  /** The property name (the key in the output_to_streams datamap). Must be snakecase. */
  name: string | null;

  /** The column name in the dataset detected columns */
  column_name: string | null;

  /** The format of the property */
  format: string | null;

  /**
   * If a dataset is contributing to an event stream, it can't delete a property that another dataset is contributing.
   * They can, however, be edited to emit a different column to the same property.
   */
  locked?: boolean;

  /**
   * Blessed properties are properties that have special meaning in the backend.
   * They are datetime and value.
   */
  blessed?: boolean;
};

const defaultState: DatasetEventState = {
  name: "",
  properties: {},
  conditions: [],
  classic: false,
};

/**
 * DatasetsEventsModal component is a modal dialog used to configure and manage dataset events.
 * It allows users to define event properties, conditions, and associate them with a dataset.
 */
export function DatasetsEventsModal({
  initialState = defaultState,
  openModal,
  onClose,
  onNext,
  dataset,
  currentStream,
  streams,
}: {
  initialState?: DatasetEventState;
  openModal: DatasetEventModalStep;
  onNext: (state: DatasetEventState) => void;
  onClose: () => void;
  dataset?: DatasetFormDataset;
  currentStream?: Stream;
  streams?: Stream[];
}) {
  const formId = useId();

  const [streamName, setStreamName] = useState(initialState.name);

  const [properties, setProperties] = useState<EventProperty[]>(() =>
    outputToStreamDatamapFromWire({
      properties: initialState.properties,
      stream: currentStream,
      datasetId: dataset?.id,
    })
  );

  const [conditions, setConditions] = useState<DatasetEventCondition[]>(
    initialState.conditions
  );

  const { errors, resetError, setErrors, refs } =
    useFormErrors<DatasetEventsError>();

  const datasetDetectedColumns = (dataset?.detectedColumns ??
    []) as DetectedColumn[];

  const disabled = Boolean(initialState.classic || dataset?.managed);

  const sampleData =
    dataset && isSampleData(dataset.sample) ? dataset.sample : undefined;

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    const trimmedName = streamName.trim();
    const errors = validateOutputToStreamEvent({
      detectedColumns: datasetDetectedColumns,
      conditions,
      eventName: trimmedName,
      properties,
      sampleData,
      eventStep: openModal,
      streams,
    });

    if (errors) {
      setErrors(errors);
      return;
    }

    const state: DatasetEventState = {
      name: trimmedName,
      properties: outputToStreamDatamapToWire(
        properties,
        initialState?.properties
      ),
      conditions,
    };

    onNext(state);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStreamName(e.target.value);
    resetError("name");
  };

  const handlePropertyChange = (property: EventProperty) => {
    setProperties((prev) => {
      const index = prev.findIndex((p) => p.id === property.id);
      const newProperties = [...prev];
      newProperties[index] = property;
      return newProperties;
    });

    resetError("properties");
  };

  const handleAddProperty = () => {
    setProperties((prev) => [
      ...prev,
      {
        id: generateUniquePropertyId(),
        name: "",
        column_name: null,
        format: null,
      },
    ]);

    resetError("properties");
  };

  const handlePropertyRemove = (property: EventProperty) => {
    setProperties((prev) => prev.filter((p) => p.id !== property.id));
    resetError("properties");
  };

  const datetimeProperty = properties.find((p) => p.name === "datetime");
  const valueProperty = properties.find((p) => p.name === "value");
  // We can assume these exist as we always insert them into the properties state in fromWire function.
  // It's just to make TS happy
  if (!datetimeProperty) throw new Error("datetime property not found");
  if (!valueProperty) throw new Error("value property not found");

  return (
    <ModalV2
      onCloseStateToConfirm={{
        streamName,
        properties,
        conditions,
      }}
      isOpen
      onClose={onClose}
      title={
        openModal === DatasetEventModalStep.existingStream
          ? `${streamName} event`
          : "Add event"
      }
      footer={
        <InlineButtons>
          <Button variant="tertiary" onClick={onClose} analyticsName="cancel">
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={disabled} // TODO: tooltip for disabled reason
            form={formId}
            type="submit"
            analyticsName="finish"
          >
            Finish
          </Button>
        </InlineButtons>
      }
      analyticsStackName="add-event-modal"
    >
      <form onSubmit={handleFormSubmit} id={formId} role="form">
        <FormFieldStack>
          <Text fontSize="fdy_lg">
            Events identify important recurrent occurences that you'd want to
            associate with people and make predictions about. If another dataset
            produces an event (orders) that this dataset can also contribute to,
            please select an existing event stream rather than creating a new
            one.
          </Text>
          {initialState?.classic ? (
            <ClassicExplanation resourceType="event" />
          ) : null}

          {openModal === DatasetEventModalStep.newStream ? (
            <FormField
              label="Event stream name"
              error={errors.name}
              helpText={namePatternHelpText}
              ref={refs.name}
            >
              <Input
                name="eventName"
                type="text"
                value={streamName}
                disabled={disabled}
                onChange={handleNameChange}
                analyticsName="event-name"
                autoFocus
              />
            </FormField>
          ) : null}

          <FormFieldset
            legend="Datetime"
            hint="If a column in your dataset indicates when this event
                  occurred, choose it here. Otherwise, Faraday will handle this
                  event as undated, which may impair prediction performance."
            error={errors.datetime}
          >
            <BlessedPropertyFields
              detectedColumns={datasetDetectedColumns}
              sampleData={sampleData}
              property={datetimeProperty}
              onChange={handlePropertyChange}
            />
          </FormFieldset>

          <FormFieldset
            legend="Value"
            hint="If a column in your dataset indicates 
                how much was money was gained or lost during this event, choose it here. 
                This field is used for models such as life time value."
            error={errors.value}
          >
            <BlessedPropertyFields
              detectedColumns={datasetDetectedColumns}
              sampleData={sampleData}
              property={valueProperty}
              onChange={handlePropertyChange}
            />
          </FormFieldset>

          <FormFieldset
            legend="Properties"
            suffix="optional"
            hint={
              <>
                If there are columns in your data that you want to associate
                with this event other than the fields above, please add them
                here. Choosing a format for a non-number value should only be
                done if you know the type of your data and it's a more complex
                data structure, such as an array. Otherwise, we recommend the
                format be left blank.{" "}
                {currentStream?.properties
                  ? "Locked properties are defined by other Datasets that contribute to this event stream — they’re optional."
                  : null}
              </>
            }
            error={errors.properties}
            ref={refs.properties}
          >
            <PropertiesTable
              properties={properties.filter((p) => !p.blessed)}
              onPropertyChange={handlePropertyChange}
              onRemoveProperty={handlePropertyRemove}
              onAddProperty={handleAddProperty}
              disabled={disabled}
              sampleData={sampleData}
              detectedColumns={datasetDetectedColumns}
            />
          </FormFieldset>

          <FormFieldset
            legend="Conditions"
            suffix="optional"
            hint="In some cases, not every row in your data represents an emission of an event. Use the button below to add conditions which must be met by a row for it to emit an event."
            error={errors.conditions}
            ref={refs.conditions}
          >
            <DatasetsEventConditionsForm
              conditions={conditions}
              setConditions={setConditions}
              clearError={() => resetError("conditions")}
              detectedColumns={datasetDetectedColumns}
              sampleData={sampleData}
              disabled={disabled}
            />
          </FormFieldset>
        </FormFieldStack>
      </form>
    </ModalV2>
  );
}
