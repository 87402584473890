import { CardV2 } from "../ui/Card/CardV2";
import { Skeleton } from "./Skeleton";

/**
 * Represents an empty piece of content while the resource is building.
 * and something will be in this place eventually.
 */
export function SkeletonCard() {
  const skeleProps = {
    mb: 3,
  };

  return (
    <CardV2>
      <Skeleton height={8} width="20%" {...skeleProps} mb={6} />
      <Skeleton height={3} width="80%" {...skeleProps} />
      <Skeleton height={3} width="50%" {...skeleProps} />
    </CardV2>
  );
}
