import { Box, Heading, Link, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export function Cardv2Section({
  title,
  text,
  cta,
  children,
  fieldset,
}: {
  title: string;
  text?: string;
  cta?: {
    href: string;
    text: string;
  };
  children: ReactNode;
  fieldset?: boolean;
}) {
  return (
    <Box as={fieldset ? "fieldset" : "section"}>
      <Box as="header" mb={6}>
        <Heading as={fieldset ? "legend" : "h2"} sx={{ fontSize: "fdy_lg" }}>
          {title}
        </Heading>

        {text && <Text mt={2}>{text}</Text>}

        {cta && (
          <Link href={cta.href} mt={4}>
            {cta.text}
          </Link>
        )}
      </Box>

      <Box>{children}</Box>
    </Box>
  );
}
