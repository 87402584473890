import { Center, Select, Spacer } from "@chakra-ui/react";
import {
  Recommender,
  RecommenderAnalysisOverallFeatures,
  RecommenderFeatureData,
} from "@fdy/faraday-js";
import { CaretDown } from "@phosphor-icons/react";
import { useMemo, useState } from "react";

import { useProgressiveDisclosureItems } from "../../../hooks/useProgressiveDisclosureItems";
import { objectEntries } from "../../../utils/objectEntries";
import { AnalysisFeatureTitle } from "../../outcomes/OutcomesShowPage/OutcomeDataCard/AnalysisFeatureTitle";
import { AccordionV2 } from "../../ui/AccordionV2";
import { Blankslate } from "../../ui/Blankslate";
import { Button } from "../../ui/Button";
import {
  DirectionalityChart,
  DirectionalityChartDatum,
} from "../../ui/charts-v2/DirectionalityChart";
import { FeatureImportanceBar } from "../../ui/FeatureImportanceBar";
import { FormField } from "../../ui/FormField";
import { useRecommenderAnalysis } from "../recommenderQueries";
import { modelGroupToLiterate } from "./recommenderUtils";

type FeatureGroup = keyof RecommenderAnalysisOverallFeatures;

interface RecommenderAnalysisDataProps {
  overallFeatures: RecommenderAnalysisOverallFeatures;
  recommender: Recommender;
}

function prepareRecommenderDirectionalityData(
  feature: RecommenderFeatureData,
  streamName: string
): DirectionalityChartDatum[] {
  const rows: DirectionalityChartDatum[] = [];
  const groupLabel = `${streamName} experients`;

  for (const t of feature.directionality) {
    switch (t.data_type) {
      case "text":
        rows.push({
          groupLabel,
          groupPercent: t.density,
          x: t.value,
          y: t.impact,
        });
        break;

      case "number":
        rows.push({
          groupLabel,
          groupPercent: t.density,
          x: `(${t.min}-${t.max}]`,
          y: t.impact,
        });
        break;

      case "date":
        rows.push({
          groupLabel,
          groupPercent: t.density,
          x: `(${t.min}-${t.max}]`,
          y: t.impact,
        });
        break;

      case "boolean":
        rows.push({
          groupLabel,
          groupPercent: t.density,
          x: String(t.value),
          y: t.impact,
        });
        break;

      default:
        console.warn(`Unknown data type: ${JSON.stringify(t)}`);
    }
  }

  return rows;
}

function RecommenderDirectionalityChart({
  feature,
  streamName,
}: {
  feature: RecommenderFeatureData;
  streamName: string;
}) {
  const data = prepareRecommenderDirectionalityData(feature, streamName);
  return (
    <DirectionalityChart
      data={data}
      title={feature.literate}
      xLabel={feature.literate}
    />
  );
}

export function RecommenderAnalysisDataFeatures({
  overallFeatures,
  recommender,
}: RecommenderAnalysisDataProps) {
  const groups = objectEntries(overallFeatures)
    // some features may be undefined
    .filter(([, f]) => f)
    .map(([k]) => k as FeatureGroup)
    .sort();

  const [group, setGroup] = useState<FeatureGroup>(groups[0]);

  // unlikely to return undefined but the fallback array makes TS easier
  const activeGroup = overallFeatures[group] ?? [];

  // Calculate max importance since we want percent charts to fill most of their area.
  // It's more helpful to read them relative to each other than to read the exact value.
  const maxImportance = useMemo(
    () => Math.max(...activeGroup.map((f) => f.importance)),
    [activeGroup]
  );

  const { shownItems, hasMoreToShow, showMoreButtonProps } =
    useProgressiveDisclosureItems(activeGroup);

  return (
    <>
      <FormField label="Recognition and experience to date">
        <Select
          value={group}
          onChange={(e) => setGroup(e.target.value as FeatureGroup)}
          maxWidth="fit-content"
        >
          {groups.map((label) => (
            <option key={label} value={label}>
              {modelGroupToLiterate(label, recommender.stream_name)}
            </option>
          ))}
        </Select>
      </FormField>

      <Spacer my={4} />

      <AccordionV2 allowMultiple>
        {shownItems.map((feature) => (
          <AccordionV2.Item
            key={feature.name}
            title={
              <AnalysisFeatureTitle
                name={feature.name}
                literate={feature.literate}
                category={feature.category}
              />
            }
            afterTitle={
              <FeatureImportanceBar
                feature={feature}
                maxImportance={maxImportance}
              />
            }
          >
            <RecommenderDirectionalityChart
              feature={feature}
              streamName={recommender.stream_name}
            />
          </AccordionV2.Item>
        ))}
      </AccordionV2>

      {hasMoreToShow && (
        <Center>
          <Button
            mt={4}
            variant="ghost"
            leftIcon={<CaretDown />}
            {...showMoreButtonProps}
            analyticsName="show-more-features"
          />
        </Center>
      )}
    </>
  );
}

export function RecommenderAnalysisData({
  recommender,
}: {
  recommender: Recommender;
}) {
  const { data } = useRecommenderAnalysis(recommender);

  if (!data?.overall_features) {
    return <Blankslate title="No feature data" />;
  }

  return (
    <RecommenderAnalysisDataFeatures
      overallFeatures={data.overall_features}
      recommender={recommender}
    />
  );
}
