import { BarSeries } from "@visx/xychart";
import { useCallback } from "react";

import { colors } from "../../../styles/chakra-theme-v2";
import { AxisBottom } from "./AxisBottom";
import { AxisLeft } from "./AxisLeft";
import { Chart, RenderTooltipCallback } from "./Chart";

export type Datum = {
  x: string;
  y: number;
};

export function BarChart({
  data,
  yLabel,
  xLabel,
  title,
}: {
  data: Datum[];
  yLabel: string;
  xLabel: string;
  title: string;
}) {
  const renderTooltip: RenderTooltipCallback<Datum> = useCallback(
    ({ tooltipData }) => {
      if (!tooltipData?.nearestDatum) return null;

      return (
        <div>
          <div>{tooltipData.nearestDatum.datum.x}</div>
          <div>{tooltipData.nearestDatum.datum.y}</div>
        </div>
      );
    },
    []
  );

  const xPadding = 0.2;
  return (
    <Chart
      title={title}
      yScale={{ type: "linear" }}
      xScale={{ type: "band", padding: xPadding }}
      renderTooltip={renderTooltip}
    >
      <BarSeries
        dataKey="bars"
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        barPadding={1}
        colorAccessor={() => colors.fdy_magenta[500]}
      />

      <AxisLeft label={yLabel} />
      <AxisBottom
        label={xLabel}
        numTicks={data.length}
        xScalePadding={xPadding}
      />
    </Chart>
  );
}
