import { gql } from "@apollo/client";
import { FormEvent, useCallback, useState } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useAccountConfigMap } from "../../../hooks/accountConfigHooks";
import { useUnsavedChangesWarning } from "../../../hooks/useUnsavedChangesWarning";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { Button } from "../../ui/Button";
import { CardV2 } from "../../ui/Card/CardV2";
import { CardStack } from "../../ui/CardStack";
import { PipelineFormQuery } from "./__generated__/PipelineFormQuery";
import { PipelineFormNameCard } from "./PipelineFormNameCard";
import { PipelineFormPayloadCard } from "./PipelineFormPayloadCard";
import { PipelineFormPopulationCard } from "./PipelineFormPopulationCard";
import { EVERYONE_ID, pipelineFormLabels } from "./pipelineFormUtils";
import { usePipelineValidator } from "./usePipelineValidator";

// shared form state for edit and new page
export interface PipelineFormState {
  name: string;
  everyoneSelected: boolean;
  populationCohortIds: string[];
  populationExclusionCohortIds: string[];
  payloadOutcomeIds: string[];
  payloadPersonaSetIds: string[];
  payloadRecommenderIds: string[];
  payloadCohortIds: string[];
  attributes: string[]; // these are traits but they're called attributes on API still..
  outcomeExplainability: boolean;
}

interface PipelineFormProps {
  defaultState?: PipelineFormState;
  onSave: (state: PipelineFormState) => void;
  saving: boolean;
}

export const PIPELINE_FORM_QUERY = gql`
  query PipelineFormQuery {
    cohorts {
      id
      name
      populationCount
      status
      archivedAt
    }
    outcomes {
      id
      name
      status
      archivedAt
    }
    personaSets {
      id
      createdAt
      active
      name
      status
      personas {
        id
        name
      }
      archivedAt
    }
    recommenders {
      id
      name
      status
      archivedAt
    }
  }
`;

const initialState: PipelineFormState = {
  name: "",
  everyoneSelected: false,
  populationCohortIds: [],
  populationExclusionCohortIds: [],
  payloadOutcomeIds: [],
  payloadCohortIds: [],
  payloadPersonaSetIds: [],
  payloadRecommenderIds: [],
  outcomeExplainability: false,
  attributes: [],
};

/**
 * Renders the editable fields of a pipeline/scope like populations, payload content, and pipeline name.
 *
 * It also queries the API for the list of available cohorts, outcomes,
 * and persona sets since the user selects from those for the above fields.
 */
export function PipelineForm({
  defaultState = initialState,
  onSave,
  saving,
}: PipelineFormProps) {
  const configMap = useAccountConfigMap();

  const { errors, resetError, validatePipeline, setErrors } =
    usePipelineValidator();

  /**
   * Fetch the list of cohorts, outcomes, and persona sets from the API.
   * Users select from these resources to use in the pipeline.
   */
  const { data, loading, error } =
    useSojournerQuery<PipelineFormQuery>(PIPELINE_FORM_QUERY);

  /**
   * Set up the initial state of the form.
   *
   * Form state manages the list of UUIDs of resources for payload, population,
   * and population exclusion.
   *
   * We also manage if 'everyone' is selected because we have some specific UI
   * logic for displaying it.
   */
  const [formState, setFormState] = useState<PipelineFormState>(() => {
    if (defaultState.everyoneSelected) {
      return {
        ...defaultState,
        populationCohortIds: [EVERYONE_ID],
      };
    }
    return defaultState;
  });

  //user warning message for unsaved changes

  const { setWarnBeforeNavigate } = useUnsavedChangesWarning({
    config: [formState],
    route: ROUTE_NAMES.PIPELINES,
  });

  // If we fail to fetch resources for creating the scope, crash the page.
  // The form would be useless.
  if (error) throw error;

  /**
   * Handles the submission of the pipeline form.
   * Ensure we pass empty cohort ids for population if 'everyone' is selected,
   * otherwise pass the list of cohort ids and all other state.
   */
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      const errors = validatePipeline(formState);
      if (errors) {
        return setErrors(errors);
      }

      if (formState.everyoneSelected) {
        onSave({
          ...formState,
          populationCohortIds: [],
        });
        setWarnBeforeNavigate(false);
      } else {
        onSave(formState);
        setWarnBeforeNavigate(false);
      }
    },
    [onSave, formState, validatePipeline, setWarnBeforeNavigate]
  );

  return (
    <form onSubmit={handleSubmit}>
      <CardStack>
        <CardV2
          title="What predictions do you want to deploy?"
          text="Pipelines let you choose which predictions you want to deploy, who you want those predictions to be made for, and where you want them deployed."
        />

        <PipelineFormPopulationCard
          cohorts={data?.cohorts ?? []}
          formState={formState}
          setFormState={setFormState}
          resetError={resetError}
          errors={errors}
          configMap={configMap}
          loading={loading}
        />

        <PipelineFormPayloadCard
          data={data}
          loading={loading}
          configMap={configMap}
          formState={formState}
          setFormState={setFormState}
          resetError={resetError}
          errors={errors}
        />

        <PipelineFormNameCard
          formState={formState}
          setFormState={setFormState}
          errors={errors}
          resetError={resetError}
        />

        <Button
          type="submit"
          isLoading={saving}
          isDisabled={loading || saving}
          loadingText={pipelineFormLabels.saving}
          size="lg"
          width="100%"
          analyticsName="save"
        >
          {pipelineFormLabels.save}
        </Button>
      </CardStack>
    </form>
  );
}
