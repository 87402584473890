import { omitBy } from "lodash";

import { TargetFilterPostInput } from "./types";

export const isEmpty = (val: unknown) =>
  val === null ||
  val === undefined ||
  val === "" ||
  (Array.isArray(val) && val.length === 0);

// util for removing empty values from an object
// in this case empty values are null, undefined, empty string, or empty array
export function removeEmptyValues<T extends object>(obj: T): Partial<T> {
  return omitBy(obj, isEmpty);
}

function targetFilterTraitToInput(conditions: TargetFilterPostInput["trait"]) {
  return conditions?.map((cond) => {
    if (!cond) throw new Error("cond is undefined");
    return {
      name: cond.name,
      ...removeEmptyValues(cond),
    };
  });
}

function targetFilterOutcomeToInput(
  conditions:
    | TargetFilterPostInput["outcomePercentile"]
    | TargetFilterPostInput["outcomeScore"]
    | TargetFilterPostInput["outcomeProbability"]
) {
  return conditions?.map((cond) => {
    if (!cond) throw new Error("cond is undefined");
    return {
      outcomeId: cond.outcomeId,
      ...removeEmptyValues(cond),
    };
  });
}

function targetFilterRecommenderToInput(
  conditions:
    | TargetFilterPostInput["recommenderRank"]
    | TargetFilterPostInput["recommenderUncalibratedProbability"]
) {
  return conditions?.map((cond) => {
    if (!cond) throw new Error("cond is undefined");
    return {
      recommenderId: cond.recommenderId,
      ...removeEmptyValues(cond),
    };
  });
}

/**
 * Target filter accepts nulls but when we POST requests we need to pass
 * undefined so this will handle the conversion
 */
export function targetFilterToPostInput(
  filter?: TargetFilterPostInput | null
): TargetFilterPostInput | undefined {
  if (!filter) return undefined;

  return {
    // personas and cohorts shouldn't contain any empty values
    ...filter,
    trait: targetFilterTraitToInput(filter.trait),
    outcomePercentile: targetFilterOutcomeToInput(filter.outcomePercentile),
    outcomeScore: targetFilterOutcomeToInput(filter.outcomeScore),
    outcomeProbability: targetFilterOutcomeToInput(filter.outcomeProbability),
    recommenderRank: targetFilterRecommenderToInput(filter.recommenderRank),
    recommenderUncalibratedProbability: targetFilterRecommenderToInput(
      filter.recommenderUncalibratedProbability
    ),
  };
}
