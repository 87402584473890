import { gql } from "@apollo/client";
import { useMemo } from "react";

import { ModelingField } from "../../../__generated__/sojournerGlobalTypes";
import { TraitsMap } from "../../../hooks/useTraitsQuery";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { useModelingFields } from "../useModelingFields";
import {
  PersonaSetAnalysisHookQuery,
  PersonaSetAnalysisHookQueryVariables,
} from "./__generated__/PersonaSetAnalysisHookQuery";
import { AnalysisDimensionRow } from "./types";
import { makeTraitDimensionRows } from "./utils";

export const PERSONA_SET_ANALYSIS_HOOK = gql`
  query PersonaSetAnalysisHookQuery($id: ID!) {
    dimensions: personaSetAnalysisDimensions(personaSetId: $id) {
      personaId
      traits {
        traitName
        bins {
          __typename
          ... on AnalysisDimensionsTraitBinText {
            category: value
            count
            percent
          }

          ... on AnalysisDimensionsTraitBinBoolean {
            value
            count
            percent
          }

          ... on AnalysisDimensionsTraitBinNumber {
            min
            max
            count
            percent
          }

          ... on AnalysisDimensionsTraitBinDate {
            minDate: min
            maxDate: max
            count
            percent
          }
        }
      }
    }
  }
`;

export interface PersonaSetAnalysisHook {
  loading: boolean;
  clusteringDimensions: AnalysisDimensionRow[];
  traitDimensions: AnalysisDimensionRow[];
}

function groupDimensionsByClusteringTraits(
  clusteringFields: string[],
  dimensions: AnalysisDimensionRow[]
): Pick<PersonaSetAnalysisHook, "clusteringDimensions" | "traitDimensions"> {
  const clusteringDimensions: AnalysisDimensionRow[] = [];
  const traitDimensions: AnalysisDimensionRow[] = [];

  const sortedDimensions = dimensions.sort((a, b) =>
    (a.field.literate ?? a.field.name).localeCompare(
      b.field.literate ?? b.field.name
    )
  );

  for (const dimension of sortedDimensions) {
    if (clusteringFields.includes(dimension.field.name)) {
      clusteringDimensions.push(dimension);
    } else {
      traitDimensions.push(dimension);
    }
  }

  return { clusteringDimensions, traitDimensions };
}

/**
 * Given a persona set, return the dimensions (counts/percentages) for each trait and each of the personas,
 * grouped by clustering traits and non-clustering traits.
 */
export function usePersonaSetAnalysis({
  traitsMap,
  personaSetId,
  modelingFields,
}: {
  traitsMap: TraitsMap;
  personaSetId: string;
  modelingFields: ModelingField[];
}): PersonaSetAnalysisHook {
  // Get the modeling/clustering fields used for the personas so we can render
  // the clustering traits separate from the rest of the calculated traits
  const { fields } = useModelingFields(modelingFields);

  const {
    data,
    loading: loadingData,
    error,
  } = useSojournerQuery<
    PersonaSetAnalysisHookQuery,
    PersonaSetAnalysisHookQueryVariables
  >(PERSONA_SET_ANALYSIS_HOOK, {
    variables: {
      id: personaSetId,
    },
  });

  if (error) throw error;

  // create a list of trait names that were used for clustering
  const clusteringFieldNames = fields.map((field) => field.name);

  // create the UI rows for the clustering traits and the other traits
  const analysis = useMemo(() => {
    if (!data?.dimensions) return;

    const traitDimensions = makeTraitDimensionRows(data.dimensions, traitsMap);

    return groupDimensionsByClusteringTraits(
      clusteringFieldNames,
      traitDimensions
    );
  }, [data, traitsMap, clusteringFieldNames]);

  return {
    loading: loadingData,
    clusteringDimensions: analysis?.clusteringDimensions ?? [],
    traitDimensions: analysis?.traitDimensions ?? [],
  };
}
