import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

import { RouterLink, RouterLinkProps } from "./RouterLink";

export interface BlankslateProps {
  /**
   * An up front heading for the blankslate, which the user should read first.
   */
  title?: ReactNode;

  /**
   * Longer form text under the title.
   * Ideally at most a sentence or two
   */
  text?: ReactNode;

  /**
   * When set, renderse a call to action for the blankslate.
   * Usually a prompt for the user to create a resource if the blankslate represents a list of resources they don't have any of yet.
   */
  button?: Pick<RouterLinkProps, "routeName" | "children" | "params">;

  /**
   * Render the blankslate with a background and border
   */
  filled?: boolean;
}

/**
 * Used for conveying empty or to-be-created content.
 *
 * Typically used where old <EmptyPage /> was.
 *
 * Requires Chakra provider.
 */
export function Blankslate({ title, text, button, filled }: BlankslateProps) {
  return (
    <Box
      sx={{
        // using rgba bg so blankslate can be layered on other varying background colors
        bg: filled ? "rgba(178, 175, 182, 0.2)" : "transparent",
        border: filled ? "1px solid" : "none",
        borderColor: "fdy_gray.300",
        borderRadius: "md",
        color: "fdy_gray.600",
        py: "4rem",
        textAlign: "center",
      }}
    >
      <Center>
        <Box>
          <Heading as="h4" fontSize="fdy_lg">
            {title}
          </Heading>

          {text && (
            <Text
              sx={{
                fontSize: "fdy_md",
                color: "fdy_gray.600",
                mt: 1,
              }}
            >
              {text}
            </Text>
          )}

          {button && (
            <RouterLink
              routeName={button.routeName}
              params={button.params}
              sx={{
                display: "inline-block",
                fontWeight: "bold",
                color: "fdy_gray.700",
                mt: 2,
              }}
            >
              {button.children}
            </RouterLink>
          )}
        </Box>
      </Center>
    </Box>
  );
}
