import { curveNatural } from "@visx/curve";
import { LineSeries } from "@visx/xychart";

import { assertArrayAndUnwrap } from "../../../../utils/assertions";
import { AxisBottom } from "../../../ui/charts-v2/AxisBottom";
import { AxisLeft } from "../../../ui/charts-v2/AxisLeft";
import { Chart } from "../../../ui/charts-v2/Chart";
import { OutcomeAnalysisQuery_outcomeAnalysis_bias_data_age } from "../__generated__/OutcomeAnalysisQuery";
import { BIAS_CHART_COLORS } from "./outcomeAnalysisBiasUtils";

export function BiasDataAgeDistributionChart({
  data,
}: {
  data: OutcomeAnalysisQuery_outcomeAnalysis_bias_data_age;
}) {
  const datasets = [
    {
      label: "Positives",
      data: assertArrayAndUnwrap(data.positives),
      color: BIAS_CHART_COLORS.positives,
    },
    {
      label: "Negatives",
      data: assertArrayAndUnwrap(data.negatives),
      color: BIAS_CHART_COLORS.negatives,
    },
    {
      label: "Candidates",
      data: assertArrayAndUnwrap(data.candidates),
      color: BIAS_CHART_COLORS.candidates,
    },
  ].filter((d) => d.data.length > 0);

  return (
    <Chart
      title="Training set demographics - age"
      xScale={{ type: "linear", zero: false }}
      yScale={{ type: "linear" }}
      height={400}
      legend={{ items: datasets }}
    >
      {datasets.map((dataset) => (
        <LineSeries
          curve={curveNatural}
          key={dataset.label}
          dataKey={dataset.label}
          data={dataset.data}
          stroke={dataset.color}
          xAccessor={(d) => d.x}
          yAccessor={(d) => d.y}
        />
      ))}
      <AxisLeft label="Density" />
      <AxisBottom label="Age" />
    </Chart>
  );
}
