import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  IconButton,
  VisuallyHidden,
} from "@chakra-ui/react";
import { CaretLeft, CaretRight, House } from "@phosphor-icons/react";
import * as React from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Link } from "../Link";

export interface Breadcrumb {
  label: string;
  routeName: string;
  params?: Record<string, string>;
}

function PageBreadcrumb({ crumbs = [] }: { crumbs?: Breadcrumb[] }) {
  const homeCrumb = (
    <BreadcrumbItem>
      <BreadcrumbLink as={Link} routeName={ROUTE_NAMES.HOME}>
        <House weight="fill" />
        <VisuallyHidden>Home</VisuallyHidden>
      </BreadcrumbLink>
    </BreadcrumbItem>
  );

  const separator = <CaretRight />;

  return (
    <Breadcrumb separator={separator} mb={2}>
      {homeCrumb}
      {crumbs.map(({ label, routeName, params }, i) => {
        return (
          <BreadcrumbItem
            key={i}
            // naively check for current page, maybe should use router hook but seems fine for now
            isCurrentPage={i === crumbs.length - 1}
          >
            <BreadcrumbLink as={Link} routeName={routeName} params={params}>
              {label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}

interface PageHeaderProps {
  title: string;
  crumbs?: Breadcrumb[];
  rightContent?: React.ReactNode;
  backBtnProps?: {
    routeName: string;
    label: string;
    routeParams?: Record<string, string>;
  };
}

/**
 * Renders a region for top of pages containing an h1 title,
 * breadcrumb, and optional right aligned content.
 *
 * Meant to replace <Toolbar /> in previous app design aesthetic.
 *
 * The breadcrumb management is very manual. If we start to have deep pages
 * or more use cases, perhaps we define a human-readable label in our routes, then
 * we can programmatically generate breadcrumb lists from route matching.
 */
export function PageHeader({
  title,
  crumbs,
  rightContent,
  backBtnProps,
}: PageHeaderProps) {
  useDocumentTitle(title);

  const backBtn = backBtnProps ? (
    <IconButton
      aria-label={backBtnProps.label}
      as={Link}
      variant="secondary"
      size="sm"
      routeName={backBtnProps.routeName}
      params={backBtnProps?.routeParams}
      icon={<CaretLeft />}
    />
  ) : null;

  return (
    <Box
      sx={{
        px: "12",
        py: "5",
        bg: "white",
        borderBottom: "1px solid",
        borderColor: "fdy_gray.200",
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 10,
        width: "100%",
      }}
    >
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Box>
          <PageBreadcrumb crumbs={crumbs} />
          <Flex alignItems="center">
            {backBtn ? <Box mr={4}>{backBtn}</Box> : null}
            <Heading as="h1" variant="h3">
              {title}
            </Heading>
          </Flex>
        </Box>

        <Box>{rightContent}</Box>
      </Flex>
    </Box>
  );
}
