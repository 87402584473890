import { Text } from "@chakra-ui/react";
import { FormEvent, useState } from "react";

import { Button } from "../../../../ui/Button";
import { InlineButtons } from "../../../../ui/InlineButtons";
import { ModalV2 } from "../../../../ui/ModalV2";
import { RadioGroupV3 } from "../../../../ui/RadioGroupV3/RadioGroupV3";
import { useId } from "../../../../ui/useId";
import { EventStreamNewOrExisting } from "./DatasetsEventsCard";
import { StreamSelect } from "./StreamSelect";

export type StreamSelectStepResult =
  | {
      action: EventStreamNewOrExisting.newStream;
    }
  | {
      name: string;
      action: EventStreamNewOrExisting.existingStream;
    };

/**
 * Modal for selecting an event stream or creating a new one on a dataset
 * Clicking "Next" will call the `onNext` callback with the selected stream or new stream name
 */
export function DatasetsEventsSelectStreamModal({
  onClose,
  onNext,
}: {
  onClose: () => void;
  onNext: (args: StreamSelectStepResult) => void;
}) {
  const [streamAction, setStreamAction] = useState<EventStreamNewOrExisting>(
    EventStreamNewOrExisting.existingStream
  );
  const [streamName, setStreamName] = useState("");
  const formId = useId();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    // need validation? likely not if `required` attributes work below.

    if (streamAction === EventStreamNewOrExisting.newStream) {
      onNext({ action: streamAction });
    } else if (streamName && EventStreamNewOrExisting.existingStream) {
      onNext({ name: streamName, action: streamAction });
    } else {
      throw new Error("Invalid state");
    }
  };

  return (
    <ModalV2
      isOpen
      onClose={onClose}
      title="Add event"
      width={768}
      footer={
        <InlineButtons>
          <Button variant="tertiary" onClick={onClose} analyticsName="cancel">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            form={formId}
            analyticsName="next"
          >
            Next
          </Button>
        </InlineButtons>
      }
      analyticsStackName="event-modal-select-stream"
    >
      <Text fontSize="fdy_lg" mb={4}>
        Events identify important recurrent occurences that you'd want to
        associate with people and make predictions about. If another dataset
        produces an event stream that this dataset can contribute to, please
        select an existing event stream rather than creating a new one.
      </Text>

      <form onSubmit={handleSubmit} id={formId}>
        <RadioGroupV3.Root
          value={streamAction}
          onChange={(value) => {
            setStreamAction(value as EventStreamNewOrExisting);
          }}
        >
          <RadioGroupV3.Item
            value={EventStreamNewOrExisting.existingStream}
            label="Add to an existing event stream"
          >
            <StreamSelect
              value={streamName}
              onChange={(val) => setStreamName(val?.value ?? "")}
              required
            />
          </RadioGroupV3.Item>
          <RadioGroupV3.Item
            label="Create a new event stream"
            value={EventStreamNewOrExisting.newStream}
          />
        </RadioGroupV3.Root>
      </form>
    </ModalV2>
  );
}
