import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";

import { TraitsMap } from "../../hooks/useTraitsQuery";
import { traitLiterateFromMap } from "../../utils/traitUtils";
import { MutedText } from "../ui/MutedText";
import { TagList } from "../ui/TagList";
import { Cohort } from "./CohortsListPage";

/**
 * Render a list of cohort traits (in this case, they are conditions)
 * in a tag list. If there are many traits, truncate the list and
 * show a popover with the rest of the traits.
 */
export function CohortTraitTags({
  conditions,
  traits,
}: {
  conditions: Cohort["traits"];
  traits: TraitsMap;
}) {
  if (conditions.length === 0) {
    return <MutedText>None</MutedText>;
  }

  const traitLiterates = conditions.map((t) =>
    traitLiterateFromMap(t.name, traits)
  );

  const maxTags = 3;
  // get the first N tags
  const truncated = traitLiterates.slice(0, maxTags);
  // get the rest of the tags after N
  const restOfTraits = traitLiterates.slice(maxTags, traitLiterates.length);

  // if there's significant overflow, show a popover
  if (restOfTraits.length > 1) {
    const overflowItem = (
      <Popover trigger="hover">
        <PopoverTrigger>
          <span>+{restOfTraits.length}</span>
        </PopoverTrigger>
        <PopoverContent p={3}>
          <TagList tags={restOfTraits} />
        </PopoverContent>
      </Popover>
    );

    const tagsWithOverflow = [...truncated, overflowItem];

    return <TagList tags={tagsWithOverflow} />;
  }

  return <TagList tags={traitLiterates} />;
}
