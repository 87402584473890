import { Link, Text } from "@chakra-ui/react";
import { useState } from "react";

import { DatasetPostInput } from "../../../__generated__/sojournerGlobalTypes";
import { uuid } from "../../../utils/uuid";
import { useToken } from "../../ui/AuthProvider/useToken";
import { Button } from "../../ui/Button";
import { FormField } from "../../ui/FormField";
import { InlineButtons } from "../../ui/InlineButtons";
import { ModalV2 } from "../../ui/ModalV2";
import { useId } from "../../ui/useId";
import {
  DatasetUploadDropZone,
  UploadState,
} from "../shared/DatasetUploadDropZone";
import { useCsvUploader } from "../shared/useCsvUploader";
import {
  DatasetsCreateNameField,
  validateDatasetName,
} from "./DatasetsCreateNameField";
import { useCreateDataset } from "./useCreateDataset";

/**
 * Renders a form to create a new hosted csv dataset
 */
export function DatasetsCreateModalHostedCsv({
  isOpen,
  onBack,
  onClose,
}: {
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
}) {
  const csvFormId = useId("hosted-csv-setup");

  const [name, setName] = useState<string>("");

  const [fileList, setFileList] = useState<FileList>();
  const [errors, setErrors] = useState<Record<string, string>>({});

  const authToken = useToken();
  const { uploadState, upload } = useCsvUploader();

  const { createDataset, loading } = useCreateDataset();

  function handleFilesChange(fileList: FileList | null) {
    if (fileList) {
      setFileList(fileList);
      setErrors({ ...errors, file: "" });

      // set dataset name to file name if it wasn't set manually
      if (!name.trim()) {
        setName(fileList[0].name);
        setErrors({ ...errors, name: "" });
      }
    } else {
      setErrors({
        ...errors,
        file: "Please upload a file (or choose a different connection type)",
      });
    }
  }

  async function createCsvDataset() {
    if (!authToken) {
      throw new Error("No auth token");
    }

    if (!fileList) {
      setErrors({
        ...errors,
        file: "Please upload a file (or choose a different connection type)",
      });
      return;
    }

    // Generate a random ID for upload directory, otherwise if you create a separate
    // dataset and upload the same file, you can overwrite the first dataset.
    const uploadDirectory = uuid().replace(/-/g, "_");

    try {
      await upload({
        fileList,
        uploadDirectory,
        authToken,
      });
    } catch (e) {
      // TODO: it'd be great to shows errors that users can act on - like max file size, etc.
      setErrors({
        ...errors,
        file: "There was an error uploading your file. Please try again.",
      });
      return;
    }

    const dataset: DatasetPostInput = {
      preview: true,
      name,
      // TODO: remove this once PR for optional identity sets is in
      // we fill in id sets after POST, when we have detected columns
      identitySets: {},
      options: {
        type: "hosted_csv",
        uploadDirectory,
      },
    };

    createDataset(dataset);
  }

  async function handleSubmit() {
    const nameErr = validateDatasetName(name);
    if (nameErr) {
      setErrors({ ...errors, name: nameErr });
      return;
    }

    await createCsvDataset();
  }

  return (
    <ModalV2
      isOpen={isOpen}
      onClose={onClose}
      title="CSV Dataset"
      footer={
        <InlineButtons>
          <Button variant="tertiary" onClick={onBack} analyticsName="back">
            Back
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={csvFormId}
            loadingText="Creating dataset..."
            isLoading={loading || uploadState === UploadState.uploading}
            disabled={loading || uploadState === UploadState.uploading}
            analyticsName="finish"
          >
            Create dataset
          </Button>
        </InlineButtons>
      }
      analyticsStackName="hosted-csv-dataset-modal"
    >
      <Text mb={4}>
        CSVs can be manually uploaded, or can be{" "}
        <Link href="https://faraday.ai/developers/reference/createupload">
          provided via API
        </Link>
        . The file upload will be the template for this dataset, and any future
        upload will need to match the format of this CSV. Once this dataset is
        created, you'll be able to view, manage, and add more data in the 'Data'
        tab.
      </Text>
      <form
        id={csvFormId}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormField label="Upload a CSV" error={errors.file}>
          <DatasetUploadDropZone
            onFilesChange={handleFilesChange}
            uploadStatus={uploadState}
          />
        </FormField>

        <DatasetsCreateNameField
          name={name}
          setName={setName}
          error={errors.name}
          setError={(err) => {
            return { ...errors, name: err };
          }}
        />
      </form>
    </ModalV2>
  );
}
