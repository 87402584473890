import { Flex, InputGroup, InputRightElement } from "@chakra-ui/react";
import { ChangeEvent } from "react";

import { exists } from "../../../../../utils/exists";
import { FormField } from "../../../../ui/FormField";
import { Input } from "../../../../ui/Input";
import {
  formIds,
  labels,
  parseNumberValue,
  TargetLimitProps,
  TargetLimitStatePercentile,
} from "./TargetLimit";
import { TargetLimitOutcomeSelect } from "./TargetLimitOutcomeSelect";

type TargetLimitPercentileProps = Pick<
  TargetLimitProps,
  "onChange" | "payloadOutcomes"
> & {
  limit: TargetLimitStatePercentile;
};

export function TargetLimitPercentile({
  limit,
  onChange,
  payloadOutcomes,
}: TargetLimitPercentileProps) {
  function handlePercentileMinChange(event: ChangeEvent<HTMLInputElement>) {
    onChange({
      ...limit,
      percentileMin: parseNumberValue(event.target.value),
    });
  }

  function handlePercentileMaxChange(event: ChangeEvent<HTMLInputElement>) {
    onChange({
      ...limit,
      percentileMax: parseNumberValue(event.target.value),
    });
  }

  function handleOutcomeChange(outcomeId: string | null) {
    onChange({
      ...limit,
      percentileOutcomeId: outcomeId,
    });
  }

  const addonStyles = {
    width: "auto",
    color: "fdy_gray.600",
    fontWeight: 600,
    right: 2,
  };

  const hasMin = exists(limit.percentileMin);
  const hasMax = exists(limit.percentileMax);

  return (
    <Flex gap={4}>
      <TargetLimitOutcomeSelect
        payloadOutcomes={payloadOutcomes}
        value={limit.percentileOutcomeId}
        onChange={handleOutcomeChange}
        required={hasMin || hasMax}
      />

      <FormField
        label={labels.percentileMin}
        htmlFor={formIds.percentile_min}
        visuallyHiddenLabel
      >
        <InputGroup>
          <Input
            type="number"
            value={limit.percentileMin ?? ""}
            onChange={handlePercentileMinChange}
            min={1}
            max={100}
            pattern="[0-9]{1,3}"
            id={formIds.percentile_min}
            required={hasMax}
            analyticsName="percentile-min"
          />
          <InputRightElement sx={addonStyles}>Min %</InputRightElement>
        </InputGroup>
      </FormField>

      <FormField
        label={labels.percentileMax}
        htmlFor={formIds.percentile_max}
        visuallyHiddenLabel
      >
        <InputGroup>
          <Input
            type="number"
            value={limit.percentileMax ?? ""}
            onChange={handlePercentileMaxChange}
            min={exists(limit.percentileMin) ? limit.percentileMin + 1 : 1}
            max={100}
            pattern="[0-9]{1,3}"
            id={formIds.percentile_max}
            isRequired={hasMin}
            analyticsName="percentile-max"
          />
          <InputRightElement sx={addonStyles}>Max %</InputRightElement>
        </InputGroup>
      </FormField>
    </Flex>
  );
}
