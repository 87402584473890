import { gql, useQuery } from "@apollo/client";
import { Warning } from "@phosphor-icons/react";
import { ReactElement } from "react";

import { PersonaImage } from "../../../ui/PersonaImage";
import { ResourceIcon } from "../../../ui/ResourceIcon";
import { Card } from "../../Card";
import {
  PersonaCardQuery,
  PersonaCardQueryVariables,
} from "./__generated__/PersonaCardQuery";

const personaCardQuery = gql`
  query PersonaCardQuery($id: UUID!) {
    persona(id: $id) {
      id
      name
      image_filename
      cohort_members
      count
    }
  }
`;

interface PersonaSegmentCard {
  id: string;
  color: string;
  onRemoveClick?: () => void;
}

export function PersonaSegmentCard({
  id,
  color,
  onRemoveClick,
}: PersonaSegmentCard): ReactElement {
  const { loading, data } = useQuery<
    PersonaCardQuery,
    PersonaCardQueryVariables
  >(personaCardQuery, {
    variables: { id },
    fetchPolicy: "cache-first",
  });

  if (!loading && !data?.persona) {
    return <Card icon={<Warning />} name="Failed to load" />;
  }

  const { name, image_filename, cohort_members, count } = data?.persona ?? {};

  const icon = image_filename ? (
    <PersonaImage filename={image_filename} size="smedium" circular />
  ) : (
    <ResourceIcon.persona_sets />
  );

  return (
    <Card
      loading={loading}
      icon={icon}
      name={name}
      count={cohort_members ?? count}
      color={color}
      onRemoveClick={onRemoveClick}
    />
  );
}
