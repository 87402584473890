import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
} from "@chakra-ui/react";

import { Privacy } from "../../../../../__generated__/sojournerGlobalTypes";
import { ApiOptions } from "../../../../../services/connectionOptions";
import { AccordionV2 } from "../../../../ui/AccordionV2";
import { CardV2 } from "../../../../ui/Card/CardV2";
import { DatasetShowPageQuery_connections } from "../../../__generated__/DatasetShowPageQuery";
import { ConnectionTypeForm } from "../../../shared/DatasetsConnectionsForm";
import { DetectedColumn } from "../shared/types";

export function DatasetsAdvancedForm({
  upsertColumn,
  setUpsertColumn,
  detectedColumns,
  connection,
  options,
  setOptions,
  privacy,
  setPrivacy,
  error,
  setError,
  disabled,
}: {
  upsertColumn: string | null;
  setUpsertColumn: (newUpsertColumn: string | null) => void;
  detectedColumns: DetectedColumn[];
  connection: DatasetShowPageQuery_connections | undefined;
  options: ApiOptions;
  setOptions: (newOptions: ApiOptions) => void;
  privacy: Privacy | null;
  setPrivacy: (newPrivacy: Privacy | null) => void;
  error: string | undefined;
  setError: (newError: string) => void;
  disabled?: boolean;
}) {
  function setOptionsAndClearError(newOptions: ApiOptions) {
    setError("");
    setOptions(newOptions);
  }

  return (
    <CardV2>
      <AccordionV2 allowToggle>
        <AccordionV2.Item title="Advanced options">
          <FormControl mb={6}>
            <FormLabel>Primary key</FormLabel>
            <FormHelperText>
              If your underlying data is mutable (instead of append-only), you
              may wish to pick a column that we can use to identify rows as
              being the same. For example, if this is a Dataset of orders, order
              ID; or if this is a Dataset of people, email (preferred) or
              customer ID. If you don't pick a column, Faraday will consider the
              uniqueness of entire rows of data, so if 2 rows differ by a single
              cell, we will treat them as different rows and ingest both. Also
              known as an "upsert key."
            </FormHelperText>
            <Select
              defaultValue={upsertColumn ?? undefined}
              onChange={(e) => {
                setUpsertColumn(e.target.value ?? null);
                setError("");
              }}
              isDisabled={disabled}
            >
              <option key="upsert_key_null" value="">
                No - Faraday will automatically identify duplicate rows
              </option>
              {detectedColumns.map((column) => (
                <option key={column.name} value={column.name}>
                  {column.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl mb={6}>
            <FormLabel>Privacy</FormLabel>
            <FormHelperText>
              "Delete" excludes individuals matched in this dataset from being
              used for modeling, and removes them from all deployments.
              <br />
              "Suppress" continues to use individuals matched in this dataset
              for modeling, but excludes them from deployments.
            </FormHelperText>
            <Select
              value={privacy ?? undefined}
              onChange={(e) => {
                setPrivacy(
                  e.target.value === "" ? null : (e.target.value as Privacy)
                );
                setError("");
              }}
              isDisabled={disabled}
            >
              <option value="">None</option>
              <option value={Privacy.DELETE}>Delete</option>
              <option value={Privacy.SUPPRESS}>Suppress</option>
            </Select>
          </FormControl>

          {/* can't edit classic connection */}
          {options.__typename === "DatasetOptionsClassic" || disabled ? null : (
            <ConnectionTypeForm
              connection={connection}
              options={options}
              setOptions={setOptionsAndClearError}
              displayContents={true}
            />
          )}
        </AccordionV2.Item>
      </AccordionV2>
      <FormControl isInvalid={!!error}>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </CardV2>
  );
}
