import { Tooltip } from "@chakra-ui/react";
import { Info } from "@phosphor-icons/react";
import { ReactNode } from "react";

import { colors } from "../../styles/chakra-theme-v2";

/**
 * An text span that displays an info icon and a tooltip.
 */
export function TextWithInfoTooltip({
  title,
  tooltip,
}: {
  title: ReactNode;
  tooltip: string;
}) {
  return (
    <Tooltip hasArrow label={tooltip}>
      <span
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        {title} <Info color={colors.fdy_gray[500]} weight="fill" />
      </span>
    </Tooltip>
  );
}
