import styled from "@emotion/styled";
import { ReactElement, ReactNode } from "react";

import {
  SegmentSubtype,
  SegmentType,
} from "../../../__generated__/globalTypes";
import { theme } from "../../../constants/theme";
import { colors } from "../../../styles/chakra-theme-v2";
import { Scroll } from "../../ui/Scroll";
import { PresetTabs } from "../presets";
import { PresetSegmentCard } from "./PresetSegmentCard";
import { SegmentInfo, useExploreParams } from "./useExploreParams";

const StyledSegmentList = styled.div`
  padding: ${theme.space(4)};
  width: 100%; // important this is 100% to allow elements rendered after segments items to fill area (specifically analysis view charts)
`;

const StyledItem = styled.div`
  margin-bottom: ${theme.space(1)};
  display: flex;
  width: 100%;
`;

const StyledAfterCard = styled.div`
  width: 100%;
  padding-left: ${theme.space(1)};
  padding-right: ${theme.space(1)};
`;

type RenderAfterSegment = ({
  color,
}: { color: string } & SegmentInfo) => ReactElement;

interface SegmentListItemProps extends SegmentInfo {
  color: string;
  renderAfterSegment?: RenderAfterSegment;
  children: ReactNode;
}

function SegmentListItem({
  id,
  color,
  type,
  subtype,
  renderAfterSegment,
  children,
}: SegmentListItemProps) {
  return (
    <StyledItem>
      {/* plain div for flexbox parent and better cooperation with renderAfter element */}
      <div>{children}</div>

      {renderAfterSegment ? (
        <StyledAfterCard>
          {renderAfterSegment({
            id,
            color,
            type,
            subtype,
          })}
        </StyledAfterCard>
      ) : null}
    </StyledItem>
  );
}

interface SegmentListProps {
  /** Optionally render an element to the right of the segment card. Used to render analysis charts. */
  renderAfterSegment?: RenderAfterSegment;

  segments: SegmentInfo[];

  showBaseline?: boolean;
}

export function SegmentListBeta({
  segments,
  renderAfterSegment,
  showBaseline,
}: SegmentListProps): ReactElement {
  const { removeSegment } = useExploreParams();

  // Add baseline to segment list
  // Originally the old explore would be smart about applying this.
  // If there was a segment that was equivalent of baseline, then it wasn't shown. For now, just always show it
  // unless we're in map view.
  const segmentsWithBaseline = showBaseline
    ? [
        ...segments,
        {
          id: "national",
          type: SegmentType.National,
          subtype: SegmentSubtype.national,
        },
      ]
    : segments;

  const canAddSegment = segments.length < 7;

  return (
    <Scroll hideBars>
      <StyledSegmentList>
        {segmentsWithBaseline.map(({ id, type, subtype }) => {
          return (
            <SegmentListItem
              key={id + subtype}
              id={id}
              color={colors.fdy_magenta[500]}
              type={type}
              subtype={subtype}
              renderAfterSegment={renderAfterSegment}
            >
              <PresetSegmentCard
                id={id}
                color={colors.white}
                type={type}
                subtype={subtype}
                removeSegment={removeSegment}
              />
            </SegmentListItem>
          );
        })}

        {canAddSegment && <PresetTabs />}
      </StyledSegmentList>
    </Scroll>
  );
}
