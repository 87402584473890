import { LineSeries } from "@visx/xychart";

import { colors } from "../../../styles/chakra-theme-v2";
import { AxisBottom } from "../../ui/charts-v2/AxisBottom";
import { AxisLeft } from "../../ui/charts-v2/AxisLeft";
import { Chart } from "../../ui/charts-v2/Chart";

interface CurveDatum {
  x: number;
  y: number;
}

interface RecommenderAccuracyCurveChartProps<Datum extends CurveDatum> {
  title: string;
  data: Datum[];
  yLabel: string;
  xLabel: string;
}

export function RecommenderAccuracyCurveChart<Datum extends CurveDatum>({
  data,
  yLabel,
  xLabel,
  title,
}: RecommenderAccuracyCurveChartProps<Datum>) {
  return (
    <Chart
      title={title}
      yScale={{ type: "linear" }}
      xScale={{ type: "linear" }}
    >
      <LineSeries
        dataKey="lines"
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        stroke={colors.fdy_green[600]}
      />

      <AxisLeft label={yLabel} />
      <AxisBottom label={xLabel} hideZero />
    </Chart>
  );
}
