import { gql } from "@apollo/client";

import { outcomeFragment } from "./outcomeFragment";

export const outcomesQuery = gql`
  query OutcomesQuery {
    outcomes {
      ...OutcomeFragment
    }
  }
  ${outcomeFragment}
`;
