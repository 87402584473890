import { gql } from "@apollo/client";

import { connectionOptionsTypesFragment } from "../../connectionOptionsFragments";
import { datasetFragment } from "./datasetFragment";

export const datasetConnectionFragment = gql`
  fragment DatasetConnectionFragment on Connection {
    id
    name
    managed
    directionality
    contents {
      name
    }
    archivedAt
    ...ConnectionOptionsTypes
  }
  ${connectionOptionsTypesFragment}
`;

export const datasetsTableQuery = gql`
  query DatasetsTableQuery {
    datasets {
      id
      name
      connectionId
      enrichment
      rowCount
      identifiedCount
      matchedCount
      identitySets
      outputToStreams
      outputToTraits
      preview
      managed
      archivedAt
      createdAt
      status
      statusError
      lastUpdatedOutputAt
      lastUpdatedConfigAt
      lastReadInputAt
      detectedColumns {
        name
        fillRate
        dataType
      }
      sample
      updates {
        datetime
      }
      mergeDatasets {
        datasetId
        joinColumn
      }
    }

    connections {
      ...DatasetConnectionFragment
    }
  }
  ${datasetConnectionFragment}
`;

export const datasetEditMutation = gql`
  mutation DatasetEditMutation($datasetId: ID!, $dataset: String!) {
    updateDataset(
      datasetId: $datasetId
      applicationJsonMergePatchInput: $dataset
    ) {
      ...DatasetFragment
    }
  }
  ${datasetFragment}
`;

export const datasetShowPageQuery = gql`
  query DatasetShowPageQuery($datasetId: ID!) {
    dataset(datasetId: $datasetId) {
      ...DatasetFragment
    }
    connections {
      ...DatasetConnectionFragment
    }
  }
  ${datasetFragment}
  ${datasetConnectionFragment}
`;
