import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ReactElement } from "react";

import { env } from "../../../env";
import { useAdmin } from "../../../hooks/useAdmin";
import { AnalyticsStack } from "../Analytics/AnalyticsStack";
import { Button } from "../Button";
import { CopyInputV2 } from "../CopyInputV2";

interface ApiErrorProps {
  apiErrorId: string;
}

function ApiError({ apiErrorId }: ApiErrorProps) {
  const { admin } = useAdmin();

  const pullRequest = env.PR_NUMBER ? "-" + env.PR_NUMBER : "";
  const sojoGcpLogs = `https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.container_name%3D%22sojourner${pullRequest}%22%0A${apiErrorId};cursorTimestamp=PT1H?project=prod-gke-e788`;

  return (
    <VStack my={4} align="stretch">
      <Box my={2}>
        <Text>API Error ID:</Text>
        <CopyInputV2 value={apiErrorId} />
      </Box>
      {admin && (
        <div style={{ textAlign: "center" }}>
          <a href={sojoGcpLogs} style={{ textDecoration: "underline" }}>
            API logs on GCP (only admins see this) &rarr;
          </a>
        </div>
      )}
    </VStack>
  );
}

export interface ErrorPageProps {
  title?: string;
  errorId?: string;
  apiErrorId?: string;
  message?: string;
  onBack?: () => void;
  onReload?: () => void;
  onReset?: () => void;
}

export function ErrorPage({
  title = "We've encountered an error.",
  errorId,
  apiErrorId,
  message = "Something unexpected happened. A Faraday support engineer has been notified.",
  onBack,
  onReload,
  onReset,
}: ErrorPageProps): ReactElement {
  return (
    <AnalyticsStack value="error-box">
      <Center flexGrow={1} flexShrink={1}>
        <Flex
          flexDirection="column"
          maxWidth="1000px"
          alignItems="center"
          align="stretch"
        >
          <div>
            <Box mb={4}>
              <Heading size="md">{title}</Heading>
              <Text my={2}>{message}</Text>
            </Box>

            {errorId && (
              <Box>
                <Text>Client error ID:</Text>
                <CopyInputV2 value={errorId} />
              </Box>
            )}

            {apiErrorId && <ApiError apiErrorId={apiErrorId} />}

            <ButtonGroup mt={2}>
              {onBack !== undefined && (
                <Button
                  variant="secondary"
                  onClick={onBack}
                  analyticsName="back"
                >
                  Go back
                </Button>
              )}
              {onReload !== undefined && (
                <Button onClick={onReload} analyticsName="reload">
                  Reload the page
                </Button>
              )}
              {onReset !== undefined && (
                <Button
                  variant="secondary"
                  onClick={onReset}
                  analyticsName="reset"
                >
                  Try reset
                </Button>
              )}
            </ButtonGroup>
          </div>
        </Flex>
      </Center>
    </AnalyticsStack>
  );
}
