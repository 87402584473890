import { gql, useMutation, useQuery } from "@apollo/client";
import { MockedResponse } from "@apollo/client/testing";
import { useCallback } from "react";
import { useRouter } from "react-router5";

import { TestModeQuery } from "./__generated__/TestModeQuery";
import { ToggleTestMode } from "./__generated__/ToggleTestMode";

const TOGGLE_TEST_MODE_MUTATION = gql`
  mutation ToggleTestMode {
    toggle_test_mode {
      id
    }
  }
`;

const TEST_MODE_QUERY = gql`
  query TestModeQuery {
    account {
      id
      test_mode
      test_account {
        id
        test_mode
      }
    }
  }
`;

/**
 * A hook to get the test mode status of an account and toggle it.
 */
export function useTestMode() {
  const { data, loading, error } = useQuery<TestModeQuery>(TEST_MODE_QUERY);
  const router = useRouter();

  const [
    toggleTestModeMutation,
    { error: toggelTestModeError, loading: toggling },
  ] = useMutation<ToggleTestMode>(TOGGLE_TEST_MODE_MUTATION);

  if (error) throw error;
  if (toggelTestModeError) throw toggelTestModeError;

  const isInTestMode = data?.account?.test_mode === true;
  const hasTestAccount = data?.account?.test_account?.test_mode === true;
  const accountId = data?.account?.id;

  const toggleTestMode = useCallback(async () => {
    await toggleTestModeMutation();
    window.location.reload();
  }, [toggleTestModeMutation, router]);

  return {
    isInTestMode,
    hasTestAccount,
    loading,
    toggling,
    toggleTestMode,
    accountId,
  };
}

// Mock the account test mode query for testing.
// This is KOPENG query, so add it to `kopengMocks` option if you're using
// createTestWrapper from testUtils.tsx
export function makeTestModeQuery(): MockedResponse<TestModeQuery> {
  return {
    request: {
      query: TEST_MODE_QUERY,
      variables: {},
    },
    result: {
      data: {
        account: {
          __typename: "Account",
          id: "57e6e069-64bb-4129-83a8-cea1a577f2d9",
          test_mode: false,
          test_account: {
            __typename: "Account",
            id: "47433faf-7ee8-41a8-9a7c-eb58f45b9d44",
            test_mode: true,
          },
        },
      },
    },
  };
}
