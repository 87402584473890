import { number, percent } from "../../../utils/formatters";
import { Persona } from "../personaSetFragment";
import {
  PersonaGridRowCell,
  PersonaGridRowDivider,
  PersonaGridRowHeader,
  PersonaSetAnalysisGridRow,
  PersonaSetAnalysisGridSection,
} from "./PersonaSetAnalysisGrid";

interface PersonaSizesProps {
  personas: Persona[];
}

/**
 * Render a pseudo table of the various 'size' counts and tallies of the personas.
 * Example: the size of the persona within the cohort it was based on.
 */
export function PersonaSetAnalysisSizes({ personas }: PersonaSizesProps) {
  const cols = personas.length + 1;

  const totals = personas.map((p) => p.individualsCount ?? 0);

  const total = totals.reduce((acc, t) => acc + t, 0);

  const sizeRows = [
    {
      key: "individuals",
      label: "Individuals",
      values: totals.map((t) => number(t)),
    },
    {
      key: "set_percent",
      label: "% of total persona set",
      values: totals.map((t) => percent(t / total)),
    },
  ];

  return (
    <PersonaSetAnalysisGridSection label="Size">
      {sizeRows.map((row) => {
        return (
          <PersonaGridRowDivider key={row.key}>
            <PersonaSetAnalysisGridRow columns={cols}>
              <PersonaGridRowHeader title={row.label} />
              {row.values.map((value, i) => (
                <PersonaGridRowCell key={i}>{value}</PersonaGridRowCell>
              ))}
            </PersonaSetAnalysisGridRow>
          </PersonaGridRowDivider>
        );
      })}
    </PersonaSetAnalysisGridSection>
  );
}
