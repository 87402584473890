import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useTestMode } from "../../../hooks/useTestMode";
import { CardV2 } from "../../ui/Card/CardV2";
import { CardStack } from "../../ui/CardStack";
import { LeaveTestModeNotice } from "../account/LeaveTestModeNotice";
import { SettingsLayout } from "../SettingsLayout";
import { InviteUserForm } from "./InviteUserForm";
import { MemberTable } from "./MemberTable";

export function TeamPage() {
  const { isInTestMode } = useTestMode();

  return (
    <SettingsLayout
      title="Team"
      lastCrumb={{
        label: "Team",
        routeName: ROUTE_NAMES.SETTINGS_MEMBERS,
      }}
    >
      <CardStack>
        <CardV2
          title="Account users"
          text="Manage who has access to your account."
        >
          <MemberTable />
        </CardV2>

        <CardV2>
          {isInTestMode ? (
            <LeaveTestModeNotice title="Leave test mode to invite users." />
          ) : (
            <InviteUserForm />
          )}
        </CardV2>
      </CardStack>
    </SettingsLayout>
  );
}
