import { gql } from "@apollo/client";
import { Spinner, Text } from "@chakra-ui/react";

import { useSojournerQuery } from "../../services/sojournerApolloClient";
import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../ui/ConfigBreakdownTable";
import { ResourceIcon } from "../ui/ResourceIcon";
import { PipelinesTableQuery_scopes as Scope } from "./__generated__/PipelinesTableQuery";
import { ScopePayloadQuery } from "./__generated__/ScopePayloadQuery";
import { ResourceLinks } from "./ResourceLinks";
import { ScopePayloadBreakdownTraits } from "./ScopePayloadBreakdownTraits";

export const SCOPE_PAYLOAD_QUERY = gql`
  query ScopePayloadQuery($id: ID!) {
    scope(scopeId: $id) {
      id
      name
      payload {
        explainability
        attributes
      }
      payloadCohorts {
        id
        name
      }
      payloadOutcomes {
        id
        name
      }
      payloadPersonaSets {
        id
        name
        personas {
          id
          name
        }
      }
      payloadRecommenders {
        id
        name
      }
    }
  }
`;

export function ScopePayloadBreakdown({
  scope,
  caption,
}: {
  scope: Scope;
  caption?: string;
}) {
  const { data, loading, error } = useSojournerQuery<ScopePayloadQuery>(
    SCOPE_PAYLOAD_QUERY,
    {
      variables: { id: scope.id },
    }
  );

  if (error) throw error;
  if (loading) return <Spinner />;
  if (!data?.scope) throw new Error("No scope found yet API did not throw 404");

  const {
    payloadCohorts,
    payloadOutcomes,
    payloadPersonaSets,
    payloadRecommenders,
    payload: { attributes, explainability },
  } = data.scope;

  return (
    <ConfigBreakdownTable caption={caption}>
      {payloadCohorts.length > 0 && (
        <ConfigBreakdownTableRow
          icon={ResourceIcon.cohorts}
          header="Cohorts"
          value={<ResourceLinks resources={payloadCohorts} />}
        />
      )}
      {payloadOutcomes.length > 0 && (
        <ConfigBreakdownTableRow
          icon={ResourceIcon.outcomes}
          header="Outcomes"
          value={
            <>
              <ResourceLinks resources={payloadOutcomes} />
              {explainability && (
                <Text sx={{ color: "fdy_gray.700", mt: 2 }}>
                  Outcomes will include explainability (most predictive
                  features).
                </Text>
              )}
            </>
          }
        />
      )}
      {payloadPersonaSets.length > 0 && (
        <ConfigBreakdownTableRow
          icon={ResourceIcon.persona_sets}
          header="Persona sets"
          value={<ResourceLinks resources={payloadPersonaSets} />}
        />
      )}
      {payloadRecommenders.length > 0 && (
        <ConfigBreakdownTableRow
          icon={ResourceIcon.recommenders}
          header="Recommenders"
          value={<ResourceLinks resources={payloadRecommenders} />}
        />
      )}
      {attributes.length > 0 && (
        <ConfigBreakdownTableRow
          icon={ResourceIcon.traits}
          header="Traits"
          value={<ScopePayloadBreakdownTraits attributes={attributes} />}
        />
      )}
    </ConfigBreakdownTable>
  );
}
