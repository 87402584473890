import { ReactNode, Suspense } from "react";

import { AnalyticsStack } from "./Analytics/AnalyticsStack";
import { AnimatedZapLogo } from "./AnimatedZapLogo";
import { ErrorBoundary } from "./ErrorBoundary";

interface PageLayoutProps {
  children: ReactNode;
  analyticsStackName?: string;
}

/**
 * Use this layout for pages that use the new faraday design language.
 */
export function PageLayout({ analyticsStackName, children }: PageLayoutProps) {
  return (
    <AnalyticsStack value={analyticsStackName}>
      <ErrorBoundary>
        <Suspense fallback={<AnimatedZapLogo />}>{children}</Suspense>
      </ErrorBoundary>
    </AnalyticsStack>
  );
}
