import { Notice } from "../../../ui/Notice";

export function LookupTargetNotice() {
  return (
    <Notice
      variant="info"
      title="Note:"
      description="Lookup API deployments must adhere to certain output formats, so some settings are disabled."
      sx={{ mb: 4 }}
    />
  );
}
