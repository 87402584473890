// These utils are rather outcome-centric, but we aren't sharing them outside yet, so nbd.
// Perhaps grid creation and find utils could be a concern of the grid component
// itself, but we use them in rendering other elements.

import {
  Grade,
  outcomeGradesGrid,
  outcomeGradeWarnings,
  outcomeROCGrades,
} from "./outcomeGrades";

type GradeCell = Grade & {
  x: number;
  y: number;
};

type GridLayout = GradeCell[][];

interface FindOutcomeGradeArgs {
  rocAuc: number;
  liftValue?: number | null;
  usesFPD: boolean;
}

function getOutcomeWarningGrade(usesFPD: boolean): Grade {
  return usesFPD ? outcomeGradeWarnings["1pd"] : outcomeGradeWarnings["3pd"];
}

export function getOutcomeGradesGrid(usesFPD: boolean): Grade[][] {
  return usesFPD ? outcomeGradesGrid["1pd"] : outcomeGradesGrid["3pd"];
}

export function getOutcomeGrades(usesFPD: boolean): Grade[] {
  return usesFPD ? outcomeROCGrades["1pd"] : outcomeROCGrades["3pd"];
}

/**
 * Given a list of grades, creates a grid of cells where the x and y axes are
 * the same list of grades. This allows us to find the grade for a given ROC
 * and lift value by finding the intersection of the ROC and lift values in the grid.
 */
export function createOutcomeGradeGrid(grades: Grade[][]): GridLayout {
  return grades.map((y, yIdx) => {
    return y.map((x, xIdx) => {
      return {
        ...x,
        x: xIdx,
        y: yIdx,
      };
    });
  });
}

export function findOutcomeGrade({
  rocAuc,
  liftValue,
  usesFPD,
}: FindOutcomeGradeArgs) {
  if (liftValue === null) {
    const grades = getOutcomeGrades(usesFPD);
    return grades.find((grade) => {
      return valueInRange({
        value: rocAuc,
        range: grade.rocRange,
        maxInclusive: grade.rocMaxInclusive,
      });
    });
  }
  const grades = getOutcomeGradesGrid(usesFPD);
  const grid = createOutcomeGradeGrid(grades);
  return findGradeCellInGrid({
    grid,
    roc: rocAuc,
    lift: liftValue,
  });
}

export function valueInRange({
  value,
  range,
  maxInclusive = false,
}: {
  value: number;
  range: [number | null, number | null];
  maxInclusive?: boolean;
}): boolean {
  const [min, max] = range;

  // If range is null, we consider it infinity, so it's always in range
  const minMatch = min === null || value >= min;
  const maxMatch = max === null || (maxInclusive ? value <= max : value < max);

  return minMatch && maxMatch;
}

function checkValuesInRanges({
  lift,
  roc,
  liftRange,
  rocRange,
  rocMaxInclusive,
  liftMaxInclusive,
  requireBoth = true,
}: {
  roc: number;
  lift?: number | null;
  rocRange: [number | null, number | null];
  liftRange?: [number | null, number | null];
  rocMaxInclusive?: boolean;
  liftMaxInclusive?: boolean;
  requireBoth?: boolean;
}): boolean {
  const rocMatch = valueInRange({
    value: roc,
    range: rocRange,
    maxInclusive: rocMaxInclusive,
  });

  if (lift === null || lift === undefined) return rocMatch;
  if (!liftRange)
    throw new Error("liftRange is required when lift is provided");

  const liftMatch = valueInRange({
    value: lift,
    range: liftRange,
    maxInclusive: liftMaxInclusive,
  });

  if (requireBoth) {
    return rocMatch && liftMatch;
  } else {
    return rocMatch || liftMatch;
  }
}

/**
 * Finds the grade in the grid that matches the ROC and lift values.
 * We want to wrapup the resulting label for both a ROC and lift value into a
 * single term and color, so we need to find the intersection of the ROC and
 * lift values in the grid.
 */
export function findGradeCellInGrid({
  grid,
  roc,
  lift,
}: {
  grid: GridLayout;
  roc: number;
  lift: number | null | undefined;
}): GradeCell | null {
  let gridCell: GradeCell | null = null;

  for (const row of grid) {
    for (const cell of row) {
      const inRange = checkValuesInRanges({
        roc,
        lift,
        rocRange: cell.rocRange,
        liftRange: cell.liftRange,
        rocMaxInclusive: cell.rocMaxInclusive,
        liftMaxInclusive: cell.liftMaxInclusive,
      });

      if (inRange) {
        gridCell = cell;
        break;
      }
    }
  }

  return gridCell;
}

/**
 * Finds the grade in the grid that matches the ROC and lift values.
 * If lift is not provided, it will only match on ROC using the normal grade list.
 * Useful because some outcomes don't have lift values stored yet.
 */
export function findGradeInGridOrFallback({
  grades,
  grid,
  roc,
  lift,
}: {
  grades: Grade[];
  grid: GridLayout;
  roc: number;
  lift?: number | null;
}): GradeCell | Grade | undefined | null {
  if (lift === null || lift === undefined) {
    return grades.find((grade) =>
      valueInRange({
        value: roc,
        range: grade.rocRange,
        maxInclusive: grade.rocMaxInclusive,
      })
    );
  }

  return findGradeCellInGrid({ grid, roc, lift });
}

/**
 * Check if the outcome performance is in the warning range.
 * Matches if EITHER the ROC or lift is in the warning range,
 * not both (like other grade comparisons).
 */
export function isOutcomePerformanceInWarning({
  usesFPD,
  roc,
  lift,
}: {
  usesFPD: boolean;
  roc: number;
  lift?: number | null;
}): boolean {
  const grade = getOutcomeWarningGrade(usesFPD);

  return checkValuesInRanges({
    roc,
    lift,
    rocRange: grade.rocRange,
    liftRange: grade.liftRange,
    rocMaxInclusive: grade.rocMaxInclusive,
    liftMaxInclusive: grade.liftMaxInclusive,
    requireBoth: false,
  });
}
