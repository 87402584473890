import { Box } from "@chakra-ui/react";

import { Trait } from "../../../../hooks/useTraitsQuery";
import { Button } from "../../../ui/Button";
import { ErrorText } from "../../../ui/ErrorText";
import { InlineButtons } from "../../../ui/InlineButtons";
import { ModalV2 } from "../../../ui/ModalV2";
import {
  TraitSearchCriteria,
  TraitSelector,
} from "../../../ui/TraitSelectorModal";

export function CohortTraitsSelectModal({
  availableTraits,
  onCancel,
  onTraitSelect,
  onNext,
  selectedTrait,
  error,
  searchCriteria,
  setSearchCriteria,
}: {
  availableTraits: Trait[];
  onCancel: () => void;
  onTraitSelect: (trait: Trait) => void;
  onNext: () => void;
  selectedTrait: Trait | undefined;
  error?: string;
  searchCriteria: TraitSearchCriteria;
  setSearchCriteria: (criteria: TraitSearchCriteria) => void;
}) {
  return (
    <ModalV2
      variant="flush"
      title="Add trait"
      isOpen={true}
      onClose={onCancel}
      scrollBehavior="inside"
      width={800}
      footer={
        <Box>
          <ErrorText>{error}</ErrorText>
          <InlineButtons reverse>
            <Button variant="primary" onClick={onNext} analyticsName="next">
              Next
            </Button>
            <Button
              variant="tertiary"
              onClick={onCancel}
              analyticsName="cancel"
            >
              Cancel
            </Button>
          </InlineButtons>
        </Box>
      }
      analyticsStackName="select-trait"
    >
      <TraitSelector
        selectedTrait={selectedTrait}
        setSelectedTrait={onTraitSelect}
        availableTraits={availableTraits}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
      />
    </ModalV2>
  );
}
