import { Notice } from "../Notice";
import { getStatusInfoFromResource } from "./getStatusInfoFromResource";
import { MixedResource } from "./normalizeResourceDetails";

interface ResourceStatusAlertProps {
  resource: MixedResource;
  titlePrefix?: string;
}

export function ResourceStatusAlert({
  resource,
  titlePrefix,
}: ResourceStatusAlertProps) {
  const info = getStatusInfoFromResource({ resource });

  if (info) {
    const alertTitle = titlePrefix
      ? `${titlePrefix} ${info.title.toLowerCase()}`
      : info.title;
    return (
      <Notice
        variant={info.variant}
        title={alertTitle}
        icon={info.icon}
        description={info.description}
      />
    );
  }

  return null;
}
