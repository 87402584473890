import { gql } from "@apollo/client";

export const pipelinesQuery = gql`
  query PipelinesTableQuery {
    scopes {
      id
      name
      status
      statusError
      preview
      createdAt
      archivedAt
      lastUpdatedOutputAt
      lastUpdatedConfigAt
      lastReadInputAt
      population {
        cohortIds
        exclusionCohortIds
      }
      payload {
        attributes
        cohortIds
        explainability
        outcomeIds
        personaSetIds
        recommenderIds
      }
    }
  }
`;
