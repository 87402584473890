import { useMemo } from "react";

import { formatFilterValue } from "../../../utils/filters";
import { AnalysisMap, Field } from "./types";

function average(values: number[]): number {
  return values.reduce((a, b) => a + b, 0) / values.length;
}

function signal(field: Field, data: Record<string, number>[]): number | null {
  // nullify signal if less than half the audiences are at least 20% covered
  // excepting has_solar, pool, and private fields
  const exceptional =
    new Set(["has_solar", "pool"]).has(field.name) ||
    field.name.match(/^private\//);

  const minimumCoverage = exceptional ? 0 : 0.2;

  const coveredCount = data.filter(
    ({ coverage }) => coverage > minimumCoverage
  ).length;

  if (coveredCount / data.length < 0.5) {
    return null;
  }

  // nullify signal if it's all zeroes
  if (data.every((d) => d.true_rate === 0 || d.max === 0)) {
    return null;
  }

  // boolean value
  if (field.filter_type === "boolean") {
    const result = data.map((d) => d.true_rate).filter((d) => d !== null);
    if (result.length < 2) return 0;

    return variance(result);
  }

  // range value
  if (field.filter_type === "range") {
    const result = data.filter((d) => d.q2 !== null);

    if (result.length < 2) return 0;

    const min = Math.min(...result.map((d) => d.q1));
    const max = Math.max(...result.map((d) => d.q3));

    const normalize = (d: number) => (d - min) / (max - min);

    // a weighted average of the variance of each stat
    return (
      variance(result.map((d) => normalize(d.q1))) * 0.25 +
      variance(result.map((d) => normalize(d.q2))) * 0.5 +
      variance(result.map((d) => normalize(d.q3))) * 0.25
    );
  }

  if (field.filter_type?.toLowerCase() === "categorical") {
    const keys = Object.keys(data[0]).filter((k) => k !== "coverage");
    const variances = keys.map((k) => variance(data.map((d) => d[k])));
    return average(variances);
  }
  return null;
}

function variance(values: number[]) {
  const avg = average(values);
  const val =
    values.map((d) => Math.abs(d - avg)).reduce((a, b) => a + b) /
    (values.length - 1);

  return isNaN(val) ? 0 : val;
}

function facetsBySignal<TField extends Field>(
  analysis: AnalysisMap | undefined,
  fields: Record<string, TField>
) {
  // no analysis, no facets:
  if (
    !analysis ||
    !fields ||
    Object.keys(analysis).length === 0 ||
    Object.keys(fields).length === 0
  ) {
    return [];
  }

  const { national, ...segmentMap } = analysis;

  // If we have a lot of segments to work with, we can calculate signal. Ideally without the national audience:
  if (Object.keys(analysis).length >= 2) {
    // if we have just one audience and a national audience, include the
    // national audience in the signal calculation:
    const segments =
      Object.values(segmentMap).length < 2
        ? Object.values(analysis)
        : Object.values(segmentMap);
    const fieldNames = Object.keys(segments[0]);

    return fieldNames
      .map((name) => {
        const field = fields[name];
        if (!field) throw new Error(`Field ${name} not found in fields`);
        return {
          field,
          name: field.literate ?? name,
          fieldName: name,
          signal: signal(
            field,
            segments.map((data) => data[name]).filter((v) => v)
          ),
        };
      })
      .filter(({ signal }) => signal !== null)
      .sort((a, b) => Number(b.signal) - Number(a.signal));
  }

  const segment = Object.values(analysis)[0];
  const fieldNames = Object.keys(segment);

  return fieldNames
    .filter(
      (name) =>
        segment[name].coverage !== 0 &&
        segment[name].true_rate !== 0 &&
        segment[name].max !== 0
    )
    .map((name) => {
      const field = fields[name];
      if (!field) throw new Error(`Field ${name} not found in fields`);
      return {
        field,
        name: field.literate ?? name,
        fieldName: name,
        signal: null,
      };
    });
}

export function useFacetsBySignal<T extends Field>(
  analysis: AnalysisMap | undefined,
  fields: T[] | undefined
): {
  field: T;
  fieldName: string;
  name: string;
  signal: number | null;
}[] {
  const fieldMap = useMemo(() => {
    if (!fields) {
      return {};
    }

    return fields.reduce((acc, field) => ({ ...acc, [field.name]: field }), {});
  }, [fields && fields.map((f) => f.name).join("::")]);

  return useMemo(
    () => facetsBySignal<T>(analysis, fieldMap),
    [analysis, fieldMap]
  );
}

/**
 * Use Arianna to format a dataset value.
 *
 * For date fields, because analysis charts deal in unix times (seconds),
 * we need to ensure they convert to milliseconds before Arianna parses them
 * otherwise the date is way off.
 */
export function formatBinLabel(field: Field, value: number): string {
  // lowercase because alchemist fields are lowercase and fdy fields are not.
  if (field.type?.toLowerCase() === "date") {
    return formatFilterValue(field, value * 1000);
  }

  return formatFilterValue(field, value);
}
