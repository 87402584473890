import { gql } from "@apollo/client";

// todo: rename to scope fragment
export const pipelineFragment = gql`
  fragment PipelineFragment on Scope {
    id
    name
    preview
    archivedAt
    status
    statusError
    populationCount
    lastUpdatedOutputAt
    lastUpdatedConfigAt
    lastReadInputAt
    createdAt
    population {
      cohortIds
      exclusionCohortIds
    }
    payload {
      attributes
      cohortIds
      explainability
      outcomeIds
      personaSetIds
      recommenderIds
    }

    columns {
      payloadColumns {
        name
        humanName
      }
      columnsForMode {
        aggregated {
          scopeCol
          outputCol
          aggregation
          aggregationCriteria
        }
        hashed {
          scopeCol
          outputCol
        }
        identified {
          scopeCol
          outputCol
          isIdentityCol
        }
        referenced {
          scopeCol
          outputCol
        }
      }
    }

    # TODO: make this a separate query? Don't include this as part of fragment. we don't need it for all queries.
    # It slows down the responses when this fragment is used.
    targets {
      id
    }
  }
`;
