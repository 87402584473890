/**
 * Go through an array and trim its contents, filtering out null
 * and empty values. Returning undefined if there are no items left.
 */
export const trimAndFilterEmptyValues = (
  list: (string | null)[] | null | undefined
) => {
  const items = list
    ?.map((item) => item?.trim())
    .filter(
      (item): item is string =>
        item !== null && item !== undefined && item.length > 0
    );

  if (!items || items.length === 0) return undefined;
  return items;
};
