import { Td, Tr } from "@chakra-ui/react";
import { useMemo, useState } from "react";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { cohortsListPageQuery } from "../../cohorts/CohortsListPage";
import {
  CohortsListPageQuery,
  CohortsListPageQuery_cohorts,
} from "../../cohorts/CohortsListPage/__generated__/CohortsListPageQuery";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTableList } from "../../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../../ui/ResourceTableTabs";
import { SortableHeader, SortableTable } from "../../ui/SortableTable";
import { TimeAgo } from "../../ui/TimeAgo";
import {
  OutcomesQuery,
  OutcomesQuery_outcomes as Outcome,
} from "../__generated__/OutcomesQuery";
import { outcomeEligibleCohortName } from "../outcomeEligibleCohortName";
import { OutcomeActionsMenu } from "../OutcomesActionsMenu";
import { outcomesQuery } from "../outcomesQuery";
import { OutcomeGradeBadge } from "../OutcomesShowPage/OutcomePerformanceCard/OutcomeGradeBadge";
import { cohortUsesFirstPartyData } from "../OutcomesShowPage/useOutcomeUsesFirstPartyData";

const BLANK_SLATE_PROPS = {
  title: "No outcomes found",
  text: "Outcomes show you the propensity of US consumers to take an action (as defined by a Cohort).",
  button: {
    routeName: ROUTE_NAMES.COHORTS,
    children:
      "Use Cohorts to create groups of US consumers to be used in an Outcome.",
  },
};

const tableHeaders: SortableHeader<Outcome>[] = [
  {
    key: "name",
    label: "Name",
  },
  {
    label: "Performance",
  },
  {
    key: "eligibleCohortName",
    label: "Eligibility",
    sortingFn(a, b, descending) {
      const aName = outcomeEligibleCohortName(a);
      const bName = outcomeEligibleCohortName(b);
      if (descending) {
        return bName.localeCompare(aName);
      } else {
        return aName.localeCompare(bName);
      }
    },
  },
  {
    key: "attainmentCohortName",
    label: "Attainment",
  },
  {
    key: "attritionCohortName",
    label: "Attrition",
  },
  {
    key: "status",
    label: "Status",
    sortingFn: "string",
  },
  {
    key: "createdAt",
    label: "Created",
    sortingFn: "date",
  },
  {
    key: "lastUpdatedConfigAt",
    label: "Modified",
    sortingFn: "date",
  },
  {
    key: "lastUpdatedOutputAt",
    label: "Processed",
    sortingFn: "date",
  },
  {
    label: "Actions",
    width: 64,
    visuallyHidden: true,
  },
];

export function OutcomesTable() {
  const { data, loading, error } = useSojournerQuery<OutcomesQuery>(
    outcomesQuery,
    {
      pollInterval: 10000,
    }
  );

  const {
    data: cohortsData,
    loading: cohortsLoading,
    error: cohortsError,
  } = useSojournerQuery<CohortsListPageQuery>(cohortsListPageQuery);

  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);

  const filteredOutcomes = useMemo(
    () => filterResourceTabs(data?.outcomes, activeTab),
    [data, activeTab]
  );

  const renderOutcomeRow = (outcome: Outcome) => {
    return (
      <OutcomeTableRow outcome={outcome} cohorts={cohortsData?.cohorts ?? []} />
    );
  };

  return (
    <>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data?.outcomes}
      />
      <SortableTable
        storageKey="outcomes"
        defaultSort={{
          key: "createdAt",
          desc: true,
        }}
        data={filteredOutcomes}
        headers={tableHeaders}
        renderData={(data) => (
          <ResourceTableList<Outcome>
            data={data}
            loading={loading || cohortsLoading}
            renderData={renderOutcomeRow}
            error={error || cohortsError}
            blankSlateProps={BLANK_SLATE_PROPS}
          />
        )}
      />
    </>
  );
}

function OutcomeTableRow({
  outcome,
  cohorts,
}: {
  outcome: Outcome;
  cohorts: CohortsListPageQuery_cohorts[];
}) {
  const outcomeHasError = outcome.status === ResourceStatus.ERROR;

  const eligibleCohort = cohorts.find((c) => c.id === outcome.eligibleCohortId);
  const attainmentCohort = cohorts.find(
    (c) => c.id === outcome.attainmentCohortId
  );
  const attritionCohort = cohorts.find(
    (c) => c.id === outcome.attritionCohortId
  );

  const usesFPD = eligibleCohort
    ? cohortUsesFirstPartyData(eligibleCohort)
    : false;

  return (
    <Tr>
      <Td sx={{ boxShadow: outcomeHasError ? "error-inset" : "" }}>
        <ResourceLink resource={outcome} sx={{ fontWeight: "bold" }} />
      </Td>
      <Td>
        {outcome.rocAuc ? (
          <OutcomeGradeBadge
            usesFPD={usesFPD}
            rocAuc={outcome.rocAuc}
            liftValue={outcome.liftValue}
          />
        ) : null}
      </Td>
      <Td>
        {eligibleCohort ? <ResourceLink resource={eligibleCohort} /> : "Anyone"}
      </Td>
      <Td>
        {attainmentCohort && <ResourceLink resource={attainmentCohort} />}
      </Td>
      <Td>{attritionCohort && <ResourceLink resource={attritionCohort} />}</Td>
      <Td>
        <ResourceStatusBadge resource={outcome} />
      </Td>
      <Td>
        <TimeAgo date={outcome.createdAt} />
      </Td>
      <Td>
        <TimeAgo date={outcome.lastUpdatedConfigAt} />
      </Td>
      <Td>
        <TimeAgo date={outcome.lastUpdatedOutputAt} />
      </Td>
      <Td textAlign="right">
        <OutcomeActionsMenu
          outcome={outcome}
          options={{
            view: true,
            technical_report: true,
            rename: true,
            edit: true,
            api: true,
            delete: true,
            archive: true,
          }}
          buttonProps={{ variant: "icon", my: -2 }}
        />
      </Td>
    </Tr>
  );
}
