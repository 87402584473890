import { Box, Flex, SystemStyleObject, Text } from "@chakra-ui/react";

import { Link as RouterLink } from "./Link";

interface TabLinksProps {
  tabs: {
    label: string;
    routeName?: string;
    params?: Record<string, string>;
    disabled?: boolean;
  }[];
}

const linkStyles: SystemStyleObject = {
  display: "inline-block",
  pt: 4,
  pb: 3,
  px: 12,
  textTransform: "uppercase",
  fontSize: "fdy_md",
  fontWeight: "bold",
  letterSpacing: ".02em",
  color: "fdy_gray.600",
  borderBottom: "4px solid",
  borderColor: "fdy_gray.400",
  transition: "all 0.2s ease-in-out",
  _hover: {
    bg: "fdy_gray.300",
    color: "fdy_gray.700",
  },
  "&.active": {
    color: "fdy_gray.800",
    borderBottomColor: "secondary",
  },
};

/**
 * Renders list of route links visually displayed as tabs.
 */
export function TabLinks({ tabs }: TabLinksProps) {
  return (
    <Box as="nav" mb={6} borderBottom="1px solid" borderColor="fdy_gray.300">
      <Flex as="ul">
        {tabs.map(({ label, routeName, params, disabled }, i) => {
          const disabledStyles = {
            opacity: 0.5,
            pointerEvents: "none",
          };

          const styles = {
            ...linkStyles,
            ...(disabled ? disabledStyles : {}),
          };

          return (
            <Box as="li" key={i}>
              <Text
                as={RouterLink}
                activeClassName="active"
                activeStrict
                routeName={routeName}
                params={params}
                sx={styles}
              >
                {label}
              </Text>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}
