import { ModalV2 } from "../../ui/ModalV2";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { getConnectionTypeInfoFromTargetOptions } from "../connectionUtils";
import { TargetFragment } from "./__generated__/TargetFragment";
import { TargetEditForm } from "./TargetEditForm";

export function TargetEditModal({
  onCancel,
  scope,
  target,
  isOpen,
}: {
  onCancel: () => void;
  scope: PipelineFragment;
  target: TargetFragment;
  isOpen: boolean;
}) {
  const info = getConnectionTypeInfoFromTargetOptions(target.options);

  return (
    <ModalV2
      title={`Edit ${info.literate} deployment`}
      onClose={onCancel}
      isOpen={isOpen}
      variant="flush"
      width="960px"
      analyticsStackName="edit-target-modal"
    >
      <TargetEditForm
        scope={scope}
        target={target}
        connectionTypeInfo={info}
        onCancel={onCancel}
        onSaved={onCancel}
      />
    </ModalV2>
  );
}
