import upperFirst from "lodash/upperFirst";

import {
  TraitCategory,
  TraitTier,
} from "../../__generated__/sojournerGlobalTypes";

export function traitCategoryToLiterate(
  categoryName: TraitCategory | null
): string {
  if (!categoryName) return "";
  if (categoryName === TraitCategory.USER_DEFINED) return "User defined";
  return upperFirst(
    categoryName.toLowerCase().replace(/^fig_/, "").replace(/_/g, " ")
  );
}

export function traitTierToLiterate(tierName: TraitTier | null): string {
  if (!tierName) return "";
  return upperFirst(tierName.toLowerCase());
}
