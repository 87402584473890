import { gql, useQuery } from "@apollo/client";
import { ReactElement } from "react";

import { Facets, FacetsProps } from "../analysis/Facets";
import { AttributeSidebarQuery } from "./__generated__/AttributeSidebarQuery";

const attributeSidebarQuery = gql`
  query AttributeSidebarQuery {
    account {
      id
      name
    }
    # is it right to base non roster features on this filter?
    fields: fdy_fields(filter: roster_post_analysis) {
      id
      name
      literate
      format
      filter_type
      breaks
      type
      categories
    }
  }
`;

export function AttributeListDataWrap(
  props: Omit<FacetsProps, "fields" | "loading" | "accountName">
): ReactElement {
  const { data, loading } = useQuery<AttributeSidebarQuery>(
    attributeSidebarQuery,
    {
      fetchPolicy: "cache-first",
    }
  );

  return (
    <Facets
      accountName={data?.account.name}
      fields={data?.fields}
      loading={loading}
      {...props}
    />
  );
}
