import { Tag, Td, Tr } from "@chakra-ui/react";
import { Lock } from "@phosphor-icons/react";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { TraitsMap } from "../../../hooks/useTraitsQuery";
import { MutedText } from "../../ui/MutedText";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { RouterLink } from "../../ui/RouterLink";
import { TimeAgo } from "../../ui/TimeAgo";
import { CohortActionsMenu } from "../CohortActionsMenu";
import { CohortExploreSwitch } from "../CohortExploreSwitch";
import { CohortEventSummary } from "../CohortForm/CohortEventsCard/CohortEventSummary";
import { CohortTraitTags } from "../CohortTraitTags";
import { Cohort } from "./CohortsListPage";

export function CohortsTableRow({
  cohort,
  traitsMap,
  exploreEnabled,
  showPlaces,
}: {
  cohort: Cohort;
  traitsMap: TraitsMap;
  exploreEnabled: boolean;
  showPlaces: boolean;
}) {
  const hasError = cohort.status === ResourceStatus.ERROR;

  return (
    <Tr>
      <Td sx={{ boxShadow: hasError ? "error-inset" : "" }}>
        <RouterLink
          routeName={ROUTE_NAMES.COHORTS_SHOW}
          params={{ cohort: cohort.id }}
          fontWeight="bold"
        >
          {cohort.name}
        </RouterLink>
      </Td>
      <Td>
        <CohortCount count={cohort.populationCount} status={cohort.status} />
      </Td>
      <Td>
        {cohort.classic ? (
          <LockTag />
        ) : cohort.streamName ? (
          <CohortEventSummary
            showStreamName
            event={{
              ...cohort,
              ...cohort.recency,
            }}
          />
        ) : (
          <MutedText>None</MutedText>
        )}
      </Td>
      <Td>
        {cohort.classic ? (
          <LockTag />
        ) : (
          <CohortTraitTags conditions={cohort.traits} traits={traitsMap} />
        )}
      </Td>
      {showPlaces && (
        <Td>{cohort.placeConditions.length || <MutedText>None</MutedText>}</Td>
      )}
      <Td>
        <ResourceStatusBadge resource={cohort} />
      </Td>
      <Td>
        <TimeAgo date={cohort.createdAt} />
      </Td>
      <Td>
        <TimeAgo date={cohort.lastUpdatedConfigAt} />
      </Td>
      <Td>
        <TimeAgo date={cohort.lastUpdatedOutputAt} />
      </Td>
      {exploreEnabled && (
        <Td>
          <CohortExploreSwitch cohort={cohort} />
        </Td>
      )}
      <Td textAlign="right">
        <CohortActionsMenu
          cohort={cohort}
          options={{
            view: true,
            rename: cohort.classic !== true,
            api: true,
            duplicate: true,
            delete: true,
            archive: true,
          }}
          buttonProps={{ variant: "icon", my: -2 }}
        />
      </Td>
    </Tr>
  );
}

function LockTag() {
  return (
    <Tag>
      <Lock style={{ marginRight: 4 }} />
      <span>Custom</span>
    </Tag>
  );
}

function CohortCount({
  count,
  status,
}: {
  count: number | null | undefined;
  status: ResourceStatus;
}) {
  if (status === ResourceStatus.STARTING || status === ResourceStatus.RUNNING) {
    return <span>Calculating...</span>;
  }

  if (
    status === ResourceStatus.ERROR ||
    count === undefined ||
    count === null
  ) {
    return null;
  }

  return <span>{count.toLocaleString()}</span>;
}
