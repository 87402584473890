import styled from "@emotion/styled";
import { CSSProperties, ReactElement } from "react";

import { theme } from "../../constants/theme";

const { radii, colors } = theme;

const sizes = {
  small: 24,
  smedium: 32, // lol fixme
  medium: 48,
  large: 88,
  xlarge: 240,
} as const;

const StyledPersonaImage = styled.img<{
  borderColor?: string;
  circular?: boolean;
  size: keyof typeof sizes;
}>`
  display: block;
  border-radius: ${({ circular }) => (circular ? radii.full : radii.lg)};
  border: 1px solid ${({ borderColor }) => borderColor ?? colors.light_gray};
  pointer-events: auto;
  max-width: 100%;
  height: auto;
  width: ${({ size }) => sizes[size]}px;
`;

interface PersonaImageProps {
  filename: string;
  borderColor?: string;
  circular?: boolean;
  size?: keyof typeof sizes;
  style?: CSSProperties;
  alt?: string;
  loading?: "eager" | "lazy";
  className?: string;
}

export function PersonaImage({
  borderColor,
  circular = false,
  className,
  filename,
  size = "medium",
  style,
  loading,
  alt,
}: PersonaImageProps): ReactElement {
  const dimension = sizes[size];
  return (
    <StyledPersonaImage
      alt={alt}
      borderColor={borderColor}
      circular={circular}
      className={className}
      height={dimension}
      loading={loading}
      size={size}
      src={`/images/persona_stereotypes/${filename}`}
      style={style}
      width={dimension}
    />
  );
}
