import styled from "@emotion/styled";
import { ReactElement } from "react";

import { theme } from "../../../constants/theme";
import { colors } from "../../../styles/chakra-theme-v2";

const StyledSignal = styled.div`
  padding: ${theme.space(0.5)};
  border-radius: ${theme.radii.full};
  border: 1px solid ${colors.fdy_gray[200]};
  background: ${colors.fdy_gray[100]};
  width: 80px;
`;

const StyledBar = styled.div`
  background: ${colors.fdy_magenta[500]};
  height: ${theme.space(1.5)};
  border-radius: ${theme.radii.full};
`;

export function FacetSignal({ signal }: { signal: number }): ReactElement {
  return (
    <StyledSignal>
      <StyledBar
        // Don't use styled components to adjust
        // width otherwise it creates a ton of classes and a warning.
        style={{
          width: `${signal * 100}%`,
        }}
      />
    </StyledSignal>
  );
}
