import { useRoute } from "react-router5";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { PersonaSetPageSkeleton } from "../PersonaSetPageSkeleton";
import { PersonaSetsSidebarLayout } from "../PersonaSetsLayout";
import { usePersonaSet } from "../usePersonaSet";
import { PersonaSetAnalysisSections } from "./PersonaSetAnalysisSections";

export function PersonaSetsAnalysisPage() {
  const { route } = useRoute();
  const personaSetId = route.params.roster;
  const { error, loading, data } = usePersonaSet(personaSetId);

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  const personaSet = data?.personaSet;
  if (!personaSet) {
    throw new Error(
      `BUG: No persona set found, but sojourner did not throw an error`
    );
  }

  return (
    <PersonaSetsSidebarLayout
      personaSet={personaSet}
      title={personaSet.name}
      lastCrumb={{
        label: personaSet.name,
        routeName: ROUTE_NAMES.PERSONAS_ANALYSIS,
        params: { roster: route.params.roster },
      }}
      containContent={personaSet.personas.length === 0}
      isSidebarOpenByDefault={false}
    >
      {personaSet.personas.length ? (
        <PersonaSetAnalysisSections personaSet={personaSet} />
      ) : personaSet.status !== ResourceStatus.ERROR ? (
        <PersonaSetPageSkeleton />
      ) : null}
    </PersonaSetsSidebarLayout>
  );
}
