import { gql } from "@apollo/client";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_CREATED_PERSONA_SET,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { UsageWarningNotice } from "../ui/UsageWarningNotice";
import {
  CreatePersonaSetMutation,
  CreatePersonaSetMutationVariables,
} from "./__generated__/CreatePersonaSetMutation";
import { PersonaSetForm, PersonaSetFormState } from "./PersonaSetForm";
import { personaSetFragment } from "./personaSetFragment";
import { PersonaSetsSidebarLayout } from "./PersonaSetsLayout";
import { PERSONAS_LIST_QUERY } from "./personasQuery";

export const CREATE_PERSONA_SET = gql`
  mutation CreatePersonaSetMutation($personaSet: PersonaSetPostInput!) {
    createPersonaSet(personaSetPostInput: $personaSet) {
      ...PersonaSetFragment
    }
  }
  ${personaSetFragment}
`;

/**
 * Renders the page for creating a new persona set.
 */
export function PersonaSetsNewPage() {
  const router = useRouter();
  const track = useHubSpotEvent();
  const toast = useToast();

  const [createPersonaSet, { loading }] = useSojournerMutation<
    CreatePersonaSetMutation,
    CreatePersonaSetMutationVariables
  >(CREATE_PERSONA_SET, {
    refetchQueries: [{ query: PERSONAS_LIST_QUERY }],
    onCompleted(data) {
      if (!data.createPersonaSet) return;
      const { id, name } = data.createPersonaSet;

      track(HUBSPOT_USER_CREATED_PERSONA_SET, {
        resource_id: id,
        resource_name: name,
      });

      toast({
        title: `${name} successfully created!`,
        status: "success",
      });

      router.navigate(ROUTE_NAMES.PERSONAS_ROSTER, {
        roster: id,
      });
    },
  });

  function handleSave(personaSet: PersonaSetFormState) {
    createPersonaSet({
      variables: {
        personaSet,
      },
    });
  }

  return (
    <PersonaSetsSidebarLayout title="New persona set">
      <UsageWarningNotice resource={"persona_sets"} />
      <PersonaSetForm onSave={handleSave} saving={loading} />
    </PersonaSetsSidebarLayout>
  );
}
