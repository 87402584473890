import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";

import { assertArrayAndUnwrap } from "../../../../utils/assertions";
import { percent } from "../../../../utils/formatters";
import { OutcomeAnalysisQuery_outcomeAnalysis_bias as BiasReport } from "../__generated__/OutcomeAnalysisQuery";
import { biasLevelToLiterate } from "./outcomeAnalysisBiasUtils";

function MetricValue({ value }: { value: number }) {
  const positive = value > 0;
  const arrowIcon = positive ? "↑" : "↓";
  return (
    <Text as="b" color={positive ? "fdy_green.600" : "fdy_magenta.500"}>
      {arrowIcon} {percent(value)}
    </Text>
  );
}

export function BiasSubpopulationTable({
  subpopulations,
}: {
  subpopulations:
    | BiasReport["fairness"]
    | BiasReport["predictions"]
    | BiasReport["power"];
}) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th width={200}>Sensitive dimensions</Th>
          <Th width={200}>Values</Th>
          <Th>Priority</Th>
        </Tr>
      </Thead>
      <Tbody>
        {assertArrayAndUnwrap(subpopulations).map((subpop, i) => {
          const firstMetric = subpop.metrics[0];
          if (!firstMetric) return null; // mostly to appease ts, we shouldn't have empty metrics
          return (
            <Tr key={i}>
              <Td bg="fdy_gray.100">{subpop.dimensions.join(" / ")}</Td>
              <Td bg="fdy_gray.100">{subpop.values.join(" / ")}</Td>
              <Td>
                <MetricValue value={firstMetric.value} />{" "}
                {biasLevelToLiterate(firstMetric.level)}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
