import { Box, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { Gear, Plus } from "@phosphor-icons/react";
import { ReactNode } from "react";
import * as React from "react";

import { Directionality } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useConnectionsQuery } from "../../connections/useConnectionsQuery";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { Blankslate } from "../../ui/Blankslate";
import { Button } from "../../ui/Button";
import { CalloutLink } from "../../ui/CalloutLink";
import { VendorLogo } from "../../ui/VendorLogo";
import {
  findConnectionTypeInfoBySlug,
  PUBLICATION_CONNECTION_TYPES,
} from "../connectionUtils";
import { TargetConnectionState } from "./PipelineTargetManager";

interface PipelineConnectionListProps {
  onConnectionSelect: (conn: TargetConnectionState) => void;
}

function ConnectionListItem({
  title,
  text,
  logo,
  onAddClick,
  analyticsName,
}: {
  title: string;
  text?: string;
  logo?: string;
  onAddClick: () => void;
  analyticsName?: string;
}) {
  return (
    <Flex
      flexDirection="column"
      sx={{
        borderRadius: 6,
        border: "1px solid",
        borderColor: "fdy_gray.200",
        boxShadow: "diffuse",
      }}
    >
      <Box
        flex={1}
        sx={{
          p: 4,
          borderBottom: "1px solid",
          borderBottomColor: "fdy_gray.200",
        }}
      >
        <Flex sx={{ alignItems: "center", mb: 1 }}>
          {logo ? <VendorLogo path={logo} /> : null}
          <Heading sx={{ fontSize: "fdy_md", ml: 2 }}>{title}</Heading>
        </Flex>
        <Text fontSize="fdy_sm">{text}</Text>
      </Box>
      <Button
        variant="ghost"
        onClick={onAddClick}
        leftIcon={<Plus weight="bold" />}
        width="100%"
        sx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        aria-label={`Add ${title} deployment`}
        analyticsName={analyticsName}
      >
        Add
      </Button>
    </Flex>
  );
}

const ConnectionsGrid: React.FC<React.PropsWithChildren> = ({ children }) => (
  <SimpleGrid columns={3} gap={4} mb={6}>
    {children}
  </SimpleGrid>
);

function ConnectionsSection({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <Box as="section" mb={6}>
      <Heading
        as="h3"
        sx={{
          textTransform: "uppercase",
          fontSize: "fdy_md",
          color: "fdy_gray.700",
          letterSpacing: "0.04em",
          mb: 2,
        }}
      >
        {title}
      </Heading>

      {children}
    </Box>
  );
}

function PublicationConnections({
  onConnectionSelect,
}: PipelineConnectionListProps) {
  return (
    <ConnectionsGrid>
      {PUBLICATION_CONNECTION_TYPES.map((connectionType) => {
        return (
          <ConnectionListItem
            key={connectionType.id}
            title={connectionType.literate}
            text={connectionType.new_target_description}
            logo={connectionType.logo_url}
            onAddClick={() =>
              onConnectionSelect({
                info: connectionType,
                id: null, // hosted targets don't get a connection ID passed to the API for creation
              })
            }
            analyticsName={`add-${connectionType.slug.replace(
              /_/g,
              "-"
            )}-deployment`}
          />
        );
      })}
    </ConnectionsGrid>
  );
}

function ReplicationConnections({
  onConnectionSelect,
}: PipelineConnectionListProps) {
  const { connections, loadingConnections } = useConnectionsQuery();

  const usableConnections = connections.filter(
    (c) =>
      // remove classic connections since users cannot act on them via UI
      c.options.__typename !== "ConnectionOptionsClassic" &&
      // remove source only. This list is for replication/push-to targets only.
      c.directionality !== Directionality.SOURCE_ONLY &&
      // remove publication connections
      !PUBLICATION_CONNECTION_TYPES.some((p) => p.slug === c.options.type) &&
      // remove managed connections
      !c.managed
  );

  if (loadingConnections) return <AnimatedZapLogo />;

  if (usableConnections.length === 0) {
    return (
      <Blankslate
        title="No Connections found"
        text="You need to to create a connection to deploy your pipeline"
        button={{
          routeName: ROUTE_NAMES.CONNECTIONS,
          children: "Manage connections",
        }}
        filled
      />
    );
  }

  return (
    <ConnectionsGrid>
      {usableConnections.map((conn) => {
        const info = findConnectionTypeInfoBySlug(conn.options.type);
        return (
          <ConnectionListItem
            key={conn.id}
            title={conn.name}
            logo={info.logo_url}
            text={info.new_target_description ?? info.description}
            onAddClick={() =>
              onConnectionSelect({
                info,
                id: conn.id,
              })
            }
            analyticsName="add-replication-deployment"
          />
        );
      })}
    </ConnectionsGrid>
  );
}

/**
 * Render usable hosted/publication connections and connections created on the account.
 *
 * For Faraday hosted connections, we fake their existance, since they are created when
 * a target is pushed for a given hosted connection.
 *
 * For any given connections shown, allow user to create a new `target` for that connection.
 */
export function PipelineConnectionList({
  onConnectionSelect,
}: PipelineConnectionListProps) {
  return (
    <Box>
      <ConnectionsSection title="Faraday hosted">
        <PublicationConnections onConnectionSelect={onConnectionSelect} />
      </ConnectionsSection>

      <ConnectionsSection title="Replicate to connection">
        <ReplicationConnections onConnectionSelect={onConnectionSelect} />
      </ConnectionsSection>

      <CalloutLink
        type="internal"
        routeName={ROUTE_NAMES.CONNECTIONS}
        label="Manage connections"
        icon={Gear}
      />
    </Box>
  );
}
