import { Box, Text } from "@chakra-ui/react";

import { Notice } from "../../../ui/Notice";
import {
  findOutcomeGrade,
  isOutcomePerformanceInWarning,
} from "./outcomeGradeUtils";

interface OutcomePerformanceTextProps {
  rocAuc: number;
  liftValue?: number | null;
  usesFPD: boolean;
}

function OutcomePerformanceWarning() {
  return (
    <Notice
      variant="warning"
      title="Some components of this outcome have extremely high signal."
      description="This could indicate that the wrong data is being used for prediction. Please review the Data section below to see if anything looks out of place and use the “Block” option accordingly."
    />
  );
}

export function OutcomePerformanceText({
  rocAuc,
  liftValue,
  usesFPD,
}: OutcomePerformanceTextProps) {
  const grade = findOutcomeGrade({
    rocAuc,
    liftValue,
    usesFPD,
  });
  const showWarning = isOutcomePerformanceInWarning({
    usesFPD,
    roc: rocAuc,
    lift: liftValue,
  });

  return (
    <Box mb={4}>
      <Text fontSize="fdy_xl" mb={2}>
        Model performance is{"  "}
        <Text
          as="span"
          sx={{
            textTransform: "lowercase",
            fontWeight: "bold",
          }}
        >
          {grade?.meta.label}
        </Text>
      </Text>

      {showWarning ? (
        <OutcomePerformanceWarning />
      ) : grade ? (
        <Text color="fdy_gray.700">{grade.meta.description}</Text>
      ) : null}
    </Box>
  );
}
