import styled from "@emotion/styled";
import { ReactElement } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AnalyticsStack } from "../../ui/Analytics/AnalyticsStack";
import { PageHeader } from "../../ui/PageHeader";
import { PageLayout } from "../../ui/PageLayout";
import { TextWithInfoTooltip } from "../../ui/TextWithInfoTooltip";
import { ExploreAnalysisBeta } from "./ExploreAnalysisBeta";

const StyledExplore = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const TOOLTIP_CONTENT = `Explore lets you visualize, analyze, and compare the world of customer data, including both your brand's data and Faraday's rich set of consumer profiles. Try adding a segment or two and using the Analysis views to discover new insights.`;

export function ExplorePage(): ReactElement {
  return (
    <AnalyticsStack value="explore-v2">
      <PageLayout>
        <PageHeader
          title="Explore"
          crumbs={[
            {
              label: "Explore",
              routeName: ROUTE_NAMES.EXPLORE,
            },
          ]}
          rightContent={
            <TextWithInfoTooltip
              tooltip={TOOLTIP_CONTENT}
              title="What is Explore?"
            />
          }
        />
        <StyledExplore>
          <ExploreAnalysisBeta />
        </StyledExplore>
      </PageLayout>
    </AnalyticsStack>
  );
}

export default ExplorePage;
