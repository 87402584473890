import { useDisclosure } from "@chakra-ui/react";

import { percent } from "../../utils/formatters";
import { PercentBarChart } from "../personas/analysis/PercentBarChart";

/**
 * Renders a single bar chart for a feature's importance.
 * The bar is filled with color N%, where N is the percent of the model explained by the feature.
 *
 * Also handles tooltip state 'manually' so we can make it more easily shown on hovering the bar.
 */
export function FeatureImportanceBar({
  feature,
  maxImportance,
}: {
  feature: { literate: string; importance: number };
  maxImportance: number;
}) {
  const tipHandler = useDisclosure();

  const importance = percent(feature.importance);

  const tooltip = `${importance} of the model is explained by ${feature.literate}`;

  return (
    <div
      onMouseLeave={tipHandler.onClose}
      onMouseEnter={tipHandler.onOpen}
      // more hover area to trigger tooltip
      style={{ display: "flex", gap: 8, alignItems: "center" }}
    >
      <PercentBarChart
        isTooltipOpen={tipHandler.isOpen}
        percent={feature.importance / maxImportance}
        tooltip={tooltip}
      />
      {importance}
    </div>
  );
}
