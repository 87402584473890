import { Notice } from "../../../../ui/Notice";
import { OutputToStreamsState } from "../events/DatasetsEventsCard";
import { IdentitySets } from "../identity/DatasetsIdentitySetsCard";
import { OutputToTraitsState } from "../traits/DatasetsTraitsCard";

export type DatasetConfig = {
  detectedColumnsReady: boolean;
  identitySets: IdentitySets;
  outputToStreams: OutputToStreamsState;
  outputToTraits: OutputToTraitsState;
  mergeDataset: boolean;
};

function isEmptyObject(obj: Record<string, unknown>) {
  return Object.keys(obj).length === 0;
}

export function DatasetMissingConfigNotice({
  datasetConfig,
}: {
  datasetConfig: DatasetConfig;
}) {
  const {
    detectedColumnsReady,
    identitySets,
    outputToStreams,
    outputToTraits,
    mergeDataset,
  } = datasetConfig;

  const missingIdentitySets = isEmptyObject(identitySets);
  const missingEventsAndTraits =
    isEmptyObject(outputToStreams) && isEmptyObject(outputToTraits);
  const missingConfig = missingIdentitySets && missingEventsAndTraits;

  if (!detectedColumnsReady || !missingConfig || mergeDataset) {
    return null;
  }

  if (missingIdentitySets && missingEventsAndTraits) {
    return (
      <Notice
        variant="warning"
        title="Dataset is missing configuration."
        description="Please configure identity sets, events and/or traits to complete this dataset."
        dismissable
      />
    );
  }
  if (missingIdentitySets) {
    return (
      <Notice
        variant="warning"
        title="Identity sets missing."
        description="Add one below to match individuals in your data into Faraday."
        dismissable
      />
    );
  }
  if (missingEventsAndTraits) {
    return (
      <Notice
        variant="warning"
        title="Events and traits missing."
        description="Add any events/traits below to get the most out of this dataset."
        dismissable
      />
    );
  }
  return null;
}
