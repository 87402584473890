import { Fragment, ReactElement } from "react";

interface WordBreakProps {
  /** character to insert breaks at */
  breakAfter: string;

  /** text to split and break */
  text: string;
}

/**
 * Enable line wrapping after certain characters in a string of text.
 */
export function WordBreak({ breakAfter, text }: WordBreakProps): ReactElement {
  if (!breakAfter || !text.includes(breakAfter)) return <>{text}</>;

  return (
    <>
      {text.split(breakAfter).map((line, i, lines) => {
        return (
          <Fragment key={i}>
            {i < lines.length - 1 ? (
              <>
                {line}
                {breakAfter}
                <wbr />
              </>
            ) : (
              line
            )}
          </Fragment>
        );
      })}
    </>
  );
}
