import { Tooltip } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { max as d3max, ScaleLinear, scaleLinear } from "d3";
import { ReactElement } from "react";
import { useMeasure } from "react-use";

import { theme } from "../../../constants/theme";
import { percent as toPercent } from "../../../utils/formatters";
import { formatBinLabel } from "./analysisUtils";
import { Bounds, Field } from "./types";

const CHART_HEIGHT = 29;

export interface HistogramBin {
  min: number;
  max: number;
  percent: number;
}

interface HistogramProps {
  color: string;
  bins: HistogramBin[];
  bounds: Bounds;
  field: Field;
}

const StyledBinLabel = styled.p`
  text-align: center;
  font-size: ${theme.fontSizes.md};
`;
const StyledBinCounts = styled.p`
  text-align: center;
  font-size: ${theme.fontSizes.xxl};
`;

function BinTooltip({ bin, field }: { bin: HistogramBin; field: Field }) {
  return (
    <div>
      <StyledBinLabel>
        {field.literate}: {formatBinLabel(field, bin.min)}-
        {formatBinLabel(field, bin.max)}
      </StyledBinLabel>
      <StyledBinCounts>{toPercent(bin.percent)}</StyledBinCounts>
    </div>
  );
}

/**
 * Renders 2 vertical bars for the histogram bin frequency.
 * - One faint bar that starts at the bin.min and ends at the bin.max
 * - This is so we can represent the bin breaks correctly but
 *   not draw too much attention to the bin width.
 */
function HistogramBarGroup({
  yScale,
  xScale,
  bin,
  color,
  field,
}: {
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  color: string;
  bin: HistogramBin;
  field: Field;
}) {
  const { min, max, percent } = bin;

  const barMaxWidth = 16;
  const barGap = 4;
  const borderRadius = 2;

  const y = yScale(percent);

  // plus a few px so bottom border radii are hidden
  // so the corners appear 0 radius
  const height = CHART_HEIGHT - y + borderRadius;

  // calculate the x positions for min and max values
  const xMin = xScale(min);
  const xMax = xScale(max);

  // Bar width should fill the min/max area
  // but no larger than size
  const smBarSpace = Math.min(xMax - xMin, barMaxWidth);
  const lgBarSpace = xMax - xMin;

  // No negative values cause svg does not like 'em.
  const smBarWidth = Math.max(smBarSpace - barGap, 1);
  const lgBarWidth = Math.max(lgBarSpace - barGap, 1);

  const centerX = (xMin + xMax) / 2;

  // center the bar between the min/max
  const smBarX = centerX - smBarWidth / 2;
  const lgBarX = centerX - lgBarWidth / 2;

  const tip = <BinTooltip bin={bin} field={field} />;

  return (
    <g>
      {/* faint min/max background bar */}
      <Tooltip label={tip}>
        <rect
          width={lgBarWidth}
          height={height}
          y={y}
          x={lgBarX}
          fill={color}
          rx={borderRadius}
          ry={borderRadius}
          opacity={0.2}
        />
      </Tooltip>

      {/* narrow centered bar */}
      <rect
        width={smBarWidth}
        height={height}
        y={y}
        x={smBarX}
        fill={color}
        rx={borderRadius}
        ry={borderRadius}
        style={{
          // Though we want to show this bar above the faint bar, the wider bar
          // should be the hover zone to trigger the tooltip, so let mouse events pass through.
          pointerEvents: "none",
        }}
      />
    </g>
  );
}

export function Histogram({
  bins,
  color,
  bounds,
  field,
}: HistogramProps): ReactElement {
  const [ref, { width = 0 }] = useMeasure();
  const refShim = (el: HTMLElement | null) => {
    if (el) ref(el);
  };

  const xScale = scaleLinear().domain(bounds).range([0, width]);
  const maxCount = d3max(bins, (d) => d.percent) || 1;
  const yScale = scaleLinear().range([CHART_HEIGHT, 0]).domain([0, maxCount]);

  return (
    <div ref={refShim}>
      <svg
        width={width}
        height={CHART_HEIGHT}
        viewBox={`0 0 ${width} ${CHART_HEIGHT}`}
        style={{ display: "block" }}
        data-bounds={bounds}
      >
        {bins.map((d, i) => {
          return (
            <HistogramBarGroup
              key={i}
              bin={d}
              xScale={xScale}
              yScale={yScale}
              color={color}
              field={field}
            />
          );
        })}
      </svg>
    </div>
  );
}
