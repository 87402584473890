import { Select } from "@chakra-ui/react";
import { UnreachableCodeError } from "@fdy/jwt";
import { ChangeEvent } from "react";

import { Direction } from "../../../../../__generated__/sojournerGlobalTypes";
import { FormField } from "../../../../ui/FormField";
import {
  formIds,
  labels,
  LimitTypename,
  TargetLimitState,
} from "./TargetLimit";

/**
 * The choices available to users in the form.
 */
export enum LimitEnum {
  TopCount = "top_count",
  BottomCount = "bottom_count",
  PercentileRange = "percentile_range",
}

export const limitTypeLabels = {
  [LimitEnum.TopCount]: "Only the top (count) - outcome optional",
  [LimitEnum.BottomCount]: "Only the bottom (count) - outcome required",
  [LimitEnum.PercentileRange]: "Between (1-100%)",
};

type LimitTypeOption = {
  label: string;
  value: LimitEnum;
  typename: LimitTypename;
};

function getLimitTypeOptions(showPercentileLimit: boolean): LimitTypeOption[] {
  const options: LimitTypeOption[] = [
    {
      label: limitTypeLabels[LimitEnum.TopCount],
      value: LimitEnum.TopCount,
      typename: "TargetLimitRowCount",
    },
    {
      label: limitTypeLabels[LimitEnum.BottomCount],
      value: LimitEnum.BottomCount,
      typename: "TargetLimitRowCount",
    },
  ];

  if (showPercentileLimit) {
    options.push({
      label: limitTypeLabels[LimitEnum.PercentileRange],
      value: LimitEnum.PercentileRange,
      typename: "TargetLimitPercentile",
    });
  }

  return options;
}

function limitEnumToDirection(limitEnum: LimitEnum): Direction {
  if (limitEnum === LimitEnum.TopCount) {
    return Direction.DESCENDING;
  } else if (limitEnum === LimitEnum.BottomCount) {
    return Direction.ASCENDING;
  }
  throw new Error(`invalid limit type ${limitEnum}`);
}

export function TargetLimitTypeSelect({
  limit,
  onChange,
  limitType,
  lockedTypename,
  required,
  showPercentileLimit,
}: {
  limit: TargetLimitState;
  onChange: (state: TargetLimitState) => void;
  limitType: LimitEnum;
  lockedTypename?: LimitTypename;
  required?: boolean;
  showPercentileLimit: boolean;
}) {
  const outcomeId =
    limit.__typename === "TargetLimitPercentile"
      ? limit.percentileOutcomeId
      : limit.rowCountOutcomeId;

  function handleLimitTypeChange(event: ChangeEvent<HTMLSelectElement>) {
    const newLimitType = event.target.value as LimitEnum;

    if (newLimitType === LimitEnum.PercentileRange) {
      onChange({
        __typename: "TargetLimitPercentile",
        method: "percentile",
        percentileOutcomeId: outcomeId,
        percentileMin: null,
        percentileMax: null,
      });
    } else if (
      newLimitType === LimitEnum.TopCount ||
      newLimitType === LimitEnum.BottomCount
    ) {
      onChange({
        __typename: "TargetLimitRowCount",
        method: "row_count",
        rowCountOutcomeId: outcomeId ?? null,
        direction: limitEnumToDirection(newLimitType),
        threshold:
          limit.__typename === "TargetLimitRowCount" ? limit.threshold : null,
      });
    } else {
      throw new UnreachableCodeError(newLimitType);
    }
  }

  const options = getLimitTypeOptions(showPercentileLimit);

  return (
    <FormField
      label={labels.limitType}
      htmlFor={formIds.direction}
      visuallyHiddenLabel
    >
      <Select
        id={formIds.direction}
        value={limitType}
        onChange={handleLimitTypeChange}
        required={required}
      >
        {options.map((option) => {
          // The API prevents swapping target limit methods (row count vs percentile) so disable it for existing limits
          // Users should now be using filter instead of percentile limit anyway.
          const enforcedType =
            lockedTypename && option.typename !== lockedTypename;

          return (
            <option
              key={option.value}
              value={option.value}
              disabled={enforcedType}
            >
              {option.label}
            </option>
          );
        })}
      </Select>
    </FormField>
  );
}
