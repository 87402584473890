import { Box } from "@chakra-ui/react";

import { CardV2 } from "../ui/Card/CardV2";
import { ProgressBar } from "../ui/ProgressBar";
import { SkeletonCard } from "../ui/SkeletonCard";

export function PersonaSetPageSkeleton() {
  return (
    <Box display="grid" gap={8}>
      <CardV2
        text="Faraday is detecting your personas, which will take a moment. Feel free
        to leave this page."
      >
        <ProgressBar value={25} />
      </CardV2>

      <SkeletonCard />
    </Box>
  );
}
