import { List, ListItem } from "@chakra-ui/react";

import { useTraitsQuery } from "../../../hooks/useTraitsQuery";
import { useConnectionsQuery } from "../../connections/useConnectionsQuery";
import { AccordionV2 } from "../../ui/AccordionV2";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { AutoScrollToNewBottomItems } from "../../ui/AutoScrollToNewBottomItems";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { TargetFragment } from "./__generated__/TargetFragment";
import { EditableTargetCard } from "./EditableTargetCard";
import { useScopeDependencies } from "./useScopeDependencies";

interface PipelineTargetListProps {
  scope: PipelineFragment;
  targets: TargetFragment[];
}

/**
 * Renders a list of editable targets for a scope.
 */
export function PipelineTargetList({
  scope,
  targets,
}: PipelineTargetListProps) {
  const { connections, loadingConnections } = useConnectionsQuery();
  const { traitsMap, loadingTraits } = useTraitsQuery();
  const scopeDeps = useScopeDependencies(scope);

  // upgrade apollo so we can use suspense? :(
  if (loadingConnections || scopeDeps.loading || loadingTraits) {
    return <AnimatedZapLogo />;
  }

  const archivedTargets = targets.filter((target) => target.archivedAt);
  const unarchivedTargets = targets.filter((target) => !target.archivedAt);

  return (
    <>
      <AutoScrollToNewBottomItems items={targets}>
        <List>
          {unarchivedTargets.map((target) => {
            return (
              <ListItem key={target.id} sx={{ _notLast: { mb: 8 } }}>
                <EditableTargetCard
                  scope={scope}
                  scopeDeps={scopeDeps}
                  connections={connections}
                  traitsMap={traitsMap}
                  target={target}
                />
              </ListItem>
            );
          })}
        </List>
      </AutoScrollToNewBottomItems>
      {archivedTargets.length > 0 && (
        <AccordionV2>
          <AccordionV2.Item title="Show archived">
            <List>
              {archivedTargets.map((target) => {
                return (
                  <ListItem key={target.id} sx={{ _notLast: { mb: 8 } }}>
                    <EditableTargetCard
                      scope={scope}
                      scopeDeps={scopeDeps}
                      connections={connections}
                      traitsMap={traitsMap}
                      target={target}
                    />
                  </ListItem>
                );
              })}
            </List>
          </AccordionV2.Item>
        </AccordionV2>
      )}
    </>
  );
}
