import { TagList } from "../../../../ui/TagList";
import { DatasetEventConfig } from "./DatasetsEventsCard";

const plural = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural;

export function EventPropertyTeaser({ event }: { event: DatasetEventConfig }) {
  const { data_map: dataMap, conditions } = event;
  const { value, datetime, ...custom } = dataMap;

  const customPropertiesCount = Object.keys(custom).length;

  const tags = [
    datetime?.column_name ? <>{datetime.column_name} &rarr; datetime </> : null,

    value?.column_name ? <>{value.column_name} &rarr; value </> : null,

    customPropertiesCount > 0 ? (
      <>
        {customPropertiesCount}{" "}
        {plural(customPropertiesCount, "property", "properties")}
      </>
    ) : null,

    conditions.length > 0 ? (
      <>
        {conditions.length}{" "}
        {plural(conditions.length, "condition", "conditions")}
      </>
    ) : null,
  ].filter(Boolean);

  return <TagList tags={tags} />;
}
