import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";

import { FEATURES_SHOWN_LIMIT } from "../../../../hooks/useProgressiveDisclosureItems";
import { assertArrayAndUnwrap } from "../../../../utils/assertions";
import {
  OutcomeAnalysisQuery_outcomeAnalysis_overallFeatures,
  OutcomeAnalysisQuery_outcomeAnalysis_tenureFeatures,
} from "../__generated__/OutcomeAnalysisQuery";
import {
  OutcomeDataCardFeatureAccordion,
  OutcomeDataCardFeatureAccordionProps,
} from "./OutcomeDataCardFeatureAccordion";

function getTenureLabel({
  tenureStart,
  tenureEnd,
}: {
  tenureStart: number | null;
  tenureEnd: number | null;
}): string {
  if (tenureStart === null && tenureEnd === null) {
    return `Before joining`;
  } else if (tenureStart !== null && tenureEnd === null) {
    return `${tenureStart}+ days`;
  } else if (tenureEnd !== null) {
    return `${tenureStart ?? 0}-${tenureEnd} days`;
  } else {
    throw new Error("misconfigured tenure ranges");
  }
}

/**
 * Renders the feature importances and directionalities for an outcome.
 */
export function OutcomeDataCardFeatures({
  overallFeatures,
  tenureFeatures,
  eligibleCohortName,
  outcomeName,
}: {
  eligibleCohortName: string | null;
  overallFeatures: OutcomeAnalysisQuery_outcomeAnalysis_overallFeatures;
  tenureFeatures:
    | OutcomeAnalysisQuery_outcomeAnalysis_tenureFeatures
    | null
    | undefined;
  outcomeName: string;
}) {
  // manage shown state here so it's not reset when switching tabs
  const [shown, setShown] = useState(FEATURES_SHOWN_LIMIT);

  const accordionProps: Omit<OutcomeDataCardFeatureAccordionProps, "features"> =
    {
      outcomeName,
      eligibleCohortName,
      shown,
      onShowMore: setShown,
    };

  const overallTab = (
    <OutcomeDataCardFeatureAccordion
      features={overallFeatures.recognizedIndividuals}
      {...accordionProps}
    />
  );

  // Render non-dynamic outcomes as a single accordion, no tabs
  if (overallFeatures && !tenureFeatures?.recognizedIndividuals.length) {
    return overallTab;
  }

  const tabs = [
    {
      label: "Overview",
      panel: overallTab,
    },
  ];

  if (tenureFeatures) {
    const feats = assertArrayAndUnwrap(tenureFeatures.recognizedIndividuals);
    tabs.push(
      ...feats.map((tenureFeature) => {
        const label = getTenureLabel(tenureFeature);
        return {
          label,
          panel: (
            <OutcomeDataCardFeatureAccordion
              features={tenureFeature.features}
              {...accordionProps}
            />
          ),
        };
      })
    );
  }

  return (
    <Tabs size="sm">
      <TabList>
        {tabs.map((tab) => (
          <Tab key={tab.label}>{tab.label}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab.label}>{tab.panel}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
