import { Recommender } from "@fdy/faraday-js";
import { useQuery } from "@tanstack/react-query";

import { useFdyClient } from "../../services/FdyClientProvider";
import { recommenderKeys } from "./recommenderKeys";

export const useRecommenderAnalysis = (recommender: Recommender) => {
  const client = useFdyClient();
  return useQuery({
    queryKey: recommenderKeys.analysis(recommender.id),
    queryFn: () => client.recommenders.getRecommenderAnalysis(recommender.id),
    // skip if we don't have a last updated output at since we likely won't
    // have any analysis data
    enabled: !!recommender.last_updated_output_at,
  });
};
