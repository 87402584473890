import { exists } from "./exists";

// Mixes possible shapes our conditions
// i.e. trait conditions, event properti conditions, target filter conditions.
type ConditionBase = {
  eq?: string | number | null;
  matches?: string | null;
  nnull?: boolean | null;
  null?: boolean | null;
  in?: (string | null)[] | null;
  nin?: (string | null)[] | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
};

// We could use some of our existing number formatters, but in this case we just
// want to support long decimals, so we'll use the built-in toLocaleString.
const readableNumber = (num: number) =>
  num.toLocaleString("en-us", { maximumFractionDigits: 16 });

/**
 * Converts a condition to a human-readable summary list of strings.
 *
 * A "condition" in this case is an object where each key is an operator
 * and the value is the value for that operator.
 *
 * Conditions are used in a few places in the UI:
 * - Cohort trait conditions
 * - Cohort event property conditions
 * - Target filters
 *
 * Example:
 *
 * ```
 * conditionToSummary({
 *   eq: "foo",
 *   matches: "bar",
 *   nnull: true,
 *   null: false,
 *   in: ["baz", "quux"],
 *   nin: ["quuux", "quuuux"],
 *   gt: 1,
 *   gte: 2,
 *   lt: 3,
 *   lte: 4,
 * });
 * ```
 *
 * Will return:
 *
 * ```
 * [
 *   "Equals foo",
 *   "Contains 'bar'",
 *   "Any values",
 *   "No empty values",
 *   "One of: baz, quux",
 *   "Not one of: quuux, quuuux",
 *   "Greater than 1",
 *   "Greater than or equal to 2",
 *   "Less than 3",
 *   "Less than or equal to 4",
 * ]
 * ```
 *
 */
export function conditionToSummary<T extends ConditionBase>(c: T): string[] {
  const result = [];
  if (exists(c.eq)) {
    result.push(`Equals ${c.eq}`);
  }

  if (exists(c.matches && c.matches.length > 0)) {
    result.push(`Contains '${c.matches}'`);
  }

  if (exists(c.nnull)) {
    if (c.nnull) {
      result.push("Any values");
    } else {
      // unlikely we'll see these, but just in case
      result.push("No values");
    }
  }

  if (exists(c.null)) {
    if (c.null) {
      result.push("Empty values only");
    } else {
      // unlikely we'll see these, but just in case
      result.push("No empty values");
    }
  }

  if (exists(c.gt)) {
    result.push(`Greater than ${readableNumber(c.gt)}`);
  }

  if (exists(c.gte)) {
    result.push(`Greater than or equal to ${readableNumber(c.gte)}`);
  }

  if (exists(c.lt)) {
    result.push(`Less than ${readableNumber(c.lt)}`);
  }

  if (exists(c.lte)) {
    result.push(`Less than or equal to ${readableNumber(c.lte)}`);
  }

  if (exists(c.in) && c.in.length > 0) {
    result.push(`One of: ${c.in.join(", ")}`);
  }

  if (exists(c.nin) && c.nin.length > 0) {
    result.push(`Not one of: ${c.nin.join(", ")}`);
  }

  return result;
}
