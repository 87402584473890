import { GraphEdge, ResourceType } from "@fdy/faraday-js";

/**
 * Removes any graph edges that are not useful to the user from a configuration perspective.
 */
export function filterGraphEdges(graphEdges: GraphEdge[]): GraphEdge[] {
  return graphEdges.filter((edge) => {
    /**
     * Example: Streams -> Outcomes are linked because of rollup generation but this is not something the user configures themselves.
     * https://faradayio.slack.com/archives/CJJ344465/p1720535489753509
     */
    const isStreamsToOutcomesLink =
      edge.upstream_type === ResourceType.Streams &&
      edge.downstream_type === ResourceType.Outcomes;

    return !isStreamsToOutcomesLink;
  });
}
