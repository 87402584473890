import { Td, Tr } from "@chakra-ui/react";
import { Dataset, Stream } from "@fdy/faraday-js";

import { useDatasetListQuery } from "../../../hooks/api";
import { largeNumber } from "../../../utils/formatters";
import { PendingValue } from "../../ui/PendingValue";
import { PopoverInfoPill, PopoverInfoPillPart } from "../../ui/PopoverInfoPill";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { TimeAgo } from "../../ui/TimeAgo";
import { EventsActionsMenu } from "../EventsActionsMenu";
import { EventStreamDatasetsTable } from "../EventShowPage/EventStreamDatasetContributionCard";
import { EventStreamPropertiesTable } from "../EventShowPage/EventStreamPropertiesCard";

export const findRelevantDatasetsByStream = (
  stream: Stream,
  datasets: Dataset[]
) => {
  return datasets.filter((dataset) => {
    return Object.values(dataset.output_to_streams ?? {}).filter(
      (st) => st.stream_id === stream.id
    ).length;
  });
};

const StreamDatasetsPill = ({ stream }: { stream: Stream }) => {
  const datasetsQuery = useDatasetListQuery(); // we can rely on deduping here, so rendering multiple times is fine
  const relevantDatasets = findRelevantDatasetsByStream(
    stream,
    datasetsQuery.data ?? []
  );

  if (!relevantDatasets.length) return null;

  return (
    <PopoverInfoPill popover={<EventStreamDatasetsTable stream={stream} />}>
      <PopoverInfoPillPart
        icon={ResourceIcon.datasets}
        value={relevantDatasets.length}
      />
    </PopoverInfoPill>
  );
};

const StreamPropertiesPill = ({ stream }: { stream: Stream }) => {
  const properties = Object.entries(stream.properties ?? {});

  if (!properties.length) return null;

  return (
    <PopoverInfoPill popover={<EventStreamPropertiesTable stream={stream} />}>
      <PopoverInfoPillPart
        icon={ResourceIcon.traits}
        value={properties.length}
      />
    </PopoverInfoPill>
  );
};

export function EventTableRow({ stream }: { stream: Stream }) {
  return (
    <Tr>
      <Td fontWeight="bold">
        <ResourceLink resource={stream} />
      </Td>
      <Td>
        <StreamDatasetsPill stream={stream} />
      </Td>
      <Td>
        <StreamPropertiesPill stream={stream} />
      </Td>
      <Td>
        <PendingValue value={stream.event_count} formatter={largeNumber} />
      </Td>
      <Td>
        <PendingValue
          value={stream.newest_date}
          formatter={(v) => <TimeAgo date={v} />}
        />
      </Td>
      <Td>
        <ResourceStatusBadge resource={stream} />
      </Td>
      <Td>
        <TimeAgo date={stream.created_at} />
      </Td>
      <Td>
        <TimeAgo date={stream.last_updated_config_at} />
      </Td>
      <Td>
        <TimeAgo date={stream.last_updated_output_at} />
      </Td>
      <Td textAlign="right">
        <EventsActionsMenu event={stream} />
      </Td>
    </Tr>
  );
}
