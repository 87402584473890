import { useEffect } from "react";
import { useRouter } from "react-router5";

import { ToastComponentProps, useToast } from "../../hooks/useToast";
import { singular } from "../../utils/singular";

type EditableToastProps = Pick<ToastComponentProps, "title" | "description">;

/**
 * Router redirects via rendering a component.
 * Defaults to parent route and merging existing params with new params (if passed).
 *
 * @example
 *
 * if(featureEnabled) {
 *   <Redirect routeName={ROUTE_NAMES.NEW_FEATURE} />
 * }
 *
 * return <OldView />
 */
export function Redirect({
  routeName,
  params,
  replace,
  showToast,
  toastProps,
}: {
  routeName?: string;
  params?: Record<string, string | string[]>;
  /** Replace the route state instead of push */
  replace?: boolean;
  showToast?: boolean;
  toastProps?: EditableToastProps;
}): null {
  const toast = useToast();
  const router = useRouter();
  const route = router.getState();

  // If routeName is not provided, use the current route name minus the last segment
  routeName =
    routeName ??
    (route.name.includes(".")
      ? route.name.split(".").slice(0, -1).join(".")
      : undefined);

  useEffect(() => {
    if (routeName) {
      router.navigate(
        routeName,
        {
          ...route.params,
          ...params,
        },
        { replace }
      );

      // ----------------
      // Toast
      //
      // route ex:
      //   {
      //     "name": "cohorts.id",
      //     "params": {
      //         "cohort": "adc143e8-7ebf-4dd7-a42b-697fa17a2c57"
      //     },
      //     "path": "/cohorts/adc143e8-7ebf-4dd7-a42b-697fa17a2c57",
      //     "meta": {
      //         "params": {
      //             "cohorts": {},
      //             "cohorts.id": {
      //                 "cohort": "url"
      //             }
      //         },
      //         "id": 1
      //     }
      // }
      if (showToast) {
        const routeNameMatch = route.name?.match(/(\w+)\.id$/);
        const resourceName = singular(
          routeNameMatch ? routeNameMatch[1] : route.name
        );
        const resourceId =
          // some routes use legacy parameter names, ex persona -> roster
          route.params?.[resourceName] ?? Object.values(route.params ?? {})[0];

        if (routeNameMatch) {
          toast({
            title: `You are being redirected to /${routeName}`,
            description: resourceId
              ? `${resourceName} with id ${resourceId} was not found`
              : `${resourceName} was not found`,
            ...toastProps,
          });
        }
      }
    }
  }, [routeName]);

  return null;
}
