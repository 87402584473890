import { useState } from "react";
import * as React from "react";

type NavigationWarningContextType = {
  warnBeforeNavigate: boolean;
  setWarnBeforeNavigate: (warnBeforeNavigate: boolean) => void;
};
const NavigationWarningContext =
  React.createContext<NavigationWarningContextType | null>(null);

interface NavigationWarningProviderProps {
  children: React.ReactNode;
}

/**
 * Provides a context for components to set a flag that will warn the user from navigating away from the page for use with page tabs
 * because router5 canDeactivate does not trigger for subpaths
 */
export function NavigationWarningProvider({
  children,
}: NavigationWarningProviderProps): React.ReactElement {
  const [warnBeforeNavigate, setWarnBeforeNavigate] = useState<boolean>(false);

  return (
    <NavigationWarningContext.Provider
      value={{ warnBeforeNavigate, setWarnBeforeNavigate }}
    >
      {children}
    </NavigationWarningContext.Provider>
  );
}

export function useNavigationWarning(): NavigationWarningContextType {
  const context = React.useContext(NavigationWarningContext);
  if (!context)
    throw new Error(
      "useNavigationWarning must be used within a NavigationWarningProvider"
    );
  return context;
}
