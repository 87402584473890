import { Skeleton as ChakraSkeleton, SkeletonProps } from "@chakra-ui/react";

import { colors } from "../../styles/chakra-theme-v2";

export function Skeleton(props: SkeletonProps) {
  return (
    <ChakraSkeleton
      startColor={colors.fdy_gray[100]}
      endColor={colors.fdy_gray[200]}
      speed={2}
      {...props}
    />
  );
}
