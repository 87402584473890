import { ReactElement } from "react";

export function SegmentCount({
  count: value,
}: {
  count: number | undefined | null;
}): ReactElement | null {
  if (value === null || value === undefined) return null;

  return (
    <span>
      &asymp;
      {value.toLocaleString()} individuals
    </span>
  );
}
