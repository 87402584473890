import { ApolloLink, HttpLink } from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import {
  ApolloClient,
  FieldFunctionOptions,
  FieldPolicy,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLError } from "graphql";
import Rollbar from "rollbar";

import { possibleTypes } from "../constants/possibleTypes";
import { isDemoOrDevEnv } from "../utils/isDemoOrDevEnv";
import { AuthService } from "./authService";
import { createLoggerLink } from "./sojournerApolloClient";

export function createErrorMiddleware(
  authService: AuthService,
  logger: Rollbar | Console
) {
  return onError(({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      if (graphQLErrors.some(isAuthenticationError)) {
        // Don't log any errors, this user isn't authenticated.
        logger.warn("[Auth]: Received authentication error, logging out.");
        authService.logout();
        return;
      }
      if (graphQLErrors.some((err) => err.extensions?.statusCode === 502)) {
        logger.info("[502]: Received 502 error, retrying.");
        return forward(operation);
      }
      // Just send the first 5, if there's more, something seriously bad is happening
      graphQLErrors
        .slice(0, 5)
        .map(({ message, locations, path, extensions }) => {
          let graphQLErrorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;

          // Somtimes the stacktrace is useful, log it to the console as well.
          // Consider limiting this logging by extensions.code in the future.
          if (
            extensions &&
            extensions.exception &&
            extensions.exception.stacktrace
          ) {
            extensions.exception.stacktrace.forEach(
              (line: string, i: unknown) => {
                // The first line of the stacktrace is the same as the message above, skip it
                if (i === 0) return;
                graphQLErrorMessage += "\n";
                graphQLErrorMessage += line;
              }
            );
          }
          logger.warn(graphQLErrorMessage);
        });
    }
    if (networkError) {
      if ("statusCode" in networkError && networkError.statusCode === 401) {
        logger.warn("[Auth]: Received 401, logging out.");
        authService.logout();
        return;
      } else if (
        ("response" in networkError &&
          networkError.response?.headers.get("contentType") === "text/html") ||
        networkError.message.includes("ServerParseError") ||
        networkError.message.includes("ChunkLoadError")
      ) {
        // Detect when sojo returns HTML responses. This appears to happen on redeploys.
        // Now we just log and retry. Suppressing until we have time to fix.
        // see https://app.asana.com/0/1203008782586563/1205041162929715/f
        // see https://faradayio.slack.com/archives/CJJ344465/p1689194025384379
        logger.info("[ServerParseError] Received HTML response, retrying.");
        return forward(operation);
      } else if (
        /Failed to fetch/.test(networkError.message) ||
        /NetworkError/.test(networkError.message) ||
        /Load failed/.test(networkError.message)
      ) {
        // TODO: these ephemeral errors are polluting rollbar. They might be CORS-related.
        // Suppressing and retrying until we have time to fix.
        // see https://app.asana.com/0/1203313451389847/1205078691272456/f
        logger.info(`SUPPRESSING network error: ${networkError}`);
        return forward(operation);
      } else {
        logger.warn(`[Network error]: ${networkError}`);
      }
    }
  });
}

interface LimitOffset {
  [key: string]: unknown;
  limit: number;
  offset: number;
}

interface List<T> {
  items: T[];
}

/**
 * Defines a limit-offset type policy for our new-style list queries. Given a
 * list endpoint it will append/accumulate cached results associated list query
 * so that calling `fetchMore` in the UI will correctly fetch more records and
 * append them to the list of items.
 *
 * Based on the implementation of Apollo's own `offsetLimitPagination` cache
 * policy function, documented here:
 *
 * <https://www.apollographql.com/docs/react/pagination/offset-based/#the-offsetlimitpagination-helper>
 */
function offsetLimitPagination<T, A extends LimitOffset>(): FieldPolicy<
  List<T>
> {
  return {
    keyArgs(args) {
      return args
        ? Object.keys(args).filter((arg) => arg !== "limit" && arg !== "offset")
        : [];
    },
    merge(existing, incoming, options) {
      const { args } = options as FieldFunctionOptions<A>;
      const merged = existing
        ? { ...existing, ...incoming, items: [...existing.items] }
        : { ...incoming, items: [] };
      if (args) {
        // Assume an offset of 0 if args.offset omitted.
        const { offset = 0 } = args;
        for (let i = 0; i < incoming.items.length; ++i) {
          merged.items[offset + i] = incoming.items[i];
        }
      } else {
        merged.items.push(...incoming.items);
      }
      return merged;
    },
  };
}

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: { fields: { deliveries: offsetLimitPagination() } },
    Roster: {
      fields: {
        analysis_fields: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

/**
 * Check if the error name is an authentication error we want to log user out for.
 *
 * NOTE:
 * - Kopeng may send generic FORBIDDEN errors and we log out in that case.
 * - Sojourner can send EXPIRED_API_KEY errors and we log out in that case,
 *   but it also can send other Forbidden errors when they run into other authorization issues (max resource quota reached),
 *   so we mainly care about logging out for EXPIRED_API_KEY, otherwise we'd just check for 40x status codes.
 */
function isGqlErrorLogoutWorthy(
  message: string,
  extension: GraphQLError["extensions"]
) {
  return (
    // For some reason, checking the code doesn't catch all of these.
    // Some are still sent to the ErrorBoundary.
    message.includes("The specified API key has expired.") ||
    (extension &&
      "code" in extension &&
      (extension.code === "FORBIDDEN" || extension.code === "EXPIRED_API_KEY"))
  );
}

function isAuthenticationError(err: GraphQLError) {
  if (Array.isArray(err.extensions)) {
    return err.extensions.some((ext) =>
      isGqlErrorLogoutWorthy(err.message, ext)
    );
  }

  return isGqlErrorLogoutWorthy(err.message, err.extensions);
}

export class ApolloGQLService extends ApolloClient<NormalizedCacheObject> {
  constructor({
    authService,
    log = console,
  }: {
    authService: AuthService;
    log: Console | Rollbar;
  }) {
    const httpLink = new HttpLink({
      uri: "/api/gql",
    });

    const authMiddleware = setContext(async () => ({
      headers: {
        authorization: `Bearer ${await authService.getToken()}`,
      },
    }));

    // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
    const retry = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 12,
        // Retry on network errors
        retryIf: (error) => !!error,
      },
    });

    const links: ApolloLink[] = [
      retry,
      authMiddleware,
      createErrorMiddleware(authService, log),
      httpLink,
    ];

    if (isDemoOrDevEnv()) {
      links.unshift(createLoggerLink("kopeng"));
    }

    super({
      cache,
      // let sojourner apollo client use devtools instead.
      connectToDevTools: false,
      link: ApolloLink.from(links),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
        },
      },
    });
  }
}
