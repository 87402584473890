import { OutputToTraits } from "@fdy/faraday-js";
import { useEffect } from "react";
import { useRoute } from "react-router5";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { graphqlOptionsToSpec } from "../../../services/connectionOptions";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { ResourceGraphCard } from "../../ui/ResourceGraphCard";
import {
  DatasetShowPageQuery,
  DatasetShowPageQueryVariables,
} from "../__generated__/DatasetShowPageQuery";
import { datasetShowPageQuery } from "../sharedQueries";
import { DatasetForm, DatasetFormState } from "./DatasetForm/DatasetForm";
import { OutputToStreamsState } from "./DatasetForm/events/DatasetsEventsCard";
import { IdentitySets } from "./DatasetForm/identity/DatasetsIdentitySetsCard";
import { isSampleData } from "./DatasetForm/shared/OptionWithSampleData";
import { DetectedColumn } from "./DatasetForm/shared/types";
import { DatasetLayout } from "./DatasetsLayout";
import { datasetToMergePatchInput } from "./sharedUtils";
import { useUpdateDataset } from "./useUpdateDataset";

export function DatasetsShowPage() {
  const { route } = useRoute();
  const datasetId = route.params.id;

  const { data, loading, error, stopPolling } = useSojournerQuery<
    DatasetShowPageQuery,
    DatasetShowPageQueryVariables
  >(datasetShowPageQuery, {
    variables: {
      datasetId,
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    if (
      error ||
      data?.dataset?.detectedColumns.length ||
      [ResourceStatus.ERROR, ResourceStatus.READY].includes(
        data?.dataset?.status ?? ResourceStatus.NEW
      )
    )
      stopPolling();
  }, [data, error]);

  const dataset = data?.dataset;

  const { updateDataset, updating } = useUpdateDataset();

  function handleSave(state: DatasetFormState) {
    updateDataset(datasetId, datasetToMergePatchInput(state));
  }

  if (loading) {
    return <AnimatedZapLogo />;
  }
  if (!dataset) {
    throw new Error(
      "BUG: No dataset found, but sojourner did not throw an error"
    );
  }

  // original array is read only (thus can't be sorted)
  // so make a copy
  const detectedColumns = [...dataset.detectedColumns].sort((a, b) =>
    a.name.localeCompare(b.name)
  ) as DetectedColumn[];
  const sampleData = dataset?.sample;

  const mergeDataset = dataset.mergeDatasets?.length > 0;

  return (
    <DatasetLayout dataset={dataset}>
      {/* FIXME: just send the dataset prop down. not so many others */}
      <DatasetForm
        saving={updating}
        defaultState={{
          // by default, these are SojJSON, which isn't very specific
          // use type-casting to provide more detailed types
          // FIXME: use types from API spec instead of hardcoding
          identitySets: dataset.identitySets as IdentitySets,
          outputToStreams: dataset.outputToStreams as OutputToStreamsState,
          outputToTraits: dataset.outputToTraits as OutputToTraits,
          options: graphqlOptionsToSpec({ ...dataset.options }),
          referenceKeyColumns: dataset.referenceKeyColumns,
          upsertColumns: dataset.upsertColumns,
          privacy: dataset.privacy,
        }}
        referenceKeyColumn={dataset.referenceKeyColumn}
        detectedColumns={detectedColumns}
        onSave={handleSave}
        datasetStatus={dataset.status}
        managed={Boolean(dataset.managed)}
        mergeDataset={mergeDataset}
        connection={data.connections.find(
          (conn) => conn.id === dataset.connectionId
        )}
        datasetId={dataset.id}
        sampleData={isSampleData(sampleData) ? sampleData : undefined}
        dataset={dataset}
        renderAboveSubmitButton={<ResourceGraphCard resourceId={dataset.id} />}
      />
    </DatasetLayout>
  );
}
