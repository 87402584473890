import { gql } from "@apollo/client";

import { connectionOptionsFragment } from "../../connectionOptionsFragments";

export const connectionFragment = gql`
  fragment ConnectionDetails on Connection {
    id
    name
    managed
    archivedAt
    status
    statusError
    lastUpdatedOutputAt
    lastUpdatedConfigAt
    lastReadInputAt
    createdAt
    ...ConnectionOptions
  }
  ${connectionOptionsFragment}
`;
