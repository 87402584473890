import { ResourceStatus, ResourceType } from "@fdy/faraday-js";
import { Icon } from "@phosphor-icons/react";

export type GraphLayoutColumn = {
  label: string;
  icon: Icon;
  types: ResourceType[];
};

export type GroupNodeData = {
  label: string;
};

export type ResourceNodeData = {
  id: string;
  name: string;
  type: ResourceType;
  status: ResourceStatus;
  statusError?: string;
  lastReadInputAt?: string;
  lastUpdatedConfigAt?: string;
  lastUpdatedOutputAt?: string;
  archived: boolean;
  /** If this node is the current resource being viewed. */
  current?: boolean;
  /** Upstream resource ID. Used when builing links to resources. */
  upstreamId?: string;
};

export enum MarkerName {
  Dot = "Dot",
  DotHighlight = "DotHighlight",
  DotSubdued = "DotSubdued",
}

export enum ResourceGraphNodeType {
  Resource = "resource",
  Group = "group",
}
