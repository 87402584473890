import { fontSizes } from "../../../../styles/chakra-theme-v2";
import { Grade } from "./outcomeGrades";
import { getOutcomeGrades, valueInRange } from "./outcomeGradeUtils";

interface OutcomeRocBarChartProps {
  /** a float from 0.1 to 1.0 */
  rocAuc: number;
  usesFPD: boolean;
}

interface BarProps extends Grade {
  score: number;
  first: boolean;
  last: boolean;
}

function Marker({ percent, color }: { color: string; percent: number }) {
  return (
    <div
      style={{
        position: "absolute",
        left: `${percent * 100}%`,
        top: -8,
        width: 10,
        height: 24,
        background: "white",
        borderRadius: 4,
        border: `2px solid ${color}`,
        boxShadow: `0 0 8px 0 rgba(0,0,0,0.1)`,
      }}
    />
  );
}

function Bar({ score, first, last, rocRange: rocRange, meta }: BarProps) {
  const { label, colors } = meta;
  const [start, end] = rocRange;

  if (start === null)
    throw new Error(`Start required for outcome score grade ${label}`);
  if (end === null)
    throw new Error(`End required for outcome score grade ${label}`);

  const inRange = valueInRange({
    value: score,
    range: rocRange,
    maxInclusive: true,
  });

  // position the marker relative to the bar section
  const percent = (score - start) / (end - start);

  return (
    <div
      style={{
        flex: 1,
        position: "relative",
      }}
    >
      <div
        style={{
          height: 8,
          background: colors.bg,
          borderTopLeftRadius: first ? 4 : 0,
          borderBottomLeftRadius: first ? 4 : 0,
          borderTopRightRadius: last ? 4 : 0,
          borderBottomRightRadius: last ? 4 : 0,
        }}
      >
        {inRange && <Marker percent={percent} color={colors.bg} />}
      </div>
      <p
        style={{
          fontSize: fontSizes.fdy_sm,
          paddingTop: 8,
        }}
      >
        {label}
      </p>
    </div>
  );
}

/**
 * Renders single horizontal bar chart with a marker for the score.
 * The bar is divided into sections based on the score ranges.
 */
export function OutcomeRocBarChart({
  rocAuc,
  usesFPD,
}: OutcomeRocBarChartProps) {
  const bars = getOutcomeGrades(usesFPD);

  return (
    <div
      style={{
        display: "flex",
        gap: 1,
      }}
      // This chart is mostly noise for screenreader. We already communicate the score in text elsewhere.
      aria-hidden
    >
      {bars.map((b, i) => (
        <Bar
          key={i}
          score={rocAuc}
          first={i === 0}
          last={i === bars.length - 1}
          {...b}
        />
      ))}
    </div>
  );
}
