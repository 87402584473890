import { Flex } from "@chakra-ui/react";
import { ReactElement, useEffect, useState } from "react";

import { Checkbox } from "../../ui/Checkbox";
import { AnalysisExport } from "./AnalysisExport";
import { useFacetsBySignal } from "./analysisUtils";
import { FacetList } from "./FacetList";
import { FacetSignal } from "./FacetSignal";
import { AnalysisMap, Field, FieldsFacet } from "./types";

// old fields start with private/, while fdyfields start with private_
const isPrivateField = (f: FieldsFacet) => f.fieldName.match(/^private(\/|_)/);

export interface FacetsProps {
  fields: Field[] | undefined;
  analysis: AnalysisMap | undefined;
  field: Field | null;
  setField: (field: Field) => void;
  audiences?: string[];
  hideSearch?: boolean;
  hideExport?: boolean;
  /** List of allowed fields to show in facet list. Use machine names e.g. cat_owner */
  allowedFields?: string[] | null;
  /** limit the number of fields shown to a given number of them */
  fieldLimit?: number;
  loading: boolean;
  accountName?: string;
}

export function Facets({
  accountName,
  allowedFields,
  analysis,
  audiences,
  field,
  fields,
  hideExport,
  hideSearch,
  fieldLimit,
  loading,
  setField,
}: FacetsProps): ReactElement {
  const [showPrivateFacets, setShowPrivateFacets] = useState(true);

  const facets = useFacetsBySignal(analysis, fields);

  const checkboxLabel = `Show ${accountName || "account"} attributes`;

  const hasPrivateFields = facets.some(isPrivateField);

  const items = facets.filter((f, i) => {
    // check if private fields are filtered out
    const prvt = showPrivateFacets ? true : !isPrivateField(f);

    // check if field is allowed to be shown
    const allowed = allowedFields?.length
      ? allowedFields.includes(f.fieldName)
      : true;

    const withinLimit = fieldLimit && fieldLimit > 0 ? i < fieldLimit : true;

    return prvt && allowed && withinLimit;
  });

  const selectedItem = field
    ? items.find((f) => f.fieldName === field.name)
    : items[0];

  useEffect(() => {
    if (selectedItem) {
      setField(selectedItem.field);
    }
  }, [selectedItem]);

  return (
    <FacetList<FieldsFacet>
      selectedItem={selectedItem}
      onItemSelect={(facet) => setField(facet.field)}
      inputLabel="Filter attributes"
      itemToString={(item) => item.name}
      defaultItems={items}
      loading={loading || !analysis}
      renderInputContainer={({ children }) => {
        if (hideExport && hideSearch) return null;

        return (
          <Flex direction="column" gap={2}>
            {!hideExport && audiences?.length ? (
              <AnalysisExport audiences={audiences} />
            ) : null}

            {!hideSearch && <div>{children}</div>}

            {hasPrivateFields && (
              <Checkbox
                checked={showPrivateFacets}
                onChange={(e) => setShowPrivateFacets(e.target.checked)}
                analyticsName="explore-private-facets"
              >
                {checkboxLabel}
              </Checkbox>
            )}
          </Flex>
        );
      }}
      renderAfterItemLabel={({ item }) =>
        item.signal !== null ? <FacetSignal signal={item.signal} /> : null
      }
    />
  );
}
