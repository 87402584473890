import { Divider, Spacer, Text } from "@chakra-ui/react";
import { Dispatch, useCallback } from "react";
import * as React from "react";
import { FormatOptionLabelMeta } from "react-select";

import { EVERYONE_COUNT } from "../../../constants/everyoneCount";
import { AccountConfigMap } from "../../../hooks/accountConfigHooks";
import { formatPendingCount } from "../../../utils/formatPendingCount";
import { CardV2 } from "../../ui/Card/CardV2";
import { FdyReactSelect, FdySelectOption } from "../../ui/FdyReactSelect";
import { FormField } from "../../ui/FormField";
import { scopeInfo } from "../scopeInfo";
import { PipelineFormQuery_cohorts } from "./__generated__/PipelineFormQuery";
import { FeatureLockedNotice } from "./FeatureLockedNotice";
import { PipelineFormState } from "./PipelineForm";
import {
  EVERYONE_ID,
  pipelineFormIds,
  pipelineFormLabels,
  sortByLabel,
} from "./pipelineFormUtils";
import { pipelineHelpText } from "./pipelineHelpText";
import { ResetScopeError, ScopeErrors } from "./usePipelineValidator";

interface PopulationOption extends FdySelectOption {
  count: number | null;
}

interface PipelineFormPopulationCardProps {
  cohorts: PipelineFormQuery_cohorts[];
  configMap: AccountConfigMap;
  errors: ScopeErrors | undefined;
  formState: PipelineFormState;
  resetError: ResetScopeError;
  setFormState: Dispatch<React.SetStateAction<PipelineFormState>>;
  loading: boolean;
}

/**
 * Customize the render of population options in the select menu,
 * so we can append the cohort count.
 */
function PopulationOptionLabel(
  { label, count }: PopulationOption,
  meta: FormatOptionLabelMeta<PopulationOption>
) {
  if (meta.context === "value") return <>{label}</>;

  return (
    <span>
      {label}{" "}
      <Text as="span" color="fdy_gray.600">
        ({formatPendingCount(count)})
      </Text>
    </span>
  );
}

export function PipelineFormPopulationCard({
  cohorts,
  configMap,
  errors,
  formState,
  resetError,
  setFormState,
  loading,
}: PipelineFormPopulationCardProps) {
  /**
   * Handle population select changes.
   *
   * Population has a special case where you can select 'Everyone', though it doesn't
   * map to a cohort id. Instead 'everyone' is considered an empty list of cohort ids.
   *
   * Also clears the population error when the select is changed.
   */
  const handlePopulationChange = useCallback(
    (selectedOptions: readonly FdySelectOption[]) => {
      // if user selected everyone, empty out the population cohort ids and set the flag
      if (selectedOptions.some((opt) => opt.value === EVERYONE_ID)) {
        setFormState((state) => ({
          ...state,
          everyoneSelected: true,
          populationCohortIds: [EVERYONE_ID],
        }));
      } else {
        setFormState((state) => ({
          ...state,
          everyoneSelected: false,
          populationCohortIds: selectedOptions.map((opt) => opt.value),
        }));
      }

      // unset the error on any changes
      resetError("population");
    },
    [setFormState, resetError]
  );

  /**
   * Handle population exclusion select changes.
   * Also clears the population exclusion error if the user changes the field.
   */
  const handlePopulationExclusionChange = useCallback(
    (selectedOptions: readonly PopulationOption[]) => {
      setFormState((state) => ({
        ...state,
        populationExclusionCohortIds: selectedOptions.map((opt) => opt.value),
      }));

      resetError("populationExclusion");
    },
    [setFormState, resetError]
  );

  const cohortOptions: PopulationOption[] = cohorts
    .filter((c) => {
      return (
        !c.archivedAt ||
        formState.populationCohortIds.includes(c.id) ||
        formState.populationExclusionCohortIds.includes(c.id)
      );
    })
    .map((c) => ({
      value: c.id,
      label: c.name,
      count: c.populationCount,
    }))
    .sort(sortByLabel);

  // if everyone is selected, disable other options in the dropdown
  const populationCohortOptions: PopulationOption[] = cohortOptions.map(
    (c) => ({
      ...c,
      isDisabled: formState.everyoneSelected,
    })
  );

  const EVERYONE_OPTION: PopulationOption = {
    value: EVERYONE_ID,
    label: "Everyone",
    count: EVERYONE_COUNT,
    isDisabled: !configMap["scopes.everybody_allowed"],
    showLockIcon: !configMap["scopes.everybody_allowed"],
  };

  const populationOptions = [EVERYONE_OPTION, ...populationCohortOptions];
  const populationValue = populationOptions.filter((opt) =>
    formState.populationCohortIds.includes(opt.value)
  );

  const populationExclusionValue = cohortOptions.filter((opt) =>
    formState.populationExclusionCohortIds.includes(opt.value)
  );

  return (
    <CardV2
      title="Population"
      text={scopeInfo.population}
      analyticsStackName="population"
    >
      <FormField
        htmlFor={pipelineFormIds.population}
        label={pipelineFormLabels.population}
        helpText={pipelineHelpText.population}
        error={errors?.population}
        analyticsName="include"
      >
        <FdyReactSelect<PopulationOption, true>
          isMulti
          inputId={pipelineFormIds.population}
          isLoading={loading}
          options={populationOptions}
          value={populationValue}
          onChange={handlePopulationChange}
          placeholder="Select cohorts"
          formatOptionLabel={PopulationOptionLabel}
          isInvalid={Boolean(errors?.population)}
        />
      </FormField>

      <Spacer my={4} />

      <FeatureLockedNotice unlessEnabled={["scopes.everybody_allowed"]} />

      <Divider />

      <FormField
        htmlFor={pipelineFormIds.populationExclusion}
        label={pipelineFormLabels.populationExclude}
        helpText={pipelineHelpText.populationExclude}
        error={errors?.populationExclusion}
        suffix="optional"
        analyticsName="exclude"
      >
        <FdyReactSelect<PopulationOption, true>
          isMulti
          inputId={pipelineFormIds.populationExclusion}
          isLoading={loading}
          options={cohortOptions}
          value={populationExclusionValue}
          onChange={handlePopulationExclusionChange}
          placeholder="Select excluded cohorts"
          formatOptionLabel={PopulationOptionLabel}
          isInvalid={Boolean(errors?.populationExclusion)}
        />
      </FormField>
    </CardV2>
  );
}
