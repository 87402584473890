import { Box, Heading, Text, VisuallyHidden } from "@chakra-ui/react";
import {
  TargetLimitMergePatch,
  TargetLimitRowCountMergePatchDirectionEnum,
} from "@fdy/faraday-js";
import { UnreachableCodeError } from "@fdy/jwt";
import { FormEvent, useState } from "react";

import {
  TargetStructureTransformationInput,
  TargetTransformPresetAggregated,
  TargetTransformPresetHashed,
  TargetTransformPresetIdentified,
  TargetTransformPresetReferenced,
} from "../../../../__generated__/sojournerGlobalTypes";
import { TraitsMap } from "../../../../hooks/useTraitsQuery";
import { ApiOptions } from "../../../../services/connectionOptions";
import { exists } from "../../../../utils/exists";
import { ConnectionTypeSlug } from "../../../../vannevar/discriminator_mappings";
import { AccordionV2, AccordionV2ItemProps } from "../../../ui/AccordionV2";
import { AnalyticsStack } from "../../../ui/Analytics/AnalyticsStack";
import { Button } from "../../../ui/Button";
import { ConnectionOptionFields } from "../../../ui/ConnectionOptionFields";
import { FormFieldset } from "../../../ui/FormFieldset";
import { InlineButtons } from "../../../ui/InlineButtons";
import {
  PipelineFragment,
  PipelineFragment_columns_columnsForMode,
} from "../../__generated__/PipelineFragment";
import {
  ConnectionTypeInfo,
  getTargetOptionsGroups,
  isConnectionType,
} from "../../connectionUtils";
import {
  TargetFragment_limit,
  TargetFragment_representation,
} from "../__generated__/TargetFragment";
import { ScopeDependencies } from "../useScopeDependencies";
import { LookupTargetNotice } from "./LookupTargetNotice";
import { ScrollWrap } from "./ScrollWrap";
import { FILTER_INITIAL_STATE, TargetFilter } from "./TargetFilter";
import { targetFilterToWire } from "./TargetFilter/targetFilterToWire";
import { TargetFilterPostInput, TargetFilterState } from "./TargetFilter/types";
import {
  LIMIT_INITIAL_STATE,
  TargetLimit,
  TargetLimitState,
} from "./TargetLimit";
import { TargetRepresentationType } from "./TargetRepresentationRadios";
import { TargetStructure } from "./TargetStructure";
import {
  connectionTypePresetMapping,
  TargetStructureTransformationFormInput,
  TransformPresetType,
} from "./targetStructureUtils";

// move to target form?
/**
 * The state shape required to pass from the API to initialize the form.
 *
 * Some values are undefined for new targets, and null for existing targets.
 */
export interface TargetFormState {
  options: ApiOptions;
  transformPreset: TransformPresetType | null;
  filter: TargetFilterState | null;
  limit: TargetLimitState | null;
  customStructure: TargetStructureTransformationInput[] | null | undefined;
  humanReadable: boolean;
  representation: TargetRepresentationType;
}

/**
 * The output state shape used to pass to the API for
 * updating or creating a target.
 */
export interface TargetStateForWire {
  options: ApiOptions;
  humanReadable: boolean;
  customStructure: TargetStructureTransformationInput[] | null | undefined;
  limit: TargetLimitMergePatch | null | undefined;
  // Unfortunately editing and creating target representation expects JSON for mutation input
  // because gql doesn't support input union types
  // https://github.com/graphql/graphql-spec/issues/488
  // FIXME: don't type this as json. That just makes it harder to work with.
  representation: SojJSON;
  filter: TargetFilterPostInput | null | undefined;
}

interface TargetFormAdvancedProps {
  connectionTypeInfo: ConnectionTypeInfo;
  saving: boolean;
  onSave: (target: TargetStateForWire) => void;
  onBack: () => void;
  scope: PipelineFragment;
  scopeDependencies: ScopeDependencies;
  representation: TargetRepresentationType;
  humanReadable: boolean;
  initialState?: TargetFormState;
  // Because we can't change discriminator types in the API (yet), we have to
  // make these types read-only on Edit (can't change type - but you can change
  // options within the type).
  // See also: https://github.com/faradayio/openapi-interfaces/issues/34
  lockedRepresentationTypename?: TargetFragment_representation["__typename"];
  lockedLimitTypename?: TargetFragment_limit["__typename"];
  traitsMap: TraitsMap;
}

/**
 * Groom the target representation state into a format/type usable for posting to the api.
 *
 * Because we're trying to rely on codegenerated enums for api <-> ui typesafety,
 * and POST/PATCH Target expects lowercase values for `aggregate`,
 * convert the ones that are uppercase to lowercase.
 * If graphql supported input types with a union, we wouldn't have to do this
 * (assuming openapi-to-graphql created mutation inputs with union types)
 * See https://github.com/graphql/graphql-spec/issues/488
 */
function targetRepresentationToInput(
  rep: TargetRepresentationType,
  preset: TransformPresetType
): SojJSON {
  // remove typename as it's not expected for input. We only keep it in state
  // because `mode` is a string, not a real discriminator
  const { __typename } = rep;
  const mode = rep.mode;
  const transform_preset = preset.toLowerCase();
  if (__typename === "TargetModesAggregated") {
    return {
      mode,
      transform_preset,
      aggregate: rep.geographicAggregate.toLowerCase(),
      include_geometry: rep.includeGeometry,
    };
  } else if (__typename === "TargetModesIdentified") {
    return {
      mode,
      transform_preset,
      aggregate: rep.identifiedAggregate.toLowerCase(),
    };
  } else if (__typename === "TargetModesReferenced") {
    return {
      mode,
      transform_preset,
      reference: {
        dataset_id: rep.reference.datasetId,
        column_name: rep.reference.columnName,
      },
    };
  } else if (__typename === "TargetModesHashed") {
    return {
      mode,
      transform_preset,
    };
  } else {
    throw new Error(
      `Unexpected representation type "${__typename}" for target`
    );
  }
}

/**
 * Transform the target limit state into a format/type usable for posting to the api.
 *
 * See comments re: representation and graphql union types
 */
function targetLimitToInput(
  limit: TargetLimitState
): TargetLimitMergePatch | undefined {
  if (limit.__typename === "TargetLimitRowCount") {
    if (!exists(limit.threshold) || limit.threshold === 0) {
      return undefined;
    }

    return {
      method: "row_count",
      outcome_id: limit.rowCountOutcomeId,
      direction:
        (limit.direction?.toLowerCase() as TargetLimitRowCountMergePatchDirectionEnum) ??
        "descending",
      threshold: limit.threshold,
    };
  } else if (limit.__typename === "TargetLimitPercentile") {
    if (
      !limit.percentileOutcomeId ||
      !exists(limit.percentileMin) ||
      !exists(limit.percentileMax)
    ) {
      return undefined;
    }
    return {
      method: "percentile",
      outcome_id: limit.percentileOutcomeId,
      percentile_min: limit.percentileMin,
      percentile_max: limit.percentileMax,
    };
  } else {
    throw new UnreachableCodeError(limit);
  }
}

const defaultState: TargetFormState = {
  options: { type: "" },
  transformPreset: null,
  filter: null,
  limit: null,
  customStructure: null,
  humanReadable: false,
  representation: {
    __typename: "TargetModesHashed",
    hashedPreset: null,
    mode: "hashed",
  },
};

/**
 * Detect if the user submitted custom structure deviates from the default
 */
function hasCustomStructure(
  defaultStructure: TargetStructureTransformationInput[] | undefined,
  submitted: TargetStructureTransformationFormInput[]
) {
  if (!defaultStructure) {
    // the metadata isn't fresh; just check the custom structure length
    return submitted.length > 0;
  }
  if (defaultStructure.length !== submitted.length) {
    return true;
  }
  // detect if a column was reordered, renamed, or hidden:
  for (let i = 0; i < defaultStructure.length; i++) {
    if (
      defaultStructure[i].scopeCol !== submitted[i].scopeCol || // reorder
      defaultStructure[i].outputCol !== submitted[i].newOutputCol || // rename
      !submitted[i].show // hidden
    ) {
      return true;
    }
  }
  // nothing changed
  return false;
}

/**
 * generate a unique string from a scope_column
 */
function hashColumn(s: TargetStructureTransformationInput) {
  return [
    s.scopeCol,
    s.isIdentityCol ? "isidentity" : "isnotidentity",
    s.aggregation || "noagg",
    s.aggregationCriteria || "noaggcriteria",
  ].join("|");
}

function getDefaultStructure({
  scope,
  scopeDependencies,
  representation,
  humanReadable,
}: {
  scope: PipelineFragment;
  scopeDependencies: ScopeDependencies;
  representation: TargetRepresentationType;
  humanReadable?: boolean;
}): TargetStructureTransformationInput[] | undefined {
  let defaultStructure: TargetStructureTransformationInput[] | undefined;

  const columnsForMode = scope.columns?.columnsForMode[
    representation.mode as keyof PipelineFragment_columns_columnsForMode
  ] as TargetStructureTransformationInput[] | undefined;

  if (columnsForMode && scope.columns?.payloadColumns && humanReadable) {
    defaultStructure = [];
    // Set the default output columns to their human-readable counterparts
    for (const ds of columnsForMode) {
      const humanName = scope.columns?.payloadColumns.find(
        (sc) => sc?.name === ds.scopeCol
      )?.humanName;
      if (humanName) {
        let outputCol = ds.outputCol.replace(ds.scopeCol, humanName);
        // Also substitute persona ID with literate values (name)
        // Ideally, this would come from the API ...
        for (const personaSet of scopeDependencies.payloadPersonaSets) {
          for (const persona of personaSet.personas) {
            if (persona.name) {
              outputCol = outputCol.replace(
                persona.id.replace(/-/g, "_"),
                persona.name.replace(/\W/g, "_").toLowerCase()
              );
            }
          }
        }
        defaultStructure.push({
          ...ds,
          outputCol,
        });
      } else {
        defaultStructure.push({ ...ds });
      }
    }
  } else {
    defaultStructure = columnsForMode;
  }

  return defaultStructure;
}

function getInitialCustomStructure(
  defaultStructure: TargetStructureTransformationInput[] | undefined,
  initialState: TargetFormState
) {
  let memo: TargetStructureTransformationFormInput[] = [];
  // Convert to the shape required by the form:
  // - every available column must be present with `show` set to yes/no
  // - newOutputCol set to the existing or default value
  if (initialState.customStructure) {
    const customCols = new Set(
      initialState.customStructure.map((s) => hashColumn(s))
    );
    memo = initialState.customStructure.map((s) => ({
      ...s,
      show: true,
      newOutputCol: s.outputCol,
    }));
    defaultStructure?.forEach((s) => {
      if (!customCols.has(hashColumn(s))) {
        memo.push({ ...s, show: false, newOutputCol: s.outputCol });
      }
    });
  } else if (defaultStructure) {
    memo = defaultStructure.map((s) => ({
      ...s,
      show: true,
      newOutputCol: s.outputCol,
    }));
  }
  return memo;
}

function getInitialTransformPreset(
  initialState: TargetFormState,
  representation: TargetFragment_representation,
  connectionTypeInfo: ConnectionTypeInfo
): TransformPresetType {
  // Autoselect preset for managed connections that have a preset otherwise return default
  const preset =
    connectionTypePresetMapping[connectionTypeInfo.slug as ConnectionTypeSlug];

  if (initialState.transformPreset) {
    return initialState.transformPreset;
  }
  const representationType = representation.__typename;
  if (representationType === "TargetModesAggregated") {
    if (
      connectionTypeInfo.type === "managed" &&
      preset &&
      preset in TargetTransformPresetAggregated
    ) {
      return preset;
    }
    return (
      representation.aggregatedPreset ?? TargetTransformPresetAggregated.DEFAULT
    );
  } else if (representationType === "TargetModesHashed") {
    if (
      connectionTypeInfo.type === "managed" &&
      preset &&
      preset in TargetTransformPresetHashed
    ) {
      return preset;
    }
    return representation.hashedPreset ?? TargetTransformPresetHashed.DEFAULT;
  } else if (representationType === "TargetModesIdentified") {
    if (
      connectionTypeInfo.type === "managed" &&
      preset &&
      preset in TargetTransformPresetIdentified
    ) {
      return preset;
    }
    return (
      representation.identifiedPreset ?? TargetTransformPresetIdentified.DEFAULT
    );
  } else if (representationType === "TargetModesReferenced") {
    if (
      connectionTypeInfo.type === "managed" &&
      preset &&
      preset in TargetTransformPresetReferenced
    ) {
      return preset;
    }
    return (
      representation.referencedPreset ?? TargetTransformPresetReferenced.DEFAULT
    );
  } else {
    throw new Error(
      `Unexpected representation type "${representationType}" for target`
    );
  }
}

/**
 * Render a form for editing or creating a connection target.
 */
export function TargetFormAdvanced({
  initialState = defaultState,
  connectionTypeInfo,
  saving,
  onSave,
  onBack,
  scope,
  scopeDependencies,
  lockedLimitTypename,
  representation,
  humanReadable,
  traitsMap,
}: TargetFormAdvancedProps) {
  const [limit, setLimit] = useState<TargetLimitState>(
    () => initialState.limit ?? LIMIT_INITIAL_STATE
  );

  const [filter, setFilter] = useState<TargetFilterState>(
    () => initialState.filter ?? FILTER_INITIAL_STATE
  );

  const [options, setOptions] = useState<ApiOptions>(initialState.options);

  const [transformPreset, setTransformPreset] = useState<TransformPresetType>(
    () =>
      getInitialTransformPreset(
        initialState,
        representation,
        connectionTypeInfo
      )
  );

  const defaultStructure = getDefaultStructure({
    scope,
    scopeDependencies,
    representation,
    humanReadable,
  });

  const [customStructure, setCustomStructure] = useState<
    TargetStructureTransformationFormInput[]
  >(() => getInitialCustomStructure(defaultStructure, initialState));

  // flag for showing outcome score in filter
  const showOutcomeScore = filter.some(
    (filter) => filter.type === "outcomeScore"
  );

  // Target limit using percentile method is deprecated. Users should use target
  // filters when they want to receive rows that meet outcome score criteria.
  // Only show the percentile limit for currently existing targets that use it.
  // New targets cannot use it.
  const showLimitPercentile =
    initialState.limit?.__typename === "TargetLimitPercentile" &&
    initialState.limit.percentileOutcomeId !== null;

  function saveTarget() {
    onSave({
      options,
      limit: targetLimitToInput(limit),
      representation: targetRepresentationToInput(
        representation,
        transformPreset
      ),
      humanReadable,
      filter: targetFilterToWire(filter),
      customStructure: hasCustomStructure(defaultStructure, customStructure)
        ? customStructure
            .filter((s) => s.show)
            .map((s) => ({
              scopeCol: s.scopeCol,
              outputCol: s.newOutputCol,
              isIdentityCol: s.isIdentityCol ?? undefined,
              aggregation: s.aggregation ?? undefined,
              aggregationCriteria: s.aggregationCriteria ?? undefined,
            }))
        : undefined,
    });
  }

  function handleFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    saveTarget();
  }

  function handleConnectionOptionChange(
    name: string,
    value: string | number | boolean
  ) {
    setOptions({ ...options, [name]: value });
  }

  const fieldGroups = getTargetOptionsGroups(connectionTypeInfo.id);
  const hasRequiredOptions = fieldGroups.required.length > 0;
  const hasOptionalOptions = fieldGroups.optional.length > 0;

  const showFilterFields =
    scope.payload.outcomeIds.length > 0 ||
    scope.payload.cohortIds.length > 0 ||
    scope.payload.personaSetIds.length > 0 ||
    scope.payload.recommenderIds.length > 0 ||
    scope.payload.attributes.length > 0;

  const showAdvancedFields = hasOptionalOptions || showFilterFields;

  const isLookupApi = isConnectionType(connectionTypeInfo, "lookup_api");

  const filterFields = (
    <TargetFilter
      filter={filter}
      onChange={setFilter}
      scopeDependencies={scopeDependencies}
      traits={traitsMap}
      showOutcomeScore={showOutcomeScore}
    />
  );

  const limitFields = (
    <TargetLimit
      limit={limit}
      showPercentileLimit={showLimitPercentile}
      onChange={setLimit}
      payloadOutcomes={scopeDependencies.payloadOutcomes}
      lockedTypename={lockedLimitTypename}
    />
  );

  const structureFields = (
    <TargetStructure
      customizable={Boolean(
        scope.columns && scope.columns.payloadColumns.length > 0
      )}
      representation={representation}
      transformPreset={transformPreset}
      hasCustomStructure={hasCustomStructure(defaultStructure, customStructure)}
      structure={customStructure}
      defaultStructure={defaultStructure}
      onChangeCustomStructure={setCustomStructure}
      onChangeTransformPreset={setTransformPreset}
    />
  );

  const optionFields = (
    <ConnectionOptionFields
      fields={fieldGroups.optional}
      values={options}
      onFieldChange={handleConnectionOptionChange}
    />
  );

  const accordionItems: (AccordionV2ItemProps & {
    show: boolean;
  })[] = [
    {
      title: "Filter",
      subtitle: "Only include rows meeting certain criteria",
      children: filterFields,
      show: showFilterFields,
      disabled: isLookupApi,
      analyticsName: "filter",
    },
    {
      title: "Limit",
      subtitle: "Cap the number of rows",
      children: limitFields,
      show: true,
      disabled: isLookupApi,
      analyticsName: "limit",
    },
    {
      title: "Structure",
      subtitle: "Rename, reorder, and hide columns",
      children: structureFields,
      show: true,
      disabled: isLookupApi,
      analyticsName: "structure",
    },
    {
      title: connectionTypeInfo.slug === "hosted_csv" ? "Format" : "Options",
      subtitle:
        connectionTypeInfo.slug === "hosted_csv"
          ? "Control CSV delimiter, quoting, and compression"
          : "Control connection options",
      children: optionFields,
      show: hasOptionalOptions,
      disabled: isLookupApi,
      analyticsName: "options",
    },
  ].filter((item) => item.show);

  const title = `Configure your ${representation.mode} ${connectionTypeInfo.literate} deployment.`;

  return (
    <AnalyticsStack value="advanced">
      <form onSubmit={handleFormSubmit}>
        <ScrollWrap>
          {isLookupApi && <LookupTargetNotice />}
          <FormFieldset
            legend={
              hasRequiredOptions ? (
                title
              ) : (
                <VisuallyHidden>{title}</VisuallyHidden>
              )
            }
          >
            {hasRequiredOptions ? (
              <Box mb={6}>
                <ConnectionOptionFields
                  fields={fieldGroups.required}
                  values={options}
                  onFieldChange={handleConnectionOptionChange}
                />
              </Box>
            ) : null}

            {showAdvancedFields && (
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Heading as="h2">Advanced settings</Heading>
                <Text
                  as="span"
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "fdy_sm",
                    color: "fdy_gray.700",
                  }}
                >
                  Optional
                </Text>
              </Box>
            )}

            <AccordionV2 allowMultiple>
              {accordionItems.map((item, i) => (
                <AccordionV2.Item key={i} {...item} />
              ))}
            </AccordionV2>
          </FormFieldset>
        </ScrollWrap>
        <InlineButtons sx={{ p: 6 }} reverse>
          <Button
            type="submit"
            variant="primary"
            isLoading={saving}
            loadingText="Saving..."
            analyticsName="save"
          >
            Save
          </Button>
          <Button variant="tertiary" onClick={onBack} analyticsName="back">
            Back
          </Button>
        </InlineButtons>
      </form>
    </AnalyticsStack>
  );
}
