import { VisuallyHidden } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Children, ReactElement, ReactNode } from "react";

import { theme } from "../../constants/theme";
import chakraThemeV2, { colors } from "../../styles/chakra-theme-v2";
import { useId } from "../ui/useId";

const StyledNavigationBarGroup = styled.nav`
  flex-grow: 0;
  flex-shrink: 0;

  > ul {
    padding-bottom: ${theme.space(2)};

    > li {
      padding-block: ${theme.space(0.75)};
    }
  }
`;

const StyledNavbarGroupLabel = styled.h3`
  text-transform: uppercase;
  color: ${colors.fdy_gray[200]};
  font-family: ${chakraThemeV2.fonts.body};
  font-size: ${chakraThemeV2.fontSizes.fdy_xs};
  font-weight: 400;
`;

interface NavigationBarGroupProps {
  children: ReactNode; // the menu options in this group
  hideLabel?: boolean; // if true, the label is visually hidden
  label: string; // label for this menu, required!
}

export function NavigationBarGroup({
  children,
  hideLabel,
  label,
}: NavigationBarGroupProps): ReactElement {
  const id = useId("nav-bar-group");

  const items = Children.map(children, (c) => (c ? <li>{c}</li> : null));
  const labelNode = hideLabel ? (
    <VisuallyHidden>
      <StyledNavbarGroupLabel id={id}>{label}</StyledNavbarGroupLabel>
    </VisuallyHidden>
  ) : (
    <StyledNavbarGroupLabel id={id}>{label}</StyledNavbarGroupLabel>
  );

  return (
    <StyledNavigationBarGroup aria-labelledby={id}>
      {labelNode}
      <ul>{items}</ul>
    </StyledNavigationBarGroup>
  );
}
