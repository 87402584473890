import {
  Configuration,
  ErrorCode,
  FaradayClient,
  Middleware,
  ModelError,
  ValidationError,
} from "@fdy/faraday-js";
import { createContext, useContext } from "react";
import * as React from "react";

import { useAuth } from "../components/ui/AuthProvider/AuthProvider";
import { isPlainObject } from "../utils/isPlainObject";
import { getApiBaseUrl } from "./getApiBaseUrl";

const FdyClientContext = createContext<FaradayClient | undefined>(undefined);

export function isFdyJsModelError(data: unknown): data is ModelError {
  return (
    isPlainObject(data) && "id" in data && "error" in data && "note" in data
  );
}

// Set up a custom error type for Faraday errors so we can handle them elsewhere in the app
// using simple `instanceof` checks. Sometimes we want to know status codes, not
// just error string name (they also feel safer than strings).
export class FdyClientError extends Error {
  id: string;
  name: ErrorCode;
  statusCode: number;
  validationErrors?: ValidationError[];

  constructor(code: number, err: ModelError) {
    super(err.note);
    this.id = err.id;
    this.name = err.error;
    this.statusCode = code;
    this.validationErrors = err.validationErrors;
  }
}

const errorHandlerMiddleware: Middleware = {
  post: async (resp) => {
    if (resp.response.ok) return resp.response;

    const json = await resp.response.json();

    if (isFdyJsModelError(json)) {
      throw new FdyClientError(resp.response.status, json);
    } else {
      throw new Error(
        `Unknown Fdy client error: ${resp.response.status}: ${resp.response.statusText}`
      );
    }
  },
};

export const FdyClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const auth = useAuth();

  const client = new FaradayClient(
    new Configuration({
      accessToken: () => auth.getToken(),
      basePath: getApiBaseUrl(),
      middleware: [errorHandlerMiddleware],
    })
  );

  return (
    <FdyClientContext.Provider value={client}>
      {children}
    </FdyClientContext.Provider>
  );
};

export const useFdyClient = () => {
  const context = useContext(FdyClientContext);

  if (context === undefined) {
    throw new Error("useFdyClient must be used within a FaradayClientProvider");
  }

  return context;
};
