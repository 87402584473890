import { Axis } from "@visx/xychart";
import { AxisProps } from "@visx/xychart/lib/components/axis/Axis";

import { chakraThemeV2 } from "../../../styles/chakra-theme-v2";

const BottomAxisTick: AxisProps["tickComponent"] = ({
  x,
  y,
  formattedValue,
  transform,
}) => {
  if (!formattedValue) return null;

  const dateObj = new Date(formattedValue);

  const monthAndDay = dateObj.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const year = dateObj.getFullYear();

  const topPad = 8;

  const styles = {
    fontFamily: chakraThemeV2.fonts.body,
    fontSize: 12,
    textAnchor: "middle",
    transform,
    x,
    y: y + topPad,
  };

  return (
    <>
      <text {...styles} dy="0" fontWeight="bold">
        {monthAndDay}
      </text>
      <text {...styles} y={y + topPad + 16}>
        {year}
      </text>
    </>
  );
};

/**
 * Renders a visx chart bottom axis with dates.
 * Uses custom tick component to render dates in a more compact format.
 *
 * @note Should be used within a visx chart that 'time' X axis.
 * Data will be passed via chart context, so it expects to have date values.
 */
export function AxisBottomDates(props: Omit<AxisProps, "orientation">) {
  return (
    <Axis
      orientation="bottom"
      tickComponent={BottomAxisTick}
      tickFormat={(d) => d.toString()}
      hideAxisLine
      hideTicks
      {...props}
    />
  );
}
