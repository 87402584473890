import { Box, Heading, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

import { OutcomeFragment } from "../../__generated__/OutcomeFragment";
import { OutcomeAnalysisQuery_outcomeAnalysis_bias as BiasReport } from "../__generated__/OutcomeAnalysisQuery";
import { BiasLevelIcon } from "./BiasLevelIcon";

function SummaryItem({
  ok,
  title,
  text,
  onDetailClick,
}: {
  title: string;
  ok: boolean;
  text: ReactNode;
  onDetailClick: () => void;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 4,
        py: 2,
      }}
    >
      <BiasLevelIcon ok={ok} />
      <Box>
        <Heading as="h3" size="sm" fontWeight="600" mb={1}>
          {title}.{" "}
          <Text
            as="button"
            onClick={onDetailClick}
            sx={{ color: "primary", fontWeight: 500 }}
          >
            Details &rarr;
          </Text>
        </Heading>
        <Text color="fdy_gray.700">{text}</Text>
      </Box>
    </Box>
  );
}

// The members of Customers<attainment> and Leads<elig> differ significantly along sensitive dimensions.
export function OutcomeBiasSummary({
  summary,
  onDetailClick,
  outcome,
}: {
  outcome: OutcomeFragment;
  summary: BiasReport["summary"];
  onDetailClick: (tab: number) => void;
}) {
  const { dataUnbiased, predictionsUnbiased, powerUnbiased, fairnessFair } =
    summary;

  return (
    <Box>
      <SummaryItem
        title={`Historical data is ${dataUnbiased ? "unbiased" : "biased"}`}
        text={
          <>
            The members of <strong>{outcome.attainmentCohortName}</strong> and{" "}
            <strong>{outcome.eligibleCohortName ?? "anyone in US"}</strong>{" "}
            {dataUnbiased ? "are similar" : "differ significantly"} along
            sensitive dimensions.
          </>
        }
        ok={dataUnbiased}
        onDetailClick={() => onDetailClick(1)}
      />
      <SummaryItem
        title={`Predictive power is ${powerUnbiased ? "unbiased" : "biased"}`}
        text={
          powerUnbiased
            ? "Predictions are equally accurate across sensitive subgroups."
            : "Predictions about some sensitive subgroups are more accurate than for others."
        }
        ok={powerUnbiased}
        onDetailClick={() => onDetailClick(2)}
      />
      <SummaryItem
        title={`Predictions are ${predictionsUnbiased ? "unbiased" : "biased"}`}
        text={
          predictionsUnbiased
            ? "Predictions are equally representative of sensitive subgroups"
            : "Predictions are skewed toward some sensitive subgroups and away from others."
        }
        ok={predictionsUnbiased}
        onDetailClick={() => onDetailClick(3)}
      />
      <SummaryItem
        title={`This outcome is ${fairnessFair ? "fair" : "unfair"}`}
        text={
          fairnessFair
            ? "Using this outcome would not unfairly privilege any sensitive subgroups."
            : "Using this outcome would unfairly privilege certain sensitive subgroups over others."
        }
        ok={fairnessFair}
        onDetailClick={() => onDetailClick(4)}
      />
    </Box>
  );
}
