import { Margin } from "@visx/xychart";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import * as React from "react";

export const CHART_DEFAULT_MARGIN: Margin = {
  top: 32,
  left: 80,
  right: 32,
  bottom: 60,
};

type FdyChartContextValue = {
  margin: Margin;
  setMargin?: Dispatch<SetStateAction<Margin>>;
};

const defaultChartOptions: FdyChartContextValue = {
  margin: CHART_DEFAULT_MARGIN,
};

export const FdyChartContext =
  createContext<FdyChartContextValue>(defaultChartOptions);

/**
 * Control chart details at a higher level since some inner components may want to adjust them.
 *
 * I tried using visx's DataContext which has a setDimensions callback, but could not get it to work.
 */
export function FdyChartProvider({ children }: { children: React.ReactNode }) {
  const [margin, setMargin] = useState(CHART_DEFAULT_MARGIN);

  const value = {
    margin,
    setMargin,
  };

  return (
    <FdyChartContext.Provider value={value}>
      {children}
    </FdyChartContext.Provider>
  );
}

export function useFdyChartContext() {
  return useContext(FdyChartContext);
}
