import { ChakraProvider } from "@chakra-ui/react";
import { IconContext } from "@phosphor-icons/react";
import { ReactElement } from "react";
import Rollbar from "rollbar";

import { CONFIG, ROUTES } from "../../constants/routes";
import { Analytics } from "../../services/analytics/Analytics";
import { FdyClientProvider } from "../../services/FdyClientProvider";
import { SojournerApolloProvider } from "../../services/sojournerApolloClient";
import chakraThemeV2 from "../../styles/chakra-theme-v2";
import { GlobalStyles } from "../../styles/GlobalStyles";
import { UserEnsurer } from "../../UserEnsurer";
import { AnalyticsProvider } from "../ui/Analytics/AnalyticsProvider";
import { ApolloProvider } from "../ui/ApolloProvider";
import { AuthProvider } from "../ui/AuthProvider/AuthProvider";
import { ConfettiPopperProvider } from "../ui/ConfettiPopper";
import { ErrorBoundary } from "../ui/ErrorBoundary";
import { ModalV2Provider } from "../ui/ModalV2";
import { NavigationWarningProvider } from "../ui/NavigationWarningProvider";
import { RollbarProvider } from "../ui/RollbarProvider";
import { Router5Provider } from "../ui/Router5/Router5Provider";
import { OldRoot } from "./OldRoot";
import { ReactQueryProvider } from "./ReactQueryProvider";

interface NewRootProps {
  analytics: Analytics;
  rollbar: Rollbar;
}

export function Root({ analytics, rollbar }: NewRootProps): ReactElement {
  return (
    <IconContext.Provider value={{ size: 16 }}>
      <ChakraProvider theme={chakraThemeV2}>
        <AnalyticsProvider analytics={analytics}>
          <RollbarProvider rollbar={rollbar}>
            <NavigationWarningProvider>
              <AuthProvider>
                <ErrorBoundary>
                  <FdyClientProvider>
                    <ReactQueryProvider>
                      <ConfettiPopperProvider>
                        <SojournerApolloProvider>
                          <ApolloProvider>
                            <UserEnsurer>
                              <Router5Provider config={CONFIG} routes={ROUTES}>
                                <ModalV2Provider>
                                  <GlobalStyles />
                                  <OldRoot />
                                </ModalV2Provider>
                              </Router5Provider>
                            </UserEnsurer>
                          </ApolloProvider>
                        </SojournerApolloProvider>
                      </ConfettiPopperProvider>
                    </ReactQueryProvider>
                  </FdyClientProvider>
                </ErrorBoundary>
              </AuthProvider>
            </NavigationWarningProvider>
          </RollbarProvider>
        </AnalyticsProvider>
      </ChakraProvider>
    </IconContext.Provider>
  );
}
