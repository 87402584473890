import { Center, VisuallyHidden } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const spinX = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
`;

const BoltLeft = styled.path`
  fill: #f72f96;
  transform-origin: center;
  animation: ${spinX} 1.35s ease-in-out infinite;
`;

const BoltRight = styled.path`
  fill: #b641ff;
  transform-origin: center;
  animation: ${spinX} 1.35s ease-in-out infinite;
  animation-delay: 0.2s;
`;

/**
 * The Faraday logo with an animation.
 * Used to indicate loading state.
 */
export function AnimatedZapLogo({ center = true }: { center?: boolean }) {
  const logo = (
    <svg
      aria-hidden
      viewBox="0 0 38 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={60}
    >
      <BoltLeft d="M0 16.9996L17.0788 0V16.9996H0Z" />
      <BoltRight d="M37.1574 0L20.0786 16.9996V0H37.1574Z" />
    </svg>
  );

  const content = (
    <>
      <VisuallyHidden>Loading...</VisuallyHidden>
      {logo}
    </>
  );

  if (center) return <Center minH="xs">{content}</Center>;

  return content;
}
