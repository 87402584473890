import {
  FormControl,
  FormErrorMessage,
  Text,
  useId,
  VisuallyHidden,
} from "@chakra-ui/react";
import { ChangeEvent, Dispatch, FormEvent, SetStateAction } from "react";

import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { Button } from "../../../ui/Button";
import { InlineButtons } from "../../../ui/InlineButtons";
import { ModalV2 } from "../../../ui/ModalV2";
import { RouterLink } from "../../../ui/RouterLink";
import { Select } from "../../../ui/Select";
import { EventState } from "../CohortForm";
import { CohortStreamsQuery_streams } from "./__generated__/CohortStreamsQuery";

/**
 * Renders a modal with a multi-step form for creating or modifying a cohort event.
 */
export function CohortEventsStreamSelectModal({
  streams,
  onClose,
  onCancel,
  onNext,
  onEventChange,
  event,
  error,
}: {
  streams: CohortStreamsQuery_streams[];
  onClose: () => void;
  onEventChange: Dispatch<SetStateAction<EventState | undefined>>;
  onCancel: () => void;
  onNext: () => void;
  event: EventState | undefined;
  error?: string;
}) {
  const sortedStreams = [...streams]
    .filter((stream) => {
      return !stream.archivedAt || stream.name === event?.streamName;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  const formId = useId();

  const eventStreamId = "event-stream";

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    const val = e.target.value;
    onEventChange((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        streamName: val === "" ? null : val,
        streamConditions: [],
      };
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onNext();
  };

  return (
    <ModalV2
      title="Add event"
      onClose={onClose}
      isOpen
      footer={
        <InlineButtons reverse>
          <Button
            type="submit"
            variant="primary"
            form={formId}
            analyticsName="next"
          >
            Next
          </Button>
          <Button variant="tertiary" onClick={onCancel} analyticsName="cancel">
            Cancel
          </Button>
        </InlineButtons>
      }
      analyticsStackName="add-event"
    >
      <form onSubmit={handleSubmit} id={formId}>
        <Text fontSize="fdy_lg" mb={5}>
          Choose an event type that will define this group of people. You can
          identify new events in your data with{" "}
          <RouterLink routeName={ROUTE_NAMES.DATASETS}>datasets</RouterLink>.
        </Text>
        <FormControl isInvalid={!!error}>
          <VisuallyHidden>
            <label htmlFor={eventStreamId}>Event stream</label>
          </VisuallyHidden>
          <Select
            id={eventStreamId}
            placeholder={"Select an event..."}
            value={event?.streamName ?? ""}
            onChange={handleSelect}
            autoFocus
            analyticsName="select-event-stream"
          >
            {sortedStreams.map((stream) => (
              <option key={stream.id} value={stream.name}>
                {stream.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
      </form>
    </ModalV2>
  );
}
