
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// This file is generated by scripts/codegen-connection-options-gql.ts

import { gql } from "@apollo/client";


export const connectionOptionsFragment = gql`
  fragment ConnectionOptions on Connection {
    options {
      __typename
      ... on ConnectionOptionsClassic {
        type
      }
      ... on ConnectionOptionsHostedCsv {
        type
      }
      ... on ConnectionOptionsLookupApi {
        type
      }
      ... on ConnectionOptionsMerge {
        type
      }
      ... on ConnectionOptionsPoplar {
        type
      }
      ... on ConnectionOptionsIterable {
        type
        webhookUrl
      }
      ... on ConnectionOptionsRecharge {
        type
        apiKey
      }
      ... on ConnectionOptionsSegment {
        type
        apiKey
      }
      ... on ConnectionOptionsTiktok {
        type
        tiktokAccountId
      }
      ... on ConnectionOptionsBigQuery {
        type
        datasetName
        projectId
      }
      ... on ConnectionOptionsFacebookCustomAudiences {
        type
        accountId
        accountLiterate
      }
      ... on ConnectionOptionsGcpGcsCsv {
        type
        bucketName
        projectId
      }
      ... on ConnectionOptionsHubspot {
        type
        optionalPassword: password
        optionalUsername: username
      }
      ... on ConnectionOptionsKlaviyo {
        type
        apiKey
        privateApiKey
      }
      ... on ConnectionOptionsLinkedinAds {
        type
        optionalPassword: password
        optionalUsername: username
      }
      ... on ConnectionOptionsS3Csv {
        type
        awsRegion
        bucketName
      }
      ... on ConnectionOptionsSalesforce {
        type
        optionalPassword: password
        optionalUsername: username
      }
      ... on ConnectionOptionsSalesforceMarketingCloud {
        type
        clientId
        clientSecret
      }
      ... on ConnectionOptionsShopify {
        type
        optionalPassword: password
        optionalUsername: username
      }
      ... on ConnectionOptionsStripe {
        type
        optionalPassword: password
        optionalUsername: username
      }
      ... on ConnectionOptionsGoogleAds {
        type
        customerId
        optionalPassword: password
        optionalUsername: username
      }
      ... on ConnectionOptionsPinterestAds {
        type
        businessId
        optionalPassword: password
        optionalUsername: username
      }
      ... on ConnectionOptionsTheTradeDesk {
        type
        advertiserId
        apiKey
        environment
      }
      ... on ConnectionOptionsGcpCloudSqlMysql {
        type
        database
        instanceConnectionName
        password
        user
      }
      ... on ConnectionOptionsGcpCloudSqlPostgres {
        type
        database
        instanceConnectionName
        password
        schema
        user
      }
      ... on ConnectionOptionsAwsAuroraMysql {
        type
        database
        host
        loadBalancer
        password
        port
        sshBastion
        user
      }
      ... on ConnectionOptionsAwsRdsMysql {
        type
        database
        host
        loadBalancer
        password
        port
        sshBastion
        user
      }
      ... on ConnectionOptionsGcpCloudSqlSqlServer {
        type
        database
        instanceConnectionName
        loadBalancer
        password
        optionalSchema: schema
        sshBastion
        user
      }
      ... on ConnectionOptionsMysql {
        type
        database
        host
        loadBalancer
        password
        port
        sshBastion
        user
      }
      ... on ConnectionOptionsSftp {
        type
        compression
        encryption
        host
        hostKey
        optionalPassword: password
        port
        user
      }
      ... on ConnectionOptionsAwsAuroraPostgres {
        type
        database
        host
        loadBalancer
        password
        port
        schema
        sshBastion
        user
      }
      ... on ConnectionOptionsAwsRdsPostgres {
        type
        database
        host
        loadBalancer
        password
        port
        schema
        sshBastion
        user
      }
      ... on ConnectionOptionsAwsRdsSqlServer {
        type
        database
        host
        loadBalancer
        password
        port
        optionalSchema: schema
        sshBastion
        user
      }
      ... on ConnectionOptionsAzureSqlServer {
        type
        database
        host
        loadBalancer
        password
        port
        optionalSchema: schema
        sshBastion
        user
      }
      ... on ConnectionOptionsPostgres {
        type
        database
        host
        loadBalancer
        password
        port
        schema
        sshBastion
        user
      }
      ... on ConnectionOptionsSnowflake {
        type
        accountName
        database
        legacyAccountLocator
        organizationName
        role
        schema
        user
        warehouse
      }
      ... on ConnectionOptionsSqlServer {
        type
        database
        host
        loadBalancer
        password
        port
        optionalSchema: schema
        sshBastion
        user
      }
      ... on ConnectionOptionsAwsRedshiftServerless {
        type
        awsRegion
        database
        host
        loadBalancer
        password
        port
        schema
        sshBastion
        user
      }
      ... on ConnectionOptionsRedshift {
        type
        awsRegion
        database
        host
        loadBalancer
        password
        port
        schema
        sshBastion
        user
      }
    }
  }
`;

export const connectionOptionsTypesFragment = gql`
  fragment ConnectionOptionsTypes on Connection {
    options {
      __typename
      ... on ConnectionOptionsClassic {
        type
      }
      ... on ConnectionOptionsHostedCsv {
        type
      }
      ... on ConnectionOptionsLookupApi {
        type
      }
      ... on ConnectionOptionsMerge {
        type
      }
      ... on ConnectionOptionsPoplar {
        type
      }
      ... on ConnectionOptionsIterable {
        type
      }
      ... on ConnectionOptionsRecharge {
        type
      }
      ... on ConnectionOptionsSegment {
        type
      }
      ... on ConnectionOptionsTiktok {
        type
      }
      ... on ConnectionOptionsBigQuery {
        type
      }
      ... on ConnectionOptionsFacebookCustomAudiences {
        type
      }
      ... on ConnectionOptionsGcpGcsCsv {
        type
      }
      ... on ConnectionOptionsHubspot {
        type
      }
      ... on ConnectionOptionsKlaviyo {
        type
      }
      ... on ConnectionOptionsLinkedinAds {
        type
      }
      ... on ConnectionOptionsS3Csv {
        type
      }
      ... on ConnectionOptionsSalesforce {
        type
      }
      ... on ConnectionOptionsSalesforceMarketingCloud {
        type
      }
      ... on ConnectionOptionsShopify {
        type
      }
      ... on ConnectionOptionsStripe {
        type
      }
      ... on ConnectionOptionsGoogleAds {
        type
      }
      ... on ConnectionOptionsPinterestAds {
        type
      }
      ... on ConnectionOptionsTheTradeDesk {
        type
      }
      ... on ConnectionOptionsGcpCloudSqlMysql {
        type
      }
      ... on ConnectionOptionsGcpCloudSqlPostgres {
        type
      }
      ... on ConnectionOptionsAwsAuroraMysql {
        type
      }
      ... on ConnectionOptionsAwsRdsMysql {
        type
      }
      ... on ConnectionOptionsGcpCloudSqlSqlServer {
        type
      }
      ... on ConnectionOptionsMysql {
        type
      }
      ... on ConnectionOptionsSftp {
        type
      }
      ... on ConnectionOptionsAwsAuroraPostgres {
        type
      }
      ... on ConnectionOptionsAwsRdsPostgres {
        type
      }
      ... on ConnectionOptionsAwsRdsSqlServer {
        type
      }
      ... on ConnectionOptionsAzureSqlServer {
        type
      }
      ... on ConnectionOptionsPostgres {
        type
      }
      ... on ConnectionOptionsSnowflake {
        type
      }
      ... on ConnectionOptionsSqlServer {
        type
      }
      ... on ConnectionOptionsAwsRedshiftServerless {
        type
      }
      ... on ConnectionOptionsRedshift {
        type
      }
    }
  }
`;

export const datasetOptionsFragment = gql`
  fragment DatasetOptions on Dataset {
    options {
      __typename
      ... on DatasetOptionsClassic {
        type
      }
      ... on DatasetOptionsHubspot {
        type
      }
      ... on DatasetOptionsIterable {
        type
      }
      ... on DatasetOptionsKlaviyo {
        type
      }
      ... on DatasetOptionsRecharge {
        type
      }
      ... on DatasetOptionsSalesforce {
        type
      }
      ... on DatasetOptionsShopify {
        type
      }
      ... on DatasetOptionsStripe {
        type
      }
      ... on DatasetOptionsAwsAuroraMysql {
        type
        tableName
      }
      ... on DatasetOptionsAwsAuroraPostgres {
        type
        tableName
      }
      ... on DatasetOptionsAwsRdsMysql {
        type
        tableName
      }
      ... on DatasetOptionsAwsRdsPostgres {
        type
        tableName
      }
      ... on DatasetOptionsAwsRdsSqlServer {
        type
        tableName
      }
      ... on DatasetOptionsAwsRedshiftServerless {
        type
        tableName
      }
      ... on DatasetOptionsAzureSqlServer {
        type
        tableName
      }
      ... on DatasetOptionsBigQuery {
        type
        tableName
      }
      ... on DatasetOptionsGcpCloudSqlMysql {
        type
        tableName
      }
      ... on DatasetOptionsGcpCloudSqlPostgres {
        type
        tableName
      }
      ... on DatasetOptionsGcpCloudSqlSqlServer {
        type
        tableName
      }
      ... on DatasetOptionsMysql {
        type
        tableName
      }
      ... on DatasetOptionsPostgres {
        type
        tableName
      }
      ... on DatasetOptionsRedshift {
        type
        tableName
      }
      ... on DatasetOptionsSalesforceMarketingCloud {
        type
        tenantSubdomain
      }
      ... on DatasetOptionsSqlServer {
        type
        tableName
      }
      ... on DatasetOptionsMerge {
        type
        merge { datasetId, joinColumn }
        migrate
      }
      ... on DatasetOptionsSnowflake {
        type
        caseSensitiveColumns
        tableName
      }
      ... on DatasetOptionsGcpGcsCsv {
        type
        delimiter
        encrypted
        headerRow
        prefix
        replaceAllWithLatestFile
      }
      ... on DatasetOptionsHostedCsv {
        type
        delimiter
        encrypted
        headerRow
        replaceAllWithLatestFile
        uploadDirectory
      }
      ... on DatasetOptionsS3Csv {
        type
        delimiter
        encrypted
        headerRow
        prefix
        replaceAllWithLatestFile
      }
      ... on DatasetOptionsSftp {
        type
        delimiter
        encrypted
        headerRow
        prefix
        replaceAllWithLatestFile
      }
    }
  }
`;

export const datasetOptionsTypesFragment = gql`
  fragment DatasetOptionsTypes on Dataset {
    options {
      __typename
      ... on DatasetOptionsClassic {
        type
      }
      ... on DatasetOptionsHubspot {
        type
      }
      ... on DatasetOptionsIterable {
        type
      }
      ... on DatasetOptionsKlaviyo {
        type
      }
      ... on DatasetOptionsRecharge {
        type
      }
      ... on DatasetOptionsSalesforce {
        type
      }
      ... on DatasetOptionsShopify {
        type
      }
      ... on DatasetOptionsStripe {
        type
      }
      ... on DatasetOptionsAwsAuroraMysql {
        type
      }
      ... on DatasetOptionsAwsAuroraPostgres {
        type
      }
      ... on DatasetOptionsAwsRdsMysql {
        type
      }
      ... on DatasetOptionsAwsRdsPostgres {
        type
      }
      ... on DatasetOptionsAwsRdsSqlServer {
        type
      }
      ... on DatasetOptionsAwsRedshiftServerless {
        type
      }
      ... on DatasetOptionsAzureSqlServer {
        type
      }
      ... on DatasetOptionsBigQuery {
        type
      }
      ... on DatasetOptionsGcpCloudSqlMysql {
        type
      }
      ... on DatasetOptionsGcpCloudSqlPostgres {
        type
      }
      ... on DatasetOptionsGcpCloudSqlSqlServer {
        type
      }
      ... on DatasetOptionsMysql {
        type
      }
      ... on DatasetOptionsPostgres {
        type
      }
      ... on DatasetOptionsRedshift {
        type
      }
      ... on DatasetOptionsSalesforceMarketingCloud {
        type
      }
      ... on DatasetOptionsSqlServer {
        type
      }
      ... on DatasetOptionsMerge {
        type
      }
      ... on DatasetOptionsSnowflake {
        type
      }
      ... on DatasetOptionsGcpGcsCsv {
        type
      }
      ... on DatasetOptionsHostedCsv {
        type
      }
      ... on DatasetOptionsS3Csv {
        type
      }
      ... on DatasetOptionsSftp {
        type
      }
    }
  }
`;

export const targetOptionsFragment = gql`
  fragment TargetOptions on Target {
    options {
      __typename
      ... on TargetOptionsFacebookCustomAudiences {
        type
      }
      ... on TargetOptionsGoogleAds {
        type
      }
      ... on TargetOptionsHubspot {
        type
      }
      ... on TargetOptionsKlaviyo {
        type
      }
      ... on TargetOptionsLinkedinAds {
        type
      }
      ... on TargetOptionsPinterestAds {
        type
      }
      ... on TargetOptionsPoplar {
        type
      }
      ... on TargetOptionsSalesforce {
        type
      }
      ... on TargetOptionsSegment {
        type
      }
      ... on TargetOptionsTheTradeDesk {
        type
      }
      ... on TargetOptionsTiktok {
        type
      }
      ... on TargetOptionsAwsAuroraMysql {
        type
        tableName
      }
      ... on TargetOptionsAwsAuroraPostgres {
        type
        tableName
      }
      ... on TargetOptionsAwsRdsMysql {
        type
        tableName
      }
      ... on TargetOptionsAwsRdsPostgres {
        type
        tableName
      }
      ... on TargetOptionsAwsRdsSqlServer {
        type
        tableName
      }
      ... on TargetOptionsAwsRedshiftServerless {
        type
        tableName
      }
      ... on TargetOptionsAzureSqlServer {
        type
        tableName
      }
      ... on TargetOptionsGcpCloudSqlMysql {
        type
        tableName
      }
      ... on TargetOptionsGcpCloudSqlPostgres {
        type
        tableName
      }
      ... on TargetOptionsGcpCloudSqlSqlServer {
        type
        tableName
      }
      ... on TargetOptionsLookupApi {
        type
        outputUrl
      }
      ... on TargetOptionsMysql {
        type
        tableName
      }
      ... on TargetOptionsPostgres {
        type
        tableName
      }
      ... on TargetOptionsRedshift {
        type
        tableName
      }
      ... on TargetOptionsSqlServer {
        type
        tableName
      }
      ... on TargetOptionsIterable {
        type
        apiKey
        projectIdentifier
      }
      ... on TargetOptionsSnowflake {
        type
        tableName
        upsert
      }
      ... on TargetOptionsBigQuery {
        type
        dayPartitioned
        tableName
        upsert
      }
      ... on TargetOptionsGcpGcsCsv {
        type
        delimiter
        gzip
        objectKey
        quoteAll
      }
      ... on TargetOptionsHostedCsv {
        type
        delimiter
        gzip
        outputUrl
        quoteAll
      }
      ... on TargetOptionsS3Csv {
        type
        delimiter
        gzip
        objectKey
        quoteAll
      }
      ... on TargetOptionsSalesforceMarketingCloud {
        type
        apiSubdomain
        ftpPrivateKey
        ftpUserPassword
        ftpUsername
      }
      ... on TargetOptionsSftp {
        type
        delimiter
        fileName
        gzip
        quoteAll
      }
    }
  }
`;

export const targetOptionsTypesFragment = gql`
  fragment TargetOptionsTypes on Target {
    options {
      __typename
      ... on TargetOptionsFacebookCustomAudiences {
        type
      }
      ... on TargetOptionsGoogleAds {
        type
      }
      ... on TargetOptionsHubspot {
        type
      }
      ... on TargetOptionsKlaviyo {
        type
      }
      ... on TargetOptionsLinkedinAds {
        type
      }
      ... on TargetOptionsPinterestAds {
        type
      }
      ... on TargetOptionsPoplar {
        type
      }
      ... on TargetOptionsSalesforce {
        type
      }
      ... on TargetOptionsSegment {
        type
      }
      ... on TargetOptionsTheTradeDesk {
        type
      }
      ... on TargetOptionsTiktok {
        type
      }
      ... on TargetOptionsAwsAuroraMysql {
        type
      }
      ... on TargetOptionsAwsAuroraPostgres {
        type
      }
      ... on TargetOptionsAwsRdsMysql {
        type
      }
      ... on TargetOptionsAwsRdsPostgres {
        type
      }
      ... on TargetOptionsAwsRdsSqlServer {
        type
      }
      ... on TargetOptionsAwsRedshiftServerless {
        type
      }
      ... on TargetOptionsAzureSqlServer {
        type
      }
      ... on TargetOptionsGcpCloudSqlMysql {
        type
      }
      ... on TargetOptionsGcpCloudSqlPostgres {
        type
      }
      ... on TargetOptionsGcpCloudSqlSqlServer {
        type
      }
      ... on TargetOptionsLookupApi {
        type
      }
      ... on TargetOptionsMysql {
        type
      }
      ... on TargetOptionsPostgres {
        type
      }
      ... on TargetOptionsRedshift {
        type
      }
      ... on TargetOptionsSqlServer {
        type
      }
      ... on TargetOptionsIterable {
        type
      }
      ... on TargetOptionsSnowflake {
        type
      }
      ... on TargetOptionsBigQuery {
        type
      }
      ... on TargetOptionsGcpGcsCsv {
        type
      }
      ... on TargetOptionsHostedCsv {
        type
      }
      ... on TargetOptionsS3Csv {
        type
      }
      ... on TargetOptionsSalesforceMarketingCloud {
        type
      }
      ... on TargetOptionsSftp {
        type
      }
    }
  }
`;
