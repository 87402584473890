import { Fragment } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { AccountConfigMap } from "../../../hooks/accountConfigHooks";
import { RouterLink } from "../../ui/RouterLink";

export const pipelineHelpText = {
  payload: (configMap: AccountConfigMap) => {
    const notes = [
      <>
        Do you want the population to be scored? Add an{" "}
        <RouterLink routeName={ROUTE_NAMES.OUTCOMES}>outcome</RouterLink>.
      </>,
      <>
        Do you want to assign personas to the population? Add a{" "}
        <RouterLink routeName={ROUTE_NAMES.COHORTS}>persona set</RouterLink>.
      </>,
    ];

    if (configMap["recommenders.enabled"]) {
      notes.push(
        <>
          Do you want to apply recommendations to the population? Add a{" "}
          <RouterLink routeName={ROUTE_NAMES.RECOMMENDERS}>
            recommender
          </RouterLink>
          .
        </>
      );
    }

    return notes.map((n, i) => <Fragment key={i}>{n} </Fragment>);
  },
  cohortMembership: (
    <>
      Do you want to know if the population is a member of another cohort? Add a{" "}
      <RouterLink routeName={ROUTE_NAMES.COHORTS}>cohort</RouterLink>.
    </>
  ),
  traits: (
    <>
      Do you want to know trait values for individuals in the population? Add{" "}
      <RouterLink routeName={ROUTE_NAMES.TRAITS}>traits</RouterLink>
    </>
  ),
  population: (
    <>
      Who do you want predictions for? If you're looking for new prospects
      choose everyone. New populations can be created in{" "}
      <RouterLink routeName={ROUTE_NAMES.COHORTS}>cohorts</RouterLink>.
    </>
  ),
  populationExclude: (
    <>
      Any cohorts you choose to exclude will be suppressed from the included
      cohorts. For example if using a lead generation outcome in your payload
      you should exclude your customers cohort.
    </>
  ),
  predictionExplainations:
    "Faraday will explain why each individual in the population received their associated predictions. Currently available for outcomes (requires at least one selected in predictions).",
};
