import {
  IconButton,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "@chakra-ui/react";
import { Plus, Trash } from "@phosphor-icons/react";
import Select from "react-select";

import { Button } from "../../ui/Button";
import {
  DatasetsTableQuery_connections,
  DatasetsTableQuery_datasets,
} from "../__generated__/DatasetsTableQuery";
import { DatasetColumnSelect } from "../DatasetsShowPage/DatasetForm/events/DatasetColumnSelect";
import {
  isSampleData,
  SampleData,
} from "../DatasetsShowPage/DatasetForm/shared/OptionWithSampleData";
import { DetectedColumn } from "../DatasetsShowPage/DatasetForm/shared/types";
import { DatasetSelectOption, reactSelectStyle } from "./reactSelectStyle";

// use a new type instead of DatasetShowPageQuery_dataset_options_DatasetOptionsMerge_merge
// because the values can *temporarily* be null
export type DatasetMergeFormat = {
  datasetId: string | null;
  joinColumn: string | null;
  __typename?: string;
} | null;

export function validationMergeDatasetErrors(merge: DatasetMergeFormat[]) {
  if (merge.length < 2) {
    return "Must merge at least two datasets";
  }
  if (merge.some((merge) => !merge?.datasetId)) return "Must choose a dataset";
  if (merge.some((merge) => !merge?.joinColumn))
    return "Must set a join column";
}

function MergeRow({
  idx,
  merge,
  setMerge,
  existingDatasets,
  clearError,
}: {
  idx: number;
  merge: DatasetMergeFormat[];
  setMerge: (arg: DatasetMergeFormat[]) => void;
  clearError: () => void;
  existingDatasets: DatasetsTableQuery_datasets[];
}) {
  const selectedDataset = existingDatasets.find(
    (dataset) => merge[idx]?.datasetId === dataset.id
  );

  const sampleData = isSampleData(selectedDataset?.sample ?? null)
    ? (selectedDataset?.sample as SampleData)
    : undefined;

  const datasetOptions: DatasetSelectOption[] = existingDatasets.map(
    (dataset) => {
      return {
        value: dataset.id,
        label: dataset.name,
        isDisabled: merge.some((merge) => merge?.datasetId === dataset.id),
      };
    }
  );

  function handleDeleteDataset() {
    const newMerge = [...merge];
    newMerge.splice(idx, 1);
    setMerge(newMerge);
  }

  function handleChangeDataset(
    newValue: { value: string; label: string } | null
  ) {
    const newDataset = {
      datasetId: newValue?.value ?? null,
      joinColumn: null, // new dataset probably has different cols than the old one
    };
    const newMerge = [...merge];
    newMerge[idx] = newDataset;
    setMerge(newMerge);
    clearError();
  }

  function handleChangeJoinColumn(newValue: string | null) {
    const newDataset = {
      datasetId: merge[idx]?.datasetId ?? null,
      joinColumn: newValue,
    };
    const newMerge = [...merge];
    newMerge[idx] = newDataset;
    setMerge(newMerge);
    clearError();
  }

  const detectedColumns =
    (selectedDataset?.detectedColumns as DetectedColumn[]) ?? [];
  return (
    <Tr>
      <Td p={1}>
        <Select<(typeof datasetOptions)[number]>
          aria-label={`Dataset ${idx}`}
          isClearable
          options={datasetOptions}
          value={
            datasetOptions.find((opt) => opt.value === merge[idx]?.datasetId) ??
            null
          }
          onChange={handleChangeDataset}
          name={`Dataset ${idx}`}
          styles={reactSelectStyle}
          menuPortalTarget={document.body} // select options should appear outside modal
        />
      </Td>
      <Td p={1}>
        <DatasetColumnSelect
          detectedColumns={detectedColumns}
          sampleData={sampleData}
          label={`Join column ${idx}`}
          disabled={!merge[idx]?.datasetId}
          onChange={handleChangeJoinColumn}
          value={merge[idx]?.joinColumn ?? null}
          analyticsName="merge-dataset-column"
        />
      </Td>
      <Td p={1}>
        <IconButton
          aria-label={`Delete ${idx}`}
          name={`Delete ${idx}`}
          variant="ghost"
          onClick={handleDeleteDataset}
        >
          <Trash />
        </IconButton>
      </Td>
    </Tr>
  );
}

export function DatasetMergeForm({
  merge,
  setMerge,
  clearError,
  existingDatasets,
  existingConnections,
}: {
  merge: DatasetMergeFormat[];
  setMerge: (arg: DatasetMergeFormat[]) => void;
  clearError: () => void;
  existingDatasets: DatasetsTableQuery_datasets[];
  existingConnections: DatasetsTableQuery_connections[];
}) {
  const mergeConnectionIds = existingConnections
    .filter((conn) => conn.options.type === "merge")
    .map((conn) => conn.id);
  const validDatasetsForMerge = existingDatasets.filter(
    (dataset) =>
      // include all datasets except merge datasets (need 'dataset.connectionId' for hosted csvs)
      !dataset.connectionId ||
      !mergeConnectionIds.includes(dataset.connectionId)
  );

  function handleAddDataset() {
    const newMerge = [...merge];
    newMerge.push({ datasetId: null, joinColumn: null });
    setMerge(newMerge);
  }

  return (
    <Table my={4}>
      <Thead>
        <Tr>
          <Th>Dataset name</Th>
          <Th>Join column</Th>
          <Th>
            <VisuallyHidden>Delete</VisuallyHidden>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {merge?.map((_, idx) => {
          return (
            <MergeRow
              idx={idx}
              merge={merge}
              setMerge={setMerge}
              clearError={clearError}
              existingDatasets={validDatasetsForMerge}
              key={idx}
            />
          );
        })}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={4} p={2} pl={4}>
            <Button
              variant="link"
              leftIcon={<Plus weight="bold" />}
              justifyContent="left"
              width="100%"
              onClick={handleAddDataset}
              analyticsName="add-merge-dataset"
            >
              Add dataset
            </Button>
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
}
