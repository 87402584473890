import { gql } from "@apollo/client";

import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { components } from "../../sojourner-oas-types";
import {
  UpdatePersonaSetMutation,
  UpdatePersonaSetMutationVariables,
} from "./__generated__/UpdatePersonaSetMutation";
import { personaSetFragment } from "./personaSetFragment";

type PersonaSetMergePatch = components["schemas"]["PersonaSetMergePatch"];

export const UPDATE_PERSONA_SET = gql`
  mutation UpdatePersonaSetMutation($id: ID!, $personaSet: String!) {
    updatePersonaSet(
      applicationJsonMergePatchInput: $personaSet
      personaSetId: $id
    ) {
      ...PersonaSetFragment
    }
  }
  ${personaSetFragment}
`;

export function useUpdatePersonaSet(options?: {
  onCompleted?: (data: UpdatePersonaSetMutation) => void;
}) {
  const [mutate, { loading: updating }] = useSojournerMutation<
    UpdatePersonaSetMutation,
    UpdatePersonaSetMutationVariables
  >(UPDATE_PERSONA_SET, {
    onCompleted: options?.onCompleted,
  });

  function updatePersonaSet(id: string, input: PersonaSetMergePatch) {
    return mutate({
      variables: {
        id,
        personaSet: JSON.stringify(input),
      },
    });
  }

  return {
    updatePersonaSet,
    updating,
  };
}
