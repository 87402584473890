import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import {
  Stream,
  StreamPropertyDetails,
  StreamPropertyDistribution,
} from "@fdy/faraday-js";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useDatasetListQuery } from "../../../hooks/api";
import { number } from "../../../utils/formatters";
import { Blankslate } from "../../ui/Blankslate";
import { CardV2 } from "../../ui/Card/CardV2";
import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../../ui/ConfigBreakdownTable";
import { PendingValue } from "../../ui/PendingValue";
import { PopoverInfoPill, PopoverInfoPillPart } from "../../ui/PopoverInfoPill";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { ResourceLink } from "../../ui/ResourceLink";
import { TagList } from "../../ui/TagList";
import {
  PrimitiveDataTypePill,
  traitTypeAliases,
} from "./PrimitiveDataTypePill";

const StreamPropertyDatasetPopoverContent = ({
  stream,
  propertyDetails,
}: {
  stream: Stream;
  propertyDetails: StreamPropertyDetails;
}) => {
  const { data: datasets } = useDatasetListQuery();
  return (
    <ConfigBreakdownTable headers={["Dataset", "Event count"]}>
      {propertyDetails.emitted_by_datasets?.map((emittedByDataset) => {
        const datasetContribution = stream.event_contribution_by_dataset?.find(
          (c) => c.dataset_id === emittedByDataset.dataset_id
        );

        const dataset = datasets?.find(
          (d) => d.id === emittedByDataset.dataset_id
        );

        const datasetName =
          dataset?.name ?? emittedByDataset.dataset_id?.slice(0, 8);

        return (
          <ConfigBreakdownTableRow
            key={emittedByDataset.dataset_id}
            icon={ResourceIcon.datasets}
            header={
              <ResourceLink
                resource={{
                  id: emittedByDataset.dataset_id,
                  resource_type: "datasets",
                  name: datasetName,
                }}
              />
            }
            value={
              <PendingValue
                value={datasetContribution?.event_count}
                formatter={number}
              />
            }
          />
        );
      })}
    </ConfigBreakdownTable>
  );
};

const StreamPropertyDatasetsPill = ({
  stream,
  propertyDetails,
}: {
  stream: Stream;
  propertyDetails: StreamPropertyDetails;
}) => {
  const datasets = propertyDetails.emitted_by_datasets ?? [];
  if (!datasets.length) return null;

  return (
    <PopoverInfoPill
      popover={
        <StreamPropertyDatasetPopoverContent
          stream={stream}
          propertyDetails={propertyDetails}
        />
      }
    >
      <PopoverInfoPillPart
        icon={ResourceIcon.datasets}
        value={datasets.length}
      />
    </PopoverInfoPill>
  );
};

const StreamPropertyTypePill = ({
  propertyDetails,
}: {
  propertyDetails: StreamPropertyDetails;
}) => {
  const showPopover =
    propertyDetails.statistical_type ||
    propertyDetails.unit ||
    propertyDetails.breaks?.length ||
    propertyDetails.categories?.length;

  const popover = (
    <ConfigBreakdownTable>
      {propertyDetails.type && (
        <ConfigBreakdownTableRow
          header="Type"
          value={traitTypeAliases[propertyDetails.type]}
        />
      )}
      {propertyDetails.statistical_type && (
        <ConfigBreakdownTableRow
          header="Statistical type"
          value={propertyDetails.statistical_type}
        />
      )}
      {propertyDetails.unit && (
        <ConfigBreakdownTableRow header="Unit" value={propertyDetails.unit} />
      )}
      {propertyDetails.breaks && propertyDetails.breaks.length > 0 && (
        <ConfigBreakdownTableRow
          header="Breaks"
          value={propertyDetails.breaks.join(", ")}
        />
      )}
      {propertyDetails.categories && propertyDetails.categories.length > 0 && (
        <ConfigBreakdownTableRow
          header="Categories"
          value={<TagList tags={propertyDetails.categories} />}
        />
      )}
    </ConfigBreakdownTable>
  );

  return (
    <PopoverInfoPill popover={showPopover ? popover : undefined}>
      <PrimitiveDataTypePill type={propertyDetails.type} />
    </PopoverInfoPill>
  );
};

const formatRangeLabel = ({ min, max }: { min: number; max?: number }) => {
  if (max === undefined) {
    return `${min}+`;
  }
  return `${min} - ${max}`;
};

const DistributionTable = ({
  data,
}: {
  data: StreamPropertyDistribution[];
}) => {
  return (
    <ConfigBreakdownTable headers={["Range", "Count"]}>
      {data
        .sort((a, b) => b.count - a.count)
        .map((bin) => (
          <ConfigBreakdownTableRow
            key={bin.min}
            header={formatRangeLabel(bin)}
            value={number(bin.count)}
          />
        ))}
    </ConfigBreakdownTable>
  );
};

const StreamPropertyCommonValuesPill = ({
  propertyDetails,
}: {
  propertyDetails: StreamPropertyDetails;
}) => {
  // for categorical properties, render sorted list of them with counts
  if (propertyDetails.values?.length) {
    const mostCommonValue = propertyDetails.values.reduce((a, b) =>
      a.count > b.count ? a : b
    );

    return (
      <PopoverInfoPill
        popover={
          <ConfigBreakdownTable headers={["Value", "Count"]}>
            {propertyDetails.values
              ?.sort((a, b) => b.count - a.count)
              .map((value) => (
                <ConfigBreakdownTableRow
                  key={value.value}
                  header={value.value}
                  value={number(value.count)}
                />
              ))}
          </ConfigBreakdownTable>
        }
      >
        {mostCommonValue.value}
      </PopoverInfoPill>
    );
  }

  // for numeric properties, render distribution histogram chart
  if (propertyDetails.distribution?.length) {
    const mostCommonBin = propertyDetails.distribution.reduce((acc, bin) =>
      bin.count > acc.count ? bin : acc
    );

    return (
      <PopoverInfoPill
        popover={<DistributionTable data={propertyDetails.distribution} />}
      >
        {formatRangeLabel(mostCommonBin)}
      </PopoverInfoPill>
    );
  }

  return null;
};

export const EventStreamPropertiesTable = ({
  stream,
  allColumns,
}: {
  stream: Stream;
  allColumns?: boolean;
}) => {
  const properties = Object.entries(stream.properties ?? {}).sort(([a], [b]) =>
    a.localeCompare(b)
  );

  if (!properties.length)
    return (
      <Blankslate
        title="No properties"
        text="This stream does not have any properties."
        button={{
          routeName: ROUTE_NAMES.DATASETS,
          children: "Add properties via datasets",
        }}
        filled
      />
    );

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Property</Th>
          <Th>Type</Th>
          {allColumns && (
            <>
              <Th>Datasets</Th>
              <Th>Common values</Th>
            </>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {properties.map(([name, details]) => (
          <Tr key={name}>
            <Td
              sx={{
                fontFamily: "monospace",
                bg: "fdy_gray.100",
              }}
            >
              {name}
            </Td>
            <Td>
              <StreamPropertyTypePill propertyDetails={details} />
            </Td>
            {allColumns && (
              <>
                <Td>
                  <StreamPropertyDatasetsPill
                    stream={stream}
                    propertyDetails={details}
                  />
                </Td>
                <Td>
                  <StreamPropertyCommonValuesPill propertyDetails={details} />
                </Td>
              </>
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export const EventStreamPropertiesCard = ({ stream }: { stream: Stream }) => {
  return (
    <CardV2 title="Properties">
      <EventStreamPropertiesTable stream={stream} allColumns />
    </CardV2>
  );
};
