import { Bounds, Field } from "../analysis/types";

export type Dataset = {
  data: Record<string, Record<string, number>> | null;
};

/**
 * Fields breaks can be strings that contain numbers, including dates or infinity values.
 * Normalize the breaks to numbers by:
 * - parsing strings into number
 * - turning dates into unix timestamps
 * - turning infinite values into null
 */
export function getAnalysisBreaks(field: Field): (number | null)[] {
  if (!field.breaks?.length) {
    return [];
  }

  return field.breaks.map((b) => {
    // backwards compat with old fields
    if (field.type?.toLowerCase() === "date") {
      // When using getTime(), converting a date like
      // 2020-01-01 returns milliseconds.
      // Unix time is in seconds so divide by 1000.
      return new Date(b).getTime() / 1000;
    }

    const num = Number(b);

    // Sometimes a break can be Infinity or -Infinity.
    // Return null for this case which is somewhat easier to deal with for charting.
    // This could be less than ideal and charts should
    // instead know how to handle infinite values...
    if (!isFinite(num)) {
      return null;
    }

    return num;
  });
}

/**
 * If an upper bound exceeds the max `field.breaks` value, use the break instead.
 */
function capBoundRange(field: Field, bounds: Bounds): Bounds {
  const breaks = getAnalysisBreaks(field).filter((b) => b !== null);

  const lowBreak = breaks[0];
  const highBreak = breaks[breaks.length - 1];

  const [min, max] = bounds;

  return [Math.max(min, lowBreak ?? min), Math.min(max, highBreak ?? max)];
}

/**
 * Find the highest `max` and lowest `min` across all segments
 * based on the currently viewed field. This is so range/boxplot charts
 * can start and end at the same coordinates across multiple segments.
 */
export function getBoundsForAnalysis(
  segmentDatasets: Dataset[],
  field: Field | null
): Bounds {
  if (segmentDatasets.length === 0 || !field) return [0, 0];

  const bounds: Bounds = segmentDatasets.reduce(
    ([min, max], seg) => {
      const data = seg.data?.[field?.name];

      // some segments may have failed to calc data for this field
      if (data?.min === undefined || data.max === undefined) return [min, max];

      return [Math.min(min, data.min), Math.max(max, data.max)];
    },
    [Infinity, -Infinity]
  );

  return capBoundRange(field, bounds);
}
