import { Box, List, ListItem } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

import { Button } from "../Button";
import { ConditionsBuilderRow } from "./ConditionsBuilderRow";
import { NUMBER_OPERATORS } from "./operators";
import { ConditionRow, ConditionsBuilderProps } from "./types";
import {
  validateConditionsHaveOperators,
  validateOperatorUniqueness,
} from "./utils";

/**
 * Renders a widget to build up a 'condition list' (useful for cohort trait inputs).
 *
 * - Each row is a dropdown/select of which operators to use, then an input is rendered based on that operator.
 * - Users can add up to one of each operator.
 * - Users can remove rows/conditions.
 */
export function ConditionsBuilder({
  initialConditions,
  onChange,
  operators = NUMBER_OPERATORS,
  largeCsvInput,
  lockedRows,
  unit,
  virtualized,
}: ConditionsBuilderProps) {
  // usually it's a bit of an anti pattern to store something in state that can be derived from props
  // but we mostly care about the order of the item. The user expects new items
  // to be added to the bottom. If we always derive the state, the upper 'fromWire' utils
  // may not be able to guarantee the order of the conditions.
  const [conditions, setConditions] = useState<ConditionRow[]>(
    () => initialConditions ?? []
  );

  // emit changes to the parent
  useEffect(() => {
    onChange(conditions);
  }, [conditions]);

  validateOperatorUniqueness(operators);
  validateConditionsHaveOperators(operators, conditions);

  // Track if we have not applied every operator yet.
  // e.g. users can't add two `gte` conditions.
  const unusedOperator = operators.find(
    (opt) => !conditions.find((c) => c.operator === opt.operator)
  );

  const hasEmptyOperator = conditions.some((c) => c.operator === null);

  const showAddBtn = lockedRows !== true && unusedOperator && !hasEmptyOperator;

  function handleAddCondition() {
    setConditions([
      ...conditions,
      {
        operator: null,
        type: null,
        value: null,
      },
    ]);
  }

  return (
    <Box>
      <List display="grid" gap={4} mb={2}>
        {conditions.map((cond, i) => {
          return (
            <ListItem key={i}>
              <ConditionsBuilderRow
                operators={operators}
                condition={cond}
                conditions={conditions}
                onChange={setConditions}
                largeCsvInput={largeCsvInput}
                locked={lockedRows}
                unit={unit}
                virtualized={virtualized}
              />
            </ListItem>
          );
        })}
      </List>

      {showAddBtn && (
        <Button
          type="button"
          leftIcon={<Plus weight="bold" />}
          variant="icon"
          px={0}
          color="primary"
          size="sm"
          onClick={handleAddCondition}
          analyticsName="add-another-condition"
        >
          Add another condition
        </Button>
      )}
    </Box>
  );
}
