import { ApolloError } from "@apollo/client";
import { ErrorCode } from "@fdy/faraday-js";
import { GraphQLError } from "graphql";
import Rollbar from "rollbar";

import { env } from "../../../env";
import {
  FdyClientError,
  isFdyJsModelError,
} from "../../../services/FdyClientProvider";

interface RollbarLoggerProps {
  error: Error | FdyClientError | ApolloError | GraphQLError;
  errorInfo?: React.ErrorInfo;
  rollbar: Rollbar;
  level?: Rollbar.Level;
}

export function parseGraphqlErrors(error: Error): {
  graphQLErrors: readonly GraphQLError[];
  apiErrorId: string | undefined;
  notFoundError: boolean;
} {
  if (error instanceof FdyClientError) {
    return {
      graphQLErrors: [],
      apiErrorId: error.id,
      notFoundError: error.statusCode === 404,
    };
  }

  const graphQLErrors =
    error instanceof ApolloError && error.graphQLErrors.length
      ? error.graphQLErrors
      : error instanceof GraphQLError
      ? [error]
      : [];
  let apiErrorId: string | undefined;
  if (graphQLErrors.length) {
    const gqlErr = graphQLErrors[0];

    if (gqlErr) {
      apiErrorId = gqlErr.extensions?.id;
    }
  }

  const notFoundError = graphQLErrors.some(
    (e) => e.extensions?.error === "NOT_FOUND"
  );

  return { graphQLErrors, apiErrorId, notFoundError };
}

/**
 * Returns true if the error has a graphql error with a certain HTTP status code
 * that would be returned from the REST endpoints.
 */
export function errorHasStatusCode(error: Error, statusCode: number): boolean {
  if (error instanceof FdyClientError) {
    return error.statusCode === statusCode;
  }
  const { graphQLErrors } = parseGraphqlErrors(error);
  return graphQLErrors.some((e) => e.extensions?.statusCode === statusCode);
}

/**
 * Logs an error to rollbar with lots of metadata. Returns the rollbar errorId and graphql apiErrorId.
 *
 * Error will be `critical` if certain conditions are met, thus notifying tier3_platform in slack.
 *
 * If `onNotFound` is provided, it will be called and rollbar will be skipped if the error is a 404.
 */
export function logErrorToRollbar({
  error,
  errorInfo,
  rollbar,
  level,
}: RollbarLoggerProps): {
  errorId: string | undefined;
  apiErrorId: string | undefined;
  notFoundError?: boolean;
} {
  const { graphQLErrors, apiErrorId, notFoundError } =
    parseGraphqlErrors(error);

  const errorMetadata = {
    ...errorInfo,
    errorType: Object.prototype.toString.call(error),
    errorTypeName: Object.getPrototypeOf(error).constructor.name,
    errorName: error.name,
    appEnv: env.ENVIRONMENT,
    apiErrorId,
    graphQLExtensions: graphQLErrors.map((e) => e.extensions),
  };

  // Extract person.email out of rollbar.options.payload into a const:
  const payload = rollbar.options?.payload ?? {};
  let email: string | undefined;
  if (Object.prototype.hasOwnProperty.call(payload, "person")) {
    email = (payload as { person: { email?: string } }).person.email;
  }

  let rollbarLogResult: Rollbar.LogResult | undefined;
  console.info("Logging error to rollbar: ", error.message);
  if (
    (!level || level === "critical") &&
    errorMetadata.appEnv === "production" &&
    email &&
    !email.includes("@faraday")
  ) {
    // log unexpected, unrecoverable errors to clients at CRITICAL level:
    rollbarLogResult = rollbar.critical(error.toString(), error, errorMetadata);
  } else if (!level || level === "error") {
    rollbarLogResult = rollbar.error(error.toString(), error, errorMetadata);
  } else {
    rollbarLogResult = rollbar.warn(error.toString(), error, errorMetadata);
  }

  return {
    errorId: rollbarLogResult?.uuid,
    apiErrorId,
    notFoundError,
  };
}

function getToastMessageFromFdyClientError(
  error: FdyClientError
): string | undefined {
  // if we get any validation errors that users can't fix, crash instead.
  if (
    error.validationErrors?.some((err) =>
      err.message.includes("property is not expected to be here")
    )
  ) {
    return;
  }

  if (error.name === ErrorCode.ValidationFailed) {
    const messages = error.validationErrors?.map((err) => err.message);
    const allMessages = messages?.length ? ": " + messages.join(", ") : "";
    return `${error.message}${allMessages}`;
  }

  if (error.name === ErrorCode.MaxResourcesReached) {
    return `${error.message}. Please delete some resources or contact support.`;
  }

  if (error.name === ErrorCode.Conflict) {
    return error.message;
  }
}

function getToastMessageFromGraphQLError(
  error: ApolloError
): string | undefined {
  // non-graphql errors don't have meaningful messages for users
  if (!error.graphQLErrors.length) {
    return;
  }

  const firstError = error.graphQLErrors[0];
  const extensions = firstError.extensions;
  const statusCode = extensions?.statusCode;
  if (!isFdyJsModelError(extensions)) return;

  // turn the error into a FdyClientError so we can use the same fn as above
  const fdyError = new FdyClientError(statusCode, extensions);
  return getToastMessageFromFdyClientError(fdyError);
}

export function getToastMessageFromError(
  error: Error | ApolloError | FdyClientError
): string | undefined {
  if (error instanceof FdyClientError) {
    return getToastMessageFromFdyClientError(error);
  } else if (error instanceof ApolloError) {
    return getToastMessageFromGraphQLError(error);
  }
}
