import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useSojournerQuery } from "../../../../services/sojournerApolloClient";
import { Pagination } from "../../../ui/Pagination-v2/Pagination";
import { TableBlankslate, TableLoading } from "../../../ui/TableV2/parts";
import { DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv } from "../../__generated__/DatasetShowPageQuery";
import { UploadState } from "../../shared/DatasetUploadDropZone";
import {
  UploadedFileQuery,
  UploadedFileQuery_uploads,
} from "./__generated__/UploadedFileQuery";
import { CsvActionsMenu } from "./DatasetsDataCsvFilesActionMenu";
import { uploadedFileQuery } from "./DatasetsDataHostedCsv";

const PAGE_SIZE = 10;

/** convert bytes into human readable kb, mb, and gb */
function formatFileSize(bytes: number) {
  if (bytes < 1024) {
    return `${bytes} bytes`;
  }

  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)}kb`;
  }

  if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / 1024 / 1024).toFixed(2)}mb`;
  }

  return `${(bytes / 1024 / 1024 / 1024).toFixed(2)}gb`;
}

function TableFileRows({
  loading,
  uploads,
  uploadDirectory,
  page,
  onFileDelete,
}: {
  loading: boolean;
  uploads: UploadedFileQuery_uploads[] | undefined;
  uploadDirectory: string;
  page: number;
  onFileDelete: () => void;
}) {
  if (loading) return <TableLoading />;

  const files = uploads?.filter((file) =>
    file.subpath.startsWith(uploadDirectory + "/")
  );

  if (!files || !files.length) {
    return (
      <TableBlankslate
        title="No files found"
        text="Get started by uploading a file above"
      />
    );
  }

  return (
    <>
      {files
        .sort((a, b) => {
          if (a.createdAt < b.createdAt) return 1;
          if (a.createdAt > b.createdAt) return -1;
          return 0;
        })
        .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
        .map((file, idx) => (
          <Tr key={idx}>
            <Td>{new Date(file.createdAt).toLocaleDateString()}</Td>
            <Td>{file.subpath}</Td>
            <Td>{formatFileSize(file.fileSize)}</Td>
            <Td textAlign="right">
              <CsvActionsMenu
                filePath={file.subpath}
                onFileDelete={onFileDelete}
              />
            </Td>
          </Tr>
        ))}
    </>
  );
}

/**
 * Renders a table of uploaded CSV files.
 * Only usable for hosted/uploaded csv datasets.
 */
export function DatasetsDataCsvFilesTable({
  options,
  uploadState,
}: {
  options: DatasetShowPageQuery_dataset_options_DatasetOptionsHostedCsv;
  uploadState: UploadState;
}) {
  const [page, setPage] = useState<number>(1);
  const { data, loading, error, refetch } =
    useSojournerQuery<UploadedFileQuery>(uploadedFileQuery);

  // update the files table if a new file was uploaded (ie the uploadState changed)
  useEffect(() => {
    if (uploadState === UploadState.done) {
      refetch();
    }
  }, [uploadState]);

  if (error) throw error;

  return (
    <>
      <Table mb={4}>
        <Thead>
          <Tr>
            <Th>Upload date</Th>
            <Th>File name</Th>
            <Th>File size</Th>
            <Th>
              <VisuallyHidden>Actions</VisuallyHidden>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <TableFileRows
            loading={loading}
            uploads={data?.uploads}
            uploadDirectory={options.uploadDirectory}
            page={page}
            onFileDelete={refetch}
          />
        </Tbody>
      </Table>
      <Pagination
        disabled={loading}
        total={
          data?.uploads
            ? data?.uploads.filter((file) =>
                file.subpath.startsWith(options.uploadDirectory + "/")
              ).length
            : 0
        }
        pageSize={PAGE_SIZE}
        current={page}
        onChange={(newPage) => {
          setPage(newPage);
        }}
      />
    </>
  );
}
