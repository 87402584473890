import { Text } from "@chakra-ui/react";
import { ChangeEvent, FormEvent, useId, useState } from "react";

import { Button } from "../../../../ui/Button";
import { FormField } from "../../../../ui/FormField";
import { FormFieldStack } from "../../../../ui/FormFieldStack";
import { InlineButtons } from "../../../../ui/InlineButtons";
import { Input } from "../../../../ui/Input";
import { ModalV2 } from "../../../../ui/ModalV2";
import { DatasetColumnSelect } from "../events/DatasetColumnSelect";
import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";

const namePatternHelpText =
  "Name must only contain lowercase letters, numbers, and underscores. The first character must be a letter";

export type TraitState = {
  name: string;
  column_name: string;
};

type TraitErrors = {
  name?: string;
  field?: string;
};

const traitNamePattern = /^[a-z][a-z0-9_]+$/;

/**
 * Renders a form in a modal for creating or editing a trait that is to be emitted from a dataset.
 */
export function DatasetsTraitsModal({
  onClose,
  onCancel,
  initialState,
  onFinish,
  detectedColumns,
  managedDataset,
  sampleData,
}: {
  initialState?: TraitState;
  detectedColumns: DetectedColumn[];
  managedDataset: boolean;
  sampleData?: SampleData;
  onClose: () => void;
  onCancel: () => void;
  onFinish: (trait: TraitState) => void;
}) {
  const formId = useId();

  const [trait, setTrait] = useState<TraitState>(
    initialState ?? {
      name: "",
      column_name: "",
    }
  );

  const [errors, setErrors] = useState<TraitErrors>();

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const errors: TraitErrors = {};

    const trimmedName = trait.name.trim();
    if (!trimmedName) {
      errors.name = "Traits must have a name";
    } else if (!trimmedName.match(traitNamePattern)) {
      errors.name = namePatternHelpText;
    }

    if (!trait.column_name) {
      errors.field =
        "Please choose a column from your dataset to represent this trait.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    onFinish({ name: trimmedName, column_name: trait.column_name });
  }

  const handleColumnChange = (col: string | null) => {
    setTrait((t) => {
      return {
        column_name: col ?? "",
        // set the name to the column name if it's not already set
        name: t.name ? t.name : col ?? "",
      };
    });
    setErrors((errs) => ({ ...errs, field: undefined }));
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTrait((t) => ({ ...t, name: e.target.value }));
    setErrors((errs) => ({ ...errs, name: undefined }));
  };

  return (
    <ModalV2
      isOpen
      onClose={onClose}
      title={trait.name ? `${trait.name} trait` : "Add trait"}
      footer={
        <InlineButtons>
          <Button variant="tertiary" onClick={onCancel} analyticsName="cancel">
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={managedDataset}
            analyticsName="finish"
          >
            Finish
          </Button>
        </InlineButtons>
      }
      analyticsStackName="trait-modal"
    >
      <Text fontSize="fdy_lg" mb={4}>
        Traits are additional pieces of data that may be used for prediction but
        are not in the form of events.
      </Text>

      <form onSubmit={handleSubmit} id={formId}>
        <FormFieldStack>
          <FormField
            label="Choose a field in the dataset"
            error={errors?.field}
            helpText="What field in your dataset represents this trait? Note that traits must have less than 700 unique values across the whole dataset in order to be useful for prediction. An error will be raised if a field is chosen that has more than 700 unique values."
          >
            <DatasetColumnSelect
              value={trait.column_name}
              onChange={handleColumnChange}
              analyticsName="trait-column-select"
              disabled={managedDataset}
              sampleData={sampleData}
              detectedColumns={detectedColumns}
              required
            />
          </FormField>

          <FormField
            label="Trait name"
            error={errors?.name}
            helpText={namePatternHelpText}
          >
            <Input
              name="name"
              value={trait.name}
              onChange={handleNameChange}
              disabled={managedDataset}
              analyticsName="trait-name"
              pattern={traitNamePattern.source}
              required
            />
          </FormField>
        </FormFieldStack>
      </form>
    </ModalV2>
  );
}
