import { Box } from "@chakra-ui/react";
import * as React from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { Breadcrumb, PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { SettingsNav } from "./SettingsNav";

export function SettingsLayout({
  title,
  children,
  lastCrumb,
}: {
  title: string;
  children: React.ReactNode;
  lastCrumb?: Breadcrumb;
}) {
  const crumbs = [{ label: "Settings", routeName: ROUTE_NAMES.SETTINGS }];
  if (lastCrumb) crumbs.push(lastCrumb);

  return (
    <PageLayout analyticsStackName="settings">
      <PageHeader title={title} crumbs={crumbs} />
      <Box
        sx={{
          display: "flex",
          gap: 8,
          maxWidth: 1440,
          py: 8,
          px: 12,
        }}
      >
        <SettingsNav />
        <Box flex="1">{children}</Box>
      </Box>
    </PageLayout>
  );
}
