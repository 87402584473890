/**
 * Keeping this file as Javascript because it does some real nasty DOM stuff
 * that TypeScript doesn't like at all.
 */

export const GoogleTagManager = {
  name: "GoogleTagManager",
  attach() {
    return new Promise((resolve, reject) => {
      // Google tag manager snippet
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js", // eslint-disable-line
        });
        var f = d.getElementsByTagName(s)[0], // eslint-disable-line
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : ""; // eslint-disable-line
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;

        // ADDED: so we know when the snippet has loaded
        j.onload = resolve;
        j.onerror = reject;

        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", env.GTM_KEY);
    });
  },
  trackEvent({ event, ...metadata }) {
    if (window.ga)
      window.ga("send", "event", metadata.category || "all", event);
  },
  trackPage({ route }) {
    if (window.ga) window.ga("send", "pageView", route);
  },
};

export const Heap = {
  name: "Heap",
  attach() {
    return new Promise((resolve, reject) => {
      // Heap Snippet
      (window.heap = window.heap || []),
        (window.heap.load = function (e, t) {
          (window.heap.appid = e), (window.heap.config = t = t || {});
          var r = document.createElement("script");
          (r.type = "text/javascript"),
            (r.async = !0),
            (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js");

          r.onload = resolve;
          r.onerror = reject;

          var a = document.getElementsByTagName("script")[0];
          a.parentNode.insertBefore(r, a);
          for (
            var n = function (e) {
                return function () {
                  window.heap.push(
                    [e].concat(Array.prototype.slice.call(arguments, 0))
                  );
                };
              },
              p = [
                "addEventProperties",
                "addUserProperties",
                "clearEventProperties",
                "identify",
                "resetIdentity",
                "removeEventProperty",
                "setEventProperties",
                "track",
                "unsetEventProperty",
              ],
              o = 0;
            o < p.length;
            o++
          )
            window.heap[p[o]] = n(p[o]);
        });
      window.heap.load(env.HEAP_KEY);
    });
  },
  configure({ user, account }) {
    if (!window.heap) return;
    window.heap.identify(user.id);
    window.heap.addUserProperties({
      name: user.name,
      account: account.name,
      hubspot_company_id: account.hubspot_company_id,
      email: user.email,
    });
  },
  trackEvent({ event, ...metadata }) {
    if (!window.heap) return;
    window.heap.track(event, metadata);
  },
  trackPage({ route }) {
    if (!window.heap) return;

    // Heap tracks URL changes automatically, but doesn't know how to aggregate
    // them the way we do, so send an additional event so we can create generic
    // pipelines around thoes route changes.
    window.heap.track("routeChange", route);
  },
};

export const Hubspot = {
  name: "Hubspot",
  attach() {
    return new Promise((resolve, reject) => {
      var w = window;
      var d = document;

      function l() {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.id = "hs-script-loader";
        s.src = `https://js-na1.hs-scripts.com/6194453.js`;
        var x = d.getElementsByTagName("script")[0];

        // ADDED: so we know when the snippet has loaded
        s.onload = resolve;
        s.onerror = reject;

        x.parentNode.insertBefore(s, x);
      }
      w._hsq = w._hsq || [];
      w.hsConversationsSettings = {
        // loadImmediately: false,
        // inlineEmbedSelector: '#some-id',
      };
      if (document.readyState === "complete") l();
      else w.addEventListener("load", l, false);
    });
  },
  configure({ user }) {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      "identify",
      {
        email: user.email,
      },
    ]);
  },
  trackEvent({ event, ...metadata }) {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      "trackCustomBehavioralEvent",
      {
        name: event,
        properties: metadata,
      },
    ]);
  },
  trackPage({ path }) {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", path]);
    _hsq.push(["trackPageView"]);
  },
};

export const DevelopmentTracker = {
  name: "DevelopmentTracker",
  trackEvent: console.log,
  trackPage: console.log,
};
