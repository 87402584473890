import { Flex, Tag, Tooltip } from "@chakra-ui/react";

import { truncateText } from "./utils";

/** Renders a list of tags, populated with the provided strings. Used in the
 * trait and event property summaries of filters applied to cohorts.
 */
export function CohortSummaryTagsSection({ tags }: { tags: string[] }) {
  const maxTagTextLength = 80;
  return (
    <Flex gap={2} flexWrap="wrap">
      {tags.map((s) => (
        <Tooltip key={s} label={s.length > maxTagTextLength ? s : undefined}>
          <Tag>{truncateText(s, maxTagTextLength)}</Tag>
        </Tooltip>
      ))}
    </Flex>
  );
}
