import { Box, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { Fragment } from "react";

import {
  analyticsAttrs,
  useAnalyticsKeyGenerator,
} from "../ui/Analytics/AnalyticsStack";

const divider = (
  <Box
    aria-hidden
    sx={{
      position: "relative",
      width: "100%",
      marginInline: "-10%",
      top: 3,
      height: "2px",
      background: "fdy_gray.300",
    }}
  />
);

export interface DividedRadioGroupOption<Value extends string> {
  label: string;
  helpText: string;
  value: Value;
  analyticsName?: string;
}

export function DividedRadioGroup<Value extends string>({
  value,
  onChange,
  name,
  options,
  disabled,
  analyticsName,
}: {
  value: Value;
  name: string;
  onChange: (value: Value) => void;
  options: DividedRadioGroupOption<Value>[];
  disabled?: boolean;
  analyticsName?: string;
}) {
  const getAnalyticsKey = useAnalyticsKeyGenerator(analyticsName);
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      isDisabled={disabled}
      sx={{
        display: "flex",
        gap: 4,
        maxWidth: 400,
      }}
    >
      {options.map((opt, i) => {
        const last = options.length - 1 === i;
        return (
          <Fragment key={opt.value}>
            {/* TODO: can we set the styles needed in the variant right here instead? */}
            <Radio
              variant="stacked"
              name={name}
              value={opt.value}
              {...analyticsAttrs(getAnalyticsKey(opt.analyticsName))}
            >
              <strong>{opt.label}</strong>
              <Text
                as="small"
                sx={{
                  fontSize: "fdy_sm",
                  display: "block",
                  whiteSpace: "nowrap",
                  width: "100px",
                }}
              >
                {opt.helpText}
              </Text>
            </Radio>
            {!last && divider}
          </Fragment>
        );
      })}
    </RadioGroup>
  );
}
