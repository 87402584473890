import { useMemo } from "react";

import {
  DirectionalityChart,
  DirectionalityChartDatum,
} from "../../../ui/charts-v2/DirectionalityChart";
import { OutcomeAnalysisFeature_directionality } from "../__generated__/OutcomeAnalysisFeature";

interface OutcomeFeatureDirectionalityProps {
  eligibleCohortName: string | null;
  featureName: string;
  outcomeName: string;
  directionality: (OutcomeAnalysisFeature_directionality | null)[];
}

function prepareDirectionalityData(
  directionality: (OutcomeAnalysisFeature_directionality | null)[],
  groupLabel: string
): DirectionalityChartDatum[] {
  const result: DirectionalityChartDatum[] = [];

  for (const d of directionality) {
    if (!d) continue;

    switch (d.__typename) {
      case "AnalysisFeatureDirectionalityBoolean": {
        result.push({
          x: String(d.value),
          y: d.impact,
          groupPercent: d.density,
          groupLabel,
        });
        break;
      }

      case "AnalysisFeatureDirectionalityText": {
        result.push({
          x: d.text,
          y: d.impact,
          groupPercent: d.density,
          groupLabel,
        });
        break;
      }

      case "AnalysisFeatureDirectionalityNumber": {
        result.push({
          x: `(${String(d.min)}-${String(d.max)}]`,
          y: d.impact,
          groupPercent: d.density,
          groupLabel,
        });
        break;
      }

      case "AnalysisFeatureDirectionalityDate": {
        result.push({
          x: `(${String(d.dateMin)}-${String(d.dateMax)}]`,
          y: d.impact,
          groupPercent: d.density,
          groupLabel,
        });
        break;
      }

      default:
        throw new Error("Unknown directionality type");
    }
  }

  return result;
}

export function OutcomeFeatureDirectionalityChart({
  featureName,
  directionality,
  eligibleCohortName,
  outcomeName,
}: OutcomeFeatureDirectionalityProps) {
  const groupLabel = eligibleCohortName
    ? `members of ${eligibleCohortName}`
    : `anyone in Faraday's identity graph`;

  const data = useMemo(
    () => prepareDirectionalityData(directionality, groupLabel),
    [directionality, groupLabel]
  );

  const title = (
    <>
      Impact of {featureName.toLowerCase()} on <strong>{outcomeName}</strong>{" "}
      likelihood
    </>
  );

  return <DirectionalityChart title={title} data={data} xLabel={featureName} />;
}
