import { memoize } from "lodash";
import { Middleware, Router as Router5Router, State } from "router5";
import { Route as Router5Route } from "router5/dist/types/router";

import { Analytics } from "../../../services/analytics/Analytics";
import { ApolloGQLService } from "../../../services/ApolloGQLService";
import { AuthService } from "../../../services/authService";
import { assert } from "../../../utils/assertions";

/**
 * Arguments to a redirect function handled by `redirectMiddleware`.
 */
export type RedirectArguments = Dependencies & {
  router: Router5Router;
  route: State;
};

/**
 * A Popsicle route.
 *
 * This is like a Route5 route, except that it can also specify a custom
 * redirect function which is handled by our `redirectMiddleware`.
 */
export type Route = Router5Route & {
  /** Override `children` to also allow our custom `Route` extensions. */
  children?: Route[];
  /** We support `redirect` handlers. */
  redirect?: (args: RedirectArguments) => State | Promise<State>;
};

/** Services known to the router. */
export type Dependencies = {
  auth: AuthService;
  gqlClient: ApolloGQLService;
  analytics: Analytics;
};

/**
 * Look up a route stack by name, recursively.
 *
 * @returns All routes in the path from the top level to the found route, in
 * that order. This will always contain at least one route.
 */
const getRouteStack = memoize(function (
  name: string,
  routes: Route[]
): Route[] {
  // Either a `Route`, or an initial dummy value containing just a `children`
  // field.
  type RouteOrDummy =
    | Route
    | {
        children: Route[];
      };

  // Recursively look up each segment.
  const segments = name.split(".");
  const stack: Route[] = [];
  let current: RouteOrDummy = { children: routes };
  for (const segment of segments) {
    // Make sure we have `children`.
    if (typeof current.children === "undefined") {
      throw new Error(`cannot find child route for ${segment} in ${segments}`);
    }

    // Look for `segment` in children.
    const found: Route | undefined = current.children.find(
      (child) => child.name === segment
    );
    if (typeof found === "undefined") {
      throw new Error(`cannot find child route for ${segment} in ${segments}`);
    }
    stack.push(found);
    current = found;
  }

  assert(
    stack.length > 0,
    "route stack should always contain at least one route"
  );
  return stack;
});

/**
 * Look up a route by name, recursively.
 *
 * @returns The route we found.
 */
function getRoute(name: string, routes: Route[]): Route {
  const stack = getRouteStack(name, routes);
  return stack[stack.length - 1];
}

export function redirectMiddleware(
  routes: Route[]
): (router: Router5Router) => Middleware {
  return (router) => (toState, fromState, done) => {
    const route = getRoute(toState.name, routes);

    if (route && route.redirect) {
      // If the redirect exists, call it with the store state and router state
      const deps = router.getDependencies() as Dependencies;
      Promise.resolve(route.redirect({ router, route: toState, ...deps })).then(
        (redirect) => {
          // No redirect loops
          if (redirect && !router.areStatesEqual(redirect, toState))
            done({ redirect });
          else done(null);
        }
      );
    } else {
      done(null);
    }
  };
}

/**
 * Check whether the user is allowed to access a specific route. If they aren't,
 * redirect them.
 */
export function redirectIfUnauthorizedMiddleware(
  _routes: Route[]
): (router: Router5Router) => Middleware {
  return (router) => (toState, fromState, done) => {
    const deps = router.getDependencies() as Dependencies;
    // We need to be careful that `auth` doesn't create a redirect loop.
    deps.auth
      .redirectionIfRouteIsUnauthorized(deps.gqlClient, toState.name)
      .then((redirectName) => {
        // If we have a redirect and it's not an obvious loop, follow it.
        if (redirectName !== null && redirectName !== toState.name) {
          done({ redirect: { name: redirectName } });
        } else {
          done(null);
        }
      })
      .catch((err) => done(err));
  };
}
