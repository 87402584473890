import { ReactNode, useEffect, useRef } from "react";

/**
 * Auto scroll to the bottom of a list when new items are added.
 */
export function AutoScrollToNewBottomItems({
  items,
  children,
}: {
  items: unknown[];
  children: ReactNode;
}) {
  const endOfList = useRef<HTMLDivElement>(null);
  const prevItemsLength = useRef(items.length);

  useEffect(() => {
    if (items.length > prevItemsLength.current) {
      endOfList.current?.scrollIntoView({ behavior: "smooth" });
    }
    prevItemsLength.current = items.length;
  }, [items]);

  return (
    <>
      {children}
      <div aria-hidden ref={endOfList} />
    </>
  );
}
