import { useQuery } from "@apollo/client";
import { ReactElement } from "react";

import { LoadingSplash } from "./components/root/LoadingSplash";
import { rootQuery } from "./components/root/OldRoot";

/**
 * The only purpose of this component is to request gql for the first
 * time, before any other parallel queries are made. So it must live as close to the top
 * of the component tree as possible.
 *
 * We do this because any parallel queries after a first time sign up
 * could cause a race between queries to create the user and account, resuling in a 400 error
 * due to the user already existing because of the other query creating it by the time the
 * second query attempting to.
 *
 * This is a hacky solution, but it's a lean amount of code that doesn't require messing with
 * the auth layer within kopeng.
 *
 * Other options:
 * - properly upsert the user/account without throwing an error
 *   (there are several pieces to get right so it may be a riskier change)
 * - remove the parallel queries that happen on first app boot (could be reintroduced again later)
 */
export function UserEnsurer({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  const { loading } = useQuery(rootQuery);

  if (loading) {
    return <LoadingSplash />;
  }

  return children;
}
