import { gql } from "@apollo/client";
import { FormEvent, useMemo, useState } from "react";

import {
  CohortPlaceConditionInput,
  CohortStreamConditionInput,
  CohortTraitInput,
  RecencyOccurrence,
  TraitPermission,
} from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useTraitsQuery } from "../../../hooks/useTraitsQuery";
import { useUnsavedChangesWarning } from "../../../hooks/useUnsavedChangesWarning";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { Button } from "../../ui/Button";
import { CardV2 } from "../../ui/Card/CardV2";
import { CardStack } from "../../ui/CardStack";
import { RouterLink } from "../../ui/RouterLink";
import { CohortEventsCard } from "./CohortEventsCard";
import { CohortLockedCard } from "./CohortLockedCard";
import { CohortNameCard } from "./CohortNameCard";
import { CohortPlaceConditionsCard } from "./CohortPlaceConditionsCard";
import { CohortTraitsCard } from "./CohortTraitsCard";

export const COHORT_FORM_DATASETS_QUERY = gql`
  query CohortFormDatasetsQuery {
    datasets {
      id
      outputToStreams
    }
  }
`;

export type EventState = {
  streamName: string | null;
  occurrence: RecencyOccurrence | undefined;
  streamConditions: CohortStreamConditionInput[];
  minDays: number | null;
  maxDays: number | null;
  minCount: number | null;
  maxCount: number | null;
  minValue: number | null;
  maxValue: number | null;
};

export type TraitCondition = CohortTraitInput;
export type TraitConditionState = TraitCondition[] | undefined;

export interface CohortsFormState {
  name: string;
  event: EventState | undefined;
  traits: TraitConditionState;
  placeConditions: CohortPlaceConditionInput[] | undefined;
}

interface CohortFormProps {
  onSave: (state: CohortsFormState) => void;
  saving: boolean;
  initialState?: CohortsFormState;
  locked?: boolean;
  renderAboveSubmitButton?: React.ReactNode;
}

const defaultState: CohortsFormState = {
  name: "",
  event: undefined,
  traits: undefined,
  placeConditions: [],
};

export const cohortFormIds = {
  name: "name",
  events: "events",
  traits: "traits",
};

type CohortFormErrors = Partial<typeof cohortFormIds>;

function validateCohort(state: CohortsFormState) {
  const errors: CohortFormErrors = {};

  const name = state.name.trim();

  if (!name) {
    errors.name = "Cohort name is required";
  }

  if (name.length > 64) {
    errors.name = "Name must be 64 characters or less";
  }

  // ensure either the event is set or traits has a length
  if (
    state.event === undefined &&
    (state.traits === undefined || state.traits.length === 0)
  ) {
    errors.events = "An event or at least one trait is required";
    errors.traits = "At least one trait or an event is required";
  }

  if (Object.keys(errors).length === 0) {
    return undefined;
  }

  return errors;
}

/**
 * Renders a form for creating or updating a cohort.
 *
 * Starts with an intro of what a cohort is and how to create one.
 * Then, the user can add an event, traits, or both.
 * Finally the user can name the cohort and save it.
 */
export function CohortForm({
  saving,
  onSave,
  initialState = defaultState,
  locked,
  renderAboveSubmitButton,
}: CohortFormProps) {
  const [errors, setErrors] = useState<CohortFormErrors>({});
  const { traits: traitsData, loadingTraits } = useTraitsQuery();

  const availableTraits = useMemo(() => {
    return traitsData.filter(
      (trait) =>
        trait.permissions.includes(TraitPermission.DEFINE_COHORT) &&
        // remove deprecated traits from the result, unless the cohort is already using them
        (!trait.deprecated ||
          initialState.traits?.find((inUse) => inUse.name === trait.name))
    );
  }, [traitsData]);

  // we store traits and event again since we want to control when they show on the page
  const [event, setEvent] = useState<EventState | undefined>(
    () => initialState.event
  );

  // store the list of traits conditions for the cohort
  const [traits, setTraits] = useState<TraitConditionState>(
    () => initialState.traits
  );

  // store cohort name state
  const [name, setName] = useState(initialState.name ?? "");

  function resetError(name: keyof CohortFormErrors) {
    setErrors((prev) => {
      const next = { ...prev };
      delete next[name];
      return next;
    });
  }

  //user warning message for unsaved changes
  const { setWarnBeforeNavigate } = useUnsavedChangesWarning({
    config: [name, event, traits],
    route: ROUTE_NAMES.COHORTS,
  });

  function handleSave(e: FormEvent) {
    e.preventDefault();

    const state: CohortsFormState = {
      name,
      event,
      traits,
      // We don't bother adding place conditions to the form state since they are not editable yet.
      placeConditions: initialState.placeConditions,
    };

    const errs = validateCohort(state);

    if (errs) {
      setErrors(errs);
    } else {
      onSave(state);
      setWarnBeforeNavigate(false);
    }
  }

  if (loadingTraits) {
    return <AnimatedZapLogo />;
  }

  const infoCard = (
    <CardV2
      title="Cohort definition"
      text={
        <>
          Cohorts are specific groups of people that are important to your
          business, such as customers and leads, and are the building blocks of
          your predictions. You can specify how individuals can become members
          of this cohort by choosing an{" "}
          <RouterLink routeName={ROUTE_NAMES.EVENTS}>event</RouterLink> they
          must have previously experienced,{" "}
          <RouterLink routeName={ROUTE_NAMES.TRAITS}>traits</RouterLink> they
          must currently exhibit, or a combination of both.
        </>
      }
    />
  );

  const fields = (
    <>
      <CardV2>
        <CohortEventsCard
          event={event}
          onEventChange={(event) => {
            setEvent(event);
            // a little funky, but our primary validation for events and traits at this component level is
            // checking if at least one of either exists, so clear them both if either changes.
            resetError("events");
            resetError("traits");
          }}
          error={errors?.events}
        />
      </CardV2>

      <CardV2>
        <CohortTraitsCard
          availableTraits={availableTraits}
          traits={traits}
          onTraitsChange={(traits) => {
            setTraits(traits);
            resetError("traits");
            resetError("events");
          }}
          error={errors?.traits}
        />
      </CardV2>

      {initialState.placeConditions &&
        initialState.placeConditions.length > 0 && (
          <CohortPlaceConditionsCard
            placeConditions={initialState.placeConditions}
          />
        )}

      <CohortNameCard
        name={name}
        onNameChange={(name) => {
          setName(name);
          resetError("name");
        }}
        error={errors?.name}
      />
    </>
  );

  return (
    <form onSubmit={handleSave} autoComplete="off">
      <CardStack>
        {infoCard}

        {locked ? <CohortLockedCard /> : fields}

        {renderAboveSubmitButton}

        {locked ? null : (
          <Button
            size="lg"
            type="submit"
            isLoading={saving}
            isDisabled={loadingTraits || saving}
            loadingText="Saving cohort..."
            analyticsName="save"
          >
            Save cohort
          </Button>
        )}
      </CardStack>
    </form>
  );
}
