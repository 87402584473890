import { Box, Heading, Text } from "@chakra-ui/react";
import { BarSeries } from "@visx/xychart";
import { ReactNode, useCallback } from "react";

import { colors } from "../../../styles/chakra-theme-v2";
import { percent } from "../../../utils/formatters";
import { AxisBottom } from "./AxisBottom";
import { AxisLeft } from "./AxisLeft";
import { Chart, RenderTooltipCallback } from "./Chart";

export interface DirectionalityChartDatum {
  x: string;
  y: number;
  groupPercent: number;
  groupLabel: string;
}

function barColorAccessor(d: DirectionalityChartDatum): string {
  if (d.y < 0) return colors.fdy_red[500];
  return colors.fdy_green[600];
}

function DirectionalityChartTooltip({
  xLabel,
  datum,
}: {
  xLabel: string;
  datum: DirectionalityChartDatum;
}) {
  const { x, y, groupPercent, groupLabel } = datum;

  return (
    <Box maxWidth={400}>
      <Heading as="h3" fontSize="fdy_md" mb={2}>
        {xLabel}: <span style={{ fontWeight: "normal" }}>{x}</span>
      </Heading>

      <Text fontSize="fdy_sm" mb={2}>
        {y > 0 ? "+" : ""}
        {y} impact
      </Text>

      <Text fontSize="fdy_sm">
        {percent(groupPercent)} of {groupLabel} fall into this group.
      </Text>
    </Box>
  );
}

export function DirectionalityChart({
  title,
  data,
  xLabel,
  yLabel = "← Impact → ",
}: {
  data: DirectionalityChartDatum[];
  title: ReactNode;
  xLabel: string;
  yLabel?: string;
}) {
  const renderTooltip: RenderTooltipCallback<DirectionalityChartDatum> =
    useCallback(
      ({ tooltipData }) => {
        if (!tooltipData?.nearestDatum) return null;

        return (
          <DirectionalityChartTooltip
            xLabel={xLabel}
            datum={tooltipData.nearestDatum.datum}
          />
        );
      },
      [xLabel]
    );

  return (
    <Chart
      title={title}
      height={300}
      xScale={{ type: "band", padding: 0.2 }}
      yScale={{ type: "linear", domain: [-50, 50] }}
      renderTooltip={renderTooltip}
    >
      <BarSeries
        data={data}
        dataKey="directionality"
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        colorAccessor={barColorAccessor}
      />

      <AxisLeft label={yLabel} />
      <AxisBottom label={xLabel} xScalePadding={0.2} />
    </Chart>
  );
}
