import styled from "@emotion/styled";

import { theme } from "../../constants/theme";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";

const StyledLoadingSplash = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${theme.colors.lighter_gray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30vh;
`;

export const LoadingSplash = () => (
  <StyledLoadingSplash>
    <AnimatedZapLogo center={false} />
  </StyledLoadingSplash>
);
