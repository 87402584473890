import { gql, useQuery } from "@apollo/client";
import { ReactElement } from "react";

import { ResourceIcon } from "../../../ui/ResourceIcon";
import { Card } from "../../Card";
import {
  CohortCardQuery,
  CohortCardQueryVariables,
} from "./__generated__/CohortCardQuery";

const cohortCardQuery = gql`
  query CohortCardQuery($id: UUID!) {
    cohort: get_cohort(id: $id) {
      id
      literate
      count
      membership_count
    }
  }
`;

interface Props {
  id: string;
  color: string;
  onRemoveClick?: () => void;
  disableRemove?: boolean;
}

export function CohortSegmentCard({
  id,
  color,
  onRemoveClick,
  disableRemove,
}: Props): ReactElement {
  const { loading, data } = useQuery<CohortCardQuery, CohortCardQueryVariables>(
    cohortCardQuery,
    {
      variables: { id },
      fetchPolicy: "cache-first",
    }
  );

  const { literate, membership_count, count } = data?.cohort ?? {};

  return (
    <Card
      loading={loading}
      icon={<ResourceIcon.cohorts size={28} />}
      name={literate}
      count={membership_count ?? count}
      color={color}
      onRemoveClick={disableRemove ? undefined : onRemoveClick}
    />
  );
}
