import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { CardV2 } from "../../ui/Card/CardV2";
import { Notice } from "../../ui/Notice";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { PipelineTargetManager } from "./PipelineTargetManager";

interface PipelineDeploymentCardProps {
  scope: PipelineFragment;
}

export function isScopeRefreshing(scope: PipelineFragment) {
  return (
    (scope.status === ResourceStatus.STARTING ||
      scope.status === ResourceStatus.RUNNING ||
      (scope.lastReadInputAt &&
        scope.lastUpdatedConfigAt &&
        scope.lastUpdatedConfigAt > scope.lastReadInputAt)) &&
    scope.lastUpdatedOutputAt !== null
  );
}

/**
 * Render the deployment details for a given pipeline/scope.
 * If there are no targets, show the list of available connections.
 * If there are targets, show the list of active targets with a button to add more targets.
 */
export function PipelineDeploymentCard({ scope }: PipelineDeploymentCardProps) {
  return (
    <CardV2
      title="Deployment"
      text="These settings specify where you want to deploy your pipeline, and how you want the output to be formatted."
    >
      {isScopeRefreshing(scope) ? (
        <Notice
          variant="warning"
          title="Deployments will refresh soon."
          description={`Results of last deployment can still be utilized, however the pipeline is refreshing and this may cause new data to be deployed soon.`}
          dismissable={false}
          sx={{ mb: 6 }}
        />
      ) : null}

      <PipelineTargetManager scope={scope} />
    </CardV2>
  );
}
