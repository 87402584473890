import { Box, Heading, Text } from "@chakra-ui/react";
import { Lock } from "@phosphor-icons/react";

import { ChatLink } from "../../ui/ChatLink";

export function CohortLockedCard() {
  return (
    <Box
      sx={{
        bg: "fdy_gray.200",
        border: "1px solid",
        borderColor: "fdy_gray.400",
        p: 8,
        borderRadius: 6,
        display: "flex",
        gap: 3,
      }}
    >
      <Lock />
      <Box>
        <Heading fontSize="fdy_lg">Custom cohort</Heading>

        <Text>
          Cohort contains conditions set by Faraday staff and cannot be edited.
          To change them, please <ChatLink>contact support</ChatLink>.
        </Text>
      </Box>
    </Box>
  );
}
