import { Box, IconButton, useClipboard } from "@chakra-ui/react";
import { Check, Copy } from "@phosphor-icons/react";

export function CodeSample({ code }: { code: string }) {
  const { hasCopied, onCopy } = useClipboard(code);

  return (
    <Box
      sx={{
        bg: "fdy_gray.900",
        color: "white",
        fontFamily: "monospace",
        borderRadius: 6,
        position: "relative",
      }}
    >
      <IconButton
        aria-label="Copy to clipboard"
        variant="unstyled"
        icon={hasCopied ? <Check /> : <Copy />}
        onClick={onCopy}
        position="absolute"
        top={2}
        right={2}
        size="sm"
      />
      <Box
        sx={{
          overflow: "auto",
          p: 4,
          maxHeight: "20em",
        }}
      >
        <pre>
          <code>{code}</code>
        </pre>
      </Box>
    </Box>
  );
}
