import { gql } from "@apollo/client";
import { Spacer } from "@chakra-ui/react";
import { useState } from "react";

import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { PipelineFragment } from "../__generated__/PipelineFragment";
import { ConnectionTypeInfo } from "../connectionUtils";
import {
  PipelineTargetManagerQuery,
  PipelineTargetManagerQueryVariables,
} from "./__generated__/PipelineTargetManagerQuery";
import { AddTargetButton } from "./AddTargetButton";
import { PipelineConnectionList } from "./PipelineConnectionList";
import { PipelineTargetList } from "./PipelineTargetList";
import { targetFragment } from "./targetFragment";
import { TargetNewModal } from "./TargetNewModal";

/**
 * Information about the connection that the user has selected to create a new target for.
 */
export interface TargetConnectionState {
  /**
   * connection_type metadata from the vannevar
   */
  info: ConnectionTypeInfo;
  /**
   * The replication connection ID or null -> publication connection (i.e. hosted_csv).
   */
  id: string | null;
}

export const PIPELINE_TARGET_MANAGER_QUERY = gql`
  query PipelineTargetManagerQuery($scopeId: ID!) {
    scopeTargets(scopeId: $scopeId) {
      ...TargetFragment
    }
  }
  ${targetFragment}
`;

/**
 * Renders either the list of existing targets for a scope, or falls back to the
 * list of available connections to create a new target.
 */
export function PipelineTargetManager({ scope }: { scope: PipelineFragment }) {
  const [connection, setConnection] = useState<TargetConnectionState>();

  const { data, loading, refetch } = useSojournerQuery<
    PipelineTargetManagerQuery,
    PipelineTargetManagerQueryVariables
  >(PIPELINE_TARGET_MANAGER_QUERY, {
    variables: {
      scopeId: scope.id,
    },
  });

  if (loading) return <AnimatedZapLogo />;
  if (!data?.scopeTargets)
    throw new Error(
      "BUG: Scope targets not found but sojourner did not throw 404"
    );

  // must be rendered in both possible results here, because target creation can happen for either
  const newModal = (
    <TargetNewModal
      connection={connection}
      isOpen={!!connection}
      onClose={() => setConnection(undefined)}
      onTargetAdded={refetch}
      scope={scope}
    />
  );

  // Render the list of their existing targets to manage.
  if (data.scopeTargets.length) {
    return (
      <>
        <AddTargetButton onConnectionSelect={setConnection} />
        <Spacer my={6} />
        <PipelineTargetList scope={scope} targets={data.scopeTargets} />
        {newModal}
      </>
    );
  }

  // if pipeline has no targets yet, render the connections gallery to quickly create a target
  return (
    <>
      <PipelineConnectionList onConnectionSelect={setConnection} />
      {newModal}
    </>
  );
}
