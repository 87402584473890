import { ReactElement } from "react";

import { DocsLinkList, DocsLinkListProps } from "../ui/DocsLinkList";
import { Prose } from "../ui/Prose";

const helpLinks: DocsLinkListProps["links"] = [
  {
    type: "external",
    href: "https://faraday.ai/docs/abstractions/cohorts",
    label: "How to create cohorts",
  },
  {
    type: "external",
    href: "https://faraday.ai/docs/abstractions/events",
    label: "What are events?",
  },
  {
    type: "external",
    href: "https://faraday.ai/docs/abstractions/traits",
    label: "What are traits?",
  },
];

export function CohortsInfoSidebar(): ReactElement {
  return (
    <>
      <Prose>
        <p>
          Cohorts represent specific groups of people that are important to your
          business, such as customers and leads. You can also create more
          advanced cohorts, like “recent customers,” or “repeat buyers.”
        </p>

        <p>
          People will flow into these cohorts as your data changes. Faraday
          keeps track of cohort membership automatically.
        </p>

        <p>
          You will use these cohorts as building blocks when you define the
          objectives you want Faraday to predict.
        </p>
      </Prose>
      <DocsLinkList links={helpLinks} />
    </>
  );
}
