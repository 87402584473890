import { Text } from "@chakra-ui/react";
import * as React from "react";

export function PageIntroText({ children }: { children: React.ReactNode }) {
  return (
    <Text fontSize="fdy_lg" mb={8} maxW="80%" color="fdy_gray.700">
      {children}
    </Text>
  );
}
