import { Text, Tooltip } from "@chakra-ui/react";
import { Icon } from "@phosphor-icons/react";

import { colors } from "../../../styles/chakra-theme-v2";
import { getStatusInfoFromResource } from "./getStatusInfoFromResource";
import { MixedResource } from "./normalizeResourceDetails";

interface IconBadgeProps {
  icon: Icon;
  label: string;
  color?: string;
}

function IconBadge({ icon: Icon, label, color }: IconBadgeProps) {
  return (
    <Text
      as="span"
      sx={{
        whiteSpace: "nowrap",
        color,
        display: "flex",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Icon />
      <span>{label}</span>
    </Text>
  );
}

export function ResourceStatusBadge({
  resource,
  titlePrefix,
  readyAsTimestamp,
}: {
  resource: MixedResource;
  titlePrefix?: string;
  readyAsTimestamp?: boolean;
}): JSX.Element | null {
  const info = getStatusInfoFromResource({
    resource,
    readyAsTimestamp,
  });

  if (info) {
    const badgeLabel = titlePrefix
      ? `${titlePrefix} ${info.shortTitle.toLowerCase()}`
      : info.shortTitle;

    return (
      <Tooltip label={info.description}>
        <span>
          <IconBadge
            icon={info.icon}
            label={badgeLabel}
            color={
              info.variant === "success" ? colors.fdy_gray[800] : info.variant
            }
          />
        </span>
      </Tooltip>
    );
  }

  return null;
}
