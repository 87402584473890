import { Privacy } from "../../../__generated__/sojournerGlobalTypes";
import { components } from "../../../sojourner-oas-types";
import { snakeCaseObjectKeys } from "../../../utils/casing";
import { exists } from "../../../utils/exists";
import { DatasetFormState } from "./DatasetForm/DatasetForm";
import { OutputToStreamsState } from "./DatasetForm/events/DatasetsEventsCard";
import { IdentitySets } from "./DatasetForm/identity/DatasetsIdentitySetsCard";
import { OutputToTraitsState } from "./DatasetForm/traits/DatasetsTraitsCard";

type schemas = components["schemas"];
type DatasetMergePatch = schemas["DatasetMergePatch"];
export type OutputToStreamsMergePatch = schemas["OutputToStreamsMergePatch"];
export type IdentitySetsMergePatch = schemas["IdentitySetsMergePatch"];
export type OutputToTraitsMergePatch = schemas["OutputToTraitsMergePatch"];
type DataMapMergePatch = schemas["DataMapMergePatch"];

export function identitySetsContainClassic(identitySets: IdentitySets) {
  return Object.keys(identitySets).some((idSetName) => {
    return identitySets[idSetName]?.email === "classic";
  });
}

function formatIdentitySets(
  identitySets: IdentitySets
): IdentitySetsMergePatch {
  const formattedIdentitySets: IdentitySetsMergePatch = {};

  Object.keys(identitySets).forEach((idSetName) => {
    const idSet = identitySets[idSetName];

    // Did user 'remove' this identity set? Set it to null so merge patch will remove it
    formattedIdentitySets[idSetName] = idSet
      ? snakeCaseObjectKeys(idSet as Record<string, unknown>)
      : null;

    const formattedIdSet = formattedIdentitySets[idSetName];

    // internal state may have empty values in array, so remove them
    if (formattedIdSet?.house_number_and_street?.length) {
      // ensure null or undefined is removed from the array
      formattedIdSet.house_number_and_street =
        formattedIdSet.house_number_and_street.filter(exists);
    }
  });

  return formattedIdentitySets;
}

function formatStreams(
  streams: OutputToStreamsState
): OutputToStreamsMergePatch | null {
  const formattedStreams: OutputToStreamsMergePatch = {};
  Object.keys(streams).forEach((stream) => {
    // see formatIdentitySets for why we're doing this
    // remove classic events from patch
    if (streams[stream]?.classic) return;

    // If the stream value is null, we are removing it.
    if (streams[stream] === null) {
      formattedStreams[stream] = null;
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dataMap = streams[stream]!.data_map as Record<string, unknown>;
    const formattedDataMap: DataMapMergePatch = {};
    Object.keys(dataMap).forEach((property) => {
      const propertyDetails = dataMap[property] as {
        column_name: string | null;
        format: string | null;
      } | null;
      if (
        !propertyDetails ||
        (propertyDetails.column_name === null &&
          propertyDetails.format === null)
      ) {
        formattedDataMap[property] = null;
      } else {
        formattedDataMap[property] = snakeCaseObjectKeys(propertyDetails);
      }
    });
    formattedStreams[stream] = {
      // remove classic, because it's read only
      data_map: formattedDataMap,
      conditions: streams[stream]?.conditions,
    };
  });

  if (Object.keys(formattedStreams).length === 0) return null;

  return formattedStreams;
}

function formatTraits(
  traits: OutputToTraitsState
): OutputToTraitsMergePatch | null {
  const formattedTraits: OutputToTraitsMergePatch = {};

  Object.keys(traits).forEach((trait) => {
    const traitDetails = traits[trait] as {
      column_name?: string;
      format?: string;
    } | null;

    if (
      !traitDetails ||
      (traitDetails.column_name === null && traitDetails.format === null)
    ) {
      formattedTraits[trait] = null;
    } else {
      // see formatIdentitySets for why we're doing this
      formattedTraits[trait] = snakeCaseObjectKeys(traitDetails);
    }
  });

  if (Object.keys(formattedTraits).length === 0) return null;

  return formattedTraits;
}

// graphql GET uses camelCase
// but graphql merge patch uses snake_case
// I need to convert some things to snake case (eg any field defined on the API spec)
// but not everything (eg the names the users pick for their identity sets, streams, etc)
// which is why the formatting logic is complex
export function datasetToMergePatchInput(
  state: DatasetFormState
): DatasetMergePatch {
  const formattedIdentitySets = state.identitySets
    ? formatIdentitySets(state.identitySets)
    : undefined;
  const formattedStreams = state.outputToStreams
    ? formatStreams(state.outputToStreams)
    : undefined;
  const formattedTraits = state.outputToTraits
    ? formatTraits(state.outputToTraits)
    : undefined;
  // unlike other others, options.merge is a JSON. It was converted to camel case by GET, but needs to be in snake case for PATCH
  if (state?.options?.type === "merge") {
    const merge = state.options.merge as Record<string, string | null>[];
    state.options.merge = merge.map((mergeObject) =>
      snakeCaseObjectKeys(mergeObject)
    );
  }

  const formattedInput: DatasetMergePatch = {
    preview: false,
    options: state.options,
    output_to_streams: formattedStreams,
    output_to_traits: formattedTraits,
    reference_key_columns: state.referenceKeyColumns ?? null,
    upsert_columns: state.upsertColumns ?? null,
    privacy:
      state?.privacy === Privacy.DELETE
        ? "delete"
        : state?.privacy === Privacy.SUPPRESS
        ? "suppress"
        : null,
  };

  const isClassic = state.identitySets
    ? identitySetsContainClassic(state.identitySets)
    : false;

  // don't include identity sets AT ALL if it's classic
  if (!isClassic) {
    // if they deleted all the id sets/traits, etc, set to null
    // because that's how merge patch knows to delete
    // checking obj != {} didn't work, so check the length of keys instead
    formattedInput.identity_sets =
      Object.keys(formattedIdentitySets || {}).length !== 0
        ? formattedIdentitySets
        : {}; // id sets are not optional on API, but you are allowed to provide empty id set like this. API committee would like to keep this as is
  }

  return formattedInput;
}
