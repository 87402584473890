import { StreamAnalysisTimeSeriesDatum } from "@fdy/faraday-js";
import { BarSeries } from "@visx/xychart";
import { useCallback } from "react";

import { date, largeNumber, number } from "../../../utils/formatters";
import { AxisBottomDates } from "../../ui/charts-v2/AxisBottomDates";
import { AxisLeft } from "../../ui/charts-v2/AxisLeft";
import { Chart, RenderTooltipCallback } from "../../ui/charts-v2/Chart";

export function EventStreamAnalysisChart({
  data,
}: {
  data: StreamAnalysisTimeSeriesDatum[];
}) {
  const tooltip: RenderTooltipCallback<StreamAnalysisTimeSeriesDatum> =
    useCallback(({ tooltipData }) => {
      const datum = tooltipData?.nearestDatum?.datum;
      if (!datum) return null;
      return (
        <div>
          <strong>{date(datum.date)}</strong>
          <div>{number(datum.count)} events</div>
        </div>
      );
    }, []);

  // Attempt to show only N ticks on the x-axis plus the last one
  const domain = data.map((d) => d.date);
  const ticksToShow = 7; // cause -1 for last day
  const xTickValues = domain.filter((_, i, arr) => {
    const tickInterval = Math.ceil(arr.length / ticksToShow);
    return tickInterval === 0 || i % tickInterval === 0 || i === arr.length - 1;
  });

  return (
    <Chart
      title="Event count by date"
      xScale={{
        // `band` works better than `time` for this data
        type: "band",
        domain,
        padding: 0.2,
      }}
      yScale={{ type: "linear" }}
      height={400}
      renderTooltip={tooltip}
    >
      <BarSeries
        dataKey="bars"
        data={data}
        xAccessor={(d) => d.date}
        yAccessor={(d) => d.count}
      />

      <AxisLeft label="Events" tickFormat={largeNumber} />
      <AxisBottomDates tickValues={xTickValues} />
    </Chart>
  );
}
