import { Box, Container } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import { useState } from "react";

import { Directionality } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { Button } from "../../ui/Button";
import { DocsLink } from "../../ui/DocsLink";
import { PageHeader } from "../../ui/PageHeader";
import { PageIntroText } from "../../ui/PageIntroText";
import { PageLayout } from "../../ui/PageLayout";
import { UsageWarningNotice } from "../../ui/UsageWarningNotice";
import { DatasetsTableQuery } from "../__generated__/DatasetsTableQuery";
import { DatasetsCreateModal } from "../DatasetsCreateModal/DatasetsCreateModal";
import { datasetsTableQuery } from "../sharedQueries";
import { DatasetsTable } from "./DatasetsTable";

/**
 * Renders the overall Datasets list page with breadcrumbs and a table of datasets.
 */
export function DatasetsListPage() {
  const [createDatasetModalVisible, setCreateDatasetModalVisible] =
    useState<boolean>(false);

  const headerCta = (
    <Box display="flex" gap={2}>
      <DocsLink resourceType="datasets" />
      <Button
        leftIcon={<Plus weight="bold" />}
        onClick={() => {
          setCreateDatasetModalVisible(true);
        }}
        analyticsName="new-dataset"
      >
        New Dataset
      </Button>
    </Box>
  );

  const { data, loading, error } = useSojournerQuery<DatasetsTableQuery>(
    datasetsTableQuery,
    {
      pollInterval: 10000,
    }
  );
  return (
    <PageLayout analyticsStackName="dataset-list">
      <PageHeader
        title="Datasets"
        rightContent={headerCta}
        crumbs={[
          {
            label: "Datasets",
            routeName: ROUTE_NAMES.DATASETS,
          },
        ]}
      />
      <Container size={"fdy_lg"} py={6}>
        <UsageWarningNotice resource="datasets" />
        <PageIntroText>
          Datasets are how you describe your data to Faraday. By pulling data
          from a connection or a CSV file, datasets help Faraday identify the
          people in your data, recognize events and their associated properties,
          and define interesting traits from your data. They are used to create
          cohorts, which are then used as the basis for your predictions such as
          outcomes and persona sets.
        </PageIntroText>
        <DatasetsTable
          error={error}
          datasets={data?.datasets}
          connections={data?.connections}
          loading={loading}
        />
      </Container>
      {createDatasetModalVisible ? (
        <DatasetsCreateModal
          onClose={() => setCreateDatasetModalVisible(false)}
          datasets={data?.datasets}
          connections={data?.connections.filter(
            (conn) =>
              // The graphql query returns all connections, but we only want to show
              // connections that are not managed or are destinations.
              !conn.managed &&
              conn.directionality !== Directionality.DESTINATION_ONLY &&
              !conn.archivedAt
          )}
        />
      ) : null}
    </PageLayout>
  );
}
