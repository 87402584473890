import { useQuery } from "@tanstack/react-query";

import { scopeQueryKeys } from "../../../hooks/api";
import { useFdyClient } from "../../../services/FdyClientProvider";

export const useScopeAnalysisQuery = (scopeId: string) => {
  const client = useFdyClient();
  return useQuery({
    queryKey: [scopeQueryKeys.show(scopeId), "analysis"],
    queryFn: () => client.scopes.getScopeAnalysis(scopeId),
  });
};
