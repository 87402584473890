import { useCallback, useState } from "react";

import { PipelineFormState } from "./PipelineForm";
import { pipelineFormIds } from "./pipelineFormUtils";

type PipelineFieldName = keyof typeof pipelineFormIds;

export type ScopeErrors = Partial<Record<PipelineFieldName, string>>;
export type ResetScopeError = (name: PipelineFieldName) => void;

export const pipelineErrors = {
  nameRequired: "Name is required",
  nameMaxLength: "Name must be less than 64 characters",
  payloadRequired:
    "Payload is required (predictions, cohort memberships, or traits)",
  populationRequired: "Population is required",
  populationExclusionDuplicates:
    "Cannot contain the same cohort for both inclusion and exclusion",
};

/**
 * Validates the scope form state and returns an
 * object with an error message per field, if there are issues.
 *
 * Though some form state fields are grouped into one field,
 * we still set the first field in that group to contain the error message.
 * This is mostly so we don't have to map between errors and all pipeline state.
 */
function validatePipeline(state: PipelineFormState): ScopeErrors | undefined {
  const errors: ScopeErrors = {};

  if (state.name.trim().length === 0) {
    errors.name = pipelineErrors.nameRequired;
  }

  if (state.name.trim().length > 64) {
    errors.name = pipelineErrors.nameMaxLength;
  }

  if (
    state.payloadOutcomeIds.length === 0 &&
    state.payloadPersonaSetIds.length === 0 &&
    state.payloadRecommenderIds.length === 0 &&
    state.payloadCohortIds.length === 0 &&
    state.attributes.length === 0
  ) {
    errors.predictions = pipelineErrors.payloadRequired;
    errors.cohortMembership = pipelineErrors.payloadRequired;
    errors.traits = pipelineErrors.payloadRequired;
  }

  if (
    state.populationCohortIds.length === 0 &&
    state.everyoneSelected === false
  ) {
    errors.population = pipelineErrors.populationRequired;
  }

  if (
    state.populationExclusionCohortIds.some((id) =>
      state.populationCohortIds.includes(id)
    )
  ) {
    errors.populationExclusion = pipelineErrors.populationExclusionDuplicates;
  }

  if (Object.keys(errors).length === 0) {
    return undefined;
  }

  return errors;
}

/**
 * Stores client side errors for validating the scope/pipeline form.
 *
 * Errors from the validation must be set manually.
 *
 * @example
 * const { errors, resetError, setErrors, validateScope } = useScopeValidator();
 *
 * const errors = validateScope(state);
 * if (errors) {
 *  return setErrors(errors);
 * }
 * // save form
 */
export function usePipelineValidator() {
  const [errors, setErrors] = useState<ScopeErrors | undefined>();

  const resetError = useCallback<ResetScopeError>(
    (name: PipelineFieldName) => {
      setErrors((errors) => ({
        ...errors,
        [name]: undefined,
      }));
    },
    [setErrors]
  );

  return {
    errors,
    resetError,
    setErrors,
    validatePipeline,
  };
}
