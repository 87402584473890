import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { PencilSimple } from "@phosphor-icons/react";
import { useState } from "react";

import { ROUTE_NAMES } from "../../../../constants/routeNames";
import { useSojournerQuery } from "../../../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "../../../ui/AnimatedZapLogo";
import { Button } from "../../../ui/Button";
import { CardV2 } from "../../../ui/Card/CardV2";
import { InlineButtons } from "../../../ui/InlineButtons";
import { ModalV2 } from "../../../ui/ModalV2";
import { RouterLink } from "../../../ui/RouterLink";
import {
  DatasetShowPageQuery_dataset,
  DatasetShowPageQuery_dataset_options_DatasetOptionsMerge,
} from "../../__generated__/DatasetShowPageQuery";
import { DatasetsTableQuery } from "../../__generated__/DatasetsTableQuery";
import {
  DatasetMergeForm,
  DatasetMergeFormat,
  validationMergeDatasetErrors,
} from "../../shared/DatasetsMergeForm";
import {
  DatasetMergeChangeConfigWarning,
  needToChangeMergeInput,
} from "../../shared/DatasetsMergeWarning";
import { datasetsTableQuery } from "../../sharedQueries";
import { useUpdateDataset } from "../useUpdateDataset";

enum AvailableModals {
  none,
  mergeEdit,
  mergeWarning,
}

export function DatasetDataTabMerge({
  dataset,
}: {
  dataset: DatasetShowPageQuery_dataset;
}) {
  if (dataset.options.__typename !== "DatasetOptionsMerge")
    throw new Error(
      "Tried to render a hosted csv target with non-hosted csv options"
    );

  // we know the options are in this format because only merge datasets can get to this page
  const datasetOptions =
    dataset.options as DatasetShowPageQuery_dataset_options_DatasetOptionsMerge;
  const [activeModal, setActiveModal] = useState<AvailableModals>(
    AvailableModals.none
  );
  const [merge, setMerge] = useState<(DatasetMergeFormat | null)[]>(
    datasetOptions?.merge
  );
  const [error, setError] = useState<string>("");
  const [apiHasError, setApiHasError] = useState<boolean>(false);

  const { updateDataset, updating } = useUpdateDataset({
    onCompleted() {
      resetModal();
    },
    onError() {
      // doing this instead of using useSojournerMutation's 'error'
      // because this is for the merge warning modal.
      // when moving config from input datasets to merge datasets
      // we have to do multiple api calls.
      // if one of those calls fails, the warning modal has functions to restore the old config
      // and apiHasError is used to keep track of whether or not that fix is still required.
      setApiHasError(true);
    },
  });

  const { data, loading } =
    useSojournerQuery<DatasetsTableQuery>(datasetsTableQuery);

  if (loading) {
    return <AnimatedZapLogo />;
  }

  if (!data) {
    throw new Error("Failed to load dataset details");
  }

  function resetModal() {
    setActiveModal(AvailableModals.none);
    setMerge(datasetOptions?.merge);
    setError("");
  }

  function checkIfConfigNeedsChange() {
    const error = validationMergeDatasetErrors(merge);
    if (error) {
      setError(error);
      return;
    }
    const inputsNeedChange = needToChangeMergeInput(
      merge,
      data?.datasets ?? []
    );
    if (Object.keys(inputsNeedChange).length) {
      setActiveModal(AvailableModals.mergeWarning);
    } else {
      handleSubmit();
    }
  }

  function handleSubmit(migrate: boolean = false) {
    const formatted = merge.map((merge) => {
      if (merge) {
        const { __typename, ...newMerge } = merge;
        return {
          dataset_id: newMerge.datasetId,
          join_column: newMerge.joinColumn,
        };
      }
    });
    const config = { options: { merge: formatted, type: "merge", migrate } };
    updateDataset(dataset.id, config);
  }

  return (
    <Box>
      <CardV2 title="Input — Merge">
        <Table my={4}>
          <Thead>
            <Tr>
              <Th>Dataset</Th>
              <Th>Join column</Th>
            </Tr>
          </Thead>
          <Tbody>
            {datasetOptions.merge.map((merge) => {
              if (!merge) {
                throw new Error("failed to load config for merge dataset");
              }
              const inputDataset = data?.datasets.find(
                (existingDataset) => existingDataset.id === merge?.datasetId
              );
              if (!inputDataset)
                throw new Error("failed to load config for merge dataset");
              return (
                <Tr key={merge.datasetId}>
                  <Td>
                    <Link
                      as={RouterLink}
                      routeName={ROUTE_NAMES.DATASETS_DEFINITION}
                      params={{ id: inputDataset.id }}
                      fontWeight="bold"
                    >
                      {inputDataset.name}
                    </Link>
                  </Td>
                  <Td>{merge.joinColumn}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Button
          width="100%"
          variant="secondary"
          leftIcon={<PencilSimple />}
          onClick={() => setActiveModal(AvailableModals.mergeEdit)}
          analyticsName="merge-edit"
        >
          Edit
        </Button>

        <ModalV2
          isOpen={activeModal === AvailableModals.mergeEdit}
          onClose={() => resetModal()}
          title="Merge dataset"
          footer={
            <InlineButtons>
              <Button
                variant="tertiary"
                onClick={() => resetModal()}
                analyticsName="cancel"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={checkIfConfigNeedsChange}
                isLoading={updating}
                isDisabled={updating}
                analyticsName="save"
              >
                Save dataset
              </Button>
            </InlineButtons>
          }
          analyticsStackName="merge-modal"
        >
          <Text fontSize="fdy_lg" mb={4}>
            Choose the existing datasets containing the data you want to merge
          </Text>
          <FormControl isInvalid={!!error}>
            <FormLabel color="fdy_gray.800" fontSize="fdy_md">
              Datasets to merge
            </FormLabel>
            <DatasetMergeForm
              merge={merge}
              setMerge={setMerge}
              existingDatasets={data?.datasets}
              existingConnections={data?.connections}
              clearError={() => {
                setError("");
              }}
            />
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
        </ModalV2>
        <DatasetMergeChangeConfigWarning
          isOpen={activeModal === AvailableModals.mergeWarning}
          onCancel={() => {
            setActiveModal(AvailableModals.mergeEdit);
          }}
          onSave={() => {
            handleSubmit(true);
          }}
          mergeConfig={merge}
          existingDatasets={data?.datasets}
          error={apiHasError}
          loading={loading}
        />
      </CardV2>
    </Box>
  );
}
