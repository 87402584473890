import { Box, Tooltip, VisuallyHidden } from "@chakra-ui/react";
import { ReactNode } from "react";

/**
 * Renders a single horizontal bar chart. The bar is filled with color N%, where N is the percent prop.
 *
 * A tooltip can be shown if the tooltip props are provided. This is so the tip can be positioned to the bar fill
 * while having the hover area that triggers the tip be controlled by some other element. Useful if you want the trigger area to the larger than the height of this chart, so users don't have to be so precise to show the tip.
 */
export function PercentBarChart({
  percent,
  tooltip,
  isTooltipOpen,
}: {
  percent: number;
  tooltip: ReactNode;
  isTooltipOpen?: boolean;
}) {
  const percentString = `${Math.round(percent * 100)}%`;

  return (
    <Box
      sx={{
        width: "100%",
        height: "8px",
        bg: "fdy_gray.200",
        borderRadius: "md",
        overflow: "hidden",
      }}
    >
      <Tooltip label={tooltip} isOpen={isTooltipOpen} placement="top" hasArrow>
        <Box
          sx={{
            height: "100%",
            bg: "fdy_magenta.500",
          }}
          style={{
            width: percentString,
          }}
        >
          <VisuallyHidden>{percentString}</VisuallyHidden>
        </Box>
      </Tooltip>
    </Box>
  );
}
