import { Box, Flex } from "@chakra-ui/react";

import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import { DatasetColumnSelect } from "./DatasetColumnSelect";
import { EventProperty } from "./DatasetsEventsModal";
import { PropertyFormatSelect } from "./PropertyFormatSelect";

export function BlessedPropertyFields({
  property,
  detectedColumns,
  disabled,
  sampleData,
  onChange,
}: {
  property: EventProperty;
  onChange: (value: EventProperty) => void;
  detectedColumns: DetectedColumn[];
  disabled?: boolean;
  sampleData: SampleData | undefined;
}) {
  return (
    <Flex gap={4}>
      <Box flex="1">
        <DatasetColumnSelect
          label={`${property.name} column`}
          value={property.column_name}
          onChange={(value) => onChange({ ...property, column_name: value })}
          sampleData={sampleData}
          detectedColumns={detectedColumns}
          analyticsName={`${property.name}-column`}
        />
      </Box>
      <Box flex="1">
        <PropertyFormatSelect
          label={`${property.name} format`}
          value={property.format}
          onChange={(value) => onChange({ ...property, format: value })}
          detectedColumns={detectedColumns}
          property={property}
          disabled={disabled}
          analyticsName={`${property.name}-format`}
          required // blessed fields require format? why?
        />
      </Box>
    </Flex>
  );
}
