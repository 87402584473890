import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
} from "react";
import Rollbar from "rollbar";

const RollbarContext = createContext<Rollbar | undefined>(undefined);

interface RollbarProviderProps extends PropsWithChildren {
  rollbar: Rollbar;
}

export const RollbarProvider: FunctionComponent<RollbarProviderProps> = ({
  children,
  rollbar,
}) => {
  return (
    <RollbarContext.Provider value={rollbar}>
      {children}
    </RollbarContext.Provider>
  );
};

export function useRollbar(): Rollbar {
  const rollbar = useContext(RollbarContext);
  if (!rollbar) throw new Error("Could not find rollbar instance in context!");

  return rollbar;
}
