import { RadioGroupV2 } from "../../../ui/RadioGroupV2";

const humanReadableOptions = [
  {
    label: "Machine friendly",
    value: "false",
    sublabel: "Best for automated systems that need consistent names",
    helpText: (
      <>
        Column headers will contain permanent IDs referencing payload components
        like outcomes and personas. Ex: <code>fdy_cohort_UUID_member</code>
      </>
    ),
  },
  {
    label: "Human friendly",
    value: "true",
    sublabel: "Best for convenient, easy to read interpretation",
    helpText: (
      <>
        Column headers will contain payload components' current names. Ex:{" "}
        <code>fdy_cohort_customers_member</code>
      </>
    ),
  },
];

export function TargetHumanReadableRadios({
  humanReadable,
  onChange,
  disabled,
}: {
  humanReadable: boolean;
  onChange: (humanReadable: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <RadioGroupV2<string>
      name="human_readable"
      options={humanReadableOptions}
      value={humanReadable ? "true" : "false"}
      onChange={(v) => onChange(v === "true" ? true : false)}
      disabled={disabled}
      analyticsName="human-readable"
    />
  );
}
