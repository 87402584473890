import { Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Icon } from "@phosphor-icons/react";
import { ReactElement } from "react";

import { theme } from "../../../constants/theme";
import { Link } from "../../ui/Link";

const StyledEmptyPreset = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.space(1)};
  align-items: center;
  padding-block: ${theme.space(4)};
`;

export function EmptyPresets({
  icon: Icon,
  name,
  cta,
  routeName,
}: {
  icon: Icon;
  name: string;
  cta: string;
  routeName: string;
}): ReactElement {
  return (
    <StyledEmptyPreset>
      <Icon size={24} />
      <Text as="strong" fontSize="fdy_lg">
        No active {name}
      </Text>
      <Text>
        To view {name} in explore, you’ll
        <br />
        need to create and/or enable them in
        <br />
        the{" "}
        <Link routeName={routeName} style={{ textDecoration: "underline" }}>
          {cta}
        </Link>
        .
      </Text>
    </StyledEmptyPreset>
  );
}
