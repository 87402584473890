import "reactflow/dist/base.css";

import { Box } from "@chakra-ui/react";
import { GraphEdge } from "@fdy/faraday-js";
import { useCallback, useMemo, useState } from "react";
import {
  Background,
  Controls,
  Node,
  NodeMouseHandler,
  ReactFlow,
} from "reactflow";

import { colors } from "../../../styles/chakra-theme-v2";
import { Markers } from "./components/Markers";
import { GRAPH_HEIGHT, NODE_TYPES } from "./config";
import { buildGraphForResource } from "./utils/buildGraphForResource";
import { createResourceGraphNodesAndEdges } from "./utils/createResourceGraphNodesAndEdges";
import { filterGraphEdges } from "./utils/filterGraphEdges";
import {
  highlightConnectedEdges,
  highlightNodeStyles,
} from "./utils/highlighting";

interface ResourceGraphChartProps {
  /**
   * The ID of the resource to center the graph around.
   * Only upstream and downstream resources of this resource will be shown.
   */
  resourceId: string;

  /**
   * The list of all graph edges that should contain the resource ID.
   */
  graphEdges: GraphEdge[];

  /**
   * The height of the chart.
   */
  height?: number | string;

  /**
   * Whether to fit all nodes in view when the chart is rendered.
   */
  fitAllNodesInView?: boolean;

  /**
   * Whether to zoom in/out on the chart when scrolling.
   */
  zoomOnScroll?: boolean;
}

/**
 * Given a resource ID and a list of graph edges on the account, renders a flow
 * chart that shows resources for each resource within the graph, and connecting lines (edges)
 * between them. This represents the model train babysitter dependency graph.
 *
 * Terms:
 * - GraphEdge: the object coming from our API that represents a dependency link
 *   between 2 resources.
 * - Node (reactflow): a resource in the graph, represented as a box in the
 *   chart with some details about the resource.
 * - Edge (reactflow): a line connecting 2 nodes.
 *
 * Features:
 * - Mousing over a node/resource will highlight its connected resources (depth of 1).
 *   This is helpful when the graph has many tangled nodes and it's difficult to
 *   trace the links betweeen resources.
 * - Nodes are anchor links that can be clicked to navigate to the resource's details page.
 * - The chart is interactive and can be panned and zoomed.
 * - Resources/Nodes are grouped into columns based on their resource type.
 *   Some groups are bundles of resource types e.g. streams and traits are considered 'Artifacts'.
 *   This was easier to implement since it only required single columns for each group.
 */
export const ResourceGraphChart = ({
  resourceId,
  graphEdges,
  height = GRAPH_HEIGHT,
  fitAllNodesInView,
  zoomOnScroll,
}: ResourceGraphChartProps) => {
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);

  const { nodes, edges, groupNodes } = useMemo(() => {
    const filteredEdges = filterGraphEdges(graphEdges);

    // Build the graph for the resource ID. No other resources will be shown.
    const resourceGraphEdges = buildGraphForResource(filteredEdges, resourceId);

    // Create nodes and edges
    return createResourceGraphNodesAndEdges(resourceGraphEdges, resourceId);
  }, [resourceId, graphEdges]);

  const handleNodeMouseEnter: NodeMouseHandler = useCallback((_event, node) => {
    setSelectedNode(node);
  }, []);

  const clearSelectedNodeId = useCallback(() => {
    setSelectedNode(null);
  }, []);

  const allNodes = useMemo(
    () => [...groupNodes, ...nodes],
    [groupNodes, nodes]
  );

  const currentNodes = useMemo(
    () => nodes.filter((node) => node.data.current),
    [nodes]
  );

  // Set up node+edge highlighting when hovering over a node.
  // Nodes are done with CSS classes for performance reasons,
  // while edges make more sense to use inline styles due to "z-index" issues.
  const highlightedNodeStyles = highlightNodeStyles(nodes, edges, selectedNode);

  const highlightedEdges = useMemo(
    () => highlightConnectedEdges(edges, selectedNode),
    [edges, selectedNode]
  );

  return (
    <Box
      style={{
        height,
        position: "relative",
        border: "1px solid",
        borderColor: colors.fdy_gray[200],
      }}
      sx={highlightedNodeStyles}
    >
      <Markers />
      <ReactFlow
        nodes={allNodes}
        edges={highlightedEdges}
        fitView
        fitViewOptions={{
          minZoom: 0.5,
          maxZoom: 1,
          nodes: fitAllNodesInView ? allNodes : currentNodes,
        }}
        onNodeMouseEnter={handleNodeMouseEnter}
        onNodeMouseLeave={clearSelectedNodeId}
        nodeTypes={NODE_TYPES}
        proOptions={{ hideAttribution: true }}
        nodesConnectable={false}
        zoomOnScroll={zoomOnScroll}
        preventScrolling={zoomOnScroll}
      >
        <Background />
        <Controls showInteractive={false} />
      </ReactFlow>
    </Box>
  );
};
