import { gql } from "@apollo/client";
import { ReactElement } from "react";
import { useRouter } from "react-router5";

import { CohortPostInput } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_CREATED_COHORT,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { UsageWarningNotice } from "../ui/UsageWarningNotice";
import {
  CohortCreateMutation,
  CohortCreateMutationVariables,
} from "./__generated__/CohortCreateMutation";
import { CohortForm, CohortsFormState } from "./CohortForm";
import { cohortsListPageQuery } from "./CohortsListPage";
import { cohortToFormState } from "./CohortsShowPage";
import { CohortsSidebarLayout } from "./CohortsSidebarLayout";
import { trimAndFilterEmptyValues } from "./cohortUtils";
import { useCohortQuery } from "./useCohortQuery";

export const CREATE_COHORT = gql`
  mutation CohortCreateMutation($cohort: CohortPostInput!) {
    createCohort(cohortPostInput: $cohort) {
      id
      name
    }
  }
`;

/**
 * Transform the form state into a format suitable for POSTing to the API.
 */
function cohortStateToPostInput({
  event,
  name,
  traits,
  placeConditions,
}: CohortsFormState): CohortPostInput {
  const input: CohortPostInput = {
    name,
    minCount: event?.minCount ?? undefined,
    maxCount: event?.maxCount ?? undefined,
    minValue: event?.minValue ?? undefined,
    maxValue: event?.maxValue ?? undefined,
    traits: traits?.map((t) => ({
      ...t,
      in: trimAndFilterEmptyValues(t.in),
      nin: trimAndFilterEmptyValues(t.nin),
    })),
    streamConditions: event?.streamConditions.map((c) => ({
      ...c,
      in: trimAndFilterEmptyValues(c.in),
      nin: trimAndFilterEmptyValues(c.nin),
    })),
  };

  input.streamName = event?.streamName ?? undefined;

  if (event?.occurrence) {
    input.recency = {
      occurrence: event.occurrence,
      maxDays: event.maxDays ?? undefined,
      minDays: event.minDays ?? undefined,
    };
  }

  if (placeConditions?.length) {
    input.placeConditions = placeConditions?.map((pc) => ({
      placeId: pc.placeId,
      distance: pc.distance,
      invert: pc.invert,
    }));
  }

  return input;
}

/**
 * Renders a page for creating a new trait or event based cohort.
 */
export function CohortsNewPage(): ReactElement {
  const router = useRouter();
  const toast = useToast();
  const track = useHubSpotEvent();

  const route = router.getState();
  const cohortToDuplicateId = route.params.duplicate as string | undefined;

  const { data: cohortToDuplicateData, loading: loadingCohortToDuplicate } =
    useCohortQuery(cohortToDuplicateId);

  const [createCohort, { loading }] = useSojournerMutation<
    CohortCreateMutation,
    CohortCreateMutationVariables
  >(CREATE_COHORT, {
    refetchQueries: [{ query: cohortsListPageQuery }],
    onCompleted(data) {
      if (data.createCohort?.id) {
        const name = data.createCohort.name;
        track(HUBSPOT_USER_CREATED_COHORT, {
          resource_id: data.createCohort.id,
          resource_name: data.createCohort.name,
        });
        toast({
          status: "success",
          title: `${name} saved`,
          description: `${name} has been saved. The cohort will need to build before we can provide accurate population counts, we’ll email you when it completes.`,
        });

        router.navigate(ROUTE_NAMES.COHORTS_SHOW, {
          cohort: data.createCohort.id,
        });
      }
    },
  });

  function onFormSave(state: CohortsFormState) {
    const cohort = cohortStateToPostInput(state);

    createCohort({
      variables: {
        cohort,
      },
    });
  }

  const title = "New cohort";

  if (loadingCohortToDuplicate) {
    return <AnimatedZapLogo />;
  }

  const initialState = cohortToDuplicateData?.cohort
    ? cohortToFormState({
        ...cohortToDuplicateData.cohort,
        name: cohortToDuplicateData.cohort.name + " (copy)",
      })
    : undefined;

  return (
    <CohortsSidebarLayout
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.COHORT_NEW,
      }}
    >
      <UsageWarningNotice resource={"cohorts"} />
      <CohortForm
        initialState={initialState}
        onSave={onFormSave}
        saving={loading}
      />
    </CohortsSidebarLayout>
  );
}
