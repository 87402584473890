import {
  FormControl,
  FormLabel,
  Switch,
  SwitchProps,
  Tooltip,
  VisuallyHidden,
} from "@chakra-ui/react";

import { analyticsAttrs, useAnalyticsKey } from "../Analytics/AnalyticsStack";
import { useId } from "../useId";

export interface SwitchV2Props extends SwitchProps {
  label: string;
  visuallyHideLabel?: boolean;
  tooltip?: string;
  hasError?: boolean;
  analyticsName?: string;
}

/**
 * Renders a switch with adjacent visible label.
 * If you don't need a visible label, you can likely just use Chakra Switch with aria-label.
 */
export function SwitchV2({
  label,
  visuallyHideLabel,
  isDisabled,
  tooltip,
  hasError,
  variant,
  analyticsName,
  ...props
}: SwitchV2Props) {
  const id = useId();
  const analyticsKey = useAnalyticsKey(analyticsName);

  const labelNode = (
    <FormLabel
      htmlFor={id}
      fontSize="fdy_md"
      opacity={isDisabled ? 0.5 : undefined}
      color="fdy_gray.600"
      mb={0}
      mr={2}
    >
      {label}
    </FormLabel>
  );

  const switchNode = (
    <FormControl display="flex" alignItems="center" width="auto">
      {visuallyHideLabel ? (
        <VisuallyHidden>{labelNode}</VisuallyHidden>
      ) : (
        labelNode
      )}
      <Switch
        id={id}
        isDisabled={isDisabled}
        isInvalid={hasError}
        variant={variant}
        {...analyticsAttrs(analyticsKey)}
        {...props}
      />
    </FormControl>
  );

  if (tooltip) {
    return (
      <Tooltip hasArrow label={tooltip} placement="end" shouldWrapChildren>
        {switchNode}
      </Tooltip>
    );
  }

  return switchNode;
}
