import { ChangeEvent } from "react";

import { FormField } from "../../../../ui/FormField";
import { Select } from "../../../../ui/Select";
import { formIds, labels } from "./TargetLimit";

export function TargetLimitOutcomeSelect({
  value,
  onChange,
  payloadOutcomes,
  required,
}: {
  value: string | null | undefined;
  onChange: (outcomeId: string | null) => void;
  payloadOutcomes: { id: string; name: string }[];
  required?: boolean;
}) {
  function handleOutcomeChange(event: ChangeEvent<HTMLSelectElement>) {
    const maybeId = event.target.value === "" ? null : event.target.value;
    onChange(maybeId);
  }

  return (
    <FormField
      label={labels.outcomeId}
      htmlFor={formIds.outcome_id}
      visuallyHiddenLabel
    >
      <Select
        id={formIds.outcome_id}
        value={value ?? ""}
        onChange={handleOutcomeChange}
        required={required}
        analyticsName="limit-outcome"
      >
        <option value="">Choose outcome...</option>
        {payloadOutcomes.map((outcome) => {
          return (
            <option key={outcome.id} value={outcome.id}>
              {outcome.name}
            </option>
          );
        })}
      </Select>
    </FormField>
  );
}
