import { Box } from "@chakra-ui/react";
import {
  Files,
  Gear,
  Icon,
  Invoice,
  Polygon,
  UsersThree,
} from "@phosphor-icons/react";

import {
  CUSTOM_GEOGRAPHY_ENABLED,
  FILES_ENABLED,
  MEMBERS_ENABLED,
} from "../../constants/accountFeatureNames";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  AccountFeature,
  useAccountConfigMap,
} from "../../hooks/accountConfigHooks";
import { RouterLink } from "../ui/RouterLink";

type SettingsNavItem = {
  icon: Icon;
  label: string;
  routeName: string;
  flag?: AccountFeature;
};

// TODO: move locations and connections to here?
export const SETTINGS_NAV_ITEMS: SettingsNavItem[] = [
  {
    label: "Account",
    icon: Gear,
    routeName: ROUTE_NAMES.SETTINGS_ACCOUNT,
  },
  {
    label: "Team",
    icon: UsersThree,
    routeName: ROUTE_NAMES.SETTINGS_MEMBERS,
    flag: MEMBERS_ENABLED,
  },
  {
    label: "Usage & billing",
    icon: Invoice,
    routeName: ROUTE_NAMES.SETTINGS_BILLING,
  },
  {
    label: "Files",
    icon: Files,
    routeName: ROUTE_NAMES.SETTINGS_FILES,
    flag: FILES_ENABLED,
  },
  {
    label: "Geographies",
    icon: Polygon,
    routeName: ROUTE_NAMES.SETTINGS_GEOGRAPHIES,
    flag: CUSTOM_GEOGRAPHY_ENABLED,
  },
];

export function SettingsNav() {
  const accountConfig = useAccountConfigMap();

  const enabledItems = SETTINGS_NAV_ITEMS.filter((item) => {
    if (item.flag) {
      return accountConfig[item.flag];
    }
    return true;
  });

  return (
    <Box as="ul">
      {enabledItems.map((item, index) => (
        <Box as="li" key={index} sx={{ _notLast: { mb: 2 } }}>
          <RouterLink
            routeName={item.routeName}
            sx={{
              display: "block",
              p: 2,
              borderRadius: "md",
              bg: "fdy_gray.200",
              width: 200,
              fontWeight: "medium",
              transition: ".2s",
              textDecoration: "none",
              color: "fdy_gray.800",
              "&.active": {
                bg: "fdy_purple.500",
                color: "white",
              },
              _hover: {
                bg: "fdy_purple.500",
                color: "white",
              },
            }}
            activeClassName="active"
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              <item.icon size={20} weight="duotone" />
              {item.label}
            </Box>
          </RouterLink>
        </Box>
      ))}
    </Box>
  );
}
