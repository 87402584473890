import {
  Box,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";

import { useId } from "../../ui/useId";

// TODO: turn into something like Cardv2FormSection and use Cardv2 styles to avoid nesting this within CardV2
export function FormSection({
  children,
  text,
  title,
  error,
  suffix,
}: {
  children: ReactNode;
  title: string;
  text: ReactNode;
  error?: string;
  suffix?: string;
}) {
  const id = useId("form-section");
  return (
    <FormControl as="section" isInvalid={Boolean(error)} aria-labelledby={id}>
      <Box as="header" mb={6}>
        <Box display="flex" gap={2} alignItems="center">
          <Heading as="h3" id={id}>
            {title}
          </Heading>
          {suffix && (
            <Text
              as="span"
              sx={{
                textTransform: "uppercase",
                fontSize: "fdy_sm",
                color: "fdy_gray.700",
              }}
            >
              {suffix}
            </Text>
          )}
        </Box>
        <Text color="fdy_gray.700" fontSize="fdy_lg">
          {text}
        </Text>
      </Box>
      {children}
      <FormErrorMessage pt={6}>{error}</FormErrorMessage>
    </FormControl>
  );
}
