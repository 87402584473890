import { Stack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const AlertStack: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack
      spacing={6}
      sx={{
        mb: 6,
        _empty: {
          mb: 0,
        },
      }}
    >
      {children}
    </Stack>
  );
};
