import { useRouter } from "react-router5";

import {
  SegmentSubtype,
  SegmentType,
} from "./../../../__generated__/globalTypes";

export interface SegmentInfo {
  id: string;
  type: SegmentType;
  subtype?: SegmentSubtype;
}

interface ExploreParamsHook {
  segments: SegmentInfo[];
  addSegment: (segment: SegmentInfo) => void;
  removeSegment: (segment: SegmentInfo) => void;
}

export function makeSegmentParam({ id, type, subtype }: SegmentInfo): string {
  return (subtype ? [type, id, subtype] : [type, id]).join(".");
}

/**
 * A hook for adding, removing, and reading preset segment (personas, cohorts, outcomes, starred audiences)
 * to/from the URL query string so the URL can be the source of truth for the Explore segment list.
 * Adding/removing a segment naturally navigates to the new URL.
 *
 * Because outcomes need subtype info, each segment bundle is stored as an array joined by a period.
 *
 * e.g. `<type>.<id>.<subtype>`
 *
 * - `type`: the type of segment (persona, cohort, outcome, audience)
 * - `id`: the id of the segment
 * - `subtype`: the modifier for the segment counted e.g. top percentile for outcomes
 *
 * Alternatively we could JSON stringify + encode the segment details as an object but then the URL would
 * look nasty/lengthy with the encoded characters.
 *
 * If we move to these segments with all their details having 1 associated UUID (like a normal audience)
 * we could go back to using only UUIDs as the query string values.
 */
export function useExploreParams(): ExploreParamsHook {
  const router = useRouter();

  const state = router.getState();

  // When there's one segment query key, it comes through as a string
  // so convert it to array.
  const segmentParam = state.params.segment || [];
  const currentSegments = Array.isArray(segmentParam)
    ? segmentParam
    : [segmentParam];

  function addSegment(seg: SegmentInfo) {
    // disallow duplicates using a Set
    const segments = Array.from(
      new Set([...currentSegments, makeSegmentParam(seg)])
    );

    const newParams = {
      ...state.params,
      // Query key is usually singular,
      // which makes for this maybe confusing key-value naming
      segment: segments,
    };

    router.navigate(state.name, newParams);
  }

  // Turn the active query string segments back into something useful for
  // fetching the original UDPX objects
  const segments: SegmentInfo[] = currentSegments.map(
    (s: string): SegmentInfo => {
      // must match same array order as we joined them by above.
      const [type, id, subtype = null] = s.split(".");

      return {
        type: type as SegmentType,
        id,
        subtype: subtype as SegmentSubtype,
      };
    }
  );

  function removeSegment(seg: SegmentInfo) {
    const segments = currentSegments.filter((s: string) => {
      return s !== makeSegmentParam(seg);
    });

    const newParams = {
      ...state.params,
      segment: segments,
    };

    router.navigate(state.name, newParams);
  }

  return {
    addSegment,
    removeSegment,
    segments,
  };
}
