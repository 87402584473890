import { Text } from "@chakra-ui/react";
import { useMemo } from "react";

import { Notice } from "./Notice";
import { useGraph } from "./ResourceGraphCard/graph-queries";
import { ResourceLink } from "./ResourceLink";

interface DependencyStatusAlertProps {
  resource: { id: string };
}

const useUpstreamResources = (resource: { id: string }) => {
  const { data } = useGraph({
    // disable suspense to avoid blocking most of the resource page
    suspense: false,
  });

  return useMemo(
    // TODO: we should update graph api endpoint to accept a resource ID param to avoid returning the whole account graph
    () => data?.filter((edge) => edge.downstream_id === resource.id) ?? [],
    [data]
  );
};

/**
 * Renders an alert if any of the dependencies of the given resource are in an error state.
 * Lists the dependencies that are in an error state with links to their detail pages.
 */
export function DependencyStatusAlert({
  resource,
}: DependencyStatusAlertProps) {
  const upstreamResources = useUpstreamResources(resource);
  const erroredResources = upstreamResources.filter(
    (edge) => edge.upstream_status === "error"
  );

  if (erroredResources.length === 0) {
    return null;
  }

  const content = (
    <div>
      <Text mb={2}>
        Dependencies of this resource are in an error state. Please resolve
        their issues to ensure this resource can build successfully.
      </Text>
      <ul>
        {upstreamResources?.map((resource) => (
          <li key={resource.upstream_id}>
            <ResourceLink
              resource={{
                id: resource.upstream_id,
                name: resource.upstream_literate,
                resource_type: resource.upstream_type,
              }}
              showResourceIcon
            />
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Notice variant="warning" title="Dependency errors" description={content} />
  );
}
