import { Text } from "@chakra-ui/react";

interface OutcomeSummaryProps {
  attainment?: string | null;
  eligibility?: string | null;
  attrition?: string | null;
}

export function OutcomeSummary({
  attainment,
  eligibility,
  attrition,
}: OutcomeSummaryProps) {
  const textProps = {
    fontSize: "fdy_2xl",
    color: "fdy_gray.900",
    lineHeight: "heading",
  };

  if (eligibility && attainment && attrition) {
    return (
      <Text as="span" {...textProps}>
        Faraday will predict whether each member of{" "}
        <strong>{eligibility}</strong> is more likely to become a member of{" "}
        <strong>{attainment}</strong> than a member of{" "}
        <strong>{attrition}</strong>.
      </Text>
    );
  }

  if (eligibility && attainment) {
    return (
      <Text as="span" {...textProps}>
        Faraday will predict whether each member of{" "}
        <strong>{eligibility}</strong> is more likely to become a member of{" "}
        <strong>{attainment}</strong>.
      </Text>
    );
  }

  if (attainment && attrition) {
    return (
      <Text as="span" {...textProps}>
        Faraday will predict whether <strong>anyone</strong> is more likely to
        become a member of <strong>{attainment}</strong> than a member of{" "}
        <strong>{attrition}</strong>.
      </Text>
    );
  }

  if (attainment) {
    return (
      <Text as="span" {...textProps}>
        Faraday will predict the likelihood that <strong>anyone</strong> will
        become a member of <strong>{attainment}</strong>.
      </Text>
    );
  }

  return (
    <Text as="span" {...textProps}>
      Please select an attainment cohort to get started.
    </Text>
  );
}
