import { Td, Tr } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useAccountConfigMap } from "../../../hooks/accountConfigHooks";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { showInExplore } from "../../cohorts/CohortsListPage/CohortsTable";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTableList } from "../../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../../ui/ResourceTableTabs";
import { RouterLink } from "../../ui/RouterLink";
import { SortableHeader, SortableTable } from "../../ui/SortableTable";
import { TimeAgo } from "../../ui/TimeAgo";
import {
  PersonasListQuery,
  PersonasListQuery_personaSets as PersonaSet,
} from "../__generated__/PersonasListQuery";
import { PersonaSetActionsMenu } from "../PersonaSetActionsMenu";
import { PersonaSetExploreSwitch } from "../PersonaSetExploreSwitch";
import { PERSONAS_LIST_QUERY } from "../personasQuery";

const BLANK_SLATE_PROPS = {
  title: "No Persona Sets found",
  text: "Persona Sets cluster US consumers using demographic, financial, and other features.",
  button: {
    routeName: ROUTE_NAMES.COHORTS,
    children:
      "Use Cohorts to create groups of US consumers to be used in a Persona Set.",
  },
};

export function PersonaSetsTable() {
  const config = useAccountConfigMap();
  const exploreEnabled = config["explore.enabled"];
  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);

  const { data, loading, error } = useSojournerQuery<PersonasListQuery>(
    PERSONAS_LIST_QUERY,
    {
      pollInterval: 10000,
    }
  );

  const filteredPersonaSets = useMemo(
    () => filterResourceTabs(data?.personaSets, activeTab),
    [data, activeTab]
  );

  const renderPersonaSet = (personaSet: PersonaSet) => {
    return (
      <PersonaSetRow personaSet={personaSet} exploreEnabled={exploreEnabled} />
    );
  };
  const tableHeaders: SortableHeader<PersonaSet>[] = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "personas",
      label: "Personas in set",
      sortingFn: "number",
      width: 200,
    },
    {
      label: "Cohort",
    },
    {
      key: "status",
      label: "Status",
      sortingFn: "string",
    },
    {
      key: "createdAt",
      label: "Created",
      sortingFn: "date",
    },
    {
      key: "lastUpdatedConfigAt",
      label: "Modified",
      sortingFn: "date",
      width: 175,
    },
    {
      key: "lastUpdatedOutputAt",
      label: "Processed",
      sortingFn: "date",
      width: 175,
    },
    {
      label: "Explore",
      rendered: exploreEnabled,
      tooltipText: showInExplore,
    },
    {
      label: "Actions",
      width: 64,
      visuallyHidden: true,
    },
  ];

  return (
    <>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data?.personaSets}
      />
      <SortableTable
        storageKey="persona_sets"
        defaultSort={{
          key: "createdAt",
          desc: true,
        }}
        data={filteredPersonaSets}
        headers={tableHeaders}
        renderData={(data) => (
          <ResourceTableList<PersonaSet>
            loading={loading}
            data={data}
            error={error}
            renderData={renderPersonaSet}
            blankSlateProps={BLANK_SLATE_PROPS}
          />
        )}
      />
    </>
  );
}

function PersonaSetRow({
  personaSet,
  exploreEnabled,
}: {
  personaSet: PersonaSet;
  exploreEnabled: boolean;
}) {
  return (
    <Tr key={personaSet.id}>
      <Td>
        <RouterLink
          routeName={ROUTE_NAMES.PERSONAS_ROSTER}
          params={{ roster: personaSet.id }}
          sx={{ fontWeight: "bold" }}
        >
          {personaSet.name}
        </RouterLink>
      </Td>
      <Td>
        {personaSet.status === ResourceStatus.READY
          ? personaSet.personas.length
          : null}
      </Td>
      <Td>
        {personaSet.cohort && <ResourceLink resource={personaSet.cohort} />}
      </Td>
      <Td>
        <ResourceStatusBadge resource={personaSet} />
      </Td>
      <Td>
        <TimeAgo date={personaSet.createdAt} />
      </Td>
      <Td>
        <TimeAgo date={personaSet.lastUpdatedConfigAt} />
      </Td>
      <Td>
        <TimeAgo date={personaSet.lastUpdatedOutputAt} />
      </Td>
      {exploreEnabled && (
        <Td>
          <PersonaSetExploreSwitch personaSet={personaSet} />
        </Td>
      )}
      <Td textAlign="right">
        <PersonaSetActionsMenu
          personaSet={personaSet}
          options={{
            view: true,
            rename: true,
            api: true,
            delete: true,
            archive: true,
          }}
          buttonProps={{
            variant: "icon",
            my: -2,
          }}
        />
      </Td>
    </Tr>
  );
}
