import { gql, NetworkStatus } from "@apollo/client";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Switch,
} from "@chakra-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useMemo, useState } from "react";

import {
  TraitCategory,
  TraitPermission,
  TraitTier,
} from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { useSojournerQuery } from "../../services/sojournerApolloClient";
import { titleCase } from "../../utils/formatters";
import { Pagination } from "../ui/Pagination-v2/Pagination";
import { ResourceTableList } from "../ui/ResourceTableList";
import {
  filterResourceTabs,
  ResourceTab,
  ResourceTableTabs,
} from "../ui/ResourceTableTabs";
import { SortableHeader, SortableTable } from "../ui/SortableTable";
import {
  TraitsTableQuery,
  TraitsTableQuery_traits3 as Trait,
} from "./__generated__/TraitsTableQuery";
import { TraitTableRow } from "./TraitsTableRow";

const PAGE_SIZE = 10;

enum ProvidersOptions {
  // needed strings for the select
  all = "all",
  faraday = "faraday",
  user = "user",
}

const BLANK_SLATE_PROPS = {
  title: "No traits found",
  text: "Traits are emitted by Datasets.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Use Datasets to add Traits.",
  },
};
const tableHeaders: SortableHeader<Trait>[] = [
  {
    key: "literate",
    label: "Name",
    width: 150,
  },
  {
    label: "Provider",
    width: 150,
  },
  {
    key: "category",
    label: "Category",
    width: 150,
  },
  {
    key: "tier",
    label: "Tier",
    width: 150,
  },
  {
    key: "description",
    label: "Details",
    sortingFn: "string",
    width: 200,
  },
  {
    label: "Actions",
    width: 64,
    visuallyHidden: true,
  },
];

export const traitsTableQuery = gql`
  query TraitsTableQuery {
    traits3 {
      id
      category
      tier
      permissions
      description
      literate
      name
      archivedAt
      status
      deprecated
    }

    datasets {
      id
      name
      outputToTraits
      archivedAt
    }
  }
`;

/**
 * Render list of account's traits with various meta data,
 */
export function TraitsTable() {
  const [page, setPage] = useState<number>(1);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [providers, setProviders] = useState<ProvidersOptions>(
    ProvidersOptions.all
  );
  const [categories, setCategories] = useState<TraitCategory | "all">("all");
  const [tiers, setTiers] = useState<TraitTier | "all">("all");
  const [filterableOnly, setFilterableOnly] = useState<boolean>(false);
  const [appendableOnly, setAppendableOnly] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);

  const { data, loading, error, refetch, networkStatus } =
    useSojournerQuery<TraitsTableQuery>(traitsTableQuery, {
      notifyOnNetworkStatusChange: true,
    });

  const filteredTraits = useMemo(
    () =>
      filterResourceTabs(data?.traits3, activeTab).filter(
        (t: Trait) =>
          // remove deprecated traits
          !t.deprecated &&
          // filter by search criteria
          (!searchCriteria ||
            (t.literate ?? t.name)
              .toLowerCase()
              .includes(searchCriteria.toLowerCase())) &&
          (!appendableOnly ||
            t.permissions.includes(TraitPermission.ADD_TO_SCOPE_PAYLOAD)) &&
          (!filterableOnly ||
            t.permissions.includes(TraitPermission.DEFINE_COHORT)) &&
          // filter by provider
          (providers === ProvidersOptions.all ||
            (providers === ProvidersOptions.user &&
              t.category === TraitCategory.USER_DEFINED) ||
            (providers === ProvidersOptions.faraday &&
              t.category !== TraitCategory.USER_DEFINED)) &&
          // filter by category
          (categories === "all" || t.category === categories) &&
          // filter by tier
          (tiers === "all" || t.tier === tiers)
      ),
    [
      data,
      searchCriteria,
      filterableOnly,
      appendableOnly,
      providers,
      tiers,
      categories,
      activeTab,
    ]
  );

  const renderTraitRow = (trait: Trait) => {
    return (
      <TraitTableRow
        trait={trait}
        datasets={data?.datasets ?? []}
        onDeleteComplete={refetch}
      />
    );
  };

  const traitsLoading = loading || networkStatus === NetworkStatus.refetch;
  return (
    <>
      <Flex gap={4} mb={4}>
        <Box>
          <FormControl>
            <FormLabel>Search for traits</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <MagnifyingGlass />
              </InputLeftElement>
              <Input
                placeholder="Type something"
                value={searchCriteria}
                onChange={(e) => {
                  setSearchCriteria(e.target.value);
                  setPage(1); // make sure they're not on a page that doesn't exist
                }}
              />
            </InputGroup>
          </FormControl>
        </Box>

        <Box>
          <FormControl>
            <FormLabel>Provider</FormLabel>
            <Select
              defaultValue={providers}
              onChange={(e) => {
                setProviders(e.target.value as ProvidersOptions);
                setPage(1); // make sure they're not on a page that doesn't exist
              }}
            >
              <option value={ProvidersOptions.all}>All providers</option>
              <option value={ProvidersOptions.faraday}>Faraday</option>
              <option value={ProvidersOptions.user}>User defined</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel>Category</FormLabel>
            <Select
              defaultValue={categories}
              onChange={(e) => {
                setCategories(e.target.value as TraitCategory | "all");
                setPage(1); // make sure they're not on a page that doesn't exist
              }}
            >
              <option value="all">All categories</option>
              {Object.keys(TraitCategory).map((category) => {
                return (
                  <option value={category} key={category}>
                    {titleCase(category.replace("FIG_", "").replace(/_/g, " "))}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel>Tier</FormLabel>
            <Select
              defaultValue={tiers}
              onChange={(e) => {
                setTiers(e.target.value as TraitTier);
                setPage(1); // make sure they're not on a page that doesn't exist
              }}
            >
              <option value="all">All tiers</option>
              {Object.keys(TraitTier).map((tier) => {
                return (
                  <option value={tier} key={tier}>
                    {titleCase(tier)}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl height="100%">
            <FormLabel>Usable in cohorts</FormLabel>
            <Switch
              size={"lg"}
              isChecked={filterableOnly}
              onChange={(e) => setFilterableOnly(e.target.checked)}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl height="100%">
            <FormLabel>Usable in pipelines</FormLabel>
            <Switch
              size={"lg"}
              isChecked={appendableOnly}
              onChange={(e) => setAppendableOnly(e.target.checked)}
            />
          </FormControl>
        </Box>
      </Flex>
      <ResourceTableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={filteredTraits}
      />
      <SortableTable
        storageKey="traits"
        defaultSort={{
          key: "literate",
          desc: false,
        }}
        data={filteredTraits}
        headers={tableHeaders}
        renderData={(sortedData) => (
          <ResourceTableList<Trait>
            data={sortedData.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)}
            loading={loading}
            renderData={renderTraitRow}
            error={error}
            blankSlateProps={BLANK_SLATE_PROPS}
          />
        )}
      />
      <Spacer my={4} />
      <Pagination
        disabled={traitsLoading}
        total={filteredTraits ? filteredTraits.length : 0}
        pageSize={PAGE_SIZE}
        current={page}
        onChange={(newPage) => {
          setPage(newPage);
        }}
      />
    </>
  );
}
