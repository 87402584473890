import { Axis } from "@visx/xychart";

import chakraThemeV2 from "../../../styles/chakra-theme-v2";

/**
 * Axis to render within a visx Chart component.
 * Styles the axis as Faraday desires.
 */
export function AxisLeft<Datum>({
  label,
  tickFormat,
}: {
  label: string;
  tickFormat?: (d: Datum) => string;
}) {
  return (
    <Axis
      orientation="left"
      label={label}
      labelOffset={48}
      tickFormat={tickFormat}
      tickLabelProps={() => ({
        fill: "#000",
        fontSize: 12,
        fontFamily: chakraThemeV2.fonts.body,
      })}
      labelProps={{
        fill: "#000",
        fontWeight: "bold",
        fontSize: 12,
        fontFamily: chakraThemeV2.fonts.body,
        textAnchor: "middle",
        verticalAnchor: "end",
      }}
      hideAxisLine
      hideTicks
    />
  );
}
