import { gql, useQuery } from "@apollo/client";
import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { LockSimple } from "@phosphor-icons/react";

import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { MembersQuery, MembersQuery_users } from "./__generated__/MembersQuery";
import { KickUserButton } from "./KickUserButton";

function MemberRow({
  user,
  isCurrentUser,
  kickable,
}: {
  user: MembersQuery_users;
  isCurrentUser: boolean;
  kickable: boolean;
}) {
  const controls = isCurrentUser ? null : kickable ? (
    <KickUserButton user={user} />
  ) : (
    <Button
      size="xs"
      leftIcon={<LockSimple weight="fill" />}
      variant="tertiary"
      disabled
    >
      Inherited
    </Button>
  );

  const name = `${user.name || user.email} ${isCurrentUser ? "(you)" : ""}`;
  return (
    <Tr>
      <Td>{isCurrentUser ? <strong>{name}</strong> : name}</Td>
      <Td>{user.email}</Td>
      <Td>{controls}</Td>
    </Tr>
  );
}

export const MEMBERS_QUERY = gql`
  query MembersQuery {
    user {
      id
    }
    users {
      id
      name
      email
      kickable
    }
  }
`;

export function MemberTable() {
  const { loading, error, data } = useQuery<MembersQuery>(MEMBERS_QUERY);

  if (loading) return <AnimatedZapLogo />;
  if (error) throw error;
  if (!data) throw new Error("Account users failed to load");

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Email</Th>
          <Th width={160}>Access</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.users.map((u) => (
          <MemberRow
            key={u.id}
            user={u}
            isCurrentUser={u.id === data.user.id}
            kickable={u.kickable}
          />
        ))}
      </Tbody>
    </Table>
  );
}
