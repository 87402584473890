import { getStringWidth } from "@visx/text";
import { Axis, DataContext } from "@visx/xychart";
import { useContext, useEffect, useMemo } from "react";

import chakraThemeV2, { colors } from "../../../styles/chakra-theme-v2";
import { useFdyChartContext } from "./FdyChartProvider";

const tickLabelStyles = {
  fill: colors.black,
  fontSize: 12,
  fontFamily: chakraThemeV2.fonts.body,
};

function truncateTick(d: unknown): string {
  const max = 14;
  const tick = String(d);

  // If the formatted string is too long, shorten it
  if (tick.length > max) {
    return `${tick.slice(0, max)}...`;
  }

  return tick;
}

type AxisBottomProps<Datum> = {
  /**
   * Label shown near the axis.
   */
  label?: string;

  /**
   * Number of (approximate) ticks to show.
   */
  numTicks?: number;

  /**
   * Format the tick label.
   */
  tickFormat?: (d: Datum) => string;

  /**
   * Hide the zero tick.
   */
  hideZero?: boolean;

  /**
   * X scale padding. Used to determine if ticks should be angled.
   * Provided as a percentage of the total width.
   *
   * _Should_ be retrieved from the chart context, but I couldn't figure out how to do that.
   */
  xScalePadding?: number;
};

/**
 * Attempt to calculate if the ticks should be angled based on the tick labels and the chart width.
 */
function calculateAngleTicks({
  ticks,
  innerWidth,
  padding: paddingPercent,
}: {
  ticks: string[];
  innerWidth: number;
  padding: number;
}) {
  // Divide the innerWidth by the number of ticks to get the width of each bar
  // then subtract the padding to get the rough bandwidth.
  // I couldn't find a way to get bandwidth from the xScale via the chart context.
  // Maybe you can be the hero and figure it out.
  const barwidth = innerWidth / ticks.length;
  const paddingPx = barwidth * paddingPercent;

  const totalSpace = ticks.reduce((acc, t) => {
    const width = getStringWidth(t, tickLabelStyles) ?? 0;
    return acc + width + paddingPx;
  }, 0);

  // remove one padding from the total space to account for the last tick
  return totalSpace > innerWidth;
}

/**
 * Axis to render within a visx Chart component.
 * Styles the axis as Faraday desires.
 */
export function AxisBottom<Datum>({
  label,
  numTicks,
  tickFormat,
  hideZero,
  xScalePadding,
}: AxisBottomProps<Datum>) {
  // Get the chart context to determine the chart width.
  const chartCtx = useContext(DataContext);

  const ticks = chartCtx.xScale?.domain();

  // Tried using setDimensions from the above chartCtx, but it didn't work, so use our own context to manage it.
  const { setMargin } = useFdyChartContext();

  // Determine if we should angle the ticks based on the tick labels and the chart width.
  const angleTicks = useMemo(() => {
    if (!ticks || !chartCtx.innerWidth || !xScalePadding) return false;
    return calculateAngleTicks({
      ticks,
      innerWidth: chartCtx.innerWidth,
      padding: xScalePadding,
    });
  }, [chartCtx, ticks, xScalePadding]);

  // If we have angled ticks, we need to adjust the bottom margin to make room for them.
  useEffect(() => {
    if (setMargin && angleTicks) {
      setMargin((prev) => ({
        ...prev,
        bottom: 120,
      }));
    }
  }, [setMargin, angleTicks]);

  return (
    <Axis
      orientation="bottom"
      label={label}
      tickFormat={(d) => {
        const formattedFromProp = tickFormat?.(d) ?? d;
        return truncateTick(formattedFromProp);
      }}
      tickLabelProps={() => ({
        ...tickLabelStyles,
        ...(angleTicks
          ? {
              textAnchor: "end",
              angle: -30,
            }
          : {}),
      })}
      labelProps={{
        fill: colors.black,
        fontWeight: "bold",
        fontSize: 12,
        fontFamily: chakraThemeV2.fonts.body,
        textAnchor: "middle",
        verticalAnchor: "end",
        // If tick labels are angled, we need to move the label down to avoid overlap.
        // This amount is arbitrary (based on the truncated ticks) and may need
        // to be adjusted if the truncation changes.
        ...(angleTicks ? { dy: "4em" } : {}),
      }}
      numTicks={numTicks}
      hideTicks
      hideAxisLine
      hideZero={hideZero}
    />
  );
}
