/**
 * Should user be able to see a resource (cohort,segment,personas,etc) in map/analysis?
 * We're checking for counts and tilesets existence instead of job_state=ready because that job state could change back to running when we regenerate tilesets.
 * We just care if there's anything to show, even if a little stale.
 *
 * TODO: type the result narrowed so count is non-null
 */
export function filterExplorableResources<T extends { count: number | null }>(
  items: T[]
): T[] {
  return items.filter((c) => c.count !== null);
}
