import { ReactElement } from "react";
import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useAccountConfigMap } from "../../hooks/accountConfigHooks";
import { Redirect } from "../ui/Redirect";
import { AccountPage } from "./account";
import { BillingPage } from "./billing";
import { FilesPage } from "./files/FilesPage";
import { GeographiesPage } from "./geographies";
import { SETTINGS_NAV_ITEMS } from "./SettingsNav";
import { TeamPage } from "./team";

const ROUTE_TO_COMPONENT = {
  [ROUTE_NAMES.SETTINGS_ACCOUNT]: AccountPage,
  [ROUTE_NAMES.SETTINGS_MEMBERS]: TeamPage,
  [ROUTE_NAMES.SETTINGS_BILLING]: BillingPage,
  [ROUTE_NAMES.SETTINGS_FILES]: FilesPage,
  [ROUTE_NAMES.SETTINGS_GEOGRAPHIES]: GeographiesPage,
  // Old unused route for now so redirect. Likely to change to contain API
  // keys when we have rotation features etc.
  [ROUTE_NAMES.SETTINGS_API]: () => (
    <Redirect routeName={ROUTE_NAMES.SETTINGS_ACCOUNT} />
  ),
};

export function Settings(): ReactElement {
  const { route } = useRoute();
  const config = useAccountConfigMap();
  if (route.name === ROUTE_NAMES.SETTINGS) {
    // redirect to the first enabled page available on the account
    const firstEnabledRoute = SETTINGS_NAV_ITEMS.find((r) =>
      r.flag ? config[r.flag] : true
    );

    if (!firstEnabledRoute) {
      return <Redirect routeName={ROUTE_NAMES.SETTINGS} showToast replace />;
    }

    return <Redirect routeName={firstEnabledRoute.routeName} replace />;
  }

  if (!(route.name in ROUTE_TO_COMPONENT)) {
    return <Redirect routeName={ROUTE_NAMES.SETTINGS} showToast replace />;
  }

  const Page = ROUTE_TO_COMPONENT[route.name];

  return <Page />;
}
