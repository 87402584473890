import { gql } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import { useRouter } from "react-router5";

import { DatasetPostInput } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import {
  HUBSPOT_USER_CREATED_DATASET,
  useHubSpotEvent,
} from "../../../hooks/useHubspotEvent";
import { useToast } from "../../../hooks/useToast";
import { useSojournerMutation } from "../../../services/sojournerApolloClient";
import { datasetsTableQuery } from "../sharedQueries";
import {
  DatasetCreateMutation,
  DatasetCreateMutationVariables,
} from "./__generated__/DatasetCreateMutation";

export const datasetCreateMutation = gql`
  mutation DatasetCreateMutation($dataset: DatasetPostInput!) {
    createDataset(datasetPostInput: $dataset) {
      id
      name
    }
  }
`;

export function useCreateDataset(options?: {
  onCompleted?: (data: DatasetCreateMutation) => void;
  onError?: (error: ApolloError) => void;
}) {
  const toast = useToast();
  const track = useHubSpotEvent();
  const router = useRouter();

  const [create, { loading: loading }] = useSojournerMutation<
    DatasetCreateMutation,
    DatasetCreateMutationVariables
  >(datasetCreateMutation, {
    refetchQueries: [{ query: datasetsTableQuery }],
    onCompleted(data) {
      if (data.createDataset?.id) {
        const { id, name } = data.createDataset;
        router.navigate(ROUTE_NAMES.DATASETS_DEFINITION, {
          id,
        });
        track(HUBSPOT_USER_CREATED_DATASET, {
          resource_id: id,
          resource_name: name,
        });
        toast({
          status: "success",
          title: `${name} saved`,
          description: `Your dataset, ${name}, is being imported and will be done shortly`,
        });
      }
      if (options?.onCompleted) options.onCompleted(data);
    },
    onError: options?.onError,
  });

  function createDataset(input: DatasetPostInput) {
    return create({
      variables: {
        dataset: input,
      },
    });
  }

  return {
    createDataset,
    loading,
  };
}
