import { gql, MutationHookOptions } from "@apollo/client";

import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { components } from "../../sojourner-oas-types";
import {
  UpdateCohortMutationHook,
  UpdateCohortMutationHookVariables,
} from "./__generated__/UpdateCohortMutationHook";
import { cohortFragment } from "./cohortFragment";

type CohortMergePatch = components["schemas"]["CohortMergePatch"];

export const UPDATE_COHORT = gql`
  mutation UpdateCohortMutationHook($id: ID!, $cohort: String!) {
    updateCohort(applicationJsonMergePatchInput: $cohort, cohortId: $id) {
      ...CohortFragment
    }
  }
  ${cohortFragment}
`;

export function useUpdateCohort(
  options?: MutationHookOptions<
    UpdateCohortMutationHook,
    UpdateCohortMutationHookVariables
  >
) {
  const [mutate, { loading: updating }] = useSojournerMutation<
    UpdateCohortMutationHook,
    UpdateCohortMutationHookVariables
  >(UPDATE_COHORT, options);

  function updateCohort(id: string, input: CohortMergePatch) {
    return mutate({
      variables: {
        id,
        cohort: JSON.stringify(input),
      },
    });
  }

  return {
    updateCohort,
    updating,
  };
}
