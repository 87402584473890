import { GraphEdge } from "@fdy/faraday-js";

// Make a directional identifier for the edge
// to ensure we don't override the same edge in the map.
const makePointer = (edge: GraphEdge) =>
  `${edge.upstream_id}-${edge.downstream_id}`;

/**
 * Recursively traverse the graph to build a list of all nodes connected to the resource.
 */
export function buildGraphForResource(
  graphEdges: GraphEdge[],
  resourceId: string
) {
  // Map to store the graph edges.
  // Identified by the upstream and downstream IDs to avoid duplicates yet
  // ensure we have all the edges between the nodes related to the resource.
  const graphEdgeMap = new Map<string, GraphEdge>();

  const traverseUpstream = (id: string) => {
    const upstreamNodes = graphEdges.filter(
      (item) => item.downstream_id === id
    );
    upstreamNodes.forEach((item) => {
      const pointer = makePointer(item);
      if (item.upstream_id && !graphEdgeMap.has(pointer)) {
        graphEdgeMap.set(pointer, item);
        traverseUpstream(item.upstream_id);
      }
    });
  };

  const traverseDownstream = (id: string) => {
    const downstreamNodes = graphEdges.filter(
      (item) => item.upstream_id === id
    );
    downstreamNodes.forEach((item) => {
      const pointer = makePointer(item);
      if (item.downstream_id && !graphEdgeMap.has(pointer)) {
        graphEdgeMap.set(pointer, item);
        traverseDownstream(item.downstream_id);
      }
    });
  };

  traverseUpstream(resourceId);
  traverseDownstream(resourceId);

  return Array.from(graphEdgeMap.values());
}
